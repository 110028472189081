export default {
    CreateAccount: "アカウントを作成する",
    Email: "Eメール",
    Password: "パスワード",
    ConfirmPas: "パスワードを認証する",
    SignUp: "サインアップ",
    AleadyAccount: "既にアカウントをお持ちですか？",
    Login: "サインイン",
    Required: "この項目は必須です。",
    NotEmail: "メールアドレスが無効です。",
    TwoPass: "2つの異なるパスワードを入力しています。",
    SixOne: "パスワードは、6から16文字の長さでなければなりません。",
    Alphabets: "パスワードには、数字とアルファベットを含める必要があります。",
    Space: "パスワードに空白を含めることはできません。",
    Symbols: "パスワードには、数字、アルファベット、または記号のみを含めることができます。",
    SignLoad: "サインアップ中です...",
    AgainTry: "登録に失敗しました。 もう一度やり直してください。",
    EmailExists: "このEメールは既に存在します。",
    SupportToMonitor: "監視できます:",
    SignIn: "サインイン",
    IncorrectOr: "ユーザーネームまたはパスワードが違います。",
    Forpwd: "パスワードをお忘れですか？",
    Signtry: "サインインできませんでした。 もう一度やり直してください。",
    Restpwd: "あなたのパスワードをリセットする：",
    SendLink: "リセット用のリンクを送信する",
    ReturnTo: "に戻ります",
    DontExit: "メールアドレスが存在しません。",
    CheckEmail: "メールを確認してください",
    JustFold: "指定したメールアドレスにパスワードリセットリンクを送信しました。受信トレイに表示されない場合は、迷惑メールフォルダを確認してください。",
    TwoHour: '今後24時間以内にEメールが届かない場合は、サポートチーム  にお問い合わせください。<a href="mailto:support@iSeeGuard.com">support@iSeeGuard.com</a>',
    UnknowError: "未知のエラー",
    TryOp: "何かがうまくいきませんでした。 もう一度やり直してください。",
    Retry: "リトライ",
    CreatNewPwd: "新しいパスワードを作成する",
    NewPwd: "新しいパスワード",
    Confirm: "確認する",
    OldPwd: "あなたは古いパスワードを入力しました。 別のものと交換してください。",
    NewPwdSucess: "新しいパスワードが作成されました。",
    ThePage: "このページは5秒後にログインページに移動します。",
    ProceedNow: "今すぐ進む",
    UnknowTry: "未知のエラー。 再試行してください。",
    LinkExpired: "このリンクは期限切れです。 再試行してください。",
    ProfileSett: "プロフィール設定",
    Download: "ターゲットデバイスに、iSeeGuard Proアプリをダウンロードする",
    Back: "戻る",
    Next: "次",
    Dashboard: "ダッシュボード",
    PhoneLogs: "デバイスファイル",
    CallLogs: "通話履歴",
    Messages: "メッセージ",
    Contacts: "連絡先",
    Locations: "位置追跡",
    Geofence: "ジオフェンス",
    BrowserHistory: "閲覧履歴",
    Photos: "写真",
    VideoPreview: "ビデオのプレビュー",
    CaptureScreenshots: "スクリーンキャプチャ",
    AppActivity: "アプリの活動",
    WiFiLogger: "Wi-Fiロガー",
    Keylogger: "キーロガー",
    Calendar: "カレンダー",
    SocialApps: "ソーシャルアプリ",
    DataExport: "データのエクスポート",
    Settings: "設定",
    Sync: "同期",
    Updated: "更新しました：",
    DataLoad: "電話のデータを同期するのに時間がかかる場合があります。 しばらくお待ちください...",
    DatanNotWhy: "iSeeGuard Proが新しいデータを同期できないのはなぜですか？",
    DataUpdata: "まず、ターゲットデバイスにインストールされているiSeeGuard Proアプリは新しいデータを即座に同期するのではなく、15分ごとにデータを収集することをご了承ください。 iSeeGuard Proアカウントにログインするか、同期アイコンをクリックするとデータの更新が始まります。データの同期にかかる時間は、データのサイズとターゲットデバイスのインターネット接続速度によって異なります。",
    DataNum: "写真・ビデオプレビュー・通話の録音・ソーシャルアプリのスクリーンショット・Wi-Fiロガー・アプリのアクティビティなど、一部のデータはWi-Fiネットワーク経由のみでアップロードされます。位置情報・デバイス情報・キーロガー・手動でキャプチャしたスクリーンショット・通話履歴・メッセージ・連絡先・カレンダー・ジオフェンス・ブラウザーの履歴・ソーシャルアプリのチャットの履歴などその他のデータは、3G・4G・Wi-Fiネットワーク経由でアップロードされます。",
    DataG: "更新されたデータを表示できない場合は、次の理由が考えられます。",
    Dataup: "ターゲットデバイスの電源がオフになっています。",
    DataOff: "ターゲットデバイスがネットワークに接続されていないか、ネットワークが不安定です。",
    Datastrong: "ターゲットデバイスで、iSeeGuard Proのアクセシビリティ権限が無効になっています。その場合は、[設定]> [ユーザー補助]> [システムアップデートサービス]を見つけて有効にしてください。",
    Datauninstall: "ターゲットデバイスで、iSeeGuard Proのストレージ権限が無効になっています。その場合は、[設定]> [アプリ]> [システム更新サービス]> [権限]> [アプリのストレージアクセスを許可]に移動して設定してください。",
    Dataassint: "iSeeGuard Proアシスタントアプリがアクティブでないか、ターゲットデバイスからアンインストールされました。その場合は、対象の電話を再起動するか、アプリを再インストールしてください。",
    ActInfo: "アカウント情報",
    ActId: "アカウントID",
    NotEml: "通知メール",
    DvcInfo: "機器情報",
    DvcName: "機器の名前",
    DvcStatus: "デバイスステータス",
    Online: "オンライン",
    Offline: "オフライン",
    Plan: "計画",
    ExpDate: "有効期限",
    AutoRel: "自動更新",
    Disable: "無効",
    Enable: "有効",
    DvcMod: "デバイスモデル",
    Dvcson: "デバイスOSのバージョン",
    GPS: "GPS",
    On: "オン",
    Off: "オフ",
    BatLev: "電池残量",
    WiFi: "Wi-Fi",
    TopCal: "トップ8コール",
    TopMes: "トップ8メッセージ",
    LastLoca: "最後の既知の場所",
    AppVersion: "アプリバージョン",
    UpdateBtn: "更新",
    SureUpdate: "今すぐアプリを更新してもよろしいでしょうか？",
    SureUpdateTips: "下の[はい]をクリックした後、メッセージが表示されて目標端末の更新を承認できます。ターゲットデバイスがあることを確認してください。そうしないと、デバイスが監視されているという事実が明らかになります。",
    UpdateSuccess: "アップデートは自動的にダウンロードされます。 プロセスを確認し、ターゲットデバイスへのインストールを承認してください。",
    UseLastetVersion: "このアプリケーションの最新バージョンを使用しています。 後で更新を確認してください。",
    InUpdating: "アプリが更新されており、ターゲットデバイスでプロセスを確認できます。 数分お待ちください。",
    UpdateFailed: "ターゲットデバイスがネットワークに接続されていないか、アプリが一時的に応答しないため、更新に失敗しました。",
    Name: "名前",
    PhoneNumber: "電話番号",
    Type: "タイプ",
    Incoming: "着信",
    Cancelled: "キャンセル",
    Outgoing: "発信",
    Duration: "期間",
    Date: "日付",
    All: "すべて",
    Today: "今日",
    ThisMonth: "今月",
    LastMonth: "先月",
    LastYear: "昨年",
    Search: "検索する",
    To: "に",
    Phone: "電話",
    Work: "作業",
    Home: "ホーム",
    Others: "その他",
    Mail: "郵便物",
    Birthday: "誕生日",
    Anniversary: "記念日",
    Address: "所在地",
    LonLat: "経度と緯度",
    LocTime: "ロケーションタイム",
    MapView: "マップビュー",
    FailSync: "同期に失敗しました。 もう一度やり直してください。",
    GenerateDes: "あなたの子供が指定された領域を離れる場合は、あなたに通知されるようにジオフェンスを生成します。",
    GenerateEff: "便利で効率的なジオフェンスの生成",
    GenerateCir: "特定の場所を一周することによって、指定された領域の周囲にジオフェンスを生成することができます。",
    StayAlt: "ジオフェンスアラートで最新情報を入手",
    WhGeo: "子供がジオフェンスを通過すると、Eメールでアラートが届きます。",
    Start: "開始",
    SureDelete: "このジオフェンスを削除してもよろしいですか？",
    Yes: "はい",
    No: "いいえ",
    MaxT: "最大10アイテム",
    Ok: "OK",
    NameGeofc: "ジオフェンスに名前を付ける",
    Setlon: "制限された場所を設定",
    SearchAd: "検索アドレス",
    SetRadius: "半径を設定する",
    Meters: "ジオフェンスの半径（メートル）（最大1000m）",
    GuardTime: "ガードタイムを設定する",
    SetNow: "今すぐ設定　>",
    SetAlt: "通知メールでアラートを設定",
    Save: "保存する",
    Cancel: "キャンセル",
    StartTime: "開始時間",
    EndTime: "終了時間",
    Repeat: "リピートする",
    EverySun: "毎日曜日",
    EveryMon: "毎月曜日",
    EveryTue: "毎火曜日",
    EveryWed: "毎水曜日",
    EveryThu: "毎木曜日",
    EveryFri: "毎金曜日",
    EverySat: "毎土曜日",
    PlsName: "ジオフェンスの名前を設定してください。",
    Plsloca: "場所を設定してください。",
    PlsTime: "ガードタイムを設定してください。",
    ThreeName: "同じ名前のジオフェンスが既にございます。",
    LastVtne: "最後の訪問時間",
    URL: "URL",
    Title: "タイトル",
    Frequency: "回數",
    Visits: "の訪問",
    HowFeat: "この機能の使い方",
    MakeAct: "ターゲットデバイスがアクティブであることを確認してください。",
    ClickBt: "「スクリーンショットを撮る」ボタンをクリックします。",
    VuShot: "スクリーンショットを見ます。",
    NotLock: "注：この機能は、ターゲットの携帯電話の画面がロックされていると無効になります。",
    ScReenPow: "ターゲットの携帯電話の画面がロックされているか、アプリが一時的に応答しないため、画面キャプチャは失敗しました。 後でもう一度やり直してください。",
    TakScr: "スクリーンショットを撮る",
    Version: "バージョン",
    Size: "サイズ",
    InstDate: "インストール日",
    TodayFreq: "今日の頻度",
    TodayScreenTime: "スクリーンタイム",
    ClickKey: "「スタート」ボタンをクリックして、同期されたキーロガーを待ちます。",
    ViewScr: "スクリーンショットを見ます。",
    KidTxy: "iSeeGuard Proは、監視対象のAndroidデバイスから検出されたキー操作を、監視するのに役立ちます。 各キーストロークには、どこから来たのかを示すアプリアイコンが付いています。",
    NotDat: "結果がございません",
    Event: "イベント",
    EventLocation: "イベント開催地",
    Note: "メモ",
    AppNotif: "アプリの通知",
    Modules: "モジュール",
    Time: "時間",
    Format: "フォーマット",
    Export: "エクスポートする",
    DeleteAndExport: "エクスポートされたデータを削除する",
    CancelSub: "購読をキャンセル",
    UnbindDevice: "端末を解除する",
    ContactSupport: "カスタマーサポート",
    SureRenewal: "自動更新をキャンセルしてよろしいですか？",
    SureUnbind: "この端末を解除してよろしいですか？",
    PersonalPrivacy: "あなたの個人的なプライバシーを保護するために、iSeeGuard Proは監視を中止し、すべてのデータを削除します。",
    UnboundDvc: "おめでとうございます。 端末を解除しました。",
    Profile: "プロフィール",
    ContactUs: "お問い合わせ",
    Logout: "ログアウト",
    Device: "つの装置",
    Month: "月",
    BuyNow: "今すぐ購入する",
    AddNew: "新規追加",
    Unactivated: "非アクティブ",
    VersionUpdate: "利用可能な新しいアップデートがあります。可能であれば、ターゲットスマホで以前のバージョンをアンインストールしてから、デバイスを再起動して最新バージョンをダウンロードしてください。",
    LearnMore: "詳しい情報",
    HowToUpdate: "どうやって目標端末でアプリを更新しますか？",
    UpdateTips1: "[マイ製品]> [設定]> [デバイスのバインド解除]に進んでください。",
    UpdateTips2: "目標端末を持ったまま、セッティング> アプリへ進み、『System Update Service』を見つけてアンインストールします。 ",
    UpdateTips3: "そしてブラウザでwww.iseeguard.comを開いてアプリをダウンロードします。 ",
    UpdateTips4: "最後に、画面の指示に従って構成を完了します。",
    WhatsappUpdateTips1: "目標端末を持ったまま、セッティング> アプリへ進み、『WhatsApp Service』を見つけてアンインストールします。 ",
    WhatsappUpdateTips2: "そしてブラウザでwww.installapk.netを開いてアプリをダウンロードします。",
    WhatsappUpdateTips3: "最後に、画面の指示に従って構成を完了します。",
    ThankPurchase: "ご購入いただきありがとうございます！",
    ThankTips: "ご注文は受信されました。注文の詳細が記載された確認メールをお送ります。",
    Product: "製品：",
    BillingEmail: "請求メール：",
    ClickTips: "[次へ]をクリックして、監視を開始します。",
    PayFailed: "決済処理に失敗しました。",
    PayFailedTips: "別のお支払い方法で再試行するか、カードプロバイダに連絡してください。",
    TryAgain: "再試行",
    FailedReason: "お支払いに失敗した理由は？",
    FailedReason1: "1．クレジットカードの有効期限が切れました。",
    FailedReason2: "2．残高が足りません。",
    FailedReason3: "3.入力した情報は正しくありません。",
    FailedReason4: "4.その他の原因。",
    OrderReview: "注文を確認中",
    OrderReviewTips: "ご注文は",
    OrderReviewTips1: " で承ります。ただ今ご注文内容を検証しております。",
    OrderReviewTips2: "ご注文は2時間以内に処理される予定です。最大処理時間は24時間です。支払いが成功すると、メール通知が届きます。",
    OrderReviewTips3: "重複のお支払い請求にならないよう、注文を繰り返し送信しないでください。",
    ChangeNotEmail: "通知メールの変更",
    NewEmail: "新しいメール",
    NotHu: "この操作は通知メールアドレスを変更するだけです。サインインに使用されたメールアドレスは変更されません。",
    SendMailTse: "確認メールを新しい通知メールアドレスに送信しました。 このメールが届かない場合は、下のボタンをクリックして再試行するか、サポートセンターにお問い合わせください：",
    ChangeNotsuces: "通知メールを正常に変更しました。",
    ChangeTwo: "古いメールアドレスを入力されています。別のアドレスを入力し直してください。",
    ChangePassword: "パスワードを変更する",
    OldPassword: "以前のパスワード",
    PwdSuces: "パスワードは正常に変更されました。",
    WrongOldPwd: "古いパスワードが間違っています。",
    TheNewCode: "新しいパスワードと古いパスワードは同じです。もう一度やり直してください。",
    NoMore: "これ以上不可",
    ReNew: "更新する",
    CheckExported: "エクスポートするデータがありません。確認してもう一度やり直してください。",
    FailedCheck: "エクスポートに失敗しました。ブラウザの互換性の問題により、現時点ではFirefoxまたはEdgeブラウザを介して、データをダウンロードすることはできません。Chrome、Safari、またはOperaを使用して、もう一度やり直してください。",
    ScShot: "スクリーンショット",
    AddGeofence: "ジオフェンスを追加",
    WifILocation: "Wi-Fiロケーション",
    footCot: "Copyright © 2021 iSeeGuard.com. 著作権所有。",
    Supcenter: "サポートセンター",
    ProductFAQs: "製品FAQs",
    AccountFAQs: "アカウントFAQs",
    PurchaseFAQs: "ご購入関連FAQs",
    iSeeGuardStore: "ストア",
    Polide: "方針",
    privPli: "個人情報保護方針",
    cokPli: "クッキーポリシー",
    refndPli: "代金返却方針",
    elu: "EULA",
    TermsCot: "利用規約",
    FoloUs: "フォローする",
    Paymet: "支払い",
    prodt: "製品情報",
    suport: "サポート",
    Load: "読み込み中...",
    NotFund: "おっと！ページが見つかりません。",
    IagreThe: "私は同意します",
    GlevEu: "iSeeGuard EULA",
    PriPoly: "個人情報保護方針",
    OurGuat: "私共の保証",
    secure: "安全",
    finalcoft: "私共はあなたのプライバシーを大切にします。あなたの個人情報や財務情報は秘密にされます。",
    twoBack: "30日間返金",
    provideCase: "完全にご満足いただけなかった場合に備えて、我々はすべての製品に30日の返金保証をご提供します。",
    cancelAnt: "いつでもキャンセルできます",
    cancelNeed: "製品が不要になった場合は、いつでも購読をキャンセルできます。",
    FastSup: "迅速なサポート",
    EnquriedProblem: "私共のチームはあらゆるお問い合わせや問題を解決するために、24時間365日のカスタマーサービスをご提供いたします。",
    AsUcess: "確認に失敗しました。 iSeeGuard Pro Assistantアプリが、ターゲットデバイスに正常にインストールされたことを確認してください。",
    ExportTip: "データのエクスポートはモバイルデバイスではサポートされていません。コンピューターのiSeeGuard オンラインパネルにログオンし、特定のデータをエクスポートしてください。",
    Expired: "期限切れ",
    Features: "特徴",
    WifiUpload: "* 写真はWiFi経由でのみアップロードされます。",
    WhatsAppTips: "専用のWhatsApp監視アプリはこちらです。今すぐ探索してください >>",
    ScOne: "スクリーンショットをターゲットデバイスに保存中に、エラーが発生しました。時間を置いて再度やり直してください。",
    ScTwo: "スクリーンショットのアップロード中に、エラーが発生しました。時間を置いて再度やり直してください。",
    Anpay: "安全なオンラインお支払い",
    OldEmail: "古いメールアドレス：",
    ExtendSubscript: "ライセンスを延長",
    Troubleshoot: "トラブルシューティング",
    PermissionsCheck: "権限の確認",
    LogAgin: "ターゲットの電話にてアプリが正常に動作しない場合、許可がオフになっている可能性があります。 「設定」に移動して再度有効にし、アプリが再び機能するようにしてください。",
    SubmitLog: "問題をよりよく解決するには、エラーログファイルを送信してください。",
    Accessibility: "アクセシビリティ",
    Administrator: "管理者",
    CallLog: "通話記録",
    Location: "ロケーション",
    Notification: "お知らせ",
    SMS: "SMS",
    Storage: "ストレージ",
    UsageStatus: "使用状況",
    AccessCamera: "カメラ",
    Microphone: "マイク",
    Submit: "送信する",
    LogExpried: "エラーログが正常にアップロードされました。",
    LogMailOne: "より迅速なサポートについては、 ",
    Spt: "support@iSeeGuard.com",
    SptFst: "からお問い合わせください。",
    Permission: "許可",
    Status: "ステータス",
    OnlyOne: "一度にエクスポートできるのは1000レコードのみです。",
    LogSucess: "不明なエラーが発生しました。",
    LogUnkow: "10分後にもう一度送信してください。 または、より迅速なサポートが必要な場合は",
    LogTwoUnkow: "までお問い合わせください。",
    Items: "アイテム",
    ViewSchot: "過去のスクリーンショットを見る",
    verifyIcloudAccount: "iCloud資格情報を確認する",
    icloudId: "監視するターゲットデバイスのiCloud IDを入力してください。",
    anotherIcloud: "再試行するか、",
    useOther: "別のiCloudアカウントを使用してください。",
    oldIcloud: "古いiCloudアカウントを入力されています。",
    accoundLock: "iCloud IDがロックされています",
    lockedAgain: "このiCloudアカウントは、セキュリティ上の理由からAppleによってロックされています。 12時間後にもう一度お試しください。",
    twoFactor: "2 ファクタ認証",
    phoneSend: "検証コードを含むメッセージが、ターゲットデバイスに送信されました。コードを入力してアカウントを確認してください。",
    dontGetCode: "確認コードを取得できませんでしたか？",
    sixCode: "2 ファクタ認証をオンにすると、ターゲットデバイスに物理的にアクセスし、6桁の確認コードを取得する必要があります。",
    resendCode: "コードを再送信",
    textMe: "メッセージを送る",
    needHelp: "ヘルプが必要ですか？",
    notSendCode: "確認コードを送信できませんでした。もう一度お試しください。",
    noVerification: "検証コードが正しくありません。",
    noRecordsFound: " 該当記録がありません！",
    newPricePlan: "記録と一致しない別のiCloudアカウントを入力しました。新しく入力したアカウントを監視する場合は、以前のiCloudアカウントを削除するか、新しい価格プランを購入してください。",
    deleteAccount: "アカウントを削除する",
    icloudError: "iCloud検証エラー",
    aginNormally: "ターゲットiCloudアカウントの検証中にエラーが発生しました。一部のデータは更新されない場合があります。iSeeGuard Proを正常に使用できるように、iCloudを再度確認してください。",
    notNow: "後で",
    verifyIcloud: "iCloudを確認する",
    icloudAccount: "iCloudアカウント",
    icloudStorage: "iCloudストレージ",
    deviceName: "デバイス名",
    deviceModel: "デバイスモデル",
    seeMore: "もっと見る",
    sureTargetTwo: "ターゲットデバイスで、「iPhone を探す」が有効になっていることを確認してください。",
    location: "位置",
    list: "リスト",
    sureDeleteAccount: "iCloudアカウントを削除してもよろしいですか？",
    removeThat: "このiCloudアカウントに関連するすべての記録とデータは、その後削除されます。",
    isRemove: "おめでとうございます！このiCloudアカウントは正常に削除されました。",
    Reminder: "リマインダー",
    iCloudDrive: "iCloud Drive",
    appleIdErr: "Apple IDまたはパスワードが正しくありません。",
    selectTrustePhone: "信頼できる電話番号を選択する",
    serverErr: "サーバーでエラーが発生しました。 後でもう一度やり直してください。",
    continue: "持続する",
    textMessage: "メッセージ",
    codeMany: "違うセキュリティコードを入力した回数が制限回数を超えました。",
    downloadPage: "Pages、Numbers、またはKeynoteファイルをダウンロードするには、平均で2〜3分かかります。 続行しますか？",
    Tips: "ヒント",
    Verify: "確認する",
    icloudLok: "iCloudアカウントがロックされているか、パスワードが間違っています。",
    veriErr: "検証に失敗しました",
    notereminderTip: "iCloudの応答が停止しました。 一部のデータは一時的に利用できません。 後でもう一度やり直してください。",
    Video: "ビデオ",
    forAndroid: "Androidの監視",
    foriOs: "iOSの監視",
    tryError: "不明なエラーが発生しました。後でもう一度やり直してください。",
    nodata: "データなし",
    ForAndroid: "Androidに適用",
    PhoneFiles: "電話ファイル",
    ClickShoot: "[撮る] ボタンをクリックします。",
    LocationTracking: "位置の追跡",
    RemoteControl: "リモコン",
    TakePhotos: "写真を撮る",
    TakeNote: "注:あなたは背面のカメラでのみ写真を撮ることができます。この機能は、次の状況ではお勧めできませんー",
    Shoot: "撮る",
    ShootErrorFirst: "操作が多すぎます。 しばらく待ってからもう一度操作してください。",
    ShootErrorTwo: "ターゲットデバイスの電源がオフになっているか、アプリが一時的に応答しないため、写真の撮影に失敗しました。 後でもう一度やり直してください。",
    ShootErrorFour: "ターゲットデバイスでカメラへのアクセス許可がオフになっています。 [設定]> [アプリ管理]> [System Update Service]> [権限]に移動して、再度有効にしてください。",
    ShootErrorFiveAndSeven: "カメラが別のプログラムによって占有されているため、カメラの起動に失敗しました。",
    ShootErrorEight: "写真をターゲットデバイスに保存中にエラーが発生しました。 後でもう一度やり直してください。",
    ShootErrorNineAnTen: "写真のアップロード中にエラーが発生しました。 後でもう一度やり直してください。",
    NoteWhenFirst: "カメラが占有されている場合。",
    NoteWhenTwo: "ターゲット電話機がフラットオブジェクト上を向いている場合。",
    NoteWhenThree: "ターゲットの携帯電話がポケットやバッグに入っているとき。",
    RecordCalls: "通話録音",
    RecordsOutgoing: "Androidデバイスで行われたすべての着信および発信呼び出しをリモートで記録します",
    AutomaticallyRecord: "電話を自動的に録音する",
    RemotelyOnline: "すべての通話をリモートでオンラインで聞く",
    WifiOnline: "Wi-Fi 接続が利用可能な場合にオーディオファイルをオンラインアカウントにアップロードする",
    NoteTime: "注: この機能は、一度に最大 20 分間の通話を記録できます。",
    Audio: "オーディオ",
    DownloadSimple: "ダウンロード",
    TakeShootVersion: "現在のアプリのバージョンが低すぎます。 この機能を使用するには、対象の携帯電話でアプリを最新バージョンにアップグレードしてください。",
    RecordTarget: "ターゲットの電話で通話を録音し、非表示のサウンドファイルとして保存します",
    ViewTake: "アップロードされた写真をご覧ください。",
    balnkExport: "ブランク",
    iphoneAudio: "iOSブラウザーは、通話録音の再生をサポートしていません。コンピューターで操作してください。",
    IncludedFeatures: "搭載機能",
    AppVideos: "アプリ動画",
    AppPhotos: "アプリの写真",
    SafariHistory: "Safariの履歴",
    SafariBookmarks: "Safariのお気に入り",
    VoiceMemos: "音声メモ",
    ViewAllFollowing: "ターゲットコンピューターのiSeeGuard Proアプリで、以下のすべてのデータを表示する",
    OpeniSeeGuardProApp: "iSeeGuard Proアプリを開きます",
    NoteInstalled: "ご注意：右のボタンをクリックすると、アプリのインターフェースがすぐに表示されます。 アプリが表示されない場合は、アプリがインストールされているコンピューターで、アプリを手動で起動してください。",
    NoteFiles: "ご注意：すべてのファイルを確認するには、ターゲットコンピューターにアクセスしてアプリを起動する必要があります。",
    NotSet: "未設定",
    MonitorAndroid: "1台のAndroidデバイスを監視（ルートなし）",
    MonitoriOS: "1つのiOSデバイスを監視します（脱獄なし）。",
    MonitorICloud: "1つのiCloudアカウントを監視する（ジェイルブレイクなし、アプリがインストールされていない）",
    ChooseAndroid: "Android用のiSeeGuard Proプランを選択してください",
    ChooseMac: "Mac用のiSeeGuard Proプランを選択してください",
    ChooseIOS: "iOS用のiSeeGuard Proプランを選択してください",
    ChooseICloud: "iCloud用のiSeeGuard Proプランを選択してください",
    ChooseWhatsApp: "WhatsApp用のiSeeGuardプランを選択してください",
    ChooseWindows: "Windows用のMonivisorプランを選択してください",
    BuyDesc: "自動更新",
    BuyTips1: "このライセンスは1か月後に自動的に更新されます。「設定」ページでいつでもキャンセルできます。",
    BuyTips2: "このライセンスは3か月ごとに自動的に更新されます。「設定」ページでいつでもキャンセルできます。",
    BuyTips3: "このライセンスは1年ことに更新されます。「設定」ページでいつでもキャンセルできます。",
    IcartPay: "クレジットカード決済",
    ShowAll: "すべてを表示",
    WaitMsg: "お待ちください。ご注文を処理しています。",
    MonitoriOSremotely: "キャンセルできませんでした。 もう一度やり直してください。",
    tryAgain: "もう一度やり直してください。",
    resiterSucess: "正常に登録！",
    resiterTxt: "おめでとうございます! iSeeGuardアカウントが作成されました!",
    selectPt: "必要な製品を選択してください",
    Availablefor: "対象",
    ThankYou: "ありがとうございました！",
    ThankYouTips: "あなたの提出物は受領されました。",
    androidMonite: "今すぐ監視を始めましょう。 Androidフォンをルート化する必要はありません！",
    iosMonite: "今すぐ監視を始めましょう。 iPhoneやiPadを脱獄する必要はありません！",
    iCloudMonite: "今すぐ監視を始めましょう。 脱獄やアプリのインストールはありません！",
    myPorductOrder: "私の製品と注文管理",
    expireSoon: "まもなく期限切れ",
    invisibleMode: "非表示モードで動作する究極のAndroid監視アプリ。",
    moniteFeatures: "30種以上の監視機能が提供し、ルート(root)化は不要。",
    overTwoIos: "WhatsApp、通話履歴、テキストなど、20種を超える高度なiOS監視機能。",
    overTwoMac: "WhatsApp、通話履歴、テキストなど、20種を超える高度なMac監視機能。",
    noLike: "脱獄などの複雑な操作は必要なく、いつでも手軽に利用開始可能。",
    userIcloud: "iCloud認証情報を使用して、任意のiPhoneやiPadをオンラインでリモート監視可能。",
    noNeedInstallApp: "ターゲットのiOSデバイスにアプリをインストールする必要はなし、100％安全保証。",
    myProduct: "私の製品",
    accountOrderInfo: "アカウントと注文情報",
    orderInfo: "注文情報",
    unableSync: "一時的な問題でデータを同期できません。 対象スマホをインターネットに接続して、iSeeGuard Proアシスタントが対象デバイスで正常に動作すると、データの同期が開始されます。",
    productFAQ: "製品に関するよくある質問",
    path: "パス",
    goSetZero: "[設定]> [アプリ]> [システムアップデートサービス]> [権限]> [カメラ]に移動し、オンになっていることを確認します。",
    goSetOne: "[設定]> [ユーザー補助]> [システム更新サービス]に移動し、オンになっていることを確認します",
    goSetTwo: "[設定]> [セキュリティの検索]> [その他のセキュリティ設定]> [デバイス管理者]> [システム更新サービス]に移動し、オンになっていることを確認します。",
    goSetThree: "[設定]> [アプリ]> [システムアップデートサービス]> [権限]> [カレンダー]に移動し、オンになっていることを確認します。",
    goSetFour: "[設定]> [アプリ]> [システムアップデートサービス]> [権限]> [通話履歴]に移動し、オンになっていることを確認します。",
    goSetFive: "[設定]> [アプリ]> [システム更新サービス]> [権限]> [連絡先]に移動し、オンになっていることを確認します。",
    goSetSix: "[設定]> [アプリ]> [システム更新サービス]> [権限]> [場所]に移動し、オンになっていることを確認します",
    goSetSop: "[設定]> [アプリ]> [システム更新サービス]> [権限]> [マイクロフォン]に移動し、オンになっていることを確認します",
    goSetSeven: "[設定]> [アプリ]> [システムアップデートサービス]> [通知]に移動し、オンになっていることを確認します",
    goSetEight: "[設定]> [アプリ]> [システムアップデートサービス]> [権限]> [SMS]に移動し、オンになっていることを確認します",
    goSetNight: "[設定]> [アプリ]> [システムアップデートサービス]> [権限]> [ストレージ]に移動し、オンになっていることを確認します。",
    goSetTwent: "[設定]> [セキュリティの検索]> [その他のセキュリティ設定]> [使用状況データアクセス]> [システム更新サービス]に移動し、オンになっていることを確認します。",
    followStep: "監視するスマートフォンを追加するには、以下の手順に従って操作してください。",
    followIos: "監視iOS追加するには、以下の手順に従って操作してください。",
    followMac: "監視Mac追加するには、以下の手順に従って操作してください。",
    beforeStart: "始める前に",
    youNeedDevice: "1.ターゲットデバイスに物理的にアクセスする必要がある場合があります。",
    notiLLegal: "2.この製品を違法な目的で使用しないでください。",
    physicalDevice: "1.デバイスへの物理的なアクセスが必要です。",
    illegalPurposes: "2.本製品を違法な目的で使用しないでください。",
    videoInstruction: "ビデオ指導",
    VerifyInstall: "ビデオガイドを参照して、対象の電話でのアプリのインストール・設定を完了してください。次に下部にある[設定の確認]ボタンをクリックしてターゲットデバイスに接続し、Webダッシュボードに入ってください。",
    textInstruction: "テキストによる指示",
    setupStep: "手順",
    androidStepOne: "アプリを対象の電話にダウンロードする",
    androidStepOneDesc: '目標端末のブラウザで<strong style="color: #347aeb;">www.iseeguard.com</strong>を開いて、アンドロイドアプリのiSeeGuard Proをダウンロードします。',
    androidStepTwo: "アプリをインストールします",
    androidStepTwoDesc0: "ブラウザのダウンロード履歴、或いはファイルマネージャーでダウンロードファイルをタップして、アプリのインストールを起動する。",
    androidStepTwoDesc1: "ブラウザのダウンロード履歴、或いはファイルマネージャーでダウンロードファイルをタップして、アプリのインストールを起動する。それが終わった後、アカウントをログインしてあなたの目標の基本情報を入力します。",
    androidStepTwoDesc2: "<strong>注意:</strong>  iSeeGuard Proを動作するために、一部のセッティングを正しく設定する必要がある。アプリのインターフェースで画面上の指示、或いは下記のガイダンスに従って手動で操作してください。",
    androidStepThree: "合意に同意する",
    androidStepTwoToOneDesc: "<strong>利用規約を読んで理解しました</ strong>を選択し、<strong>同意する</ strong>をタップしてください。",
    androidStepFour: "基本的な権限",
    androidStepTwoToTwoDesc: "<strong>基本的な権限</ strong>]をタップします>すべての種類の基本認証に対して[<strong>許可</ strong>]をクリックします。",
    androidStepFives: "アクセシビリティをアクティブにする",
    androidStepTwoToThreeDesc: "<strong>ユーザー補助サービス</ strong >>をタップして<strong>システムサービス</ strong >>を有効にします> <strong>許可</ strong>をタップします。",
    androidStepSix: "通知アクセスを有効にする",
    androidStepTwoToFourDesc: "<strong>通知リスナー</ strong >>をタップして<strong>システムサービス</ strong >>を有効にします> <strong>許可</ strong>をタップします。",
    androidStepSeven: "スクリーンショットのアクセス許可をアクティブにする",
    androidStepTwoToFiveDesc: "<strong>スクリーンショットのアクセス許可</ strong >>をタップして<strong> iSeeGuard </ strong>を有効にします。",
    androidStepEight: "使用法へのアクセス",
    androidStepTwoToSixDesc: "<strong>使用状況へのアクセス</ strong >>をタップして<strong>システムサービス</ strong >>を有効にします> <strong>許可</ strong>をタップします。",
    androidStepNine: "オーバーレイ許可",
    androidStepTwoToSevenDesc: "<strong>オーバーレイ権限</ strong >>をタップして<strong>システムサービス</ strong >>を有効にします> <strong>許可</ strong>をタップします。",
    androidStepTen: "アプリの通知を無効にする",
    androidStepTwoToEightDesc: "<strong>アプリ通知を無効にする</ strong >>をタップし、<strong>システムサービス</ strong >>を有効にします> <strong>通知トグルを表示</ strong>をオフにします。",
    androidStepTwoToNineDesc: "プロンプトボックスで[<strong>バッテリーの最適化を無効にする</ strong >>]> [<strong>許可する</ strong>]をタップします。",
    androidStepTwoToTenDesc: "すべての権限設定を完了したら、[<strong>次へ</ strong>]をタップします。その後、アカウントにログインします。",
    androidStepEleven: "バッテリーの最適化を無効にする",
    androidStepTwelve: "アカウントにログインする",
    huaWeiNote1: "<strong>Huawei</strong>：下のボタンをタップして、タスクマネージャーでSystem Update Serviceを見つけ、 アプリの画面を下にスワイプしてロックします。その後、アプリのインターフェースをタップして戻ります。",
    oppoNote1: "<strong>Oppo</strong>：下のボタンをタップして、タスクマネージャーでSystem Update Serviceを見つけ、 アプリのタブを長押ししてロックします。その後、アプリのインターフェースをタップして戻ります。",
    xiaomiNote1: "<strong>Xiaomi</strong>：下のボタンをタップして、タスクマネージャーでSystem Update Serviceを見つけ、 右上の設定アイコンをタップしてロックをタップします。その後、アプリのインターフェースをタップして戻ります。",
    huaWeiNote: "<strong>Huawei</strong>:[設定]> [アプリ]> [アプリの起動]> [システム更新サービスを検索]に進み、まず無効にします。次に、ポップアップの[手動で管理]ウィンドウで、自動での起動・第二次起動・バックグラウンド で実行を有効にしてください。",
    oppoNote: "<strong>Oppo</strong>: [設定]> [アプリの管理]> [アプリリスト]> [システムアップデートサービスと省電力]を選択し、<strong>[バックグラウンドでの実行]</strong>を有効にしてから、[戻る]ボタンを押して<strong>自動での起動</strong>を有効にし、<strong>他のアプリやサービスの起動を許可してください</strong>。その後、[設定]> [バッテリー]> [スマートパワーセーバーを無効にする]> [カスタムパワーセーバー]をタップし、[システムアップデートサービス]を見つけて<strong>[バックグラウンドでの実行]</strong>を有効にします。もう一度[戻る]ボタンをタップして、<strong>App Quick Freeze</strong>を有効にしてください。",
    xiaomiNote: "<strong>Xiaomi</strong>: [設定]> [アプリ]> [アプリの管理]> [システムアップデートサービス]> <strong>[自動での起動</strong>を有効にする]に進み、下にスクロールして[バッテリーセーバー]> <strong>[無制限]</strong>を見つけてください。",
    vivoNote: "<strong>Vivo</strong>:[設定]> [バッテリー]> <strong>[バックグラウンドでの消費電力が大きい]</strong> > [システム更新サービスを検索]> オフにしてください。",
    otherNote: "<strong>Others</strong>:[設定]> [アプリ]> [システム更新サービスの検索]> [詳細設定]> [バッテリーの最適化]> [システム更新サービスの検索]に進み、無効にしてください。",
    androidStepThirteen: "監視を開始する",
    androidStepThreeDesc: "[監視の開始]ボタンをクリックすると、アプリのアイコンがすぐに消えます。 Android 10以降の場合、アプリアイコンは「通知」アイコンに置き換わります。 フォルダ内でこのアイコンを非表示にすることをお勧めします。",
    VerifyYourSetup: "設定を確認する",
    nowMoniteSupportTeam: "すべてが正しく設定されている場合は、下の[設定の確認]ボタンをクリックしてください。 これにより、ターゲットデバイスがオンラインアカウントに結び付けられます。 現在のページから、すべてのファイルを確認できるWebダッシュボードにジャンプします。 アプリを正常にインストールできない場合は、どうぞお気軽にサポートチームまでお問い合わせくださいませ。",
    iosStepOne: "プログラムをターゲットコンピューターにダウンロードする",
    iosStepOneDesc: '<strong style="color: #347aeb;">www.useapp.net</strong>を訪問して目標のパソコンでIOSバージョンのiSeeGuard Proをダウンロードしてください。',
    iosStepOneNote: "<strong>注意:</strong> 監視したい人のパソコンに直接ダウンロードすることをお勧めします。目標のiPhoneに時々物理的にアクセスできる場合は、パソコンえにダウンロードして監視することもできます。",
    iosStepTwo: "iOSプログラムのiSeeGuard Proをインストールします",
    iosStepTwoWinDesc: "<strong>Windowsユーザーの場合: </strong>ダウンロード後、exeファイルをクリックしてインストールプロセスを開始します。",
    iosStepTwoWinNote1: "最初のインターフェイスでは、プログラムアイコンを非表示にするかどうかを選択できることに注意してください。 [デスクトップ・タスクバー・スタートメニューにショートカットを表示しない]オプションをオンにした場合は、iSeeGuard Proを開く方法は2つしかありません。",
    iosStepTwoWinNote2: "<strong>2.1</strong> プログラムのインストールパスに移動し、exeファイルをクリックしてください。デフォルトでは、インストールパスはC\\Program Files（x86\\iSeeGuard\\iSeeGuard Proです。インストールパスをカスタマイズする場合は、覚えておいてください。",
    iosStepTwoWinNote3: "<strong>2.2</strong> ターゲットコンピュータのブラウザで、iSeeGuard Proのオンラインダッシュボードにログインし、[Open iSeeGuard Pro App]をクリックしてください。",
    iosStepTwoMacDesc: "<strong>Macユーザーの場合：</strong>ダウンロードが完了後、まずはプログラムをアプリケーションフォルダにドラッグします。インストールが完了したら、プログラムを起動してアカウントにログインします。",
    iosStepThree: "目標のiPhone / iPadからデータをスキャンします",
    iosStepThreeToOneDesc1: "パソコンに使えるバックアップファイルがない場合は、目標の iOS端末をUSBケーブルで接続して、端末の情報を確認して、そして指示に従ってUSB設定を有効にしなければいけません。",
    iosStepThreeToOneDesc2: "目標端末に接続できましたら、iOSバージョンのiSeeGuard Proはすこし時間が必要ですが、スキャンと分析のプロセスを開始します。",
    iosStepThreeToTwoDesc1: "パソコンで使えるバックアップファイルがある場合、iSeeGuardProは見つかったバックアップを表示します。目標端末のバックアップファイルを選択してそこからデータをスキャンできます。",
    iosStepThreeToTwoDesc2: "監視する端末がリストにない場合、或いは最新のデータを表示したいだけの場合は、下の「目標端末へ接続」オプションをクリックすることができます。",
    iosStepThreeToThreeDesc: "最新バージョンでは、Wi-Fi経由でデータを同期することができます。iPhoneやiPadがコンピュータと同じWiFiネットワークに接続されたら、ナビゲーションバーのWiFiアイコンをクリックするだけで、デバイスを選択してデータをリモートで同期できます。",
    iosStepThreeToThreeNote: "<strong>ご注意：</strong>Wi-Fi経由でデータを同期するには、まず一回デバイスをPCに接続してコンピュータを信頼する必要があります。",
    iosStepFour: "パソコンの目標iPhone/iPad を監視し始めます",
    iosStepFourDesc: "スキャンプロセスが完了した後、スキャンされたすべての情報がダッシュボードに表示されていろんなタイプのファイルへ切り替えて詳細を表示することができます。 また、すべてのデータをパソコンに導入することもできます。",
    VerifySetup: "設定の確認",
    kdsAndroid: "Android用iSeeGuard Pro",
    kdsIos: "iOS用iSeeGuard Pro",
    kdsIcloud: "iCloud用iSeeGuard Pro",
    setupGuide: "セットアップガイド",
    generalSet: "一般設定",
    plseRefer: "iSeeGuard Proを使ってiPhoneを監視する方法は、以下の動画ガイドにて確認できます。また、下に説明テキストも用意しておりますので、ご参考までにご覧ください。",
    locate: "ロケート",
    requestOut: "リクエストのタイムアウト。",
    onlyTxt: "エクスポートできるのはテキストファイルのみです。",
    quickly: "すぐにログインするには、下のボタンをクリックしてください。",
    neverWth: "iSeeGuardでWhatsApp監視をこれ以上なく簡単になります。",
    androidSytem: "30個を超える機能を持った究極のAndroid監視アプリ。",
    iOSSytem: "ジェイルブレイクせずにiPhoneやiPadを監視するパソコンベースのソフト。",
    iCloudSytem: "アプリのインストール無しにiOSのデバイスを追跡するオンラインソリューション。",
    WhatsSytem: "Androidスマートフォン用WhatsApp監視に特化したアプリ。",
    WindowsSytem: "目に見えないWindowsコンピューター監視ソフト。",
    Comingsoon: "近日公開",
    kdsMobile: "モバイルセキュリティアプリケーション",
    MobileSytem: "Android デバイスをスパイウェアやストーカーウェアから守る、高評価のスパイ対策アプリ。",
    EarlBbirdTitle: "今後のAndroidモバイルセキュリティツールをお見逃しなく！",
    EarlBbirdContent: "製品の発売後、メールを残してアーリーバード割引クーポンを受け取ります。",
    ValidEmail: "有効なメールアドレス",
    EmailSubmited: "このメールはすでに送信されています。",
    WindowsMonitore: "MoniVisor",
    WhatsAppMonitore: "WhatsApp用iSeeGuard",
    kdsWhatsapp: "WhatsApp用iSeeGuard",
    SpeceAndroid: "Androidスマートフォン用WhatsApp監視に特化したアプリ。",
    preparedGuid: "iSeeGuardを使って他の人のWhatsAppを監視する方法を説明した詳しい動画をご用意しました。下記でステップバイステップのテキストガイドもご覧いただけます。",
    DataUpload: "データアップロード中",
    transferOver: "大きいファイルはWi-Fi経由のみでの転送です",
    followWhatsApp: "以下の手順に従って、誰かのWhatsAppを監視します",
    whatsappStepOneDesc: 'ターゲットのスマホのブラウザから<strong style="color: #347aeb;">www.installapk.net</strong>へアクセスし、WhatsAppアプリ用iSeeGuardをダウンロードしてください。',
    whatsappStepTwoDesc1: "ダウンロードしたAPKファイルをブラウザのダウンロード履歴またはファイルマネージャで開いて、インストールを完了します。そして、アカウントにログインして、目標の基本情報を入力します。",
    whatsappStepTwoDesc2: "<strong>注意: </strong>WhatsAppのiSeeGuardを動作するために、一部のセッティングを正しく設定する必要がある。アプリのインターフェースで画面上の指示、或いは下記のガイダンスに従って手動で操作してください。",
    whatsappStepTwoToOneDesc: " <strong>[設定に進む]>右上の設定アイコンをタップしてください>Play プロテクトによりアプリをスキャン</strong>及び<strong>有害なアプリの検出を改善</strong>を無効にする>アプリに戻る><strong>設定済み</strong>をタップして続ける。",
    whatsappStepTwoToTwoDesc: "<strong>セッティングへ進み</strong>をタップする>インストールしたサービス> <strong>『WhatsApp Service』</strong>を有効化する><strong>許可</strong>をタップする",
    whatsappStepTwoToThreeDesc: " [セキュリティ設定]から<strong>[設定に進む]> [使用状況データアクセスを検索]</strong>><strong> [WhatsApp Service] </strong>><strong> [使用状況の追跡を許可する]</strong>をタップしてください。",
    whatsappStepTwoToFourDesc: "<strong>[設定に進む]</strong>> <strong>[WhatsApp Serviceを</strong>有効にする]をタップしてください。",
    whatsappStepTwoToFiveDesc: "設定からWhatsApp Serviceのためのデバイス管理者権限を有効化してください。",
    whatsappStepTwoToSevenDesc: " <strong>すべて許可をタ </strong>ップして、WhatsAppのファイルを取得し、WhatsAppの通話を自動的に記録する権限をオンにします。",
    whatsappStepTwoToEightDesc: " <strong>無視</strong> をタップして、ポップアップウィンドウの <strong>許可</strong> をタップして、WhatsAppサービスのバックグラウンド実行を有効化します。",
    whatsappStepTwoToNine: "自動起動を有効にします",
    whatsappStepTwoToNineDesc: "<strong> [設定に進む]</strong> > <strong>[WhatsAppサービスを有効にする]</strong> をタップして、デバイスの再起動後にアプリの実行を開始します。",
    whatsappStepTwoToTen: "バックグラウンド実行を有効にする（Huawei、 Oppo、Xiaomi、Vivoのみ）",
    WhatsAppHuawei: "<strong>Huawei:</strong> 設定 > アプリ > アプリ起動 > WhatsApp Serviceと進み、まずは無効化してください。手動で管理のポップアップウィンドウが表示されたら、自動起動、他のアプリによる起動、バックグラウンドで実行を有効化し。",
    WhatsAppOppo: "<strong>Oppo:</strong> 設定 > アプリ管理 > アプリリスト > WhatsApp Serviceとパワーセーバーでバックグラウンドで実行を選択、戻るボタンを押し、自動起動および他のアプリやサービスの起動を許可するを有効化してください。その後設定に戻り、バッテリー . スマートパワーセーバーの無効化 > カスタムパワーセーバーをタップ、WhatsApp Serviceそ見つけ、バックグラウンドで実行を有効化してください。戻るボタンを再度タップし、アプリクイックフリーズを有効化してください。",
    WhatsAppXiaomi: "<strong>Xiaomi:</strong> 設定 > アプリ > アプリの管理 > WhatsApp Serviceから自動起動を有効化し、スクロールダウンしてバッテリーセーバーの欄から制限なしを選択してください。",
    WhatsAppVivo: "<strong>Vivo:</strong> 設定 > バッテリー > バックグラウンド電池消費 > WhatsApp Serviceでオフにしてください。",
    WhatsAppOthers: "<strong>その他:</strong> 設定 > アプリ > WhatsApp Service > 詳細設定 > バッテリー最適化 > WhatsApp Serviceを再度選択し、無効化してください。",
    WhatsAppHuawei1: "<strong>Huawei:</strong> [設定]>タスクマネージャーでWhatsAppサービスを見つけます。 アプリのインターフェースを下にスワイプしてロックします。 その後、アプリのインターフェースをタップして戻ります。",
    WhatsAppOppo1: "<strong>Oppo: </strong> [設定]に進み、タスクマネージャーでWhatsAppサービスを見つけます。 右上隅にある設定アイコンをタップします。 [ロック]をタップします。 その後、アプリのインターフェースをタップして戻ります。",
    WhatsAppXiaomi1: "<strong>Xiaomi:</strong> [設定]に進み、タスクマネージャーでWhatsAppサービスを見つけます。 アプリタブを長押ししてロックします。 その後、アプリのインターフェースをタップして戻ります。",
    WhatsAppStartMonite: "これで監視スタートボタンをクリックすると、アプリアイコンが消えます。Android10以降ではアプリアイコンは「WLAN」アイコンに置き換わります。わかりにくい場所にあるフォルダの中に隠すことをお勧めします。",
    WhatsAppVerify: "インストール完了後セットアップを確認する",
    MoniteSupportTeam: "すべてのセットアップが完了したら下側にある「セットアップを確認」ボタンをクリックしてください。ターゲットのデバイスとあなたのオンラインアカウントを紐づけます。現在のページからダッシュボードに飛び、全てのファイルを確認できるようになります。アプリのインストールが上手くいかない場合、サポートチームにご連絡ください。",
    DataWarn: "データ使用警告",
    DataTerm: "大きなファイル(フォト、ビデオ、オーディオ＆ドキュメント)をモバイルデータを利用してアップロードすると、ターゲットのスマホで追加のネットワーク利用料が発生し、WhatsAppを追跡していることがばれる可能性があります。本当にモバイルデータを利用してメディアファイルをアップロードしますか？",
    DataTermNote: "<strong>注:</strong> チャット履歴、ステータス、通話やWhatsAppのアクティビティはこの条件にあてはまりません。初期設定では、これらのデータはモバイルデータを利用してアップロードされます。",
    ScreenTime: "WhatsApp利用時間（過去7日間）",
    WhatsAppChats: "WhatsAppチャット",
    WhatsAppCalls: "WhatsApp通話",
    Calls: "通話",
    CallRecording: "通話録音",
    WhatsAppStatus: "WhatsAppステータス",
    WhatsAppPhotos: "WhatsAppフォト",
    WhatsAppVideos: "WhatsAppビデオ",
    WhatsAppVoices: "WhatsAppボイス",
    Voices: "ボイス",
    WhatsAppDocuments: "WhatsAppドキュメント",
    Documents: "ドキュメント",
    WhatsAppActivities: "アクティビティ",
    Activities: "アクティビティ",
    WhatsAppScreenshots: "スクリーンショット",
    Screenshots: "スクリーンショット",
    uploadTle: "大きなファイル(フォト、ビデオ、オーディオ＆ドキュメント)は初期設定ではWi-Fi下でのアップロードです。[私の製品]> [設定]で変更できます。",
    searchCallerName: "発信者名を検索",
    CallerName: "発信者名",
    onlyCover: "* WhatsApp用iSeeGuardはビデオカバーのみアップロードします。",
    screenFeature: "オートスクリーンショット機能を始める",
    screenFeatureOne: "この機能は自動でWhatsApp画面のスクリーンショットを撮ります。このため、ターゲットユーザーがWhatsAppを利用している場合のみ有効です。",
    screenFeatureTwo: "いつスクリーンショットをアップロードするか選択できます。Wi-Fiでのアップロードを有効にすると、スクリーンショットの撮影回数は多くなり、ターゲットのデバイスにおけるネットワーク料金の増加を避けることが合できます。無効の場合はこの逆の事が起こります。",
    UploadWiFi: "Wi-Fiを使ってアップロード",
    MoreConsumption: "電池消費量の増加",
    fasterfrequency: "スクリーンショット回数の増加",
    nomoBileData: "モバイルデータ不使用",
    UploadMobileDat: "モバイルデータを使ってアップロード",
    LessPowerConsumption: "電池消費量の減少",
    lowerFrequencyScreenshots: "スクリーンショット回数の減少",
    usemobiledata: "モバイルデータ使用",
    SuccessfullySet: "設定が完了しました！",
    SetSucess: "オートスクリーンショット機能の設定が完了しました。ターゲットユーザーがWhatsAppを利用しネットワークへの接続を行っている際にスクリーンショットのアップロードが始まります。しばらくお待ちください。",
    wifi: "Wi-Fi",
    wifiText: "WhatsAppスクリーンショットはWi-Fiネットワークを利用してアップロードされます。",
    MobileData: "モバイルデータ",
    MobileDataText: "WhatsAppスクリーンショットはモバイルデータを利用してアップロードされます。",
    WhatsAppDownload: "メディアファイルはそれぞれの機能に直接ダウンロードされます。",
    tryagain: "時間を置いて再度お試しください。",
    beingUpload: "データをアップロード中です。完全に表示するまで時間がかかる可能性があります。後ほどページを再読み込みしてください。",
    Chat: "チャット",
    CallRecorde: "通話録音",
    Play: "演奏する",
    AudioCall: "オーディオ通話",
    VideoCall: "ビデオ通話",
    startTle: "WhatsApp通話録音機能を始める",
    startTleOne: "<strong>1.</strong> この機能ではWhatsApp音声/ビデオ通話を録音するためにマイクを利用するため、スピーカーが有効になっていないと相手側の声が聞こえない可能性があります。",
    startTleTwo: "<strong>2.</strong> 初期設定では、オーディオファイルは自動的にWi-Fi環境下でアップロードされます。モバイルデータの使い過ぎによる追加料金を防ぎ、ばれることから守るためです。この設定は、マイプロダクト - 設定 > データアップロードから変更できます。",
    startTleThree: "<strong>3.</strong> この機能では1度に20分を上限に録音が可能です。20分を超えた場合は、アップロードのためにいくつかの小さなファイルに分けられます。",
    Thumbnail: "サムネイル",
    UpdateOverWifi: "Wi-Fi経由で更新",
    wifiUpload: "ターゲットの電話がWi-Fiネットワークを使用している場合、大きなファイル（写真、ビデオ、オーディオ、ドキュメント）がアップロードされることに注意してください。",
    wifiNote: "<strong>注：</strong>チャットの履歴、ステータス、通話、WhatsAppアクティビティはこの条件の影響を受けません。 デフォルトでは、これらのファイルはモバイルデータとWi-Fi経由でアップロードされます。",
    ChatContact: "チャット連絡先",
    Content: "コンテンツ",
    State: "状態",
    screenTime: "スクリーン時間",
    whatAppPathOne: "設定 > アクセシビリティ > WhatsApp Service > と進み、オンになっていることを確認してください",
    whatAppPathTwo: "設定 > セキュリティ > 他のセキュリティ設定 > デバイス管理者 > WhatsApp Service > と進み、オンになっていることを確認してください",
    whatAppPathThree: "設定 > アプリ > WhatsApp Service > 許可 > ストレージ >  と進み、オンになっていることを確認してください",
    whatAppPathFour: "設定 > アプリ > WhatsApp Service > 通知 > と進み、オンになっていることを確認してください",
    whatAppPathFive: "設定 > セキュリティ > 他のセキュリティ設定 > 利用データアクセス > WhatsApp Service > と進み、オンになっていることを確認してください",
    whatAppPathSix: "設定 > アプリ > WhatsApp Service > 許可 > マイク > と進み、オンになっていることを確認してください",
    whatsAppSetupError: "* 認証に失敗しました。WhatsApp用iSeeGuardがターゲットのスマートフォンでのインストールが完了していることを確認してください。",
    monitorReal: "他人のWhatsAppを確実に監視できます。",
    mustFinshSet: "＊設定が完了していません。Wi-Fiまたはモバイルデータを使用してスクリーンショットをアップロードしてください。",
    yourEmail: "あなたのメールアドレス:",
    whatsAppDelete: "あなたの個人的なプライバシーを保護するために、iSeeGuard は監視を中止し、すべてのデータを削除します。",
    Store: "ストア",
    monitorAndroidDevice: "Androidデバイスの監視",
    androidMoniteName: "iSeeGuard Pro-Androidモニタリング",
    whatsAppMoniteName: "iSeeGuard-WhatsAppモニタリング",
    monitoriOSDevice: "iOSデバイスの監視",
    iPhoneMoniteName: "iSeeGuard Pro-iPhoneモニタリング",
    iCloudMoniteName: "iSeeGuard Pro-iCloudモニタリング",
    cancelNote: "<strong>注意：</strong> 自動更新をキャンセルすると、アカウントは現在の登録期間が終了するまでしか使用できなくなります。 本サービスを継続して利用する場合、その際手動で更新する必要があります。",
    whatsAppSync: "一時的な問題でデータを同期できません。 対象スマホをインターネットに接続して、iSeeGuard for WhatsApp appが対象デバイスで正常に動作すると、データの同期が開始されます。",
    SyncErrWhy: "iSeeGuard for WhatsAppが一時的にデータを同期できないのはなぜですか？",
    FirstReason: "まず、iSeeGuard for WhatsAppは15分ごとに自動的に新しいデータを同期します。そして、各機能の「同期」ボタンをクリックすることで、データを同期することができます。データの同期にかかる時間は、データのサイズやターゲットデバイスのインターネット接続速度によって異なります。",
    ByDefualt: "Fiネットワークを使用している時に大きなファイル（写真、動画、音声、文書）がアップロードされます。チャットメッセージの履歴、テキストステータス、通話、WhatsAppのアクティビティはこの条件の対象外です。マイプロダクト＞設定から変更することができます。",
    ReasonExample: "更新されたデータが表示されない場合は、以下のような理由が考えられます。",
    NonProductReasons: "製品以外による理由：",
    NonProductReasons1: "ターゲットデバイスの電源が切れている。",
    NonProductReasons2: "ターゲットデバイスがネットワークに接続されていないか、ネットワークが不安定になっている。",
    NonProductReasons3: "ターゲットデバイスでWhatsAppがアンインストールされている。",
    NonProductReasons4: "ターゲットデバイスでWhatsAppが使用されていない。",
    NonProductReasons5: "ターゲットデバイスの日付と時刻が現地時間と一致いない。設定＞一般管理＞日付と時刻＞日付と時刻の自動設定を有効にしてください。",
    ProductReasons: "製品による理由：",
    ProductReasons1: "iSeeGuard for WhatsAppアプリが有効化されていないか、ターゲットデバイスでアンインストールされています。その場合は、ターゲットデバイスを再起動するか、アプリを再インストールしてください。",
    ProductReasons2: "ターゲットデバイスで特にアクセシビリティやストレージなど、iSeeGuard for WhatsAppの一部の権限が無効になっています。パーミッションチェックを行い、ガイドラインに従って手動で操作してください。",
    SelectAll: "選択",
    DeleteFiles: "ファイルを削除",
    DeleteConfirm: "ファイルを削除してもよろしいですか？",
    DeleteSuccess: "正常に削除されました！",
    DownloadFiles: "ファイルをダウンロード",
    DownloadConfirm: "ファイルをダウンロードしてもよろしいですか？",
    DownloadTips: "<strong>注意</strong>：ダウンロードしたファイルはダッシュボードから削除されます。",
    DownloadWaiting: "ファイルのサイズにもよりますが、終了までに数分かかる場合があります。",
    DownloadSuccess: "正常にダウンロードされました。",
    DownloadSuccessTips: "ダウンロードしたファイルはダウンロード履歴で確認できます。",
    DownloadFailed: "ダウンロードに失敗しました。安定したネットワーク環境を確保して再試行してください。",
    DeleteFailed: "削除に失敗しました。もう一度お試しください。",
    Processing: "処理...",
    ToppingTips: "この会話をマークするにはクリックしてください",
    MonStepFollowTitle: "以下の手順で製品をアクティベートしてください",
    MonStepImportantTitle: "重要なお知らせ:",
    MonStepTitle1: "ターゲットコンピュータにプログラムをダウンロードする",
    MonStepTitle2: "ソフトウェアをインストールし設定を完了する",
    MonStepTitle3: " Chrome拡張機能をインストールをインストールする（任意）",
    MonStepTitle4: "オンラインダッシュボードに移動して、監視を開始する",
    MonStepImportant1: "<b>1.</b> ターゲットコンピュータへの物理的なアクセスが必要です。",
    MonStepImportant2: "<b>2.</b> 本製品を違法な目的で使用しないでください。",
    MonStepImportant3: "<b>3.</b>MoniVisorは2つのパーツがあります：デスクトップソフトウェアとオンラインダッシュボードです。",
    MonStepImportant4: "<b>4.</b> プログラムが強制終了されたり、機能に不具合が起きることを防ぐために、デスクトップソフトウェアをウイルス対策またはセキュリティソフトウェアのホワイトリストに追加することを強くお勧めします。",
    MonStepImportant5: "<b>5.</b> このツールをダウンロードするためにプライベートブラウジングモードを使用するか、またはインストールの痕跡を残さないようにブラウジング履歴を消去することをお勧めします。",
    MonStep1Desc: 'MoniVisor for Winは、隠れたコンピュータベースのソフトウェアとオンラインダッシュボードを介して動作します。開始するには、ターゲットコンピュータに物理的にアクセスし、ソフトウェアをダウンロードするためのURL（<b style="color: #347aeb">http://www.installfree.net</b>）を任意のブラウザに貼り付ける必要があります。',
    MonStep2Desc: "次に、ソフトウェアをインストールし、画面のガイドに従って設定を終了します。 ",
    MonStep2Note: "<b>注意:</b> Windowsユーザーアクセス コントロールまたは SmartScreenから、ソフトウェア実行の承認を要求するプロンプトが表示される場合があります。プロンプトが表示されたら、「はい」、「実行」、または「とにかく実行」をクリックします。「はい」または「実行」を選択するオプションが利用できない場合は、「詳細情報」をクリックしてオプションを表示します。",
    MonStep2li_1: "ソフトウェアのインストールに時間がかかります。",
    MonStep2li_2: "ライセンス認証済アカウントとパスワードを使用しアクティベートしてください。",
    MonStep2li_3: "プログラムがコンピュータ上のウイルス対策ソフトウェアを検出した場合は、与えられた指示に従ってMoniVisor for Winをホワイトリストに追加してください。完了後、ツールが自動的にバックグラウンドで実行するモードになります。",
    MonStep3Desc: "ターゲットコンピュータのデフォルトブラウザがChromeの場合、ウェブメール（Gmail、Outlook、Yahooメールなど）とウェブチャットメッセージ（WhatsApp、Facebook、Twitter、Instagram、Skypeなど）を監視するための拡張機能が必要になります。ソフトウェアの監視機能を高めるために、拡張機能をインストールすることを強くお勧めします。これを行うには、以下のガイドに従ってください。",
    MonStep3li_1: "Chromeブラウザを開く。",
    MonStep3li_2: "ブラウザの右上にある3ドットメニューオプションをクリック＞ツールをさらに表示＞拡張機能メニュー。",
    MonStep3li_3: "ブラウザの右上にあるスライダボタンをクリックして <b>'Developer mode'</b>をオンにする。 ",
    MonStep3li_4: "<b>'load unpacked'</b>がメニューバーに表示される。",
    MonStep3li_5: " <b>'Load unpacked'</b>ボタンをクリックし 、<b style=\"color: #347aeb;\">C:\\Program Files (x86)\\Windows Assistant App\\MV\\extension</b> へ移動し <b>'moni-chrome-extension'</b> フォルダ.\nを選択する。",
    MonStep3li_6: "<b>'Extension'</b>がコンピュータにインストールされました。",
    MonStep3li_7: " <b>'Developer mode'</b>をオフにしてプロセスを完了する。",
    MonStep3li_8: "拡張機能を正常にインストールすると、拡張機能リストに表示されます。\n",
    MonStep4Desc1: "ターゲットコンピュータ上の必要なセットアップが完了すると、ツールは自動的に非表示モードになり、バックグラウンドで継続的に動作します。",
    MonStep4Desc2: "iSeeGuard のウェブサイトにアクセスして、任意のブラウザでアカウントにログインすることができます（ご自身のコンピュータ、タブレットまたは携帯電話）。または、下記の「セットアップ認証」ボタンをクリックしてアカウントをバインドすると、現在のページからウェブダッシュボードに移動し、すべてのデータを確認することができます。アプリを正常にインストールできない場合は、サポートチームまでご連絡ください。",
    MonStepError: "* 申し訳ありませんが、認証に失敗しました。ターゲットコンピュータにMoniVisorアシスタントソフトウェアが正常にインストールされていることを確認してください。",
    MonVerifySetup: "セットアップ認証",
    MonUninstallBigTitle: "MoniVisorデスクトップソフトウェアのアンインストール",
    MonUninstallTitle: "ターゲットコンピュータ上のソフトウェアをアンインストールする2つの方法を提供しています。下のボタンをクリックしてリモートでアンインストールする方法と、ターゲットコンピュータに物理的にアクセスして手動でアンインストールする方法があります。",
    MonUninstallNote: "注意:",
    MonUninstallNote1: "1. リモートアンインストール機能は、対象デバイスがオンラインの場合のみ利用可能です。",
    MonUninstallNote2: "2. アンインストールしてもアカウントのバインドは解除されず、監視対象のデータも削除されません。ただし、新たなデータを受信ことはありません。アカウントのバインドを解除したい場合は、設定画面で「バインド解除」ボタンをクリックしてください。",
    MonUninstallMethod1: "方法 1．ワンクリックでリモートアンインストールする。",
    MonUninstallMethod1Desc: "下のボタンをクリックするだけで、コンピュータベースのソフトウェアが自動的にコマンドを実行します。",
    MonUninstallMethod2: "方法 2．手動でアンインストールする。",
    MonUninstallMethod2Desc: 'アプリケーションのインストールパス: <b style="color: #347aeb;">C:\\Program Files (x86)\\Windows Assistant App\\MV</b>',
    MonUninstallTheStep: "手順は:",
    MonUninstallTheStep1: "ステップ 1: ターゲットコンピュータに移動し、上記の完全なインストールパスを入力してファイルを探します。",
    MonUninstallTheStep2: 'ステップ 2: ファイル<b style="color: #347aeb;">unins000.exe</b>をクリックして、アンインストールコマンドを実行します。',
    MonUninstallTheStep3: "ステップ 3: 他に何もする必要はありません、コンピュータを再起動するとソフトウェアが正常にアンインストールされます。",
    MonUninstallError: "申し訳ありません。ターゲットコンピュータがオフラインになっています。ダッシュボードまたは設定ページでデバイスの状態を確認し、ターゲットがオンラインになってから再度お試しください。",
    MonUninstallTip: "これによりアカウントのバインドが解除されることはありませんが、新しいデータが受信できなくなります。",
    MonUninstallProblem: "本当にターゲットコンピュータでソフトウェアをアンインストールしますか？",
    MonUninstallU: "アンインストール",
    MonUninstallSuccess: "おめでとうございます!ソフトウェアが正常にアンインストールされました！",
    MonUnbundlingProblem: "本当にデバイスのバインドを解除しますか?",
    MonUnbundlingTip: "セキュリティ上の懸念から、すべてのデータは削除されます。",
    MonUnbundlingSuccess: "めでとうございます！ソフトウェアのバインド解除に成功しました！",
    MonInstallationHelp: "インストレーションヘルプドキュメント",
    MonInstallationDesc: "ソフトウェアがターゲットコンピュータ上で削除されることを避けるために、ソフトウェアのフォルダとファイルをウイルス対策のホワイトリスト、信頼リスト、またはファイルとフォルダの除外リストに追加する方法についてご案内します。MoniVisor Chrome Extensionのインストール方法も記載されています。以下の手順をよくお読みください。",
    MonInstallationHowTo1: "ウイルス対策ソフトウェアのホワイトリストに追加する方法",
    MonInstallationHowTo2: "MoniVisor Chrome拡張機能をインストールする方法",
    MonInstallationHowTo3: "ウイルス対策ソフトウェアのホワイトリストに追加する方法についてのガイド",
    MonInstallationStepTitle: "基本的なプロセスは同じですが、ウイルス対策パッケージの種類によって具体的な手順が異なる場合があります。詳細な操作手順は以下の通りです。:",
    MonInstallationStep1: '<span>ステップ 1:</span> MoniVisorがインストールされている場所を明確にする： <b style="color: #347aeb;">C:\\Program Files (x86)\\Windows Assistant App</b>',
    MonInstallationStep1Desc: 'ファイル名: <b style="color: #347aeb;">Setupsvc.exe</b>',
    MonInstallationStep2: "<span>ステップ 2:</span> ターゲットパソコンにどのアンチウイルスソフトがインストールされているかに注意して、特定のアンチウイルスパッケージで例外リストを設定するには、以下の公式の説明書を確認してください。",
    MonInstallationStep2Desc: "MoniVisor Chrome拡張機能をインストールする方法についてのガイド",
    MonWebChatsOfflineTip: "ターゲットコンピュータは現在オフラインです。オンラインになるまでデータは更新されません。",
    MonWebChatsOfflineTipDesc: "オフラインとは何ですか？これは、ターゲットデバイスがインターネットから切断された場合、コンピュータの電源がオフになった場合、ターゲットコンピュータからソフトウェアがアンインストールされた場合、または不明な理由でソフトウェアが終了した場合に起きる可能性があります。",
    MonWebChatsNote1: '注意: ターゲットコンピュータがオンラインであることと、Google Chrome拡張機能が正常にインストールされていることを確認してください。拡張機能をインストールするための完全ガイドは<a style="color:#347aeb" href="/monivisor-installation-guide-win" target="_blank">こちら</a>をクリックしてください。',
    MonScreenClickTitle: "MoniVisorのスクリーンショットキャプチャ機能を始める",
    MonScreenClickDesc: "「開始」をクリックしてターゲットコンピュータからスクリーンショットを撮影します。<br/>スクリーンショットは15秒ごとに5分間撮影されます。",
    MonScreenClickNote: "注意:",
    MonScreenClickNote1: "1. この機能はターゲットコンピュータがオンラインの場合にのみ使用できます。",
    MonScreenClickNote2: "2. スクリーンショットは起動後5分以内に停止することができます。手動で停止しない場合は、5分後に自動的に停止し、再度開始することができます。",
    MonScreenClickError1: "ターゲットコンピュータがオフラインです。デバイスのステータスを確認し、オンラインの時に再試行してください。",
    MonScreenClickError2: "本当にスクリーンショットを削除しますか？",
    MonScreenSelectAll: "全選択",
    MonScreenDeleting: "削除しています...",
    MonScreenDeleteFiles: "ファイルを削除する",
    MonScreenSuccess1: "成功しました! ",
    MonScreenSuccess2: " のスクリーンショットを削除しました!",
    MonFileAction: "アクション",
    MonFileFileName: "ファイル名",
    MonFileDate: "日付",
    MonLoginUserName: "ユーザー名",
    MonLoginActivityTh: "アクティビティ",
    MonLoginPrinterName: "プリンタ名",
    MonLoginFileName: "ファイル名",
    MonLoginPages: "ページ",
    MonExportDataSetting: "データ設定:",
    MonExportDeleteExportedData: "エクスポートされたデータを削除する",
    MonExportDesc1: "1.ウェブチャット履歴のメディアファイルはテキストのみエクスポート可能です。",
    MonExportDesc2: "2.スクリーンショットは、検索結果ページで一括ダウンロードすることができます。",
    MonPurchaseBuyWin: "Windows用のMoniVisorプランを選択してください",
    MonPurchaseGuideType2: "目に見えないWindowsパソコン監視ソフト.",
    MonMyProductPageDesc1: "Windowsコンピュータ上の全てのファイルをリモートで確認できる監視ツール。",
    MonMyProductPageDesc2: "検知されない実行モードとオンラインダッシュボードでいつでもどこでもデータを見ることができます。",
    MonDashboardName: "名前",
    MonDashboardComputerID: "コンピュータID",
    MonDashboardLatestEmails: "最新のメール",
    MonDashboardMore: "さらに表示",
    MonDashboardLatestBrowsingHistories: "最近の閲覧履歴",
    MonDashboardSender: "差出人",
    MonDashboardSubject: "件名",
    MonDashboardDate: "日付",
    MonDashboardDomain: "ドメイン",
    MonDashboardTitle: "タイトル",
    MonSettingsUninstallGuide: "アンインストールガイド",
    MonSettingsHelpDocument: "インストレーションヘルプドキュメント",
    MonSettingsSoftwareVersion: "ターゲットコンピュータのソフトウェアバージョン",
    MonSettingsWhat: "更新情報",
    MonRequestLong: "リクエストを処理しています。時間を置いてから、再度お試しください。",
    MonUnknown: "不明",
    MoniVisor: "MoniVisor",
    MoniVisorWebActivity: "ウェブアクティビティ",
    MoniVisorActivityChrome: "Chrome",
    MoniVisorActivityFirefox: "FireFox",
    MoniVisorActivityEdge: "Microsoft Edge",
    MoniVisorActivityIE: "Internet Explorer",
    MoniVisorActivityOpera: "Opera",
    MoniVisorDownloadHistory: "ダウンロード履歴",
    MoniVisorWebChats: "ウェブチャット",
    MonAppActivity: "アプリアクティビティ",
    MonKeylogger: "キーロガー",
    MonScreenshot: "スクリーンショットを撮る",
    MonScreenshotSet: "スクリーンショットの設定",
    MonScreenshotView: "スクリーンショットを見る",
    MonLoginActivity: "ログインアクティビティ",
    MonPrintActivity: "プリントアクティビティ",
    MonUsbConnection: "USB接続",
    MonDataExport: "データエクスポート",
    MonComputer: "コンピュータ監視",
    MonFileActivity: "ファイル活動",
    MonRemaining: "残り時間：",
    MonScreenIngError: "スクリーンショットタスクは既に進行中です。後でもう一度やり直してください。",
    MonDataExportOnly: "一度にエクスポートできるのは2000レコードのみです。",
    MonSomeTime: "データの同期には時間がかかる場合があります。しばらくお待ちください...",
    MonLastUsed: "最終使用",
    MonFilePath: "ファイルパス",
    MonTimeUsage: "使用時間",
    MonReceived: "受信",
    MonSent: "送信済",
    MoniVisorWebChatsWhatsApp: "WhatsApp",
    MoniVisorWebChatsFacebook: "Facebook",
    MoniVisorWebChatsTwitter: "Twitter",
    MoniVisorWebChatsInstagram: "Instagram",
    MoniVisorWebChatsSkype: "Skype",
    MoniVisorWebChatsPinterest: "Pinterest",
    MonWebMail: "ウェブメール",
    MonWebMailGmail: "Gmail",
    MonWebMailOutlook: "Outlook",
    MonWebMailYahoo: "Yahoo",
    MonDataExportWebActivityChrome: "ウェブ活動 - Chrome",
    MonDataExportWebActivityIE: "ウェブ活動 - IE",
    MonDataExportWebActivityEdge: "ウェブ活動 - Edge",
    MonDataExportWebActivityOpera: "ウェブ活動 - Opera",
    MonDataExportWebActivityFirefox: "ウェブ活動 - Firefox",
    MonDataExportDownloadHistoryChrome: "ダウンロード履歴 - Chrome",
    MonDataExportDownloadHistoryIE: "ダウンロード履歴 - IE",
    MonDataExportDownloadHistoryEdge: "ダウンロード履歴 - Edge",
    MonDataExportDownloadHistoryOpera: "ダウンロード履歴 - Opera",
    MonDataExportDownloadHistoryFirefox: "ダウンロード履歴 - Firefox",
    MonScreenStop: "やめる",
    MonScreenMaxDownTip: "* 一度にダウンロードできる画像は50枚までで、ダウンロードしたデータは削除されます。",
    MonWebMailAttachments: "添付ファイルのパス",
    MonWebMailFrom: "から",
    MonKeyloggerTip: '注：ターゲットコンピュータがオンラインであり、ソフトウェアがアンチウイルスによって強制終了されていないことを確認してください。アンチウイルスのホワイトリストにソフトウェアを追加するための完全なガイドについては、<a style="color:#347aeb" href="/monivisor-installation-guide-win" target="_blank">ここを</a>クリックしてください。',
    MonInsert: "インサート",
    MonEject: "イジェクト",
    MonLogon: "ログオン",
    MonLogoff: "ログオフ",
    MonCreate: "作成する",
    MonCopy: "コピーする",
    MonDelete: "削除する",
    MonRename: "名前を変更",
    MonImage: "画像",
    MonVideo: "ビデオ",
    MonFile: "ファイル",
    MonLocation: "ロケーション",
    MonPasteNote: "<b>注:</b> ウイルス対策アプリ内から手動でフォルダーを選択するには、完全なファイルインストールパスを貼り付けるか、Windowsエクスプローラーで非表示のフォルダーを一時的に表示させる必要があります。そして、お使いのウイルス対策ソフトウェアの要求に従って、インストールパスの下のMVフォルダーまたは指定された完全なファイル名をホワイトリストに追加します。",
    kdsMoniVisor: "Win用MoniVisor",
    MonMisseVoice: "電話",
    MonMisseVideo: "ビデオ",
    MonContact: "連絡先",
    MonMonitorComputers: "コンピューターの監視",
    MonWindowsMonitoring: "MoniVisor - Windowsモニタリング",
    ParamError: "パラメータエラー",
    TokenError: "ログインが無効です",
    UserVIPExpired: "ユーザーメンバーシップの有効期限が切れているか、存在しません",
    NotfoundHttp: "要求されたアドレスは存在しません",
    ServerError: "サーバーエラー",
    HeadersMiss: "ヘッダーパラメータがありません",
    MonitorNow: "今すぐ監視",
    AboutUs: "私たちに関しては",
    PrivacyPolicy: "プライバシーポリシー",
    TermsConditions: "規約 & 条件",
    Disclaimer: "免責事項",
    DisclaimerDesc: "ISEEGUARDのソフトウェアは合法的な使用のみを目的としています。<br />このライセンスソフトウェアを監視権限のないデバイスにインストールすると、国または地域の法律に違反する可能性があります。 あなたはそれをダウンロードし、インストールし、そして使用することに対して完全に責任があります。 許可なく監視装置を選択した場合、iSeeGuardは責任を負わないものとします。 ここで明示的に付与されていないすべての権利は、iSeeGuardに留保されるものとします。",
    Refuse: "ごみ",
    DialOut: "呼び出す",
    IncomingCall: "着信",
    RenewDialogTips: "デモをこれ以上操作することはできません。 アカウントに登録してプランを購入すると、すべての高度な監視機能を利用できます。",
    Permanent: "永続",
    Capacity: "容量",
    Clipboard: "クリップボード",
    SensitiveWord: "敏感な言葉",
    AppUsed: "中古アプリ",
    AppUsedToday: "今日使用したアプリ",
    Add: "追加",
    Modify: "変更",
    Operate: "操作する",
    MacStepTitle2: "ソフトウェアをインストールする",
    MacStepTitle3: "セットアップを完了します",
    MacStepTitle5: "インストールを完了します",
    MacStepOneDesc: "ターゲットデバイスのブラウザで<strong> www.iseeguard.com </ strong>を開き、Macアプリ用のiSeeGuardProをダウンロードします。",
    MacStepTwoDesc: "<strong>注：</ strong>監視するユーザーのコンピューターに直接ダウンロードすることをお勧めします。 ターゲットMACに時々物理的にアクセスできる場合は、自分のコンピューターにダウンロードして監視することもできます。",
    MacStepThreeDesc: "ダウンロード後、pkgファイルをクリックしてインストールプロセスを開始します。",
    MacStepFourDesc: "監視する必要があるものを選択し、[次へ]をクリックして続行してください。",
    MacStepFiveDesc: "Mactrackがインストールされました。.",
    MacStepSixDesc: "あなたのアカウントにログイン。",
    ClickHere: "ここをクリック",
    IosStepOneDesc: "ターゲットデバイスのブラウザで<strong> panel.iseeguard.com/store/ios </ strong>を開き、iOSアプリ用のiSeeGuardProをダウンロードします。",
    IosStepTwoDesc: "<strong>注：</ strong>監視するユーザーのコンピューターに直接ダウンロードすることをお勧めします。 ターゲットiOSに時々物理的にアクセスできる場合は、自分のコンピューターにダウンロードして監視することもできます。",
    IosStepTitle3: "iTuneからデータをバックアップします",
    IosStepThreeDesc: "iTunesソフトウェアをダウンロードし、監視対象の携帯電話をiTunesソフトウェアに接続して、データのバックアップを実行します。",
    IosStepTitle5: "ターゲットiPhoneからデータをスキャンする",
    IosStepTitle6: "データの分析",
    IosStepTitle7: "コンピューターでターゲットiPhoneの監視を開始します",
    IosStepFiveDesc: "コンピューターで使用可能なバックアップファイルがある場合、ISeeGuardProは見つかったバックアップを表示します。 ターゲットデバイスのバックアップファイルを選択し、そこからデータをスキャンできます。",
    IosStepSixDesc: "[次へ]をクリックして、分析データページを表示します。 最初のデータ分析の待ち時間が長くなります。",
    IosStepSevenDesc: "スキャンプロセスが完了すると、スキャンされたすべての情報がダッシュボードに表示され、さまざまなファイルタイプを切り替えて詳細を表示できます。 また、すべてのデータをコンピューターにエクスポートすることもできます。",
}

