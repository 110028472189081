import { getUserAccountOrderInfo, getMostFrequentlyContactedContacts } from "@/api/android/dashboard"
import { getMostRecentLocation } from "@/api/android/location"
import router from "@/router"
import { getCurrentDate } from "@/utils/common"
import i18n from "@/lang"
export default {
  namespaced: true,
  state: {
    showMap: false,
    loading: false,
    // 最后同步时间
    synchronisedTime: '',
    // 账户与订单信息
    accountOrderInfo: {
      email: '', // 账户邮箱
      noticeEmail: '', // 通知邮箱
      payType: '', // 付费类型
      expirationDate: '', // 到期时间
      isAutomaticSubscription: 0// 是否自动订阅
    },
    // 装置信息
    equipmentInfo: {
      name: '', // 设备名称
      model: '', // 设备型号
      systemVersion: '', // 操作系统版本
      status: 0, // 设备状态
      batteryPower: 0, // 电池电量
      gpsStatus: 0, // gps状态
      wifiStatus: 0// wifi状态
    },
    // 最常联系的通话对象列表
    mostFrequentlyContactedCallPartner: [],
    // 最常联系的信息对象列表
    mostFrequentlyContactedSmsPartner: [],
    // 最后的位置信息
    lastPosition: {
      longitude: '', // 经度
      latitude: '',// 纬度
      locationTime:'', //
      placeName:''
    }
  },
  mutations: {
    SET_SHOW_MAP(state, boolean){
      state.showMap = boolean
    },
    /**
         * 更新最后同步时间
         * @param {*} state
         * @param {synchronisedTime} payload
         */
    setSynchronisedTime(state) {
      state.synchronisedTime = getCurrentDate()
      // Vue.set(state, 'synchronisedTime', getCurrentDate());
    },
    /**
         * 更新账户与订单信息
         * @param {*} state
         * @param {email,noticeEmail,payType,expirationDate,isAutomaticSubscription} payload
         */
    setAccountOrderInfo(state, payload) {
      const { email, noticeEmail, payType, expirationDate, isAutomaticSubscription } = payload
      state.accountOrderInfo = {
        email: email,
        noticeEmail: noticeEmail,
        payType: payType,
        expirationDate: expirationDate,
        isAutomaticSubscription: isAutomaticSubscription
      }
      // Vue.set(state, 'accountOrderInfo', {
      //   email: email,
      //   noticeEmail: noticeEmail,
      //   payType: payType,
      //   expirationDate: expirationDate,
      //   isAutomaticSubscription: isAutomaticSubscription
      // })
    },
    /**
         * 更新装置信息
         * @param {*} state
         * @param {name,model,systemVersion,status,batteryPower,gpsStatus,wifiStatus} payload
         */
    setEquipmentInfo(state, payload) {
      const { name, model, systemVersion, status, batteryPower, gpsStatus, wifiStatus } = payload
      state.equipmentInfo = {
        name: name,
        model: model,
        systemVersion: systemVersion,
        status: status,
        batteryPower: batteryPower,
        gpsStatus: gpsStatus,
        wifiStatus: wifiStatus
      }
      // Vue.set(state, "equipmentInfo", {
      //   name: name,
      //   model: model,
      //   systemVersion: systemVersion,
      //   status: status,
      //   batteryPower: batteryPower,
      //   gpsStatus: gpsStatus,
      //   wifiStatus: wifiStatus
      // })

    },
    /**
         * 更新最常联系的通话对象列表
         * @param {*} state
         * @param {data} payload
         */
    setMostFrequentlyContactedCallPartner(state, data) {
      // Vue.set(state, 'mostFrequentlyContactedCallPartner', data)
      state.mostFrequentlyContactedCallPartner = data
    },
    /**
         * 更新最常联系的信息对象列表
         * @param {*} state
         * @param {data} payload
         */
    setMostFrequentlyContactedSmsPartner(state, data) {
      state.mostFrequentlyContactedSmsPartner = data
      // Vue.set(state, 'mostFrequentlyContactedSmsPartner', data)
    },
    /**
         * 更新最后的位置信息
         * @param {*} state
         * @param {longitude,latitude} payload
         */
    setLastPosition(state, payload) {
      state.lastPosition.longitude = payload.longitude
      state.lastPosition.latitude = payload.latitude
      state.lastPosition.locationTime = payload.location_time
      state.lastPosition.placeName = payload.place_name

    },
    setLoading(state, loading) {
      state.loading = loading
      // Vue.set(state, 'loading', loading)
    }
  },
  getters: {
    getShowMap: state => {
      return state.showMap
    },
    getLoading: state => {
      return state.loading
    },
    getSynchronisedTime: state => {
      return state.synchronisedTime
    },
    /**
     * 获取账户与订单信息
     * @param {*} state 
     * @returns 
     */
    getAccountOrderInfo: state => {
      console.log('getAccountOrderInfo', state);
      const data = {
        email: state.accountOrderInfo.email, // 账户邮箱
        noticeEmail: state.accountOrderInfo.noticeEmail, // 通知邮箱
        payType: state.accountOrderInfo.payType, // 付费类型
        expirationDate: state.accountOrderInfo.expirationDate, // 到期时间
        isAutomaticSubscription: state.accountOrderInfo.isAutomaticSubscription === 0 ? '否' : '是'// 是否自动订阅
      };
      return data
    },
    /**
     * 获取装置信息
     * @param {*} state 
     */
    getEquipmentInfo: state => {
      const data = {
        name: state.equipmentInfo.name, // 设备名称
        model: state.equipmentInfo.model, // 设备型号
        systemVersion: state.equipmentInfo.systemVersion, // 操作系统版本
        status: state.equipmentInfo.status === 0 ? i18n.t("Offline") : i18n.t("Online"), // 设备状态
        batteryPower: state.equipmentInfo.batteryPower + '%', // 电池电量
        gpsStatus: state.equipmentInfo.gpsStatus === 0 ? i18n.t("On") : i18n.t("Off"), // gps状态
        wifiStatus: state.equipmentInfo.wifiStatus === 0 ? i18n.t("On") : i18n.t("Off")// wifi状态
      }
      return data;
    },
    /**
     * 获取最常联系的短信联系人
     * @param {*} state 
     */
    getMostFrequentlyContactedSmsPartner: state => {
      return state.mostFrequentlyContactedSmsPartner
    },
    /**
     * 获取最常联系的通话联系人
     * @param {*} state 
     * @returns 
     */
    getMostFrequentlyContactedCallPartner: state => {
      return state.mostFrequentlyContactedCallPartner
    },
    /**
     * 获取最后的位置信息
     * @param {*} state 
     * @returns 
     */
    getLastPosition: state => {
      return state.lastPosition
    }
  },
  actions: {
    setShowMap({ commit }, data) {
      return new Promise(resolve => {
          commit('SET_SHOW_MAP', data);
          resolve();
      })
    },
    /**
     * 获取指定设备信息
     * @param {*} param0 
     */
    async requestData({ commit }) {
      const result = await getUserAccountOrderInfo(router.app._route.params.equipmentId)
      const { data } = result
      const { users, user_products, equipment_device_information } = data
      const accountOrderInfo = {
        email: users.email,
        noticeEmail: users.notice_email === null ? '---' : users.notice_email,
        payType: user_products.product_price_versions.version_name,
        expirationDate: user_products.is_permanent === 1 ? i18n.t("Permanent") : user_products.expiration_date
      }
      commit('setAccountOrderInfo', accountOrderInfo)
      const equipmentInfo = {
        name: data.model,
        model: data.name,
        systemVersion: data.system_version,
        status: data.status,
        batteryPower: equipment_device_information.battery_power,
        gpsStatus: equipment_device_information.gps_status,
        wifiStatus: equipment_device_information.wifi_status
      }
      commit('setEquipmentInfo', equipmentInfo)
    },
    /**
     * 获取最常联系人
     * @param {*} param0 
     */
    async requestMostFrequentlyContactedContacts({ commit }) {
      const result = await getMostFrequentlyContactedContacts(router.app._route.params.equipmentId)
      const { data } = result;
      const { callRecord, sms } = data;
      commit('setMostFrequentlyContactedCallPartner', callRecord);
      commit('setMostFrequentlyContactedSmsPartner', sms);
    },
    /**
     * 获取设备最近一次的位置信息
     * @param {*} param0 
     */
    async requestMostRecentLocation({ commit }) {
      const result = await getMostRecentLocation(router.app._route.params.equipmentId)
      const { data } = result;
      commit("setLastPosition",data)
    }
  }
}
