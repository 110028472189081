export default {
    CreateAccount: "建立帳號",
    Email: "郵件地址",
    Password: "密碼",
    ConfirmPas: "確認密碼",
    SignUp: "註冊",
    AleadyAccount: "已有帳號？",
    Login: "登入",
    Required: "這是必填欄",
    NotEmail: "電子郵件地址格式不符",
    TwoPass: "您輸入了兩個不同的密碼",
    SixOne: "密碼長度必須介於6-16個字元之間",
    Alphabets: "密碼必須含有數字跟字母",
    Space: "密碼不能含有空格",
    Symbols: "密碼只能含有數字、字母以及符號",
    SignLoad: "註冊中",
    AgainTry: "註冊失敗。請重新再試。",
    EmailExists: "電子郵件地址已存在",
    SupportToMonitor: "支持監控:",
    SignIn: "登入",
    IncorrectOr: "帳號或密碼錯誤",
    Forpwd: "忘記密碼？",
    Signtry: "登入失敗。請重新再試。",
    Restpwd: "重設密碼",
    SendLink: "發送重設密碼的鏈接",
    ReturnTo: "返回",
    DontExit: "電子郵件地址不存在",
    CheckEmail: "請檢查您的電子郵件",
    JustFold: "我們已傳送重設密碼的連結至您的電子郵件地址。如果您在收件匣找不到這封電子郵件，請查收您的垃圾郵件。",
    TwoHour: '如果您在接下來的24小時內未收到該電子郵件，請通過<a href="mailto:support@iSeeGuard.com">support@iSeeGuard.com</a>與我們的支持團隊聯繫。',
    UnknowError: "不買錯誤",
    TryOp: "哎呀。剛才出了點問題。請重新再試。",
    Retry: "重試",
    CreatNewPwd: "建立新密碼",
    NewPwd: "新密碼",
    Confirm: "確認",
    OldPwd: "您輸入了舊的密碼。請換一個再試。",
    NewPwdSucess: "已成功建立了新密碼！",
    ThePage: "這個頁面將在5秒後返回至登入頁面。",
    ProceedNow: "立即前往",
    UnknowTry: "不明錯誤。請重試。",
    LinkExpired: "鏈接已失效。請重試。",
    ProfileSett: "賬號設定",
    Download: "將iSeeGuard Pro 應用程式下載到目標裝置上",
    Back: "返回",
    Next: "繼續",
    Dashboard: "儀表板",
    PhoneLogs: "本機文件",
    CallLogs: "通話記錄",
    Messages: "訊息",
    Contacts: "聯絡人",
    Locations: "位置",
    Geofence: "地理圍欄",
    BrowserHistory: "瀏覽記錄",
    Photos: "相片",
    VideoPreview: "視頻預覽",
    CaptureScreenshots: "截圖捕獲",
    AppActivity: "應用程式",
    WiFiLogger: "Wi-Fi日誌",
    Keylogger: "鍵盤記錄",
    Calendar: "行事曆",
    SocialApps: "社交應用",
    DataExport: "數據導出",
    Settings: "設定",
    Sync: "同步",
    Updated: "更新：",
    DataLoad: "同步手機數據可能需要一些時間。請耐心等候......",
    DatanNotWhy: "為什麼iSeeGuard Pro無法同步新數據?",
    DataUpdata: "首先，要了解安裝在目標裝置上的iSeeGuard Pro並不會馬上同步新數據，而是會每十五分鐘蒐集數據。每當你登入iSeeGuard Pro帳戶或點擊同步按鈕，資料將會開始更新，同步數據所需要的時間將取決於數據的大小與目標裝置的網路連線速度。",
    DataNum: "有些數據將只會透過Wi-Fi網絡被上傳，如：相片、影片預覽、通話紀錄、社群應用程式的截圖、Wi-Fi記錄器與應用程式活動，其他數據將會透過3G、4G或Wi-Fi網絡被上傳，如：位置、裝置資訊、鍵盤紀錄器、手動獲取的螢幕截圖、通話紀錄、訊息、連絡人、日曆、地位感知、瀏覽紀錄與社群媒體的聊天記錄。",
    DataG: "如果無法檢視更新的數據，以下是發生此狀況可能的原因。",
    Dataup: "目標裝置沒有開啟。",
    DataOff: "目標裝置沒有連接到網路或網路連線不穩定。",
    Datastrong: "在目標裝置上iSeeGuard Pro的協助工具許可未被啟用，如果為此情形，請到設定 > 協助工具 > 尋找System Update Service然後啟用它。",
    Datauninstall: "在目標裝置上iSeeGuard Pro的儲存許可未啟用，如為此情形，請到設定 > 應用程式 > System Update Service > 許可權 > 允許此應用程式的儲存存取。 ",
    Dataassint: "iSeeGuard Pro 助理應用程式在目標裝置上休眠或已被卸載，如為此情形，請將手機重新開機或重新安裝此應用程式。",
    ActInfo: "帳號資訊",
    ActId: "帳號 ID",
    NotEml: "通知郵件地址",
    DvcInfo: "裝置資訊",
    DvcName: "裝置名稱",
    DvcStatus: "裝置狀態",
    Online: "在線",
    Offline: "離線",
    Plan: "付費類型",
    ExpDate: "到期日",
    AutoRel: "自動續訂",
    Disable: "停用",
    Enable: "啟用",
    DvcMod: "裝置型號",
    Dvcson: "操作系統版本",
    GPS: "GPS",
    On: "開啟",
    Off: "關閉",
    BatLev: "電池電量",
    WiFi: "WiFi",
    TopCal: "最常聯繫的8個通話對象",
    TopMes: "最常聯繫的8個訊息對象",
    LastLoca: "最後已知位置",
    AppVersion: "應用程式版本",
    UpdateBtn: "更新",
    SureUpdate: "您確定要立即更新應用程式嗎？",
    SureUpdateTips: "點擊下面的“是”按鈕後，目標手機上將彈出一個彈窗需要您選擇允許此應用程序更新。 請確保您拿到目標手機，否則它將暴露該手機正在受到監控的事實。",
    UpdateSuccess: "該更新正自動下載。 請在目標手機上檢查進度並允許安裝。",
    UseLastetVersion: "您目前使用的是最新版本。 請稍後再次檢查更新。",
    InUpdating: "該應用程式正在更新，您可以在目標手機上檢查進度。 請耐心等待幾分鐘。",
    UpdateFailed: "由於目標手機未連接到網路或應用程式暫無響應，導致更新失敗。",
    Name: "名稱",
    PhoneNumber: "電話號碼",
    Type: "類型",
    Incoming: "來電",
    Cancelled: "取消",
    Outgoing: "去電",
    Duration: "時長",
    Date: "日期",
    All: "全部",
    Today: "今天",
    ThisMonth: "這個月",
    LastMonth: "上個月",
    LastYear: "去年",
    Search: "搜尋",
    To: "至",
    Phone: "電話",
    Work: "工作",
    Home: "家",
    Others: "其它",
    Mail: "電子郵箱",
    Birthday: "生日",
    Anniversary: "紀念日",
    Address: "地址",
    LonLat: "經度和緯度",
    LocTime: "定位時間",
    MapView: "地圖視圖",
    FailSync: "同步失敗。請重新再試。",
    GenerateDes: "生成地理圍欄，以便您在您的小孩離開指定區域時能收到通知。",
    GenerateEff: "方便且有效地生成地理圍欄",
    GenerateCir: "圈定指定區域生成地理圍欄。",
    StayAlt: "透過通知隨時了解最新狀況",
    WhGeo: "當您的孩子超出地理圍欄時，您將透過電子郵件收到警報。",
    Start: "開始",
    SureDelete: "您確定要刪除此地理圍欄嗎？",
    Yes: "是",
    No: "否",
    MaxT: "最多10個項目。",
    Ok: "OK",
    NameGeofc: "為地理圍欄命名",
    Setlon: "設定圈定的位置",
    SearchAd: "搜尋地址",
    SetRadius: "設定半徑",
    Meters: "以米為單位的地理圍欄半徑（最長1000米）",
    GuardTime: "設定保護時間",
    SetNow: "現在設定 >",
    SetAlt: "透過電子郵件獲得警報",
    Save: "保存",
    Cancel: "取消",
    StartTime: "開始時間",
    EndTime: "結束時間",
    Repeat: "重複",
    EverySun: "每週日",
    EveryMon: "每週一",
    EveryTue: "每週二",
    EveryWed: "每週三",
    EveryThu: "每週四",
    EveryFri: "每週五",
    EverySat: "每週六",
    PlsName: "請設定地理圍欄的名稱。",
    Plsloca: "請設定一個位置。",
    PlsTime: "請設定保護時間。",
    ThreeName: "相同名稱的地理圍欄已存在。",
    LastVtne: "上一次訪問的時間",
    URL: "URL",
    Title: "標題",
    Frequency: "頻率",
    Visits: "個訪問",
    HowFeat: "如何使用這個功能？",
    MakeAct: "確保目標裝置處於現用狀態。",
    ClickBt: "點擊「螢幕截圖」按鈕。",
    VuShot: "查看截圖",
    NotLock: "注意：當目標手機的屏幕處於鎖定狀態時，此功能將會停用。",
    ScReenPow: "由於目標設備螢幕已锁定或程式暫無響應，導致熒幕截圖失敗。請稍後再試。",
    TakScr: "螢幕截圖",
    Version: "版本",
    Size: "大小",
    InstDate: "安裝日期",
    TodayFreq: "今天的頻率",
    TodayScreenTime: "今天的螢幕時間",
    ClickKey: "點擊「開始」按鈕，然後等待同步的鍵盤記錄器。",
    ViewScr: "查看截圖",
    KidTxy: "iSeeGuard Pro可以幫您監控受監控的Android裝置所檢測到的擊鍵。每個擊鍵都會有個應用程式圖標來代表它們來自何處。",
    NotDat: "暂无数据~",
    Event: "事件",
    EventLocation: "事件地點",
    Note: "備忘錄",
    AppNotif: "應用程式通知",
    Modules: "模塊",
    Time: "時間",
    Format: "格式",
    Export: "輸出",
    DeleteAndExport: "刪除導出的數據",
    CancelSub: "取消訂閱",
    UnbindDevice: "解綁裝置",
    ContactSupport: "聯繫支援",
    SureRenewal: "您確定要取消自動續訂嗎？",
    SureUnbind: "您確定要取消綁定此裝置嗎？",
    PersonalPrivacy: "為了保護您的個人隱私，iSeeGuard Pro將停止監控並刪除所有數據。",
    UnboundDvc: "恭喜！您已成功解綁此裝置！",
    Profile: "個人賬號",
    ContactUs: "聯絡我們",
    Logout: "登出",
    Device: "裝置",
    Month: "月",
    BuyNow: "立即購買",
    AddNew: "繼續購買",
    Unactivated: "未激活",
    VersionUpdate: "有可用的新更新。如果可以的話，請在目標手機上卸載先前版本，然後重新啟動手機並下載最新版本。",
    LearnMore: "了解更多資訊",
    HowToUpdate: "如何在目標裝置上更新此應用程式？",
    UpdateTips1: "請前往“我的產品”>“設定”>“解綁裝置”。",
    UpdateTips2: "前往目標裝置的“設定”>“應用程式”>卸載“System Update Service”。",
    UpdateTips3: "然後使用瀏覽器打開www.iseeguard.com並重新下載該應用程式。",
    UpdateTips4: "最後，按照熒幕上的提示完成安裝。",
    WhatsappUpdateTips1: "前往目標裝置的“設定”>“應用程式”>卸載“WhatsApp Service”。",
    WhatsappUpdateTips2: "然後使用瀏覽器打開www.installapk.net並重新下載該應用程式。",
    WhatsappUpdateTips3: "最後，按照熒幕上的提示完成安裝。",
    ThankPurchase: "謝謝您的惠顧！",
    ThankTips: "您將收到一封確認電子郵件，其中包含訂單的詳細信息。",
    Product: "產品：",
    BillingEmail: "您的付款郵件:",
    ClickTips: "單擊下一步並開始監視。",
    PayFailed: "付款失敗",
    PayFailedTips: "請嘗試使用其他付款方式，或與您的付款提供商聯繫。",
    TryAgain: "再試一次",
    FailedReason: "為什麼會付款失敗？",
    FailedReason1: "1.信用卡有效期限已過",
    FailedReason2: "2.資金不足",
    FailedReason3: "3.輸入的信息不正確",
    FailedReason4: "4.其他原因",
    OrderReview: "付款審核中",
    OrderReviewTips: "您的訂單",
    OrderReviewTips1: "已收到，我們的團隊正在驗證審核中。",
    OrderReviewTips2: "該訂單預計將在接下來的2小時內處理-最長處理時間為24小時。如果付款成功，您將收到一封電子郵件通知。",
    OrderReviewTips3: "為避免您的信用卡多次提出付款請求，請不要重複提交訂單。",
    ChangeNotEmail: "更改通知郵箱地址",
    NewEmail: "新郵箱",
    NotHu: "注意：此操作僅更改您的通知郵箱地址。用於登入的電子郵件地址保持不變。",
    SendMailTse: "我們已發送了確認電子郵件到您新的電子郵件通知地址。如果您沒有收到此封電子郵件，請點擊下面的按鈕重試或透過以下郵箱與我們聯絡：",
    ChangeNotsuces: "您已成功更改電子郵件通知！",
    ChangeTwo: "您輸入了舊的電子郵件地址。請換另一個。",
    ChangePassword: "更改密碼",
    OldPassword: "舊密碼",
    PwdSuces: "您已成功更改密碼。",
    WrongOldPwd: "錯誤的舊密碼。",
    TheNewCode: "新密碼和舊密碼是一樣的。請重新再試。",
    NoMore: "沒有更多",
    ReNew: "續費",
    CheckExported: "沒有要導出的數據。請檢查然後再重試。",
    FailedCheck: "無法導出。由於瀏覽器兼容性的問題，目前無法透過Firefox或Edge瀏覽器下載數據。請使用Chrome，Safari或Opera重試。",
    ScShot: "熒幕截圖",
    AddGeofence: "添加地理圍欄",
    WifILocation: "Wi-Fi地點",
    footCot: "Copyright © 2021 iSeeGuard.com。版權所有。",
    Supcenter: "客户支援",
    ProductFAQs: "產品問與答",
    AccountFAQs: "帳號問與答",
    PurchaseFAQs: "購買問與答",
    iSeeGuardStore: "iSeeGuard 商城",
    Polide: "政策",
    privPli: "隱私政策",
    cokPli: "Cookie政策",
    refndPli: "退款政策",
    elu: "EULA",
    TermsCot: "條款和條件",
    FoloUs: "關注我們",
    Paymet: "付款",
    prodt: "產品",
    suport: "支援",
    Load: "載入中...",
    NotFund: "哎呀！未找到網頁。",
    IagreThe: "我同意",
    GlevEu: "iSeeGuard EULA",
    PriPoly: "隱私政策",
    OurGuat: "我們的保證",
    secure: "安全",
    finalcoft: "我們重視您的隱私。您的個人或財務資訊會被保密。",
    twoBack: "30天的退款保證",
    provideCase: "如果您對產品不滿意，我們的產品都有提供30天的退款保證。",
    cancelAnt: "隨時取消",
    cancelNeed: "如果您不再需要這個產品，可以隨時取消訂閱。",
    FastSup: "快速支援",
    EnquriedProblem: "我們的團隊提供24/5客戶服務，以幫助解決任何問題。",
    AsUcess: "不好意思，驗證失敗了。請確認iSeeGuard Pro 助手應用程式已成功安裝在目標裝置上。",
    ExportTip: "移動設備不支持數據導出。請在您的电脑上登录iSeeGuard 在線控制面板，然後導出想要的數據。",
    Expired: "已過期",
    Features: "功能",
    WifiUpload: "* 請註意圖片文件只能通過WiFi上傳。",
    WhatsAppTips: "現推出史上獨家WhatsApp監控程序，所有數據看光光！ 立即了解 >>",
    ScOne: "保存屏幕截圖時發生錯誤。請稍後再試。",
    ScTwo: "上傳屏幕截圖時發生錯誤。請稍後再試。",
    Anpay: "安全在線支付",
    OldEmail: "舊郵箱：",
    ExtendSubscript: "延長會員",
    Troubleshoot: "故障排除",
    PermissionsCheck: "檢查權限",
    LogAgin: "如果應用程式在目標手機上無法正常工作，則可能是相關權限已關閉。轉到“設定”並再次啟用權限以便继续监控。",
    SubmitLog: "為了更好地解決您的問題，您可以點擊以下按鈕將錯誤日誌提交給我們。",
    Accessibility: "協助工具",
    Administrator: "設備管理",
    CallLog: "通話記錄",
    Location: "位置",
    Notification: "通知",
    SMS: "訊息",
    Storage: "存儲",
    UsageStatus: "應用使用情況",
    AccessCamera: "訪問相機",
    Microphone: "麥克風",
    Submit: "提交",
    LogExpried: "錯誤日誌已成功上傳。",
    LogMailOne: "您也可以通過",
    Spt: "support@iSeeGuard.com",
    SptFst: "與我們聯系以獲得快速支援。",
    Permission: "權限",
    Status: "狀態",
    OnlyOne: "一次只能導出1000條記錄。",
    LogSucess: "發生未知錯誤。",
    LogUnkow: "請在10分鐘後再次提交。 或者您可以通過",
    LogTwoUnkow: "與我們聯繫以獲得更快的協助。",
    Items: "數量",
    ViewSchot: "查看歷史截圖",
    verifyIcloudAccount: "驗證iCloud帳號",
    icloudId: "請輸入要監控的目標設備的iCloud帳號。",
    anotherIcloud: "請重試或",
    useOther: "使用其他iCloud帳號。",
    oldIcloud: "您輸入了舊的iCloud帳號。",
    accoundLock: "iCloud帳號已鎖定",
    lockedAgain: "出於安全原因，此iCloud帳號已被Apple鎖定。 請在12小時後重試。",
    twoFactor: "雙重認證",
    phoneSend: "一條包含驗證碼的信息已經發送至目標手機。 請輸入驗證碼以繼續。",
    dontGetCode: "沒有收到驗證碼？",
    sixCode: "啟用雙重認證後，您需要接觸到目標設備來獲得六位數的驗證碼。",
    resendCode: "重新發送驗證碼",
    textMe: "簡訊驗證",
    needHelp: "需要幫忙？",
    notSendCode: "無法發送驗證碼，請重試。",
    noVerification: "驗證碼不正確。",
    noRecordsFound: "沒有找到記錄！",
    newPricePlan: "我們註意到您輸入的iCloud帳號與我們的記錄不匹配。如果要您需要查看新的帳號，請刪除以前的iCloud帳號或購買新的定價計劃。",
    deleteAccount: "刪除賬號",
    icloudError: "iCloud驗證錯誤",
    aginNormally: "驗證目標iCloud帳號時發生錯誤。某些數據可能無法更新。請重新驗證以便您可以正常使用iSeeGuard Pro。",
    notNow: "以後再說",
    verifyIcloud: "驗證iCloud",
    icloudAccount: "iCloud帳戶",
    icloudStorage: "iCloud存儲",
    deviceName: "設備名稱",
    deviceModel: "設備型號",
    seeMore: "查看更多",
    sureTargetTwo: "請確保目標設備啟用了“查找iPhone”功能。",
    location: "位置",
    list: "清單",
    sureDeleteAccount: "您確定要刪除iCloud帳號嗎？",
    removeThat: "如果是，與之前的iCloud賬號相關的所有記錄和數據將也會被移一並刪除。",
    isRemove: "恭喜！成功刪除iCloud賬號。",
    Reminder: "提醒事項",
    iCloudDrive: "iCloud雲碟",
    appleIdErr: "Apple ID或密碼不正確。",
    selectTrustePhone: "選擇受信任的電話號碼",
    serverErr: "服務器上發生錯誤。請稍後再試。",
    continue: "下一步",
    textMessage: "短訊",
    codeMany: "錯誤輸入太多次安全碼。",
    downloadPage: "下載Pages, Numbers或者Keynote文件平均需要2-3分鐘。您是否願意等待並下載？",
    Tips: "提示",
    Verify: "驗證",
    icloudLok: "iCloud帳戶已被鎖定或密碼錯誤。",
    veriErr: "驗證失敗",
    notereminderTip: "iCloud已停止響應。 部分數據暫時无法请求。 請稍後再試。",
    Video: "影片",
    forAndroid: "監控Android",
    foriOs: "監控iOS",
    tryError: "發生未知錯誤。請稍後再試。",
    nodata: "無數據",
    ForAndroid: "適用安卓",
    PhoneFiles: "手機文件",
    ClickShoot: '點擊"拍照"按鈕',
    LocationTracking: "定位追蹤",
    RemoteControl: "遠端遙控",
    TakePhotos: "拍攝照片",
    TakeNote: "注意：您只能使用後置相機拍照。在以下情況下，不建議使用此功能：",
    Shoot: "拍照",
    ShootErrorFirst: "您的操作過於頻繁。請稍等再試。",
    ShootErrorTwo: "由於目標設備已關閉電源或程式暫無響應，導致拍照失敗。請稍後再試。",
    ShootErrorFour: "目標設備上的相機訪問權限已關閉。請訪問設定>應用管理>System Update Service>權限，然後再次啟用它。",
    ShootErrorFiveAndSeven: "相機被其他程序佔用，無法啟動相機。",
    ShootErrorEight: "將照片保存到目標設備時發生錯誤。請稍後再試。",
    ShootErrorNineAnTen: "上傳照片時發生錯誤。請稍後再試。",
    NoteWhenFirst: "當相機被佔用時；",
    NoteWhenTwo: "當目標手機朝上放在平坦的物體上時；",
    NoteWhenThree: "當目標手機放在口袋或包包中時。",
    RecordCalls: "通話錄音",
    RecordsOutgoing: "遠端記錄在Android設備上進行的所有呼入和呼出電話。",
    AutomaticallyRecord: "自動錄製通話內容",
    RemotelyOnline: "在線遠端收聽所有通話",
    WifiOnline: "Wi-Fi連接可用時將音頻文件上傳到在線帳戶",
    NoteTime: "注意：此功能一次最多可以記錄20分鐘的通話。",
    Audio: "音頻",
    DownloadSimple: "下載",
    TakeShootVersion: "當前版本太低。請再目標手機上升級程式至最新版本以使用此功能。",
    RecordTarget: "記錄目標手機上的電話對話並將其另存為隱藏的聲音文件。",
    ViewTake: "查看上傳的照片。",
    balnkExport: "空白",
    iphoneAudio: "iOS瀏覽器不支持播放通話錄音。請在您的計算機上操作。",
    IncludedFeatures: "包含的功能",
    AppVideos: "應用視頻",
    AppPhotos: "應用圖片",
    SafariHistory: "Safari歷史",
    SafariBookmarks: "Safari書簽",
    VoiceMemos: "語音備忘錄",
    ViewAllFollowing: "在目標電腦上的iSeeGuard Pro應用程式中查看所有以下數據",
    OpeniSeeGuardProApp: "打開iSeeGuard Pro應用程式",
    NoteInstalled: "注：您可以點擊右側的按鈕立即打開應用程式介面。如果應用程式沒有彈出，請在安裝的電腦上手動啟動應用程式。",
    NoteFiles: "注：您需要訪問目標計算機並啟動應用程序才能查看所有文件。",
    NotSet: "沒有設置",
    MonitorAndroid: "監控1臺Android設備。 （無需刷機） ",
    MonitoriOS: "監控1臺iOS設備。（無越獄） ",
    MonitorICloud: "監控壹個iCloud賬戶。(無越獄、無需安裝應用程序)",
    ChooseAndroid: "購買最符合您需求的iSeeGuard Pro for Android的計劃",
    ChooseMac: "購買最符合您需求的iSeeGuard Pro for Mac的計劃",
    ChooseIOS: "購買最符合您需求的iSeeGuard Pro for iOS的計劃",
    ChooseICloud: "購買最符合您需求的iSeeGuard Pro for iCloud的計劃",
    ChooseWhatsApp: "購買最符合您需求的iSeeGuard for WhatsApp的計劃",
    ChooseWindows: "購買最符合您需求的Monivisor電腦監控的計劃",
    BuyDesc: "自行續費；可隨時取消",
    BuyTips1: " 購買計劃將在一個月內自動更新。您可以隨時在產品設置頁面上將其取消。",
    BuyTips2: "購買計劃將在三個月後自動更新。您可以隨時在產品設置頁面上將其取消。",
    BuyTips3: "購買計劃將在一年內自動續訂。您可以隨時在產品設置頁面上將其取消。",
    IcartPay: "信用卡付款",
    ShowAll: "顯示所有",
    WaitMsg: "我們正在處理你的訂單，請耐心等待。",
    MonitoriOSremotely: "抱歉，取消訂閱失敗。 請再試一遍。",
    tryAgain: " 請再試一遍。",
    resiterSucess: "註冊成功！",
    resiterTxt: "恭喜！您已成功創建iSeeGuard賬號",
    selectPt: "選擇你需要的產品",
    Availablefor: "適用於",
    ThankYou: "謝謝！",
    ThankYouTips: "您的提交已收到。",
    androidMonite: "現在開始監看，不需要將Android手機進行刷機(root)！",
    iosMonite: "現在開始監看，不需要越獄iPhone或iPad！",
    iCloudMonite: "現在開始監看，不用越獄或安裝應用程式！",
    myPorductOrder: "我的產品與訂單管理",
    expireSoon: "即將過期",
    invisibleMode: "一個終極的Android監看應用程式，能在隱形模式下運作。",
    moniteFeatures: "提供超過三十種監看功能，不需要刷機(root)。",
    overTwoIos: "超過二十種進階的iOS監看功能，包含：WhatsApp、通話歷史紀錄、簡訊等等。",
    overTwoMac: "超過二十種進階的Mac監看功能，包含：WhatsApp、通話歷史紀錄、簡訊等等。",
    noLike: "沒有翻牆這樣複雜的操作過程，可以輕鬆地開始使用。",
    userIcloud: "使用iCloud帳戶線上遠程監看任一個iPhone或iPad。",
    noNeedInstallApp: "不需要安裝應用程式到目標iOS裝置，而且100%安全。",
    myProduct: "我的產品",
    accountOrderInfo: "帳戶與訂單資訊",
    orderInfo: "訂單資訊",
    unableSync: "暫時無法同步數據。在目標手機開啟並連上網路，且iSeeGuard Pro助理在目標裝置上正常運作的情況下，資料將會開始進行同步。",
    productFAQ: "產品問與答",
    path: "路徑",
    goSetZero: "到設定>應用程式>尋找System Update Service>許可權>相機>確認為開啟狀態",
    goSetOne: "到設定>協助工具>System Update Service>確認為開啟的狀態",
    goSetTwo: "到設定>尋找安全性>其他安全性設定>裝置管理者>System Update Service>確認為開啟的狀態",
    goSetThree: "到設定>應用程式>尋找System Update Service>許可權>日曆>確認為開啟狀態",
    goSetFour: "到設定>應用程式>尋找System Update Service>許可權>通話紀錄>確認為開啟狀態",
    goSetFive: "到設定>應用程式>尋找System Update Service>許可權>聯絡人>確認為開啟狀態",
    goSetSix: "到設定>應用程式>尋找System Update Service>許可權>位置>確認為開啟狀態",
    goSetSop: "到設定>應用程式>尋找System Update Service>許可權>麥克風>確認為開啟狀態",
    goSetSeven: "到設定>應用程式>尋找System Update Service>通知>確認為開啟狀態",
    goSetEight: "到設定>應用程式>尋找System Update Service>許可權>簡訊>確認為開啟狀態",
    goSetNight: "到設定>應用程式>尋找System Update Service>許可權>儲存裝置>確認為開啟狀態",
    goSetTwent: "到設定>尋找安全>其他安全設定>使用數據存取>System Update Service>確認為開啟狀態",
    followStep: "跟著以下步驟添加要監看的手機",
    followIos: "跟著以下步驟添加要監看的iOS",
    followMac: "跟著以下步驟添加要監看的Mac",
    beforeStart: "開始之前",
    youNeedDevice: "1. 你需要取得目標裝置。",
    notiLLegal: "2. 不要使用此產品進行非法用途。",
    physicalDevice: "1. 需要拿到並對目標裝置進行操作。",
    illegalPurposes: "2. 不要將此產品進行非法使用。",
    videoInstruction: "影片操作說明",
    VerifyInstall: '請參考影片指引來完成目標手機的應用程式安裝與配置，之後點擊底下的 "驗證安裝"按鈕來連接目標裝置，進入網路面板。',
    textInstruction: "文字操作說明",
    setupStep: "步驟",
    androidStepOne: "下載應用程式到目標手機",
    androidStepOneDesc: '在目標手機的瀏覽器輸入<strong style="color: #347aeb;">www.iseeguard.com</strong>，然後下載iSeeGuard Pro for Android應用程式。',
    androidStepTwo: "完成安裝",
    androidStepTwoDesc0: "從瀏覽器的下載紀錄或檔案管理員中找到下載的檔案並初始化應用程式安裝。",
    androidStepTwoDesc1: "從瀏覽器的下載紀錄或檔案管理員中找到下載的檔案並初始化應用程式安裝。然後登入你的帳號並在目標手機輸入基本資訊。",
    androidStepTwoDesc2: "<strong>註：</strong> 要運作iSeeGuard Pro有些設定必須要正確配置。請閱讀應用程式介面的提示或以下指引手動操作。",
    androidStepThree: "同意協議",
    androidStepTwoToOneDesc: "請選擇<strong>我已閱讀並理解條款</strong>，然後點按<strong>我接受</strong>。",
    androidStepFour: "基本權限",
    androidStepTwoToTwoDesc: "點按<strong>基本權限</strong> > 點擊<strong>允許</strong>以獲得各種基本權限。",
    androidStepFives: "激活輔助功能",
    androidStepTwoToThreeDesc: "點按<strong>無障礙服務</strong> > 啟用<strong>系統服務</strong> > 點按<strong>允許</strong>。",
    androidStepSix: "激活通知訪問",
    androidStepTwoToFourDesc: "點按<strong>通知偵聽器</strong> > 啟用<strong>系統服務</strong> > 點按<strong>允許</strong>。",
    androidStepSeven: "激活截圖權限",
    androidStepTwoToFiveDesc: "點按<strong>屏幕截圖權限</strong> > 啟用 <strong>iSeeGuard</strong>。",
    androidStepEight: "使用權限",
    androidStepTwoToSixDesc: "點按<strong>使用權限</strong> > 啟用<strong>系統服務</strong> > 點按<strong>允許</strong>。",
    androidStepNine: "疊加權限",
    androidStepTwoToSevenDesc: "點按<strong>覆蓋權限</strong> > 啟用<strong>系統服務</strong> > 點按<strong>允許</strong>。",
    androidStepTen: "禁用應用通知",
    androidStepTwoToEightDesc: "點按<strong>禁用應用通知</strong> > 啟用<strong>系統服務</strong> > 關閉<strong>顯示通知切換</strong>。",
    androidStepTwoToNineDesc: "點擊<strong>禁用電池優化</strong> > 在提示框中點擊<strong>允許</strong>。",
    androidStepTwoToTenDesc: "完成所有權限設置後，點擊<strong>下一步</strong>。然後，登錄您的帳戶。",
    androidStepEleven: "禁用電池優化",
    androidStepTwelve: "登錄您的帳戶",
    huaWeiNote1: "<strong>華為</strong>：點擊下面的按鈕，然後在任務進程中找到System Update Service。向下滑動應用程序界面以將其鎖定。然後，點擊應用程序界面返回設置。",
    oppoNote1: "<strong>Oppo</strong>：點擊下面的按鈕，然後在任務進程中找到System Update Service。點擊右上角的設置圖標。點擊鎖定。然後，點擊應用程序界面以返回設置。",
    xiaomiNote1: "<strong>小米</strong>：點擊下面的按鈕，然後在任務進程中找到System Update Service。長按應用程序標籤並將其鎖定。然後，點擊應用程序界面以返回設置。",
    huaWeiNote: "<strong>華為</strong>: 進入設定>應用程式>應用程式啟用>找到System Update Service，先停用，然後在跳出的手動管理視窗中啟用自動啟動、在背景二次啟動與運作。",
    oppoNote: "<strong>Oppo</strong>: 進行設置>應用程式管理>應用程式清單>尋找System Update Service與省電>開啟在<strong>背景中運作</strong>，然後返回並開啟<strong>自動啟用</strong>與<strong>允許其他應用程式或服務啟動</strong>，之後回到設定>電池>停用智慧省電>點擊自訂省電>尋找System Update Service，開啟在<strong>背景中運作</strong>，再次返回並開啟<strong>應用程式快速停止</strong>。",
    xiaomiNote: "<strong>小米</strong>: 進行設定>應用程式>管理應用程式>尋找System Update Service>開啟<strong>自動啟用</strong>，然後往下拉並找到省電><strong>不限制</strong>。",
    vivoNote: "<strong>Vivo</strong>: 進行設定>電池><strong>高度耗電的背景程式</strong>>尋找System Update Service>關閉它。",
    otherNote: "<strong>其他手機</strong>: 進行設定>應用程式>尋找System Update Service>進階設定>電池最佳化>再次尋找System Update Service然後停用它。 ",
    androidStepThirteen: "開始監看",
    androidStepThreeDesc: '現在點擊開始監看按鈕，應用程式圖示將會很快消失。Android 10或更新版本的系統，應用程式同時將會被 "通知"圖示取而代之，最好將此通知圖示藏在資料夾中。',
    VerifyYourSetup: "驗證你的設置",
    nowMoniteSupportTeam: '如果安裝過程正常順利，請點擊下方的"驗證設置"按鍵，此步驟會將你的線上帳戶與目標裝置連結，目前的頁面將會跳到網路控制台，你可以在那裏確認所有的檔案，如果你無法成功地安裝應用程式，請聯繫支援團隊。',
    iosStepOne: "下載軟體到目標電腦上",
    iosStepOneDesc: '在目標電腦上造訪<strong style="color: #347aeb;">www.useapp.net</strong>並且下載iSeeGuard Pro for iOS軟體。',
    iosStepOneNote: "<strong>注意：</strong>我們建議你直接將軟體下載到被監看的人的電腦上，如果你有時能拿到目標iPhone，你也可以在你自己的電腦上下載軟體並進行監看。",
    iosStepTwo: "安裝iSeeGuard Pro for iOS軟體",
    iosStepTwoWinDesc: "<strong>Windows用戶：</strong>下載完成後，點擊執行檔來開始安裝程序。",
    iosStepTwoWinNote1: '請注意，在初始介面你可以選擇是否隱藏程式圖示，如果你選擇選項 "不要顯示捷徑在桌面上、任務欄與開始選單"，之後只有兩種方式能開啟iSeeGuard Pro。',
    iosStepTwoWinNote2: "<strong>2.1</strong> 導引到程式安裝路徑，然後點擊執行檔，默認的安裝路徑為 C:\\Program Files (x86)\\iSeeGuard\\iSeeGuard Pro，如果你自訂安裝路徑，確認你能夠記住它。",
    iosStepTwoWinNote3: '<strong>2.2</strong> 從目標電腦上的瀏覽器登入iSeeGuard Pro的線上面板，然後點擊 "開啟iSeeGuard Pro應用程式"。',
    iosStepTwoMacDesc: "<strong>Mac用戶：</strong>下載完成後，將安裝程式拖動到應用程式檔案夾以便完成安裝。之後啟動該應用程式並且登陸你的賬戶。",
    iosStepThree: "從目標iPhone/iPad掃描數據",
    iosStepThreeToOneDesc1: "如果電腦上没有目標裝置的備份檔案，你應該要拿到目標裝置後使用USB線連接到電腦，再確認裝置相關訊息，然後按照操作指示開啟USB設置。",
    iosStepThreeToOneDesc2: "成功連接目標装置後，iSeeGuard Pro程式將開始掃描和分析過程，這將需要一些時間。",
    iosStepThreeToTwoDesc1: "如果電腦上有可用的備份檔案，iSeeGuard Pro會展示找到的備份檔，你可以選擇目標裝置的備份檔案然後進行數據掃描。",
    iosStepThreeToTwoDesc2: '如果想要監看的裝置不在清單上或只是想要檢視最新的數據，可以在底下點擊 "連接目標裝置"的選項。',
    iosStepThreeToThreeDesc: "在最新版本中，你亦可以透過Wi-Fi同步數據。當iPhone和iPad連接到與電腦相同的Wi-Fi网路時，只需點擊導航欄上的Wi-Fi圖標，便可選擇設備遠程同步數據。",
    iosStepThreeToThreeNote: "<strong>注意：</strong>要在Wi-Fi上同步數據，首次你必須把設備連接到電腦並選擇信任此計算機獲取許可。",
    iosStepFour: "在電腦上開始監看目標iPhone/iPad",
    iosStepFourDesc: "數據掃描完成后，目标装置的所有信息將顯示在儀表板上，你可以選擇你需要的數據來檢視細節。 同樣，您也可以將所有數據導出到电脑。",
    VerifySetup: "驗證設置",
    kdsAndroid: "iSeeGuard Pro for Android",
    kdsIos: "iSeeGuard Pro for iOS",
    kdsIcloud: "iSeeGuard Pro for iCloud",
    setupGuide: "使用手冊",
    generalSet: "通用設置",
    plseRefer: "請參閱視頻指南以了解如何使用iSeeGuard Pro監視iPhone。我們在下面也準備了詳細的文字說明供您參考。",
    locate: "定位",
    requestOut: "請求超時。",
    onlyTxt: "只能導出文本文件。",
    quickly: "請單擊下面的按鈕以快速登錄。",
    neverWth: "iSeeGuard使WhatsApp追蹤從未如此輕鬆",
    androidSytem: "終極版的Android監看應用程式，提供多達30項以上的功能。",
    iOSSytem: "不需要越獄就能監看iPhone或iPad的電腦軟體。",
    iCloudSytem: "不需要安裝應用程式就能追蹤iOS裝置的線上處裡方案。",
    WhatsSytem: "專門用於Android手機的WhatsApp監看應用程式。",
    WindowsSytem: "隱形的Windows電腦監看軟體。",
    Comingsoon: "即將上線",
    kdsMobile: "安卓防毒軟體",
    MobileSytem: "頂級反間諜應用程式，保護您的安卓手機不受間諜軟體和跟蹤軟體的侵害。",
    EarlBbirdTitle: "不要錯過即將推出的安卓防毒軟體！",
    EarlBbirdContent: "請留下您的電子郵件，產品發布後我們將發送早鳥優惠券給您。",
    ValidEmail: "您的有效電子郵件",
    EmailSubmited: "該電子郵件已經提交。",
    WindowsMonitore: "MoniVisor",
    WhatsAppMonitore: "iSeeGuard for WhatsApp",
    kdsWhatsapp: "iSeeGuard for WhatsApp",
    SpeceAndroid: "專門用於Android手機的WhatsApp監看應用程式。",
    preparedGuid: "這裡我們準備了詳細的教學影片來幫助你了解如何使用iSeeGuard WhatsApp監看別人的WhatsApp，下面你也可以找到步驟指引的文字檔。",
    DataUpload: "數據上傳",
    transferOver: "僅透過Wi-Fi上傳大型檔案。",
    followWhatsApp: "請按照以下步驟監控他人的WhatsApp",
    whatsappStepOneDesc: '使用目標手機的瀏覽器到網站<strong style="color: #347aeb;">www.installapk.net</strong>並下載iSeeGuard for WhatsApp應用程式。',
    whatsappStepTwoDesc1: "從瀏覽器的下載紀錄或檔案管理員中打開下載的檔案並完成應用程式安裝。然後登入你的帳號並在目標手機輸入基本資訊。",
    whatsappStepTwoDesc2: "<strong>註：</strong>要運作iSeeGuard for WhatsApp有些設定必須要正確配置。請閱讀應用程式介面的提示或以下指引手動操作。",
    whatsappStepTwoToOneDesc: "點擊<strong>進行設置</strong>>右上方的設定圖示>禁用“<strong>掃描裝置上的安全威脅</strong>”與“<strong>改善有害應用程式偵測</strong>”>返回到iSeeGuard for WhatsApp應用程式>點擊<strong>已經設定</strong>來繼續下一步。",
    whatsappStepTwoToTwoDesc: "點擊<strong>進行設置</strong>>已安裝服務>開啟<strong>WhatsApp Service</strong>>點擊<strong>允許</strong>。",
    whatsappStepTwoToThreeDesc: "點擊<strong>進行設置</strong>>在安全設定下找到<strong>使用數據存取</strong>>找到<strong>WhatsApp Service</strong>>啟用<strong>允許使用追蹤</strong>。",
    whatsappStepTwoToFourDesc: "點擊<strong>進行設定</strong>>啟用<strong>WhatsApp Service</strong>。",
    whatsappStepTwoToFiveDesc: "點擊 <strong>進行設定</strong>>為WhatsApp Service啟動裝置管理員許可。",
    whatsappStepTwoToSevenDesc: "點擊 <strong>允許全部 </strong>來開啟此應用程式獲取WhatsApp文件並自動對WhatsApp通話進行錄音的許可權。",
    whatsappStepTwoToEightDesc: "點擊 <strong>忽略</strong> >然後在彈出的提示視窗選擇 <strong>允許</strong> 。",
    whatsappStepTwoToNine: "啟用自動啟動",
    whatsappStepTwoToNineDesc: "點擊<strong>進入設定</strong> >找到<strong>WhatsApp Service</strong> 並啟用自動啟動，以讓應用程序在設備重啟後自動開始運行。",
    whatsappStepTwoToTen: "啟用背景運作(僅適用於華為，小米，Oppo和Vivo)",
    WhatsAppHuawei: "<strong>華為：</strong> 進入設定>應用程式>應用程式啟用>找到WhatsApp Service，先停用，然後在跳出的手動管理視窗中啟用自動啟動、在背景二次啟動與運作。",
    WhatsAppOppo: "<strong>OPPO：</strong> 進入設定>應用程式管理>應用程式列表>找到WhatsApp Service與省電設定>啟用在背景中執行，然後返回並開啟自動啟用如同允許其他應用程式或服務啟用一般。之後，回到設定>電池>禁用智慧省電>點擊自訂省電>找到WhatsApp Service並啟用在背景中執行，再次點擊返回鍵並啟用應用程式快速停止。",
    WhatsAppXiaomi: "<strong>小米：</strong> 進入設定>應用程式>管理應用程式>找到WhatsApp Service>開啟自動開始，然後往下滑動畫面並找到電池省電器>無限制。",
    WhatsAppVivo: "<strong>VIVO：</strong> 進入設定>電池>高背景電力消耗>找到WhatsApp Service>關閉它。",
    WhatsAppOthers: "<strong>其他手機：</strong> 進入設定>應用程式>找到WhatsApp Service>進階設定>電池優化>再次找到WhatsApp Service並且禁用。",
    WhatsAppHuawei1: "<strong>華為：</strong> 進行設定>然後在任務進程中找到WhatsApp Service。向下滑動應用程序界面以將其鎖定。然後，點擊應用程序界面返回設置。",
    WhatsAppOppo1: "<strong>Oppo: </strong> 進行設定>然後在任務進程中找到WhatsApp Service。點擊右上角的設置圖標。點擊鎖定。然後，點擊應用程序界面以返回設置。",
    WhatsAppXiaomi1: "<strong>小米：</strong>進行設定>然後在任務進程中找到WhatsApp Service。長按應用程序標籤並將其鎖定。然後，點擊應用程序界面以返回設置。",
    WhatsAppStartMonite: '現在點擊開始監看的按鍵，應用程式的圖示將會很快消失。對於Android 10或更高的版本，應用程式的圖示會被"WLAN"圖示所取代，最好將此圖示隱藏在不顯眼的資料夾中。',
    WhatsAppVerify: "在完成安裝之後驗證你的設定",
    MoniteSupportTeam: '如果所有設定都完成，請點擊下方的"驗證設定"按鍵，此動作將會連結目標裝置到你的線上帳號，目前畫面將會跳到網頁面板，你可以在此監看所有檔案。如果無法成功安裝此應用程式，請聯繫支援團隊。',
    DataWarn: "數據使用警告",
    DataTerm: "使用行動數據上傳大型檔案(相片、影片、音檔和文件)可能會在目標手機上產生額外的網路收費，並暴露你在追蹤WhatsApp的事實。是否確定仍要透過行動數據上傳媒體檔案?",
    DataTermNote: "<strong>注意：</strong> 聊天對話，動態，通話和WhatsApp活動不受此條件的影響。 默認情況下，這些文件是透過行動數據上傳的。",
    ScreenTime: "WhatsApp使用螢幕時間(過去7天)",
    WhatsAppChats: "WhatsApp對話",
    WhatsAppCalls: "WhatsApp通話",
    Calls: "通話",
    CallRecording: "通話錄音",
    WhatsAppStatus: "WhatsApp動態",
    WhatsAppPhotos: "WhatsApp相片",
    WhatsAppVideos: "WhatsApp影片",
    WhatsAppVoices: "WhatsApp語音",
    Voices: "語音",
    WhatsAppDocuments: "WhatsApp文件",
    Documents: "文件",
    WhatsAppActivities: "WhatsApp活動",
    Activities: "活動",
    WhatsAppScreenshots: "WhatsApp螢幕截圖",
    Screenshots: "螢幕截圖",
    uploadTle: "大型檔案(如相片、影片、音檔和文件)將默認狀態下使用Wi-Fi上傳，您可以在“我的產品”>“設定”中進行更改。",
    searchCallerName: "搜索來電者姓名",
    CallerName: "通話者姓名",
    onlyCover: "* iSeeGuard WhatsApp僅會上傳影片封面",
    screenFeature: "開始使用自動螢幕截圖功能",
    screenFeatureOne: "此功能將會自動將WhatsApp畫面進行截圖，所以此功能只會在目標使用者使用WhatsApp的時候被啟用。",
    screenFeatureTwo: "你可以選擇何時上傳螢幕截圖。藉著使用Wi-Fi上傳功能，可以更頻繁地使用螢幕截圖，這能避免目標手機產生額外的網路收費，另一個則相反。",
    UploadWiFi: "透過Wi-Fi上傳",
    MoreConsumption: "更多電力消耗",
    fasterfrequency: "螢幕截圖頻率更快",
    nomoBileData: "不消耗行動數據",
    UploadMobileDat: "透過行動數據上傳",
    LessPowerConsumption: "較少電力消耗",
    lowerFrequencyScreenshots: "螢幕截圖頻率較低",
    usemobiledata: "使用更多行動數據",
    SuccessfullySet: "設定成功",
    SetSucess: "你已經成功設定自動螢幕截圖功能，當目標使用者使用WhatsApp並且有網路連線時，螢幕截圖將會進行上傳，請耐心等候。",
    wifi: "Wi-Fi",
    wifiText: "WhatsApp螢幕截圖將會透過Wi-Fi上傳。",
    MobileData: "行動數據",
    MobileDataText: "WhatsApp螢幕截圖將會透過行動數據上傳。",
    WhatsAppDownload: "媒體檔案可以在各個功能中直接下載。",
    tryagain: "請求超時，請稍後再次嘗試。",
    beingUpload: "數據正在上傳，可能需要一些時間才能完全顯示，請稍後刷新頁面。",
    Chat: "對話",
    CallRecorde: "通話錄音",
    Play: "播放",
    AudioCall: "語音通話",
    VideoCall: "視訊電話",
    startTle: "開始使用WhatsApp通話錄音功能",
    startTleOne: "<strong>1.</strong> 此功能使用麥克風來錄製WhatsApp的語音或視訊通話，所以除非揚聲器(擴音)開啟，否則你可能無法聽到通話另一端的聲音。",
    startTleTwo: "<strong>2.</strong> 在默認的狀態下，錄音會自動透過Wi-Fi上傳，這是要避免使用行動數據產生的額外收費，並保護你免於被發現，你可以在“我的產品-設定>資料上傳”更改此設定。",
    startTleThree: "<strong>3.</strong> 此功能可以一次錄音長達20分鐘，所以如果通話時間超過20分鐘，錄音將會被分隔為數個音檔上傳。",
    Thumbnail: "縮圖",
    UpdateOverWifi: "透過Wi-Fi上傳大型檔案",
    wifiUpload: "請注意，大文件（相片、影片、音檔和文件）將在目標手機使用Wi-Fi網絡時上傳。",
    wifiNote: "<strong>注意：</strong>聊天對話，動態，通話和WhatsApp活動不受此條件的影響。 默認情況下，這些文件是透過行動數據和Wi-Fi上傳的。",
    ChatContact: "聊天聯絡人",
    Content: "內容",
    State: "狀態",
    screenTime: "螢幕時間",
    whatAppPathOne: "到設定 > 協助工具 > WhatsApp Service > 確認為開啟",
    whatAppPathTwo: "到設定 > 安全性 > 其他安全設定 > 裝置管理員 > WhatsApp Service > 確認為開啟",
    whatAppPathThree: "到設定 > 應用程式 > 找到WhatsApp Service > 許可權 > 儲存空間 > 確認為開啟",
    whatAppPathFour: "到設定 > 應用程式 > 找到WhatsApp Service > 通知 > 確認為開啟",
    whatAppPathFive: "到設定 > 找到安全性 > 其他安全設定 > 使用數據存取 > WhatsApp Service > 確認為開啟",
    whatAppPathSix: "到設定 > 應用程式 > 找到WhatsApp Service > 許可權 > 麥克風 > 確認為開啟",
    whatsAppSetupError: "* 抱歉，驗證失敗。請確認你已經成功安裝iSeeGuard for WhatsApp應用程式到目標手機。",
    monitorReal: "讓您真正地監視他人的WhatsApp。",
    mustFinshSet: "*您尚未完成設定。 請選擇在Wi-Fi或行動數據下上傳螢幕截圖。",
    yourEmail: "你的電子郵件：",
    whatsAppDelete: "為了保護您的個人隱私，iSeeGuard 將停止監控並刪除所有數據。",
    Store: "購買中心",
    monitorAndroidDevice: "監控Android装置",
    androidMoniteName: "iSeeGuard Pro-Android監控",
    whatsAppMoniteName: "iSeeGuard-WhatsApp監控",
    monitoriOSDevice: "監控iOS装置",
    iPhoneMoniteName: "iSeeGuard Pro-iPhone監控",
    iCloudMoniteName: "iSeeGuard Pro-iCloud監控",
    cancelNote: "<strong>注意：</strong> 取消自動續訂后，您的帳戶可正常使用至到期日。 如果您想繼續使用我們的服務，則需要手動续订。",
    whatsAppSync: "暫時無法同步數據。在目標手機開啟並連上網路，且iSeeGuard for WhatsApp應用程式在目標裝置上正常運作的情況下，資料將會開始進行同步。",
    SyncErrWhy: "為什麼iSeeGuard for WhatsApp無法同步新數據?",
    FirstReason: "首先，iSeeGuard for WhatsApp會每十五分鐘自動同步新數據。其次，您可以點擊同步按鈕以刷新資料。同步數據所需要的時間將取決於數據的大小與目標裝置的網路連線速度。",
    ByDefualt: "默認狀態下，大型檔案(如相片、影片、音檔和文件)將透過Wi-Fi網絡上傳。文字聊天紀錄，文字動態，通話紀錄與WhatsApp活動則不受此條件限制。您也可以在“我的產品”>“設定”中進行更改此設定。",
    ReasonExample: "如果無法檢視更新的數據，以下是發生此狀況可能的原因。",
    NonProductReasons: "與產品無關的原因：",
    NonProductReasons1: "目標裝置沒有開啟。",
    NonProductReasons2: "目標裝置沒有連接到網路或網路連線不穩定。",
    NonProductReasons3: "WhatsApp應用程式在目標裝置上被卸載。",
    NonProductReasons4: " WhatsApp應用程式沒有被使用。",
    NonProductReasons5: " 目標裝置上的日期和時間與本地時間不壹致。 請在目標裝置上前往“設置”>“常規管理”>“日期和時間”>“啟用自動日期和時間”。",
    ProductReasons: "產品原因：",
    ProductReasons1: "iSeeGuard for WhatsApp應用程式在目標裝置上休眠或已被卸載，如為此情形，請將手機重新開機或重新安裝此應用程式。",
    ProductReasons2: "在目標裝置上iSeeGuard for WhatsApp應用程式正常運行所需的許可未啟用，尤其是協助工具許可和儲存許可。如果為此情形，請到檢查權限並根據提示路徑啟用被關閉的許可。",
    SelectAll: "選擇",
    DeleteFiles: "刪除檔案",
    DeleteConfirm: "您確定要刪除檔案嗎？",
    DeleteSuccess: "刪除成功！",
    DownloadFiles: "下載檔案",
    DownloadConfirm: "您確定要下載檔案嗎？",
    DownloadTips: "<strong>註意</strong>：下載的檔案將從儀表板上刪除。",
    DownloadWaiting: "根據檔案的大小，可能需要幾分鐘才能完成。",
    DownloadSuccess: "下載成功！",
    DownloadSuccessTips: " 您可以在下載歷史記錄中找到下載的檔案。",
    DownloadFailed: "下載失敗。 請在穩定的網絡下重試。",
    DeleteFailed: "刪除失敗。 請再試壹次。",
    Processing: "處理中...",
    ToppingTips: "點擊以標記此對話",
    MonStepFollowTitle: "跟著以下步驟啟用產品",
    MonStepImportantTitle: "重要資訊：",
    MonStepTitle1: "下載程式到目標電腦上",
    MonStepTitle2: "安裝軟體並完成配置",
    MonStepTitle3: "安裝Chrome擴充程式(選擇性的)",
    MonStepTitle4: "到線上介面進行監看",
    MonStepImportant1: "<b>1.</b> 你需要實際操作目標電腦。",
    MonStepImportant2: "<b>2.</b> 不要將本產品用於非法目的。",
    MonStepImportant3: "<b>3.</b> MoniVisor包含兩部分：桌機軟體與線上控制台。",
    MonStepImportant4: "<b>4.</b> 我們強烈建議你將桌機軟體新增到防毒軟體或安全防護軟體中的安全名單，以預防程式被刪除或其功能無法正常的運作。",
    MonStepImportant5: "<b>5.</b> 建議你使用私密瀏覽模式來下載此工具或清除瀏覽紀錄來避免留下任何進行安裝的紀錄。",
    MonStep1Desc: 'MoniVisor for Win透過目標電腦上的隱藏軟體與線上控制台來運作。開始使用之前，你需要實際操作目標電腦並將這個URL( <b style="color: #347aeb">http://www.installfree.net</b> )貼到任何瀏覽器中以進行軟體下載。',
    MonStep2Desc: "下一步，安裝軟體並跟著螢幕上的指引完成配置。",
    MonStep2Note: '<b>注意：</b> Windows User Access或SmartScreen可能會提醒你，要求執行此軟體的許可，如果提醒出現，點擊 “是”、“執行”" 或 “直接執行”，如果選項 “是”或 “執行”沒有出現，點擊 “更多資訊” 來顯示選項。',
    MonStep2li_1: "安裝軟體會花費一些時間。",
    MonStep2li_2: "使用許可帳號和密碼啟用軟體。",
    MonStep2li_3: "如果程式偵測到電腦上有防毒軟體，請跟著提供的指示將MoniVisor for Win新增到安全白名單，完成之後你就會發現軟體自動進入隱藏運作模式。",
    MonStep3Desc: "如果目標電腦上默認的瀏覽器是Chrome，那麼你會需要擴充程式來監看網頁郵件(例如：Gmail、Outlook或Yahoo Mail)與網路聊天訊息(例如：WhatsApp、Facebook、Twitter、Instagram或Skype)，我們高度推薦你安裝此擴充程式來增加軟體監看的能力。要完成此操作，請跟著以下指引。",
    MonStep3li_1: "打開Chrome瀏覽器。",
    MonStep3li_2: "點擊瀏覽器右上方3個點的選項-<b>更多工具</b>-<b>擴充清單</b>。",
    MonStep3li_3: "點擊瀏覽器右上方的滑桿鍵開啟開發人員模式。",
    MonStep3li_4: "<b>“載入未封裝項目”</b> 按鈕就會出現在左上角。",
    MonStep3li_5: "點擊 <b>“載入未封裝項目”</b>，然後到 <b style=\"color: #347aeb;\">C:\\Program Files (x86)\\Windows Assistant App\\MV\\extension</b> 'moni-chrome-extension'</b> 文件夾。 \n'",
    MonStep3li_6: "擴充程式已經安裝在電腦上。",
    MonStep3li_7: "關閉 “開發人員模式” 來完成該過程。",
    MonStep3li_8: "如果擴充程式安裝成功，你將會看到它出現在擴充列表上。\n",
    MonStep4Desc1: "現在，已經完成目標電腦上的必要安裝步驟，你會發現此工具自動進入隱藏運作模式，且它會持續在後台中運作。",
    MonStep4Desc2: "你可以使用任何瀏覽器到iSeeGuard網站登入你的帳號(使用你自己的電腦、平板或手機)，或點擊下方的驗證安裝按鈕來快速連結你的帳號，目前的頁面將會跳轉至網頁面板，你可以在此檢查所有的數據。如果你無法成功安裝，請聯繫 支援團隊。",
    MonStepError: "* 抱歉，驗證失敗。請確定MoniVisor輔助軟體已經安裝在目標電腦上。",
    MonVerifySetup: "驗證安裝",
    MonUninstallBigTitle: "MoniVisor 桌面軟體解除安裝指南",
    MonUninstallTitle: "我們提供兩種方式解除安裝目標電腦上的軟體。通過點擊下方的按鈕進行遠程解除安裝或直接操作目標電腦手動解除安裝。",
    MonUninstallNote: "注意：",
    MonUninstallNote1: "1. 遠程解除安裝功能僅能在目標裝置在綫時才能使用。",
    MonUninstallNote2: "2. 解除安裝軟體將不會解綁裝置，也不會刪除監看數據，但你將不會再收到新數據，如果想要徹底解綁裝置，可以到設定頁面然後點擊“解綁裝置”按鈕。",
    MonUninstallMethod1: "方法1：一鍵點擊遠程解除安裝。",
    MonUninstallMethod1Desc: "只要點擊下方按鈕，電腦的軟體將會自動執行命令。",
    MonUninstallMethod2: "方法2：手動解除安裝。",
    MonUninstallMethod2Desc: '應用程式安裝路徑：<b style="color: #347aeb;">C:\\Program Files (x86)\\Windows Assistant App\\MV</b>',
    MonUninstallTheStep: "步驟有：",
    MonUninstallTheStep1: "步驟1：到目標電腦並輸入上面完整的安裝路徑來找到檔案。",
    MonUninstallTheStep2: '步驟2：點擊檔案<b style="color: #347aeb;">unins000.exe</b> 執行解除安裝命令。',
    MonUninstallTheStep3: "步驟3：不需要其他動作，只要將電腦重新啟動，你就可以成功將軟體解除安裝了。",
    MonUninstallError: "抱歉，目標電腦現在處於離線狀態。請從儀表板或設定頁面檢查電腦狀態，當目標電腦在綫時再試一次。",
    MonUninstallTip: "這將不會解綁你的裝置，但是你將不會再收到任何新數據。",
    MonUninstallProblem: "你確定要解除目標電腦上的軟體嗎?",
    MonUninstallU: "解除安裝",
    MonUninstallSuccess: "恭喜你！你已成功解除安裝軟體。",
    MonUnbundlingProblem: "你確定要解綁裝置嗎?",
    MonUnbundlingTip: "出於安全考量，所有數據將會被刪除。",
    MonUnbundlingSuccess: "恭喜你！你已成功解綁裝置。",
    MonInstallationHelp: "安裝協助文件",
    MonInstallationDesc: "避免軟體被目標電腦刪除，這裡有為你準備關於如何新增軟體資料夾與檔案到防毒軟體安全白名單、信任名單或檔案與資料夾排除清單的指引。安裝MoniVisor Chrome 擴充功能的指引也會包含在內，請仔細閱讀下列步驟。",
    MonInstallationHowTo1: "如何新增防毒軟體安全名單",
    MonInstallationHowTo2: "如何安裝MonVisor Chrome擴充程式",
    MonInstallationHowTo3: "如何新增防毒軟體安全名單的指引",
    MonInstallationStepTitle: "雖然基本過程都相同，但不同的防毒軟體包可能會有不同的特定指引。如何操作的步驟如下：",
    MonInstallationStep1: '<span>步驟1：</span>清楚了解MoniVisor安裝在哪裡：<b style="color: #347aeb;">C:\\Program Files (x86)\\Windows Assistant App</b>',
    MonInstallationStep1Desc: '檔案名稱為：<b style="color: #347aeb;">Setupsvc.exe</b>',
    MonInstallationStep2: "<span>步驟2：</span>請了解目標電腦上安裝的是哪一種防毒軟體，並確認下方官方版的指引來進行特定防毒軟體包的排除清單配置。",
    MonInstallationStep2Desc: "如何安裝MoniVisor Chrome擴充程式的指引",
    MonWebChatsOfflineTip: "目標電腦現在離線中，上線之前將不會更新數據。",
    MonWebChatsOfflineTipDesc: "什麼是離線? 這可能會發生在目標裝置未連接到網路、電腦關機、軟體已經從目標電腦上解除安裝或軟體被不知名的原因刪除掉的時候。",
    MonWebChatsNote1: '注意：請確認目標電腦在線並且Google Chrome擴充程式已經安裝完成。點擊<a style="color:#347aeb" href="/monivisor-installation-guide-win" target="_blank">這裡</a>以獲得安裝擴充程式的完整指引。',
    MonScreenClickTitle: "MoniVisor螢幕截圖功能入門",
    MonScreenClickDesc: "點擊開始來截取目標電腦的螢幕照片，<br/>螢幕截圖將會每15秒截取一張，維持5分鐘。",
    MonScreenClickNote: "注意：",
    MonScreenClickNote1: "1. 此功能僅能在目標電腦上線的時候使用。",
    MonScreenClickNote2: "2. 你可以在開始後的5分鐘內停止螢幕截圖，如果你不想手動停止，它將會自動於5分鐘後停止，然後你可以再次開始截圖。",
    MonScreenClickError1: "目標電腦離線中，請確認裝置的狀態，然後在裝置上線後再試一次。",
    MonScreenClickError2: "你確定要刪除螢幕截圖嗎?",
    MonScreenSelectAll: "選擇全部",
    MonScreenDeleting: "刪除中…",
    MonScreenDeleteFiles: "刪除檔案",
    MonScreenSuccess1: "成功！你已經刪除 ",
    MonScreenSuccess2: " 螢幕截圖",
    MonFileAction: "指令",
    MonFileFileName: "檔案名",
    MonFileDate: "日期",
    MonLoginUserName: "用戶名",
    MonLoginActivityTh: "活動",
    MonLoginPrinterName: "列印機名稱",
    MonLoginFileName: "檔案名",
    MonLoginPages: "頁數",
    MonExportDataSetting: "數據設定:",
    MonExportDeleteExportedData: "刪除已匯出的數據",
    MonExportDesc1: "1. 在網路聊天紀錄中的媒體檔案只能以文字形式被匯出。",
    MonExportDesc2: "2. 螢幕截圖可以在結果頁面中被批量下載。",
    MonPurchaseBuyWin: "購買最符合您需求的MoniVisor電腦監控的計劃",
    MonPurchaseGuideType2: "看不見的Windows電腦監看軟體。",
    MonMyProductPageDesc1: "一個遠程檢查所有Windows電腦上檔案的監看工具。",
    MonMyProductPageDesc2: "透過無法被偵測的運作模式與線上控制台來隨時隨地瀏覽數據。",
    MonDashboardName: "名字",
    MonDashboardComputerID: "電腦ID",
    MonDashboardLatestEmails: "最新的電子郵件",
    MonDashboardMore: "更多",
    MonDashboardLatestBrowsingHistories: "最新的瀏覽紀錄",
    MonDashboardSender: "寄件者",
    MonDashboardSubject: "主旨",
    MonDashboardDate: "日期",
    MonDashboardDomain: "域名",
    MonDashboardTitle: "標題",
    MonSettingsUninstallGuide: "解除安裝指引",
    MonSettingsHelpDocument: "安裝協助文件",
    MonSettingsSoftwareVersion: "目標電腦上的軟體版本",
    MonSettingsWhat: "最新資訊",
    MonRequestLong: "我們正在處理你的請求，請稍等並再試一次。",
    MonUnknown: "未知",
    MoniVisor: "MoniVisor",
    MoniVisorWebActivity: "網路活動",
    MoniVisorActivityChrome: "Chrome",
    MoniVisorActivityFirefox: "火狐",
    MoniVisorActivityEdge: "Microsoft Edge",
    MoniVisorActivityIE: "Internet Explorer",
    MoniVisorActivityOpera: "Opera",
    MoniVisorDownloadHistory: "下載紀錄",
    MoniVisorWebChats: "網路聊天",
    MonAppActivity: "應用程式活動",
    MonKeylogger: "鍵盤紀錄器",
    MonScreenshot: "螢幕截圖",
    MonScreenshotSet: "螢幕截圖設定",
    MonScreenshotView: "瀏覽螢幕截圖",
    MonLoginActivity: "登入活動",
    MonPrintActivity: "列印活動",
    MonUsbConnection: "USB連結",
    MonDataExport: "數據匯出",
    MonComputer: "監控電腦",
    MonFileActivity: "文件活動",
    MonRemaining: "剩餘時間：",
    MonScreenIngError: "屏幕截圖任務已在進行中。請稍後再試。",
    MonDataExportOnly: "一次只能導出2000條記錄。",
    MonSomeTime: "同步數據可能需要一些時間。請耐心等待...",
    MonLastUsed: "上次使用",
    MonFilePath: "檔案路徑",
    MonTimeUsage: "時間使用",
    MonReceived: "已收到",
    MonSent: "已寄出",
    MoniVisorWebChatsWhatsApp: "WhatsApp",
    MoniVisorWebChatsFacebook: "Facebook",
    MoniVisorWebChatsTwitter: "Twitter",
    MoniVisorWebChatsInstagram: "Instagram",
    MoniVisorWebChatsSkype: "Skype",
    MoniVisorWebChatsPinterest: "Pinterest",
    MonWebMail: "網路郵件",
    MonWebMailGmail: "Gmail",
    MonWebMailOutlook: "Outlook",
    MonWebMailYahoo: "Yahoo",
    MonDataExportWebActivityChrome: "網路活動 - Chrome",
    MonDataExportWebActivityIE: "網路活動 - IE",
    MonDataExportWebActivityEdge: "網路活動 - Edge",
    MonDataExportWebActivityOpera: "網路活動 - Opera",
    MonDataExportWebActivityFirefox: "網路活動 - Firefox",
    MonDataExportDownloadHistoryChrome: "下載紀錄 - Chrome",
    MonDataExportDownloadHistoryIE: "下載紀錄 - IE",
    MonDataExportDownloadHistoryEdge: "下載紀錄 - Edge",
    MonDataExportDownloadHistoryOpera: "下載紀錄 - Opera",
    MonDataExportDownloadHistoryFirefox: "下載紀錄 - Firefox",
    MonScreenStop: "停止",
    MonScreenMaxDownTip: "* 一次最多可以下載50張圖像，並且下載的數據將被刪除。",
    MonWebMailAttachments: "附件路徑",
    MonWebMailFrom: "從",
    MonKeyloggerTip: '注意：請檢查以確保目標計算機處於聯機狀態，並且該軟件尚未被防病毒軟件殺死。單擊<a style="color:#347aeb" href="/monivisor-installation-guide-win" target="_blank">此處</a>以獲取完整指南，以將該軟件添加到防病毒白名單中。',
    MonInsert: "插入",
    MonEject: "拔出",
    MonLogon: "登入",
    MonLogoff: "註銷",
    MonCreate: "創建",
    MonCopy: "複製",
    MonDelete: "刪除",
    MonRename: "改名",
    MonImage: "圖片",
    MonVideo: "視頻",
    MonFile: "文檔",
    MonLocation: "位置",
    MonPasteNote: "<b>注意:</b> 您需要粘貼完整的文件安裝路徑或暫時允許在Windows File Explorer中查看隱藏的文件夾，才能從防病毒應用程序內部手動選擇文件夾。根據不同防病毒軟件的要求，將安裝路徑下的MV文件夾或給定的完整文件名添加到白名單。",
    kdsMoniVisor: "MoniVisor for Win",
    MonMisseVoice: "未接語音電話",
    MonMisseVideo: "未接視頻通話",
    MonContact: "聯絡人",
    MonMonitorComputers: "監控計算機",
    MonWindowsMonitoring: "MoniVisor - Windows監控",
    ParamError: "參數錯誤",
    TokenError: "登錄無效",
    UserVIPExpired: "用戶會員資格已過期或不存在",
    NotfoundHttp: "請求的地址不存在",
    ServerError: "服務器錯誤",
    HeadersMiss: "缺少 headers 參數",
    MonitorNow: "立即監控",
    AboutUs: "關於我們",
    PrivacyPolicy: "隱私政策",
    TermsConditions: "條款和條件",
    Disclaimer: "免責聲明",
    DisclaimerDesc: "ISEEGUARD 的軟件僅供合法使用。<br />在您沒有監控權的設備上安裝此許可軟件可能會違反您所在國家或地區的法律。 您對下載、安裝和使用它負全部責任。 如果您未經允許選擇監控設備，iSeeGuard 概不負責。 此處未明確授予的所有權利均由 iSeeGuard 保留。",
    Refuse: "拒絕",
    DialOut: "撥出",
    IncomingCall: "來電",
    RenewDialogTips: "該演示無法進一步操作。 您可以註冊一個帳戶併購買一個計劃來享受所有高級監控功能。",
    Permanent: "永久",
    Capacity: "容量",
    Clipboard: "剪貼板",
    SensitiveWord: "敏感詞",
    AppUsed: "使用過的應用程序",
    AppUsedToday: "今天使用的應用程序",
    Add: "添加",
    Modify: "調整",
    Operate: "操作",
    MacStepTitle2: "安裝軟體",
    MacStepTitle3: "完成配置",
    MacStepTitle5: "完成安裝",
    MacStepOneDesc: "使用目標設備上的瀏覽器打開 <strong>www.iseeguard.com</strong> 並下載 iSeeGuard Pro for Mac 應用程序。",
    MacStepTwoDesc: "<strong>注意：</strong>建議您直接將其下載到您要監視的人的計算機上。 如果您可以不時物理訪問目標MAC，您也可以在自己的計算機上下載和監視它。",
    MacStepThreeDesc: "下載後，點擊pkg文件開始安裝過程。",
    MacStepFourDesc: "請選擇您需要監控的內容，點擊下一步繼續。",
    MacStepFiveDesc: "Mactrack 已安裝。",
    MacStepSixDesc: "登錄到您的帳戶。",
    ClickHere: "點擊這裡",
    IosStepOneDesc: "在目標設備上使用瀏覽器打開 <strong>panel.iseeguard.com/store/ios</strong> 並下載 iSeeGuard Pro for iOS 應用程序。",
    IosStepTwoDesc: "<strong>注意：</strong>建議您直接將其下載到您要監視的人的計算機上。 如果您可以不時物理訪問目標iOS，您也可以在自己的計算機上下載和監視它。",
    IosStepTitle3: "從 iTunes 備份數據",
    IosStepThreeDesc: "下載iTunes軟件，將需要監控的手機連接到iTunes軟件，進行數據備份。",
    IosStepTitle5: "從目標 iPhone 掃描數據",
    IosStepTitle6: "分析數據",
    IosStepTitle7: "開始監視計算機上的目標 iPhone",
    IosStepFiveDesc: "如果計算機上有可用的備份文件，則 ISeeGuard Pro 將顯示找到的備份。 您可以選擇目標設備的備份文件並從中掃描數據。",
    IosStepSixDesc: "單擊“下一步”，顯示分析數據頁面。 第一次數據分析的等待時間會更長。",
    IosStepSevenDesc: "掃描過程完成後，所有掃描的信息將顯示在儀表板上，您可以在不同的文件類型之間切換並查看詳細信息。 此外，您可以將所有數據導出到計算機。",
}
