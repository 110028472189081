import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'

// 引入element
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss' // global css
import 'mapbox-gl/dist/mapbox-gl.css';

import xss from 'xss'
import i18n from './lang' // internationalization
import './icons' // icon
import './permission'

import datePlugin from "@/middleware/datePlugin"

// 图片预览
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
Vue.use(VueViewer)

// dayjs
Vue.use(datePlugin)
// import VueLazyload from 'vue-lazyload'

// ? 图片懒加载
// Vue.use(VueLazyload)

// ? 解决v-html 存在xss 漏洞问题
Vue.prototype.xss = xss;

Vue.use(Element, {
  i18n: (key, value) => i18n.t(key, value)
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})
