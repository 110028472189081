/*
 * @Author: withering 
 * @Date: 2021-07-03 17:11:08 
 * @Last Modified by:   withering 
 * @Last Modified time: 2021-07-03 17:11:08 
 */
import request from "@/utils/request"
import qs from "qs"
/**
 * 获取地理围栏列表
 */
export function getList(equipmentId, params = {}) {
    // return request.get('/geofence/' + equipmentId, params);
    return request({
        url: '/geofence/' + equipmentId,
        method: 'get',
        params
    })
}
/**
 * 创建地理围栏
 * @param {*} equipmentId 
 * @param {*} params 
 * @returns 
 */
export function store(equipmentId, params = {}) {
    // return request.post('/geofence/' + equipmentId, params);
    return request({
        url: '/geofence/' + equipmentId,
        method: 'post',
        data: qs.stringify(params)
    })
}
/**
 * 获取地理围栏详情
 * @param {*} equipmentId 
 * @param {*} id 
 * @returns 
 */
export function show(equipmentId, id) {
    // return request.get('/geofenceDetails/' + equipmentId, {
    //     id: id
    // });
    return request({
        url: '/geofenceDetails/' + equipmentId,
        method: 'get',
        params: {
            id: id
        }
    })
}
/**
 * 更新地理围栏
 * @param {*} equipmentId 
 * @param {*} params 
 * @returns 
 */
export function update(equipmentId, params = {}) {
    // return request.put('/geofence/' + equipmentId, params);
    return request({
        url: '/geofence/' + equipmentId,
        method: 'put',
        data: qs.stringify(params)
    })
}

/**
 * 删除地理围栏
 * @param {*} equipmentId 
 * @param {*} id 
 * @returns 
 */
export function del(equipmentId, id) {
    // return request.del('/geofence/' + equipmentId, {
    //     id: id
    // });
    return request({
        url: '/geofence/' + equipmentId,
        method: 'delete',
        params: {
            id: id
        }
    })
}
/**
 * 获取指定地理围栏记录
 * @param {*} equipmentId 
 * @param {*} id 
 * @returns 
 */
export function getRecords(equipmentId, id, page = 1) {
    // return request.get('/geofence/' + equipmentId + "/records", {
    //     geofence_id: id,
    //     page: page
    // });
    return request({
        url: '/geofence/' + equipmentId + "/records",
        method: 'get',
        params: {
            geofence_id: id,
            page: page
        }
    })
}
/**
 * 更新地理围栏状态
 * @param {*} equipmentId 
 * @param {*} id 
 * @param {*} status 
 * @returns 
 */
export function updateStatus(equipmentId, id, status) {
    // return request.put('/geofence/updateStatus/' + equipmentId, {
    //     id: id,
    //     status: status
    // });
    return request({
        url: '/geofence/updateStatus/' + equipmentId,
        method: 'put',
        data: qs.stringify({
            id: id,
            status: status
        })
    })
}