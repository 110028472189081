<template>
  <div class="footer">
    <div class="containet">
      <div class="footer-boxone">
        <div class="footerItem">
          <div class="logo">
            <img src="~@/assets/images/footer/iseeguard.png" alt="" />
          </div>
          <div class="copyright">{{ $t("footCot") }}</div>
          <!-- <div class="icon-box">
            <div class="inco">
              <a href="/">
                <img src="~@/assets/images/footer/share_facebook.png" alt="" />
              </a>
            </div>
            <div class="inco">
              <a href="/">
                <img src="~@/assets/images/footer/share_twitter.png" alt="" />
              </a>
            </div>
            <div class="inco">
              <a href="/">
                <img src="~@/assets/images/footer/share_youtube.png" alt="" />
              </a>
            </div>
            <div class="inco">
              <a href="/">
                <img src="~@/assets/images/footer/follow_instagram.png" alt="" />
              </a>
            </div>
            <div class="inco">
              <a href="/">
                <img src="~@/assets/images/footer/follow_pinterest.png" alt="" />
              </a>
            </div>
          </div> -->
        </div>
        <div class="footerItem">
          <a
            href="https://www.iseeguard.com/about-us/"
            target="_blank"
            rel="noopener noreferrer"
            >{{ $t("AboutUs") }}</a
          >
          <a
            href="https://www.iseeguard.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
            >{{ $t("PrivacyPolicy") }}</a
          >
          <a
            href="https://www.iseeguard.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
            >{{ $t("TermsConditions") }}</a
          >
        </div>
      </div>
      <div class="affirm">
        <div class="top-affirm">{{ $t('Disclaimer') }}</div>
        <div class="bottom-affirm">
          <p v-html="$t('DisclaimerDesc')"></p>
          <div>
            <CopyRight />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CopyRight from "@/components/common/CopyRight"
export default {
  name: "Footer",
  components: {
    CopyRight
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@media only screen and (max-width: $lg) {
  .footer-boxone {
    justify-content: flex-start;
  }
}
@media only screen and (max-width: $md) {
  .footer-boxone {
    justify-content: flex-start;
    flex-wrap: wrap;

    .footerItem{
      >a{
        margin-top: 10px;
      }
    }
  }
}
@media only screen and (max-width: $sm) {
  .footer-boxone {
    justify-content: flex-start;
    .footerItem{
      flex-wrap: wrap;
    }
  }
}
.footer {
  width: 100%;
  font-size: 14px;
  background: #313739;
}
.containet {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.footer-boxone {
  padding-top: 50px;
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.footerItem {
  display: flex;
  align-items: center;

  .copyright {
    font-size: 17px;
    color: #b1b7bf;
    line-height: 24px;
    margin-left: 20px;
  }
  > a {
    font-size: 17px;
    color: #fff;
    margin: 0 20px;
  }
}
.affirm {
  text-align: center;
  color: #fff;
  padding-top: 30px;
  border-top: 1px solid #464b4d;
  .top-affirm {
    font-size: 18px;
    line-height: 26px;
  }
  .bottom-affirm {
    font-size: 12px;
    line-height: 22px;
    color: #b1b7bf;
    padding-top: 20px;
    text-align: left;
    padding-bottom: 30px;

    > div {
      font-size: 14px;
      text-align: center;
      margin-top: 10px;
    }
  }
}

</style>
