<template>
  <div name="Headers">
    <div class="headers">
      <svg-icon icon-class="four-squares" class-name="log-img" />
      <div class="img-tle" @click="nextPagehandle()">
        &lt; {{ $t("myProduct") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name:"Headers",
    data() {
        return {
            
        }
    },
    methods: {
        nextPagehandle() {
            this.$emit('nextPagehandle')
        }
    }
}
</script>

<style scoped lang="scss">
.headers {
  width: 300px;
  background-color: #347aeb;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  padding-left: 30px;
  align-items: center;
  font-size:14px;
  .log-img {
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }
  .img-tle {
    color: #333333;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
  }
}
</style>