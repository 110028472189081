/*
 * @Author: withering 
 * @Date: 2021-07-03 16:09:50 
 * @Last Modified by:   withering 
 * @Last Modified time: 2021-07-03 16:09:50 
 */
import request from "@/utils/request"
/**
 * 获取日程列表
 * @param {*} equipmentId 
 * @param {*} params 
 * @returns 
 */
export function getList(equipmentId,params) {
    // return request.get('/calendars/'+equipmentId,params)
    return request({
        url: '/calendars/' + equipmentId,
        method: 'get',
        params
    })
}