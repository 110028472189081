import Vue from 'vue'
import VueI18n from 'vue-i18n'
import aes from "@/utils/aes"
import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang
import elementEsLocale from 'element-ui/lib/locale/lang/es'// element-ui lang
import elementJaLocale from 'element-ui/lib/locale/lang/ja'// element-ui lang
import enLocale from './en'
import zhLocale from './zh'
import esLocale from './es'
import jaLocale from './ja'
import deLocale from './de'
import elementDeLocale from 'element-ui/lib/locale/lang/de'// element-ui lang
import frLocale from './fr'
import elementFrLocale from 'element-ui/lib/locale/lang/fr'// element-ui lang
import ltLocale from './lt'
import elementLtLocale from 'element-ui/lib/locale/lang/lt'// element-ui lang
import nlLocale from './nl'
import elementNlLocale from 'element-ui/lib/locale/lang/nl'// element-ui lang
import ptLocale from './pt'
import elementPtLocale from 'element-ui/lib/locale/lang/pt'// element-ui lang

Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },
  zh_TW: {
    ...zhLocale,
    ...elementZhLocale
  },
  es: {
    ...esLocale,
    ...elementEsLocale
  },
  ja: {
    ...jaLocale,
    ...elementJaLocale
  },
  de: {
    ...deLocale,
    ...elementDeLocale
  },
  fr: {
    ...frLocale,
    ...elementFrLocale
  },
  lt: {
    ...ltLocale,
    ...elementLtLocale
  },
  nl: {
    ...nlLocale,
    ...elementNlLocale
  },
  pt:{
    ...ptLocale,
    ...elementPtLocale
  }
}
export function getLanguage() {
  const LANGUAGE = aes.Encrypt('language');
  const LOCAL_LANGUAGE = localStorage.getItem(LANGUAGE);
  const chooseLanguage = LOCAL_LANGUAGE ? aes.Decrypt(localStorage.getItem(LANGUAGE)) : '';
  if (chooseLanguage) return chooseLanguage

  // if has not choose language
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'en'
}
const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  // quodz/274xy5PbV23wh2uA==
  locale: getLanguage(),
  // set locale messages
  messages
})

export default i18n
