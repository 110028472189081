export default {
    namespaced: true,
    state: {
        equipmentIdLocalStorageKey:"equipmentId",
    },
    getters: {
        getEquipmentId:state=>{
            return localStorage.getItem(state.equipmentIdLocalStorageKey)
        }
    },
    mutations: {
        setEquipmentId (state,equipmentId) {
            console.log("插入:"+equipmentId)
            localStorage.setItem(state.equipmentIdLocalStorageKey,equipmentId)
        }
    }
}