/*
 * @Author: withering 
 * @Date: 2021-07-03 16:07:01 
 * @Last Modified by: withering
 * @Last Modified time: 2021-07-14 17:14:39
 */
import request from "@/utils/request"
/**
 * 获取应用聊天记录
 * @param {*} equipmentId 
 * @param {*} type 
 * @param {*} params 
 * @returns 
 */
export function getList(equipmentId, params) {
    // return request.get("/applicationChatRecord/"+equipmentId,params)
    return request({
        url: '/applicationChatRecord/' + equipmentId,
        method: 'get',
        params
    })
}