export default {
    CreateAccount: "Ein Konto erstellen",
    Email: "Email",
    Password: "Passwort",
    ConfirmPas: "Passwort bestätigen",
    SignUp: "Anmelden",
    AleadyAccount: "Haben Sie schon ein Konto?",
    Login: "Einloggen",
    Required: "Dieses Feld wird benötigt.",
    NotEmail: "Die E-Mail Adresse ist nicht gültig.",
    TwoPass: "Sie haben zwei verschiedene Passwörter eingegeben.",
    SixOne: "Das Passwort muss 6-16 Zeichen lang sein.",
    Alphabets: "Das Passwort muss Ziffern und Buchstaben enthalten",
    Space: "Das Passwort darf keine Leerzeichen enthalten.",
    Symbols: "Das Passwort darf nur aus Ziffern, Buchstaben oder Symbolen bestehen.",
    SignLoad: "Anmelden ...",
    AgainTry: "Anmeldung fehlgeschlagen. Bitte versuchen Sie es erneut.",
    EmailExists: "Die Email existiert bereits.",
    SupportToMonitor: "Kann überwachen:",
    SignIn: "Einloggen",
    IncorrectOr: "Falscher Benutzername oder falsches Passwort.",
    Forpwd: "Passwort vergessen?",
    Signtry: "Anmeldung fehlgeschlagen. Bitte versuche es erneut.",
    Restpwd: "Setzen Sie Ihr Passwort zurück:",
    SendLink: "Link zum Zurücksetzen senden",
    ReturnTo: "Zurück zum",
    DontExit: "Die Email Adresse existiert nicht.",
    CheckEmail: "Bitte überprüfen Sie Ihre E-Mail",
    JustFold: "Wir haben gerade einen Link zum Zurücksetzen des Passworts an die von Ihnen angegebene E-Mail-Adresse gesendet. Wenn Sie es nicht im Posteingang finden, überprüfen Sie Ihren Spam- oder Junk-Ordner.",
    TwoHour: 'Wenn Sie die E-Mail in den nächsten 24 Stunden nicht erhalten, wenden Sie sich an unser Support-Team unter <a href="mailto:support@iSeeGuard.com">support@iSeeGuard.com</a>',
    UnknowError: "Unbekannter Fehler",
    TryOp: "Hoppla. Gerade ist etwas schief gelaufen. Bitte versuchen Sie es erneut.",
    Retry: "Wiederholen",
    CreatNewPwd: "Neues Passwort erstellen",
    NewPwd: "Neues Kennwort",
    Confirm: "Bestätigen",
    OldPwd: "Sie haben das alte Passwort eingegeben. Bitte wählen Sie ein anderes.",
    NewPwdSucess: "Neues Passwort erfolgreich erstellt!",
    ThePage: "Die Seite wird in 5 Sekunden zur Anmeldeseite weitergeleitet.",
    ProceedNow: "Fahren Sie jetzt fort",
    UnknowTry: "Unbekannter Fehler. Bitte erneut versuchen.",
    LinkExpired: "Der Link ist abgelaufen. Bitte erneut versuchen.",
    ProfileSett: "Profileinstellungen",
    Download: "Laden Sie die iSeeGuard Pro App auf das Zielgerät herunter",
    Back: "Zurück",
    Next: "Nächster",
    Dashboard: "Dashboard",
    PhoneLogs: "Telefonprotokolle",
    CallLogs: "Anruflisten",
    Messages: "Mitteilungen",
    Contacts: "Kontakte",
    Locations: "Standorte",
    Geofence: "Geofence",
    BrowserHistory: "Browserverlauf",
    Photos: "Fotos",
    VideoPreview: "Video Vorschau",
    CaptureScreenshots: "Screenshots aufnehmen",
    AppActivity: "App-Aktivitäten",
    WiFiLogger: "Wi-Fi Logger",
    Keylogger: "Keylogger",
    Calendar: "Kalender",
    SocialApps: "Soziale Apps",
    DataExport: "Datenexport",
    Settings: "Einstellungen",
    Sync: "Synchronisieren",
    Updated: "Aktualisierte: ",
    DataLoad: "Es kann einige Zeit dauern, bis die Telefondaten synchronisiert sind. Bitte haben Sie Geduld...",
    DatanNotWhy: "Warum ist iSeeGuard Pro nicht in der Lage, neue Daten zu synchronisieren?",
    DataUpdata: "Zuerst müssen Sie beachten, dass die auf dem Zielgerät installierte Anwendung iSeeGuard Pro nicht sofort neue Daten synchronisiert, sondern alle 15 Minuten Daten sammelt. Jedes Mal, wenn Sie sich in das iSeeGuard Pro Konto einloggen oder auf das Sync-Symbol klicken, werden die Daten aktualisiert. Wie lange es dauert, bis die Daten synchronisiert sind, hängt von der Größe der Daten und der Internetverbindungsgeschwindigkeit des Zielgeräts ab.",
    DataNum: "Einige Daten werden nur über eine Verbindung zu einem Wi-Fi-Netzwerk hochgeladen: Fotos, Videovorschau, Anrufaufzeichnungen, Screenshots von Social Apps, Wi-Fi-Logger und App-Aktivitäten. Andere Daten werden über ein 3G-, 4G- oder Wi-Fi-Netzwerk hochgeladen: Standorte, Geräteinformationen, Keylogger, manuell aufgenommene Screenshots, Anrufprotokolle, Nachrichten, Kontakte, Kalender, Geofence, Browser-Verlauf und Chat-Verlauf sozialer Apps.",
    DataG: "Folgendes könnte der Grund dafür sein, wenn Sie keine aktualisierten Daten angezeigt bekommen.",
    Dataup: "Das Zielgerät ist ausgeschaltet.",
    DataOff: "Das Zielgerät ist nicht mit dem Netzwerk verbunden oder das Netzwerk ist instabil.",
    Datastrong: "Die Zugriffsberechtigung für iSeeGuard Pro ist auf dem Zielgerät deaktiviert. Wenn dies der Fall ist, gehen Sie bitte zu den Einstellungen > Zugänglichkeit > suchen Sie den System Update Services und aktivieren Sie diesen.",
    Datauninstall: "Die Speicherberechtigung für iSeeGuard Pro ist auf dem Zielgerät deaktiviert. Wenn dies der Fall ist, gehen Sie bitte zu den Einstellungen > Anwendungen > System Update Services > Berechtigungen > Speicherzugriff für die Anwendung zulassen. ",
    Dataassint: "Der iSeeGuard Pro Assistent ist inaktiv oder wurde auf dem Zielgerät deinstalliert. Wenn dies der Fall ist, starten Sie das Zieltelefon neu oder installieren Sie die App neu.",
    ActInfo: "Kontoinformation",
    ActId: "Konto-ID",
    NotEml: "Benachrichtigungsemail",
    DvcInfo: "Geräteinformationen",
    DvcName: "Gerätename",
    DvcStatus: "Gerätestatus",
    Online: "Online",
    Offline: "Offline",
    Plan: "Planen",
    ExpDate: "Ablaufdatum",
    AutoRel: "Automatische Erneuerung",
    Disable: "Deaktiviert",
    Enable: "Aktiviert",
    DvcMod: "Gerätemodell",
    Dvcson: "Betriebssystemversion des Geräts",
    GPS: "GPS",
    On: "An",
    Off: "Aus",
    BatLev: "Batterie Level",
    WiFi: "WiFi",
    TopCal: "Top 8 Anrufe",
    TopMes: "Top 8 Nachrichten",
    LastLoca: "Letzter bekannter Ort",
    AppVersion: "App Version",
    UpdateBtn: "Aktualisieren",
    SureUpdate: "Sind Sie sicher, dass Sie die App jetzt aktualisieren möchten?",
    SureUpdateTips: 'Nachdem Sie auf die Schaltfläche "Ja" unten geklickt haben, erhalten Sie auf dem Zielgerät eine Aufforderung, das App-Update zu genehmigen. Stellen Sie sicher, dass Sie das Zielgerät haben, da sonst die Tatsache angezeigt wird, dass das Gerät überwacht wird.',
    UpdateSuccess: "Das Update wird automatisch heruntergeladen. Bitte überprüfen Sie den Vorgang und genehmigen Sie die Installation auf dem Zielgerät.",
    UseLastetVersion: "Sie verwenden die neueste Version dieser Anwendung. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
    InUpdating: "Die App wird aktualisiert und Sie können den Vorgang auf dem Zielgerät überprüfen. Bitte warten Sie ein paar Minuten.",
    UpdateFailed: "Das Update ist fehlgeschlagen, weil das Zielgerät nicht mit dem Netzwerk verbunden ist oder die App vorübergehend nicht reagiert.",
    Name: "Name",
    PhoneNumber: "Telefonnummer",
    Type: "Art",
    Incoming: "Eingehend",
    Cancelled: "Abgebrochen",
    Outgoing: "Ausgehend",
    Duration: "Dauer",
    Date: "Datum",
    All: "Alles",
    Today: "Heute",
    ThisMonth: "Diesen Monat",
    LastMonth: "Letzten Monat",
    LastYear: "Letztes Jahr",
    Search: "Suche",
    To: "An",
    Phone: "Telefon",
    Work: "Arbeit",
    Home: "Zuhause",
    Others: "Andere",
    Mail: "Mail",
    Birthday: "Geburtstage",
    Anniversary: "Jahrestag",
    Address: "Adresse",
    LonLat: "Längen-und Breitengrad",
    LocTime: "Ortszeit",
    MapView: "Kartenansicht",
    FailSync: "Synchronisierung fehlgeschlagen. Bitte versuchen Sie es erneut.",
    GenerateDes: "Erstellen Sie einen Geofence, damit Sie benachrichtigt werden, wenn Ihr Kind einen bestimmten Bereich verlässt.",
    GenerateEff: "Geofences bequem und effizient generieren",
    GenerateCir: "Erstellen Sie einen Geofence um ein bestimmtes Gebiet, indem Sie einen bestimmten Ort umkreisen.",
    StayAlt: "Bleiben Sie mit Geofence Alerts auf dem Laufenden",
    WhGeo: "Wenn Ihr Kind den Geofence durchquert, erhalten Sie eine Benachrichtigung per E-Mail.",
    Start: "Start",
    SureDelete: "Möchten Sie diesen Geofence wirklich löschen?",
    Yes: "Ja",
    No: "Nein",
    MaxT: "Maximal 10 Artikel.",
    Ok: "OK",
    NameGeofc: "Geofence benennen",
    Setlon: "Eingeschränkte Position festlegen",
    SearchAd: "Adresse suchen",
    SetRadius: "Radius einstellen",
    Meters: "Geofence-Radius in Metern (max. 1000 m)",
    GuardTime: "Schutzzeit einstellen",
    SetNow: "Jetzt einstellen >",
    SetAlt: "Warnmeldungen per Benachrichtigungs-E-Mail einrichten",
    Save: "Sichern",
    Cancel: "Stornieren",
    StartTime: "Startzeit",
    EndTime: "Endzeit",
    Repeat: "Wiederholen",
    EverySun: "Jeden Sonntag",
    EveryMon: "Jeden Montag",
    EveryTue: "Jeden Dienstag",
    EveryWed: "Jeden Mittwoch",
    EveryThu: "Jeden Donnerstag",
    EveryFri: "Jeden Freitag",
    EverySat: "Jeden Samstag",
    PlsName: "Bitte geben Sie einen Namen für den Geofence ein.",
    Plsloca: "Bitte geben Sie einen Ort an.",
    PlsTime: "Bitte stellen Sie die Schutzzeit ein.",
    ThreeName: "Es gibt bereits einen Geofence mit demselben Namen.",
    LastVtne: "Letzter Besuch",
    URL: "URL",
    Title: "Titel",
    Frequency: "Frequenz",
    Visits: "Besuche",
    HowFeat: "Wie benutze ich diese Funktion?",
    MakeAct: "Stellen Sie sicher, dass das Zielgerät aktiv ist.",
    ClickBt: 'Klicken Sie auf die Schaltfläche "Screenshots aufnehmen".',
    VuShot: "Sehen Sie sich die Screenshots an.",
    NotLock: "Hinweis: Diese Funktion ist deaktiviert, wenn der Bildschirm des Zieltelefons gesperrt ist.",
    ScReenPow: "Die Bildschirmaufnahme ist fehlgeschlagen, da der Bildschirm des Ziel Telefons gesperrt ist oder die App vorübergehend nicht reagiert. Bitte versuchen Sie es später noch einmal.",
    TakScr: "Screenshots aufnehmen",
    Version: "Ausführung",
    Size: "Größe",
    InstDate: "Installiertes Datum",
    TodayFreq: "Heutige Frequenz",
    TodayScreenTime: "Heutige Bildschirmzeit",
    ClickKey: 'Klicken Sie auf die Schaltfläche "Start" und warten Sie auf die synchronisierten Keylogger.',
    ViewScr: "Sehen Sie sich die Screenshots an.",
    KidTxy: "Mit iSeeGuard Pro können Sie die vom überwachten Android-Gerät erkannten Tastenanschläge überwachen. Bei jedem Tastendruck wird ein App-Symbol angezeigt, das angibt, woher sie kommen.",
    NotDat: "Keine Ergebnisse",
    Event: "Veranstaltung",
    EventLocation: "Veranstaltungsort",
    Note: "Hinweis",
    AppNotif: "App-Benachrichtigungen",
    Modules: "Module",
    Time: "Zeit",
    Format: "Format",
    Export: "Export",
    DeleteAndExport: "Exportierte Daten aus dem Dashboard löschen",
    CancelSub: "Abonnement kündigen",
    UnbindDevice: "Gerät entbinden",
    ContactSupport: "Support kontaktieren",
    SureRenewal: "Möchten Sie die automatische Verlängerung wirklich abbrechen?",
    SureUnbind: "Möchten Sie dieses Gerät wirklich entbinden?",
    PersonalPrivacy: "Zum Schutz Ihrer Privatsphäre beendet iSeeGuard Pro die Überwachung und löscht alle Daten.",
    UnboundDvc: "Herzlichen Glückwunsch! Sie haben das Gerät erfolgreich entbunden!",
    Profile: "Profil",
    ContactUs: "Kontaktieren Sie uns",
    Logout: "Ausloggen",
    Device: "Gerät",
    Month: "Monat",
    BuyNow: "Jetzt kaufen",
    AddNew: "Hinzuf.",
    Unactivated: "Nicht aktiv",
    VersionUpdate: "Es sind neue Updates verfügbar. Wenn dies möglich ist, deinstallieren Sie bitte die vorherige Version auf dem Zieltelefon, starten Sie das Telefon neu und laden Sie die neueste Version herunter.",
    LearnMore: "Erfahren Sie mehr",
    HowToUpdate: "Wie aktualisiert man die App auf dem Zielgerät?",
    UpdateTips1: "Gehen Sie zu Meine Produkte> Einstellungen> Gerät trennen.",
    UpdateTips2: "Halten Sie das Zielgerät, gehen Sie zu Einstellungen > Apps > suchen Sie den System Update Service und deinstallieren Sie ihn zunächst. ",
    UpdateTips3: "Öffnen Sie dann www.iseeguard.com mit dem Browser und laden Sie die App herunter.",
    UpdateTips4: "Befolgen Sie abschließend die Anweisungen auf dem Bildschirm, um die Konfiguration abzuschließen.",
    WhatsappUpdateTips1: "Halten Sie das Zielgerät, gehen Sie zu Einstellungen > Apps > suchen Sie den WhatsApp Service und deinstallieren Sie ihn zunächst. ",
    WhatsappUpdateTips2: "Öffnen Sie dann www.installapk.net mit dem Browser und laden Sie die App herunter.",
    WhatsappUpdateTips3: "Befolgen Sie abschließend die Anweisungen auf dem Bildschirm, um die Konfiguration abzuschließen.",
    ThankPurchase: "Danke für ihren Einkauf!",
    ThankTips: "Sie erhalten eine Bestätigungs-E-Mail mit den Einzelheiten Ihrer Bestellung.",
    Product: "Produkt:",
    BillingEmail: "Ihre Rechnungs-E-Mail:",
    ClickTips: "Klicken Sie auf Weiter und starten Sie die Überwachung.",
    PayFailed: "Die Zahlung ist fehlgeschlagen",
    PayFailedTips: "Bitte versuchen Sie es erneut mit einer anderen Zahlungsmethode oder wenden Sie sich an Ihren Zahlungsanbieter.",
    TryAgain: "Versuchen Sie es nochmal",
    FailedReason: "Warum schlägt die Zahlung fehl?",
    FailedReason1: "1. Abgelaufene Karte",
    FailedReason2: "2. Unzureichende Mittel",
    FailedReason3: "3. Die eingegebenen Informationen sind falsch",
    FailedReason4: "4. Andere Gründe",
    OrderReview: "Ihre Bestellung im Rückblick",
    OrderReviewTips: "Ihre Bestellung ",
    OrderReviewTips1: " ist eingegangen und wird von unserem Team überprüft",
    OrderReviewTips2: "Die Bestellung wird voraussichtlich innerhalb der nächsten 2 Stunden bearbeitet - die maximale Bearbeitungsdauer beträgt 24 Stunden. Sie erhalten eine E-Mail-Benachrichtigung, wenn die Zahlung erfolgreich ist.",
    OrderReviewTips3: "Um zu vermeiden, dass mehrere Zahlungsanfragen von Ihrer Kreditkarte gestellt werden, senden Sie die Bestellung bitte nicht wiederholt.",
    ChangeNotEmail: "Benachrichtigungs-E-Mail ändern",
    NewEmail: "Neue Email",
    NotHu: "Hinweis: Diese Änderung gilt nur für Ihre Benachrichtigungs-E-Mail.Die Login-E-Mail bleibt unverändert.",
    SendMailTse: "Wir haben eine Bestätigungs-E-Mail an Ihre neue Benachrichtigungs-Email-Adresse gesendet. Wenn Sie diese E-Mail nicht erhalten, klicken Sie bitte auf die Schaltfläche unten, um es erneut zu versuchen, oder kontaktieren Sie uns unter",
    ChangeNotsuces: "Sie haben die Benachrichtigungs-E-Mail erfolgreich geändert!",
    ChangeTwo: "Sie haben die alte E-Mail eingegeben. Bitte wählen Sie eine andere.",
    ChangePassword: "Passwort ändern",
    OldPassword: "Altes Passwort",
    PwdSuces: "Sie haben das Passwort erfolgreich geändert.",
    WrongOldPwd: "Falsches altes Passwort.",
    TheNewCode: "Het nieuwe en het oude paswoord zijn hetzelfde. Probeer aub opnieuw.",
    NoMore: "kein weiteres Ergebnis",
    ReNew: "Erneuern",
    CheckExported: "Es sind keine Daten zum Exportieren vorhanden. Bitte überprüfen Sie und versuchen Sie es erneut.",
    FailedCheck: "Export fehlgeschlagen. Aufgrund von Problemen mit der Browserkompatibilität können Daten derzeit nicht über Firefox oder den Edge-Browser heruntergeladen werden. Bitte verwenden Sie Chrome, Safari oder Opera und versuchen Sie es erneut.",
    ScShot: "Bildschirmfoto",
    AddGeofence: "Geofence hinzufügen",
    WifILocation: "Wifi Standort",
    footCot: "Copyright © 2021 iSeeGuard.com. Alle Rechte vorbehalten.",
    Supcenter: "Hilfecenter",
    ProductFAQs: "Produkt-FAQs",
    AccountFAQs: "Konto-FAQs",
    PurchaseFAQs: "FAQs zum Kauf",
    iSeeGuardStore: "iSeeGuard Shop",
    Polide: "Richtlinie",
    privPli: "Datenschutz-Richtlinie",
    cokPli: "Cookie-Richtlinie",
    refndPli: "Erstattungsrichtlinie",
    elu: "EULA",
    TermsCot: "Geschäftsbedingungen",
    FoloUs: "Folgen Sie UNS",
    Paymet: "Zahlung",
    prodt: "Produkte",
    suport: "Support",
    Load: "Wird geladen...",
    NotFund: "Hoppla! Seite nicht gefunden.",
    IagreThe: "Ich stimme zu",
    GlevEu: "iSeeGuard EULA",
    PriPoly: "Datenschutz-Bestimmungen",
    OurGuat: "Unsere Garantie",
    secure: "Sichern",
    finalcoft: "Wir legen Wert auf Ihre Privatsphäre. Ihre persönlichen oder finanziellen Daten werden vertraulich behandelt.",
    twoBack: "30 Tage Geld zurück",
    provideCase: "Wir bieten eine 30-tägige Geld-zurück-Garantie für alle Produkte, falls Sie nicht vollständig zufrieden sind.",
    cancelAnt: "Jederzeit abbrechen",
    cancelNeed: "Sie können das Abonnement jederzeit kündigen, wenn Sie das Produkt nicht mehr benötigen.",
    FastSup: "Schneller Support",
    EnquriedProblem: "Unser Team bietet einen 24/5-Kundendienst, um bei Fragen und Problemen zu helfen.",
    AsUcess: "Entschuldigung, die Bestätigung ist fehlgeschlagen. Bitte stellen Sie sicher, dass die iSeeGuard Pro Assistant App erfolgreich auf dem Zielgerät installiert wurde.",
    ExportTip: "Der Datenexport wird auf Mobilgeräten nicht unterstützt. Melden Sie sich am iSeeGuard Online-Panel Ihres Computers an und exportieren Sie die spezifischen Daten.",
    Expired: "Abgelaufen",
    Features: "Features",
    WifiUpload: "* Bitte beachten Sie, dass Bilder nur per WiFi hochgeladen werden können.",
    WhatsAppTips: "Die dedizierte WhatsApp-Überwachungs-App Nr. 1 ist hier. Jetzt erkunden >>",
    ScOne: "Ein Fehler ist beim Speichern des Screenshots auf dem gewünschten Gerät aufgetreten. Bitte versuchen Sie es später erneut.",
    ScTwo: "Ein Fehler ist beim Hochladen des Screenshots aufgetreten. Bitte versuchen Sie es später erneut.",
    Anpay: "Sichere Online-Zahlung",
    OldEmail: "Alte E-Mail:",
    ExtendSubscript: "Abonnement verlängern",
    Troubleshoot: "Fehlerbehebung",
    PermissionsCheck: "Berechtigungsprüfung",
    LogAgin: "Falls die App auf dem gewünschten Handy nicht richtig funktioniert, kann es sein, dass die Genehmigung ausgestellt ist. Gehen Sie zu den Einstellungen und stellen Sie sie wieder an, damit die App wieder funktioniert.",
    SubmitLog: "Damit Ihr Problem besser gelöst werden kann, können Sie uns das Fehlerprotokoll zuschicken.",
    Accessibility: "Verfügbarkeit",
    Administrator: "Administrator",
    CallLog: "Anrufliste",
    Location: "Ort",
    Notification: "Mitteilung",
    SMS: "SMS",
    Storage: "Speicher",
    UsageStatus: "Nutzungsstatus",
    AccessCamera: "Kamera",
    Microphone: "Mikrofon",
    Submit: "Abschicken",
    LogExpried: "Das Fehlerprotokoll wurde erfolgreich hochgeladen.",
    LogMailOne: " Für schnelleren Support können Sie uns unter support",
    Spt: "support@iSeeGuard.com",
    SptFst: "kontaktieren.",
    Permission: "Genehmigungen",
    Status: "Status",
    OnlyOne: "Es können jeweils nur 1000 Datensätze exportiert werden.",
    LogSucess: "Ein unbekannter Fehler ist aufgetreten.",
    LogUnkow: "Bitte reichen Sie es in 10 Minuten erneut ein. Oder Sie können uns über ",
    LogTwoUnkow: "kontaktieren, um schnelleren Support zu erhalten.",
    Items: "Menge",
    ViewSchot: "Historische Screenshots anzeigen",
    verifyIcloudAccount: "iCloud-Zugangsdaten verifizieren",
    icloudId: "Bitte tragen Sie die iCloud-ID des Zielgeräts ein, das Sie überwachen möchten.",
    anotherIcloud: "Bitte versuchen Sie es erneut oder",
    useOther: "verwenden Sie ein anderes iCloud-Konto.",
    oldIcloud: "Sie haben das alte iCloud-Konto eingetragen.",
    accoundLock: "iCloud-Konto gesperrt",
    lockedAgain: "Dieses iCloud-Konto wurde aus Sicherheitsgründen von Apple gesperrt. Bitte versuchen Sie es in 12 Stunden nochmal.",
    twoFactor: "Zwei-Faktor-Authentifizierung",
    phoneSend: "Eine Nachricht mit einem Verifizierungscode wurde an das Zielgerät gesendet.Bitte tragen Sie den Code ein, um das Konto zu verifizieren.",
    dontGetCode: "Sie haben keinen Verifizierungscode erhalten?",
    sixCode: "Mit eingeschalteter Zwei-Faktor-Authentifizierung benötigen Sie physischen Zugriff auf das Zielgerät, um den sechsstelligen Verifizierungscode zu erhalten.",
    resendCode: "Code erneut senden",
    textMe: "SMS-Bestätigung",
    needHelp: "Brauchen Sie Hilfe?",
    notSendCode: "Verifizierungscode konnte nicht gesendet werden. Bitte versuchen Sie es nochmal.",
    noVerification: "Falscher Verifizierungscode.",
    noRecordsFound: "Keine Aufzeichnungen gefunden!",
    newPricePlan: "Uns ist aufgefallen, dass Sie ein anderes iCloud-Konto eingetragen haben, das nicht unseren Aufzeichnungen entspricht. Wenn Sie das gerade eingegebene Konto überwachen möchten, löschen Sie Ihr vorheriges iCloud-Konto bitte und kaufen Sie einen neuen Preisplan.",
    deleteAccount: "Konto löschen",
    icloudError: "iCloud-Verifizierungsfehler",
    aginNormally: "Bei der Verifizierung des gewünschten iCloud-Kontos ist ein Fehler aufgetreten. Einige Daten könnten nicht aktualisiert worden sein. Bitte verifizieren Sie erneut, damit Sie iSeeGuard Pro normal weiterverwenden können.",
    notNow: "Nicht jetzt",
    verifyIcloud: "iCloud verifizieren",
    icloudAccount: "iCloud-Konto",
    icloudStorage: "iCloud-Speicher",
    deviceName: "Gerätename",
    deviceModel: "Gerätemodell",
    seeMore: "Mehr sehen",
    sureTargetTwo: "Bitte stellen Sie sicher, dass das Find My Phone Feature auf dem Zielgerät aktiviert ist.",
    location: "Standort",
    list: "Liste",
    sureDeleteAccount: "Sind Sie sicher, dass Sie das iCloud-Konto löschen möchten?",
    removeThat: "Alle Aufzeichnungen und Daten in Bezug auf das vorherige iCloud-Konto werden im Anschluss entfernt.",
    isRemove: "Herzlichen Glückwunsch! Dieses iCloud-Konto wurde erfolgreich entfernt.",
    Reminder: "Erinnerungen",
    iCloudDrive: "iCloud Drive",
    appleIdErr: "De Apple ID of het wachtwoord is onjuist.",
    selectTrustePhone: "Vertrauenswürdige Telefonnummer wählen",
    serverErr: "Auf dem Server ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
    continue: "Fortsetzen",
    textMessage: "Textnachricht",
    codeMany: "Troppi inserimenti del codice di sicurezza non corretti.",
    downloadPage: "Das Herunterladen einer Pages-, Numbers- oder Keynote-Datei dauert durchschnittlich 2-3 Minuten. Bist du dir sicher, dass du weitermachen willst?",
    Tips: "Tipps",
    Verify: "Verifizieren",
    icloudLok: "Das iCloud-Konto wurde gesperrt oder das Passwort ist falsch.",
    veriErr: "Überprüfung fehlgeschlagen",
    notereminderTip: "iCloud reagiert nicht mehr. Einige Daten sind vorübergehend nicht verfügbar. Bitte versuchen Sie es später noch einmal.",
    Video: "Videos",
    forAndroid: "Android überwachen",
    foriOs: "iOS überwachen",
    tryError: "Ein unbekannter Fehler ist aufgetreten.Bitte versuchen Sie es später noch einmal.",
    nodata: "Keine Daten",
    ForAndroid: "Für Android",
    PhoneFiles: "Telefon-Dateien",
    ClickShoot: 'Klicken Sie auf die Schaltfläche "Fotografieren".',
    LocationTracking: "Standortverfolgung",
    RemoteControl: "Fernsteuerung",
    TakePhotos: "Fotos machen",
    TakeNote: "Hinweis: Sie können nur mit der Rückfahrkamera Fotos machen. Diese Funktion wird in den folgenden Situationen nicht empfohlen:",
    Shoot: "Fotografieren",
    ShootErrorFirst: "Die Anfrage ist zu häufig. Bitte warten Sie einen Moment und versuchen Sie es erneut.",
    ShootErrorTwo: "Ein Foto konnte nicht aufgenommen werden, da das Zielgerät ausgeschaltet ist oder die App vorübergehend nicht reagiert. Bitte versuchen Sie es später noch einmal.",
    ShootErrorFour: "Die Zugriffsberechtigung für die Kamera wurde auf dem Zielgerät deaktiviert. Bitte gehen Sie zu Einstellungen> Apps> System Update Service> Berechtigungen und aktivieren Sie es erneut.",
    ShootErrorFiveAndSeven: "Die Kamera konnte nicht gestartet werden, da sie von einem anderen Programm belegt ist.",
    ShootErrorEight: "Beim Speichern der Fotos auf dem Zielgerät ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
    ShootErrorNineAnTen: "Beim Hochladen der Fotos ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
    NoteWhenFirst: "Wenn die Kamera besetzt ist;",
    NoteWhenTwo: "Wenn das Zieltelefon auf ein flaches Objekt gerichtet ist;",
    NoteWhenThree: "Wenn sich das Zieltelefon in einer Tasche oder Tasche befindet.",
    RecordCalls: "Anrufe aufzeichnen",
    RecordsOutgoing: "Zeichnet alle ein- und ausgehenden Anrufe auf, die auf Android-Geräten getätigt werden",
    AutomaticallyRecord: "Automatische Aufzeichnung von Telefonaten",
    RemotelyOnline: "Alle Anrufe aus der Ferne online abhören",
    WifiOnline: "Laden Sie die Audiodateien auf das Online-Konto hoch, wenn eine Wi-Fi-Verbindung verfügbar ist.",
    NoteTime: "Hinweis: Diese Funktion kann bis zu 20 Minuten Anrufe gleichzeitig aufzeichnen.",
    Audio: "Audio",
    DownloadSimple: "Herunterladen",
    TakeShootVersion: "Die aktuelle Version ist zu niedrig. Aktualisieren Sie die App auf die neueste Version des Zieltelefons, um diese Funktion zu verwenden.",
    RecordTarget: "Nehmen Sie Telefongespräche auf und speichern Sie sie als versteckte Audiodateien auf dem Zieltelefon",
    ViewTake: "Zeigen Sie die hochgeladenen Fotos an.",
    balnkExport: "Leer",
    iphoneAudio: "Der iOS-Browser unterstützt die Wiedergabe von Anrufaufzeichnungen nicht. Bitte machen Sie es auf Ihrem Computer.",
    IncludedFeatures: "Enthaltene Funktionen",
    AppVideos: "App-Videos",
    AppPhotos: "App-Fotos",
    SafariHistory: "Safari-Verlauf",
    SafariBookmarks: "Safari-Lesezeichen",
    VoiceMemos: "Sprachnotizen",
    ViewAllFollowing: "Alle folgenden Daten in der iSeeGuard Pro App auf dem Zielcomputer anzeigen",
    OpeniSeeGuardProApp: "iSeeGuard Pro-Anwendung öffnen",
    NoteInstalled: "Hinweis: Sie können auf die Schaltfläche unten klicken, um schnell die Benutzeroberfläche der Anwendung aufzurufen. Wenn die Anwendung nicht aufgerufen wird, starten Sie die Anwendung bitte manuell auf dem Computer, auf dem sie installiert ist.",
    NoteFiles: "Hinweis: Sie müssen auf den Zielcomputer zugreifen und die App starten, um alle Dateien zu überprüfen.",
    NotSet: "Nicht festgelegt",
    MonitorAndroid: "Überwachen Sie 1 Android-Gerät. (Ohne root)",
    MonitoriOS: "Überwachen Sie 1 iOS-Gerät. (Ohne jailbreak)",
    MonitorICloud: "Überwachen Sie 1 iCloud-Konto. (Ohne jailbreak & ohne installierte Anwendung)",
    ChooseAndroid: "Wählen Sie Ihren Plan für iSeeGuard Pro für Android",
    ChooseMac: "Wählen Sie Ihren Plan für iSeeGuard Pro für Mac",
    ChooseIOS: "Wählen Sie Ihren Plan für iSeeGuard Pro für iOS",
    ChooseICloud: "Wählen Sie Ihren Plan für iSeeGuard Pro für iCloud",
    ChooseWhatsApp: "Wählen Sie Ihren Plan für iSeeGuard Pro für WhatsApp",
    ChooseWindows: "Wählen Sie Ihren Plan für Monivisor für Windows",
    BuyDesc: "Automatische Erneuerung.",
    BuyTips1: "Die Lizenz wird automatisch in einem Monat verlängert. Sie können sie jederzeit auf der Seite Produkteinstellungen kündigen. ",
    BuyTips2: "Die Lizenz wird automatisch in 3 Monaten erneuert. Sie können sie jederzeit auf der Seite Produkteinstellungen kündigen. ",
    BuyTips3: "Die Lizenz wird automatisch um ein Jahr verlängert. Sie können sie jederzeit auf der Produkt-Einstellungsseite kündigen. ",
    IcartPay: "Bezahlen Mit Kreditkarte",
    ShowAll: "Alle anzeigen",
    WaitMsg: "Warten Sie mal. Wir bearbeiten Ihre Bestellung.",
    MonitoriOSremotely: "Entschuldigung, die Stornierung ist fehlgeschlagen. Bitte versuche es erneut.",
    tryAgain: "Bitte versuche es erneut.",
    resiterSucess: "Erfolgreich registriert!",
    resiterTxt: "Herzlichen Glückwunsch! iSeeGuard-Konto erfolgreich erstellt!",
    selectPt: "Wählen Sie das gewünschte Produkt aus",
    Availablefor: "Verfügbar für",
    ThankYou: "Danke!",
    ThankYouTips: "Ihre Einsendung ist eingegangen.",
    androidMonite: "Beginnen Sie jetzt mit der Überwachung. Sie müssen Ihr Android-Smartphone nicht rooten!",
    iosMonite: "Beginnen Sie jetzt mit der Überwachung. Sie müssen keine iPhones oder iPads mit Jailbreak haben!",
    iCloudMonite: "Beginnen Sie jetzt mit der Überwachung. Kein Jailbreaking und keine Installation von Apps!",
    myPorductOrder: "Produkte und Bestellungen verwalten",
    expireSoon: "Läuft bald ab",
    invisibleMode: "Eine ultimative Android-Überwachungsapp, die im Unsichtbar-Modus arbeitet.",
    moniteFeatures: "Mehr als 30 Überwachungsfunktionen stehen zur Verfügung. Kein Rooten erforderlich.",
    overTwoIos: "Über 20 erweiterte iOS-Überwachungsfunktionen, darunter für WhatsApp, Anruf-Verlauf, SMS usw.",
    overTwoMac: "Über 20 erweiterte Mac-Überwachungsfunktionen, darunter für WhatsApp, Anruf-Verlauf, SMS usw.",
    noLike: "Keine komplizierten Sachen wie Jailbreaking erforderlich. Ein einfacher Einstieg.",
    userIcloud: "Überwachen Sie jedes iPhone oder iPad aus der Ferne und online mit iCloud-Anmeldedaten.",
    noNeedInstallApp: "Es ist nicht nötig, die Anwendung auf dem iOS-Zielgerät zu installieren, 100% sicher.",
    myProduct: "Meine Produkte",
    accountOrderInfo: "Account & Bestellinfo",
    orderInfo: "Bestellinfo",
    unableSync: "Daten können vorübergehend nicht synchronisiert werden. Die Daten beginnen mit der Synchronisierung, nachdem das Zieltelefon mit Internetverbindung eingeschaltet wurde und der iSeeGuard Pro Assistent auf dem Zielgerät läuft.",
    productFAQ: "FAQs zum Produkt",
    path: "Pfad",
    goSetZero: "Gehen Sie zu Einstellungen > Anwendungen > System Update Services > Berechtigungen > Kamera > stellen Sie sicher, dass es eingeschaltet ist",
    goSetOne: "Gehen Sie zu Einstellungen > Zugänglichkeit > System Update Services > stellen Sie sicher, dass es eingeschaltet ist",
    goSetTwo: "Gehen Sie zu Einstellungen > Sicherheit > Andere Sicherheitseinstellungen > Geräteadministrator > System Update Services > stellen Sie sicher, es dieser eingeschaltet ist",
    goSetThree: "Gehen Sie zu Einstellungen > Anwendungen > System Update Services > Berechtigungen > Kalender > stellen Sie sicher, dass es eingeschaltet ist",
    goSetFour: "Gehen Sie zu Einstellungen > Anwendungen > System Update Services > Berechtigungen > Anrufprotokolle > stellen Sie sicher, dass es eingeschaltet ist",
    goSetFive: "Gehen Sie zu Einstellungen > Anwendungen > System Update Services > Berechtigungen > Kontakte > stellen Sie sicher, dass es eingeschaltet ist",
    goSetSix: "Gehen Sie zu Einstellungen > Anwendungen > System Update Services > Berechtigungen > Standorte > stellen Sie sicher, dass es eingeschaltet ist",
    goSetSop: "Gehen Sie zu Einstellungen > Anwendungen > System Update Services > Berechtigungen > Mikrofon  > stellen Sie sicher, dass es eingeschaltet ist",
    goSetSeven: "Gehen Sie zu Einstellungen > Anwendungen > System Update Services > Benachrichtigungen > stellen Sie sicher, dass es eingeschaltet ist",
    goSetEight: "Gehen Sie zu Einstellungen > Apps > System Update Services > Berechtigungen > SMS > stellen Sie sicher, dass es eingeschaltet ist",
    goSetNight: "Gehen Sie zu Einstellungen > Anwendungen > finden Sie System Update Service > Berechtigungen > Speicherung > stellen Sie sicher, dass es eingeschaltet ist",
    goSetTwent: "Gehen Sie zu Einstellungen > finden Sie Sicherheit > Andere Sicherheitseinstellungen > Zugriff auf Nutzungsdaten > System Update Services > stellen Sie sicher, dass es eingeschaltet ist",
    followStep: "Führen Sie die folgenden Schritte aus, um das Telefon hinzuzufügen, das Sie überwachen möchten",
    followIos: "Führen Sie die folgenden Schritte aus, um das iOS hinzuzufügen, das Sie überwachen möchten",
    followMac: "Führen Sie die folgenden Schritte aus, um das Mac hinzuzufügen, das Sie überwachen möchten",
    beforeStart: "Vor dem Start",
    youNeedDevice: "1. Möglicherweise benötigen Sie einen direkten Zugriff auf das Zielgerät.",
    notiLLegal: "2. Verwenden Sie dieses Produkt nicht für illegale Zwecke.",
    physicalDevice: "1. Es ist ein direkter Zugriff auf das Gerät erforderlich.",
    illegalPurposes: "2. Verwenden Sie dieses Produkt nicht für illegale Zwecke.",
    videoInstruction: "Video-Anleitung",
    VerifyInstall: 'Bitte beachten Sie die Videoanleitung, um die Installation und Konfiguration der App auf dem Zieltelefon abzuschließen. Klicken Sie danach unten auf die Schaltfläche "Konfiguration bestätigen", um eine Verbindung mit dem Zielgerät herzustellen und das Web-Dashboard aufzurufen.',
    textInstruction: "Textanweisung",
    setupStep: "Schritt ",
    androidStepOne: "Laden Sie die App auf das Zieltelefon",
    androidStepOneDesc: 'Öffnen Sie <strong style="color: #347aeb;">www.iseeguard.com</strong> mit einem Browser auf dem Zielgerät und laden Sie die iSeeGuard Pro für Android App herunter.',
    androidStepTwo: "Installieren Sie die App",
    androidStepTwoDesc0: "Tippen Sie auf die heruntergeladene Datei aus dem Download-Verlauf des Browsers oder dem Dateimanager und starten Sie die Installation der App.",
    androidStepTwoDesc1: "Tippen Sie auf die heruntergeladene Datei aus dem Download-Verlauf des Browsers oder dem Dateimanager und starten Sie die Installation der App. Melden Sie sich danach bei Ihrem Konto an und geben Sie die grundlegenden Informationen Ihres Zielgeräts ein.",
    androidStepTwoDesc2: "<strong>Hinweis:</strong>  Einige Einstellungen müssen korrekt konfiguriert werden, damit iSeeGuard Pro funktioniert. Bitte folgen Sie den Bildschirmanweisungen in der Anwendungsoberfläche oder den folgenden Richtlinien zur manuellen Bedienung.",
    androidStepThree: "Stimmen Sie der Vereinbarung zu",
    androidStepTwoToOneDesc: "Wählen Sie <strong>Ich habe die Bedingungen gelesen und verstanden</strong> und tippen Sie auf <strong>Ich stimme zu</strong>.",
    androidStepFour: "Basisberechtigungen",
    androidStepTwoToTwoDesc: "Tippen Sie auf <strong>Basisberechtigungen </strong>> > Klicken Sie auf <strong>Zulassen</strong>, um alle Arten von Basisberechtigungen anzuzeigen.",
    androidStepFives: "Barrierefreiheit aktivieren",
    androidStepTwoToThreeDesc: 'Tippen Sie auf <strong>Bedienungshilfen</strong> > aktivieren Sie <strong>Systemdienste</strong> > tippen Sie auf <strong>Zulassen</strong>.',
    androidStepSix: "Benachrichtigungszugriff aktivieren",
    androidStepTwoToFourDesc: "Tippen Sie auf <strong>Benachrichtigungs-Listener</strong> > aktivieren Sie <strong>Systemdienste</strong> > tippen Sie auf <strong>Zulassen</strong>.",
    androidStepSeven: "Screenshot-Berechtigung aktivieren",
    androidStepTwoToFiveDesc: "Tippen Sie auf <strong>Screenshot-Berechtigung</strong> > aktivieren Sie <strong>iSeeGuard</strong>.",
    androidStepEight: "Zugang zur Nutzung",
    androidStepTwoToSixDesc: "Tippen Sie auf <strong>Zugriff auf Nutzung</strong> > aktivieren Sie <strong>Systemdienste</strong> > tippen Sie auf <strong>Zulassen</strong>.",
    androidStepNine: "Overlay-Berechtigung",
    androidStepTwoToSevenDesc: 'Tippen Sie auf <strong>Overlay-Berechtigung</strong> > aktivieren Sie <strong>Systemdienste</strong> > tippen Sie auf <strong>Zulassen</strong>.',
    androidStepTen: "App-Benachrichtigungen deaktivieren",
    androidStepTwoToEightDesc: "Tippen Sie auf <strong>App-Benachrichtigungen deaktivieren</strong> > aktivieren Sie <strong>Systemdienste</strong> > deaktivieren Sie <strong>Benachrichtigungsschalter anzeigen</strong>.",
    androidStepTwoToNineDesc: "Tippen Sie auf <strong>Batterieoptimierung deaktivieren</strong> > tippen Sie im Eingabeaufforderungsfeld auf <strong>Zulassen</strong>.",
    androidStepTwoToTenDesc: "Tippen Sie nach Abschluss aller Berechtigungseinstellungen auf <strong>Weiter</strong>. Melden Sie sich danach bei Ihrem Konto an.",
    androidStepEleven: "Batterieoptimierung deaktivieren",
    androidStepTwelve: "Melden Sie sich mit Ihrem Konto an",
    huaWeiNote1: "<strong>Huawei</strong>:  Tippen Sie auf die Schaltfläche unten und suchen Sie den System Update Service im Task-Manager. Wischen Sie über die App-Oberfläche, um sie zu sperren. Tippen Sie danach auf die App-Oberfläche, um zurückzukehren.",
    oppoNote1: "<strong>Oppo</strong>: Tippen Sie auf die Schaltfläche unten und suchen Sie den System Update Service im Task-Manager. Tippen Sie oben rechts auf das Symbol Einstellungen. Tippen Sie auf Sperren. Tippen Sie danach auf die App-Oberfläche, um zurückzukehren.",
    xiaomiNote1: "<strong>Xiaomi</strong>: Tippen Sie auf die Schaltfläche unten und suchen Sie den System Update Service im Task-Manager. Drücken Sie lange auf die App-Registerkarte und sperren Sie sie. Tippen Sie danach auf die App-Oberfläche, um zurückzukehren.",
    huaWeiNote: "<strong>Huawei</strong>:  Fahren Sie mit Einstellungen > Anwendungen > Start der Anwendung fort > finden Sie den System Update Service, deaktivieren Sie ihn zunächst. Aktivieren Sie dann im Popup-Fenster Manuell verwalten die Optionen Automatischer Start, Sekundärer Start und Im Hintergrund ausführen.",
    oppoNote: '<strong>Oppo</strong>:  Gehen Sie zu Einstellungen > Anwendungsverwaltung > Anwendungsliste > System Update Service und Power Saver > aktivieren Sie <strong>"Im Hintergrund ausführen"</strong>, gehen Sie dann auf die Schaltfläche "Zurück" und aktivieren Sie <strong>"Autostart"</strong> sowie <strong>"Andere Anwendungen oder Dienste starten lassen"</strong>. Danach gehen Sie zurück zu Einstellungen > Akku > Smart Power Saver deaktivieren > Benutzerdefinierter Stromsparer antippen  > System Update Service und aktivieren Sie <strong>"Im Hintergrund ausführen"</strong>. Tippen Sie erneut auf die Schaltfläche "Zurück" und aktivieren Sie <strong>App "Quick Freeze"</strong>. ',
    xiaomiNote: "<strong>Xiaomi</strong>: Gehen Sie zu Einstellungen > Anwendungen > Anwendungen verwalten > System Update Service > aktivieren Sie <strong>Autostart</strong>, scrollen Sie dann nach unten zum Batteriesparer > <strong>Ohne Einschränkungen</strong>. ",
    vivoNote: "<strong>Vivo</strong>: Gehen Sie zu Einstellungen > Akku > <strong>Hoher Stromverbrauch im Hintergrund</strong> > System Update Service > schalten Sie diesen aus.",
    otherNote: "<strong>Andere</strong>: Gehen Sie zu Einstellungen > Anwendungen > System Update Service > Erweiterte Einstellungen > Batterieoptimierung > wieder zu System Update Service und diesen deaktivieren.",
    androidStepThirteen: "Überwachung starten",
    androidStepThreeDesc: 'Klicken Sie nun auf die Schaltfläche "Überwachung starten", und das Anwendungssymbol wird verschwinden. Für Android 10 und neuer wird das App-Symbol durch ein "Notice"-Symbol ersetzt. Es ist am besten, dieses Symbol in einem Ordner zu verstecken. ',
    VerifyYourSetup: "Bestätigen Sie Ihre Konfiguration",
    nowMoniteSupportTeam: 'Wenn alles ordnungsgemäß eingerichtet ist, klicken Sie bitte unten auf die Schaltfläche " Konfiguration bestätigen". Dadurch wird das Zielgerät an Ihr Online-Konto gebunden. Die aktuelle Seite springt auf das Web-Dashboard, wo Sie alle Dateien überprüfen können. Wenn Sie die Anwendung nicht erfolgreich installieren können, wenden Sie sich bitte an das Support-Team.',
    iosStepOne: "Laden Sie das Programm auf den Zielcomputer",
    iosStepOneDesc: 'Besuchen Sie <strong style="color: #347aeb;">www.useapp.net</strong>, um iSeeGuard Pro für iOS auf den Zielcomputer herunterzuladen.',
    iosStepOneNote: "<strong>Hinweis:</strong> Es wird empfohlen, dass Sie es direkt auf den Computer der zu überwachenden Person herunterladen. Wenn Sie von Zeit zu Zeit physischen Zugriff auf das Ziel-iPhone haben, können Sie es auch auf Ihren eigenen Computer herunterladen und überwachen.",
    iosStepTwo: "Installieren Sie das Programm iSeeGuard Pro für iOS",
    iosStepTwoWinDesc: "<strong>Für Windows-Benutzer: </strong>Klicken Sie nach dem Download auf die exe-Datei, um den Installationsprozess zu starten.",
    iosStepTwoWinNote1: 'Bitte beachten Sie, dass Sie in diesem anfänglichen Interface festlegen können, ob Sie das Programmsymbol ausblenden möchten. Wenn Sie die Option "Die Verknüpfung auf dem Desktop, in der Taskleiste und im Startmenü nicht anzeigen" auswählen, gibt es nur zwei Möglichkeiten, iSeeGuard Pro zu öffnen.',
    iosStepTwoWinNote2: "<strong>2.1</strong> Gehen Sie zum Installationspfad des Programms und klicken Sie auf die exe-Datei. Standardmäßig lautet der Installationspfad C:\\Program Files (x86)\\iSeeGuard\\iSeeGuard Pro. Wenn Sie den Installationspfad ändern, stellen Sie sicher, dass Sie ihn sich merken können.",
    iosStepTwoWinNote3: '<strong>2.2</strong> Melden Sie sich im Online-Dashboard von iSeeGuard Pro im Browser des Zielcomputers an und klicken Sie dann auf "iSeeGuard Pro-Anwendung öffnen".',
    iosStepTwoMacDesc: "<strong>Für Mac-Benutzer：</strong>Ziehen Sie die Programmdatei nach dem Herunterladen in den Anwendungsordner. Wenn die Installation abgeschlossen ist, starten Sie das Programm und melden Sie sich bei Ihrem Konto an.",
    iosStepThree: "Daten vom Ziel-iPhone/iPad scannen",
    iosStepThreeToOneDesc1: "Wenn keine Sicherungsdateien auf dem Computer vorhanden sind, müssen Sie das iOS-Zielgerät über ein USB-Kabel mit diesem verbinden, dann die Geräteinformationen bestätigen und die USB-Einstellungen gemäß den Anweisungen aktivieren.",
    iosStepThreeToOneDesc2: "Sobald das Zielgerät erfolgreich angeschlossen ist, startet iSeeGuard Pro für iOS den Scan- und Analyseprozess, der einige Zeit in Anspruch nehmen wird.",
    iosStepThreeToTwoDesc1: "Wenn auf dem Computer Sicherungsdateien vorhanden sind, zeigt iSeeGuard Pro die gefundene Sicherung an. Sie können die Sicherungsdatei des Zielgeräts auswählen und die Daten daraus scannen.",
    iosStepThreeToTwoDesc2: 'Wenn das zu überwachende Gerät nicht in der Liste enthalten ist oder Sie nur die allerneuesten Daten einsehen möchten, können Sie unten auf die Option "Zielgerät verbinden" klicken.',
    iosStepThreeToThreeDesc: "In der neuesten Version können Sie die Daten über WLAN synchronisieren.  Sobald das iPhone, iPad und der Computer mit demselben WLAN-Netzwerk verbunden sind, klicken Sie einfach auf „WLAN“ Symbol in der Navigationsleiste, wählen Sie anschließend das Gerät, um die Daten aus der Ferne zu synschronisieren.",
    iosStepThreeToThreeNote: "<strong>Hinweis: </strong>um Daten über WLAN zu synchronisieren, sollten Sie das Gerät mit dem PC verbinden und dem Computer vertrauen.",
    iosStepFour: "Überwachung des Ziel-iPhones/iPads auf dem Computer starten",
    iosStepFourDesc: "Nachdem der Scanvorgang abgeschlossen ist, werden alle gescannten Informationen auf dem Dashboard angezeigt und Sie können zwischen verschiedenen Dateitypen wechseln und Details einsehen. Außerdem haben Sie die Möglichkeit, alle Daten auf den Computer zu exportieren.",
    VerifySetup: "Konfiguration bestätigen",
    kdsAndroid: "iSeeGuard Pro für Android",
    kdsIos: "iSeeGuard Pro für iOS",
    kdsIcloud: "iSeeGuard Pro für iCloud",
    setupGuide: "Anleitung",
    generalSet: "Allgemeine Einstellungen",
    plseRefer: "In der Videoanleitung erfahren Sie, wie Sie ein iPhone mit iSeeGuard Pro überwachen. Wir haben auch eine detaillierte Textanweisung für Ihre Referenz vorbereitet.",
    locate: "Lokalisieren",
    requestOut: "Das Anforderungszeitlimit.",
    onlyTxt: "Es können nur Textdateien exportiert werden.",
    quickly: "Bitte klicken Sie auf die Schaltfläche unten, um sich schnell anzumelden.",
    neverWth: "Die WhatsApp-Verfolgung war noch nie so einfach wie mit iSeeGuard.",
    androidSytem: "Ultimative Android-Überwachungsanwendung mit bis zu 30+ Funktionen.",
    iOSSytem: "PC-basierte Software zur Überwachung eines iPhones oder iPads ohne Jailbreaking. ",
    iCloudSytem: "Online-Lösung zur Überwachung von iOS-Geräten ohne App-Installation. ",
    WhatsSytem: "Spezialisierte WhatsApp Überwachungs-App für Android-Telefone.",
    WindowsSytem: "Unsichtbare Windows-Computerüberwachungssoftware. ",
    Comingsoon: "Kommt bald",
    kdsMobile: "Mobile Security Anwendung",
    MobileSytem: "Erstklassige Anti-Spy-App, um Ihr Android-Gerät frei von Spyware und Stalkerware zu halten.",
    EarlBbirdTitle: "Verpassen Sie nicht das kommende mobile Sicherheitstool für Android！",
    EarlBbirdContent: "Hinterlassen Sie Ihre E-Mail-Adresse und erhalten Sie den Frühbucherrabatt-Gutschein, nachdem das Produkt auf den Markt gebracht wurde.",
    ValidEmail: "Ihre gültige E-Mail",
    EmailSubmited: "Diese E-Mail ist bereits gesendet.",
    WindowsMonitore: "MoniVisor",
    WhatsAppMonitore: "iSeeGuard für WhatsApp",
    kdsWhatsapp: "iSeeGuard für WhatsApp",
    SpeceAndroid: "Spezialisierte WhatsApp Überwachungs-App für Android-Telefone.",
    preparedGuid: "Hier haben wir ein detailliertes Video vorbereitet, damit Sie wissen, wie Sie die WhatsApp von jemandem über iSeeGuard for WhatsApp überwachen können. Unten finden Sie auch die Schritt-für-Schritt-Textanleitung. ",
    DataUpload: "Hochladen von Daten",
    transferOver: "Übertragen Sie große Dateien nur über Wi-Fi.",
    followWhatsApp: "Führen Sie die folgenden Schritte aus, um die WhatsApp einer Person zu überwachen",
    whatsappStepOneDesc: 'Besuchen Sie <strong style="color: #347aeb;">www.installapk.net</strong> mit dem Browser des Zieltelefons und laden Sie die Anwendung iSeeGuard für WhatsApp herunter',
    whatsappStepTwoDesc1: "Öffnen Sie die heruntergeladene APK-Datei aus dem Download-Verlauf des Browsers oder dem Dateimanager und schließen Sie die Installation ab. Melden Sie sich dann in Ihrem Konto an und geben Sie die grundlegenden Informationen Ihres Ziels ein.",
    whatsappStepTwoDesc2: "<strong>Hinweis: </strong>Einige Einstellungen müssen korrekt konfiguriert werden, damit iSeeGuard für WhatsApp funktioniert. Bitte folgen Sie den Bildschirmanweisungen in der Anwendungsoberfläche oder den folgenden Richtlinien zur manuellen Bedienung.",
    whatsappStepTwoToOneDesc: "Tippen Sie auf <strong>Weiter zu Einstellungen</strong> > das Einstellungssymbol in der oberen rechten Ecke > deaktivieren Sie <strong>Apps mit Play Protect scannen und Erkennung schädlicher Apps verbessern</strong> > kehren Sie zur iSeeGuard für WhatsApp App zurück > tippen Sie auf <strong>Bereits eingestellt</strong>, um fortzufahren.",
    whatsappStepTwoToTwoDesc: "Tippen Sie auf <strong>Fortfahren mit Einstellungen</strong> > Installierte Dienste > aktivieren Sie <strong>WhatsApp Service</strong> > tippen Sie auf <strong>Zulassen</strong>.",
    whatsappStepTwoToThreeDesc: '<strong>Gehen Sie zu Einstellungen > Nutzungsdatenzugriff</strong> unter Sicherheitseinstellungen > <strong>WhatsApp Service</strong> > aktivieren Sie <strong>"Nutzungsüberwachung zulassen"</strong> .',
    whatsappStepTwoToFourDesc: "<strong>Gehen Sie zu Einstellungen</strong> > <strong>WhatsApp Service</strong> aktivieren.",
    whatsappStepTwoToFiveDesc: "Tippen Sie auf  <strong>Einstellungen</strong> > Geräteadministrator-Berechtigung für WhatsApp Service aktivieren.",
    whatsappStepTwoToSevenDesc: "Tippen Sie auf  <strong>Alle zulassen </strong>, um die Berechtigung für diese App zu aktivieren, WhatsApp-Dateien zu erhalten und WhatsApp-Anrufe automatisch aufzuzeichnen.",
    whatsappStepTwoToEightDesc: "Tippen Sie auf  <strong>Ignorieren</strong>  und anschließend im Popup-Fenster auf  <strong>Zulassen</strong> , damit WhatsApp Service im Hintergrund ausgeführt wird.",
    whatsappStepTwoToNine: "Aktivieren Sie den Autostart",
    whatsappStepTwoToNineDesc: "Tippen Sie auf <strong>Weiter zu Einstellungen</strong> > <strong>WhatsApp-Dienst</strong>  aktivieren, damit die App nach dem Neustart des Geräts ausgeführt wird.",
    whatsappStepTwoToTen: "Aktivieren der Hintergrundausführung (nur für Huawei, Oppo, Xiaomi, Vivo)",
    WhatsAppHuawei: "<strong>Huawei:</strong> Fahren Sie mit Einstellungen > Anwendungen > Start der Anwendung fort > finden Sie den WhatsApp Service, deaktivieren Sie ihn zunächst. Aktivieren Sie dann im Popup-Fenster Manuell verwalten die Optionen Automatischer Start, Sekundärer Start und Im Hintergrund ausführen.",
    WhatsAppOppo: "<strong>OPPO:</strong> Fahren Sie mit Einstellungen > Anwendungsverwaltung > Anwendungsliste > suchen Sie WhatsApp Service und Power Saver > aktivieren Sie Run in background, gehen Sie dann auf die Schaltfläche back und aktivieren Sie Auto Startup sowie Allow other Apps or Services to Launch. Danach gehen Sie zurück zu Einstellungen > Akku > Smart Power Saver deaktivieren > Tippen Sie auf Benutzerdefinierter Stromsparer > suchen Sie WhatsApp Service und aktivieren Sie Run in Background. Tippen Sie erneut auf die Schaltfläche Zurück und aktivieren Sie App Quick Freeze.",
    WhatsAppXiaomi: "<strong>Xiaomi:</strong> Gehen Sie zu Einstellungen > Anwendungen > Anwendungen verwalten > finden Sie WhatsApp Service > aktivieren Sie Autostart, dann scrollen Sie nach unten und finden Sie Batteriesparer > Keine Einschränkungen. ",
    WhatsAppVivo: "<strong>VIVO:</strong> Fahren Sie mit Einstellungen > Akku > Hoher Stromverbrauch im Hintergrund > suchen Sie WhatsApp Service > schalten Sie ihn aus.",
    WhatsAppOthers: "<strong>Andere:</strong> Fahren Sie mit Einstellungen > Anwendungen fort > finden Sie den WhatsApp Service > Erweiterte Einstellungen > Batterieoptimierung > finden Sie den WhatsApp Service wieder und deaktivieren Sie ihn. ",
    WhatsAppHuawei1: "<strong>Huawei:</strong> Gehen Sie zu Einstellungen > und suchen Sie den WhatsApp Service im Task-Manager. Wischen Sie über die App-Oberfläche, um sie zu sperren. Tippen Sie danach auf die App-Oberfläche, um zurückzukehren.",
    WhatsAppOppo1: "<strong>Oppo: </strong> Gehen Sie zu Einstellungen > und suchen Sie den WhatsApp Service im Task-Manager. Tippen Sie oben rechts auf das Symbol Einstellungen. Tippen Sie auf Sperren. Tippen Sie danach auf die App-Oberfläche, um zurückzukehren.",
    WhatsAppXiaomi1: "<strong>Xiaomi:</strong> Gehen Sie zu Einstellungen > und suchen Sie den WhatsApp Service im Task-Manager. Wischen Sie über die App-Oberfläche, um sie zu sperren. Tippen Sie danach auf die App-Oberfläche, um zurückzukehren. Drücken Sie lange auf die App-Registerkarte und sperren Sie sie. Tippen Sie danach auf die App-Oberfläche, um zurückzukehren.",
    WhatsAppStartMonite: 'Klicken Sie nun auf die Schaltfläche Überwachung starten, und das Anwendungssymbol wird bald verschwinden. Für Android 10 und höher wird das App-Symbol durch ein "WLAN"-Symbol ersetzt. Am besten verstecken Sie dieses Symbol in einem nicht offensichtlichen Ordner.',
    WhatsAppVerify: "Überprüfen sie ihr setup nach abschluss der installation",
    MoniteSupportTeam: 'Wenn alles richtig eingerichtet ist, klicken Sie bitte unten auf die Schaltfläche "Einrichtung überprüfen". Dadurch wird das Zielgerät an Ihr Online-Konto gebunden. Die aktuelle Seite springt auf das Web-Dashboard, wo Sie alle Dateien überprüfen können. Wenn Sie die Anwendung nicht erfolgreich installieren können, wenden Sie sich bitte an das Support-Team.',
    DataWarn: "Warnung zur Datennutzung",
    DataTerm: "Das Hochladen großer Dateien (Fotos, Videos, Audiodateien und Dokumente) über mobile Daten kann zusätzliche Netzwerkgebühren auf Ihrem Zieltelefon verursachen und die Tatsache aufdecken, dass Sie WhatsApp verfolgen. Sind Sie sicher, dass Sie dennoch Mediendateien über mobile Daten hochladen möchten? ",
    DataTermNote: "<strong>Hinweis:</strong>  Chat-Verlauf, Status, Anrufe und WhatsApp-Aktivitäten unterliegen dieser Bedingung nicht. Standardmäßig werden diese Dateien über mobile Daten hochgeladen.",
    ScreenTime: "WhatsApp-Bildschirmzeit (letzte 7 Tage)",
    WhatsAppChats: "WhatsApp-Chats",
    WhatsAppCalls: "WhatsApp-Anrufe",
    Calls: "Anrufe",
    CallRecording: "Anruf-Aufnahmen",
    WhatsAppStatus: "WhatsApp-Status",
    WhatsAppPhotos: "WhatsApp-Fotos",
    WhatsAppVideos: "WhatsApp-Videos",
    WhatsAppVoices: "WhatsApp-Stimmen",
    Voices: "Stimmen",
    WhatsAppDocuments: "WhatsApp-Dokumente",
    Documents: "Dokumente",
    WhatsAppActivities: "WhatsApp-Aktivitäten",
    Activities: "Aktivitäten",
    WhatsAppScreenshots: "Bildschirmfotos",
    Screenshots: "Bildschirmfotos",
    uploadTle: "Große Dateien (Fotos, Videos, Audios & Dokumente) werden standardmäßig über Wi-Fi hochgeladen. Sie können es unter Produkt > Einstellungen ändern.",
    searchCallerName: "anrufername suchen",
    CallerName: "Name des Anrufers",
    onlyCover: "* iSeeGuard für WhatsApp lädt nur Videocover hoch.",
    screenFeature: "Erste Schritte mit der automatischen Screenshot-Funktion",
    screenFeatureOne: "Diese Funktion erstellt automatisch Screenshots des WhatsApp Bildschirms. Sie ist also nur aktiviert, wenn der Zielbenutzer WhatsApp verwendet. ",
    screenFeatureTwo: "Sie können wählen, wann Sie die Screenshots hochladen möchten. Durch die Aktivierung des Uploads über Wi-Fi werden Screenshots häufiger erfasst, wodurch zusätzliche Netzwerkgebühren auf Ihrem Zieltelefon vermieden werden können. Das andere ist genau das Gegenteil.",
    UploadWiFi: "Hochladen über Wi-Fi",
    MoreConsumption: "Mehr Stromverbrauch",
    fasterfrequency: "Schnellere Screenshot-Frequenz",
    nomoBileData: "Kein mobiler Datenverbrauch",
    UploadMobileDat: "Hochladen über mobile Daten",
    LessPowerConsumption: "Weniger Stromverbrauch",
    lowerFrequencyScreenshots: "Niedrigere Screenshot-Frequenz",
    usemobiledata: "Mehr mobile Daten verwenden",
    SuccessfullySet: "Erfolgreich gesetzt!",
    SetSucess: "Sie haben die automatische Screenshot-Funktion erfolgreich eingerichtet. Der Upload der Screenshots beginnt, wenn der Zielbenutzer WhatsApp verwendet und eine Netzwerkverbindung besteht. Bitte haben Sie etwas Geduld.",
    wifi: "Wi-Fi",
    wifiText: "Die WhatsApp-Screenshots werden über das Wi-Fi-Netzwerk hochgeladen.",
    MobileData: "Mobile Daten",
    MobileDataText: "Die WhatsApp-Screenshots werden mit Hilfe mobiler Daten hochgeladen.",
    WhatsAppDownload: "Mediendateien können in jedem Feature direkt heruntergeladen werden.",
    tryagain: "Das Anfrage-Timeout. Bitte versuchen Sie es später noch einmal.",
    beingUpload: "Die Daten werden gerade hochgeladen. Es kann eine Weile dauern, bis sie vollständig angezeigt werden. Bitte aktualisieren Sie die Seite später.",
    Chat: "WhatsApp-Chats",
    CallRecorde: "Anruf-Aufnahmen",
    Play: "Abspielen",
    AudioCall: "Audioanruf",
    VideoCall: "Videoanruf",
    startTle: "Erste Schritte mit der WhatsApp Anrufaufzeichnungsfunktion",
    startTleOne: "<strong>1.</strong> Diese Funktion nutzt die Vorteile des Mikrofons zur Aufzeichnung von WhatsApp Sprach-/Videogesprächen, so dass Sie die Stimme auf der anderen Seite möglicherweise nicht hören, es sei denn, der Lautsprecher ist aktiviert.",
    startTleTwo: "<strong>2.</strong> Standardmäßig werden die Audiodateien automatisch über Wi-Fi hochgeladen. Dies soll zusätzliche Gebühren für mobile Daten verhindern und Sie davor schützen, entdeckt zu werden. Sie können diese Einstellungen unter Meine Produkte - Einstellungen > Daten-Upload ändern.",
    startTleThree: "<strong>3.</strong>Diese Funktion kann bis zu 20 Minuten auf einmal aufzeichnen, d.h. wenn die Gesprächsdauer 20 Minuten überschreitet, wird sie zum Hochladen in mehrere kleine Fragmente aufgeteilt.",
    Thumbnail: "Miniaturansicht",
    UpdateOverWifi: "Update über Wi-Fi",
    wifiUpload: "Bitte beachten Sie, dass große Dateien (Fotos, Videos, Audio und Dokumente) hochgeladen werden, wenn das Zieltelefon ein Wi-Fi-Netzwerk verwendet.",
    wifiNote: "<strong>Hinweis:</strong> Chat-Verlauf, Status, Anrufe und WhatsApp-Aktivitäten unterliegen dieser Bedingung nicht. Standardmäßig werden diese Dateien über mobile Daten und WLAN hochgeladen.",
    ChatContact: "Chat-Kontakt",
    Content: "Inhalt",
    State: "Status",
    screenTime: "WhatsApp-Bildschirmzeit (letzte 7 Tage)",
    whatAppPathOne: "Gehen Sie zu Einstellungen > Barrierefreiheit > WhatsApp Service > stellen Sie sicher, dass er eingeschaltet ist",
    whatAppPathTwo: "Gehen Sie zu Einstellungen > Suchen Sie Sicherheit > Weitere Sicherheitseinstellungen > Geräteadministrator > WhatsApp Service > Stellen Sie sicher, dass er eingeschaltet ist",
    whatAppPathThree: "Gehen Sie zu Einstellungen > Anwendungen > Suchen Sie den WhatsApp Service > Berechtigungen > Storage > Stellen Sie sicher, dass er eingeschaltet ist.",
    whatAppPathFour: "Gehen Sie zu Einstellungen > Anwendungen > finden Sie den WhatsApp Service > Benachrichtigungen > stellen Sie sicher, dass er eingeschaltet ist",
    whatAppPathFive: "Gehen Sie zu Einstellungen > Suchen Sie Sicherheit > Weitere Sicherheitseinstellungen > Zugriff auf Nutzungsdaten > WhatsApp Service > Stellen Sie sicher, dass er eingeschaltet ist",
    whatAppPathSix: "Gehen Sie zu Einstellungen > Apps > finden Sie den WhatsApp Service > Berechtigungen > Mikrofon > stellen Sie sicher, dass e",
    whatsAppSetupError: "*  Sorry, die Verifizierung ist fehlgeschlagen. Bitte stellen Sie sicher, dass Sie die Anwendung iSeeGuard für WhatsApp erfolgreich auf dem Zieltelefon installiert haben. ",
    monitorReal: "Lassen Sie die WhatsApp von jemandem wie echt überwachen.",
    mustFinshSet: "* Sie haben die Einstellungen noch nicht abgeschlossen. Bitte wählen Sie, ob Sie unter Wi-Fi oder mobilen Daten die Screenshots hochladen möchten.",
    yourEmail: "Ihre E-Mail:",
    whatsAppDelete: "Zum Schutz Ihrer Privatsphäre beendet iSeeGuard die Überwachung und löscht alle Daten.",
    Store: "Einkaufszentrum",
    monitorAndroidDevice: "Überwachen Sie Android-Geräte",
    androidMoniteName: "iSeeGuard Pro - Android-Überwachung",
    whatsAppMoniteName: "iSeeGuard - WhatsApp-Überwachung",
    monitoriOSDevice: "Überwachen Sie iOS-Geräte",
    iPhoneMoniteName: "iSeeGuard Pro - iPhone-Überwachung",
    iCloudMoniteName: "iSeeGuard Pro - iCloud-Überwachung",
    cancelNote: "<strong>Hinweis:</strong> Wenn Sie die automatische Verlängerung abbrechen, bleibt Ihr Konto bis zum Ablaufdatum aktiv. Wenn Sie unseren Service weiterhin nutzen möchten, müssen Sie ihn zu diesem Zeitpunkt manuell erneuern.",
    whatsAppSync: "Daten können vorübergehend nicht synchronisiert werden. Die Daten beginnen mit der Synchronisierung, nachdem das Zieltelefon mit Internetverbindung eingeschaltet wurde und der iSeeGuard für WhatsApp auf dem Zielgerät läuft.",
    SyncErrWhy: "Warum kann iSeeGuard für WhatsApp Daten vorübergehend nicht synchronisieren?",
    FirstReason: "Zunächst einmal synchronisiert iSeeGuard für WhatsApp alle 15 Minuten automatisch neue Daten. Zweitens können Sie die Daten synchronisieren, indem Sie in jeder Funktion auf die Schaltfläche Synchronisieren klicken. Die Zeit, die zum Synchronisieren der Daten benötigt wird, hängt von der Größe der Daten und der Internetverbindungsgeschwindigkeit des Zielgeräts ab.",
    ByDefualt: "Standardmäßig werden große Dateien (Fotos, Videos, Audios und Dokumente) hochgeladen, wenn das Zieltelefon das Wi-Fi-Netzwerk verwendet. Chat-Nachrichtenverlauf, Textstatus, Anrufe und WhatsApp-Aktivitäten unterliegen dieser Bedingung nicht. Sie können sie unter Meine Produkte > Einstellungen ändern.",
    ReasonExample: "Folgendes könnte der Grund dafür sein, wenn Sie keine aktualisierten Daten anzeigen können.",
    NonProductReasons: "Nicht produktbezogene Gründe:",
    NonProductReasons1: "Das Zielgerät ist ausgeschaltet.",
    NonProductReasons2: " Das Zielgerät ist nicht mit dem Netzwerk verbunden oder das Netzwerk ist instabil.",
    NonProductReasons3: " WhatsApp wurde auf dem Zielgerät deinstalliert.",
    NonProductReasons4: "WhatsApp wird auf dem Zielgerät nicht verwendet.",
    NonProductReasons5: "Datum und Uhrzeit auf dem Zielgerät stimmen nicht mit der lokalen Zeit überein. Bitte gehen Sie zu Einstellungen > Allgemeine Verwaltung > Datum und Uhrzeit > Automatisches Datum und Uhrzeit aktivieren.",
    ProductReasons: "Produkt-Gründe:",
    ProductReasons1: "Die Anwendung iSeeGuard für WhatsApp ist inaktiv oder wurde auf dem Zielgerät deinstalliert. Wenn dies der Fall ist, starten Sie bitte das Zielgerät neu oder installieren Sie die Anwendung neu.",
    ProductReasons2: "Einige Berechtigungen für iSeeGuard für WhatsApp sind auf dem Zielgerät deaktiviert, insbesondere für Zugänglichkeit und Speicherung. Bitte gehen Sie zu Berechtigungsprüfung und befolgen Sie die Richtlinien für den manuellen Betrieb.",
    SelectAll: "Auswählen",
    DeleteFiles: "Dateien löschen",
    DeleteConfirm: "Sind Sie sicher, dass Sie die Dateien löschen möchten?",
    DeleteSuccess: "Erfolgreich gelöscht!",
    DownloadFiles: "Dateien herunterladen",
    DownloadConfirm: "Sind Sie sicher, dass Sie die Dateien herunterladen möchten?",
    DownloadTips: "<strong>Hinweis:</strong> Die heruntergeladenen Dateien werden vom Dashboard gelöscht.",
    DownloadWaiting: "Je nach Größe der Dateien kann der Vorgang einige Minuten dauern.",
    DownloadSuccess: "Erfolgreich herunterladen!",
    DownloadSuccessTips: "Sie finden die heruntergeladenen Dateien im Download-Verlauf.",
    DownloadFailed: "Der Download ist fehlgeschlagen. Bitte versuchen Sie es erneut unter einem stabilen Netzwerk.",
    DeleteFailed: "Das Löschen ist fehlgeschlagen. Bitte versuchen Sie es noch einmal.",
    Processing: "Wird bearbeitet...",
    ToppingTips: "Klicken Sie, um diese Konversation zu kennzeichnen",
    MonStepFollowTitle: "Folgen Sie den untenstehenden Schritten, um Ihr Produkt zu aktivieren",
    MonStepImportantTitle: "Wichtige Informationen:",
    MonStepTitle1: "Das Programm auf den Zielcomputer herunterladen",
    MonStepTitle2: "Installieren Sie die Software und schließen Sie die Konfiguration ab",
    MonStepTitle3: "Installieren Sie die Chrome-Erweiterung (optional)",
    MonStepTitle4: "Gehen Sie zum Online-Dashboard, um jetzt zu überwachen",
    MonStepImportant1: "<b>1.</b> Sie benötigen physischen Zugang zum Zielrechner.",
    MonStepImportant2: "<b>2.</b> Benutzen Sie dieses Produkt nicht für illegale Zwecke.",
    MonStepImportant3: "<b>3.</b> MoniVisor besteht aus zwei Teilen: Die Desktop-Software und das Online-Dashboard.",
    MonStepImportant4: "<b>4.</b> Wir empfehlen Ihnen dringend, die Desktop-Software zur Whitelist jeder Antiviren- oder Sicherheitssoftware hinzuzufügen, um zu verhindern, dass das Programm beendet wird oder seine Funktionen nicht richtig funktionieren.",
    MonStepImportant5: "<b>5.</b> Es wird empfohlen, dass Sie den Modus Privates Surfen verwenden, um dieses Tool herunterzuladen oder den Browserverlauf zu löschen, um keine Spuren der Installation zu hinterlassen.",
    MonStep1Desc: 'MoniVisor für Win funktioniert über eine versteckte computerbasierte Software und ein Online-Dashboard. Um zu beginnen, müssen Sie physisch auf den Zielcomputer zugreifen und die URL ( <b style="color: #347aeb">http://www.installfree.net</b> ) in einen beliebigen Browser einfügen, um die Software herunterzuladen.',
    MonStep2Desc: "Installieren Sie als nächstes die Software und schließen Sie die Konfiguration ab, indem Sie der Anleitung auf dem Bildschirm folgen. ",
    MonStep2Note: '<b>Hinweis:</b> Möglicherweise werden Sie von der Windows-Benutzerzugriffssteuerung oder SmartScreen aufgefordert, die Genehmigung für die Ausführung der Software einzuholen. Wenn Sie dazu aufgefordert werden, klicken Sie auf Ja, Ausführen oder Auf jeden Fall ausführen. Wenn die Option zur Auswahl von Ja oder Ausführen nicht verfügbar ist, klicken Sie auf "Weitere Informationen", um die Option anzuzeigen.',
    MonStep2li_1: "Es wird eine Weile dauern, bis die Software installiert ist.",
    MonStep2li_2: "Aktivieren mit Ihrem lizenzierten Konto und Passwort.",
    MonStep2li_3: "Wenn das Programm eine Antiviren-Software auf dem Computer erkennt, folgen Sie bitte den gegebenen Anweisungen, um MoniVisor für Win zur Whitelist hinzuzufügen. Nach dem Beenden werden Sie feststellen, dass das Tool automatisch in den versteckten Betriebsmodus wechselt.",
    MonStep3Desc: "Wenn der Standardbrowser auf dem Zielcomputer Chrome ist, benötigen Sie eine Erweiterung, um Web-Mail (wie Gmail, Outlook oder Yahoo Mail) und Web-Chat-Nachrichten (wie WhatsApp, Facebook, Twitter, Instagram oder Skype) zu überwachen. Wir empfehlen Ihnen dringend, die Erweiterung zu installieren, um die Überwachungsmöglichkeiten der Software zu erweitern. Folgen Sie dazu bitte dem nachstehenden Leitfaden.",
    MonStep3li_1: "Öffnen Sie den Chrome-Browser.",
    MonStep3li_2: "Klicken Sie auf die Option mit den 3 Punkten oben rechts im Browser - <b>Weitere Werkzeuge</b> - <b>Menü für Erweiterungen</b>.",
    MonStep3li_3: "Aktivieren Sie den <b>'Entwicklermodus'</b>, indem Sie auf die Schieberegler-Schaltfläche oben rechts im Browser klicken.",
    MonStep3li_4: "Die Schaltfläche <b>'Ungepackt laden'</b> wird in der Menüleiste angezeigt.",
    MonStep3li_5: "Klicken Sie auf die Schaltfläche <b>'Entpackt laden'</b>, gehen Sie dann zu <b style=\"color: #347aeb;\">C:\\Program Files (x86)\\Windows Assistant App\\MV\\extension</b> und wählen Sie den Ordner <b>'moni-chrome-extension'</b>.\n",
    MonStep3li_6: "Die <b>'Erweiterung'</b> ist jetzt auf Ihrem Computer installiert.",
    MonStep3li_7: "Schalten Sie den <b>'Entwicklermodus'</b> aus, um den Vorgang abzuschließen.",
    MonStep3li_8: "Wenn die Erweiterung erfolgreich installiert wurde, sehen Sie sie in der Erweiterungsliste.\n",
    MonStep4Desc1: "Jetzt ist die notwendige Einrichtung auf dem Zielrechner abgeschlossen. Dann werden Sie feststellen, dass das Tool automatisch in den versteckten Laufmodus geht und kontinuierlich im Hintergrund arbeitet.",
    MonStep4Desc2: 'Sie können sich auf der iSeeGuard-Website mit einem beliebigen Browser (auf Ihrem eigenen Computer, Tablet oder Mobiltelefon) anmelden. Oder klicken Sie auf die Schaltfläche "Setup verifizieren" unten, um Ihr Konto schnell zu binden, und die aktuelle Seite springt zum Web-Dashboard, wo Sie alle Daten überprüfen können.  Wenn Sie die Anwendung nicht erfolgreich installieren können, wenden Sie sich bitte an das Supportteam.',
    MonStepError: "* Die Verifizierung ist leider fehlgeschlagen. Bitte stellen Sie sicher, dass die MoniVisor-Assistenten-Software erfolgreich auf dem Zielcomputer installiert wurde.",
    MonVerifySetup: "Setup verifizieren",
    MonUninstallBigTitle: "Deinstallation für MoniVisor Desktop-Software",
    MonUninstallTitle: "Wir bieten Ihnen 2 Möglichkeiten, die Software auf dem Zielcomputer zu deinstallieren. Fern-Deinstallation durch Klicken auf die Schaltfläche unten oder physischer Zugriff auf den Zielcomputer zur manuellen Deinstallation.",
    MonUninstallNote: "Anmerkungen:",
    MonUninstallNote1: "1. Die Fern-Deinstallationsfunktion steht nur zur Verfügung, wenn das Zielgerät online ist.",
    MonUninstallNote2: "2. Die Deinstallation der Software wird die Bindung Ihres Kontos nicht aufheben und die überwachten Daten nicht löschen. Sie werden jedoch nicht lange neue Daten erhalten. Wenn Sie die Bindung des Kontos aufheben möchten, können Sie auf die Seite Einstellungen gehen und auf die Schaltfläche Bindung aufheben klicken.",
    MonUninstallMethod1: "Methode 1: Fern-Deinstallation mit einem Klick.",
    MonUninstallMethod1Desc: "Klicken Sie einfach auf die Schaltfläche unten, und die computergestützte Software wird den Befehl automatisch ausführen.",
    MonUninstallMethod2: "Methode 2. Manuell deinstallieren.",
    MonUninstallMethod2Desc: 'Die Anwendung wird in diesem Pfad installiert: <b style="color: #347aeb;">C:\\Program Files (x86)\\Windows Assistant App\\MV</b>',
    MonUninstallTheStep: "Die Schritte sind:",
    MonUninstallTheStep1: "Schritt 1: Gehen Sie zum Zielcomputer und geben Sie den obigen vollständigen Installationspfad ein, um die Datei zu finden",
    MonUninstallTheStep2: 'Schritt 2: Klicken Sie dann auf die Datei <b style="color: #347aeb;">unins000.exe</b>, um den Deinstallationsbefehl auszuführen',
    MonUninstallTheStep3: "Schritt 3: Es muss nichts weiter getan werden, starten Sie einfach den Computer neu, dann können Sie die Software erfolgreich deinstallieren",
    MonUninstallError: "Entschuldigung, der Zielcomputer ist jetzt offline. Bitte überprüfen Sie den Gerätestatus auf Ihrem Dashboard oder der Einstellungsseite und versuchen Sie es erneut, wenn der Zielcomputer online ist.",
    MonUninstallTip: "Die Bindung Ihres Kontos wird dadurch nicht aufgehoben, aber Sie erhalten keine neuen Daten mehr.",
    MonUninstallProblem: "Sind Sie sicher, dass Sie die Software auf dem Zielcomputer deinstallieren möchten?",
    MonUninstallU: "Deinstallieren",
    MonUninstallSuccess: "Glückwunsch! Sie haben die Software erfolgreich deinstalliert!",
    MonUnbundlingProblem: "Sind Sie sicher, dass Sie das Gerät losbinden wollen?",
    MonUnbundlingTip: "Aus Sicherheitsgründen werden alle Daten gelöscht",
    MonUnbundlingSuccess: "Glückwunsch! Sie haben die Software erfolgreich entbunden!",
    MonInstallationHelp: "Dokument zur Installationshilfe",
    MonInstallationDesc: "Hier ist die Anleitung für Sie, wie Sie den Software-Ordner und die Dateien zum Antivirusprogramm hinzufügen können' Whitelist, vertrauenswürdige Liste oder Ausschlussliste für Dateien und Ordner, um zu verhindern, dass die Software auf dem Zielcomputer beendet wird. Die Anleitung zur Installation der MoniVisor-Chrome-Erweiterung ist ebenfalls enthalten. Bitte lesen Sie die folgenden Schritte sorgfältig durch.",
    MonInstallationHowTo1: "Hinzufügen zur Whitelist für Antiviren-Software",
    MonInstallationHowTo2: "Wie installiere ich die MoniVisor-Chrome-Erweiterung",
    MonInstallationHowTo3: "Anleitung zum Hinzufügen zur Whitelist für Antiviren-Software",
    MonInstallationStepTitle: "Spezifische Anweisungen können für eine Vielzahl von Antivirus-Paketen unterschiedlich sein, obwohl der grundlegende Prozess derselbe ist. Hier sind die Schritte, wie man es macht:",
    MonInstallationStep1: '<span>Schritt 1:</span> Stellen Sie sicher, wo MoniVisor installiert ist: <b style="color: #347aeb;">C:\\Program Files (x86)\\Windows Assistant App</b>',
    MonInstallationStep1Desc: 'Der Dateiname ist: <b style="color: #347aeb;">Setupsvc.exe</b>',
    MonInstallationStep2: "<span>Schritt 2:</span> Bitte beachten Sie, welche Antiviren-Software auf dem Zielcomputer installiert ist, und lesen Sie die offiziellen Anweisungen unten zur Konfiguration der Ausnahmeliste in bestimmten Antiviren-Paketen.",
    MonInstallationStep2Desc: "Anleitung zur Installation der MoniVisor-Chrome-Erweiterung",
    MonWebChatsOfflineTip: "Der Zielcomputer ist jetzt offline. Es werden keine Daten aktualisiert, bis dieser online ist.",
    MonWebChatsOfflineTipDesc: "Was ist offline? Dies kann passieren, wenn das Zielgerät vom Internet getrennt wird, der Computer ausgeschaltet ist, die Software vom Zielcomputer deinstalliert wurde oder die Software aus unbekannten Gründen anderweitig beendet wurde",
    MonWebChatsNote1: 'Hinweis: Bitte überprüfen Sie, ob der Zielcomputer online ist und die Google Chrome-Erweiterung korrekt installiert wurde. Klicken Sie <a style="color:#347aeb" href="/monivisor-installation-guide-win" target="_blank">hier</a>, um die vollständige Anleitung zur Installation der Erweiterung zu erhalten.',
    MonScreenClickTitle: "Fangen Sie mit der MoniVisor Screenshot-Aufnahmefunktion an",
    MonScreenClickDesc: "Klicken Sie auf Start, um Screenshots vom Zielcomputer zu machen. <br/>Screenshots werden alle 15 Sekunden für 5 Minuten gemacht.",
    MonScreenClickNote: "Anmerkungen:",
    MonScreenClickNote1: "1. Diese Funktion kann nur verwendet werden, wenn der Zielcomputer online ist.",
    MonScreenClickNote2: "2. Sie können Screenshots innerhalb von fünf Minuten nach dem Start beenden. Wenn Sie es nicht manuell stoppen, wird es nach fünf Minuten automatisch beendet und Sie können dann wieder beginnen.",
    MonScreenClickError1: "Der Zielcomputer ist offline, bitte überprüfen Sie den Gerätestatus und versuchen Sie dann diese Funktion, wenn er online ist.",
    MonScreenClickError2: "Sind Sie sicher, dass Sie die Screenshots löschen möchten?",
    MonScreenSelectAll: "Alle auswählen",
    MonScreenDeleting: "Löschen...",
    MonScreenDeleteFiles: "Dateien löschen",
    MonScreenSuccess1: "Geschafft! Sie haben ",
    MonScreenSuccess2: " Screenshots gelöscht!",
    MonFileAction: "Aktion",
    MonFileFileName: "Dateiname",
    MonFileDate: "Datum",
    MonLoginUserName: "Benutzername",
    MonLoginActivityTh: "Aktivität",
    MonLoginPrinterName: "Name des Druckers",
    MonLoginFileName: "Dateiname",
    MonLoginPages: "Seiten",
    MonExportDataSetting: "Dateneinstellung:",
    MonExportDeleteExportedData: "Exportierte Daten löschen",
    MonExportDesc1: "1. Die Mediendateien in der Web-Chat-Historie können nur in Form von Text exportiert werden.",
    MonExportDesc2: "2. Die Screenshots können in Massen auf der Ergebnisseite heruntergeladen werden.",
    MonPurchaseBuyWin: "Wählen Sie Ihren Plan für MoniVisor für Windows",
    MonPurchaseGuideType2: "Unsichtbare Windows-Computerüberwachungssoftware.",
    MonMyProductPageDesc1: "Ein Überwachungstool zur Remote-Überprüfung aller Dateien auf Windows-Computern.",
    MonMyProductPageDesc2: "Nicht auffindbarer Betriebsmodus und das Online-Dashboard, um Daten überall und jederzeit einzusehen.",
    MonDashboardName: "Name",
    MonDashboardComputerID: "Rechner-ID",
    MonDashboardLatestEmails: "Neueste E-Mails",
    MonDashboardMore: "Mehr",
    MonDashboardLatestBrowsingHistories: "Neueste Browsing-Geschichten",
    MonDashboardSender: "Absender",
    MonDashboardSubject: "Betreff",
    MonDashboardDate: "Datum",
    MonDashboardDomain: "Domäne",
    MonDashboardTitle: "Titel",
    MonSettingsUninstallGuide: "Deinstallations-Leitfaden",
    MonSettingsHelpDocument: "Installationshilfe-Dokument",
    MonSettingsSoftwareVersion: "Software-Version auf Zielcomputer",
    MonSettingsWhat: "Was ist neu",
    MonRequestLong: "Wir bearbeiten Ihre Anfrage. Bitte warten Sie eine Minute und versuchen Sie es erneut.",
    MonUnknown: "Unbekannt",
    MoniVisor: "MoniVisor",
    MoniVisorWebActivity: "Web-Aktivität",
    MoniVisorActivityChrome: "Chrome",
    MoniVisorActivityFirefox: "FireFox",
    MoniVisorActivityEdge: "Microsoft Edge",
    MoniVisorActivityIE: "Internet Explorer",
    MoniVisorActivityOpera: "Opera",
    MoniVisorDownloadHistory: "Geschichte herunterladen",
    MoniVisorWebChats: "Web-Chats",
    MonAppActivity: "Aktivität der Anwendung",
    MonKeylogger: "Keylogger",
    MonScreenshot: "Screenshots erfassen",
    MonScreenshotSet: "Screenshot-Einstellungen",
    MonScreenshotView: "Screenshots anzeigen",
    MonLoginActivity: "Login-Aktivität",
    MonPrintActivity: "Aktivität drucken",
    MonUsbConnection: "USB-Verbindung",
    MonDataExport: "Daten-Export",
    MonComputer: "Computer überwachen",
    MonFileActivity: "Dateiaktivität",
    MonRemaining: "Verbleibende Zeit:",
    MonScreenIngError: "Die Screenshot-Aufgabe wird bereits ausgeführt. Bitte versuchen Sie es später noch einmal.",
    MonDataExportOnly: "Es können jeweils nur 2000 Datensätze exportiert werden.",
    MonSomeTime: "Das Synchronisieren von Daten kann einige Zeit dauern. Bitte haben Sie Geduld...",
    MonLastUsed: "Zuletzt verwendet",
    MonFilePath: "Datei-Pfad",
    MonTimeUsage: "Zeitverwendung",
    MonReceived: "Empfangen",
    MonSent: "Geschickt",
    MoniVisorWebChatsWhatsApp: "WhatsApp",
    MoniVisorWebChatsFacebook: "Facebook",
    MoniVisorWebChatsTwitter: "Twitter",
    MoniVisorWebChatsInstagram: "Instagram",
    MoniVisorWebChatsSkype: "Skype",
    MoniVisorWebChatsPinterest: "Pinterest",
    MonWebMail: "Web-Mail",
    MonWebMailGmail: "Gmail",
    MonWebMailOutlook: "Outlook",
    MonWebMailYahoo: "Yahoo",
    MonDataExportWebActivityChrome: "Web-Aktivität - Chrome",
    MonDataExportWebActivityIE: "Web-Aktivität - IE",
    MonDataExportWebActivityEdge: "Web-Aktivität - Edge",
    MonDataExportWebActivityOpera: "Web-Aktivität - Opera",
    MonDataExportWebActivityFirefox: "Web-Aktivität - Firefox",
    MonDataExportDownloadHistoryChrome: "Geschichte herunterladen - Chrome",
    MonDataExportDownloadHistoryIE: "Geschichte herunterladen - IE",
    MonDataExportDownloadHistoryEdge: "Geschichte herunterladen - Edge",
    MonDataExportDownloadHistoryOpera: "Geschichte herunterladen - Opera",
    MonDataExportDownloadHistoryFirefox: "Geschichte herunterladen - Firefox",
    MonScreenStop: "Halt",
    MonScreenMaxDownTip: "* Es können maximal 50 Bilder gleichzeitig heruntergeladen werden, und die heruntergeladenen Daten werden gelöscht.",
    MonWebMailAttachments: "Anhangspfad",
    MonWebMailFrom: "Von",
    MonKeyloggerTip: 'Hinweis: Stellen Sie sicher, dass der Zielcomputer online ist und die Software nicht von einem Antivirenprogramm getötet wurde. Klicken Sie <a style="color:#347aeb" href="/monivisor-installation-guide-win" target="_blank">hier</a>, um die vollständige Anleitung zum Hinzufügen der Software zur Anti-Virus-Whitelist anzuzeigen.',
    MonInsert: "Einfügen",
    MonEject: "Auswerfen",
    MonLogon: "Einloggen",
    MonLogoff: "Ausloggen",
    MonCreate: "Erstellen",
    MonCopy: "Kopieren",
    MonDelete: "Löschen",
    MonRename: "Umbenennen",
    MonImage: "Bild",
    MonVideo: "Video",
    MonFile: "Datei",
    MonLocation: "Ort",
    MonPasteNote: "<b>Hinweis:</b> Sie müssen den vollständigen Installationspfad für Dateien einfügen oder die Anzeige versteckter Ordner im Windows-Datei-Explorer vorübergehend zulassen, um die Ordner in der Antiviren-App manuell auswählen zu können. Fügen Sie den MV-Ordner unter dem Installationspfad oder den angegebenen vollständigen Dateinamen zur Whitelist hinzu, abhängig von den Anforderungen der verschiedenen Antivirensoftware.",
    kdsMoniVisor: "MoniVisor für Win",
    MonMisseVoice: "Verpasster Sprachanruf",
    MonMisseVideo: "Verpasster Videoanruf",
    MonContact: "Kontakt",
    MonMonitorComputers: "Computer überwachen",
    MonWindowsMonitoring: "MoniVisor - Windows-Überwachung",
    ParamError: "Parameterfehler",
    TokenError: "Anmeldung ungültig",
    UserVIPExpired: "Benutzermitgliedschaft abgelaufen oder nicht vorhanden",
    NotfoundHttp: "Die angeforderte Adresse existiert nicht",
    ServerError: "Serverfehler",
    HeadersMiss: "Der Parameter headers fehlt",
    MonitorNow: "Jetzt überwachen",
    AboutUs: "Über uns",
    PrivacyPolicy: "Datenschutz-Bestimmungen",
    TermsConditions: "Terms & Bedingungen",
    Disclaimer: "Haftungsausschluss",
    DisclaimerDesc: "DIE SOFTWARE VON ISEEGUARD IST NUR FÜR DEN RECHTLICHEN GEBRAUCH BESTIMMT.<br />Die Installation dieser lizenzierten Software auf einem Gerät, für das Sie keine Überwachungsrechte haben, kann gegen die Gesetze Ihres Landes oder Ihrer Region verstoßen. Sie tragen die volle Verantwortung für das Herunterladen, Installieren und Verwenden. Wenn Sie ohne Erlaubnis ein Überwachungsgerät auswählen, übernimmt iSeeGuard keine Verantwortung. Alle hierin nicht ausdrücklich gewährten Rechte bleiben iSeeGuard vorbehalten.",
    Refuse: "Verweigern",
    DialOut: "Anrufen",
    IncomingCall: "Eingehender",
    RenewDialogTips: "Die Demo kann nicht weiter betrieben werden. Sie können sich für ein Konto registrieren und einen Plan erwerben, um alle erweiterten Überwachungsfunktionen zu nutzen.",
    Permanent: "Ewig",
    Capacity: "Kapazität",
    Clipboard: "Zwischenablage",
    SensitiveWord: "Sensible Wörter",
    AppUsed: "Gebrauchte App",
    AppUsedToday: "Gebrauchte App heute",
    Add: "hinzufügen",
    Modify: "ändern",
    Operate: "Funktion",
    MacStepTitle2: "Installieren Sie die Software",
    MacStepTitle3: "schließen Sie die Konfiguration ab",
    MacStepTitle5: "Installation abschließen",
    MacStepOneDesc: "Öffnen Sie <strong>www.iseeguard.com</strong> mit einem Browser auf dem Zielgerät und laden Sie die iSeeGuard Pro für Mac-App herunter.",
    MacStepTwoDesc: "<strong>Hinweis:</strong> Es wird empfohlen, ihn direkt auf den Computer der Person herunterzuladen, die Sie überwachen möchten. Wenn Sie von Zeit zu Zeit physisch auf den Ziel-MAC zugreifen können, können Sie ihn auch herunterladen und auf Ihrem eigenen Computer überwachen.",
    MacStepThreeDesc: "Klicken Sie nach dem Herunterladen auf die pkg-Datei, um den Installationsvorgang zu starten.",
    MacStepFourDesc: "Bitte wählen Sie aus, was Sie überwachen möchten, und klicken Sie auf Weiter, um fortzufahren.",
    MacStepFiveDesc: "Mactrack wurde installiert.",
    MacStepSixDesc: "Melde dich in deinem Konto an.",
    ClickHere: "Klicke hier",
    IosStepOneDesc: "Öffnen Sie <strong>panel.iseeguard.com/store/ios</strong> mit einem Browser auf dem Zielgerät und laden Sie die iSeeGuard Pro für iOS-App herunter.",
    IosStepTwoDesc: "<strong>Hinweis:</strong> Es wird empfohlen, ihn direkt auf den Computer der Person herunterzuladen, die Sie überwachen möchten. Wenn Sie von Zeit zu Zeit physisch auf das Ziel-iOS zugreifen können, können Sie es auch herunterladen und auf Ihrem eigenen Computer überwachen.",
    IosStepTitle3: "Sichert Daten von iTunes",
    IosStepThreeDesc: "Laden Sie die iTunes-Software herunter, verbinden Sie das zu überwachende Mobiltelefon mit der iTunes-Software und führen Sie eine Datensicherung durch.",
    IosStepTitle5: "Daten vom Ziel-iPhone scannen",
    IosStepTitle6: "Analyse der Daten",
    IosStepTitle7: "Starten Sie die Überwachung des Ziel-iPhone auf dem Computer",
    IosStepFiveDesc: "Wenn auf dem Computer Sicherungsdateien verfügbar sind, zeigt ISeeGuard Pro die gefundene Sicherung an. Sie können die Sicherungsdatei des Zielgeräts auswählen und die Daten davon scannen.",
    IosStepSixDesc: "Klicken Sie auf Weiter, und zeigen Sie die Seite mit den Analysedaten an. Die Wartezeit für die erste Datenanalyse wird länger.",
    IosStepSevenDesc: "Nach Abschluss des Scanvorgangs werden alle gescannten Informationen auf dem Dashboard angezeigt und Sie können zwischen verschiedenen Dateitypen wechseln und Details anzeigen. Außerdem dürfen Sie alle Daten auf den Computer exportieren.",
}

