import { getList } from '@/api/android/calendars'
import router from "@/router"
import {getAddressInfo} from '@/utils/mapboxRequest'
import { getCurrentDate } from "@/utils/common"
export default {
    namespaced: true,
    state: {
        //日历列表
        calendarList: [],
        //分页参数
        pageParams: {
            page: 1,
            total: 0,
            type: 0,
            search:""
        },
        // 最后同步时间
        synchronisedTime: ''
    },
    mutations: {
        /**
         * 更新最后同步时间
         * @param {*} state
         * @param {synchronisedTime} payload
         */
         setSynchronisedTime(state) {
            state.synchronisedTime = getCurrentDate()
            // Vue.set(state, 'synchronisedTime', getCurrentDate());
        },
        /**
         * 写入日程列表
         * @param {*} state 
         * @param {*} data
         */
        setCalendarList(state, data) {
            state.calendarList = data
            // Vue.set(state, 'calendarList', data)
        },
        /**
         * 重置日程列表
         * @param {*} state 
         */
        resetCalendarList(state) {
            state.calendarList = []
            // Vue.set(state, 'calendarList', [])
        },
        /**
         * 重置分页参数
         * @param {*} state 
         */
        resetPageParams(state) {
            state.pageParams.page = 1
            state.pageParams.total = 0
            // Vue.set(state.pageParams, 'page', 1)
            // Vue.set(state.pageParams, 'total', 0)
        },
        /**
         * 写入分页参数
         * @param {*} state 
         * @param {*} data 
         */
        setPageParams(state,data) {
            state.pageParams.page = data.current_page
            state.pageParams.total = data.total
            // Vue.set(state.pageParams, 'page', data.current_page)
            // Vue.set(state.pageParams, 'total', data.total)
        },
        setPageParamsPage(state,page) {
            state.pageParams.page = page
            // Vue.set(state.pageParams, 'page', page)
        },
        setPageParamsSearch(state,search) {
            state.pageParams.search = search
            // Vue.set(state.pageParams, 'search', search)
        }
    },
    actions: {
        async requestCalendarList(context,paramsData = []) {
            let params = context.getters.getRequestCalendarListParams;
            paramsData.forEach((item) => { params[item.key] = item.value });
            const result = await getList(router.app._route.params.equipmentId, params);
            let data = result.data.data;
            context.commit('setCalendarList', data)
            context.commit('setPageParams',result.data)
        }
    },
    getters: {
        /**
         * 获取同步时间
         * @param {*} state 
         * @returns 
         */
         getSynchronisedTime: state => {
            return state.synchronisedTime
        },
        /**
         * 获取日程列表
         * @param {*} state 
         * @returns 
         */
        getCalendarList: state => {
            let data = state.calendarList;
            data.forEach(item => {
                if(item.longitude==0||item.latitude==0){
                    item.place_name = "---"
                }else{
                    item.place_name = getAddressInfo(item.longitude,item.latitude)
                }
                if(item.description == ""){
                    item.description = "---"
                }
                
            })
            return data
        },
        /**
         * 获取请求日程列表参数
         * @param {*} state 
         * @returns 
         */
        getRequestCalendarListParams: state => {
            return {
                page: state.pageParams.page,
                type: state.pageParams.type,
                search:state.pageParams.search
            }
        },
        getPageParams:state=>{
            return state.pageParams
        }
    }
}