import { getList } from "@/api/android/applicationChatRecords"
import router from "@/router"
import { getCurrentDate } from "@/utils/common"
export default {
    namespaced: true,
    state: {
        applicationChatRecordList: {},
        pageParams: {},
        synchronisedTime: {}
    },
    mutations: {
        /**
         * 更新最后同步时间
         * @param {*} state
         * @param {synchronisedTime} payload
         */
        setSynchronisedTime(state, applicationId) {
            let synchronisedTime = state.synchronisedTime;
            synchronisedTime[applicationId] = getCurrentDate()
            state.synchronisedTime = synchronisedTime
            // Vue.set(state, "synchronisedTime", synchronisedTime);
        },
        /**
         * 写入聊天记录
         * @param {*} state 
         * @param {*} param1 
         */
        setApplicationChatRecordList(state, { applicationId, data }) {
            let applicationChatRecordList = state.applicationChatRecordList
            if (!(applicationId in applicationChatRecordList)) {
                applicationChatRecordList[applicationId] = []
                state.applicationChatRecordList = applicationChatRecordList
                // Vue.set(state, 'applicationChatRecordList', applicationChatRecordList);
            }
            data.forEach(item => { applicationChatRecordList[applicationId].unshift(item) })
            state.applicationChatRecordList = applicationChatRecordList;
            // Vue.set(state.applicationChatRecordList, applicationChatRecordList)
        },
        /**
         * 重置聊天记录
         * @param {*} state 
         * @param {*} applicationId 
         */
        resetApplicationChatRecordList(state, applicationId) {
            let applicationChatRecordList = state.applicationChatRecordList
            applicationChatRecordList[applicationId] = []
            state.applicationChatRecordList = applicationChatRecordList
            // Vue.set(state, 'applicationChatRecordList', applicationChatRecordList);
        },
        /**
         * 重置分页参数
         * @param {*} state 
         * @param {*} applicationId 
         */
        resetApplicationChatRecordPageParams(state, applicationId) {
            state.pageParams[applicationId] = {
                page: 1,
                total: 0,
                startDateTime: "",
                endDateTime: "",
                toDayTime: "",
                search:""
            }
            // Vue.set(state.pageParams, applicationId, {
            //     page: 1,
            //     total: 0,
            //     startDateTime: "",
            //     endDateTime: "",
            //     toDayTime: "",
            //     search:""
            // });
        },
        /**
         * 设置分页参数
         * @param {*} state 
         * @param {*} param1 
         */
        setApplicationChatRecordPageParams(state, { applicationId, current_page, total }) {
            let pageParams = state.pageParams
            pageParams[applicationId].page = current_page
            pageParams[applicationId].total = total
            state.pageParams = pageParams
            // Vue.set(state, 'pageParams', pageParams)
        },
        /**
         * 设置分页参数当前页
         * @param {*} state 
         * @param {*} param1 
         */
        setApplicationChatRecordPageParamsPage(state, { applicationId, page }) {
            let pageParams = state.pageParams
            pageParams[applicationId].page = page
            state.pageParams = pageParams
            // Vue.set(state, 'pageParams', pageParams)
        },
        setApplicationChatRecordPageParamsDateTime(state, { applicationId, startDateTime, endDateTime, toDayTime }) {
            let pageParams = state.pageParams
            pageParams[applicationId].startDateTime = startDateTime
            pageParams[applicationId].endDateTime = endDateTime
            pageParams[applicationId].toDayTime = toDayTime
            state.pageParams = pageParams
            // Vue.set(state, 'pageParams', pageParams)
        },
        setApplicationChatRecordPageParamsSearch(state,{applicationId,search}) {
            let pageParams = state.pageParams
            console.log(applicationId)
            console.log(pageParams[applicationId])
            pageParams[applicationId].search = search
            state.pageParams = pageParams
            // Vue.set(state, 'pageParams', pageParams)
        }
    },
    actions: {
        /**
         * 请求获取应用聊天记录列表
         * @param {*} context 
         * @param {*} param1 
         */
        async requestApplicationChatRecordList(context, applicationId) {
            const result = await getList(router.app._route.params.equipmentId, context.getters.getrequestApplicationChatRecordListParams(applicationId))
            context.commit('setApplicationChatRecordList', {
                applicationId: applicationId,
                data: result.data.data
            })
            context.commit('setApplicationChatRecordPageParams', {
                applicationId: applicationId,
                current_page: result.data.current_page,
                total: result.data.total
            })
        }
    },
    getters: {
        getSynchronisedTime: state => (applicationId) => {
            if (!(applicationId in state.synchronisedTime)) {
                const d = new Date();
                return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`
            }
            return state.synchronisedTime[applicationId]
        },
        /**
         * 获取应用聊天记录列表
         * @param {*} state 
         * @returns 
         */
        getApplicationChatRecordList: state => (applicationId) => {
            let applicationChatRecordList = state.applicationChatRecordList
            if (!(applicationId in applicationChatRecordList)) {
                applicationChatRecordList[applicationId] = []
                state.applicationChatRecordList = applicationChatRecordList
                // Vue.set(state, 'applicationChatRecordList', applicationChatRecordList);
                return [];
            }
            return state.applicationChatRecordList[applicationId]
        },
        /**
        * 获取列表分页参数
        * @param {*} state 
        * @returns 
        */
        getPageParams: state => (applicationId) => {
            let pageParams = state.pageParams
            if (!(applicationId in pageParams)) {
                return {
                    page: 1,
                    total: 0,
                    startDateTime: "",
                    endDateTime: "",
                    toDayTime: "",
                    search: ""
                }
            }
            return state.pageParams[applicationId]
        },
        /**
         * 获取应用聊天记录列表请求参数
         * @param {*} state 
         * @returns 
         */
        getrequestApplicationChatRecordListParams: state => (applicationId) => {
            let pageParams = state.pageParams
            let params = {};
            params.applicationId = applicationId
            if (!(applicationId in pageParams)) {
                pageParams[applicationId] = {
                    page: 1,
                    total: 0,
                    startDateTime: "",
                    endDateTime: "",
                    toDayTime: "",
                    search: ""
                }
            }
            params.page = pageParams[applicationId].page
            params.startDateTime = pageParams[applicationId].startDateTime
            params.endDateTime = pageParams[applicationId].endDateTime
            params.toDayTime = pageParams[applicationId].toDayTime
            params.search = pageParams[applicationId].search
            return params;
        },
        /**
         * 获取大图预览列表
         * @param {*} state 
         * @returns 
         */
        getPicturePreviewList: state => (applicationId) => {
            let applicationChatRecordList = state.applicationChatRecordList[applicationId]
            let images = [];
            applicationChatRecordList.forEach((item) => {
                if (item.type === 2) {
                    item.record.original_image_url = item.record.thumbnail_url
                    images.push(item.record)
                }
            })
            return images
        },
    }
}