<template>
  <div class="user_bx">
    <el-dropdown trigger="click" @command="handleUserCommand">
      <div class="flex flex-align-items-center" @click="userFlag = !userFlag">
        <el-avatar :size="30" icon="el-icon-s-custom" alt="avatar"></el-avatar>
        <i
          class="el-icon-arrow-down transition-arrow"
          :class="userFlag ? 'active' : ''"
        />
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="userCenter" v-if="routeHidden !== 1">
          <span>{{ $t("myProduct") }}</span>
        </el-dropdown-item>
        <!-- <el-dropdown-item command="setting">
                <span>{{ $t('Profile') }}</span>
              </el-dropdown-item>
              <el-dropdown-item command="contactUs">
                <span>{{ $t('ContactUs') }}</span>
              </el-dropdown-item> -->
        <el-dropdown-item command="outLogin">
          <span>{{ $t("Logout") }}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: "Avatar",
  data() {
    return {
      userFlag: false,
      routeHidden: 0, // 判断当前是哪个路由，对应隐藏什么item
    };
  },
  mounted(){
    const routePath = this.$route.path;
    switch (routePath) {
      case '/my-products':
        this.routeHidden = 1
        break;
      default:
        break;
    }
  },
  methods: {
    handleUserCommand(command) {
      this.userFlag = !this.userFlag;
      switch (command) {
        case "userCenter":
          this.$router.replace("/my-products");
          break;
        case "contactUs":
          break;
        case "outLogin":
          this.$store.dispatch("users/login_out").then(() => {
            this.$notify({
              title: "success",
              message: "loginOut success",
              type: "success",
              duration: 3000,
            });
            this.$router.push("/login");
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.user_bx {
  margin-left: 20px;
  img {
    width: 30px;
    height: 30px;
  }
}
.active {
  transform: rotate(180deg);
}
.transition-arrow {
  transition: all 0.5s;
  margin-left: 6px;
}
</style>