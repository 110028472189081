const CryptoJS = require('crypto-js') // 引用AES源码js

const key = CryptoJS.enc.Utf8.parse('CSDWebForKey3494') // 十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse('CSDWebForIV42682') // 十六位十六进制数作为密钥偏移量

// 解密方法
function Decrypt(sth) {
  if (process.env.VUE_APP_AES === 'true') {
    const encryptedHexStr = CryptoJS.enc.Base64.parse(sth)
    const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr)
    const decrypt = CryptoJS.AES.decrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    })
    const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
    try {
      return JSON.parse(decryptedStr)
    } catch (error) {
      return decryptedStr
    }
  } else {
    return sth
  }
}

// 加密方法
function Encrypt(sth) {
  if (process.env.VUE_APP_AES === 'true') {
    const srcs = CryptoJS.enc.Utf8.parse(sth)
    const encrypted = CryptoJS.AES.encrypt(srcs, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    })
    // return encrypted.ciphertext.toString().toUpperCase(); // 16进
    return encrypted.toString() // base64
    // return encrypted // base64
  } else {
    return sth
  }
}

export default {
  Decrypt,
  Encrypt,
}