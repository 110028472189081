import i18n from "@/lang"

function _generateLanguage(key) {
  const i18nString = i18n.t(key)
  if (i18nString) {
    return i18nString
  } else {
    return key
  }
}

export const generateLanguage = _generateLanguage;