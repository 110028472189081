<template>
  <div name="AdminLayout">
    <div class="app-wrapper">
      <div class="main-container">
        <div
          v-show="getAdminSidebarShow"
          class="hazy-layer"
          @click="setAdminSidebarShow(false)"
        />
        <transition name="left">
          <div v-show="getAdminSidebarShow" class="left-main">
            <Headers @nextPagehandle="nextPagehandle" />
            <sidebar :menu-list.sync="menuList" />
          </div>
        </transition>
        <div class="right-main">
          <backstage-head class="backstage-head" />
          <el-scrollbar
            class="app-main-scrollbar"
            :style="{ height: scrollbarHeight }"
            wrap-class="app-main"
            ref="scrollbarBox"
          >
            <app-main ref="appMain" />
          </el-scrollbar>
          <copyright ref="copyright" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import { AppMain, Sidebar, BackstageHead, Headers } from "./components";
import Copyright from "@/components/admin/Copyright";
export default {
  name: "AdminLayout",
  components: {
    AppMain,
    Sidebar,
    BackstageHead,
    Copyright,
    Headers,
  },
  data() {
    return {
      scrollbarHeight: "",
      isShowMap: false,
    };
  },
  computed: {
    ...mapGetters("admin", {
      getAdminSidebarShow: "getAdminSidebarShow",
    }),
    ...mapGetters("admin/permission", {
      menuList: "getMenuList",
    }),
    ...mapGetters("width", {
      theCurrentWidth: "theCurrentWidth",
    }),
    ...mapGetters("equipment", {
      getEquipmentId: "getEquipmentId",
    }),
  },
  watch: {
    //监听页面宽度
    theCurrentWidth: {
      immediate: true,
      handler(newValue) {
        this.setAdminSidebarShow(newValue > 1200);
      },
    },
    "$route.params.equipmentId": {
      handler() {
        this.requestData();
      },
      // 深度观察监听
      immediate: true,
    },
  },
  created() {
    const that = this;
    that.setMenuList("Admin");
  },
  mounted() {
    this.initWebSocket();
    this.initScrollbarHeight();
    this.handleScroll();
  },
  methods: {
    ...mapMutations("equipment", {
      setEquipmentId: "setEquipmentId",
    }),
    ...mapActions("admin/websocket", {
      initWebSocket: "initWebSocket",
    }),
    ...mapMutations("admin/permission", {
      setMenuList: "setMenuList",
    }),
    ...mapMutations("admin", {
      setAdminSidebarShow: "setAdminSidebarShow",
    }),
    ...mapActions("admin/dashboard", {
      requestData: "requestData",
    }),
    nextPagehandle() {
      this.$router.push({ name: "MyProducts" });
    },
    handleScroll() {
      let scrollbarEl = this.$refs.scrollbarBox.wrap;
      const clientWidth = document.body.clientWidth;

      if (clientWidth >= 1200) {
        if (!this.isShowMap) {
          this.$store.dispatch("admin/dashboard/setShowMap", true).then(() => {
            this.isShowMap = true;
          });
        }
      } else {
        scrollbarEl.onscroll = () => {
          if (scrollbarEl.scrollTop > 1100) {
            if (!this.isShowMap) {
              this.$store.dispatch("admin/dashboard/setShowMap", true).then(() => {
                  this.isShowMap = true;
              });
            }
          }
        };
      }
    },
    initScrollbarHeight() {
      this.loading = true;
      const copyrightHeight = this.$refs.copyright.$el.offsetHeight;
      this.scrollbarHeight = `calc(100vh - ${copyrightHeight + 60}px)`;

      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//朦胧层
.hazy-layer {
  @media (max-width: 1199px) {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }
  /* 大屏幕（大桌面显示器，大于等于 1200px） */
  @media (min-width: 1200px) {
    display: none;
  }
}
//左侧边栏过度效果
.left-enter,
.left-leave-to {
  transform: translate3d(-100%, 0, 0);
}
.left-leave,
.left-enter-to {
  transform: translate3d(0, 0, 0);
}
.left-enter-active,
.left-leave-active {
  transition: all 0.2s;
}
.app-wrapper {
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: #f2f2f2;
  overflow: hidden;
}
.main-container {
  display: flex;
  justify-content: flex-start;
  height: calc(100vh - 60px);
}
.left-main {
  @media (max-width: 1199px) {
    position: absolute;
    z-index: 999;
  }

  /* 大屏幕（大桌面显示器，大于等于 1200px） */
  @media (min-width: 1200px) {
    display: block;
  }
}
.right-main {
  /* 小屏幕（平板，大于等于 768px） */
  @media (max-width: 1199px) {
    width: calc(100%);
  }

  /* 大屏幕（大桌面显示器，大于等于 1200px） */
  @media (min-width: 1200px) {
    width: calc(100% - 300px);
  }
}
.app-main-scrollbar {
  width: calc(100%);
  overflow-x: hidden;

  /* 大屏幕（大桌面显示器，大于等于 1200px） */
  @media (min-width: 1200px) {
    height: calc(100vh - 100px);
  }
}
.app-main {
  width: 100%;
  overflow-x: hidden;
  @media (max-width: 1199px) {
    padding: 0 15px;
  }

  /* 大屏幕（大桌面显示器，大于等于 1200px） */
  @media (min-width: 1200px) {
    padding: 0 32px;
  }
}
.el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
