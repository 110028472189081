import i18n from "@/lang"

export const searchDatetimeOptions = [
  {
    value: 0,
    label: i18n.t("All"),
  },
  {
    value: 1,
    label: i18n.t("Today"),
  },
  {
    value: 2,
    label: i18n.t("ThisMonth"),
  },
  {
    value: 3,
    label: i18n.t("LastMonth"),
  },
  {
    value: 4,
    label: i18n.t("LastYear"),
  },
]

export const httpCode = {
  SUCCESS: {
    code: 10000,
    msg: "成功"
  },
  PARAMS_ERROR: {
    code: 10001,
    msg: i18n.t("ParamError"), // 参数错误
  },
  TOKEN_ERROR: {
    code: 10002,
    msg: i18n.t("TokenError"), // token错误或失效
  },
  USER_VIP_EXPIRED: {
    code: 10003,
    msg: i18n.t("UserVIPExpired"), // 用户会员过期或不存在
  },
  NOTFOUND_HTTP: {
    code: 10004,
    msg: i18n.t("NotfoundHttp"), // 请求地址不存在
  },
  SERVER_ERROR: {
    code: 10005,
    msg: i18n.t("ServerError"), // 服务器错误
  },
  HEADERS_MISSING: {
    code: 10006,
    msg: i18n.t("HeadersMiss"), // headers参数缺失
  },
  LOGIN_EMAIL_ERROR: {
    code: 10007,
    msg: i18n.t("LoginEmailError"), // 邮箱错误
  },
  LOGIN_PASSWORD_ERROR: {
    code: 10008,
    msg: i18n.t("LoginPasswordError"), // 密码错误
  },
  LOGIN_EXPIRED: {
    code: 10009,
    msg: i18n.t("LoginExpired"), // 登录过期
  }
}

export const langList = [
  {
    title: "English",
    icon: require("@/assets/images/lang/uk.png"),
    file: 'en',
  },
  {
    // 德语
    title: "Deutsch",
    icon: require("@/assets/images/lang/Germany.png"),
    file: 'de',
  },
  {
    // 法语
    title: "Français",
    icon: require("@/assets/images/lang/France.png"),
    file: 'fr',
  },
  {
    // 意大利语
    title: "Italiano",
    icon: require("@/assets/images/lang/Italy.png"),
    file: 'lt',
  },
  {
    // 荷兰语
    title: "Nederlands",
    icon: require("@/assets/images/lang/Netherlands.png"),
    file: 'nl',
  },
  {
    // 西班牙语
    title: "Español",
    icon: require("@/assets/images/lang/Spain.png"),
    file: 'es',
  },
  {
    // 葡萄牙语
    title: "Português",
    icon: require("@/assets/images/lang/Portugal.png"),
    file: 'pt',
  },
  {
    title: "日本語",
    icon: require("@/assets/images/lang/Japan.png"),
    file: 'ja',
  },
  {
    title: "繁體中文",
    icon: require("@/assets/images/lang/China.png"),
    file: 'zh_TW',
  },
]

export const kidsGuards = {
  'android': 'soft_android',
  'whatsapp': 'soft_whatsapp',
  'mac': 'soft_mac',
  'windows': 'soft_windows',
  'ios': 'soft_ios',
  'icloud': 'soft_icound', // 为了前后端一致
}

export const httpUrl = {
  android_buy: 'https://www.iseeguard.com/pricing/'
}

export const fileType = {
  image: 'bmp,jpg,png,tif,tiff,gif,pcx,tga,exif,fpx,svg,psd,cdr,pcd,dxf,ufo,eps,ai,raw,wmf,jpeg,webp',
  video: 'mp4,m2v,mkv,rmvb,wmv,avi,flv,mov,m4v,mpeg,rm,ogg,mod,wma,3gp,asf,webm,mpg',
  audio: 'mp3,wav,aif,aiff,au,mp1,mp2,ra,ram,mid,rmi,wma,mmf,amr,aac,flac',
  txt: 'text,txt',
  excel: 'xls,xlsx,xlsm,xltx,xltm,xlsa,xlsk',
  pdf: 'pdf',
  ppt: 'ppt,pptx,pps,ppsx,ppa,ppam,pot,potx,thmx',
  word: 'doc,docx',
  zip: 'rar,zip,arj,gz,winzip,z',
}



