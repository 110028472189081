import aes from "@/utils/aes"
import i18n from '@/lang'

const SET_IS_DEMO = 'SET_IS_DEMO';
const IS_DEMO = aes.Encrypt('is_demo');

const SET_LANGUAGE = 'SET_LANGUAGE';
const LANGUAGE = aes.Encrypt('language');

const SET_KIDSGUARDSTYPE = 'SET_KIDSGUARDSTYPE';
const KIDSGUARDSTYPE = aes.Encrypt('kidsGuardsType');

const SET_KIDSGUARDSTITLE = 'SET_KIDSGUARDSTITLE';
const KIDSGUARDSTITLE = aes.Encrypt('kidsGuardsTitle');

const SET_KIDSGUARDSID = 'SET_KIDSGUARDSID';
const KIDSGUARDSID = aes.Encrypt('kidsGuardsId');

const SET_EQUIPMENTID = 'SET_EQUIPMENTID';
const EQUIPMENTID = aes.Encrypt('equipmentId');

const SET_ROUTERSTACK = 'SET_ROUTERSTACK';
const SET_ROUTERCACHE = 'SET_ROUTERCACHE';
const SET_SYNC_LOADING = 'SET_SYNC_LOADING';

function local(key, defaultValue) {
    return localStorage.getItem(key) ? aes.Decrypt(localStorage.getItem(key)) : defaultValue
}

const state = {
    isDemo: local(IS_DEMO, true), // 设置是否demo页面
    language: local(LANGUAGE, 'en'), // 多语言
    kidsGuardsType: local(KIDSGUARDSTYPE, ''), // 设置product页面点击进去的是哪一个产品
    kidsGuardsTitle: local(KIDSGUARDSTITLE, ''), // 设置product页面点击进去的是哪一个产品
    kidsGuardsId: local(KIDSGUARDSID, 0), // 产品id
    equipmentId: local(EQUIPMENTID, 0), // 设备id
    routerStack: [], // 统计路由栈
    routerCache: [], // keep-alive include
    sync_loading: false,
    // ↓ 跟consts.kidsGuards对应 0 android 1 whatsapp 2 mac 3 windows 4 ios 5 icloud
    fromSource: -1, // 默认Android 从哪里跳转到这个后台管理项目的来源 例如mac,whatsapp,ios....
}

const mutations = {
    [SET_IS_DEMO]: (state, data) => {
        state.isDemo = data
    },
    [SET_LANGUAGE]: (state, data) => {
        state.language = data
    },
    [SET_KIDSGUARDSTYPE]: (state, data) => {
        state.kidsGuardsType = data
    },
    [SET_KIDSGUARDSTITLE]: (state, data) => {
        state.kidsGuardsTitle = data
    },
    [SET_KIDSGUARDSID]: (state, data) => {
        state.kidsGuardsId = data
    },
    [SET_EQUIPMENTID]: (state, data) => {
        state.equipmentId = data
    },
    [SET_ROUTERSTACK]: (state, to) => {
        let list = state.routerStack.filter(v => v.name !== to.name)
        list.push(to)
        state.routerStack = list
    },
    [SET_ROUTERCACHE]: (state, to) => {
        if (state.routerCache.includes(to.name)) return
        if (to.meta.cache) {
            state.routerCache.push(to.name)
        }
    },
    [SET_SYNC_LOADING]: (state, data) => {
        state.sync_loading = data
    },
}

const actions = {
    set_is_demo({ commit }, data) {
        const datas = aes.Encrypt(data);
        localStorage.setItem(IS_DEMO, datas);
        commit(SET_IS_DEMO, data)
    },
    set_language({ commit }, data) {
        const datas = aes.Encrypt(data);
        localStorage.setItem(LANGUAGE, datas);
        i18n.locale = data;
        commit(SET_LANGUAGE, data);
    },
    set_kids_guards_type({ commit }, data) {
        const datas = aes.Encrypt(data);
        localStorage.setItem(KIDSGUARDSTYPE, datas);
        commit(SET_KIDSGUARDSTYPE, data);
    },
    set_kids_guards_title({ commit }, data) {
        const datas = aes.Encrypt(data);
        localStorage.setItem(KIDSGUARDSTITLE, datas);
        commit(SET_KIDSGUARDSTITLE, data);
    },
    set_kids_guards_id({ commit }, data) {
        const datas = aes.Encrypt(data);
        localStorage.setItem(KIDSGUARDSID, datas);
        commit(SET_KIDSGUARDSID, data);
    },
    set_equipmentId({ commit }, data) {
        const datas = aes.Encrypt(data);
        localStorage.setItem(EQUIPMENTID, datas);
        commit(SET_EQUIPMENTID, data);
    },
    set_routerStack({ commit }, to) {
        return new Promise(resolve => {
            commit(SET_ROUTERSTACK, to);
            resolve()
        })
    },
    set_routerCache({ commit }, to) {
        commit('SET_ROUTERCACHE', to)
    },
    set_sync_loading({ commit }, data) {
        commit('SET_SYNC_LOADING', data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}