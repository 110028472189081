import { synchronize,screenshot,takePhotos } from '@/api/android/command'
import { Notification } from 'element-ui'
import router from "@/router"

export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        /**
         * 发送同步指令
         * @param {*} context 
         * @param {*} command 
         */
        async requestSynchronize(context, command) {
            await synchronize(router.app._route.params.equipmentId, {
                type: command
            })
            Notification({
                title: "success",
                type: "success",
                message: "Command sent successfully",
                duration: 4500
            })
        },
        /**
         * 发送截屏指令
         * @param {*} context 
         * @param {*} command 
         */
        async requestScreenshot() {
            await screenshot(router.app._route.params.equipmentId)
            Notification({
                title: "success",
                type: "success",
                message: "Command sent successfully",
                duration: 4500
            })
        },
        /**
         * 发送拍照指令
         * @param {*} context 
         */
        async requestTakePhotos() {
            await takePhotos(router.app._route.params.equipmentId)
            Notification({
                title: "success",
                type: "success",
                message: "Command sent successfully",
                duration: 4500
            })
        }
    },
    getters: {},
}