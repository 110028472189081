/*
 * @Author: withering 
 * @Date: 2021-07-03 17:19:39 
 * @Last Modified by:   withering 
 * @Last Modified time: 2021-07-03 17:19:39 
 */
import request from "@/utils/request"
/**
 * 获取短信列表
 * @param {*} equipmentId 
 * @returns 
 */
export function getList(equipmentId, params) {
    // return request.get('/sms/'+equipmentId,params)
    return request({
        url: '/sms/' + equipmentId,
        method: 'get',
        params
    })
}

/**
 * 获取短信详情列表
 * @param {*} equipmentId 
 * @param {*} phone 
 * @returns 
 */
export function getDetailsList(equipmentId, params) {
    // return request.get('/sms/detailsList/' + equipmentId, params)
    return request({
        url: '/sms/detailsList/' + equipmentId,
        method: 'get',
        params
    })
}