import { getList } from "@/api/android/applications"
import router from "@/router"
import { getCurrentDate } from "@/utils/common"
/**
 * 应用程序
 */
export default {
    namespaced: true,
    state: {
        //应用程序列表
        applicationList: [],
        //分页参数
        pageParams: {
            page: 1,
            total: 0,
            search: ""
        },
        // 最后同步时间
        synchronisedTime: ''
    },
    mutations: {
        /**
       * 更新最后同步时间
       * @param {*} state
       * @param {synchronisedTime} payload
       */
        setSynchronisedTime(state) {
            state.synchronisedTime = getCurrentDate()
            // Vue.set(state, 'synchronisedTime', getCurrentDate());
        },
        /**
         * 写入应用程序列表
         * @param {*} state 
         * @param {*} data 
         */
        setApplicationList(state, data) {
            let applicationList = state.applicationList
            data.forEach(item => { applicationList.push(item) })
            state.applicationList = applicationList
            // Vue.set(state, 'applicationList', applicationList)
        },
        /**
         * 重置应用程序列表
         * @param {*} state 
         */
        resetApplicationList(state) {
            state.applicationList = []
            // Vue.set(state, 'applicationList', [])
        },
        /**
         * 重置分页参数
         * @param {*} state 
         */
        resetPageParams(state) {
            state.pageParams = {
                page: 1,
                total: 0,
                search: ""
            }
            // Vue.set(state, 'pageParams', {
            //     page: 1,
            //     total: 0,
            //     search: ""
            // })
        },
        setPageParams(state, data) {
            state.pageParams = {
                page: data.current_page,
                total: data.total,
                type: state.pageParams.type,
                search: ""
            }
            // Vue.set(state, 'pageParams', {
            //     page: data.current_page,
            //     total: data.total,
            //     type: state.pageParams.type,
            //     search: ""
            // })
        },
        setPageParamsSearch(state, search) {
            state.pageParams.search = search
            // Vue.set(state.pageParams, 'search', search)
        },
        setPageParamsPage(state, page) {
            state.pageParams.page = page
            // Vue.set(state.pageParams, 'page', page)
        }
    },
    actions: {
        /**
         * 请求获取应用程序列表
         * @param {*} context 
         */
        async requestApplicationList(context,data = []) {
            let params = context.getters.getRequestApplicationListParams
            data.forEach((item) => { params[item.key] = item.value });
            const result = await getList(router.app._route.params.equipmentId, params)
            context.commit('setApplicationList', result.data.data)
            context.commit('setPageParams', result.data)
        }
    },
    getters: {
        /**
         * 获取同步时间
         * @param {*} state 
         * @returns 
         */
        getSynchronisedTime: state => {
            return state.synchronisedTime
        },
        /**
         * 获取请求应用程序列表参数
         * @param {*} state 
         * @returns 
         */
        getRequestApplicationListParams: state => {
            return {
                page: state.pageParams.page,
                type: state.pageParams.type,
                search: state.pageParams.search
            }
        },
        /**
         * 获取应用程序列表
         * @param {*} state 
         * @returns 
         */
        getApplicationList: state => {
            return state.applicationList
        },
        /**
         * 获取分页参数
         * @param {*} state 
         * @returns 
         */
        getPageParams: state => {
            return state.pageParams
        }
    }
}