/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    headertitle: '', title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    cache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

  const mac_admin = {
    path: '/mac',
    component: () => import('@/layout/app_admin/index'),
    name: 'Mac-Admin',
    redirect: '/mac/dashboard',
    meta: { icon: 'menu-mac' },
    alwaysShow: true,
    children: [
        {
            path: 'dashboard',
            component: () => import('@/views/mac/dashboard/index'),
            name: 'Mac-Dashboard',
            meta: { headertitle: 'Mac Dashboard', title: 'Dashboard', icon: 'menu-dashboard', cache: true }
        },
        {
            path: 'usb',
            component: () => import('@/views/mac/usb/index'),
            name: 'Mac-USB',
            meta: { headertitle: 'Mac UsbConnection', title: 'MonUsbConnection', icon: 'menu-usb', cache: true } // 有使用到preview组件的页面不能做缓存
        },
        {
            path: 'apprecord',
            component: () => import('@/views/mac/apprecord/index'),
            name: 'Mac-AppRecord',
            meta: { headertitle: 'Mac AppActivity', title: 'AppActivity', icon: 'menu-userecord', cache: false } // 有使用到preview组件的页面不能做缓存
        },
        {
            path: 'keyboard',
            component: () => import('@/views/mac/keyboard/index'),
            name: 'Mac-Keyboard',
            meta: { headertitle: 'Mac Keylogger', title: 'Keylogger', icon: 'menu-keyboard', cache: true } // 有使用到preview组件的页面不能做缓存
        },
        {
            path: 'clipboard',
            component: () => import('@/views/mac/clipboard/index'),
            name: 'Mac-Clipboard',
            meta: { headertitle: 'Mac Clipboard', title: 'Clipboard', icon: 'menu-clipboard', cache: true } // 有使用到preview组件的页面不能做缓存
        },
        {
            path: 'screenshots',
            component: () => import('@/views/mac/screenshots/index'),
            name: 'Mac-Screenshots',
            meta: { headertitle: 'Mac Screenshots', title: 'Screenshots', icon: 'menu-screenshots', cache: false }
        },
        {
            path: 'browser',
            component: () => import('@/views/mac/browser/index'),
            name: 'Mac-Browser',
            meta: { headertitle: 'Mac BrowserHistory', title: 'BrowserHistory', icon: 'menu-safari', cache: true } // 有使用到preview组件的页面不能做缓存
        },
        {
            path: 'sensitive',
            component: () => import('@/views/mac/sensitive/index'),
            name: 'Mac-Sensitive',
            meta: { headertitle: 'Mac SensitiveWord', title: 'SensitiveWord', icon: 'menu-sensitive', cache: true } // 有使用到preview组件的页面不能做缓存
        },
    ]
}

export default mac_admin;