<template>
  <el-header class="header">
    <div class="header-container">
      <div class="logo-box">
        <img
          v-if="mode < 4"
          src="~@/assets/images/header/logo.png"
          alt=""
          @click="toIndex"
        >
        <div v-else class="back-product" @click="back">
          &lt;&lt;&nbsp;{{ $t("myProduct") }}
        </div>
      </div>
      <!--右侧 -->
      <div class="header-navbar">
        <ul v-show="!showSearch">
          <!-- <li class="flex flex-align-items-center" @click="btndata()"> -->
          <!-- <li v-if="mode < 4">
            <Product />
          </li> -->
          <!-- <li v-if="mode == 1">
            <router-link tag="div" :to="{ path: '/demo/my-products' }">
              Demo
            </router-link>
          </li> -->
          <!-- <li>
            <router-link tag="div" :to="{ path: '/store' }">{{ $t('Store') }}</router-link>
          </li> -->
          <!-- <li>
            <router-link tag="div" :to="{}">Support</router-link>
          </li> -->
          <li v-if="!loginStatus && mode < 4">
            <router-link tag="div" :to="{ path: '/login' }">
              {{ $t("Login") }}
            </router-link>
          </li>
          <li v-else-if="loginStatus && mode < 4">
            <router-link tag="div" :to="{ path: '/my-products' }">
              {{ $t("myProduct") }}
            </router-link>
          </li>
        </ul>
        <!-- 用户---start -->
        <Avatar v-if="loginStatus && mode < 4" />
        <!-- 用户---end -->
        <!-- <el-button
          v-if="!loginStatus && (mode == 1 || mode == 2)"
          v-show="!showSearch"
          class="sign-btn"
          type="primary"
          round
          @click="toRegister"
        >
          {{ $t("SignUp") }}
        </el-button> -->
        <!-- 搜索 -->
        <!-- <Search v-if="mode == 1" v-model="showSearch" /> -->
        <!-- <el-button
          v-if="!loginStatus && (mode == 3 || mode == 4)"
          class="monitor-btn"
          type="primary"
          round
          @click="toRegister"
        >
          {{ $t("MonitorNow") }}
        </el-button> -->
        <!-- 国际化 -->
        <Lang v-if="mode > 1" />
      </div>
    </div>
    <div class="header-container-md">
      <div class="menu-box" @click="asideShow">
        <i class="el-icon-menu menu-icon" />
      </div>
      <div class="logo-box">
        <img
          v-if="mode < 4"
          src="@/assets/images/header/logo.png"
          alt=""
          @click="toIndex"
        >
        <div v-else class="back-product" @click="back">
          &lt;&lt;&nbsp;{{ $t("myProduct") }}
        </div>
      </div>
      <!-- 用户---start -->
      <Avatar v-if="loginStatus && mode < 4" />
      <!-- 用户---end -->
      <!-- <el-button
        v-if="!loginStatus && (mode == 1 || mode == 2)"
        v-show="!showSearch"
        class="sign-btn"
        type="primary"
        round
        @click="toRegister"
      >
        {{ $t("SignUp") }}
      </el-button> -->
      <!-- <el-button
        v-if="!loginStatus && (mode == 3 || mode == 4)"
        class="monitor-btn"
        type="primary"
        round
        @click="toRegister"
      >
        {{ $t("MonitorNow") }}
      </el-button> -->
      <aside class="aside-box">
        <div v-show="showAside" class="aside-box-bg" @click="asideShow" />
        <div class="aside-box-con" :class="showAside ? 'aside-show' : ''">
          <i class="el-icon-close close-icon" @click="asideShow" />
          <router-link
            v-if="!loginStatus && (mode == 3 || mode == 4)"
            tag="div"
            class="aside-box-con-item"
            :to="{ path: '/login' }"
            @click.native="asideShow"
          >
            {{ $t("Login") }}
          </router-link>
          <!-- <router-link
            v-if="!loginStatus && (mode == 3 || mode == 4)"
            tag="div"
            class="aside-box-con-item"
            :to="{ path: '/login' }"
            @click.native="asideShow"
          >
            {{ $t("MonitorNow") }}
          </router-link> -->
          <!-- <Search v-model="showSearch" /> -->
          <Lang v-if="mode > 1" class="aside-box-con-item aside-box-con-lang" />
        </div>
      </aside>
    </div>
  </el-header>
</template>

<script>
import Avatar from "@/components/header/Avatar"
import Lang from "@/components/header/Lang"
// import Search from "./Search";
// import Product from "./Product"
import { mapGetters } from "vuex";
export default {
  name: "Header",
  components: {
    Lang,
    Avatar
    // Search,
    // Product,
  },
  data() {
    return {
      showAside: false,
      showmode: false,
      showSearch: false, // 是否显示搜索
      mode: 1, // 显示的模式
      modeOne: [
        "/",
        "/eula",
        "/privacy-policy",
        "/cookies-policy",
        "/refund-policy",
      ],
      modeTwo: [
        "/login",
        "/register",
        "/forgot-password",
        "/change-password",
        "/email-sent",
        "/successfully",
      ],
      modeFour: ["/settings", "/demo/settings", "/guide/android", "/guide/whatsapp"],
    };
  },
  computed: {
    ...mapGetters(["loginStatus"]),
  },
  watch: {
    $route: {
      handler(to) {
        if (this.modeOne.includes(to.path)) {
          this.mode = 1;
        } else if (this.modeTwo.includes(to.path)) {
          this.mode = 2;
        } else if (this.modeFour.includes(to.path)) {
          this.mode = 4;
        } else {
          this.mode = 3;
        }
      },
      deep: false,
      immediate: true,
    },
  },
  methods: {
    asideShow() {
      this.showAside = !this.showAside;
    },
    btndata() {
      if (this.showmode) {
        this.showmode = false;
      } else {
        this.showmode = true;
      }
    },
    toIndex() {
      window.location.href = 'https://www.iseeguard.com/'
      // this.$router.push("/");
    },
    toRegister() {
      this.showAside = false
      this.$router.push("/register");
    },
    toLogin() {
      this.showAside = false
      this.$router.push("/login");
    },
    back() {
      this.$router.back();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

@media only screen and (max-width: $md) {
  .header-container {
    display: none !important;
  }
  .header-container-md {
    display: flex !important;
  }
}
@media only screen and (max-width: $sm) {
  .monitor-btn {
    display: none !important;
  }
  .aside-box-con {
    width: 70% !important;
  }
}
.sign-btn{
  width: 75px;
  height: 34px;
  padding: 0 !important;
}
.header-container-md {
  display: none;
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .logo-box {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    img{
      width: 110px;
    }
  }
  .menu-icon {
    font-size: 24px;
  }
}
.aside-box {
  position: fixed;
  top: 0;
  left: 0;
  .aside-box-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba($color: #000000, $alpha: 0.7);
    z-index: 1001;
  }
  .aside-box-con {
    position: fixed;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: #fff;
    transform: translate(-100%);
    z-index: 1002;
    transition: all ease 0.3s;

    .close-icon {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      font-size: 24px;
      padding-left: 20px;
      border-bottom: 2px solid $borderColor;
    }

    .aside-box-con-item {
      display: flex;
      align-items: center;
      height: 50px;
      padding-left: 20px;
      @include borderBottom;
    }
    .aside-box-con-lang{
      margin-left: 0;
    }
  }
}
.aside-show {
  transform: translate(0) !important;
}
.header {
  position: fixed;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: center;
  z-index: 1000;
  background: #fff;
  .header-container {
    width: 1200px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
// logo
.logo-box {
  display: flex;
  align-items: center;
  height: 100%;
  line-height: 1;
  cursor: pointer;
  img {
    width: 150px;
    border: 0;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }
  .back-product {
    line-height: 26px;
    color: #333333;
    font-weight: 700;
  }
}
// 右侧navbar
.header-navbar {
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 0;
    li {
      padding: 0 20px;
      color: #333333;
      font-size: 16px;
      cursor: pointer;
      a {
        color: #333333;
      }
      a:hover {
        background: none;
        text-decoration: none;
        color: #347aeb;
      }
    }
  }
  //   搜索
  .search-btn {
    overflow: hidden;
    display: inline-block;
    align-items: center;
    justify-content: center;
    padding: 6px 20px 0 30px;
    .search-btn-click {
      color: #787878;
      font-size: 24px;
    }
  }

  .sign-btn {
    height: 35px;
    padding: 0 20px;
    color: #fff;
    margin-left: 20px;
    margin-right: 20px;
  }
}
.user_bx {
  margin-left: 20px;
  img {
    width: 30px;
    height: 30px;
  }
}

.monitor-btn {
  height: 35px;
  padding: 0 20px !important;
  color: #fff;
  margin-left: 20px;
  margin-right: 20px;
}
.active {
  transform: rotate(180deg);
}
.transition-arrow {
  transition: all 0.5s;
  margin-left: 6px;
}
</style>