import { exportData } from '@/api/android/exportData'
import router from '@/router'
export default {
    namespaced: true,
    state: {},
    mutations: {},
    actions: {
        async requestExports(context,{module,isDelete,limit=1000}) {
            const result = await exportData(router.app._route.params.equipmentId,{
                module:module,
                isDelete:isDelete,
                limit:limit
            })
            return result;
        }
    },
    getters: {},
}