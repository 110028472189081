
const homeList = [
    // '/',
    '/login',
    '/register',
    '/email',
    '/device',
    '/eula',
    '/privacy-policy',
    '/cookies-policy',
    '/demo/my-products',
    '/refund-policy',
    '/retrieve',
    '/forgot-password',
    '/email-sent',
    '/settings',
    '/store',
    '/notfound',
];
const price = [
    '/store/android-pricing',
    '/store/whatsapp-pricing',
    '/store/mac-pricing',
    '/store/ios-pricing',
    '/store/window',
    '/store/ios',
]
const demo_android = [
    '/demo-android/dashboard',
    '/demo-android/phone-file',
    '/demo-android/phone-file/cal-logs',
    '/demo-android/phone-file/sms',
    '/demo-android/phone-file/contacts',
    '/demo-android/phone-file/browser-history',
    '/demo-android/phone-file/photo-list',
    '/demo-android/phone-file/photos',
    '/demo-android/phone-file/videos',
    '/demo-android/phone-file/applications',
    '/demo-android/phone-file/keyloggers',
    '/demo-android/phone-file/calendars',
    '/demo-android/location-tracking',
    '/demo-android/location-tracking/location',
    '/demo-android/location-tracking/wifi-loggers',
    '/demo-android/social-application',
    '/demo-android/social-application/whatsapp',
    '/demo-android/social-application/facebook',
    '/demo-android/social-application/messenger',
    '/demo-android/social-application/snapchat',
    '/demo-android/social-application/tinder',
    '/demo-android/social-application/telegram',
    '/demo-android/social-application/kik',
    '/demo-android/social-application/viber',
    '/demo-android/social-application/line',
    '/demo-android/social-application/wechat',
    '/demo-android/social-application/qq',
    '/demo-android/remote-control',
    '/demo-android/remote-control/call-recording',
    '/demo-android/remote-control/capture-screenshots',
    '/demo-android/remote-control/take-photos',
];
const demo_whatsApp = [
    '/demo-whatsapp/dashboard',
    '/demo-whatsapp/chats',
    '/demo-whatsapp/calls',
    '/demo-whatsapp/call-recording',
    '/demo-whatsapp/status',
    '/demo-whatsapp/photos',
    '/demo-whatsapp/videos',
    '/demo-whatsapp/voices',
    '/demo-whatsapp/documents',
    '/demo-whatsapp/activities',
    '/demo-whatsapp/screenshots',
    // '/demo-whatsapp/export',
];
const demo_mac = [
    '/demo-mac/dashboard',
    '/demo-mac/usb',
    '/demo-mac/apprecord',
    '/demo-mac/keyboard',
    '/demo-mac/clipboard',
    '/demo-mac/screenshots',
    '/demo-mac/browser',
    '/demo-mac/sensitive',
];

export default [...homeList, ...demo_android, ...demo_whatsApp, ...demo_mac, ...price]