import router from "@/router"
/**
 * 获取后台路由列表
 * @param {*} routes 
 * @returns 
 */
function getAdminRoutes(routeName="Admin") {
  //获取admin路由的所有子菜单
  let adminRoutes = []
  const routes = router.options.routes
  routes.forEach((item) => {
    if(item.name === routeName && item.children){
      adminRoutes = item.children
    }
  })
  return adminRoutes
}
/**
 * 获取菜单列表
 * @param {*} routes 
 * @returns 
 */
function getMenu(routes) {
  const data = [];
  routes.forEach((item) => {
    if(!item.hidden){
      if (item.children) {
        item.children = getMenu(item.children)
      }
      data.push(item)
    }
  })
  return data
}

export default {
  namespaced: true,
  state: {
    menuList: []
  },
  getters: {
    getMenuList:state =>{
      return state.menuList
    }
  },
  mutations: {
    /**
     * 写入菜单
     * @param {*} state 
     * @param {*} routes 
     */
    setMenuList: (state, routeName = "Admin") => {
      const adminRoutes = getAdminRoutes(routeName);
      state.menuList = getMenu(adminRoutes)
    },
    /**
     * 更新指定菜单状态
     * @param {*} state 
     * @param {*} payload 
     */
    updateAssignMenuShowStatus: (state, payload) => {
      const {key,showStatus} = payload
      state.menuList[key].meta.show = showStatus
    }
  },
  actions: {}
}