/*
 * @Author: withering 
 * @Date: 2021-07-03 17:27:12 
 * @Last Modified by:   withering 
 * @Last Modified time: 2021-07-03 17:27:12 
 */
import request from "@/utils/request"
/**
 * 获取视频列表
 * @param {*} params 
 * @returns 
 */
export function getList(equipmenId, params) {
    // return request.get('/videoList/'+equipmenId, );
    return request({
        url: '/videoList/' + equipmenId,
        method: 'get',
        params
    })

}
/**
 * 获取指定视频id的文件路径
 * @param {*} equipmenId 
 * @param {*} params 
 * @returns 
 */
export function getAssignIdVideoUrl(equipmenId, params) {
    // return request.get('/getAssignIdVideoUrl/' + equipmenId, params);
    return request({
        url: '/getAssignIdVideoUrl/' + equipmenId,
        method: 'get',
        params
    })
}