export default {
    CreateAccount: "Creëer een Account",
    Email: "E-mail",
    Password: "Paswoord",
    ConfirmPas: "Bevestig Paswoord",
    SignUp: "Registreer",
    AleadyAccount: "Heb je al een account? ",
    Login: "Login",
    Required: "Dit veld is verplicht.",
    NotEmail: "Het e-mailadres is niet geldig.",
    TwoPass: "Je hebt twee verschillende paswoorden ingegeven.",
    SixOne: "Het paswoord moet 6-16 karakters lang zijn.",
    Alphabets: "Het paswoord moet cijfers en letters bevatten.",
    Space: "Het paswoord kan geen spatie bevatten.",
    Symbols: "Het paswoord kan karakters of enkel cijfers, letters of symbolen bevatten.",
    SignLoad: "Registreren…",
    AgainTry: "Registreren mislukt. Probeer aub opnieuw.",
    EmailExists: "Het e-mailadres bestaat al.",
    SupportToMonitor: "Geldt voor:",
    SignIn: "Login",
    IncorrectOr: "Onjuiste gebruikersnaam of paswoord.",
    Forpwd: "Paswoord vergeten?",
    Signtry: "Aanmelden mislukt. Probeer aub opnieuw.",
    Restpwd: "Reset je Paswoord:",
    SendLink: "Stuur Reset Link",
    ReturnTo: "Terug naar",
    DontExit: "Het e-mailadres bestaat niet.",
    CheckEmail: "Check Aub je E-mail",
    JustFold: "We hebben net een paswoordresetlink naar het e-mailadres dat je opgeschreven hebt gestuurd. Als je het niet kan vinden in de inbox, check je spam of vuilbak.",
    TwoHour: 'Als je de e-mail niet in de volgende 24 uur ontvangt, contacteer aub ons ondersteuningsteam op <a href="mailto:support@iSeeGuard.com">support@iSeeGuard.com</a>',
    UnknowError: "Onbekende Fout",
    TryOp: "Oeps. Er ging net iets fout. Probeer aub opnieuw.",
    Retry: "Probeer opnieuw",
    CreatNewPwd: "Creëer een Nieuw Paswoord",
    NewPwd: "Nieuw Paswoord",
    Confirm: "Bevestig",
    OldPwd: "Je hebt het oude paswoord ingegeven. Verander het aub naar het nieuwe.",
    NewPwdSucess: "Nieuw paswoord succesvol aangemaakt!",
    ThePage: "De pagina zal naar de loginpagina gaan in 5 seconden.",
    ProceedNow: "Ga Nu Verder",
    UnknowTry: "Onbekende fout. Probeer opnieuw.",
    LinkExpired: "De link is verlopen. Probeer aub opnieuw.",
    ProfileSett: "Profielinstellingen",
    Download: "Download iSeeGuard Pro App op het Doeltoestel",
    Back: "Terug",
    Next: "Volgende",
    Dashboard: "Dashboard",
    PhoneLogs: "Telefoonlogs",
    CallLogs: "Belgeschiedenis",
    Messages: "Berichten",
    Contacts: "Contacten",
    Locations: "Locaties",
    Geofence: "Geofence",
    BrowserHistory: "Browsergeschiedenis",
    Photos: "Foto's",
    VideoPreview: "Video Bekijken",
    CaptureScreenshots: "Maak Screenshots",
    AppActivity: "App Activiteiten",
    WiFiLogger: "Wifi Logger",
    Keylogger: "Keylogger",
    Calendar: "Agenda",
    SocialApps: "Sociale Apps",
    DataExport: "Data Export",
    Settings: "Instellingen",
    Sync: "Sync",
    Updated: "Geüpdatet: ",
    DataLoad: "Het kan enige tijd duren om de telefoongegevens te synchroniseren. Heb alstublieft geduld....",
    DatanNotWhy: "Waarom is iSeeGuard Pro niet in staat om nieuwe gegevens te synchroniseren?",
    DataUpdata: "Begrijp om te beginnen dat de iSeeGuard Pro-app die op het doelapparaat is geïnstalleerd, niet direct nieuwe gegevens synchroniseert, maar elke 15 minuten gegevens verzamelt. Wanneer u zich aanmeldt bij de iSeeGuard Pro-account of op het pictogram Sync klikt, worden de gegevens bijgewerkt. De tijd die nodig is om de gegevens te synchroniseren is afhankelijk van de grootte van de gegevens en de snelheid van de internetverbinding van het doelapparaat.",
    DataNum: "Sommige gegevens worden alleen geüpload via een verbinding met een Wi-Fi-netwerk: foto's, videopreview, gespreksopnames, screenshots van de sociale app, Wi-Fi-logger en activiteiten van de app. Andere gegevens worden geüpload via een 3G-, 4G- of Wi-Fi-netwerk: locaties, apparaatinformatie, keylogger, handmatig vastgelegde screenshots, gesprekslogs, berichten, contactpersonen, agenda's, geofence, browsergeschiedenis en chatgeschiedenis van de sociale app.",
    DataG: "Het volgende kan de reden zijn als u geen bijgewerkte gegevens kunt bekijken.",
    Dataup: " Het doelapparaat is uitgeschakeld.",
    DataOff: "Het doelapparaat is niet verbonden met het netwerk of het netwerk is onstabiel.",
    Datastrong: "De Toegankelijkheidstoestemming voor iSeeGuard Pro is uitgeschakeld op het doelapparaat. Zo ja, ga dan naar Instellingen > Toegankelijkheid > Zoek System Update Service en schakel deze in.",
    Datauninstall: "De opslagtoestemming voor iSeeGuard Pro is op het doelapparaat uitgeschakeld. Zo ja, ga dan naar Instellingen > Apps > System Update Service > Machtigingen > opslagtoegang voor de app toestaan. ",
    Dataassint: "De iSeeGuard Pro-assistentie-app is niet actief of is op het doelapparaat verwijderd. Zo ja, start de doeltelefoon opnieuw op of installeer de app opnieuw.",
    ActInfo: "Account Info",
    ActId: "Account ID",
    NotEml: "Notificatie E-mail",
    DvcInfo: "Toestel Info",
    DvcName: "Toestel Naam",
    DvcStatus: "Toestelstatus",
    Online: "Online",
    Offline: "Offline",
    Plan: "Plan",
    ExpDate: "Vervaldatum",
    AutoRel: "Automatisch hernieuwen",
    Disable: "Ingeschakeld",
    Enable: "Uitgeschakeld",
    DvcMod: "Toestelmodel",
    Dvcson: "Toestel OS Versie",
    GPS: "GPS",
    On: "Aan",
    Off: "Uit",
    BatLev: "Batterijniveau",
    WiFi: "Wifi",
    TopCal: "Top 8 Telefoontjes",
    TopMes: "Top 8 Berichten",
    LastLoca: "Laatst Bekende Locatie",
    AppVersion: "App versie",
    UpdateBtn: "Bijwerken",
    SureUpdate: "Weet je zeker dat je de app nu wilt updaten?",
    SureUpdateTips: 'Nadat je op de knop "Ja" hebt geklikt, verschijnt een bericht op het doelapparaat om de app-update goed te keuren. Zorg ervoor dat u het doelapparaat heeft, anders laat het zien dat het apparaat wordt gecontroleerd.',
    UpdateSuccess: "De update wordt automatisch gedownload. Controleer het proces en keur de installatie op het doelapparaat goed.",
    UseLastetVersion: "U gebruikt de nieuwste versie van deze applicatie. Controleer op een later tijdstip nogmaals voor updates.",
    InUpdating: "De app wordt bijgewerkt en u kunt het proces op het doelapparaat controleren. Wacht een paar minuten.",
    UpdateFailed: "De update is mislukt omdat het doelapparaat niet is verbonden met het netwerk of de app tijdelijk niet reageert.",
    Name: "Naam",
    PhoneNumber: "Telefoonnummer",
    Type: "Type",
    Incoming: "Inkomend",
    Cancelled: "Geannuleerd",
    Outgoing: "Uitgaand",
    Duration: "Duurtijd",
    Date: "Datum",
    All: "Alle",
    Today: "Vandaag",
    ThisMonth: "Deze Maand",
    LastMonth: "Vorige Maand",
    LastYear: "Vorig Jaar",
    Search: "Zoek",
    To: "Naar",
    Phone: "Telefoon",
    Work: "Werk",
    Home: "Thuis",
    Others: "Anderen",
    Mail: "Mail",
    Birthday: "Verjaardagen",
    Anniversary: "Jubileum",
    Address: "Adres",
    LonLat: "Lengte- en Breedtegraad",
    LocTime: "Locatie Tijd",
    MapView: "Kaartoverzicht",
    FailSync: "Kan niet synchroniseren. Probeer aub opnieuw.",
    GenerateDes: "Genereer een geofence, zodat je bericht krijgt als je kind een bepaalde zone verlaat.",
    GenerateEff: "Genereer Gemakkelijk en Efficiënt Geofences",
    GenerateCir: "Genereer een geofence rond een bepaalde zone door een specifieke locatie te omcirkelen.",
    StayAlt: "Blijf Geïnformeerd met Geofence Alarmen",
    WhGeo: "Als je kind de geofence oversteekt, zal je op de hoogte gebracht worden via e-mail",
    Start: "Start",
    SureDelete: "Ben je zeker dat je deze geofence wil verwijderen?",
    Yes: "Ja",
    No: "Nee",
    MaxT: "Maximum 10 items.",
    Ok: "OK",
    NameGeofc: "Noem het Geofence",
    Setlon: "Stel Begrensde Locatie in",
    SearchAd: "Zoek Adres",
    SetRadius: "Stel Radius in",
    Meters: "Radius van de geofence in meters (1000m max.)",
    GuardTime: "Stel Bewakingstijd in",
    SetNow: "Stel nu in >",
    SetAlt: "Stel Alarmen in via Notificatie E-mail",
    Save: "Opslaan",
    Cancel: "Annuleer",
    StartTime: "Starttijd",
    EndTime: "Eindtijd",
    Repeat: "Herhaal",
    EverySun: "Elke Zon",
    EveryMon: "Elke Maa",
    EveryTue: "Elke Din",
    EveryWed: "Elke Woe",
    EveryThu: "Elke Don",
    EveryFri: "Elke Vri",
    EverySat: "Elke Zat",
    PlsName: "Geef de geofence aub een naam.",
    Plsloca: "Stel aub een locatie in.",
    PlsTime: "Stel aub de bewakingstijd in.",
    ThreeName: "Er is al een geofence met dezelfde naam.",
    LastVtne: "Laatste Bezoektijd",
    URL: "URL",
    Title: "Titel",
    Frequency: "Frequentie",
    Visits: "Bezoeken",
    HowFeat: "Hoe gebruik je deze optie?",
    MakeAct: "Zorg ervoor dat het doeltoestel actief is.",
    ClickBt: 'Klik op de "Neem Screenshots" knop.',
    VuShot: "Bekijk de screenshots.",
    NotLock: "Nota: Deze optie werd uitgeschakeld als het scherm van de doeltelefoon geblokkeerd is.",
    ScReenPow: "Het vastleggen van het scherm is mislukt omdat het scherm van de doeltelefoon is vergrendeld of omdat de app tijdelijk niet reageert. Probeer het later opnieuw.",
    TakScr: "Neem Screenshots",
    Version: "Versie",
    Size: "Grootte",
    InstDate: "Installatiedatum",
    TodayFreq: "Frequentie van Vandaag",
    TodayScreenTime: "Schermtijd van Vandaag",
    ClickKey: 'Klik op de "Start" knop en wacht voor de synchroon keyloggers.',
    ViewScr: "Bekijk de screenshots.",
    KidTxy: "iSeeGuard Pro kan je helpen om de toetsslagen op het gemonitorde Android toestel te detecteren. Elke toetsslag komt met een appicoon dat aangeeft waar ze vandaan komen.",
    NotDat: "Geen Resultaten",
    Event: "Evenementen",
    EventLocation: "Evenementlocatie",
    Note: "Notities",
    AppNotif: "Appnotificaties",
    Modules: "Modules",
    Time: "Tijd",
    Format: "Formaat",
    Export: "Export",
    DeleteAndExport: "Verwijder de geëxporteerde gegevens",
    CancelSub: "Annuleer Abonnement",
    UnbindDevice: "Ontbind Toestel",
    ContactSupport: "Contact opnemen met de klantenservice",
    SureRenewal: "Ben je zeker dat je de auto-hernieuwing wil annuleren?",
    SureUnbind: "Ben je zeker dat je dit toestel wil ontbinden?",
    PersonalPrivacy: "Om je persoonlijke privacy te beschermen, zal iSeeGuard Pro stoppen met alle data te monitoren en het verwijderen.",
    UnboundDvc: "Proficiat! Je hebt het toestel succesvol verwijderd.",
    Profile: "Profiel",
    ContactUs: "Contact met ons",
    Logout: "Log uit",
    Device: "Toestellen",
    Month: "Maand",
    BuyNow: "Koop Nu",
    AddNew: "Toevoegen",
    Unactivated: "Niet-geactiveerde",
    VersionUpdate: "Er zijn nieuwe updates beschikbaar. Verwijder indien mogelijk de vorige versie van de doeltelefoon, start de telefoon opnieuw en download de nieuwste versie.",
    LearnMore: "Meer informatie",
    HowToUpdate: "Hoe de app bij te werken op het doelapparaat?",
    UpdateTips1: "Ga naar Mijn producten> Instellingen> Apparaat loskoppelen.",
    UpdateTips2: "Ga het doelapparaat vasthouden, ga naar Instellingen > Apps > zoek System Update Service en verwijder deze eerst.",
    UpdateTips3: "Open vervolgens www.iseeguard.com met de browser en download de app. ",
    UpdateTips4: "Volg ten slotte de instructies op het scherm om de configuratie te voltooien.",
    WhatsappUpdateTips1: "Ga het doelapparaat vasthouden, ga naar Instellingen > Apps > zoek WhatsApp Service en verwijder deze eerst. ",
    WhatsappUpdateTips2: "Open vervolgens www.installapk.net met de browser en download de app. ",
    WhatsappUpdateTips3: "Volg ten slotte de instructies op het scherm om de configuratie te voltooien.",
    ThankPurchase: "Bedankt voor jouw aankoop!",
    ThankTips: "Dank u. Uw bestelling is ontvangen. U ontvangt een bevestigingsmail met de details van uw bestelling.",
    Product: "Product:",
    BillingEmail: "Uw e-mailadres voor facturering:",
    ClickTips: "Klik op Volgende en start met monitoren.",
    PayFailed: "De betaling is mislukt",
    PayFailedTips: "Probeer het opnieuw met een andere betalingsmethode of neem contact op met uw betalingsprovider.",
    TryAgain: "Probeer nog eens",
    FailedReason: "Waarom mislukt de betaling?",
    FailedReason1: "1. Verlopen kaart",
    FailedReason2: "2. Onvoldoende middelen",
    FailedReason3: "3. De ingevoerde informatie is onjuist",
    FailedReason4: "4. Andere redenen",
    OrderReview: "Uw bestelling wordt beoordeeld",
    OrderReviewTips: "Uw bestelling ",
    OrderReviewTips1: " is ontvangen en wordt geverifieerd door ons team.",
    OrderReviewTips2: "De bestelling wordt naar verwachting binnen de komende 2 uur verwerkt - de maximale verwerkingstijd is 24 uur. U ontvangt een e-mailmelding als de betaling is gelukt.",
    OrderReviewTips3: "Om te voorkomen dat u meerdere betalingsverzoeken met uw creditcard doet, dient u de bestelling niet herhaaldelijk in te dienen.",
    ChangeNotEmail: "Verander Notificatie E-mail",
    NewEmail: "Nieuw E-mail",
    NotHu: "Nota: Deze verandering is enkel van toepassing op jouw notificatie e-mail. Het login e-mailadres blijft onveranderd.",
    SendMailTse: "We hebben je een bevestigingse-mail gestuurd naar je nieuwe notificatie e-mailadres. Als je deze e-mail niet ontvangen hebt, klik aub op de knop hieronder of contacteer ons op",
    ChangeNotsuces: "Je hebt het notificatie e-mailadres succesvol veranderd!",
    ChangeTwo: "Je hebt een oud e-mailadres opgegeven. Probeer aub een ander.",
    ChangePassword: "Verander Paswoord",
    OldPassword: "Oud Paswoord",
    PwdSuces: "Je hebt het paswoord succesvol veranderd.",
    WrongOldPwd: "Fout oud paswoord",
    TheNewCode: "Het nieuwe en het oude paswoord zijn hetzelfde. Probeer aub opnieuw.",
    NoMore: "niet meer",
    ReNew: "Vernieuwen",
    CheckExported: "Er is geen data die geëxporteerd moet worden.  Controleer aub en probeer opnieuw.",
    FailedCheck: "Kan niet exporteren. Door browser compatibiliteitsproblemen kan de data voorlopig niet gedownload worden via Firefox of Edge. Gebruik aub Chrome, Safari of Opera en probeer opnieuw.",
    ScShot: "Screenshot",
    AddGeofence: "Voeg een geofence toe",
    WifILocation: "Wifi Locatie",
    footCot: "Copyright © 2021 iSeeGuard.com. Alle rechten voorbehouden.",
    Supcenter: "Ondersteuning",
    ProductFAQs: "Product FAQ's",
    AccountFAQs: "Account FAQ's",
    PurchaseFAQs: "Aankoop FAQ's",
    iSeeGuardStore: "Winkel",
    Polide: "Beleid",
    privPli: "Privacybeleid",
    cokPli: "Cookiebeleid",
    refndPli: "Terugbetalingsbeleid",
    elu: "EULA",
    TermsCot: "Algemene Voorwaarden",
    FoloUs: "Volg Ons",
    Paymet: "Betaling",
    prodt: "Producten",
    suport: "Ondersteuning",
    Load: "Laden…",
    NotFund: "Oeps! Pagina Niet Gevonden.",
    IagreThe: "Ik ga akkoord met de/het",
    GlevEu: "iSeeGuard EULA",
    PriPoly: "Privacybeleid",
    OurGuat: "Onze Garantie",
    secure: "Veilig",
    finalcoft: "We waarderen jouw privacy. Jouw persoonlijke of financiële informatie zal vertrouwelijk gehouden worden.",
    twoBack: "30 Dagen Geld Terug",
    provideCase: "We geven een 30 dagen geld terug garantie voor al onze producten in het geval dat je niet volledig tevreden bent.",
    cancelAnt: "Annuleer Op Elk Moment",
    cancelNeed: "Je kan het abonnement op elk moment stopzetten als je het product niet meer nodig hebt.",
    FastSup: "Snelle Ondersteuning",
    EnquriedProblem: "Ons team biedt een 24/5 Klantendienst aan om je met alle vragen en problemen te helpen.",
    AsUcess: "Sorry, de controle mislukte. Zorg er aub voor dat de iSeeGuard Pro Assistant app succesvol geïnstalleerd is op het doeltoestel.",
    ExportTip: "Gegevensexport wordt niet ondersteund op mobiele apparaten. Meld u aan bij het iSeeGuard online-paneel op uw computer en exporteer de specifieke gegevens.",
    Expired: "Verlopen",
    Features: "Functies",
    WifiUpload: "* Let op: foto's worden alleen via WiFi geüpload.",
    WhatsAppTips: "De nummer 1 speciale WhatsApp-monitoring-app is hier. Nu ontdekken >>",
    ScOne: "Er is een fout opgetreden bij het opslaan van de screenshots op het desbetreffende apparaat. Probeer het later opnieuw.",
    ScTwo: "Er is een fout opgetreden tijdens het uploaden van de screenshots. Probeer het later opnieuw.",
    Anpay: "Beveiligde online betaling",
    OldEmail: "Oud E-mail",
    ExtendSubscript: "Abonnement verlengen",
    Troubleshoot: "Problemen oplossen",
    PermissionsCheck: "Toelatingscontrole",
    LogAgin: "Als de app niet goed werkt op de telefoon waar het voor bestemd is, kan het zijn dat de toestemming is uitgeschakeld. Ga naar Instellingen en schakel ze weer in om de app weer te laten functioneren.",
    SubmitLog: "Om uw probleem beter op te lossen, kunt u het foutlogbestand naar ons doorsturen.",
    Accessibility: "Toegankelijkheid",
    Administrator: "Beheerder",
    CallLog: "Telefoonlog",
    Location: "Locatie",
    Notification: "Kennisgeving",
    SMS: "SMS",
    Storage: "Opslag",
    UsageStatus: "Gebruiksstatus",
    AccessCamera: "Camera",
    Microphone: "Microfoon",
    Submit: "Aanmelden",
    LogExpried: "Het foutenlogboek is succesvol geüpload.",
    LogMailOne: "U kunt contact met ons opnemen via",
    Spt: "support@iSeeGuard.com",
    SptFst: "voor snellere assistentie.",
    Permission: "Toestemming",
    Status: "Status",
    OnlyOne: "Er kunnen slechts 1000 records tegelijk worden geëxporteerd.",
    LogSucess: "Een onbekende fout is opgetreden.",
    LogUnkow: "Dien opnieuw in over 10 minuten. Of u kunt contact met ons opnemen via",
    LogTwoUnkow: "voor snellere ondersteuning.",
    Items: "Aantal",
    ViewSchot: "Bekijk historische screenshots",
    verifyIcloudAccount: "Controleer iCloud-account",
    icloudId: "Voer de iCloud-ID in van het apparaat dat u wilt controleren.",
    anotherIcloud: "Probeer het opnieuw of",
    useOther: "gebruik een andere iCloud account.",
    oldIcloud: "U heeft de oude iCloud-account ingevoerd.",
    accoundLock: "iCloud-account vergrendeld",
    lockedAgain: "Dit iCloud-account is om veiligheidsredenen vergrendeld door Apple. Probeer het over 12 uur opnieuw.",
    twoFactor: "Twee-factor-authenticatie",
    phoneSend: "Er is een bericht met een verificatiecode naar het betreffende apparaat gestuurd. Voer de code in om de account te controleren.",
    dontGetCode: "Geen verificatiecode ontvangen?",
    sixCode: "Als Twee-factor-authenticatie is ingeschakeld, hebt u fysieke toegang tot het doelapparaat nodig om een ​​verificatiecode van zes cijfers te krijgen.",
    resendCode: "Code opnieuw verzenden",
    textMe: "Stuur mij een berichtje",
    needHelp: "Hulp nodig?",
    notSendCode: "Verificatiecode verzenden mislukt, probeer het opnieuw.",
    noVerification: "Onjuiste verificatiecode.",
    noRecordsFound: "Geen gegevens gevonden!",
    newPricePlan: "U hebt een ander iCloud-account ingevoerd dat niet overeenkomt met onze gegevens. Als u het nieuw ingevoerde account wilt controleren, verwijdert u het vorige iCloud-account of koopt u een nieuw tariefplan.",
    deleteAccount: "Account verwijderen",
    icloudError: "iCloud Verificatie Fout",
    aginNormally: "Er is een fout opgetreden bij het verifiëren van het iCloud-doelaccount. Sommige gegevens worden mogelijk niet bijgewerkt. Controleer het iCloud-account opnieuw zodat u iSeeGuard Pro normaal kunt gebruiken.",
    notNow: "Niet nu",
    verifyIcloud: "Controleer iCloud",
    icloudAccount: "iCloud account",
    icloudStorage: "iCloudopslag",
    deviceName: "Apparaatnaam",
    deviceModel: "Apparaatmodel",
    seeMore: "Zie meer",
    sureTargetTwo: "Zorg ervoor dat de functie Zoek mijn iPhone functie is ingeschakeld op het doeltoestel.",
    location: "Locatie",
    list: "Lijst",
    sureDeleteAccount: "Weet u zeker dat u de iCloud-account wilt verwijderen?",
    removeThat: "Alle records en gegevens met betrekking tot dit iCloud-account worden daarna verwijderd.",
    isRemove: "Gefeliciteerd! Dit iCloud-account is met succes verwijderd.",
    Reminder: "Herinneringen",
    iCloudDrive: "iCloud Drive",
    appleIdErr: "De Apple ID of het wachtwoord is onjuist.",
    selectTrustePhone: "Selecteer vertrouwd telefoonnummer",
    serverErr: "Er is een fout opgetreden op de server. Probeer het later opnieuw.",
    continue: "Doorgaan met",
    textMessage: "Tekst bericht",
    codeMany: "Beveiligingscode te vaak onjuist ingevoerd.",
    downloadPage: "Het duurt gemiddeld 2-3 minuten om een Pages-, Numbers- of Keynote-bestand te downloaden. Weet je zeker dat je door wilt gaan?",
    Tips: "Tips",
    Verify: "Controleer",
    icloudLok: "Het iCloud-account is vergrendeld of het wachtwoord is onjuist.",
    veriErr: "Verificatie mislukt",
    notereminderTip: "iCloud reageert niet meer. Sommige gegevens zijn tijdelijk niet beschikbaar. Probeer het later opnieuw.",
    Video: "Video's",
    forAndroid: "Monitor Android",
    foriOs: "Monitor iOS",
    tryError: "Een onbekende fout is opgetreden.Probeer het later opnieuw.",
    nodata: "Geen gegevens",
    ForAndroid: "Voor Android",
    PhoneFiles: "Telefoon bestanden",
    ClickShoot: 'Klik op de knop "Foto\\ \'s maken".',
    LocationTracking: "Locatie volgen",
    RemoteControl: "Afstandsbediening",
    TakePhotos: "Foto's nemen",
    TakeNote: "Opmerking: u kunt alleen foto's maken met de achteruitrijcamera. Deze functie wordt niet aanbevolen in de volgende situaties:",
    Shoot: "Foto's maken",
    ShootErrorFirst: "Het verzoek komt te vaak voor. Even geduld en probeer het opnieuw.",
    ShootErrorTwo: "Kan de foto niet maken omdat het doelapparaat is uitgeschakeld of omdat de app tijdelijk niet reageert. Probeer het later opnieuw.",
    ShootErrorFour: "De toestemming voor toegang tot de camera is uitgeschakeld op het doelapparaat. Ga naar Instellingen> Apps> System Update Service> Machtigingen en schakel het opnieuw in.",
    ShootErrorFiveAndSeven: "Kan de camera niet starten omdat deze bezet is door een ander programma.",
    ShootErrorEight: "Er is een fout opgetreden bij het opslaan van de foto's op het doelapparaat. Probeer het later opnieuw.",
    ShootErrorNineAnTen: "Er is een fout opgetreden bij het uploaden van de foto's. Probeer het later opnieuw.",
    NoteWhenFirst: "Wanneer de camera bezet is;",
    NoteWhenTwo: "Wanneer de doeltelefoon naar boven op een plat voorwerp staat;",
    NoteWhenThree: "Wanneer de doeltelefoon in een zak of tas zit.",
    RecordCalls: "Gesprekken opnemen",
    RecordsOutgoing: "Neemt alle inkomende en uitgaande telefoongesprekken op afstand op Android-apparaten op",
    AutomaticallyRecord: "Telefoongesprekken automatisch opnemen",
    RemotelyOnline: "Luister online naar alle oproepen op afstand",
    WifiOnline: "Upload de audiobestanden naar het online-account wanneer een Wi-Fi-verbinding beschikbaar is",
    NoteTime: "Opmerking: deze functie kan maximaal 20 minuten aan gesprekken tegelijkertijd opnemen.",
    Audio: "Audio",
    DownloadSimple: "Download",
    TakeShootVersion: "De huidige versie is te laag. Upgrade de app naar de nieuwste versie op de doeltelefoon om deze functie te gebruiken.",
    RecordTarget: "Neem telefoongesprekken op de doeltelefoon op en sla deze op als verborgen geluidsbestanden",
    ViewTake: "Bekijk de geüploade foto's.",
    balnkExport: "Blanco",
    iphoneAudio: "De iOS-browser ondersteunt het afspelen van oproep opnemen niet. Doe het alstublieft op uw computer.",
    IncludedFeatures: "Inbegrepen functionaliteiten",
    AppVideos: "App Video's",
    AppPhotos: "App Foto's",
    SafariHistory: "Safari Geschiedenis",
    SafariBookmarks: "Safari Bladwijzers",
    VoiceMemos: "Spraakmemo's",
    ViewAllFollowing: "Bekijk alle volgende gegevens in de iSeeGuard Pro-app op de doelcomputer",
    OpeniSeeGuardProApp: "Open de iSeeGuard Pro-app",
    NoteInstalled: "Opmerking: U kunt op de rechterknop klikken om de interface van de app snel te openen. Als de app niet verschijnt, start de app dan handmatig op de computer waar deze is geïnstalleerd.",
    NoteFiles: "Opmerking: U moet toegang krijgen tot de doelcomputer en de app starten om alle bestanden te controleren.",
    NotSet: "Niet ingesteld",
    MonitorAndroid: "Monitor 1 Android-apparaat. (Geen root)",
    MonitoriOS: "Monitor 1 iOS-apparaat. (Geen jailbreak)",
    MonitorICloud: "Monitor 1 iCloud-account. (Geen jailbreak en geen app geïnstalleerd)",
    ChooseAndroid: "Kies uw plan voor iSeeGuard Pro voor Android",
    ChooseMac: "Kies uw plan voor iSeeGuard Pro voor Mac",
    ChooseIOS: "Kies uw plan voor iSeeGuard Pro voor iOS",
    ChooseICloud: "Kies uw plan voor iSeeGuard Pro voor iCloud",
    ChooseWhatsApp: "Kies uw plan voor iSeeGuard voor WhatsApp",
    ChooseWindows: "Kies uw plan voor Monivisor voor Windows",
    BuyDesc: "Auto vernieuwen. Annuleer elk moment. ",
    BuyTips1: "De licentie wordt automatisch verlengd in een maand. U kunt deze op elk gewenst moment annuleren op de pagina met productinstellingen.",
    BuyTips2: "De licentie wordt automatisch verlengd in 3 maanden. U kunt deze op elk gewenst moment opzeggen op de pagina met productinstellingen.",
    BuyTips3: "De licentie wordt automatisch verlengd in een jaar. U kunt de licentie op elk gewenst moment opzeggen op de pagina met productinstellingen.",
    IcartPay: "Betaal met credit card",
    ShowAll: "Toon alle",
    WaitMsg: "Wacht alsjeblieft. We zijn uw bestelling aan het verwerken.",
    MonitoriOSremotely: "Sorry, de annulering is mislukt. Probeer het alstublieft opnieuw.",
    tryAgain: "Probeer het alstublieft opnieuw.",
    resiterSucess: "Succesvol geregistreerd!",
    resiterTxt: "Gefeliciteerd! iSeeGuard account succesvol aangemaakt!",
    selectPt: "Selecteer het product dat u nodig heeft",
    Availablefor: "Beschikbaar voor",
    ThankYou: "Dank je!",
    ThankYouTips: "Uw inzending is ontvangen.",
    androidMonite: "Begin nu met de bewaking. Geen behoefte aan root Android-telefoons!",
    iosMonite: "Begin nu met monitoren. Geen noodzaak om iPhones of iPads te jailbreaken!",
    iCloudMonite: "Begin nu met monitoren. Geen jailbreaking of app installatie!",
    myPorductOrder: "Mijn producten en orderbeheer",
    expireSoon: "Verstrijken Spoedig",
    invisibleMode: "Een ultieme Android monitoring app die werkt in onzichtbare modus.",
    moniteFeatures: "30+ bewakingsfuncties voorzien. Geen beworteling nodig.",
    overTwoIos: "Meer dan 20 geavanceerde iOS-bewakingsfuncties, waaronder: WhatsApp, belgeschiedenis, teksten, enz.",
    overTwoMac: "Meer dan 20 geavanceerde Mac-bewakingsfuncties, waaronder: WhatsApp, belgeschiedenis, teksten, enz.",
    noLike: "Geen complexe bewerkingen zoals jailbreaking nodig. Eenvoudig aan de slag. ",
    userIcloud: "Bewaak elke iPhone of iPad op afstand online met behulp van de iCloud-gegevens.",
    noNeedInstallApp: "Je hoeft geen app te installeren op het doel iOS-apparaat, 100% veilig. ",
    myProduct: "Mijn producten",
    accountOrderInfo: "Account & Bestelinformatie",
    orderInfo: "Bestelinformatie",
    unableSync: "Niet in staat om gegevens tijdelijk te synchroniseren. De gegevens worden gesynchroniseerd nadat de doeltelefoon is ingeschakeld met een internetverbinding en de iSeeGuard Pro-assistent aan het doelapparaat werkt.",
    productFAQ: "Product FAQ's",
    path: "Pad",
    goSetZero: "Ga naar Instellingen > Apps > Zoek System Update Service > Machtigingen > Camera > Zorg dat het aan staat",
    goSetOne: "Ga naar Instellingen > Toegankelijkheid > System Update Service > zorg dat het aan staat",
    goSetTwo: "Ga naar Instellingen > Zoek Beveiliging > Andere beveiligingsinstellingen > Apparaatbeheerder > System Update Service > zorg dat het aan staat",
    goSetThree: "Ga naar Instellingen > Apps > Zoek System Update Service > Machtigingen > Kalender > Zorg dat het aan staat",
    goSetFour: "Ga naar Instellingen > Apps > Zoek System Update Service > Machtigingen > Oproeplogboeken > zorg dat het aan staat",
    goSetFive: "Ga naar Instellingen > Apps > Zoek System Update Service > Rechten > Contacten > Zorg dat het aan staat",
    goSetSix: "Ga naar Instellingen > Apps > Zoek System Update Service > Machtigingen > Locaties > zorg dat het aan staat",
    goSetSop: "Ga naar Instellingen > Apps > Zoek System Update Service > Machtigingen > Microfoon > zorg dat het aan staat",
    goSetSeven: "Ga naar Instellingen > Apps > Zoek System Update Service > Meldingen > Zorg dat het aan staat",
    goSetEight: "Ga naar Instellingen > Apps > Zoek System Update Service > Machtigingen > SMS > zorg dat het aan staat",
    goSetNight: "Ga naar Instellingen > Apps > Zoek System Update Service > Rechten > Opslag > Zorg dat het aan staat",
    goSetTwent: "Ga naar Instellingen > Zoek Beveiliging > Andere beveiligingsinstellingen > Gebruiksgegevenstoegang > System Update Service > zorg ervoor dat het aan staat",
    followStep: "Volg de onderstaande stappen om de telefoon die u wilt controleren toe te voegen",
    followIos: "Volg de onderstaande stappen om de iOS die u wilt controleren toe te voegen",
    followMac: "Volg de onderstaande stappen om de Mac die u wilt controleren toe te voegen",
    beforeStart: "Voordat u begint",
    youNeedDevice: "1. U hebt misschien fysieke toegang tot het doelapparaat nodig.",
    notiLLegal: "2. Gebruik dit product niet voor illegale doeleinden.",
    physicalDevice: "1. Fysieke toegang tot het apparaat is vereist.",
    illegalPurposes: "2. Gebruik dit product niet voor illegale doeleinden.",
    videoInstruction: "Video-instructie",
    VerifyInstall: "Raadpleeg de videogids om de installatie en configuratie van de app op de doeltelefoon te voltooien. Klik daarna op de knop Verifieer de instelling onderaan om verbinding te maken met het doeltoestel en het webdashboard in te voeren.",
    textInstruction: "Tekstinstructie",
    setupStep: "Stap ",
    androidStepOne: "Download de app naar de doeltelefoon",
    androidStepOneDesc: 'Open <strong style="color: #347aeb;">www.iseeguard.com</strong> met een browser op het doelapparaat en download de iSeeGuard Pro for Android app.',
    androidStepTwo: "Installeer de app",
    androidStepTwoDesc0: "Tik op het gedownloade bestand uit de downloadgeschiedenis van de browser of File Manager en initialiseer de installatie van de app.",
    androidStepTwoDesc1: "Tik op het gedownloade bestand uit de downloadgeschiedenis van de browser of File Manager en initialiseer de installatie van de app. Log daarna in op uw account en voer de basisgegevens van uw doel in.",
    androidStepTwoDesc2: "<strong>Opmerking: </strong> Sommige instellingen moeten correct worden geconfigureerd om iSeeGuard Pro te laten werken. Volg de aanwijzingen op het scherm in de applicatie-interface of de volgende richtlijnen om handmatig te werken.",
    androidStepThree: "Akkoord gaan met de overeenkomst",
    androidStepTwoToOneDesc: "Kies <strong>Ik heb de voorwaarden gelezen en begrepen</strong> en tik op <strong>Ik ga akkoord</strong>.",
    androidStepFour: "Basisrechten",
    androidStepTwoToTwoDesc: "Tik op <strong>Basisrechten </strong>> > Klik op <strong>Toestaan</strong> voor alle soorten basisautorisaties.",
    androidStepFives: "Toegankelijkheid activeren",
    androidStepTwoToThreeDesc: "Tik op <strong>Toegankelijkheidsservice</strong> > schakel <strong>Systeemservices</strong> in > tik op <strong>Toestaan</strong>.",
    androidStepSix: "Toegang tot meldingen activeren",
    androidStepTwoToFourDesc: "Tik op <strong>Luisteraar voor meldingen</strong> > schakel <strong>Systeemservices</strong> in > tik op <strong>Toestaan</strong>.",
    androidStepSeven: "Screenshot-toestemming activeren",
    androidStepTwoToFiveDesc: "Tik op <strong>Screenshot-toestemming</strong> > schakel <strong>iSeeGuard</strong> in.",
    androidStepEight: "Toegang tot gebruik",
    androidStepTwoToSixDesc: "Tik op <strong>Toegang tot gebruik</strong> > schakel <strong>Systeemservices</strong> in > tik op <strong>Toestaan</strong>.",
    androidStepNine: "Overlay-machtiging",
    androidStepTwoToSevenDesc: "Tik op <strong>Overlay-machtiging</strong> > schakel <strong>Systeemservices</strong> in > tik op <strong>Toestaan</strong>.",
    androidStepTen: "App-meldingen uitschakelen",
    androidStepTwoToEightDesc: "Tik op <strong>App-meldingen uitschakelen</strong> > schakel <strong>Systeemservices</strong> in > schakel <strong>Meldingsschakelaar weergeven</strong> uit.",
    androidStepTwoToNineDesc: "Tik op <strong>Batterijoptimalisatie uitschakelen</strong> > tik op <strong>Toestaan</strong> in het promptvenster.",
    androidStepTwoToTenDesc: "Nadat u alle machtigingsinstellingen hebt voltooid, tikt u op <strong>volgende</strong>. Log daarna in op uw account.",
    androidStepEleven: "Batterij-optimalisatie uitschakelen",
    androidStepTwelve: "Log in op je account",
    huaWeiNote1: "<strong>Huawei</strong>: Raak de onderstaande knop aan en zoek de System Update Service in Taakbeheer. Veeg de interface van de app omlaag om deze te vergrendelen. Tik daarna op de app-interface om terug te keren.",
    oppoNote1: "<strong>Oppo</strong>:  Raak de onderstaande knop aan en zoek de System Update Service in Taakbeheer. Tik op het instellingenpictogram in de rechterbovenhoek. Tik op Vergrendelen. Tik daarna op de app-interface om terug te keren.",
    xiaomiNote1: "<strong>Xiaomi</strong>: Raak de onderstaande knop aan en zoek de System Update Service in Taakbeheer. Druk lang op het app-tabblad en vergrendel het.",
    huaWeiNote: "<strong>Huawei</strong>: Ga naar Instellingen > Apps> App start > Zoek System Update Service, schakel deze eerst uit. Vervolgens schakelt u in het pop-up venster Manage Manually Manage, Auto-launch, Secondary launch en Run in de achtergrond in.",
    oppoNote: "<strong>Oppo</strong>: Ga naar Instellingen > Appbeheer > Applijst > Zoek System Update Service en Energiebesparing > Schakel Uitvoeren op de achtergrond in, ga dan naar de knop Terug en schakel <strong>Automatisch opstarten</strong> in en <strong>laat andere apps of diensten opstarten</strong>. Ga daarna terug naar Instellingen > Batterij > Schakel Smart Power Saver uit > Tik op Custom Power Saver > Zoek System Update Service en schakel <strong>Uitvoeren op de achtergrond</strong> in. Tik nogmaals op de knop Terug en <strong>schakel App Quick Freeze in</strong>. ",
    xiaomiNote: "<strong>Xiaomi</strong>: Ga naar Instellingen > Apps > Apps beheren > System Update Service zoeken > <strong>Autostart</strong> inschakelen, dan naar beneden scrollen en zoeken naar Batterijbespaarder > <strong>Geen beperkingen</strong>. ",
    vivoNote: "<strong>Vivo</strong>: Ga naar Instellingen > Batterij > <strong>Hoog stroomverbruik op de achtergrond</strong> > Zoek System Update Service > schakel het uit.",
    otherNote: "<strong>Andere</strong>: Ga naar Instellingen > Apps > Zoek System Update Service > Geavanceerde instellingen > Geavanceerde instellingen > Batterijoptimalisatie > Zoek de System Update Service opnieuw en schakel deze uit. ",
    androidStepThirteen: "Start Bewaking",
    androidStepThreeDesc: "Klik nu op de Start Monitoring knop en het app-icoontje zal snel verdwijnen. Voor Android 10 en hoger wordt het app-icoon vervangen door een Notice-icoon. Dit pictogram kunt u het beste in een map verbergen. ",
    VerifyYourSetup: "Controleer uw installatie",
    nowMoniteSupportTeam: "Als alles goed is ingesteld, klikt u op de knop Verifieer de instelling hieronder. Dit zal het doelapparaat aan uw online account binden. De huidige pagina zal naar het webdashboard springen, waar u alle bestanden kunt controleren. Als u de app niet succesvol kunt installeren, neem dan contact op met het ondersteuningsteam.",
    iosStepOne: "Download het programma op de doelcomputer",
    iosStepOneDesc: 'Ga naar <strong style="color: #347aeb;">www.useapp.net</strong> om iSeeGuard Pro voor iOS te downloaden op de doelcomputer.',
    iosStepOneNote: "<strong>Opmerking:</strong> Het wordt aanbevolen om het programma rechtstreeks te downloaden op de computer van de persoon die u wilt monitoren. Als u van tijd tot tijd fysiek toegang hebt tot de beoogde iPhone, kunt u het ook downloaden en op uw eigen computer in de gaten houden.",
    iosStepTwo: "Installeer het programma iSeeGuard Pro voor iOS",
    iosStepTwoWinDesc: "<strong>Voor Windows-gebruikers:</strong> Klik na het downloaden op het exe-bestand om het installatieproces te starten.",
    iosStepTwoWinNote1: "Houd er rekening mee dat u in deze eerste interface kunt kiezen of u het programmapictogram wilt verbergen. Als u de optie Laat de snelkoppeling niet zien op het bureaublad, de taakbalk en het startmenu aanvinkt, zijn er slechts twee manieren om iSeeGuard Pro te openen.",
    iosStepTwoWinNote2: "<strong>2.1</strong> Navigeer naar het installatiepad van het programma en klik op het exe bestand. Standaard is het installatiepad C: Programmabestanden (x86) iSeeGuard iSeeGuard Pro. Als u het installatiepad aanpast, zorg er dan voor dat u het kunt onthouden.",
    iosStepTwoWinNote3: "<strong>2.2</strong> Log in op het online dashboard van iSeeGuard Pro op de browser van de doelcomputer en klik vervolgens op iSeeGuard Pro App openen.",
    iosStepTwoMacDesc: "<strong>Voor Mac-gebruikers：</strong>Sleep na het downloaden het programmabestand naar de toepassingsmap. Start het programma wanneer de installatie is voltooid en log in op uw account.",
    iosStepThree: "Scan de gegevens van de iPhone/iPad",
    iosStepThreeToOneDesc1: "Als er geen back-up bestanden beschikbaar zijn op de computer, moet u het doel iOS apparaat verbinden via een USB kabel, bevestig dan de apparaat info en activeer USB instellingen volgens de instructies.",
    iosStepThreeToOneDesc2: "Zodra het doelapparaat succesvol is aangesloten, zal iSeeGuard Pro for iOS het scan- en analyseproces starten, wat enige tijd in beslag zal nemen.",
    iosStepThreeToTwoDesc1: "Als er backup-bestanden op de computer beschikbaar zijn, zal iSeeGuard Pro de gevonden backup weergeven. U kunt het backup-bestand van het doel-apparaat selecteren en de gegevens daaruit scannen.",
    iosStepThreeToTwoDesc2: 'Als het apparaat dat u wilt controleren niet in de lijst staat of als u alleen de allerlaatste gegevens wilt zien, kunt u onderaan op de optie "Het doelapparaat verbinden" klikken.',
    iosStepThreeToThreeDesc: "In de nieuwste versie kunt u de gegevens ook synchroniseren via Wi-Fi. Zodra de iPhone en iPad zijn verbonden met hetzelfde WiFi-netwerk als de computer, klikt u gewoon op het WiFi-pictogram in de navigatiebalk en vervolgens kunt u het apparaat selecteren om de gegevens op afstand te synchroniseren.",
    iosStepThreeToThreeNote: "<strong>Opmerking: </strong>Om gegevens via Wi-Fi te synchroniseren, moet u het apparaat eenmaal met de pc verbinden en erop vertrouwen dat de computer toestemming geeft.",
    iosStepFour: "Start het controleren van de doel iPhone/iPad op de computer",
    iosStepFourDesc: "Nadat het scanproces is voltooid, zal alle gescande informatie worden weergegeven op het dashboard en kunt u schakelen tussen verschillende bestandstypen en details bekijken. Ook kunt u alle gegevens exporteren naar de computer.",
    VerifySetup: "Verifieer de instelling ",
    kdsAndroid: "iSeeGuard Pro voor Android",
    kdsIos: "iSeeGuard Pro voor iOS",
    kdsIcloud: "iSeeGuard Pro voor iCloud",
    setupGuide: "Handleiding",
    generalSet: "Algemene instellingen",
    plseRefer: "Raadpleeg de videogids om te weten hoe u een iPhone kunt bewaken met iSeeGuard Pro. We hebben ook een gedetailleerde tekstinstructie hieronder opgesteld ter referentie.",
    locate: "Opsporen",
    requestOut: "De verzoektime-out.",
    onlyTxt: "Alleen tekstbestanden kunnen worden geëxporteerd.",
    quickly: "Klik op de onderstaande knop om snel in te loggen.",
    neverWth: "WhatsApp tracking is nog nooit zo eenvoudig geweest met iSeeGuard.",
    androidSytem: "Ultieme Android monitoring app die tot 30+ functies biedt. ",
    iOSSytem: "PC-gebaseerde software om een iPhone of iPad te monitoren zonder dat deze in de gevangenis belandt. ",
    iCloudSytem: "Online oplossing om iOS-apparaten te volgen zonder app-installatie.",
    WhatsSytem: "Gespecialiseerde WhatsApp-bewakingsapp voor Android-telefoons.",
    WindowsSytem: "Onzichtbare Windows-computerbewakingssoftware. ",
    Comingsoon: "Komt binnenkort",
    kdsMobile: "Mobiele beveiligingstoepassing",
    MobileSytem: "Hoogwaardige anti-spionage-app om uw Android-apparaat vrij te houden van spyware en stalkerware.",
    EarlBbirdTitle: "Mis de aankomende Android-tool voor mobiele beveiliging niet！",
    EarlBbirdContent: "Laat uw e-mailadres achter en ontvang de kortingsbon voor vroege vogels nadat het product is gelanceerd.",
    ValidEmail: "Uw geldige e-mailadres",
    EmailSubmited: "Deze e-mail is al verzonden.",
    WindowsMonitore: "MoniVisor",
    WhatsAppMonitore: "iSeeGuard voor WhatsApp",
    kdsWhatsapp: "iSeeGuard voor WhatsApp",
    SpeceAndroid: "Gespecialiseerde WhatsApp-bewakingsapp voor Android-telefoons.",
    preparedGuid: "Hier hebben we een gedetailleerde video voorbereid om je te helpen weten hoe je iemands WhatsApp via iSeeGuard voor WhatsApp kunt monitoren. Hieronder vindt je ook de stap-voor-stap tekstgids. ",
    DataUpload: "Gegevens uploaden",
    transferOver: "Grote bestanden alleen overbrengen via Wi-Fi.",
    followWhatsApp: "Volg de onderstaande stappen om iemands WhatsApp te volgen",
    whatsappStepOneDesc: 'Ga naar <strong style="color: #347aeb;">www.installapk.net</strong> met de browser van de doeltelefoon en download de iSeeGuard for WhatsApp app.',
    whatsappStepTwoDesc1: "Open het gedownloade APK bestand uit de browser download geschiedenis of File Manager en voltooi de installatie. Log dan in op uw account en voer de basisgegevens van uw doel in.",
    whatsappStepTwoDesc2: "<strong>Opmerking: </strong>Sommige instellingen moeten correct worden geconfigureerd om iSeeGuard voor WhatsApp te laten werken. Volg de aanwijzingen op het scherm in de applicatie-interface of de volgende richtlijnen om handmatig te werken.",
    whatsappStepTwoToOneDesc: "Tik op <strong>Verder naar instellingen </strong>> het instellingenpictogram in de rechterbovenhoek > schakel <strong>apps scannen met Play Protect en detectie van schadelijke apps </strong>verbeteren uit > keer terug naar de iSeeGuard voor WhatsApp-app > tik op <strong>Al ingesteld</strong> om verder te gaan.",
    whatsappStepTwoToTwoDesc: "Tik op <strong>Verder naar instellingen</strong> > Geïnstalleerde services > schakel <strong>WhatsApp Service</strong> in > tik op <strong>Toestaan</strong>.",
    whatsappStepTwoToThreeDesc: "Tik op <strong>Ga naar Instellingen</strong> > Zoek <strong>toegang tot gebruiksgegevens</strong> onder Beveiligingsinstellingen > Zoek de <strong>WhatsApp Service</strong> > Schakel het <strong>bijhouden van het gebruik</strong> in.",
    whatsappStepTwoToFourDesc: "Tik op<strong> Ga naar Instellingen</strong> > <strong>WhatsApp Service</strong> inschakelen.",
    whatsappStepTwoToFiveDesc: "Tik op <strong>Ga naar Instellingen  </strong>> activeer de toestemming van de toestelbeheerder voor de WhatsApp Service.",
    whatsappStepTwoToSevenDesc: "Tik op  <strong>Alles toestaan </strong> om deze app toestemming te geven automatisch WhatsApp-bestanden te ontvangen en WhatsApp-gesprekken op te nemen.",
    whatsappStepTwoToEightDesc: "Tik op <strong> Negeren</strong>  en vervolgens in het pop-upvenster op  <strong>Toestaan</strong>  om de WhatsApp Service op de achtergrond te laten draaien.",
    whatsappStepTwoToNine: "Autostart inschakelen",
    whatsappStepTwoToNineDesc: "Tik op <strong>Doorgaan naar instellingen</strong> > <strong>WhatsApp-service</strong>  inschakelen om de app te laten starten zodra het apparaat opnieuw is opgestart.",
    whatsappStepTwoToTen: "Activeren op achtergrond (Alleen voor Huawei, Oppo, Xiaomi, Vivo)",
    WhatsAppHuawei: "<strong>Huawei:</strong> Ga naar Instellingen > Apps > App start > Zoek WhatsApp Service, schakel deze eerst uit. Vervolgens schakelt je in het pop-up venster Manage Manually Manage, Auto-launch, Secondary launch en Run in de achtergrond in.",
    WhatsAppOppo: "<strong>Oppo:</strong> Ga naar Instellingen > Appbeheer > Applijst > Zoek Systeemupdate Service en Energiebesparing > Schakel Uitvoeren op de achtergrond in, ga dan naar de knop Terug en schakel Automatisch opstarten in en laat andere apps of diensten opstarten. Ga daarna terug naar Instellingen > Batterij > Schakel Smart Power Saver uit > Tik op Custom Power Saver > Zoek WhatsApp Service en schakel Uitvoeren op de achtergrond in. Tik nogmaals op de knop Terug en schakel App Quick Freeze in.",
    WhatsAppXiaomi: "<strong>Xiaomi:</strong> Ga naar Instellingen > Apps > Apps beheren > WhatsApp Service zoeken > Autostart inschakelen, dan naar beneden scrollen en zoeken naar Batterijbespaarder > Geen beperkingen.",
    WhatsAppVivo: "<strong>Vivo:</strong> Ga naar Instellingen > Batterij > Hoog stroomverbruik op de achtergrond > Zoek WhatsApp Service > schakel het uit.",
    WhatsAppOthers: "<strong>Overige:</strong> ga verder naar Instellingen > Apps > zoek WhatsApp Service > Geavanceerde instellingen > Batterij-optimalisatie > zoek WhatsApp Service opnieuw en schakel deze uit.",
    WhatsAppHuawei1: "<strong>Huawei:</strong> Ga naar Instellingen > zoek de WhatsApp Service in Taakbeheer. Veeg de interface van de app omlaag om deze te vergrendelen. Tik daarna op de app-interface om terug te keren.",
    WhatsAppOppo1: "<strong>Oppo:</strong> Ga naar Instellingen > zoek de WhatsApp Service in Taakbeheer. Tik op het instellingenpictogram in de rechterbovenhoek. Tik op Vergrendelen. Tik daarna op de app-interface om terug te keren.",
    WhatsAppXiaomi1: "<strong>Xiaomi:</strong> Ga naar Instellingen > zoek de WhatsApp Service in Taakbeheer. Druk lang op het app-tabblad en vergrendel het.",
    WhatsAppStartMonite: "Klik nu op de Start Monitoring knop en het app-icoontje zal snel verdwijnen. Voor Android 10 en hoger wordt het app-icoon vervangen door een WLAN icoon. je kunt dit pictogram het beste in een onduidelijke map verbergen.",
    WhatsAppVerify: "Controleer jouw installatie na voltooiing van de installatie",
    MoniteSupportTeam: "Als alles goed is ingesteld, klikt je op de knop Verifieer de instelling hieronder. Dit zal het doelapparaat aan jouw online account binden. De huidige pagina zal naar het webdashboard springen, waar je alle bestanden kunt controleren. Als je de app niet succesvol kunt installeren, neem dan contact op met het supportteam.",
    DataWarn: "Waarschuwing voor het gebruik van gegevens",
    DataTerm: "Het uploaden van grote bestanden (foto's, video's, audio en documenten) via mobiele gegevens kan extra netwerkkosten met zich meebrengen op je doeltelefoon en kan het feit blootleggen dat je WhatsApp volgt. Weet je zeker dat je nog steeds mediabestanden wilt uploaden via mobiele data?",
    DataTermNote: "<strong>Opmerking:</strong> Chatgeschiedenis, status, gesprekken en WhatsApp-activiteiten zijn niet onderworpen aan deze voorwaarde. Standaard worden deze bestanden geüpload via mobiele data.",
    ScreenTime: "WhatsApp Schermtijd (Laatste 7 dagen)",
    WhatsAppChats: "WhatsApp Chats",
    WhatsAppCalls: "WhatsApp Gesprekken",
    Calls: "Gesprekken",
    CallRecording: "Call Recording",
    WhatsAppStatus: "WhatsApp-status",
    WhatsAppPhotos: "WhatsApp-foto's",
    WhatsAppVideos: "WhatsApp video's",
    WhatsAppVoices: "WhatsApp Stemmen",
    Voices: "Stemmen",
    WhatsAppDocuments: "WhatsApp Documenten",
    Documents: "Documenten",
    WhatsAppActivities: "WhatsApp-activiteiten",
    Activities: "activiteiten",
    WhatsAppScreenshots: "WhatsApp Screenshots",
    Screenshots: "Schermafbeeldingen",
    uploadTle: "Grote bestanden (foto's, video's, audio & documenten) worden standaard geüpload via Wi-Fi. U kunt dit wijzigen in Produkte> Einstellungen.",
    searchCallerName: "zoek naam beller",
    CallerName: "Naam van de beller",
    onlyCover: "* iSeeGuard voor WhatsApp zal alleen videocovers uploaden.",
    screenFeature: "Aan de slag met de automatische schermafbeeldingsfunctie",
    screenFeatureOne: "Deze functie zal automatisch schermafbeeldingen van WhatsApp-schermen vastleggen. Het wordt dus alleen geactiveerd wanneer de doelgebruiker WhatsApp gebruikt. ",
    screenFeatureTwo: "Je kunt kiezen wanneer je de screenshots wilt uploaden. Door het inschakelen van Upload via Wi-Fi worden de screenshots vaker vastgelegd en dit kan extra netwerkkosten op je doeltelefoon voorkomen. Het andere is precies het tegenovergestelde.",
    UploadWiFi: "Uploaden via Wi-Fi",
    MoreConsumption: "Meer stroomverbruik",
    fasterfrequency: "Snellere screenshot-frequentie",
    nomoBileData: "Geen mobiel dataverbruik",
    UploadMobileDat: "Uploaden via mobiele gegevens",
    LessPowerConsumption: "Minder stroomverbruik",
    lowerFrequencyScreenshots: "Lagere schermafbeeldingsfrequentie",
    usemobiledata: "Gebruik meer mobiele data",
    SuccessfullySet: "Met succes!",
    SetSucess: "U hebt de automatische screenshot-functie met succes ingesteld. De screenshots beginnen te uploaden wanneer de doelgebruiker WhatsApp gebruikt en er een netwerkverbinding is. Even geduld a.u.b.",
    wifi: "Wi-Fi",
    wifiText: "De WhatsApp-screenshots worden geüpload via het Wi-Fi-netwerk.",
    MobileData: "Mobiele gegevens",
    MobileDataText: "De WhatsApp-screenshots worden geüpload met behulp van mobiele data.",
    WhatsAppDownload: "Mediabestanden kunnen direct worden gedownload in elke functie.",
    tryagain: " Probeer het later nog eens.",
    beingUpload: "De gegevens worden geüpload. Het kan een tijdje duren voordat de gegevens volledig worden weergegeven, gelieve de pagina later te verversen.",
    Chat: "Chats",
    CallRecorde: "Oproepopnames",
    Play: "Speel",
    AudioCall: "Audiogesprek",
    VideoCall: "Video-oproep",
    startTle: "Aan de slag met WhatsApp Call Recording Feature",
    startTleOne: "<strong>1.</strong> Deze functie maakt gebruik van de microfoon om WhatsApp-voice/video-oproepen op te nemen, zodat je de stem aan de andere kant misschien niet hoort, tenzij de luidspreker is ingeschakeld.",
    startTleTwo: "<strong>2.</strong> Standaard worden de audiobestanden automatisch geüpload via Wi-Fi. Dit is om extra kosten voor mobiele gegevens te voorkomen en je te beschermen tegen ontdekking. je kunt deze instellingen wijzigen in Mijn producten - Instellingen > Gegevens uploaden.",
    startTleThree: "<strong>3.</strong> Deze functie kan tot 20 minuten per keer opnemen, dus als het gesprek langer duurt dan 20 minuten, zal het worden opgedeeld in verschillende kleine fragmenten voor het uploaden.",
    Thumbnail: "Thumbnail",
    UpdateOverWifi: "Update via wifi",
    wifiUpload: "Houd er rekening mee dat grote bestanden (foto's, video's, audio en documenten) worden geüpload wanneer de doeltelefoon een Wi-Fi-netwerk gebruikt.",
    wifiNote: "<strong>Opmerking:</strong> chatgeschiedenis, status, oproepen en WhatsApp-activiteiten zijn niet onderworpen aan deze voorwaarde. Deze bestanden worden standaard geüpload via mobiele data en wifi.",
    ChatContact: "Chatcontact",
    Content: "Inhoud",
    State: "Toestand",
    screenTime: "Schermtijd",
    whatAppPathOne: "Ga naar Instellingen > Toegankelijkheid > WhatsApp Service > zorg dat het aan staat",
    whatAppPathTwo: "Ga naar Instellingen > Zoek Beveiliging > Andere beveiligingsinstellingen > Apparaatbeheerder > WhatsApp Service > zorg ervoor dat het aan staat",
    whatAppPathThree: "Ga naar Instellingen > Apps > Zoek WhatsApp Service > Rechten > Opslag > Zorg dat het aan staat",
    whatAppPathFour: "Ga naar Instellingen > Apps > Zoek WhatsApp Service > Meldingen > zorg dat het aan staat",
    whatAppPathFive: "Ga naar Instellingen > Zoek Beveiliging > Andere beveiligingsinstellingen > Gebruiksgegevens toegang > WhatsApp Service > zorg ervoor dat het aan staat",
    whatAppPathSix: "Ga naar Instellingen > Apps > Zoek WhatsApp Service > Machtigingen > Microfoon > zorg dat deze aan staat",
    whatsAppSetupError: "* Sorry, de verificatie is mislukt. Zorg ervoor dat je de iSeeGuard voor WhatsApp app met succes hebt geïnstalleerd in de doeltelefoon. ",
    monitorReal: "Laat je iemands WhatsApp volgen als echt.",
    mustFinshSet: "* U bent niet klaar met de instellingen. Kies of u onder Wi-Fi of mobiele data de schermafbeeldingen wilt uploaden.",
    yourEmail: "Uw e-mail:",
    whatsAppDelete: "Om je persoonlijke privacy te beschermen, zal iSeeGuard stoppen met alle data te monitoren en het verwijderen.",
    Store: "Winkel",
    monitorAndroidDevice: "Monitor Android-apparaten",
    androidMoniteName: "iSeeGuard Pro - Android-monitoring",
    whatsAppMoniteName: "iSeeGuard - WhatsApp-monitoring",
    monitoriOSDevice: "Monitor iOS Devices",
    iPhoneMoniteName: "iSeeGuard Pro - iPhone-monitoring",
    iCloudMoniteName: "iSeeGuard Pro - iCloud-monitoring",
    cancelNote: "<strong>Opmerking:</strong> door automatische verlenging te annuleren, blijft uw account actief tot de vervaldatum. Als u onze service wilt blijven gebruiken, moet u deze op dat moment handmatig verlengen.",
    whatsAppSync: "Niet in staat om gegevens tijdelijk te synchroniseren. De gegevens worden gesynchroniseerd nadat de doeltelefoon is ingeschakeld met een internetverbinding en de iSeeGuard voor WhatsApp app aan het doelapparaat werkt.",
    SyncErrWhy: " Waarom is iSeeGuard for WhatsApp niet in staat om tijdelijk data te synchroniseren?",
    FirstReason: "Allereerst synchroniseert iSeeGuard for WhatsApp elke 15 minuten automatisch nieuwe data. Ten tweede kunt u de data synchroniseren door te klikken op de Sync knop in elke functie. De tijd die nodig is om data te synchroniseren is afhankelijk van de grootte van de data en de snelheid van de internetverbinding van het doelapparaat.",
    ByDefualt: "Standaard worden grote bestanden (foto's, video's, audio en documenten) geüpload wanneer de doeltelefoon gebruik maakt van een Wi-Fi-netwerk. De geschiedenis van chatberichten, de tekststatus, gesprekken en WhatsApp-activiteiten zijn niet onderworpen aan deze voorwaarde. U kunt dit wijzigen in Mijn producten > Instellingen.",
    ReasonExample: "Het volgende kan de reden zijn als u geen bijgewerkte gegevens kunt bekijken.",
    NonProductReasons: "Niet-productgebonden redenen:",
    NonProductReasons1: "Het doelapparaat is uitgeschakeld.",
    NonProductReasons2: "Het doelapparaat is niet verbonden met het netwerk of het netwerk is onstabiel.",
    NonProductReasons3: "WhatsApp is verwijderd van het doelapparaat.",
    NonProductReasons4: "WhatsApp wordt niet gebruikt op het doelapparaat.",
    NonProductReasons5: "De datum en tijd op het doelapparaat komen niet overeen met de lokale tijd. Ga naar Instellingen > Algemeen beheer > Datum en tijd > automatische datum en tijd inschakelen.",
    ProductReasons: "Productredenen:",
    ProductReasons1: "De iSeeGuard voor WhatsApp app is niet actief of is verwijderd van het doelapparaat. Als dit het geval is, start u het doelapparaat opnieuw op of installeert u de app opnieuw.",
    ProductReasons2: "Sommige rechten voor iSeeGuard for WhatsApp zijn uitgeschakeld op het doelapparaat, met name voor Accessiblity en Storage. Ga naar Toestemmingscontrole en volg de richtlijnen voor handmatige bediening.",
    SelectAll: "Selecteer",
    DeleteFiles: "Bestanden verwijderen",
    DeleteConfirm: "Weet u zeker dat u de bestanden wilt verwijderen?",
    DeleteSuccess: "Succesvol verwijderen!",
    DownloadFiles: "Bestanden downloaden",
    DownloadConfirm: "Weet u zeker dat u de bestanden wilt downloaden?",
    DownloadTips: "<strong>Opmerking</strong>: De gedownloade bestanden worden van het dashboard verwijderd.",
    DownloadWaiting: "Het kan enkele minuten duren voor dat ze klaar zijn, afhankelijk van de grootte van de bestanden.",
    DownloadSuccess: "Succesvol downloaden!",
    DownloadSuccessTips: "U kunt de gedownloade bestanden vinden in Downloads Geschiedenis.",
    DownloadFailed: "Het downloaden is mislukt. Probeer het opnieuw onder een stabiel netwerk.",
    DeleteFailed: "Het downloaden is mislukt. Probeer het opnieuw onder een stabiel netwerk.",
    Processing: "Verwerken...",
    ToppingTips: "klik om dit gesprek te markeren",
    MonStepFollowTitle: "Volg de onderstaande stappen om uw product te activeren",
    MonStepImportantTitle: "Belangrijke informatie:",
    MonStepTitle1: "Download het programma op de doelcomputer",
    MonStepTitle2: "Installeer de software en voltooi de configuratie",
    MonStepTitle3: "Installeer de Chrome Extension (Optioneel)",
    MonStepTitle4: "Ga naar het Online Dashboard om nu te monitoren",
    MonStepImportant1: "<b>1.</b> U heeft fysieke toegang tot de doelcomputer nodig.",
    MonStepImportant2: "<b>2.</b> Gebruik dit product niet voor illegale doeleinden.",
    MonStepImportant3: "<b>3.</b> MoniVisor bestaat uit twee delen: De desktop software en het online dashboard.",
    MonStepImportant4: "<b>4.</b> Wij raden u ten zeerste aan om de desktop software toe te voegen aan de Whitelist van eventuele anti-virus of beveiligingssoftware om te voorkomen dat het programma wordt gedood of dat de functies ervan niet goed functioneren.",
    MonStepImportant5: "<b>5.</b> Het wordt aanbevolen om de Private browsing modus te gebruiken om deze tool te downloaden of de browsing geschiedenis te wissen om te voorkomen dat er sporen van de installatie achterblijven.",
    MonStep1Desc: 'MoniVisor for Win werkt via een verborgen computer-gebaseerde software en een online dashboard. Om te beginnen moet u fysiek toegang krijgen tot de doelcomputer en de URL ( <b style="color: #347aeb">http://www.installfree.net</b> ) in elke browser plakken om de software te downloaden.',
    MonStep2Desc: "Installeer vervolgens de software en voltooi de configuratie door de handleiding op het scherm te volgen. ",
    MonStep2Note: "<b>Note:</b> U kunt worden gevraagd door Windows User Access Control of SmartScreen, om goedkeuring te vragen voor het uitvoeren van de software. Klik, indien gevraagd, op 'Ja', 'Uitvoeren' of 'Hoe dan ook uit te voeren'. Als de optie om Ja of Uitvoeren te selecteren niet beschikbaar is, klik dan op 'meer info' om de optie weer te geven.",
    MonStep2li_1: "Het duurt even om de software te installeren.",
    MonStep2li_2: "Activeer met uw gelicenseerde account en wachtwoord.",
    MonStep2li_3: "Als het programma een anti-virus software op de computer detecteert, volg dan de gegeven instructies om MoniVisor for Win aan Whitelist toe te voegen. Na afloop zult u merken dat het programma automatisch in de verborgen modus gaat draaien.",
    MonStep3Desc: "Als de standaard browser Chrome is in de doelcomputer, dan heb je een extensie nodig om webmail (zoals Gmail, Outlook of Yahoo Mail) en webchat berichten (zoals WhatsApp, Facebook, Twitter, Instagram of Skype) in de gaten te houden. We raden u sterk aan om de extensie te installeren om de monitoringmogelijkheden van de software te vergroten. Volg hiervoor de onderstaande handleiding.",
    MonStep3li_1: "Open de Chrome browser.",
    MonStep3li_2: "Klik op de 3 stippen optie rechtsboven in de browser - <b>Meer hulpmiddelen</b> - <b>Uitbreidingenmenu</b>.",
    MonStep3li_3: "Zet de <b>'ontwikkelaarsmodus'</b> aan door op de schuifknop rechtsboven in de browser te klikken.",
    MonStep3li_4: "De knop <b>'Uitgepakt laden'</b> wordt in de menubalk weergegeven.",
    MonStep3li_5: "Klik op de knop <b>'Uitgepakt laden'</b> en ga dan naar <b style=\"color: #347aeb;\">C:\\Program Files (x86)\\Windows Assistant App\\MV\\extension</b> en selecteer de map <b>'moni-chrome-extension'</b>map.\n",
    MonStep3li_6: "De <b>'Extensie'</b> is nu geïnstalleerd op uw computer.",
    MonStep3li_7: "Schakel de <b>'ontwikkelaarsmodus'</b> uit om het proces te voltooien.",
    MonStep3li_8: "Als de extensie succesvol is geïnstalleerd, ziet u deze op de uitbreidingslijst.\n",
    MonStep4Desc1: "Nu is de benodigde setup op de doelcomputer voltooid. Dan zult u merken dat de tool automatisch in de verborgen modus gaat en op de achtergrond blijft werken.",
    MonStep4Desc2: "U kunt naar de website van iSeeGuard gaan om in te loggen met een willekeurige browser (op uw eigen computer, tablet of mobiele telefoon). Of klik op de Verify Setup knop hieronder om uw account snel vast te leggen, en de huidige pagina springt naar het webdashboard, waar u alle gegevens kunt controleren.  Als u de app niet succesvol kunt installeren, neem dan contact op met het supportteam.",
    MonStepError: "* Sorry, de verificatie is mislukt. Zorg ervoor dat de MoniVisor-assistentsoftware met succes op de doelcomputer is geïnstalleerd",
    MonVerifySetup: "Verifieer de instelling",
    MonUninstallBigTitle: "Uninstallation for MoniVisor Desktop Software",
    MonUninstallTitle: "Wij bieden u 2 manieren om de sofware op de doelcomputer te verwijderen. U kunt de sofware op afstand verwijderen door op de onderstaande knop te klikken of door de doelcomputer fysiek te openen voor handmatige verwijdering.",
    MonUninstallNote: "Opmerkingen:",
    MonUninstallNote1: "1. De functie voor verwijderen op afstand is alleen beschikbaar als het doelapparaat online is.",
    MonUninstallNote2: "2. Het verwijderen van de software zal uw account niet ontbinden, en zal de bewaakte gegevens niet verwijderen. U zult echter geen longr nieuwe gegevens ontvangen. Als u het account wilt ontbinden, kunt u naar de instellingenpagina gaan en op de knop 'Ontbinden' klikken.",
    MonUninstallMethod1: "Methode 1: Op afstand verwijderen met één klik.",
    MonUninstallMethod1Desc: "Klik op de onderstaande knop en de computersoftware voert de opdracht automatisch uit.",
    MonUninstallMethod2: "Methode 2. De-installeer het handmatig.",
    MonUninstallMethod2Desc: 'De app wordt in het pad geïnstalleerd: <b style="color: #347aeb;">C:\\Program Files (x86)\\Windows Assistant App\\MV</b>',
    MonUninstallTheStep: "De stappen zijn:",
    MonUninstallTheStep1: "Stap 1: Ga naar de doelcomputer en typ het bovenstaande volledige installatiepad in om het bestand te lokaliseren",
    MonUninstallTheStep2: 'Stap 2: Klik vervolgens op het bestand <b style="color: #347aeb;">unins000.exe</b> om het verwijderingscommando uit te voeren',
    MonUninstallTheStep3: "Stap 3: Er hoeft niets anders te worden gedaan, u hoeft alleen maar de computer opnieuw op te starten en u kunt de software met succes verwijderen",
    MonUninstallError: "Sorry, de doelcomputer is nu offline. Controleer de status van het apparaat op uw Dashboard of op de instellingenpagina en probeer het opnieuw als het doelwit online is",
    MonUninstallTip: "Dit zal uw account niet ontbinden, maar u zult geen nieuwe gegevens meer ontvangen.",
    MonUninstallProblem: "Weet u zeker dat u de software op de doelcomputer wilt verwijderen?",
    MonUninstallU: "Uninstall",
    MonUninstallSuccess: "Gefeliciteerd! Je hebt de software met succes gedeïnstalleerd!",
    MonUnbundlingProblem: "Weet u zeker dat u het apparaat wilt loskoppelen?",
    MonUnbundlingTip: "Uit veiligheidsoverwegingen worden alle gegevens verwijderd",
    MonUnbundlingSuccess: "Gefeliciteerd! U hebt de software met succes ontbonden!",
    MonInstallationHelp: "Installatie-Helpdocument",
    MonInstallationDesc: "Hier is de handleiding voor u over hoe u de softwaremap en bestanden aan de antivirus lijst' kunt toevoegen Whitelist, Trusted List, of bestands- en mapuitzonderingslijst om te voorkomen dat de software op de doelcomputer wordt gedood. De gids voor het installeren van de MoniVisor Chrome Extension is ook inbegrepen. Lees de onderstaande stappen zorgvuldig door.",
    MonInstallationHowTo1: "Hoe toe te voegen aan de Whitelist van de antivirussoftware",
    MonInstallationHowTo2: "Hoe MoniVisor Chrome Extension te installeren",
    MonInstallationHowTo3: "Gids voor het toevoegen aan de Whitelist van antivirussoftware",
    MonInstallationStepTitle: "Specifieke instructies kunnen verschillen voor verschillende antiviruspakketten, hoewel het basisproces hetzelfde is. Hier zijn de stappen om dit te doen:",
    MonInstallationStep1: '<span>Stap 1:</span> Wees duidelijk waar de MoniVisor is geïnstalleerd: <b style="color: #347aeb;">C:\\Program Files (x86)\\Windows Assistant App</b>',
    MonInstallationStep1Desc: 'De bestandsnaam is: <b style="color: #347aeb;">Setupsvc.exe</b>',
    MonInstallationStep2: "<span>Stap 2:</span> Houd er rekening mee welke antivirussoftware op de doelcomputer is geïnstalleerd en controleer de onderstaande officiële instructies voor het configureren van de uitzonderingenlijst in specifieke antiviruspakketten.",
    MonInstallationStep2Desc: "Guide on How to Install MoniVisor Chrome Extension",
    MonWebChatsOfflineTip: "De doelcomputer is nu offline. Er worden geen gegevens geüpdatet tot hij online is.",
    MonWebChatsOfflineTipDesc: "Wat is offline? Dit kan gebeuren wanneer het doelapparaat wordt losgekoppeld van het internet, de computer wordt uitgeschakeld, de software van de doelcomputer is verwijderd of de software op een andere manier om onbekende redenen is verwijdert",
    MonWebChatsNote1: 'Opmerking: Controleer of de doelcomputer online is en of de Google Chrome-extensie goed is geïnstalleerd. Klik <a style="color:#347aeb" href="/monivisor-installation-guide-win" target="_blank">hier</a> voor de volledige handleiding om de extensie te installeren.',
    MonScreenClickTitle: "Aan de slag met MoniVisor Screenshot Capture Feature",
    MonScreenClickDesc: "Klik op Start om screenshots te maken van de doelcomputer.<br/> Screenshots worden elke 15 seconden gemaakt gedurende 5 minuten.",
    MonScreenClickNote: "Opmerkingen:",
    MonScreenClickNote1: "1. Deze functie kan alleen worden gebruikt als de doelcomputer online is.",
    MonScreenClickNote2: "2. U kunt de screenshooting binnen vijf minuten na het starten stoppen. Als je het niet handmatig stopt, stopt het automatisch na vijf minuten en kun je opnieuw beginnen.",
    MonScreenClickError1: "De doelcomputer is offline, controleer de status van het apparaat en probeer dan deze functie als hij online is.",
    MonScreenClickError2: "Weet je zeker dat je de screenshots wilt verwijderen?",
    MonScreenSelectAll: "Selecteer alles",
    MonScreenDeleting: "Verwijderen...",
    MonScreenDeleteFiles: "Bestanden verwijderen",
    MonScreenSuccess1: "Succes! Je hebt ",
    MonScreenSuccess2: " screenshots verwijderd!",
    MonFileAction: "Actie",
    MonFileFileName: "Bestandsnaam",
    MonFileDate: "Datum",
    MonLoginUserName: "Gebruikersnaam",
    MonLoginActivityTh: "Activity",
    MonLoginPrinterName: "Printernaam",
    MonLoginFileName: "Bestandsnaam",
    MonLoginPages: "Pagina's",
    MonExportDataSetting: "Data Setting:",
    MonExportDeleteExportedData: "Delete Exported Data",
    MonExportDesc1: "1. Mediabestanden in de geschiedenis van de webchats kunnen alleen in de vorm van tekst worden geëxporteerd.",
    MonExportDesc2: "2. Screenshots kunnen in bulk worden gedownload op de resultatenpagina.",
    MonPurchaseBuyWin: "Kies uw plan voor MoniVisor voor Windows",
    MonPurchaseGuideType2: "Onzichtbare Windows-computerbewakingssoftware.",
    MonMyProductPageDesc1: "Een monitoring tool om op afstand alle bestanden op de Windows-computer te controleren.",
    MonMyProductPageDesc2: "Ondetecteerbare loopmodus en het online dashboard om overal en altijd gegevens te bekijken.",
    MonDashboardName: "Naam",
    MonDashboardComputerID: "Computer ID",
    MonDashboardLatestEmails: "Laatste E-mails",
    MonDashboardMore: "Meer",
    MonDashboardLatestBrowsingHistories: "Latest Browsing Histories",
    MonDashboardSender: "Afzender",
    MonDashboardSubject: "Onderwerp",
    MonDashboardDate: "Datum",
    MonDashboardDomain: "Domein",
    MonDashboardTitle: "Titel",
    MonSettingsUninstallGuide: "De-installatiegids",
    MonSettingsHelpDocument: "Installatie-HelpDocument",
    MonSettingsSoftwareVersion: "Softwareversie op de doelcomputer",
    MonSettingsWhat: "Wat is er nieuw",
    MonRequestLong: "Wij behandelen uw verzoek. Wacht even en probeer het nog eens.",
    MonUnknown: "Onbekend",
    MoniVisor: "MoniVisor",
    MoniVisorWebActivity: "Webactiviteit",
    MoniVisorActivityChrome: "Chrome",
    MoniVisorActivityFirefox: "FireFox",
    MoniVisorActivityEdge: "Microsoft Edge",
    MoniVisorActivityIE: "Internet Explorer",
    MoniVisorActivityOpera: "Opera",
    MoniVisorDownloadHistory: "Geschiedenis downloaden",
    MoniVisorWebChats: "Web Chats",
    MonAppActivity: "App Activiteit",
    MonKeylogger: "Keylogger",
    MonScreenshot: "Screenshots vastleggen",
    MonScreenshotSet: "Screenshot-instellingen",
    MonScreenshotView: "Bekijk schermafbeeldingen",
    MonLoginActivity: "Login Activiteit",
    MonPrintActivity: "Drukactiviteit",
    MonUsbConnection: "USB-aansluiting",
    MonDataExport: "Gegevens exporteren",
    MonComputer: "Monitor de computer",
    MonFileActivity: "Bestandsactiviteit",
    MonRemaining: "Overgebleven tijd:",
    MonScreenIngError: "De schermafdruktaak is al bezig. Probeer het later opnieuw.",
    MonDataExportOnly: "Er kunnen slechts 2000 records tegelijk worden geëxporteerd.",
    MonSomeTime: "Het synchroniseren van gegevens kan even duren. Wees alstublieft geduldig...",
    MonLastUsed: "Laatst gebruikt",
    MonFilePath: "Bestandspad",
    MonTimeUsage: "Tijdgebruik",
    MonReceived: "Ontvangen",
    MonSent: "Verzonden",
    MoniVisorWebChatsWhatsApp: "WhatsApp",
    MoniVisorWebChatsFacebook: "Facebook",
    MoniVisorWebChatsTwitter: "Twitter",
    MoniVisorWebChatsInstagram: "Instagram",
    MoniVisorWebChatsSkype: "Skype",
    MoniVisorWebChatsPinterest: "Pinterest",
    MonWebMail: "Webmail",
    MonWebMailGmail: "Gmail",
    MonWebMailOutlook: "Outlook",
    MonWebMailYahoo: "Yahoo",
    MonDataExportWebActivityChrome: "Webactiviteit - Chrome",
    MonDataExportWebActivityIE: "Webactiviteit - IE",
    MonDataExportWebActivityEdge: "Webactiviteit - Edge",
    MonDataExportWebActivityOpera: "Webactiviteit - Opera",
    MonDataExportWebActivityFirefox: "Webactiviteit - Firefox",
    MonDataExportDownloadHistoryChrome: "Download geschiedenis - Chrome",
    MonDataExportDownloadHistoryIE: "Download geschiedenis - IE",
    MonDataExportDownloadHistoryEdge: "Download geschiedenis - Edge",
    MonDataExportDownloadHistoryOpera: "Download geschiedenis - Opera",
    MonDataExportDownloadHistoryFirefox: "Download geschiedenis - Firefox",
    MonScreenStop: "Hou op",
    MonScreenMaxDownTip: "* Er kunnen maximaal 50 afbeeldingen tegelijk worden gedownload en de gedownloade gegevens worden verwijderd.",
    MonWebMailAttachments: "Bijlagepad",
    MonWebMailFrom: "Van",
    MonKeyloggerTip: 'Opmerking: controleer of de doelcomputer online is en dat de software niet is gedood door een antivirusprogramma. Klik <a style="color:#347aeb" href="/monivisor-installation-guide-win" target="_blank">hier</a> voor de volledige gids om de software toe te voegen aan de witte lijst van antivirusprogramma\'s.',
    MonInsert: "Invoegen",
    MonEject: "Uitwerpen",
    MonLogon: "Inloggen",
    MonLogoff: "Uitloggen",
    MonCreate: "Creëer",
    MonCopy: "Kopiëren",
    MonDelete: "Verwijderen",
    MonRename: "Hernoemen",
    MonImage: "Beeld",
    MonVideo: "Video",
    MonFile: "Het dossier",
    MonLocation: "Plaats",
    MonPasteNote: "<b>Opmerking:</b> u moet het volledige installatiepad van het bestand plakken of tijdelijk het bekijken van verborgen mappen in Windows Verkenner toestaan ​​om de mappen handmatig te selecteren vanuit de antivirus-app. Voeg de MV-map onder het installatiepad of de opgegeven volledige bestandsnaam toe aan de witte lijst, afhankelijk van de vereisten van verschillende antivirussoftware.",
    kdsMoniVisor: "MoniVisor voor Win",
    MonMisseVoice: "Gemiste spraakoproep",
    MonMisseVideo: "Gemiste videogesprek",
    MonContact: "Contact",
    MonMonitorComputers: "Computers controleren",
    MonWindowsMonitoring: "MoniVisor - Windows Monitoring",
    ParamError: "Parameterfout",
    TokenError: "Inloggen ongeldig",
    UserVIPExpired: "Gebruikerslidmaatschap is verlopen of bestaat niet",
    NotfoundHttp: "Het gevraagde adres bestaat niet",
    ServerError: "Serverfout",
    HeadersMiss: "De headers-parameter ontbreekt",
    MonitorNow: "Nu controleren",
    AboutUs: "over ons",
    PrivacyPolicy: "Privacybeleid",
    TermsConditions: "voorwaarden & Conditie",
    Disclaimer: "Zastrzeżenie",
    DisclaimerDesc: "DE SOFTWARE VAN ISEEGUARD UITSLUITEND BEDOELD VOOR WETTELIJK GEBRUIK.<br />Het installeren van deze gelicentieerde software op een apparaat waarvoor u geen controlerechten heeft, kan in strijd zijn met de wetten van uw land of regio. U bent volledig verantwoordelijk voor het downloaden, installeren en gebruiken ervan. Als u zonder toestemming een bewakingsapparaat kiest, kan iSeeGuard niet verantwoordelijk worden gehouden. Alle rechten die hierin niet uitdrukkelijk worden verleend, zijn voorbehouden aan iSeeGuard.",
    Refuse: "Weigeren",
    DialOut: "Bellen",
    IncomingCall: "Binnenkomend",
    RenewDialogTips: "De demo kan niet verder worden bediend. U kunt zich registreren voor een account en een abonnement kopen om te genieten van alle geavanceerde bewakingsfuncties.",
    Permanent: "Eeuwigdurend",
    Capacity: "Capaciteit",
    Clipboard: "Klembord",
    SensitiveWord: "Gevoelige Woorden",
    AppUsed: "Gebruikte app",
    AppUsedToday: "Gebruikte app vandaag",
    Add: "Toevoegen",
    Modify: "Aanpassen",
    Operate: "Bedienen",
    MacStepTitle2: "Installeer de software",
    MacStepTitle3: "voltooi de configuratie",
    MacStepTitle5: "Installatie voltooien",
    MacStepOneDesc: "Open <strong>www.iseeguard.com</strong> met een browser op het doelapparaat en download de iSeeGuard Pro voor Mac-app.",
    MacStepTwoDesc: "<strong>Opmerking:</strong> het wordt aanbevolen om het rechtstreeks te downloaden naar de computer van de persoon die u wilt controleren. Als u van tijd tot tijd fysiek toegang hebt tot de doel-MAC, kunt u deze ook downloaden en controleren op uw eigen computer.",
    MacStepThreeDesc: "Klik na het downloaden op het pkg-bestand om het installatieproces te starten.",
    MacStepFourDesc: "Selecteer welke u wilt controleren, klik op Volgende om door te gaan.",
    MacStepFiveDesc: "Mactrack is geïnstalleerd.",
    MacStepSixDesc: "Log in op jouw account.",
    ClickHere: "Klik hier",
    IosStepOneDesc: "Open <strong>panel.iseeguard.com/store/ios</strong> met een browser op het doelapparaat en download de iSeeGuard Pro voor iOS-app.",
    IosStepTwoDesc: "<strong>Opmerking:</strong> het wordt aanbevolen om het rechtstreeks te downloaden naar de computer van de persoon die u wilt controleren. Als u van tijd tot tijd fysiek toegang hebt tot de doel-iOS, kunt u deze ook downloaden en controleren op uw eigen computer.",
    IosStepTitle3: "Maakt een back-up van gegevens van de iTunes",
    IosStepThreeDesc: "Download de iTunes-software, sluit de te bewaken mobiele telefoon aan op de iTunes-software en maak een back-up van de gegevens.",
    IosStepTitle5: "Gegevens scannen vanaf de doel-iPhone",
    IosStepTitle6: "Analyseren van de gegevens",
    IosStepTitle7: "Begin met het volgen van doel-iPhone op de computer",
    IosStepFiveDesc: "als er back-upbestanden beschikbaar zijn op de computer, zal ISeeGuard Pro de gevonden back-up weergeven. U kunt het back-upbestand van het doelapparaat selecteren en de gegevens ervan scannen.",
    IosStepSixDesc: "Klik op Volgende en geef de pagina met analysegegevens weer. De wachttijd voor de eerste data-analyse zal langer zijn.",
    IosStepSevenDesc: "Nadat het scanproces is voltooid, wordt alle gescande informatie weergegeven op het dashboard en kunt u schakelen tussen verschillende bestandstypen en details bekijken. Ook mag u alle gegevens naar de computer exporteren.",
}

