<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import { mapActions } from "vuex";
// import { isReload } from "@/utils/common"
export default {
  name: "App",
  created() {
    this.initCurrentWidth();
    this.initCurrentTopHeight();
    this.setLocalLanguage();
  },
  methods: {
    ...mapActions('width',{
      initCurrentWidth:'resetCurrentWidth',
      initCurrentTopHeight:'resetCurrentTopHeight'
    }),
    setLocalLanguage(){
      let local = this.$i18n.locale;
      this.$store.dispatch('settings/set_language', local)
    }
  },
  // watch:{
  //   $route(to,from){
  //     console.log(from.path) // 从哪来
  //     console.log(to.path) // 到哪去
  //     var reload_path = [
  //       '/device',
  //       '/store/android-pricing',
  //       '/store/whatsapp-pricing',
  //       '/store/mac-pricing',
  //       '/store/ios-pricing'
  //     ];
  //     if(reload_path.includes(to.path) && isReload()){
  //       window.localStorage.setItem('RELOAD_EXPIRES', Date.now() + 1200); //1.2秒有效期
  //       window.location.reload(); 
  //     }
  //   }
  // }
};
</script>
