<template>
  <div>
    <!-- 大型机 -->
    <div class="header hidden-md-and-down">
      <!-- 右侧 -->
      <div class="right-check">
        <el-button v-if="isDemo" class="buy-btn" type="primary" @click="buy">
          {{ $t("BuyNow") }}
          <i class="el-icon-shopping-cart-2 el-icon--right" />
        </el-button>
        <!-- <div v-if="isDemo" class="mobile-demobtn" @click="handleMonitorBtn">
          {{ $t("ProceedNow") }}
        </div> -->
        <!-- 国际化 -->
        <div class="lang_bx">
          <Lang />
        </div>
      </div>
    </div>
    <!-- 中型机 -->
    <div class="mobile_header">
      <div class="mobile_header_box">
        <div
          class="menu_btn cursor-pointer"
          @click="setAdminSidebarShow(!getAdminSidebarShow)"
        >
          <i class="el-icon-menu" />
        </div>
        <el-button v-if="isDemo" class="buy-btn" type="primary" @click="buy">
          {{ $t("BuyNow") }}
          <i class="el-icon-shopping-cart-2 el-icon--right" />
        </el-button>
        <!-- <div class="centerIamge">
          <img src="~@/assets/images/header/logo.png" alt="" />
        </div> -->
        <div v-if="loginStatus" class="user_bx">
          <el-dropdown trigger="click" @command="handleUserCommand">
            <div
              class="flex flex-align-items-center"
              @click="userFlag = !userFlag"
            >
              <el-avatar :size="30" icon="el-icon-s-custom" alt="avatar" />
              <i
                class="el-icon-arrow-down transition-arrow"
                :class="userFlag ? 'active' : ''"
              />
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="userCenter">
                <span>{{ $t("myProduct") }}</span>
              </el-dropdown-item>
              <!-- <el-dropdown-item command="setting">
                <span>{{ $t('Profile') }}</span>
              </el-dropdown-item> -->
              <!-- <el-dropdown-item command="contactUs">
                <span>{{ $t('ContactUs') }}</span>
              </el-dropdown-item> -->
              <el-dropdown-item command="outLogin">
                <span>{{ $t("Logout") }}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div v-if="isDemo" class="monitor" @click="handleMonitorBtn">
          {{ $t("ProceedNow") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
import Lang from "@/components/header/Lang";
export default {
  name: "BackstageHead",
  components: {
    Lang,
  },
  props: {
    isDemo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userFlag: false,
    };
  },
  computed: {
    ...mapGetters("admin", {
      getAdminSidebarShow: "getAdminSidebarShow",
    }),
    ...mapGetters(["loginStatus"]),
  },
  methods: {
    ...mapMutations("admin", {
      setAdminSidebarShow: "setAdminSidebarShow",
    }),
    handleMonitorBtn() {
      this.$emit("handleMonitorBtn");
    },
    handleUserCommand(command) {
      this.userFlag = !this.userFlag;
      switch (command) {
        case "userCenter":
          this.$router.replace("/my-products");
          break;
        case "contactUs":
          break;
        case "outLogin":
          this.$store.dispatch("users/login_out").then(() => {
            this.$notify({
              title: "success",
              message: "loginOut success",
              type: "success",
              duration: 3000,
            });
            this.$router.push("/login");
          });
          break;
        default:
          break;
      }
    },
    buy() {
      this.$store.dispatch('users/set_register_email', '');
      const path = this.$route.path;
      if (path.indexOf("demo") > -1) {
        const d = path.split("/")[1];
        const t = d.split("-")[1];
        switch (t) {
          case "android":
            this.$router.push({ path: "/store/android-pricing" });
            break;
          case "whatsapp":
            this.$router.push({ path: "/store/whatsapp-pricing" });
            break;
          case "mac":
            this.$router.push({ path: "/store/mac-pricing" });
            break;
          case "windows":
            break;
          case "ios":
            break;
          case "icloud":
            break;
          default:
            break;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.user_bx {
  margin-left: 20px;
  img {
    width: 30px;
    height: 30px;
  }
}
.active {
  transform: rotate(180deg);
}
.transition-arrow {
  transition: all 0.5s;
  margin-left: 6px;
}
.mobile_header_box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}
.header {
  overflow: hidden;
  box-sizing: border-box;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  @media (max-width: 1199px) {
    display: none;
  }
  /* 大屏幕（大桌面显示器，大于等于 1200px） */
  @media (min-width: 1200px) {
    display: block;
  }
  .right-check {
    width: calc(100%);
    height: 100%;
    border-bottom: 1px solid #ffffff;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .mobile-demobtn {
      height: 35px;
      line-height: 35px;
      padding: 0px 13px;
      background-color: #347aeb;
      border: 1px solid #347aeb;
      color: #fff;
      display: inline-block;
      border-radius: 45px;
      min-width: 120px;
      text-align: center;
      cursor: pointer;
    }
    .lang_bx {
      display: inline-block;
      padding: 7px 16px;
    }
    .active {
      transform: rotate(180deg);
      transition: all 0.5s;
    }
  }
}
// 中型机
.mobile_header {
  background-color: #fff;
  box-shadow: 0 3px 10px #ededed;
  height: 60px;
  @media (max-width: 1199px) {
    display: block;
  }
  /* 大屏幕（大桌面显示器，大于等于 1200px） */
  @media (min-width: 1200px) {
    display: none;
  }
  .menu_btn {
    // float: left;
    font-size: 24px;
    line-height: 26px;
    color: #4a4a4a;
    padding: 17px 15px;
    height: 60px;
  }
  .centerIamge {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 130px;
    img {
      @media (max-width: 1199px) {
        width: 110px;
      }
    }
  }
  .monitor {
    padding: 5px 13px;
    background-color: #347aeb;
    border: 1px solid #347aeb;
    color: #fff;
    display: inline-block;
    border-radius: 45px;
    right: 20px;
    min-width: 120px;
    text-align: center;
  }
}

.buy-btn {
  height: 35px;
  padding: 0 20px !important;
  color: #fff;
  margin-left: 20px;
  margin-right: 30px;
}
</style>

<style lang="scss">
.buy-btn {
  &.el-button--primary {
    background: #ff8032 !important;
    border-color: #ff8032 !important;
    &:hover {
      background: #ff731d !important;
      border-color: #ff8032 !important;
    }
  }
}
</style>