import { getList, getFolderList,getAssignIdOriginalImageUrl } from '@/api/android/photos'
import router from '@/router'
import { getCurrentDate } from "@/utils/common"
import collect from 'collect.js';
export default {
    namespaced: true,
    state: {
        //当前父级文件夹名称
        parentFolderLocalStorageKey: "ParentFolder",
        //相册文件夹列表
        photoFolderList: [],
        //相册列表
        photoList: [],
        //相册文件夹列表分页参数
        photoFolderPageParams: {
            page: 1,
            total: 0
        },
        //相片列表分页参数
        photoListPageParams: {
            page: 1,
            total: 0
        },
        // 最后同步时间
        synchronisedTime: '',
        //屏幕截取首次提示
        captureScreenshotPrompt:false,
        //拍摄照片首次提示
        takePhotoPrompt:false,
        screenshotList:[],
        screenshotListPageParams:{
            page: 1,
            total: 0
        },
        takePhotoList:[],
        takePhotoListPageParams:{
            page: 1,
            total: 0
        },
    },
    mutations: {
        //关闭屏幕截取首次提示
        setCaptureScreenshotPrompt(state,status=false) {
            state.captureScreenshotPrompt = status;
            // Vue.set(state, 'captureScreenshotPrompt', status);
        },
        //关闭拍摄照片首次提示
        setTakePhotoPrompt(state,status=false) {
            state.takePhotoPrompt = status
            // Vue.set(state, 'takePhotoPrompt', status);
        },
        /**
      * 更新最后同步时间
      * @param {*} state
      * @param {synchronisedTime} payload
      */
        setSynchronisedTime(state) {
            state.synchronisedTime = getCurrentDate()
            // Vue.set(state, 'synchronisedTime', getCurrentDate());
        },
        /**
         * 写入相册文件夹列表
         * @param {*} state 
         * @param {*} data 
         */
        setPhotoFolderList(state, data) {
            const photoFolderList = state.photoFolderList
            data.forEach(item => { photoFolderList.push(item) })
            state.photoFolderList = photoFolderList
            // Vue.set(state, 'photoFolderList', photoFolderList)
        },
        /**
         * 写入相册列表
         * @param {*} state 
         * @param {*} data 
         */
        setPhotoList(state, data) {
            const photoList = state.photoList
            data.forEach(item => { photoList.push(item) })
            state.photoList = photoList
            // Vue.set(state, 'photoList', photoList)
        },
        /**
         * 写入截屏列表
         * @param {*} state
         * @param {*} parentFolder
         */
        SET_SCREENSHOT_LIST:(state, data)=> {
            const screenshotList = state.screenshotList
            data.forEach(item => { screenshotList.push(item) })
            state.screenshotList = screenshotList
            // Vue.set(state, 'screenshotList', screenshotList)
        },
        /**
         * 重置截屏列表
         * @param {*} state 
         * @param {*} data 
         */
        RESET_SCREENSHOT_LIST:(state) => {
            state.screenshotList = []
            // Vue.set(state, 'screenshotList', [])
        },
        /**
         * 写入截屏请求参数
         * @param {*} state 
         * @param {*} data 
         */
        SET_SCREENSHOT_LIST_PAGE_PARAMS:(state,data) => {
            if(data.current_page){
                state.screenshotListPageParams.page = data.current_page
                // Vue.set(state.screenshotListPageParams, 'page', data.current_page)
            }
            if(data.total){
                state.screenshotListPageParams.total = data.total
                // Vue.set(state.screenshotListPageParams, 'total', data.total) 
            }
        },
        /**
         * 写入拍照列表
         * @param {*} state 
         * @param {*} data 
         */
        SET_TAKE_PHOTO_LIST:(state, data)=> {
            const takePhotoList = state.takePhotoList
            data.forEach(item => { takePhotoList.push(item) })
            state.takePhotoList = takePhotoList
            // Vue.set(state, 'takePhotoList', takePhotoList)
        },
        /**
         * 写入拍照列表请求参数
         * @param {*} state 
         * @param {*} data 
         */
        SET_TAKE_PHOTO_LIST_PAGE_PARAMS: (state, data) => {
            if(data.current_page){
                state.takePhotoListPageParams.page = data.current_page
                // Vue.set(state.takePhotoListPageParams, 'page', data.current_page) 
            }
            if(data.total){
                state.takePhotoListPageParams.total = data.total
                // Vue.set(state.takePhotoListPageParams, 'total', data.total) 
            }
        },
        /**
         * 重置拍照列表
         */
        RESET_TAKE_PHOTO_LIST:(state)=> {
            state.takePhotoList = []
            // Vue.set(state, 'takePhotoList',[])
        },
        /**
         * 写入父级文件夹
         * @param {*} state 
         * @param {*} ParentFolde 
         */
        setParentFolder(state, parentFolder) {
            localStorage.setItem(state.parentFolderLocalStorageKey, parentFolder)
        },
        /**
         * 重置相册文件夹列表
         */
        resetPhotoFolderList(state) {
            state.photoFolderList = []
            // Vue.set(state, 'photoFolderList', [])
        },
        /**
         * 重置相册列表
         * @param {*} state 
         */
        resetPhotoList(state) {
            state.photoList = []
            // Vue.set(state, 'photoList', [])
        },
        /**
         * 重置相册文件夹列表分页参数
         * @param {*} state 
         */
        resetPhotoFolderPageParams(state) {
            state.photoFolderPageParams = {
                page: 1,
                total: 0
            }
            // Vue.set(state, 'photoFolderPageParams', {
            //     page: 1,
            //     total: 0
            // })
        },
        setPhotoFolderPageParams(state,data) {
            state.photoFolderPageParams = {
                total: data.total,
                page: data.current_page
            }
            // Vue.set(state,'photoFolderPageParams',{
            //     total: data.total,
            //     page: data.current_page
            // })
        },
        setPhotoFolderPageParamsPage(state,page) {
            state.photoFolderPageParams.page = page
            // Vue.set(state.photoFolderPageParams, 'page', page)
        },
        /**
         * 重置相册列表分页参数
         * @param {*} state 
         */
         resetPhotoListPageParams(state) {
            state.photoListPageParams = {
                page: 1,
                total: 0
            }
            // Vue.set(state, 'photoListPageParams', {
            //     page: 1,
            //     total: 0
            // })
        },
        setPhotoListPageParams(state,data) {
            state.photoListPageParams = {
                total: data.total,
                page: data.current_page
            }
            // Vue.set(state,'photoListPageParams',{
            //     total: data.total,
            //     page: data.current_page
            // })
        },
        setPhotoListPageParamsPage(state,page) {
            state.photoListPageParams.page = page
            // Vue.set(state.photoListPageParams, 'page', page)
        },
        updateAssignIdOriginalImageUrl(state,data) {
            let photoList = state.photoList
            const {id,original_image_url} = data
            photoList.forEach(item => {
                if(item.id === id){
                    item.original_image_url = original_image_url;
                }
            })
            state.photoList = photoList
            // Vue.set(state, 'photoList',photoList)
        }
    },
    actions: {
        /**
         * 请求获取相册文件夹列表
         * @param {*} context 
         */
        async requestPhotoFolderList(context) {
            const result = await getFolderList(router.app._route.params.equipmentId)
            context.commit('setPhotoFolderList', result.data.data)
            context.commit('setPhotoFolderPageParams', result.data)
        },
        /**
         * 请求获取相册列表
         * @param {*} context 
         * @param {*} parentFolder 
         */
        async requestPhotoList(context,data) {
            if (context.getters.getParentFolder === "") {
                router.push({ name: "PhotoList" })
            } else {
                let params = {};
                switch (data.type){
                    case 0:
                        params = context.getters.getRequestPhotoListParams
                        break;
                    case 1:
                        params = context.getters.getRequestscreenshotListParams
                        break;
                    case 2:
                        params = context.getters.getRequesttakePhotoListParams
                        break;
                    default:
                        params = {};
                        break;
                }
                if(data.parentFolder){
                    params.parent_folder = data.parentFolder
                }
                params.type = data.type
                const result = await getList(router.app._route.params.equipmentId,params)
                switch (data.type){
                    case 0:
                        context.commit('setPhotoList', result.data.data)
                        context.commit('setPhotoListPageParams', result.data)
                        break;
                    case 1:
                        context.commit('SET_SCREENSHOT_LIST', result.data.data)
                        context.commit('SET_SCREENSHOT_LIST_PAGE_PARAMS', result.data)
                        break;
                    case 2:
                        context.commit('SET_TAKE_PHOTO_LIST', result.data.data)
                        context.commit('SET_TAKE_PHOTO_LIST_PAGE_PARAMS', result.data)
                        break;
                }
                
            }
        },
        /**
         * 获取指定id文件的源文件信息
         * @param {*} context 
         * @param {*} id 
         * @returns 
         */
        async requestAssignIdOriginalImageUrl(context,id) {
            const result = await getAssignIdOriginalImageUrl(router.app._route.params.equipmentId,{
                file_id:id
            })
            context.commit('updateAssignIdOriginalImageUrl',result.data)
        },
        /**
         * 写入截屏列表
         * @param {*} context 
         * @param {*} data 
         */
        setScreenshitList(context,data) {
            context.commit('SET_SCREENSHOT_LIST',data)
        },
        /**
         * 写入拍照列表
         */
        setTakePhotoList(context,data) {
            context.commit('SET_TAKE_PHOTO_LIST',data)
        },
        /**
         * 写入拍照分页参数
         * @param {*} context 
         * @param {*} data 
         */
        setTakePhotoListPageParams(context,data) {
            context.commit('SET_SCREENSHOT_LIST_PAGE_PARAMS',data)
        }
    },
    getters: {
        //获取屏幕截取首次提示
        getCaptureScreenshotPrompt:state =>{
            return state.captureScreenshotPrompt
        },
        //获取拍摄照片首次提示
        getTakePhotoPrompt:state => {
            return state.takePhotoPrompt
        },
        /**
         * 获取同步时间
         * @param {*} state 
         * @returns 
         */
        getSynchronisedTime: state => {
            return state.synchronisedTime
        },
        /**
         * 获取相册文件夹列表
         * @param {*} state 
         * @returns 
         */
        getPhotoFolderList: state => {
            return state.photoFolderList
        },
        /**
         * 获取相册列表
         * @param {*} state 
         * @returns 
         */
        getPhotoList: state => {
            let data = state.photoList;
            let grouped = collect(data).groupBy("date").toArray()
            console.log(grouped);
            return grouped;
        },
        /**
         * 获取截屏列表
         * @param {*} state 
         * @returns 
         */
        getScreenshotList: state => {
            let data = state.screenshotList;
            let grouped = collect(data).groupBy("date").toArray()
            return grouped;
        },
        /**
         * 获取拍照列表
         * @param {*} state 
         * @returns 
         */
        getTakePhotoList: state => {
            let data = state.takePhotoList;
            let grouped = collect(data).groupBy("date").toArray()
            return grouped;
        },
        getParentFolderLocalStorageKey:state =>{
            return state.parentFolderLocalStorageKey
        },
        /**
         * 获取大图预览列表
         * @param {*} state 
         * @returns 
         */
        getPicturePreviewList: state => {
            return state.photoList
        },
        /**
         * 获取父级文件夹
         * @param {*} state 
         * @returns 
         */
        getParentFolder: state => {
            return localStorage.getItem(state.parentFolderLocalStorageKey)
        },
        /**
         * 获取请求获取相册文件夹列表接口参数
         * @param {*} state 
         * @returns 
         */
        getRequestPhotoFolderListParams: state => {
            return {
                page: state.photoFolderPageParams.page
            }
        },
        /**
         * 获取请求获取相册列表接口参数
         * @param {*} state 
         * @returns 
         */
        getRequestPhotoListParams: state => {
            return {
                page: state.photoListPageParams.page,
            }
        },
        /**
         * 获取请求截屏列表接口参数
         * @param {*} state 
         * @returns 
         */
        getRequestscreenshotListParams:state => {
            return {
                page: state.screenshotListPageParams.page
            }
        },
        /**
         * 获取拍照列表接口参数
         * @param {*} state 
         * @returns 
         */
        getRequesttakePhotoListParams:state => {
            return {
                page: state.takePhotoListPageParams.page
            }
        },
        /**
         * 相册列表元数据
         * @param {*} state 
         */
        getPhotoListSourceData:state => {
            return state.photoList
        },
        /**
         * 截屏列表元数据
         * @param {*} state 
         * @returns 
         */
        getScreenshotListSourceData:state => {
            let data = []
            state.screenshotList.forEach((item)=> {
                item.original_image_url = item.thumbnail_url
                data.push(item)
            })
            return data
        },
        /**
         * 拍照列表元数据
         * @param {*} state 
         * @returns 
         */
        getTakePhotoListSourceData:state => {
            let data = []
            state.takePhotoList.forEach((item)=> {
                item.original_image_url = item.thumbnail_url
                data.push(item)
            })
            return data
        },
        getPhotoListPageParams:state => {
            return state.photoListPageParams
        },
        getScreenshotListPageParams:state => {
            return state.screenshotListPageParams
        },
        getTakePhotoListPageParams:state => {
            return state.takePhotoListPageParams
        },
        getPhotoFolderListParams:state => {
            return state.photoFolderPageParams
        }
    }
}