const SET_PERVIEWLIST = 'SET_KIDSGUARDSTYPE';
const SET_IDLIST = 'SET_IDLIST';
const SET_CURRENTID = 'SET_CURRENTID';
const SET_ADD_LONDNUM = 'SET_ADD_LONDNUM';
const SET_REDUCE_LONDNUM = 'SET_REDUCE_LONDNUM';
const SET_LOAD_SUCCESS = 'SET_LOAD_SUCCESS';
const INIT_PERVIEWLIST = 'INIT_PERVIEWLIST';

const getDefaultState = () => {
    return {
        preivewList: [],
        total: 0,
        currentId: 0,
        idList: [],
        loadNum: 0,
        loadSuccess: null,
    }
}

const state = getDefaultState();

const mutations = {
    [SET_PERVIEWLIST]: (state, data) => {
        state.preivewList = data;
        state.total = data.length;
    },
    [SET_IDLIST]: (state, data) => {
        state.idList = data;
    },
    [SET_CURRENTID]: (state, data) => {
        state.currentId = data;
    },
    [SET_ADD_LONDNUM]: (state) => {
        state.loadNum = state.loadNum + 1;
    },
    [SET_REDUCE_LONDNUM]: (state) => {
        state.loadNum = state.loadNum - 1;
    },
    [SET_LOAD_SUCCESS]: (state, data) => {
        state.loadSuccess = data;
    },
    [INIT_PERVIEWLIST]: (state) => {
        Object.assign(state, getDefaultState())
    },
}

const actions = {
    // data = { id: xxx, file: xxx }
    set_preview({ commit, state }, data) {
        return new Promise(resolve => {
            let list = state.preivewList;
            let idlist = state.idList;
            list.push(data.file)
            idlist.push(data.id)
            commit(SET_PERVIEWLIST, list);
            commit(SET_IDLIST, idlist);
            resolve(list)
        })
    },
    init_preview({ commit }) {
        return new Promise(resolve => {
            commit(INIT_PERVIEWLIST);
            resolve()
        })
    },
    set_add_londnum({ commit }) {
        return new Promise(resolve => {
            commit(SET_ADD_LONDNUM)
            commit(SET_LOAD_SUCCESS, false)
            resolve()
        })
    },
    set_reduce_londnum({ commit }) {
        return new Promise(resolve => {
            commit(SET_REDUCE_LONDNUM)
            resolve()
        })
    },
    set_load_success({ commit }, data) {
        return new Promise(resolve => {
            commit(SET_LOAD_SUCCESS, data)
            resolve()
        })
    },
    get_current_id({ commit, state }, id) {
        return new Promise(resolve => {
            const index = state.idList.findIndex((item) => item === id);
            commit(SET_CURRENTID, index)
            resolve(index)
        })
    },
    set_current_id({ commit }, data) {
        return new Promise(resolve => {
            commit(SET_CURRENTID, data)
            resolve(data)
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
