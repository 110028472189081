/*
 * @Author: withering 
 * @Date: 2021-07-03 17:12:54 
 * @Last Modified by:   withering 
 * @Last Modified time: 2021-07-03 17:12:54 
 */
import request from "@/utils/request"
/**
 * 获取相册文件夹列表
 * @param {*} equipmentId 
 * @returns 
 */
export function getFolderList(equipmentId) {
    // return request.get('/photoFolderList/'+equipmentId)
    return request({
        url: '/photoFolderList/' + equipmentId,
        method: 'get',
    })
}
/**
 * 获取相册列表
 * @param {*} equipmentId 
 * @param {*} params 
 * @returns 
 */
export function getList(equipmentId, params) {
    // return request.get('/photoList/' + equipmentId, params)
    return request({
        url: '/photoList/' + equipmentId,
        method: 'get',
        params
    })
}
/**
 * 获取指定照片文件源文件信息
 * @param {*} equipmentId 
 * @param {*} params 
 * @returns 
 */
export function getAssignIdOriginalImageUrl(equipmentId, params) {
    // return request.get('/getAssignIdOriginalImageUrl/' + equipmentId, params)
    return request({
        url: '/getAssignIdOriginalImageUrl/' + equipmentId,
        method: 'get',
        params
    })
}