import { getToken } from "@/utils/auth"
import { Notification } from 'element-ui'
import router from "@/router"
const command = {
    "SEND_FILE_UPLOAD_SCHEDULE": function(context,data) {
        if (data.params.type === 0) {
            context.dispatch('admin/filesSchedule/setFileSchedule', {
                uniqueId: data.params.uniqueId,
                schedule: data.params.schedule,
                type: "image"
            }, { root: true })

        }
        if (data.params.type === 1) {
            context.dispatch('admin/filesSchedule/setFileSchedule', {
                uniqueId: data.params.uniqueId,
                schedule: data.params.schedule,
                type: "video"
            }, { root: true })
        }
    },
    "UPLOAD_DONE_DEVICE_INFO":function(context,data){
        context.dispatch("admin/dashboard/requestData",data,{root: true})
        Notification({
            title: "success",
            type: "success",
            message: "Synchronized success",
            duration: 0
        })
    },
    "UPLOAD_DONE_SCREENSHOTS":function(context,data) {
        if(router.currentRoute.name === "CaptureScreenshots" && router.currentRoute.params.equipmentId == data.data.equipment_id && data.data.type == 1) {
            context.dispatch("admin/photos/setScreenshitList",[data.data],{root: true})
            Notification({
                title: "success",
                type: "success",
                message: "Screenshot success",
                duration: 1000
            })
        }
    },
    "UPLOAD_DONE_TALE_PHOTOS":function(context,data) {
        if(router.currentRoute.name === "TakePhotos" && router.currentRoute.params.equipmentId == data.data.equipment_id && data.data.type == 2) {
            context.dispatch("admin/photos/setTakePhotoList",[data.data],{root: true})
            Notification({
                title: "success",
                type: "success",
                message: "Take pictures success",
                duration: 1000
            })
        }
    },
}
export default {
    namespaced: true,
    state: {
        socketUrl: "wss://wsadmin.iseeguard.com:4433",
        // socketUrl: "ws://159.75.77.224:8384",
        socket: null,
    },
    mutations: {
        setSocket(state, websocket) {
            state.socket = websocket
            // Vue.set(state, 'socket', websocket)
        }
    },
    getters: {
        getSocket: state => {
            return state.socket
        }
    },
    actions: {
        initWebSocket(context) {
            context.commit('setSocket', new WebSocket(context.state.socketUrl + '?token=' + getToken()))
            context.state.socket.onopen = function (e) {
                context.dispatch('wsOpen', e)
            }
            context.state.socket.onclose = function (e) {
                context.dispatch('wsClose', e)
            }
            context.state.socket.onmessage = function (msg) {
                context.dispatch('wsMsg', msg)
            }
            context.state.socket.onerror = function (e) {
                context.dispatch('wsError', e);
            }
        },
        wsOpen(context, e) {
            console.log(e)
        },
        wsMsg(context, msg) {
            const data = JSON.parse(msg.data)
            console.log(data)
            if(data.type === "@heart@"){
                //心跳检测
            }else{
                command[data.type](context,data);
            }
        },
        wsClose(context, e) {
            console.log('连接关闭')
            console.log(e)
        },
        wsError(context, e) {
            console.log('错误')
            console.log(e)
        },
        sendMessage(context, msg) {
            context.state.socket.send(msg)
        }
    }
}