/*
 * @Author: withering 
 * @Date: 2021-07-03 16:10:23 
 * @Last Modified by:   withering 
 * @Last Modified time: 2021-07-03 16:10:23 
 */
import request from "@/utils/request"
/**
 * 获取通话记录列表
 * @param {*} equipmentId 
 * @param {*} params 
 * @returns 
 */
export function getList(equipmentId, params) {
    // return request.get('/callLogs/'+equipmentId,params);
    return request({
        url: '/callLogs/' + equipmentId,
        method: 'get',
        params
    })
}