/*
 * @Author: withering 
 * @Date: 2021-07-27 11:27:31 
 * @Last Modified by: withering
 * @Last Modified time: 2021-08-13 16:51:25
 */

import request from "@/utils/request"
import qs from "qs"
/**
 * 登录
 * @param {*} params 
 * @returns 
 */
export function login(params) {
    // return request.post('/login', params);
    return request({
        url: '/login',
        method: 'post',
        data: qs.stringify(params)
    })
}
/**
 * 注册
 * @param {*} email 邮箱
 * @param {*} password 密码
 * @param {*} passwordConfirmation 确认密码
 * @returns 
 */
export function register(data) {
    // return request.post('/register', data);
    return request({
        url: '/register',
        method: 'post',
        data: qs.stringify(data)
    })
}

/**
 * 修改 用户信息 邮箱/密码
 * @param {*} notice_email 邮箱
 * @param {*} password 密码
 * @returns 
 */
export function changeNotificationInfo(data) {
    return request({
        url: '/user',
        method: 'put',
        data: qs.stringify(data)
    })
}

// 获取详细信息 -- 设置 修改账号密码的信息
export function getNotificationInfo(data) {
    return request({
        url: '/user',
        method: 'get',
        params: {
            equipmentId: data.equipmentId
        }
    })
}

// 获取用户信息
export function getUserInfo() {
    return request({
        url: '/user/info',
        method: 'get'
    })
}
