/*
 * @Author: withering 
 * @Date: 2021-07-03 16:17:40 
 * @Last Modified by:   withering 
 * @Last Modified time: 2021-07-03 16:17:40 
 */
import request from "@/utils/request"
/**
 * 导出内容
 * @param {*} equipmentId 
 * @param {*} params 
 * @returns 
 */
export function exportData(equipmentId, params = {}) {
    // return request.post('/export/' + equipmentId, params);
    return request({
        url: '/export/' + equipmentId,
        method: 'post',
        params
    })
}