import { getList } from "@/api/android/wifi"
import router from "@/router"
import {getAddressInfo} from "@/utils/mapboxRequest"
import { getCurrentDate } from "@/utils/common"
export default {
    namespaced: true,
    state: {
        wifiList: [],
        //分页参数
        pageParams: {
            page: 1,
            total: 0,
            type: 0,
            search:""
        },
        // 最后同步时间
        synchronisedTime: ''
    },
    mutations: {
        /**
       * 更新最后同步时间
       * @param {*} state
       * @param {synchronisedTime} payload
       */
         setSynchronisedTime(state) {
             state.synchronisedTime = getCurrentDate()
            // Vue.set(state, 'synchronisedTime', getCurrentDate());
        },
        /**
         * 写入wifi列表
         * @param {*} state 
         * @param {*} data 
         */
        setWifiList(state, data) {
            let wifiList = state.wifiList
            data.forEach((item) => { wifiList.push(item) })
            state.wifiList = wifiList
            // Vue.set(state, 'wifiList', wifiList)
        },
        /**
         * 重置wifi列表
         * @param {*} state 
         */
        resetWifiList(state) {
            state.wifiList = []
            // Vue.set(state, 'wifiList', [])
        },
        resetPageParams(state) {
            state.pageParams.page = 1
            state.pageParams.total = 0
            // Vue.set(state.pageParams, 'page',1)
            // Vue.set(state.pageParams, 'total',0)
        },
        /**
         * 写入分页参数
         * @param {*} state 
         * @param {*} data 
         */
        setPageParams(state, data) {
            state.pageParams.page = data.current_page
            state.pageParams.total = data.total
            // Vue.set(state.pageParams, 'page',data.current_page)
            // Vue.set(state.pageParams, 'total',data.total)
        },
        setPageParamsPage(state,page) {
            state.pageParams.page = page
            // Vue.set(state.pageParams, 'page',page)
        },
        setPageParamsPageSearch(state,search) {
            state.pageParams.search = search
            // Vue.set(state.pageParams, 'search',search)
        }
    },
    actions: {
        /**
         * 请求获取wifi日志列表
         * @param {*} context 
         */
        async requestWifiList(context,paramsData = []) {
            let params = {}
            params = context.getters.getRequestWifiListParams;
            paramsData.forEach((item) => {params[item.key] = item.value });
            const result = await getList(router.app._route.params.equipmentId,params);
            let data = result.data.data;
            Promise.all([data.forEach(item =>{
                item.place_name = "---"
                getAddressInfo(item.longitude,item.latitude).then(result =>{
                    if(result.data.features.length > 0){
                        item.place_name = result.data.features[0].place_name
                    }
                })
            })]).then(()=>{
                context.commit('setWifiList', data)
                context.commit('setPageParams',result.data)
            })
        }
    },
    getters: {
        /**
         * 获取wifi日志列表请求参数
         * @param {*} state 
         * @returns 
         */
        getRequestWifiListParams: state => {
            return {
                page: state.pageParams.page,
                search:state.pageParams.search
            }
        },
        /**
         * 获取wifi日志列表
         * @param {*} state 
         * @returns 
         */
        getWifiList: state => {
            return state.wifiList;
        },
        getPageParams: state => {
            return state.pageParams
        },
        /**
         * 获取同步时间
         * @param {*} state 
         * @returns 
         */
         getSynchronisedTime: state => {
            return state.synchronisedTime
        },
    }
}