export default {
    CreateAccount: "Criar Conta",
    Email: "Email",
    Password: "Palavra-Passe",
    ConfirmPas: "Confirmar Palavra-Passe",
    SignUp: "Registar",
    AleadyAccount: "Já tem uma conta?",
    Login: "Entrar",
    Required: "Este campo é obrigatório.",
    NotEmail: "O endereço de e-mail não é válido.",
    TwoPass: "Inseriu duas senhas diferentes.",
    SixOne: "A senha deve ter de 6 a 16 caracteres.",
    Alphabets: "A senha deve conter dígitos e alfabetos.",
    Space: "A senha não pode conter um espaço em branco.",
    Symbols: "A senha pode conter caracteres apenas de dígitos, alfabetos ou símbolos.",
    SignLoad: "A registar…",
    AgainTry: "A inscrição falhou. Por favor, tente novamente.",
    EmailExists: "O email já existe.",
    SupportToMonitor: "Pode monitorar:",
    SignIn: "Entrar",
    IncorrectOr: "Usuário ou palavra-passe incorretos.",
    Forpwd: "Esqueceu-se da sua palavra-passe?",
    Signtry: "O registo falhou. Por favor, tente novamente.",
    Restpwd: "Redefina a sua palavra-passe:",
    SendLink: "Enviar Link de Redefinição",
    ReturnTo: "Retornar ao",
    DontExit: "O endereço de email não existe.",
    CheckEmail: "Por Favor Verifique o Seu Email",
    JustFold: "Acabamos de enviar um link de redefinição da sua palavra-passe para o endereço de e-mail fornecido. Se não o encontrar na caixa de entrada, por favor verifique o spam ou lixo eletrónico.",
    TwoHour: 'Se você não receber o email nas próximas 24 horas, entre em contacto com a nossa equipa de suporte através do endereço de email <a href="mailto:support@iSeeGuard.com">support@iSeeGuard.com</a>',
    UnknowError: "Erro Desconhecido",
    TryOp: "Ups. Acabou de ocorrer um erro. Por favor, tente novamente.",
    Retry: "Tentar novamente",
    CreatNewPwd: "Criar uma Nova Palavra-passe",
    NewPwd: "Nova Palavra-passe",
    Confirm: "Confirmar ",
    OldPwd: "Inseriu a palavra-passe antiga. Por favor, altere para outra.",
    NewPwdSucess: "Nova palavra-passe criada com sucesso!",
    ThePage: "A página será direcionada para a página de login em 5 segundos.",
    ProceedNow: "Continuar Agora",
    UnknowTry: "Erro desconhecido. Por favor tente novamente.",
    LinkExpired: "O link expirou. Por favor tente novamente.",
    ProfileSett: "Configurações do perfil",
    Download: "Baixe o aplicativo iSeeGuard Pro no dispositivo de destino",
    Back: "Voltar",
    Next: "Próximo",
    Dashboard: "Painel de Controlo",
    PhoneLogs: "Registos do Telefone",
    CallLogs: "Registo de Chamadas",
    Messages: "Mensagens",
    Contacts: "Contatos",
    Locations: "Localizações",
    Geofence: "Geofence",
    BrowserHistory: "Histórico do Navegador",
    Photos: "Fotos",
    VideoPreview: "Pré-visualização de Vídeo",
    CaptureScreenshots: "Recorte de Ecrã",
    AppActivity: "Atividades de Aplicações",
    WiFiLogger: "Logger Wi-Fi",
    Keylogger: "Keylogger",
    Calendar: "Calendário",
    SocialApps: "Apps Sociais",
    DataExport: "Exportação de Dados",
    Settings: "Definições",
    Sync: "Sincronizar",
    Updated: "Atualizado: ",
    DataLoad: "Pode demorar algum tempo a sincronizar os dados do telefone. Por favor, seja paciente...",
    DatanNotWhy: "Por que razão o iSeeGuard Pro não consegue sincronizar os novos dados?",
    DataUpdata: "Antes de mais, precisa de saber que a app iSeeGuard Pro instalada no dispositivo-alvo não sincroniza novos dados imediatamente, mas recolhe sim os dados a cada 15 minutos. Sempre que entra na conta do iSeeGuard Pro ou clica no ícone Sync, os dados começarão a ser atualizados. O tempo necessário para sincronizar os dados depende do tamanho dos dados e da velocidade da ligação à Internet do dispositivo-alvo.",
    DataNum: "Alguns dados apenas serão carregados através de uma rede Wi-Fi: fotos, pré-visualização de vídeos, gravações de chamadas, capturas de ecrã de apps sociais, registador Wi-Fi e atividades de apps. Outros dados serão carregados através da rede 3G, 4G ou Wi-Fi: localizações, informações do dispositivo, keylogger, capturas manuais de ecrã, registos de chamadas, mensagens, contatos, calendários, geolocalização, histórico do browser e histórico de chats de apps sociais.",
    DataG: "Abaixo poderá encontrar a razão pela qual não consegue ver quaisquer dados atualizados. ",
    Dataup: "O dispositivo-alvo está desligado. ",
    DataOff: "O dispositivo-alvo não está ligado à rede ou a rede está instável. ",
    Datastrong: "A permissão de Acessibilidade do iSeeGuard Pro está desativada no dispositivo-alvo. Se sim, entre em Configurações > Acessibilidade > encontre o System Update Service e ative. ",
    Datauninstall: " A permissão de armazenamento do iSeeGuard Pro está desativada no dispositivo-alvo. Se sim, entre em Configurações > Apps > System Update Service > Permissões > permitir o acesso da app ao armazenamento.",
    Dataassint: "A app assistente do iSeeGuard Pro está inativa ou foi desinstalada no dispositivo-alvo. Se sim, reinicie o telefone-alvo ou reinstale a app.",
    ActInfo: "Informações da Conta",
    ActId: "ID da conta",
    NotEml: "E-mail de notificação",
    DvcInfo: "Informação do Dispositivo",
    DvcName: "Nome do Dispositivo",
    DvcStatus: "Situação do Dispositivo",
    Online: "Online",
    Offline: "Offline",
    Plan: "Plano",
    ExpDate: "Data de Validade",
    AutoRel: "Renovação Automática",
    Disable: "Desativado",
    Enable: "Ativado",
    DvcMod: "Modelo do Dispositivo",
    Dvcson: "Versão OS do Dispositivo",
    GPS: "GPS",
    On: "On",
    Off: "Off",
    BatLev: "Nível de bateria",
    WiFi: "Wi-fi",
    TopCal: "Top 8 chamadas",
    TopMes: "Top 8 Mensagens",
    LastLoca: "Último local conhecido",
    AppVersion: "Versão do aplicativo",
    UpdateBtn: "Atualizar",
    SureUpdate: "Quer mesmo fazer o update à app agora?",
    SureUpdateTips: 'Após clicar no botão "Sim" abaixo, verá no dispositivo-alvo um pedido para aprovar o update da app. Certifique-se de que tem o dispositivo de destino, caso contrário, ele exporá o fato de que o dispositivo está sendo monitorado.',
    UpdateSuccess: "A atualização está sendo baixada automaticamente. Verifique o processo e aprove a instalação no dispositivo de destino.",
    UseLastetVersion: "Você está usando a versão mais recente deste aplicativo. Por favor, verifique novamente para atualizações mais tarde.",
    InUpdating: "O aplicativo está sendo atualizado e você pode verificar o processo no dispositivo de destino. Aguarde alguns minutos.",
    UpdateFailed: "A atualização falhou porque o dispositivo de destino não está conectado à rede ou o aplicativo está temporariamente sem resposta.",
    Name: "Nome",
    PhoneNumber: "Número de telefone",
    Type: "Tipo",
    Incoming: "Recebida",
    Cancelled: "Cancelado",
    Outgoing: "Enviada",
    Duration: "Duração",
    Date: "Data",
    All: "Todos",
    Today: "Hoje",
    ThisMonth: "Este mês",
    LastMonth: "Mês passado",
    LastYear: "Ano passado",
    Search: "Procurar",
    To: "Para",
    Phone: "Telefone",
    Work: "Trabalho",
    Home: "Casa",
    Others: "Outros",
    Mail: "Mail",
    Birthday: "Aniversários",
    Anniversary: "Data Especial",
    Address: "Endereço",
    LonLat: "Longitude e Latitude",
    LocTime: "Hora da Localização",
    MapView: "Visão do Mapa",
    FailSync: "Falha na sincronização. Por favor, tente novamente.",
    GenerateDes: "Gere uma geofence, para que seja notificado se o seu filho sair de uma área designada.",
    GenerateEff: "Gere Geofences Convenientemente e Eficientemente",
    GenerateCir: "Gere uma geofence em torno de uma área designada circulando um local específico.",
    StayAlt: "Mantenha-se informado com os alertas da Geofence",
    WhGeo: "Quando o seu filho atravessar a geofence, você receberá um alerta por e-mail.",
    Start: "Iniciar",
    SureDelete: "Tem certeza de que deseja eliminar esta geofence?",
    Yes: "Sim",
    No: "Não",
    MaxT: "Máximo de 10 itens.",
    Ok: "OK",
    NameGeofc: "Dê um nome à Geofence",
    Setlon: "Definir Local Restrito",
    SearchAd: "Endereço de Pesquisa",
    SetRadius: "Definir Raio",
    Meters: "Raio da geofence em metros (1000m máx.)",
    GuardTime: "Definir Tempo de Guarda",
    SetNow: "Definir Agora >",
    SetAlt: "Definir Alertas via Email de Notificação",
    Save: "Guardar",
    Cancel: "Cancelar",
    StartTime: "Hora de Início",
    EndTime: "Hora de Fim",
    Repeat: "Repetir",
    EverySun: "Cada domingo",
    EveryMon: "Cada segunda",
    EveryTue: "Cada terça",
    EveryWed: "Cada quarta",
    EveryThu: "Cada quinta",
    EveryFri: "Cada sexta",
    EverySat: "Cada sábado",
    PlsName: "Por favor, defina um nome para a geofence.",
    Plsloca: "Por favor, defina um local.",
    PlsTime: "Por favor, defina o tempo de guarda.",
    ThreeName: "Já existe uma geofence com o mesmo nome.",
    LastVtne: "Tempo da Última Visita",
    URL: "URL",
    Title: "Título",
    Frequency: "Frequência",
    Visits: "Visitas",
    HowFeat: "Como usar este recurso?",
    MakeAct: "Certifique-se de que o dispositivo alvo está ativo.",
    ClickBt: "Clique no botão “Fazer Recorte de Ecrã”.",
    VuShot: "Ver os recortes de ecrã.",
    NotLock: "Nota: Esse recurso é desativado quando a tela do telefone de destino está bloqueada.",
    ScReenPow: "A captura de tela falhou porque a tela do telefone de destino está bloqueada ou o aplicativo está temporariamente sem resposta. Por favor tente novamente mais tarde.",
    TakScr: "Fazer Recortes de Ecrã",
    Version: "Versão",
    Size: "Tamanho",
    InstDate: "Data Instalada",
    TodayFreq: "Frequência de Hoje",
    TodayScreenTime: "Tempo de Ecrã de Hoje",
    ClickKey: 'Clique no botão "Iniciar" e aguarde os keyloggers sincronizados.',
    ViewScr: "Ver os recortes de ecrã.",
    KidTxy: "O iSeeGuard Pro pode ajudá-lo a monitorizar as teclas digitadas detectadas no dispositivo Android monitorizado. Cada toque no teclado vem com um ícone da aplicação indicando de onde provém.",
    NotDat: "Sem Resultados",
    Event: "Evento",
    EventLocation: "Local do evento",
    Note: "Notas",
    AppNotif: "Notificações da Aplicação",
    Modules: "Módulos",
    Time: "Tempo",
    Format: "Formato",
    Export: "Exportar",
    DeleteAndExport: "Eliminar os dados exportados do painel",
    CancelSub: "Cancelar Assinatura",
    UnbindDevice: "Desvincular Dispositivo",
    ContactSupport: "Suporte ao Cliente",
    SureRenewal: "Tem a certeza de que deseja cancelar a renovação automática?",
    SureUnbind: "Tem a certeza de que deseja desvincular este dispositivo?",
    PersonalPrivacy: "Para proteger a sua privacidade pessoal, o iSeeGuard Pro irá parar de monitorizar e eliminará todos os dados.",
    UnboundDvc: "Parabéns! Desvinculou o dispositivo com sucesso!",
    Profile: "Perfil",
    ContactUs: "Contate-nos",
    Logout: "Sair",
    Device: "Dispositivo",
    Month: "Mês",
    BuyNow: "Comprar Agora",
    AddNew: "Ad. Novo",
    Unactivated: "Desativado",
    VersionUpdate: "Existem novas atualizações disponíveis. Se for possível, desinstale a versão anterior do telefone de destino, reinicie o telefone e baixe a versão mais recente.",
    LearnMore: "Saiba mais",
    HowToUpdate: "Como fazer o update da app no dispositivo-alvo?",
    UpdateTips1: "Prossiga para Meus produtos> Configurações> Desvincular dispositivo.",
    UpdateTips2: "Pegue no dispositivo-alvo, entre em Definições > Apps> encontre o System Update Service e desinstale-o em primeiro lugar.",
    UpdateTips3: "De seguida, abra www.iseeguard.com no browser e faça o download da app. ",
    UpdateTips4: "Finalmente, siga as instruções na tela para finalizar a configuração.",
    WhatsappUpdateTips1: "Pegue no dispositivo-alvo, entre em Definições > Apps> encontre o WhatsApp Service e desinstale-o em primeiro lugar.",
    WhatsappUpdateTips2: "De seguida, abra www.installapk.net no browser e faça o download da app. ",
    WhatsappUpdateTips3: "Finalmente, siga as instruções na tela para finalizar a configuração.",
    ThankPurchase: "Obrigado pela sua compra!",
    ThankTips: "Receberá um email de confirmação com os detalhes da sua encomenda.",
    Product: "Produtos:",
    BillingEmail: "Seu e-mail de cobrança:",
    ClickTips: "Clique em Avançar e comece a monitorar.",
    PayFailed: "O pagamento falhou",
    PayFailedTips: "Tente novamente com outro método de pagamento ou entre em contato com seu provedor de pagamento.",
    TryAgain: "Tente novamente",
    FailedReason: " Por que o pagamento falha?",
    FailedReason1: "1. Cartão expirado",
    FailedReason2: "2. Fundos insuficientes",
    FailedReason3: "3. As informações inseridas estão incorretas",
    FailedReason4: "4. Outros motivos",
    OrderReview: "Seu pedido em revisão",
    OrderReviewTips: "Seu pedido ",
    OrderReviewTips1: " foi recebido e está sendo verificado por nossa equipe.",
    OrderReviewTips2: "O pedido deve ser processado nas próximas 2 horas - a duração máxima de processamento é de 24 horas. Você receberá uma notificação por e-mail se o pagamento for bem-sucedido.",
    OrderReviewTips3: "Para evitar fazer várias solicitações de pagamento com seu cartão de crédito, não envie o pedido repetidamente.",
    ChangeNotEmail: "Alterar Email de Notificação",
    NewEmail: "Novo Email",
    NotHu: "Nota: Esta alteração aplica-se apenas ao seu email de notificação.O e-mail de login permanece inalterado.",
    SendMailTse: "Enviamos um e-mail de confirmação para o novo endereço de e-mail de notificação. Se não receber este e-mail, clique no botão abaixo para tentar novamente ou entre em contacto através de",
    ChangeNotsuces: "Alterou o e-mail de notificação com sucesso!",
    ChangeTwo: "Inseriu o e-mail antigo. Por favor, altere para outro.",
    ChangePassword: "Alterar Palavra-passe",
    OldPassword: "Palavra-passe Antiga",
    PwdSuces: "Você alterou a palavra-passe com sucesso.",
    WrongOldPwd: "Palavra-passe antiga errada.",
    TheNewCode: "A palavra-passe nova é igual à antiga. Por favor, tente novamente.",
    NoMore: "Nunca mais",
    ReNew: "Renovar",
    CheckExported: "Não há dados para exportar. Por favor verifique e tente novamente.",
    FailedCheck: "Falha na exportação. Devido a problemas de compatibilidade do navegador, os dados ainda não podem ser descarregados através do Firefox ou do navegador Edge. Por favor, use o Chrome, Safari ou Opera e tente novamente.",
    ScShot: "Recorte de ecrã",
    AddGeofence: "Adicione uma geofence",
    WifILocation: "Localização Wifi",
    footCot: "Copyright © 2021 iSeeGuard.com. Todos os direitos reservados.",
    Supcenter: "Centro Apoio",
    ProductFAQs: "FAQs Produto",
    AccountFAQs: "FAQs Contas",
    PurchaseFAQs: "FAQs Compras",
    iSeeGuardStore: "iSeeGuard Loja",
    Polide: "Política",
    privPli: "Política de Privacidade",
    cokPli: "Política de Cookies",
    refndPli: "Politica de Reembolso",
    elu: "EULA",
    TermsCot: "Termos e Condições",
    FoloUs: "Siga-nos",
    Paymet: "Pagamento",
    prodt: "Produtos",
    suport: "Apoio",
    Load: "Carregando...",
    NotFund: "Ups! Página não encontrada.",
    IagreThe: "Concordo com o",
    GlevEu: "EULA iSeeGuard",
    PriPoly: "Política de Privacidade",
    OurGuat: "A Nossa Garantia",
    secure: "Seguro",
    finalcoft: "Nós valorizamos a sua privacidade. As suas informações pessoais ou financeiras serão mantidas em sigilo.",
    twoBack: "30 Dias - Reembolso",
    provideCase: "Oferecemos garantia de reembolso de 30 dias para todos os produtos, caso não esteja completamente satisfeito.",
    cancelAnt: "Cancele a qualquer momento",
    cancelNeed: "Você pode sempre cancelar a assinatura se não precisar mais do produto.",
    FastSup: "Suporte Rápido",
    EnquriedProblem: "A nossa equipa oferece Atendimento ao Cliente 24/5 para ajudar com quaisquer dúvidas e problemas.",
    AsUcess: "Desculpe, a verificação falhou. Por favor, certifique-se de que a aplicação iSeeGuard Pro Assistant foi instalado no dispositivo de destino com sucesso.",
    ExportTip: "A exportação de dados não é suportada em dispositivos móveis. Faça logon no painel online iSeeGuard no seu computador e exporte os dados específicos.",
    Expired: "Expirado",
    Features: "Características",
    WifiUpload: "* Por favor, note que as imagens só serão enviadas via WiFi.",
    WhatsAppTips: "O aplicativo de monitoramento WhatsApp dedicado está aqui. Explore agora >>",
    ScOne: "Ocorreu um erro ao guardar os recortes de ecrã no dispositivo de destino. Por favor, tente novamente mais tarde.",
    ScTwo: "Ocorreu um erro ao carregar os recortes de ecrã. Por favor, tente novamente mais tarde.",
    Anpay: "Pagamento online seguro",
    OldEmail: "Email antigo:",
    ExtendSubscript: "Estender Assinatura",
    Troubleshoot: "Solução de problemas",
    PermissionsCheck: "Solução de problemas",
    LogAgin: "Se a aplicação não estiver a funcionar corretamente no telemóvel de destino, pode ser porque a permissão está desativada. Vá às Configurações e ative-a novamente para que a aplicação volte a funcionar.",
    SubmitLog: "Para resolver melhor o seu problema, pode enviar-nos o ficheiro de log de erros.",
    Accessibility: "Acessibilidade",
    Administrator: "Administrador",
    CallLog: "Registo de chamadas",
    Location: "Localização",
    Notification: "Notificação",
    SMS: "SMS",
    Storage: "Armazenamento",
    UsageStatus: "Status de Utilização",
    AccessCamera: "Câmera",
    Microphone: "Microfone",
    Submit: "Enviar",
    LogExpried: "O log de erros foi enviado com sucesso.",
    LogMailOne: "Pode contactar-nos para",
    Spt: "support@iSeeGuard.com",
    SptFst: "para um apoio mais rápido.",
    Permission: "Permissões",
    Status: "Status",
    OnlyOne: "Somente 1000 registros podem ser exportados por vez.",
    LogSucess: "Ocorreu um erro desconhecido.",
    LogUnkow: "Envie novamente em 10 minutos. Ou você pode entrar em contato conosco via",
    LogTwoUnkow: "para obter suporte mais rápido.",
    Items: "Quantidade",
    ViewSchot: "Exibir capturas de tela históricas",
    verifyIcloudAccount: "Verifique as credenciais do iCloud",
    icloudId: "Insira o ID do iCloud do dispositivo alvo que pretende monitorizar.",
    anotherIcloud: "Por favor, tente novamente ou",
    useOther: "use outra conta do iCloud.",
    oldIcloud: "Você inseriu uma conta antiga do iCloud.",
    accoundLock: "Conta do iCloud Bloqueada",
    lockedAgain: "Esta conta do iCloud foi bloqueada pela Apple por razões de segurança. Tente novamente dentro de 12 horas.",
    twoFactor: "Autenticação de dois fatores",
    phoneSend: "Foi enviada uma mensagem com um código de verificação para o dispositivo alvo. Por favor, insira o código para verificar a conta.",
    dontGetCode: "Não recebeu um código de verificação?",
    sixCode: "Com a autenticação de dois fatores ativada, você precisará de acesso físico ao dispositivo de destino para obter um código de verificação de seis dígitos.",
    resendCode: "Reenviar Código",
    textMe: "Receber Mensagem",
    needHelp: "Preciso de ajuda?",
    notSendCode: "Falha ao enviar o código de verificação, por favor tente novamente.",
    noVerification: "Código de verificação incorreto.",
    noRecordsFound: "Não Foram Encontrados Registos!",
    newPricePlan: "Verificámos que inseriu uma conta diferente do iCloud que não corresponde aos nossos registos. Se pretende monitorizar a conta recém-inserida, exclua a conta anterior do iCloud ou compre um novo plano.",
    deleteAccount: "Eliminar Conta",
    icloudError: "Erro de verificação do iCloud",
    aginNormally: "Ocorreu um erro ao verificar a conta alvo do iCloud. Alguns dados podem não ser atualizados. Verifique novamente para poder usar o iSeeGuard Pro normalmente.",
    notNow: "Mais tarde",
    verifyIcloud: "Verificar iCloud",
    icloudAccount: "Conta do iCloud",
    icloudStorage: "Armazenamento do iCloud",
    deviceName: "Nome do Dispositivo",
    deviceModel: "Modelo do Dispositivo",
    seeMore: "Ver mais",
    sureTargetTwo: "Verifique se o recurso Localizar iPhone está ativado no dispositivo alvo.",
    location: "Localização",
    list: "Lista",
    sureDeleteAccount: "Tem a certeza de que deseja excluir a conta do iCloud?",
    removeThat: "Todos os registos e dados relacionados com esta conta do iCloud serão removidos depois disso.",
    isRemove: "Parabéns! Esta conta do iCloud foi removida com sucesso.",
    Reminder: "Lembretes",
    iCloudDrive: "iCloud Drive",
    appleIdErr: "O ID ou palavra-passe da Apple está incorreta.",
    selectTrustePhone: "Selecione Número de Telefone Confiável",
    serverErr: "Ocorreu um erro no servidor. Por favor, tente novamente mais tarde.",
    continue: "Continuar",
    textMessage: "Mensagem de texto",
    codeMany: "O código de segurança foi incorretamente digitado demasiadas vezes.",
    downloadPage: "Demora em média 2-3 minutos para baixar um arquivo Pages, Numbers ou Keynote. Você tem certeza que quer continuar?",
    Tips: "Dicas",
    Verify: "Verificar",
    icloudLok: "A conta do iCloud foi bloqueada ou a senha está incorreta.",
    veriErr: "Falha na verificação",
    notereminderTip: "O iCloud parou de responder. Alguns dados estão temporariamente indisponíveis. Por favor, tente novamente mais tarde.",
    Video: "Vídeos",
    forAndroid: "Monitore Android",
    foriOs: "Monitorar iOS",
    tryError: "Ocorreu um erro desconhecido.Por favor, tente novamente mais tarde.",
    nodata: "Sem dados",
    ForAndroid: "Para Android",
    PhoneFiles: "Ficheiros do telefone",
    ClickShoot: 'Clique no botão “Fotografar".',
    LocationTracking: "Rastreamento de local",
    RemoteControl: "Controlo remoto",
    TakePhotos: "Tirar fotos",
    TakeNote: "Nota: Você só pode tirar fotos com a câmera traseira. Esta funcionalidade não é recomendada nas seguintes situações:",
    Shoot: "Fotografar",
    ShootErrorFirst: "A sua operação é muito frequente. Aguarde um momento e opere novamente.",
    ShootErrorTwo: "Falha ao tirar uma foto porque o dispositivo de destino está desligado ou a aplicação está temporariamente sem responder. Por favor, tente novamente mais tarde.",
    ShootErrorFour: "A permissão para o acesso à câmera foi desativada no dispositivo de destino. Vá a Configurações> Aplicativos> System Update Service> Permissões e ative-a novamente.",
    ShootErrorFiveAndSeven: " Falha ao iniciar a câmera porque está a ser utilizada por outro programa.",
    ShootErrorEight: "Ocorreu um erro ao salvar as fotos no dispositivo de destino. Por favor, tente novamente mais tarde.",
    ShootErrorNineAnTen: "Ocorreu um erro ao carregar as fotos. Por favor, tente novamente mais tarde.",
    NoteWhenFirst: "Quando a câmera está ocupada;",
    NoteWhenTwo: "Quando o telefone de destino está voltado para cima num objeto plano;",
    NoteWhenThree: "Quando o telefone de destino está num bolso ou mala.",
    RecordCalls: "Gravação de Chamadas",
    RecordsOutgoing: "Grava todas as chamadas recebidas e efetuadas em dispositivos Android remotamente",
    AutomaticallyRecord: "Grave Chamadas Telefónicas Automaticamente",
    RemotelyOnline: "Oiça Todas as Chamadas Online Remotamente",
    WifiOnline: "Faça o upload dos arquivos de áudio para a conta online quando uma conexão Wi-Fi estiver disponível",
    NoteTime: "Nota: esta funcionalidade pode gravar até 20 minutos de chamadas de cada vez.",
    Audio: "Áudio",
    DownloadSimple: "Download",
    TakeShootVersion: "A versão atual é muito antiga. Atualize o aplicativo para a versão mais recente no telefone de destino para usar esse recurso.",
    RecordTarget: "Grave conversas telefónicas no telefone de destino e salve como arquivos de áudio ocultos",
    ViewTake: "Veja as fotos carregadas.",
    balnkExport: "Em branco",
    iphoneAudio: "O navegador iOS não suporta a reprodução de gravações de chamadas. Por favor, opere no seu computador.",
    IncludedFeatures: "Recursos Incluídos",
    AppVideos: "Vídeos de Aplicativos",
    AppPhotos: "Fotos de Aplicativos",
    SafariHistory: "História do Safari",
    SafariBookmarks: "Favoritos do Safari",
    VoiceMemos: "Gravações de Voz",
    ViewAllFollowing: "Ver todos os dados a seguir no aplicativo iSeeGuard pro no computador de destino",
    OpeniSeeGuardProApp: "Abrir o Aplicativo iSeeGuard Pro",
    NoteInstalled: "Nota: Você pode clicar no botão direito para abrir rapidamente a interface do aplicativo. Se o aplicativo não aparecer, inicie-o manualmente no computador em que está instalado.",
    NoteFiles: "Nota: Você precisa acessar o computador de destino e iniciar o aplicativo para verificar todos os arquivos.",
    NotSet: "Não definido",
    MonitorAndroid: "Monitorar 1 dispositivo Android. (Sem raiz)",
    MonitoriOS: "Monitore 1 dispositivo iOS. (Sem jailbreak)",
    MonitorICloud: "Monitore 1 conta do iCloud. (Sem jailbreak e nenhum aplicativo instalado)",
    ChooseAndroid: "Escolha o seu plano iSeeGuard Pro para Android",
    ChooseMac: "Escolha o seu plano iSeeGuard Pro para Mac",
    ChooseIOS: "Escolha o seu plano iSeeGuard Pro para iOS",
    ChooseICloud: "Escolha o seu plano iSeeGuard Pro para iCloud",
    ChooseWhatsApp: "Escolha o seu plano iSeeGuard Pro para WhatsApp",
    ChooseWindows: "Escolha o seu plano Monivisor para Windows",
    BuyDesc: "Renovação automática.",
    BuyTips1: "A licença será automaticamente renovada dentro de um mês. Pode cancelá-la em qualquer altura na página das definições do produto.",
    BuyTips2: "A licença será automaticamente renovada dentro de três meses. Pode cancelá-la em qualquer altura na página das definições do produto.",
    BuyTips3: "A licença será automaticamente renovada dentro de um ano. Pode cancelá-la em qualquer altura na página das definições do produto.",
    IcartPay: "Cartão de crédito",
    ShowAll: "Mostrar Tudo",
    WaitMsg: "Por favor, espere. Estamos processando seu pedido.",
    MonitoriOSremotely: "Desculpe, o cancelamento falhou. Por favor, tente novamente.",
    tryAgain: "Por favor, tente novamente.",
    resiterSucess: "Registrado com sucesso!",
    resiterTxt: "Parabéns! A conta iSeeGuard foi criada com sucesso!",
    selectPt: "Selecione o produto que você precisa",
    Availablefor: "Disponível para",
    ThankYou: "Obrigada!",
    ThankYouTips: "Sua submissão foi recebida.",
    androidMonite: "Comece já a monitorizar. Não precisa de fazer de aceder à root dos seus telefones Android!",
    iosMonite: "Comece já a monitorizar. Não precisa de fazer o jailbreak de iPhones ou iPads!",
    iCloudMonite: "Comece já a monitorizar. Sem jailbreak ou instalação de apps!",
    myPorductOrder: "Gestão dos Meus Produtos e Pedidos",
    expireSoon: "Expira em breve",
    invisibleMode: "Uma app de monitorização Android de última geração que funciona no modo invisível.",
    moniteFeatures: "Tem mais de 30 recursos de monitorização. Não exige acesso à root.",
    overTwoIos: "Mais de 20 recursos avançados de monitorização para iOS, incluindo: WhatsApp, histórico de chamadas, textos, etc.",
    overTwoMac: "Mais de 20 recursos avançados de monitorização para Mac, incluindo: WhatsApp, histórico de chamadas, textos, etc.",
    noLike: "Não é preciso fazer operações complexas como jailbreak. O início é muito simples. ",
    userIcloud: "Monitorize qualquer iPhone ou iPad de forma remota online usando as credenciais iCloud. ",
    noNeedInstallApp: "Não é preciso instalar a app no dispositivo iOS alvo, é 100% seguro. ",
    myProduct: "Os Meus Produtos",
    accountOrderInfo: "Informação de Conta & Pedidos",
    orderInfo: "Informação de Pedidos",
    unableSync: "Não é possível temporariamente sincronizar os dados. Os dados começarão a ser sincronizados após o telefone-alvo ser ligado com uma conexão à internet e quando o assistente iSeeGuard Pro estiver a ser executado no dispositivo-alvo.",
    productFAQ: "FAQ Acerca dos Produtos",
    path: "Caminho",
    goSetZero: "Entre em Configurações > Apps > System Update Service > Permissões > Câmera > garanta que está ligado",
    goSetOne: "Entre em Configurações > Acessibilidade > System Update Service > garanta que está ligado",
    goSetTwo: "Entre em Configurações > procure Segurança > Outras Configurações de segurança > Administrador de Dispositivos > System Update Service > garanta que está ligado",
    goSetThree: "Entre em Configurações > Apps > System Update Service > Permissões > Calendário > garanta que está ligado",
    goSetFour: "Entre em Configurações > Apps > encontre o System Update Service > Permissões > Registros de chamadas > garanta que está ligado",
    goSetFive: "Entre em Configurações > Apps > encontre o System Update Service > Permissões > Contatos > garanta que está ligado",
    goSetSix: "Entre em Configurações > Apps > System Update Service > Permissões > Localizações > garanta que está ligado",
    goSetSop: "Entre em Configurações > Apps > System Update Service > Permissões > Microfone > garanta que está ligado",
    goSetSeven: "Entre em Configurações > Apps > System Update Service > Notificações > garanta que está ligado",
    goSetEight: "Entre em Configurações > Apps > encontre o System Update Service > Permissões > SMS > garanta que está ligado",
    goSetNight: "Entre em Configurações > Apps > System Update Service > Permissões > Armazenamento > garanta que está ligado",
    goSetTwent: "Entre em Configurações > encontre a Segurança > Outras Configurações de segurança > Acesso aos dados de utilização > System Update Service > garanta que está ligado",
    followStep: "Siga os passos abaixo para adicionar o telefone que quer monitorizar",
    followIos: "Siga os passos abaixo para adicionar o iOS que quer monitorizar",
    followMac: "Siga os passos abaixo para adicionar o Mac que quer monitorizar",
    beforeStart: "Antes de começar",
    youNeedDevice: "1. Pode precisar de ter acesso físico ao dispositivo-alvo. ",
    notiLLegal: "2. Não utilize este produto para fins ilegais.",
    physicalDevice: "1. Necessita de acesso físico ao dispositivo. ",
    illegalPurposes: "2. Não utilize este produto para fins ilegais.",
    videoInstruction: "Instrução em vídeo",
    VerifyInstall: 'Consulte o guia de vídeo para concluir a instalação e a configuração da app no telefone-alvo. Após isso, clique no botão "Verificar Configuração" na parte inferior para se ligar ao dispositivo-alvo e entrar no painel web.',
    textInstruction: "Instruções de Texto",
    setupStep: "Passo ",
    androidStepOne: "Faça o download da app para o telefone-alvo",
    androidStepOneDesc: 'Abra <strong style="color: #347aeb;">www.iseeguard.com</strong> num navegador no dispositivo-alvo e faça o download da app iSeeGuard Pro para Android.',
    androidStepTwo: "Instale a App",
    androidStepTwoDesc0: "Toque no ficheiro descarregado no histórico de downloads do browser ou no Gestor de Ficheiros e inicie a instalação da app.",
    androidStepTwoDesc1: "Toque no ficheiro descarregado no histórico de downloads do browser ou no Gestor de Ficheiros e inicie a instalação da app. Depois, entre na sua conta e digite as informações básicas do seu alvo.",
    androidStepTwoDesc2: "<strong>Nota:</strong>  Algumas definições terão de ser corretamente configuradas para que o iSeeGuard Pro funcione. Siga por favor as instruções no ecrã na interface da aplicação ou as orientações abaixo caso queira fazê-lo manualmente.",
    androidStepThree: "Concordo com o acordo",
    androidStepTwoToOneDesc: "Escolha <strong> Li e compreendo os termos </strong> e toque em <strong> Aceito </strong>.",
    androidStepFour: "Permissões básicas",
    androidStepTwoToTwoDesc: "Toque em <strong> Permissões básicas </strong>>> Clique em <strong> Permitir </strong> para todos os tipos de autorizações básicas.",
    androidStepFives: "Ativar acessibilidade",
    androidStepTwoToThreeDesc: "Toque em <strong> Serviço de Acessibilidade </strong>> ative <strong> Serviços do Sistema </strong>> toque em <strong> Permitir </strong>.",
    androidStepSix: "Ativar acesso à notificação",
    androidStepTwoToFourDesc: "Toque em <strong> Listener de notificação </strong>> ative <strong> Serviços do sistema </strong>> toque em <strong> Permitir </strong>.",
    androidStepSeven: "Ativar permissão de captura de tela",
    androidStepTwoToFiveDesc: "Toque em <strong> Permissão de captura de tela </strong>> ativar <strong> iSeeGuard </strong>.",
    androidStepEight: "Acesso ao uso",
    androidStepTwoToSixDesc: "Toque em <strong> Acesso ao Uso </strong>> ative <strong> Serviços do Sistema </strong>> toque em <strong> Permitir </strong>.",
    androidStepNine: "Permissão de sobreposição",
    androidStepTwoToSevenDesc: "Toque em <strong> Permissão de sobreposição </strong>> ative <strong> Serviços do sistema </strong>> toque em <strong> Permitir </strong>.",
    androidStepTen: "Desativar notificações de aplicativos",
    androidStepTwoToEightDesc: "Toque em <strong> Desativar notificações de aplicativo </strong>> ativar <strong> Serviços do sistema </strong>> desativar <strong> Mostrar alternância de notificação </strong>.",
    androidStepTwoToNineDesc: "Toque em <strong> Desativar otimização da bateria </strong>> toque em <strong> Permitir </strong> na caixa de prompt.",
    androidStepTwoToTenDesc: "Depois de concluir todas as configurações de permissão, toque em <strong> próximo </strong>. Depois disso, faça login em sua conta.",
    androidStepEleven: "Desativar Otimização de Bateria",
    androidStepTwelve: "Faça login na sua conta",
    huaWeiNote1: "<strong>Huawei</strong>: Toque no botão abaixo e encontre o Systen Update Service no Gerenciador de Tarefas. Deslize para baixo na interface do aplicativo para bloqueá-lo. Depois disso, toque na interface do aplicativo para retornar.",
    oppoNote1: "<strong>Oppo</strong>: Toque no botão abaixo e encontre o Systen Update Service no Gerenciador de Tarefas. Toque no ícone Configurações no canto superior direito. Toque em Bloquear. Depois disso, toque na interface do aplicativo para retornar.",
    xiaomiNote1: "<strong>Xiaomi</strong>: Toque no botão abaixo e encontre o Systen Update Service no Gerenciador de Tarefas. Pressione longamente a guia do aplicativo e bloqueie-o. Depois disso, toque na interface do aplicativo para retornar.",
    huaWeiNote: "<strong>Huawei</strong>: Avance para Configurações > Apps > Execução de app > encontre o System Update Service, desative-o primeiro. Depois, na janela Gerir Manualmente, ative a Execução Automática, Execução secundária e Execução no segundo plano.",
    oppoNote: "<strong>Oppo</strong>:Aceda a Configurações > Gestão de Apps > Lista de Apps > encontre o System Update Service e a Poupança de Energia > ative <strong>Executar em segundo plano</strong>, depois toque no botão  de recuar e ative <strong>Início Automático</strong> e também <strong>Permitir Execução de Outras Apps ou Serviços</strong>. Depois disso, regresse Configurações > Bateria > desativar Poupança de Energia Inteligente > Toque em Poupança de Energia Personalizada > encontre o System Update Service e ative <strong>Executar em segundo plano</strong>. Toque novamente no botão Voltar e ative <strong>Congelamento Rápido de Apps</strong>. ",
    xiaomiNote: "<strong>Xiaomi</strong>: Aceda a Configurações > Apps > Executar App > encontre o System Update Service > ative o <strong>Início Automático</strong>, depois navegue para baixo e procure a Poupança de Bateria > <strong>Sem restrições</strong>.  ",
    vivoNote: "<strong>Vivo</strong>: Aceda a Configurações > Bateria > <strong>Consumo de energia elevado em segundo plano</strong> > encontre o System Update Service > desligue-o.",
    otherNote: "<strong>Outros</strong>:  Aceda a Configurações > Apps > encontre o System Update Service > Configurações avançadas > Otimização de Bateria > encontre o System Update Service novamente e desative-o. ",
    androidStepThirteen: "Inicie a Monitorização",
    androidStepThreeDesc: 'Agora clique no botão Iniciar Monitorização e o ícone da aplicação irá desaparecer em breve. Para Android 10 e superior, o ícone da aplicação será substituído por um ícone de "Aviso". É melhor esconder este ícone numa pasta. ',
    VerifyYourSetup: "Verifique As Suas Configurações",
    nowMoniteSupportTeam: "Se tudo estiver configurado corretamente, clique no botão “Verificar Configurações” abaixo. Isto ligará o dispositivo-alvo na sua conta online. A página Atual irá saltar para o painel web, onde poderá verificar todos os ficheiros. Se não conseguir instalar a aplicação com sucesso, por favor contate a equipa de suporte.",
    iosStepOne: "Faça o download do programa para o computador-alvo",
    iosStepOneDesc: 'Visite <strong style="color: #347aeb;">www.useapp.net</strong> para fazer o download da iSeeGuard Pro para iOS no computador-alvo.',
    iosStepOneNote: "<strong>Nota:</strong> Recomendamos fazer o download diretamente para o computador da pessoa que pretende monitorizar. Se conseguir com regularidade aceder fisicamente ao iPhone alvo, também pode fazer o download e monitorizá-lo no seu próprio computador.",
    iosStepTwo: "Instale o iSeeGuard Pro para o Programa iOS",
    iosStepTwoWinDesc: "<strong>Para Utilizadores Windows: </strong>Após o download, clique no ficheiro exe para iniciar o processo de instalação.",
    iosStepTwoWinNote1: 'Tenha em atenção que nesta interface inicial pode optar por ocultar o ícone do programa. Se assinalar a opção "Não mostrar o atalho no desktop, barra de tarefas e menu iniciar", existem apenas duas formas de abrir o iSeeGuard Pro.',
    iosStepTwoWinNote2: '<strong>2.1</strong> Tenha em atenção que nesta interface inicial pode optar por ocultar o ícone do programa. Se assinalar a opção "Não mostrar o atalho no desktop, barra de tarefas e menu iniciar", existem apenas duas formas de abrir o iSeeGuard Pro.',
    iosStepTwoWinNote3: '<strong>2.2</strong> Entre no painel online do iSeeGuard Pro no browser do computador-alvo e clique em "Abrir iSeeGuard Pro App."',
    iosStepTwoMacDesc: "<strong>Para usuários de Mac：</strong>Após o download, arraste o arquivo do programa para a pasta Aplicativo. Quando a instalação estiver concluída, inicie o programa e faça login na sua conta.",
    iosStepThree: "Verifique os Dados do iPhone/iPad alvo",
    iosStepThreeToOneDesc1: "Caso não existam ficheiros de backup disponíveis no computador, será necessário ligar o dispositivo iOS alvo a este através de um cabo USB, depois terá de confirmação as informações do dispositivo e ativar as definições USB de acordo com as instruções.",
    iosStepThreeToOneDesc2: "Logo que o dispositivo-alvo for ligado com sucesso, o iSeeGuard Pro para iOS iniciará o processo de scan e análise, o que demorará algum tempo.",
    iosStepThreeToTwoDesc1: "Caso existam ficheiros de backup no computador, o iSeeGuard Pro mostrará o backup que encontrar. Pode escolher o ficheiro de backup do dispositivo-alvo e digitalizar os dados a partir dele.",
    iosStepThreeToTwoDesc2: 'Se o dispositivo que quer monitorizar não estiver na lista ou se quer somente ver os dados mais recentes, pode clicar na opção "Conectar o Dispositivo-Alvo" em rodapé.',
    iosStepThreeToThreeDesc: "Na versão mais recente, também é permitido sincronizar os dados através de Wi-Fi. Quando o iPhone e o iPad estiverem ligados à mesma rede WiFi que o computador, basta clicar no ícone WiFi na barra de navegação e depois pode seleccionar o dispositivo para sincronizar os dados remotamente.",
    iosStepThreeToThreeNote: "<strong>Nota: </strong>Para sincronizar dados por Wi-Fi, você precisa conectar o dispositivo ao PC uma vez e confiar no computador para conceder permissão.",
    iosStepFour: "Inicie a monitorização do iPhone/iPad alvo no computador",
    iosStepFourDesc: "Quando o processo de scan estiver concluído, toda a informação analisada será mostrada no painel e poderá alternar entre diferentes tipos de ficheiros e consultar os detalhes. Pode também exportar todos os dados para o computador.",
    VerifySetup: "Verificar Configurações",
    kdsAndroid: "iSeeGuard Pro para Android",
    kdsIos: "iSeeGuard Pro para iOS",
    kdsIcloud: "iSeeGuard Pro para iCloud",
    setupGuide: "Manual de instrução",
    generalSet: "Configurações Gerais",
    plseRefer: "Consulte o guia de vídeo para saber como monitorar um iPhone usando o iSeeGuard Pro. Também preparamos uma instrução de texto detalhada abaixo para sua referência.",
    locate: "Localizar",
    requestOut: "O tempo limite da solicitação.",
    onlyTxt: "Somente arquivos de texto podem ser exportados.",
    quickly: "Clique no botão abaixo para fazer login rapidamente.",
    neverWth: "Usar o iSeeGuard para rastrear o WhatsApp nunca foi tão fácil.",
    androidSytem: "A melhor app de monitorização Android com mais de 30+ funcionalidades. ",
    iOSSytem: "Um software baseado em PC que permite monitorizar um iPhone ou iPad sem jailbreaking. ",
    iCloudSytem: "Uma solução online que permite monitorizar dispositivos iOS sem instalar qualquer app.",
    WhatsSytem: "Uma app de monitorização especializada para WhatsApp para telefones Android.",
    WindowsSytem: "Um invisível software de monitorização de computadores Windows. ",
    Comingsoon: "Em breve",
    kdsMobile: "Aplicativo de segurança móvel",
    MobileSytem: "Aplicativo anti-espião de alto nível para manter seu dispositivo Android livre de spyware e stalkerware.",
    EarlBbirdTitle: "Não perca a próxima ferramenta de segurança móvel Android！",
    EarlBbirdContent: "Deixe seu e-mail e receba o cupom de desconto antecipado após o lançamento do produto.",
    ValidEmail: "Seu email válido",
    EmailSubmited: "Este email já foi enviado.",
    WindowsMonitore: "MoniVisor",
    WhatsAppMonitore: "iSeeGuard para WhatsApp",
    kdsWhatsapp: "iSeeGuard para WhatsApp",
    SpeceAndroid: "Uma app de monitorização especializada para WhatsApp para telefones Android.",
    preparedGuid: "Preparámos aqui um vídeo detalhado que o ajudará a aprender a monitorizar o WhatsApp de alguém através do iSeeGuard for WhatsApp. Abaixo pode também encontrar o guia textual passo-a-passo. ",
    DataUpload: "Upload de Dados",
    transferOver: "Transfira grandes ficheiros apenas por Wi-Fi.",
    followWhatsApp: "Siga as etapas abaixo para monitorar o WhatsApp de alguém",
    whatsappStepOneDesc: 'Aceda ao website <strong style="color: #347aeb;">www.installapk.net</strong> no browser do telefone-alvo e proceda ao download da app iSeeGuard for WhatsApp.',
    whatsappStepTwoDesc1: "Abra o ficheiro APK descarregado no histórico de downloads do navegador ou no Gestor de Ficheiros e conclua a instalação. Depois, inicie sessão na sua conta e digite as informações básicas do seu alvo.",
    whatsappStepTwoDesc2: "<strong>Nota:</strong> Algumas definições terão de ser corretamente configuradas para que o iSeeGuard para WhatsApp funcione. Siga por favor as instruções no ecrã na interface da aplicação ou as orientações abaixo caso queira fazê-lo manualmente.",
    whatsappStepTwoToOneDesc: "Toque em <strong>Avançar para Definições</strong> > o ícone das definições no canto superior direito > desative <strong>Analisar apps com Play Protect e Melhorar deteção de apps nocivas</strong> > regresse à app iSeeGuard para WhatsApp > toque em <strong>Já Definido</strong> para continuar.",
    whatsappStepTwoToTwoDesc: "Toque em <strong>Avançar para Definições</strong> > Serviços instalados > ativar <strong>WhatsApp Service</strong> > toque em <strong>Permitir</strong>.",
    whatsappStepTwoToThreeDesc: "Toque em <strong>Avançar para Configurações</strong> > localize o Acesso aos Dados de Utilização em Configurações de segurança > localize o <strong>WhatsApp Service</strong> > ative a opção <strong>Permitir rastreio de utilização</strong>.",
    whatsappStepTwoToFourDesc: "Toque em <strong>Avançar para Configurações</strong> > ative a opção <strong>WhatsApp Service</strong>.",
    whatsappStepTwoToFiveDesc: "Toque em <strong> Avançar para Configurações </strong>> ative as permissões de administrador do dispositivo para o WhatsApp Service.",
    whatsappStepTwoToSevenDesc: "Toque em  <strong>Permitir Tudo </strong> para ativar as permissões desta app a fim de obter os ficheiros WhatsApp e registar automaticamente as chamadas WhatsApp.",
    whatsappStepTwoToEightDesc: "Toque em  <strong>Ignorar</strong> , depois toque na janela pop-up, toque em  <strong>Permitir</strong>  para que o serviço WhatsApp funcione em segundo plano.",
    whatsappStepTwoToNine: "Habilite o início automático",
    whatsappStepTwoToNineDesc: "Toque em <strong>Avançar para Configurações</strong> > habilitar o <strong>serviço WhatsApp</strong>  para permitir que o aplicativo comece a ser executado assim que o dispositivo for reiniciado.",
    whatsappStepTwoToTen: "Ative a Execução em Segundo Plano (Apenas para Huawei, Oppo, Xiaomi, Vivo)",
    WhatsAppHuawei: "<strong>Huawei:</strong> Avance para Definições > Apps > Executar App > encontre o WhatsApp Service, desative-o primeiro. Depois, na janela pop-up Gerir Manualmente, ative a Execução Automática, Execução secundária e Executar em segundo plano.",
    WhatsAppOppo: "<strong>Oppo:</strong> Avance para Definições > Gestão de Apps > Lista de Apps > encontre o WhatsApp Service e Economizador de Energia > ative Executar em segundo plano, depois vá atrás e ative o Arranque Automático, permitindo também o arranque de outras apps ou serviços. Depois, regresse a Definições > Bateria > desative o Economizador de Energia Inteligente > toque em Economizador de Energia Personalizado > procure o WhatsApp Service e ative Executar em segundo plano. Toque novamente no botão regressar e ative o Congelamento Rápido de Apps. ",
    WhatsAppXiaomi: "<strong>Xiaomi:</strong> Avance para Definições > Apps > Gerir apps > encontre o WhatsApp Service > ative o Início Automático, navegue para baixo e procure Economizador de bateria > Sem restrições. ",
    WhatsAppVivo: "<strong>Vivo:</strong> Avance para Definições > Bateria > Consumo de bateria elevado em segundo plano > encontre o WhatsApp Service > desligue-o.",
    WhatsAppOthers: "<strong>Outros:</strong> Avance para Definições > Apps > encontre o WhatsApp Service > Definições avançadas > Otimização da bateria > encontre o encontre o WhatsApp Service novamente e desative-o.",
    WhatsAppHuawei1: "<strong>Huawei:</strong> Aceda a Configurações > encontre o WhatsApp Service no Gerenciador de Tarefas. Deslize para baixo na interface do aplicativo para bloqueá-lo. Depois disso, toque na interface do aplicativo para retornar.",
    WhatsAppOppo1: "<strong>Oppo: </strong> Aceda a Configurações > encontre o WhatsApp Service no Gerenciador de Tarefas. Toque no ícone Configurações no canto superior direito. Toque em Bloquear. Depois disso, toque na interface do aplicativo para retornar.",
    WhatsAppXiaomi1: "<strong>Xiaomi:</strong> Aceda a Configurações > encontre o WhatsApp Service no Gerenciador de Tarefas. Pressione longamente a guia do aplicativo e bloqueie-o. Depois disso, toque na interface do aplicativo para retornar.",
    WhatsAppStartMonite: 'Clique no botão Iniciar Monitorização e o ícone da app desaparecerá pouco depois. Para Android 10 e acima, o ícone da app será substituído por um ícone "WLAN". É melhor esconder este ícone numa pasta dissimulada.',
    WhatsAppVerify: "Verifique a Sua Configuração Após a Conclusão da Instalação",
    MoniteSupportTeam: 'Se tudo estiver corretamente configurado, clique no botão "Verificar Configuração" abaixo. Isto ligará o dispositivo-alvo à sua conta online. A página atual saltará para o painel web, onde poderá verificar todos os ficheiros. Se não conseguir instalar a app com êxito, contate a equipa de apoio.',
    DataWarn: "Aviso Sobre a Utilização de Dados",
    DataTerm: "Fazer o upload de ficheiros grandes (fotos, vídeos, áudio e documentos) através de dados móveis pode incorrer em custos de rede adicionais no seu telefone-alvo e revelar que está a seguir o WhatsApp. Tem a certeza de que pretende ainda assim fazer o upload de ficheiros multimédia utilizando os dados móveis?",
    DataTermNote: "<strong>Nota:</strong> O histórico das conversações, o estado, as chamadas e as atividades WhatsApp não estão sujeitos a esta condição. Por defeito, estes ficheiros são carregados através de dados móveis.",
    ScreenTime: "Tempo de Ecrã no WhatsApp (Últimos 7 Dias)",
    WhatsAppChats: "Conversas",
    WhatsAppCalls: "Chamadas",
    Calls: "Chamadas",
    CallRecording: "Gravação de Chamadas",
    WhatsAppStatus: "Status do WhatsApp",
    WhatsAppPhotos: "Fotos do WhatsApp",
    WhatsAppVideos: "Vídeos do WhatsApp",
    WhatsAppVoices: "Áudios do WhatsApp",
    Voices: "Áudios",
    WhatsAppDocuments: "Documentos",
    Documents: "Documentos",
    WhatsAppActivities: "Atividades",
    Activities: "Atividades",
    WhatsAppScreenshots: "Screenshots",
    Screenshots: "Screenshots",
    uploadTle: "Os ficheiros grandes (fotos, vídeos, áudios & documentos) são carregados por Wi-Fi por padrão. Você pode alterá-lo em Meus produtos> Configurações.",
    searchCallerName: "pesquisar nome do chamador",
    CallerName: "Nome do Autor da Chamada",
    onlyCover: "* O iSeeGuard para WhatsApp irá apenas fazer o upload de capas de vídeo.",
    screenFeature: "Comece com a funcionalidade de Screenshots Automáticos",
    screenFeatureOne: "Esta funcionalidade irá captar automaticamente screenshots do ecrã WhatsApp. Por isso, apenas é ativada quando o utilizador-alvo está a utilizar o WhatsApp. ",
    screenFeatureTwo: "Pode escolher quando fazer o upload dos screenshots. Ao ativar o Upload via Wi-Fi, os screenshots são captados com maior frequência, podendo isto evitar despesas adicionais com a operadora do telefone-alvo. A outra opção é precisamente o oposto.",
    UploadWiFi: "Upload via Wi-Fi",
    MoreConsumption: "Maior consumo de energia",
    fasterfrequency: "Frequência de screenshots mais rápida",
    nomoBileData: "Nenhum consumo de dados móveis",
    UploadMobileDat: "Upload via Dados Móveis",
    LessPowerConsumption: "Menor consumo de energia",
    lowerFrequencyScreenshots: "Frequência de screenshots mais baixa",
    usemobiledata: "Utiliza mais dados móveis",
    SuccessfullySet: "Configurado Com Sucesso!",
    SetSucess: "Configurou com sucesso a funcionalidade de screenshots automáticos. O upload dos screenshots começará quando o utilizador-alvo estiver a utilizar o WhatsApp e existir uma ligação de rede. Por favor seja paciente.",
    wifi: "Wi-Fi",
    wifiText: "Os screenshots do WhatsApp serão carregados através da rede Wi-Fi.",
    MobileData: "Dados Móveis",
    MobileDataText: "Os screenshots do WhatsApp serão carregados através dos dados móveis.",
    WhatsAppDownload: "Os ficheiros multimédia podem ser descarregados diretamente em cada funcionalidade.",
    tryagain: "O tempo do pedido expirou. Por favor tente novamente mais tarde.",
    beingUpload: "Os dados estão a ser carregados. Pode demorar algum tempo até mostrá-los por completo, por favor atualize a página mais tarde.",
    Chat: "Conversas",
    CallRecorde: "Gravações de Chamadas",
    Play: "Jogar",
    AudioCall: "Chamada de Áudio",
    VideoCall: "Videochamada",
    startTle: "Comece a Funcionalidade da Gravação de Chamadas WhatsApp",
    startTleOne: "<strong>1.</strong> Esta funcionalidade usa o microfone para gravar chamadas de voz/vídeo do WhatsApp, por isso poderá não ouvir a voz do outro lado a menos que o altifalante esteja ativado.",
    startTleTwo: "<strong>2.</strong> Por padrão, os ficheiros de áudio serão automaticamente carregados via Wi-Fi. Isto evita despesas adicionais nos dados móveis e protege-o contra a possibilidade de ser descoberto. Pode alterar estas definições em Os Meus Produtos - Definições > Carregamento de Dados.",
    startTleThree: "<strong>3.</strong> Esta funcionalidade pode gravar até 20 minutos em cada vez, como tal, se a duração da chamada ultrapassar os 20 minutos, será dividida em pequenos fragmentos no upload.",
    Thumbnail: "Miniatura",
    UpdateOverWifi: "Atualizar por Wi-Fi",
    wifiUpload: "Observe que arquivos grandes (fotos, vídeos, áudio e documentos) serão carregados quando o telefone de destino estiver usando a rede Wi-Fi.",
    wifiNote: "<strong>Nota:</strong> o histórico de bate-papo, status, chamadas e atividades do WhatsApp não estão sujeitos a essa condição. Por padrão, esses arquivos são carregados por dados móveis e Wi-Fi.",
    ChatContact: "Contato de bate-papo",
    Content: "Conteúdo",
    State: "Status",
    screenTime: "Tempo de Ecrã no WhatsApp (Últimos 7 Dias)",
    whatAppPathOne: "Entre em Definições > Acessibilidade > WhatsApp Service > garanta que está ligado",
    whatAppPathTwo: "Entre em Definições > procure Segurança > Outras definições de segurança > Administrador de dispositivos > WhatsApp Service > garanta que está ligado",
    whatAppPathThree: "Entre em Definições > Apps > encontre o WhatsApp Service > Permissões > Armazenamento > garanta que está ligado",
    whatAppPathFour: "Entre em Definições > Apps > encontre o WhatsApp Service > Notificações > garanta que está ligado",
    whatAppPathFive: "Entre em Definições > procure Segurança > Outras definições de segurança > Acesso aos dados de utilização > WhatsApp Service > garanta que está ligado",
    whatAppPathSix: "Entre em Definições > Apps > encontre WhatsApp Service > Permissões > Microfone > garanta que está ligado",
    whatsAppSetupError: "* Desculpe, a verificação falhou. Garanta que instalou a app iSeeGuard for WhatsApp corretamente no telefone-alvo. ",
    monitorReal: "Permite monitorar o WhatsApp de alguém como real.",
    mustFinshSet: "* Você não terminou as configurações. Escolha se está em Wi-Fi ou dados móveis para fazer o upload das capturas de tela.",
    yourEmail: "O Seu Email:",
    whatsAppDelete: "Para proteger a sua privacidade pessoal, o iSeeGuard irá parar de monitorizar e eliminará todos os dados.",
    Store: "Loja",
    monitorAndroidDevice: "Monitore dispositivos Android",
    androidMoniteName: "iSeeGuard Pro - Monitoramento Android",
    whatsAppMoniteName: "iSeeGuard - Monitoramento de WhatsApp",
    monitoriOSDevice: "Monitorar dispositivos iOS",
    iPhoneMoniteName: "iSeeGuard Pro - Monitoramento de iPhone",
    iCloudMoniteName: "iSeeGuard Pro - Monitoramento iCloud",
    cancelNote: "<strong>Nota:</strong> Ao cancelar a renovação automática, sua conta permanecerá ativa até a data de expiração. Se você quiser continuar usando nosso serviço, terá que renová-lo manualmente.",
    whatsAppSync: "Não é possível temporariamente sincronizar os dados. Os dados começarão a ser sincronizados após o telefone-alvo ser ligado com uma conexão à internet e quando o aplicativo iSeeGuard para WhatsApp estiver a ser executado no dispositivo-alvo.",
    SyncErrWhy: "Por que razão o iSeeGuard para o WhatsApp não consegue sincronizar dados de forma temporária?",
    FirstReason: "Primeiramente, o iSeeGuard para WhatsApp sincroniza automaticamente os novos dados a cada 15 minutos. Em segundo, é possível sincronizar os dados ao clicar no botão Sync em cada recurso. O tempo necessário para sincronizar os dados depende do tamanho dos dados e da velocidade da ligação à internet do dispositivo-alvo.",
    ByDefualt: "Os ficheiros grandes (fotos, vídeos, áudios & documentos) são carregados por Wi-Fi por padrão. O histórico das mensagens no chat, o estado da mensagem, as chamadas e as atividades WhatsApp não estão sujeitos a esta condição. Você pode alterá-lo em Meus produtos> Configurações.",
    ReasonExample: "O enumerado abaixo pode ser a razão caso não consiga visualizar quaisquer dados atualizados.",
    NonProductReasons: "Razões não-produto:",
    NonProductReasons1: "O dispositivo-alvo está desativado.",
    NonProductReasons2: "O dispositivo-alvo não está ligado à rede ou a rede é instável.",
    NonProductReasons3: " O WhatsApp foi desinstalado no dispositivo-alvo.",
    NonProductReasons4: "O WhatsApp não está a ser utilizado no dispositivo-alvo.",
    NonProductReasons5: "A data e hora no dispositivo-alvo são inconsistentes com a hora local. Vá a Definições > Gestão geral > Data e hora > ativar Data e hora automáticas.",
    ProductReasons: "Razões de produto:",
    ProductReasons1: "A app iSeeGuard para WhatsApp está inativa ou foi desinstalada no dispositivo-alvo. Em caso afirmativo, reinicie por favor o dispositivo-alvo ou reinstale a aplicação.",
    ProductReasons2: "Algumas permissões para a iSeeGuard para o WhatsApp estão desativadas no dispositivo-alvo, especialmente relacionadas com a Acessibilidade e Armazenamento. Por favor entre na Verificação de Permissões e siga as diretrizes para operar manualmente.",
    SelectAll: "Selecionar",
    DeleteFiles: "Eliminar Ficheiros",
    DeleteConfirm: "Quer mesmo apagar os ficheiros?",
    DeleteSuccess: "Eliminado com sucesso!",
    DownloadFiles: "Download dos Ficheiros",
    DownloadConfirm: "Quer mesmo fazer o download dos ficheiros?",
    DownloadTips: "<strong>Nota</strong>: Os ficheiros descarregados serão apagados do painel.",
    DownloadWaiting: "Poderá demorar alguns minutos a terminar, dependendo do tamanho dos ficheiros.",
    DownloadSuccess: "Download feito com êxito!",
    DownloadSuccessTips: "Pode encontrar os ficheiros descarregados no Histórico de Downloads.",
    DownloadFailed: "Falha no download. Tente novamente em uma rede estável.",
    DeleteFailed: "Falha ao eliminar. Por favor tente novamente.",
    Processing: "Em processamento...",
    ToppingTips: "clique para sinalizar esta conversa",
    MonStepFollowTitle: "Siga os Passos abaixo para Ativar o Seu Produto",
    MonStepImportantTitle: "Informação Importante:",
    MonStepTitle1: "Faça o Download do Programa para o Computador Alvo",
    MonStepTitle2: "Instale o Software e Conclua a Configuração",
    MonStepTitle3: "Instale a Extensão do Chrome (Opcional)",
    MonStepTitle4: "Entre no Painel Online para Monitorizar Agora",
    MonStepImportant1: "<b>1.</b> Precisa de acesso físico ao computador alvo.",
    MonStepImportant2: "<b>2.</b> Não utilize este produto para fins ilegais.",
    MonStepImportant3: "<b>3.</b> O MoniVisor tem duas partes: O software desktop e o painel online.",
    MonStepImportant4: "<b>4.</b> Recomendamos que adicione o software desktop à Lista de Permissões de qualquer software antivírus ou de segurança para evitar que o programa seja eliminado ou que os seus recursos não funcionem corretamente.",
    MonStepImportant5: "<b>5.</b> Recomenda-se utilizar o modo de navegação privada para fazer o download desta ferramenta ou limpar o histórico de navegação para evitar deixar quaisquer vestígios da da instalação.",
    MonStep1Desc: 'O MoniVisor for Win atua através dum software oculto baseado em computador e um painel online. Primeiro, terá de aceder fisicamente ao computador alvo e colar o URL ( <b style="color: #347aeb">http://www.installfree.net</b> ) em qualquer browser para fazer o download do software.',
    MonStep2Desc: "Depois instale o software e conclua a configuração, seguindo o guia apresentado no ecrã.",
    MonStep2Note: '<b>Nota:</b> Poderá ser-lhe apresentado uma caixa do Controlo de Acesso do Utilizador do Windows ou SmartScreen, pedindo-lhe aprovação para executar o software. Se tal lhe for pedido, clique em Sim, Executar ou Executar Sempre. Se a opção Sim ou Executar não estiver disponível, clique em "mais informações" para exibir a opção.',
    MonStep2li_1: "Será necessário algum tempo a instalar o software.",
    MonStep2li_2: "Faça ativação com a sua conta licenciada e password.",
    MonStep2li_3: "Se o programa detetar um software antivírus no computador, siga as instruções dadas para adicionar o MoniVisor for Win à Lista de Permissões. Depois disto, verá que a ferramenta passa automaticamente para o modo de execução oculta.",
    MonStep3Desc: "Se o navegador padrão do computador alvo for o Chrome, precisará de uma extensão para monitorizar o correio web (como o Gmail, Outlook ou Yahoo Mail) e mensagens em web chats (como WhatsApp, Facebook, Twitter, Instagram ou Skype). Recomendamos-lhe que instale a extensão para aumentar as capacidades de monitorização do software. Para tal, siga o guia abaixo.",
    MonStep3li_1: "Abra o navegador Chrome.",
    MonStep3li_2: "Clique na opção dos 3 pontos no canto superior direito do navegador - <b>Mais ferramentas</b> - <b>Menu Extensões</b>.",
    MonStep3li_3: "Ative o modo <b>'Developer mode'</b>, ao clicar no botão deslizante no canto superior direito do navegador.",
    MonStep3li_4: "O botão <b>'Load unpacked'</b> será exibido na barra do menu.",
    MonStep3li_5: "Clique no botão <b>'Load unpacked'</b> e depois vá a <b style=\"color: #347aeb;\">C:\\Program Files (x86)\\Windows Assistant App\\MV\\extension</b> e seleccione a pasta <b>'moni-chrome-extension'</b>.\n",
    MonStep3li_6: "A <b>'Extensão'</b>\\ está agora instalada no seu computador.",
    MonStep3li_7: "Desligue o <b>'Developer mode'</b> para completar o processo.",
    MonStep3li_8: "Se a extensão for instalada corretamente, vê-la-á na Lista de Extensões.\n",
    MonStep4Desc1: "Está agora concluída a configuração necessária no computador alvo. Verá que a ferramenta passa automaticamente para o modo de execução oculto, funcionando continuamente em segundo plano.",
    MonStep4Desc2: "Pode ir ao website iSeeGuard para iniciar sessão na sua conta em qualquer browser (no seu próprio computador, tablet ou telemóvel). Ou clique no botão Verificar Configuração abaixo para vincular rapidamente a sua conta, e a página atual irá saltar para o painel web, onde poderá verificar todos os dados. Se não conseguir instalar a aplicação com êxito, contate a equipa de apoio.",
    MonStepError: "* Desculpe, a verificação falhou. Assegure-se de que o software assistente MoniVisor foi instalado com sucesso no computador alvo.",
    MonVerifySetup: "Verificar Configuração",
    MonUninstallBigTitle: "Desinstalar o Software MoniVisor Desktop",
    MonUninstallTitle: "Apresentamos-lhe 2 formas de desinstalar o software no computador alvo. Pode desinstalar remotamente ao clicar no botão abaixo ou aceder fisicamente ao computador alvo para executar uma desinstalação manual.",
    MonUninstallNote: "Notas:",
    MonUninstallNote1: "1. A função de desinstalação remota está apenas disponível quando o dispositivo alvo se encontra online.",
    MonUninstallNote2: "2. Desinstalar o software não desvinculará a sua conta, nem apagará os dados monitorizados. Contudo, não receberá novos dados a longo prazo. Se quiser desvincular a conta, pode ir até à página Configurações e clicar no botão Desvincular.",
    MonUninstallMethod1: "Método 1: Desinstalar remotamente com um clique.",
    MonUninstallMethod1Desc: "Basta clicar no botão abaixo, e o software baseado no computador executará o comando automaticamente.",
    MonUninstallMethod2: "Método 2. Desinstalação manual.",
    MonUninstallMethod2Desc: 'A aplicação é instalado neste caminho: <b style="color: #347aeb;">C:\\Program Files (x86)\\Windows Assistant App\\MV</b>',
    MonUninstallTheStep: "Os passos são:",
    MonUninstallTheStep1: "Passo 1: Entrar no computador e digitar o caminho de instalação completo acima mencionado a fim de localizar o ficheiro.",
    MonUninstallTheStep2: 'Passo 2: Clicar no ficheiro <b style="color: #347aeb;">unins000.exe</b> para executar o comando de desinstalação.',
    MonUninstallTheStep3: "Passo 3: E pronto, só precisa de reiniciar o computador e depois pode desinstalar o software com sucesso.",
    MonUninstallError: "Desculpe, o computador alvo encontra-se de momento offline. Verifique o estado do dispositivo na sua página Painel ou em Configurações e tente novamente quando o alvo estiver online",
    MonUninstallTip: "Isto não desvinculará a sua conta, mas fará com que deixe de receber quaisquer novos dados.",
    MonUninstallProblem: "Tem a certeza que quer desinstalar o software no computador alvo?",
    MonUninstallU: "Desinstalar",
    MonUninstallSuccess: "Parabéns! Desinstalou o software com sucesso!",
    MonUnbundlingProblem: "Tem a certeza de que quer desvincular o dispositivo?",
    MonUnbundlingTip: "Por questões de segurança, todos os dados serão apagados",
    MonUnbundlingSuccess: "Parabéns! Desvinculou o software com sucesso!",
    MonInstallationHelp: "Documento de Auxílio à Instalação",
    MonInstallationDesc: "Eis o guia sobre como adicionar a pasta e ficheiros de software à Lista de Permissões do antivírus, à Lista de Confiança ou à lista de Exclusão de ficheiros e pastas para evitar que o software seja eliminado no computador alvo. O guia para instalar a Extensão MoniVisor Chrome está também incluído. Leia atentamente os passos abaixo.",
    MonInstallationHowTo1: "Como Adicionar o Software Antivírus à Lista de Permissões",
    MonInstallationHowTo2: "Como Instalar a Extensão MoniVisor Chrome",
    MonInstallationHowTo3: "Guia Sobre Como Adicionar o Software Antivírus à Lista de Permissões",
    MonInstallationStepTitle: "As instruções específicas podem diferir consoante os pacotes antivírus, mas a base do processo permanece a mesma. Abaixo estão os passos:",
    MonInstallationStep1: '<span>Passo 1:</span> Especifique onde está instalado o MoniVisor: <b style="color: #347aeb;">C:\\Program Files (x86)\\Windows Assistant App</b>',
    MonInstallationStep1Desc: 'O nome do ficheiro é: <b style="color: #347aeb;">Setupsvc.exe</b>',
    MonInstallationStep2: "<span>Passo 2:</span> Lembre-se que software antivírus está instalado no computador alvo e verifique as instruções oficiais abaixo para configurar a lista de Exceções consoante os pacotes antivírus específicos.",
    MonInstallationStep2Desc: "Guia sobre como Instalar a Extensão MoniVisor Chrome",
    MonWebChatsOfflineTip: "O computador alvo está agora offline. Nenhum dado será atualizado até ficar online.",
    MonWebChatsOfflineTipDesc: "O que está offline? Isto pode acontecer quando o dispositivo alvo se desliga da internet, quando o computador é desligado, quando o software foi desinstalado do computador alvo, ou quando o software foi eliminado por razões desconhecidas.",
    MonWebChatsNote1: 'Nota: Verifique se o computador alvo está online e se a extensão Google Chrome foi instalada corretamente. Clique <a style="color:#347aeb" href="/monivisor-installation-guide-win" target="_blank">aqui</a> para o guia completo para instalar a extensão.',
    MonScreenClickTitle: "Comece com o Recurso de Captura de Ecrã do MoniVisor",
    MonScreenClickDesc: "Clique em Iniciar para tirar capturas de ecrã do computador alvo.<br/> As capturas de ecrã serão tiradas a cada 15 segundos durante 5 minutos.",
    MonScreenClickNote: "Notas:",
    MonScreenClickNote1: "1. Esta funcionalidade apenas pode ser utilizada quando o computador alvo está online.",
    MonScreenClickNote2: "2. Pode interromper a captação de ecrã dentro de cinco minutos após o início. Se não a interromper manualmente, esta parará automaticamente após cinco minutos e depois pode recomeçar.",
    MonScreenClickError1: "O computador alvo está offline, verifique o estado do dispositivo e depois experimente esta funcionalidade quando estiver online.",
    MonScreenClickError2: "Tem a certeza de que quer apagar as capturas de ecrã?",
    MonScreenSelectAll: "Selecionar tudo",
    MonScreenDeleting: "A Eliminar...",
    MonScreenDeleteFiles: "Apagar Ficheiros",
    MonScreenSuccess1: "Sucesso! Apagou ",
    MonScreenSuccess2: " caputras de tela!",
    MonFileAction: "Ação",
    MonFileFileName: "Nome do Ficheiro",
    MonFileDate: "Data",
    MonLoginUserName: "Nome de Utilizador",
    MonLoginActivityTh: "Atividade",
    MonLoginPrinterName: "Nome da Impressora",
    MonLoginFileName: "Nome do Ficheiro",
    MonLoginPages: "Páginas",
    MonExportDataSetting: "Definição de Dados:",
    MonExportDeleteExportedData: "Eliminar Dados Exportados",
    MonExportDesc1: "1. Os ficheiros multimédia no histórico dos web chats apenas podem ser exportados sob a forma de texto.",
    MonExportDesc2: "2. Os screenshots podem ser descarregados em massa na sua página de resultados.",
    MonPurchaseBuyWin: "Escolha o seu plano MoniVisor para Windows",
    MonPurchaseGuideType2: "Software de monitorização de computador Windows Invisível.",
    MonMyProductPageDesc1: "Uma ferramenta de monitorização que permite verificar remotamente todos os ficheiros no computador Windows.",
    MonMyProductPageDesc2: "Modo de execução indetetável e painel online para ver os dados a qualquer momento e em qualquer lugar.",
    MonDashboardName: "Nome",
    MonDashboardComputerID: "ID Computador",
    MonDashboardLatestEmails: "Últimos Emails",
    MonDashboardMore: "Mais",
    MonDashboardLatestBrowsingHistories: "Últimos Históricos de Navegação",
    MonDashboardSender: "Remetente",
    MonDashboardSubject: "Assunto",
    MonDashboardDate: "Data",
    MonDashboardDomain: "Domínio",
    MonDashboardTitle: "Título",
    MonSettingsUninstallGuide: "Guia de Desinstalação",
    MonSettingsHelpDocument: "Documento de Auxílio à Instalação",
    MonSettingsSoftwareVersion: "Versão do Software no Computador Alvo",
    MonSettingsWhat: "Novidades",
    MonRequestLong: "Estamos a tratar do seu pedido. Aguarde por favor um minuto e tente novamente.",
    MonUnknown: "Desconhecido",
    MoniVisor: "MoniVisor",
    MoniVisorWebActivity: "Atividade Web",
    MoniVisorActivityChrome: "Chrome",
    MoniVisorActivityFirefox: "FireFox",
    MoniVisorActivityEdge: "Microsoft Edge",
    MoniVisorActivityIE: "Internet Explorer",
    MoniVisorActivityOpera: "Opera",
    MoniVisorDownloadHistory: "Download do Histórico",
    MoniVisorWebChats: "Web Chats",
    MonAppActivity: "Atividade de Apps",
    MonKeylogger: "Keylogger",
    MonScreenshot: "Capturas de Ecrã",
    MonScreenshotSet: "Definições de Capturas de Ecrã",
    MonScreenshotView: "Ver Capturas de Ecrã",
    MonLoginActivity: "Atividade de Login",
    MonPrintActivity: "Atividade de Impressão",
    MonUsbConnection: "Ligação USB",
    MonDataExport: "Exportação de Dados",
    MonComputer: "Computador monitor",
    MonFileActivity: "Atividade de arquivo",
    MonRemaining: "Tempo restante:",
    MonScreenIngError: "A tarefa de captura de tela já está em andamento. Por favor, tente novamente mais tarde.",
    MonDataExportOnly: "Somente 2000 registros podem ser exportados por vez.",
    MonSomeTime: "Pode levar algum tempo para sincronizar os dados. Por favor, seja paciente...",
    MonLastUsed: "Última Utilização",
    MonFilePath: "Caminho de Ficheiro",
    MonTimeUsage: "Tempo de Utilização",
    MonReceived: "Recebido",
    MonSent: "Enviado",
    MoniVisorWebChatsWhatsApp: "WhatsApp",
    MoniVisorWebChatsFacebook: "Facebook",
    MoniVisorWebChatsTwitter: "Twitter",
    MoniVisorWebChatsInstagram: "Instagram",
    MoniVisorWebChatsSkype: "Skype",
    MoniVisorWebChatsPinterest: "Pinterest",
    MonWebMail: "Correio Web",
    MonWebMailGmail: "Gmail",
    MonWebMailOutlook: "Outlook",
    MonWebMailYahoo: "Yahoo",
    MonDataExportWebActivityChrome: "Atividade Web - Chrome",
    MonDataExportWebActivityIE: "Atividade Web - IE",
    MonDataExportWebActivityEdge: "Atividade Web - Edge",
    MonDataExportWebActivityOpera: "Atividade Web - Opera",
    MonDataExportWebActivityFirefox: "Atividade Web - Firefox",
    MonDataExportDownloadHistoryChrome: "Download do Histórico - Chrome",
    MonDataExportDownloadHistoryIE: "Download do Histórico - IE",
    MonDataExportDownloadHistoryEdge: "Download do Histórico - Edge",
    MonDataExportDownloadHistoryOpera: "Download do Histórico - Opera",
    MonDataExportDownloadHistoryFirefox: "Download do Histórico - Firefox",
    MonScreenStop: "Pare",
    MonScreenMaxDownTip: "* Um máximo de 50 imagens podem ser baixadas por vez e os dados baixados serão excluídos.",
    MonWebMailAttachments: "Caminho de anexos",
    MonWebMailFrom: "De",
    MonKeyloggerTip: 'Observação: verifique se o computador de destino está online e se o software não foi eliminado por um antivírus. Clique <a style="color:#347aeb" href="/monivisor-installation-guide-win" target="_blank">aqui</a> para obter o guia completo para adicionar o software à lista de permissões do antivírus.',
    MonInsert: "Inserir",
    MonEject: "Ejetar",
    MonLogon: "Entrar",
    MonLogoff: "Sair",
    MonCreate: "Crio",
    MonCopy: "cópia de",
    MonDelete: "Excluir",
    MonRename: "Renomear",
    MonImage: "Imagem",
    MonVideo: "Vídeo",
    MonFile: "Arquivo",
    MonLocation: "Localização",
    MonPasteNote: "<b>Observação:</b> você precisa colar o caminho completo de instalação do arquivo ou permitir temporariamente a exibição de pastas ocultas no Windows File Explorer para selecionar manualmente as pastas de dentro do aplicativo antivírus. Adicione a pasta MV no caminho de instalação ou o nome de arquivo completo fornecido à lista de permissões, dependendo dos requisitos de software antivírus diferente.",
    kdsMoniVisor: "MoniVisor para Win",
    MonMisseVoice: "Chamada Perdida",
    MonMisseVideo: "Chamada Perdida",
    MonContact: "Contatos",
    MonMonitorComputers: "Monitorar computadores",
    MonWindowsMonitoring: "MoniVisor - Monitoramento do Windows",
    ParamError: "Erro de parâmetro",
    TokenError: "Login inválido",
    UserVIPExpired: "A adesão do usuário expirou ou não existe",
    NotfoundHttp: "O endereço solicitado não existe",
    ServerError: "erro de servidor",
    HeadersMiss: "O parâmetro headers está faltando",
    MonitorNow: "Monitore agora",
    AboutUs: "sobre nós",
    PrivacyPolicy: "Política de Privacidade",
    TermsConditions: "Termos & Condições",
    Disclaimer: "Isenção de responsabilidade",
    DisclaimerDesc: "SOFTWARE ISEEGUARD DESTINADO SOMENTE PARA USO LEGAL.<br />Instalar este software licenciado em um dispositivo para o qual você não possui direitos de monitoramento pode violar as leis de seu país ou região. Você é totalmente responsável por fazer o download, instalar e usá-lo. Se você escolher um dispositivo de monitoramento sem permissão, iSeeGuard não será responsabilizado. Todos os direitos não expressamente concedidos neste documento devem ser reservados a iSeeGuard.",
    Refuse: "Recusar",
    DialOut: "Chamar",
    IncomingCall: "Recebida",
    RenewDialogTips: "A demonstração não pode ser operada posteriormente. Você pode registrar uma conta e adquirir um plano para desfrutar de todos os recursos avançados de monitoramento.",
    Permanent: "Eterno",
    Capacity: "Capacidade",
    Clipboard: "Prancheta",
    SensitiveWord: "Palavras Sensíveis",
    AppUsed: "Aplicativo usado",
    AppUsedToday: "Aplicativo usado hoje",
    Add: "Adicionar",
    Modify: "Modificar",
    Operate: "Operar",
    MacStepTitle2: "Instale o Software",
    MacStepTitle3: "Conclua a Configuração",
    MacStepTitle5: "Terminar a instalação",
    MacStepOneDesc: "Abra <strong> www.iseeguard.com </strong> com um navegador no dispositivo de destino e baixe o aplicativo iSeeGuard Pro para Mac.",
    MacStepTwoDesc: "<strong> Observação: </strong> é recomendável fazer o download diretamente para o computador da pessoa que deseja monitorar. Se você puder acessar fisicamente o MAC de destino de vez em quando, também poderá fazer o download e monitorá-lo em seu próprio computador.",
    MacStepThreeDesc: "Após o download, clique no arquivo pkg para iniciar o processo de instalação.",
    MacStepFourDesc: "Selecione qual você precisa monitorar e clique em Avançar para continuar.",
    MacStepFiveDesc: "Mactrack foi instalado.",
    MacStepSixDesc: "Faça login na sua conta.",
    ClickHere: "Clique aqui",
    IosStepOneDesc: "Abra <strong> panel.iseeguard.com/store/ios </strong> com um navegador no dispositivo de destino e baixe iSeeGuard Pro para o aplicativo iOS.",
    IosStepTwoDesc: "<strong> Observação: </strong> é recomendável fazer o download diretamente para o computador da pessoa que deseja monitorar. Se você pode acessar fisicamente o iOS de destino de vez em quando, também pode fazer o download e monitorá-lo em seu próprio computador.",
    IosStepTitle3: "Backups de dados do iTunes",
    IosStepThreeDesc: "Baixe o software iTunes, conecte o telefone celular a ser monitorado ao software iTunes e execute o backup dos dados.",
    IosStepTitle5: "Digitalize os dados do iPhone de destino",
    IosStepTitle6: "Analisando os dados",
    IosStepTitle7: "Comece a monitorar o iPhone de destino no computador",
    IosStepFiveDesc: "se houver arquivos de backup disponíveis no computador, o ISeeGuard Pro exibirá o backup encontrado. Você pode selecionar o arquivo de backup do dispositivo de destino e escanear os dados dele.",
    IosStepSixDesc: "Clique em Avançar e exiba a página de dados de análise. O tempo de espera para a primeira análise de dados será maior.",
    IosStepSevenDesc: "Após a conclusão do processo de digitalização, todas as informações digitalizadas serão exibidas no painel e você pode alternar entre os diferentes tipos de arquivo e ver os detalhes. Além disso, você tem permissão para exportar todos os dados para o computador.",
}

