<template>
  <div name="copyright">
    <div class="copyright">
      {{ $t('footCot') }}
    </div>
    <div class="internationalization">
      <Lang />
    </div>
  </div>
</template>

<script>
import Lang from "@/components/header/Lang"
/**
 * 版权组件
 */
export default {
    name:"Copyright",
    components:{
      Lang
    }
}
</script>

<style lang="scss" scoped>
.copyright{
    text-align:center;
    color: #333333;
    font-size: 16px;
    padding-top: 10px;
}
.internationalization{
  margin-top:10px;
  text-align:center;
  /* 小屏幕（平板，大于等于 768px） */
  @media (max-width: 1199px) {
    display: block;
  }

  /* 大屏幕（大桌面显示器，大于等于 1200px） */
  @media (min-width: 1200px) {
    display: none;
  }
}
</style>