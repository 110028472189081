import { getKeyloggerApplicationList, getList } from "@/api/android/keyloggers"
import router from "@/router"
import { getCurrentDate } from "@/utils/common"
export default {
    namespaced: true,
    state: {
        //键盘App列表
        keyloggerAppList: [],
        //键盘App列表分页参数
        keyloggerAppPageParams: {
            page: 1,
            total: 0
        },
        //键盘记录列表
        keyloggerList: [],
        //键盘记录列表分页参数
        keyloggerListPageParams: {
            page: 1,
            total: 0,
            applicationId: ""
        },
        // 最后同步时间
        synchronisedTime: ''
    },
    mutations: {
        /**
       * 更新最后同步时间
       * @param {*} state
       * @param {synchronisedTime} payload
       */
        setSynchronisedTime(state) {
            state.synchronisedTime = getCurrentDate()
            // Vue.set(state, 'synchronisedTime', getCurrentDate());
        },
        /**
         * 写入键盘记录App列表
         * @param {*} state 
         * @param {*} data 
         */
        setKeyloggerAppList(state, data) {
            let keyloggerAppList = state.keyloggerAppList
            data.forEach(item => { keyloggerAppList.push(item) })
            state.keyloggerAppList = keyloggerAppList
            // Vue.set(state, 'keyloggerAppList', keyloggerAppList)
        },
        /**
         * 写入键盘记录列表
         * @param {*} state 
         * @param {*} data 
         */
        setKeyloggerList(state, data) {
            let keyloggerList = state.keyloggerList
            data.forEach(item => { keyloggerList.push(item) })
            state.keyloggerList = keyloggerList
            // Vue.set(state, 'keyloggerList', keyloggerList)
        },
        /**
         * 写入键盘记录分页参数
         * @param {*} state 
         * @param {*} param1 
         */
         setKeyloggerListPageParams(state, data) {
            state.keyloggerListPageParams.page = data.current_page
            state.keyloggerListPageParams.page = data.total
            // Vue.set(state.keyloggerListPageParams, 'page', data.current_page)
            // Vue.set(state.keyloggerListPageParams, 'total',data.total)
        },
        setKeyloggerListPageParamsApplicationId(state, applicationId) {
            state.keyloggerListPageParams.applicationId = applicationId
            // Vue.set(state.keyloggerListPageParams, 'applicationId', applicationId)
        },
        setsetKeyloggerListPageParamsPage(state, page) {
            state.keyloggerListPageParams.page = page
            // Vue.set(state.keyloggerListPageParams, 'page', page)
        },
         /**
         * 重置键盘记录分页参数
         * @param {*} state 
         */
          resetKeyloggerListPageParams(state) {
            state.keyloggerListPageParams = {
                page: 1,
                total: 0,
                applicationId: ""
            }
            // Vue.set(state, 'keyloggerListPageParams', {
            //     page: 1,
            //     total: 0,
            //     applicationId: ""
            // })
        },
        /**
         * 写入键盘app请求参数
         * @param {*} state 
         * @param {*} data 
         */
        setKeyloggerAppPageParams(state,data) {
            state.keyloggerAppPageParams = {
                page: data.current_page,
                total: data.total,
            }
            // Vue.set(state, 'keyloggerAppPageParams', {
            //     page: data.current_page,
            //     total: data.total,
            // })
        },
        /**
         * 写入键盘app分页请求参数
         * @param {*} state 
         * @param {*} page 
         */
        setKeyloggerAppPageParamsPage(state,page) {
            state.keyloggerAppPageParams.page = page
            // Vue.set(state.keyloggerAppPageParams, 'page', page)
        },
        /**
         * 重置键盘记录App列表
         * @param {*} state 
         */
        resetKeyloggerAppList(state) {
            state.keyloggerAppList = []
            // Vue.set(state, 'keyloggerAppList', [])
        },
        /**
         * 重置键盘记录App分页参数
         * @param {*} state 
         */
        resetKeyloggerAppPageParams(state) {
            state.keyloggerAppPageParams = {
                page: 1,
                total: 0
            }
            // Vue.set(state, 'keyloggerAppPageParams', {
            //     page: 1,
            //     total: 0
            // })
        },
        /**
         * 重置键盘记录列表
         * @param {*} state 
         */
        resetKeyloggerList(state) {
            state.keyloggerList = []
            // Vue.set(state, 'keyloggerList', [])
        }
        
    },
    actions: {
        /**
         * 请求获取键盘记录App列表
         * @param {*} context 
         */
        async requestKeyloggerAppList(context) {
            const result = await getKeyloggerApplicationList(router.app._route.params.equipmentId, context.getters.getKeyloggerAppListRequestParams)
            context.commit('setKeyloggerAppList', result.data.data)
            context.commit('setKeyloggerAppPageParams', result.data)
            
        },
        /**
         * 请求获取键盘记录列表
         * @param {*} context 
         */
        async requestKeyloggerList(context,data={}) {
            const {search} = data
            let params = context.getters.getKeyloggerListRequestParams;
            params.search = search;
            const result = await getList(router.app._route.params.equipmentId,params)
            context.commit('setKeyloggerList', result.data.data)
            context.commit('setKeyloggerListPageParams',result.data)
        }
    },
    getters: {
        /**
         * 获取同步时间
         * @param {*} state 
         * @returns 
         */
         getSynchronisedTime: state => {
            return state.synchronisedTime
        }, 
        /**
         * 获取键盘App列表
         * @param {*} state 
         * @returns 
         */
        getKeyloggerAppList: state => {
            return state.keyloggerAppList
        },
        /**
         * 获取键盘记录app列表请求参数
         * @param {*} state 
         * @returns 
         */
        getKeyloggerAppPageParams:state => {
            return state.keyloggerAppPageParams
        },
        /**
         * 获取键盘记录列表
         * @param {*} state 
         * @returns 
         */
        getKeyloggerList: state => {
            return state.keyloggerList
        },
        /**
         * 获取请求键盘记录App列表请求参数
         * @param {*} state 
         */
        getKeyloggerAppListRequestParams: state => {
            return {
                page: state.keyloggerAppPageParams.page
            }
        },
        /**
        * 获取请求键盘记录列表请求参数
        * @param {*} state 
        */
        getKeyloggerListRequestParams: state => {
            return {
                page: state.keyloggerListPageParams.page,
                applicationId: state.keyloggerListPageParams.applicationId
            }
        },
        /**
         * 获取键盘记录列表请求参数
         * @param {*} state 
         * @returns 
         */
        getKeyloggerListPageParams: state => {
            return state.keyloggerListPageParams
        }
    }
}