/*
 * @Author: withering 
 * @Date: 2021-07-03 16:07:31 
 * @Last Modified by: withering
 * @Last Modified time: 2021-07-03 16:18:25
 */
import request from "@/utils/request"
/**
 * 获取应用程序列表
 * @param {*} equipmentId 
 * @param {*} params 
 * @returns 
 */
export function getList(equipmentId, params) {
    // return request.get('/applicationList/' + equipmentId, params);
    return request({
        url: '/applicationList/' + equipmentId,
        method: 'get',
        params
    })
}