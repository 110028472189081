<template>
  <div class="lang_bx">
    <el-dropdown
      trigger="click"
      @command="handleCommand"
      @visible-change="visibleChange"
    >
      <div class="flex flex-align-items-center">
        <img class="national-flag" :src="langSelected.icon" alt="">
        <span class="toggle_true">{{ langSelected.title }}</span>
        <i
          class="el-icon-arrow-down transition-arrow"
          :class="active ? 'active' : ''"
        />
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(item, index) in langList"
          :key="index"
          :command="item"
          class="flex flex-align-items-center"
        >
          <img class="national-flag margin-right-16" :src="item.icon" alt="">
          <span>{{ item.title }}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { langList } from "@/utils/consts";
export default {
  name: "Lang",
  data() {
    return {
      langList: langList,
      langSelected: {
        title: "",
        icon: "",
        selected: false,
      },
      active: false,
    };
  },
  computed: {
    ...mapGetters(["language"]),
  },
  watch: {
    language: {
      handler(newVal) {
        for (let i = 0; i < langList.length; i++) {
          const item = langList[i];
          if (item.file == newVal) {
            this.langSelected = item;
          }
        }
      },
      deep: false,
      immediate: false,
    },
  },
  mounted() {
    for (let i = 0; i < langList.length; i++) {
      const item = langList[i];
      if (item.file == this.language) {
        this.langSelected = item;
      }
    }
  },
  methods: {
    visibleChange(e) {
      this.active = e;
    },
    handleCommand(command) {
      this.langSelected = command;
      this.$store.dispatch("settings/set_language", command.file);
    },
  },
};
</script>

<style lang="scss" scoped>
.lang_bx {
  height: 22px;
  margin-left: 20px;
  cursor: pointer;
  .toggle_true {
    color: #666;
    padding-left: 10px;
    font-size: 14px;
    vertical-align: middle;
  }
}

.transition-arrow {
  transition: all 0.5s;
  margin-left: 6px;
}

.active {
  transform: rotate(180deg);
}
</style>