import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

// ! 匹配所有modules下的index.js  既所有模块必须是 files/index.js 
const modulesFiles = require.context('./modules', true, /index\.js$/)

// 你不需要`import app from './modules/app/index.js'`
// 它将自动从模块文件中获取所有 vuex 模块
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\/index.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

const store = new Vuex.Store({
  modules,
  getters
})

export default store
