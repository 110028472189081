/*
 * @Author: withering 
 * @Date: 2021-07-03 17:27:52 
 * @Last Modified by: withering
 * @Last Modified time: 2021-08-13 15:38:28
 */
import request from "@/utils/request"

/**
 * 获取wifi日志列表
 * @param {*} equipmentId 
 * @param {*} params 
 * @returns 
 */
export function getList(equipmentId, params) {
    return request({
        url: '/wifiLoggers/' + equipmentId,
        method: 'get',
        params
    })
}