import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
import whiteList from '@/middleware/routerWhiteList' // no redirect whitelist

NProgress.configure({ showSpinner: false }) // NProgress Configurationc

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()
  if(to.matched.length === 0){
    next('/notfound')
    return
  }

  store.dispatch('settings/set_routerStack', to).then(() => {
    store.dispatch('settings/set_routerCache', to)
  })

  // set page title
  document.title = getPageTitle(to.meta.headertitle)

  // determine whether the user has logged in
  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/login' || to.path === '/') {
      // if is logged in, redirect to the home page
      next({ path: '/my-products' })
      NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const hasRoles = store.getters.roles && store.getters.roles.length > 0;
      if (hasRoles) {
        next()
      } else {
        try {
          // get user info
          // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
          // const roles = await store.dispatch('users/getUserInfo')

          // generate accessible routes map based on roles
          // const accessRoutes = await store.dispatch('permission/generateRoutes', 'android')

          // dynamically add accessible routes
          // router.addRoute(accessRoutes)

          // hack method to ensure that addRoutes is complete
          // set the replace: true, so the navigation will not leave a history record
          // next({ ...to, replace: true })

          
          
          next()
        } catch (error) {
          // remove token and go to login page to re-login
          await store.dispatch('users/login_out')
          Message.error(error || 'Has Error')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) > -1) {
      // in the free login whitelist, go directly
      // const accessRoutes = await store.dispatch('permission/generateRoutes', 'android')
      // if(Array.isArray(accessRoutes)){
      //   accessRoutes.forEach(i=>{
      //     router.addRoute(i)
      //   })
      // } else {
      //   router.addRoute(accessRoutes)
      // }

      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
