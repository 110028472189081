/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    headertitle: '', title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    cache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

const whatsapp_admin = {
    path: '/whatsapp',
    component: () => import('@/layout/app_admin/index'),
    name: 'Whatsapp-Admin',
    redirect: '/whatsapp/dashboard',
    meta: { icon: 'phone' },
    alwaysShow: true,
    children: [
        {
            path: 'dashboard',
            component: () => import('@/views/whatsapp/dashboard/index'),
            name: 'Whatsapp-Dashboard',
            meta: { headertitle: 'Whatsapp Dashboard', title: 'Dashboard', icon: 'menu-dashboard', cache: true }
        },
        {
            path: 'chats',
            component: () => import('@/views/whatsapp/chats/index'),
            name: 'Whatsapp-Chats',
            meta: { headertitle: 'Whatsapp Chats', title: 'Chat', icon: 'menu-chats', cache: false } // 有使用到preview组件的页面不能做缓存
        },
        {
            path: 'calls',
            component: () => import('@/views/whatsapp/calls/index'),
            name: 'Whatsapp-Calls',
            meta: { headertitle: 'Whatsapp Calls', title: 'Calls', icon: 'menu-call', cache: true }
        },
        {
            path: 'call-recording',
            component: () => import('@/views/whatsapp/call-recording/index'),
            name: 'Whatsapp-CallsRecording',
            meta: { headertitle: 'Whatsapp Calls', title: 'CallRecording', icon: 'menu-call-record', cache: true }
        },
        {
            path: 'status',
            component: () => import('@/views/whatsapp/status/index'),
            name: 'Whatsapp-Status',
            meta: { headertitle: 'Whatsapp Status', title: 'Status', icon: 'menu-status', cache: true }
        },
        {
            path: 'photos',
            component: () => import('@/views/whatsapp/photos/index'),
            name: 'Whatsapp-Photos',
            meta: { headertitle: 'Whatsapp Photos', title: 'Photos', icon: 'menu-photos', cache: false }
        },
        {
            path: 'videos',
            component: () => import('@/views/whatsapp/videos/index'),
            name: 'Whatsapp-Videos',
            meta: { headertitle: 'Whatsapp Videos', title: 'Video', icon: 'menu-videos', cache: false },
        },
        {
            path: 'voices',
            component: () => import('@/views/whatsapp/voices/index'),
            name: 'Whatsapp-Voices',
            meta: { headertitle: 'Whatsapp Voices', title: 'Voices', icon: 'menu-voices', cache: true }
        },
        {
            path: 'documents',
            component: () => import('@/views/whatsapp/documents/index'),
            name: 'Whatsapp-Documents',
            meta: { headertitle: 'Whatsapp Documents', title: 'Documents', icon: 'documentation', cache: false }
        },
        {
            path: 'activities',
            component: () => import('@/views/whatsapp/activities/index'),
            name: 'Whatsapp-Activities',
            meta: { headertitle: 'Whatsapp Activities', title: 'Activities', icon: 'menu-activities', cache: true }
        },
        {
            path: 'screenshots',
            component: () => import('@/views/whatsapp/screenshots/index'),
            name: 'Whatsapp-Screenshots',
            meta: { headertitle: 'Whatsapp Screenshots', title: 'Screenshots', icon: 'menu-screenshots', cache: false }
        },
        // {
        //     path: 'export',
        //     component: () => import('@/views/whatsapp/export/index'),
        //     name: 'Whatsapp-Export',
        //     meta: { headertitle: '', title: 'Data Export', icon: 'menu-export', cache: true }
        // },
    ]
}

export default whatsapp_admin;