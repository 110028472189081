import { getList,getAssignIdVideoUrl } from '@/api/android/videos'
import router from "@/router"
import { getCurrentDate } from "@/utils/common"
import collect from 'collect.js';
export default {
    namespaced: true,
    state: {
        //视频列表
        videoList: [],
        //分页参数
        pageParams: {
            page: 1,
            total: 0
        },
        // 最后同步时间
        synchronisedTime: ''
    },
    actions: {
        /**
         * 请求获取视频列表
         * @param {*} context 
         */
        async requestVideoList(context) {
            let params = context.getters.getRequestParams
            const result = await getList(router.app._route.params.equipmentId, params);
            context.commit('setVideoList', result.data.data);
            context.commit("setPageParams",result.data);
        },
        async requestAssignIdVideoUrl(context,id) {
            const result = await getAssignIdVideoUrl(router.app._route.params.equipmentId,{
                file_id:id
            })
            context.commit('updateAssignIdVideoUrl',result.data)
        }
    },
    mutations: {
        updateAssignIdVideoUrl(state,data) {
            let videoList = state.videoList
            const {id,video_url} = data
            videoList.forEach(item => {
                if(item.id === id){
                    item.video_url = video_url;
                }
            })
            state.videoList = videoList
            // Vue.set(state,'videoList',videoList)
        },
        /**
       * 更新最后同步时间
       * @param {*} state
       * @param {synchronisedTime} payload
       */
         setSynchronisedTime(state) {
             state.synchronisedTime = getCurrentDate();
            // Vue.set(state, 'synchronisedTime', getCurrentDate());
        },
        /**
         * 写人视频列表数据
         * @param {*} state 
         * @param {*} data 
         */
        setVideoList(state, data) {
            let videoList = state.videoList
            data.forEach(item => { videoList.push(item) })
            // Vue.set(state, 'videoList', videoList)
            state.videoList = videoList
        },

        /**
         * 重置视频列表
         * @param {*} state 
         */
        resetVideoList(state) {
            state.videoList = []
            // Vue.set(state, 'videoList', [])
        },

        /**
         * 重置分页参数
         * @param {*} state 
         */
        resetPageParams(state){
            state.pageParams = {
                page: 1,
                total: 0
            }
            // Vue.set(state, 'pageParams', {
            //     page: 1,
            //     total: 0
            // })
        },
        /**
         * 写入分页参数
         */
         setPageParams(state,data) {
             state.pageParams = {
                page: data.current_page,
                total: data.total
             }
            // Vue.set(state, 'pageParams', {
            //     page: data.current_page,
            //     total: data.total
            // })
         },
         setPageParamsPage(state,page) {
            state.pageParams.page = page
            // Vue.set(state.pageParams, 'page', page)
         }
    },
    getters: {
        /**
         * 获取同步时间
         * @param {*} state 
         * @returns 
         */
         getSynchronisedTime: state => {
            return state.synchronisedTime
        },
        getPicturePreviewList: state => {
            return state.videoList
        },
        /**
         * 获取视频列表
         * @param {*} state 
         * @returns 
         */
        getVideoList: state => {
            let data = state.videoList;
            let grouped = collect(data).groupBy("date").toArray()
            return grouped;
        },
        /**
         * 获取分页参数
         * @param {*} state 
         * @returns 
         */
        getPageParams:state => {
            return state.pageParams;
        },
        /**
         * 获取请求参数
         * @param {*} state 
         * @returns 
         */
        getRequestParams:state => {
            let params = {
                page:state.pageParams.page
            }
            return params;
        }
    }
}