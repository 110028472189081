/*
 * @Author: withering 
 * @Date: 2021-07-03 16:18:06 
 * @Last Modified by:   withering 
 * @Last Modified time: 2021-07-03 16:18:06 
 */
import request from '@/utils/request'
/**
 * 获取账户与订单信息
 * @param {*} equipmentId
 */
export function getUserAccountOrderInfo(equipmentId) {
  // return request.get('/userAccountOrderInfo/' + equipmentId)
  return request({
    url: '/userAccountOrderInfo/' + equipmentId,
    method: 'get',
  })
}
/**
 * 获取最常联系人
 * @param {*} equipmentId 
 * @returns 
 */
export function getMostFrequentlyContactedContacts(equipmentId) {
  // return request.get('/mostFrequentlyContactedContacts/' + equipmentId)
  return request({
    url: '/mostFrequentlyContactedContacts/' + equipmentId,
    method: 'get',
  })
}