import { getList, getDetailsList } from "@/api/android/sms"
import router from "@/router"
import { getCurrentDate } from "@/utils/common"
export default {
    namespaced: true,
    state: {
        //短信列表
        smsList: [],
        //短信详情列表
        smsDetailsList: [],
        //短信列表分页参数
        smsListPageParams: {
            page: 1,
            total: 0,
            search:""
        },
        //短信详情列表分页参数
        smsDetailsListPageParams: {
            page: 1,
            total: 0,
        },
        // 最后同步时间
        synchronisedTime: ''
    },
    mutations: {
        /**
       * 更新最后同步时间
       * @param {*} state
       * @param {synchronisedTime} payload
       */
        setSynchronisedTime(state) {
            state.synchronisedTime = getCurrentDate()
            // Vue.set(state, 'synchronisedTime', getCurrentDate());
        },
        /**
         * 写入信息列表
         * @param {*} state 
         * @param {*} data 
         */
        setSmsList(state, data) {
            let smsList = state.smsList
            data.forEach(item => { smsList.push(item) })
            state.smsList = smsList
            // Vue.set(state, 'smsList', smsList)
        },
        /**
         * 写入信息详情列表
         * @param {*} state 
         * @param {*} data 
         */
        setSmsDetailsList(state, data) {
            let smsDetailsList = state.smsDetailsList
            data.forEach(item => { smsDetailsList.push(item) })
            state.smsDetailsList = smsDetailsList
            // Vue.set(state, 'smsDetailsList', smsDetailsList)
        },
        /**
         * 重置信息列表
         * @param {*} state 
         */
        resetSmsList(state) {
            state.smsList = []
            // Vue.set(state, 'smsList', [])
        },
        /**
         * 重置信息详情列表
         * @param {*} state 
         */
        resetSmsDetailsList(state) {
            state.smsDetailsList = []
            // Vue.set(state, 'smsDetailsList',[])
        },
        /**
         * 写入指定短信详情列表分页参数
         * @param {*} state 
         * @param {phone,total,current_page} data 
         */
        setSmsDetailsListPageParams(state, data) {
            state.smsDetailsListPageParams = {
                total: data.total,
                page: data.current_page
            }
            // Vue.set(state,'smsDetailsListPageParams',{
            //     total: data.total,
            //     page: data.current_page
            // })
        },
        /**
         * 写入指定短信列表分页参数
         * @param {*} state 
         * @param {*} data 
         */
        setSmsListPageParams(state, data) {
            state.smsListPageParams = {
                total: data.total,
                page: data.current_page
            }
            // Vue.set(state,'smsListPageParams',{
            //     total: data.total,
            //     page: data.current_page
            // })
        },
        setSmsListPageParamsPage(state, page) {
            state.smsListPageParams.page = page
            // Vue.set(state.smsListPageParams, 'page',page)
        },
        setSmsListPageParamsSearch(state,search) {
            state.smsListPageParams.search = search
            // Vue.set(state.smsListPageParams, 'search',search)
        },
        /**
         * 重置指定短信详情列表分页参数
         * @param {*} state 
         */
        resetSmsDetailsListPageParams(state) {
            state.smsDetailsListPageParams = {
                total: 0,
                page: 1
            }
            // Vue.set(state,'smsDetailsListPageParams',{
            //     total: 0,
            //     page: 1
            // })
        },
        /**
         * 重置短信列表分页参数
         * @param {*} state 
         */
        resetSmsListPageParams(state) {
            state.smsListPageParams = {
                total: 0,
                page: 1
            }
            // Vue.set(state,'smsListPageParams',{
            //     total: 0,
            //     page: 1
            // })
        }

    },
    actions: {
        /**
         * 获取短信列表
         * @param {*} context 
         */
        async requestSmsList(context) {
            console.log(context.getters.getSmsListRequestParams)
            const result = await getList(router.app._route.params.equipmentId,context.getters.getSmsListRequestParams)
            context.commit('setSmsList', result.data.data);
            context.commit('setSmsListPageParams',result.data)
        },
        /**
         * 获取短信详情列表
         * @param {*} context 
         * @param {*} phone 
         */
        async requestSmsDetailsList(context, phone) {
            let params = context.getters.getsmsDetailsListPageRequestParams;
            params.phone = phone;
            const result = await getDetailsList(router.app._route.params.equipmentId, params)
            if (result.data.data.length > 0) {
                context.commit('setSmsDetailsList', result.data.data)
            }
        }
    },
    getters: {
        /**
         * 获取同步时间
         * @param {*} state 
         * @returns 
         */
        getSynchronisedTime: state => {
            return state.synchronisedTime
        },
        /**
         * 获取短信列表
         * @param {*} state 
         * @returns 
         */
        getSmsList: state => {
            return state.smsList
        },
        /**
         * 获取指定电话的短信详情列表
         * @param {*} state 
         * @returns 
         */
        getSmsDetailsList: state => {
            return state.smsDetailsList
        },
        /**
         * 获取指定短信详情列表分页请求参数
         * @param {*} state 
         * @returns 
         */
        getsmsDetailsListPageRequestParams: state => {
            return {
                page: state.smsDetailsListPageParams.page
            }
        },
        /**
         * 获取短信列表分页参数
         * @param {*} state 
         * @returns 
         */
        getSmsListPageParams:state => {
            return state.smsListPageParams
        },
        getSmsListRequestParams:state => {
            return {
                page:state.smsListPageParams.page,
                search:state.smsListPageParams.search
            }
        }
    }
}