<template>
  <el-main>
    <div class="app-main">
      <router-view :key="key" />
    </div>
  </el-main>
</template>

<script>
export default {
  name: "AppMian",
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-mian{
  box-sizing: border-box;
  height:auto;
}
</style>