import { getList } from "@/api/android/location"
import router from "@/router"
// import { getAddressInfo } from "@/utils/mapboxRequest"
import { getCurrentDate } from "@/utils/common"
export default {
    namespaced: true,
    state: {
        locationList: [],
        pageParams: {
            page: 1,
            total: 0
        },
        // 最后同步时间
        synchronisedTime: '',
    },
    mutations: {
        /**
         * 更新最后同步时间
         * @param {*} state
         * @param {synchronisedTime} payload
         */
        setSynchronisedTime(state) {
            state.synchronisedTime = getCurrentDate()
            // Vue.set(state, 'synchronisedTime', getCurrentDate());
        },
        /**
         * 写入位置列表
         * @param {*} state 
         * @param {*} data 
         */
        setLocationList(state, data) {
            let locationList = state.locationList;
            data.forEach(item => {
                locationList.push(item)
            })
            state.locationList = locationList
            // Vue.set(state, 'locationList', locationList)
        },
        /**
         * 重置位置列表
         * @param {*} state 
         */
        resetLocationList(state) {
            state.locationList = []
            // Vue.set(state, 'locationList', [])
        },
        /**
         * 重置分页参数
         * @param {*} state 
         */
        resetPageParams(state) {
            state.pageParams = {
                page: 1,
                total: 0,
                search: ""
            }
            // Vue.set(state, 'pageParams', {
            //     page: 1,
            //     total: 0,
            //     search: ""
            // })
        },
        setPageParams(state, data) {
            if(data.current_page){
                state.pageParams.page = data.current_page
                // Vue.set(state.pageParams, 'page', data.current_page)
            }
            if(data.total){
                state.pageParams.total = data.total
                // Vue.set(state.pageParams, 'total', data.total)
            }
            if(data.search){
                state.pageParams.search = data.search
                // Vue.set(state.pageParams, 'search', data.search)
            }
        }
    },
    actions: {
        /**
         * 请求获取位置列表
         * @param {*} context 
         */
        async requestLocationList(context,paramData = []) {
            let params = context.getters.getRequestLocationListParams
            paramData.forEach((item) => { params[item.key] = item.value });
            const result = await getList(router.app._route.params.equipmentId, params)
            let data = result.data.data;
            context.commit('setLocationList', data)
            context.commit('setPageParams',result.data)        
        }
    },
    getters: {
        getSynchronisedTime: state => {
            return state.synchronisedTime
        },
        /**
         * 获取位置列表
         * @param {*} state 
         * @returns 
         */
        getLocationList: state => {
            return state.locationList;
        },
        /**
         * 获取列表请求参数
         * @param {*} state 
         * @returns 
         */
        getRequestLocationListParams: state => {
            return {
                page: state.pageParams.page,
                search:state.pageParams.search,
            }
        },
        getPageParams: state => {
            return state.pageParams
        }
    }
}