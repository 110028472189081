import { getList } from '@/api/android/browserHistory'
import router from "@/router"
import { getCurrentDate } from "@/utils/common"

export default {
    namespaced: true,
    state: {
        //网络预览记录列表
        browserHistoryList: [],
        //分页参数
        pageParams: {
            page: 1,
            total: 0,
            search: ""
        },
        // 最后同步时间
        synchronisedTime: ''
    },
    mutations: {
        /**
       * 更新最后同步时间
       * @param {*} state
       * @param {synchronisedTime} payload
       */
        setSynchronisedTime(state) {
            state.synchronisedTime = getCurrentDate()
            // Vue.set(state, 'synchronisedTime', getCurrentDate());
        },
        /**
         * 写入网络预览记录列表
         * @param {*} state 
         * @param {*} data 
         */
        setBrowserHistoryList(state, data) {
            let browserHistoryList = state.browserHistoryList
            data.forEach(item => { browserHistoryList.push(item) })
            state.browserHistoryList = browserHistoryList;
            // Vue.set(state, 'browserHistoryList', browserHistoryList)
        },
        /**
         * 写入分页参数
         * @param {*} state 
         * @param {*} data 
         */
        setPageParams(state, data) {
            state.pageParams = {
                page: data.current_page,
                total: data.total,
                search: ""
            }
            // Vue.set(state, 'pageParams', {
            //     page: data.current_page,
            //     total: data.total,
            //     search: ""
            // })
        },
        setPageParamsPage(state, page) {
            state.pageParams.page = page
            // Vue.set(state.pageParams, 'page', page)
        },
        setPageParamsSearch(state, search) {
            state.pageParams.search = search
            // Vue.set(state.pageParams, 'search', search)
        },
        /**
         * 重置网络预览记录
         * @param {*} state 
         */
        resetBrowserHistoryList(state) {
            state.browserHistoryList = []
            // Vue.set(state, 'browserHistoryList', [])
        },
        /**
         * 重置分页参数
         * @param {*} state 
         */
        resetPageParams(state) {
            state.pageParams = {
                page: 1,
                total: 0
            }
            // Vue.set(state, 'pageParams', {
            //     page: 1,
            //     total: 0
            // })
        }
    },
    actions: {
        /**
         * 请求获取网络预览记录列表
         */
        async requestBrowserHistoryList(context, data = []) {
            let params = context.getters.getPageRequestParams
            data.forEach((item) => { params[item.key] = item.value });
            const result = await getList(router.app._route.params.equipmentId, params)
            context.commit('setBrowserHistoryList', result.data.data)
            context.commit('setPageParams', result.data)
        }
    },
    getters: {
        /**
         * 获取同步时间
         * @param {*} state 
         * @returns 
         */
        getSynchronisedTime: state => {
            return state.synchronisedTime
        },
        /**
         * 获取网络预览记录列表
         * @param {*} state 
         * @returns 
         */
        getBrowserHistoryList: state => {
            return state.browserHistoryList
        },
        /**
         * 获取分页请求参数
         * @param {*} state 
         * @returns 
         */
        getPageRequestParams: state => {
            return {
                page: state.pageParams.page,
                search: state.pageParams.search
            }
        },
        getPageParams: state => {
            return state.pageParams
        }
    }
}