export default {
    CreateAccount: "Créer un compte",
    Email: "Email",
    Password: "Mot de passe",
    ConfirmPas: "Confirmer le mot de passe",
    SignUp: "S'inscrire",
    AleadyAccount: "Vous avez déjà un compte ?",
    Login: "Connexion",
    Required: "Ce champ est requis.",
    NotEmail: "L'adresse email n'est pas valide.",
    TwoPass: "Vous avez entré deux mots de passe différents.",
    SixOne: "Le mot de passe doit comporter entre 6 et 16 caractères.",
    Alphabets: "Le mot de passe doit contenir des chiffres et des alphabets.",
    Space: "Le mot de passe ne peut pas contenir d'espace vide.",
    Symbols: "Le mot de passe ne peut contenir que des chiffres, des alphabets ou des symboles.",
    SignLoad: "Enregistrement",
    AgainTry: "L'inscription a échoué",
    EmailExists: "L'e-mail existe déjà.",
    SupportToMonitor: "Peut surveiller:",
    SignIn: "Se connecter",
    IncorrectOr: "Identifiant ou mot de passe incorrect.",
    Forpwd: "Mot de passe oublié ?",
    Signtry: "La connexion a échoué. Veuillez réessayer.",
    Restpwd: "Réinitialisez votre mot de passe :",
    SendLink: "Envoyer le lien de réinitialisation",
    ReturnTo: "Retour à",
    DontExit: "Cette adresse email n'existe pas.",
    CheckEmail: "Veuillez consulter vos emails",
    JustFold: "Nous venons d'envoyer un lien de réinitialisation de mot de passe à l'adresse email que vous avez spécifiée. S'il ne se trouve pas dans la boîte de réception, vérifiez votre dossier de courrier indésirable.",
    TwoHour: "Si vous ne recevez pas l'email dans les 24 prochaines heures, veuillez contacter notre équipe de support à <a href='mailto:support@iSeeGuard.com'>support@iSeeGuard.com</a>",
    UnknowError: "Erreur inconnue",
    TryOp: "Oups. Une erreur est survenue. Veuillez réessayer.",
    Retry: "Réessayer",
    CreatNewPwd: "Créer un nouveau mot de passe",
    NewPwd: "Nouveau mot de passe",
    Confirm: "Confirmer",
    OldPwd: "Vous avez saisi votre ancien mot de passe. Veuillez le modifier.",
    NewPwdSucess: "Nouveau mot de passe créé avec succès !",
    ThePage: "La page sera dirigée vers la page de connexion dans 5 secondes.",
    ProceedNow: "Procéder maintenant",
    UnknowTry: "Erreur inconnue. Veuillez réessayer.",
    LinkExpired: "Le lien a expiré. Veuillez réessayer.",
    ProfileSett: "Paramètres de profil",
    Download: "Téléchargez l'application iSeeGuard Pro sur l'appareil cible",
    Back: "Retour",
    Next: "Suivant",
    Dashboard: "Tableau de bord",
    PhoneLogs: "Historique téléphonique",
    CallLogs: "Historique des appels",
    Messages: "Messages",
    Contacts: "Contacts",
    Locations: "Emplacements",
    Geofence: "Géorepérage",
    BrowserHistory: "Historique du navigateur",
    Photos: "Photos",
    VideoPreview: "Aperçu vidéo",
    CaptureScreenshots: "Faire des captures d'écran",
    AppActivity: "Activité de l'application",
    WiFiLogger: "Enregistreur Wi-Fi",
    Keylogger: "Keylogger",
    Calendar: "Calendrier",
    SocialApps: "Applications sociales",
    DataExport: "Données d'exportation",
    Settings: "Paramètres",
    Sync: "Synchronisation",
    Updated: "Mis à jour : ",
    DataLoad: "La synchronisation des données du téléphone peut prendre un certain temps. Veuillez patienter...",
    DatanNotWhy: "Pourquoi est-ce que iSeeGuard Pro ne peut pas synchronisé les données ?",
    DataUpdata: "Premièrement, il est important de comprendre que l'application iSeeGuard Pro installée sur l'appareil ne synchronise pas directement les nouvelles données, la collecte se fait toutes les 15 minutes. À chaque fois que vous vous connectez à votre compte iSeeGuard Pro ou que vous cliquez sur l'icône de Synchronisation, les données commenceront à se synchroniser. Le temps de recevoir les nouvelles données va dépendre de la taille de ces données et de la vitesse de connexion de l'appareil ciblé.",
    DataNum: "Certaines données ne seront envoyées qu'avec une connexion WiFi comme les : photos, vidéos, enregistrement d'appels, capture d'écran sur les réseaux sociaux, connexion WiFi ainsi que les activités sur certaines applications. D'autres données seront collectées via la 3G, 4G ou le WiFi : localisations, information système, enregistreur de touches, capture d'écran manuelle, appels entrants, messages, liste des contacts, calendrier, géofence, historique du navigateur et l'historique des conversations sur les réseaux sociaux.",
    DataG: "Dans le cas où vous ne recevez aucune collecte de données mises à jour, voici les raisons possibles :",
    Dataup: "L'appareil cible est éteint.",
    DataOff: "L'appareil cible n'est pas relié au réseau ou le réseau est trop instable.",
    Datastrong: " Les permissions d'accès pour iSeeGuard Pro ont été désactivées sur l'appareil cible. Si c'est le cas, rendez-vous dans les Paramètres > Accessibilité > cherchez le Service de Mise À Jour du Système et activez-le.",
    Datauninstall: "Les permissions de stockage pour iSeeGuard Pro ont été désactivées sur l'appareil cible. Si c'est le cas, rendez-vous dans les Paramètres > Applications > Service de Mise À Jour du Système > Permissions > autorisez la possibilité de stocker pour cette application.",
    Dataassint: " L'assistant iSeeGuard Pro est inactif ou a été désinstallé sur l'appareil cible. Si c'est le cas, redémarrez l'appareil cible ou bien réinstallez le logiciel.",
    ActInfo: "Informations du compte",
    ActId: "Identifiant de compte",
    NotEml: "Notification par email",
    DvcInfo: "Informations de l'appareil",
    DvcName: "Nom de l'appareil",
    DvcStatus: "Statut de l'appareil",
    Online: "En ligne",
    Offline: "Hors ligne",
    Plan: "Forfait",
    ExpDate: "Date d'expiration",
    AutoRel: "Renouvellement automatique",
    Disable: "Désactivé",
    Enable: "Activé",
    DvcMod: "Modèle de l'appareil",
    Dvcson: "Version du système d'exploitation de l'appareil",
    GPS: "GPS",
    On: "Allumé",
    Off: "Éteint",
    BatLev: "Niveau de la batterie",
    WiFi: "WiFi",
    TopCal: "Top 8 des appels",
    TopMes: "Top 8 des messages",
    LastLoca: "Dernier emplacement connu",
    AppVersion: "Version de l'application",
    UpdateBtn: "Mettre à jour",
    SureUpdate: "Vous voulez vraiment mettre à jour l'application maintenant ?",
    SureUpdateTips: "Après avoir cliqué sur \"Oui\", vous allez recevoir une invitation sur l'appareil cible pour accepter la mise à jour de l'application. Veuillez vous assurer que vous disposez de l'appareil cible, sinon cela exposera le fait que l'appareil est surveillé.",
    UpdateSuccess: "La mise à jour se télécharge automatiquement. Veuillez vérifier le processus et approuver l'installation sur le périphérique cible.",
    UseLastetVersion: "Vous utilisez la dernière version de cette application. Veuillez vérifier à nouveau les mises à jour ultérieurement.",
    InUpdating: "L'application est en cours de mise à jour et vous pouvez vérifier le processus sur l'appareil cible. Veuillez patienter quelques minutes.",
    UpdateFailed: "La mise à jour a échoué car le périphérique cible n'est pas connecté au réseau ou l'application ne répond temporairement pas.",
    Name: "Nom",
    PhoneNumber: "Numéro de téléphone",
    Type: "Type",
    Incoming: "Entrant",
    Cancelled: "Annulé",
    Outgoing: "Sortant",
    Duration: "Durée",
    Date: "Date",
    All: "Tout",
    Today: "Aujourd'hui",
    ThisMonth: "Ce mois-ci",
    LastMonth: "Le mois dernier",
    LastYear: "L'année dernière",
    Search: "Chercher",
    To: "À",
    Phone: "Téléphone",
    Work: "Travail",
    Home: "Maison",
    Others: "Autres",
    Mail: "Courrier",
    Birthday: "Anniversaires de naissance",
    Anniversary: "Anniversaire",
    Address: "Adresse",
    LonLat: "Longitude et latitude",
    LocTime: "Heure de localisation",
    MapView: "Vue de la carte",
    FailSync: "La synchronisation a échoué. Veuillez réessayer.",
    GenerateDes: "Créez une zone de géorepérage et soyez averti si votre enfant quitte une zone désignée.",
    GenerateEff: "Créez des zones de géorepérage de manière pratique et efficace",
    GenerateCir: "Créez une zone de géorepérage autour d'une zone désignée en encerclant un emplacement spécifique.",
    StayAlt: "Demeurez informé grâce aux alertes de Géorepérage",
    WhGeo: "Lorsque votre enfant traverse la zone de géorepérage, vous recevrez une alerte par email.",
    Start: "Commencer",
    SureDelete: "Êtes-vous certain de vouloir supprimer cette zone de géorepérage?",
    Yes: "Oui",
    No: "Non",
    MaxT: "10 items maximum",
    Ok: "D'accord",
    NameGeofc: "Nommer la zone de géorepérage",
    Setlon: "Définir la zone de restriction",
    SearchAd: "Chercher une adresse",
    SetRadius: "Définir le rayon",
    Meters: "Rayon de la zone de géorepérage en mètres (1000m max.)",
    GuardTime: "Définir le temps de garde",
    SetNow: "Définir maintenant >",
    SetAlt: "Définir les Alertes par notification email",
    Save: "Sauvegarder",
    Cancel: "Annuler",
    StartTime: "Heure de début",
    EndTime: "Heure de fin",
    Repeat: "Répéter",
    EverySun: "Tous les Dim",
    EveryMon: "Tous les Lun",
    EveryTue: "Tous les Mar",
    EveryWed: "Tous les Mer",
    EveryThu: "Tous les Jeu",
    EveryFri: "Tous les Ven",
    EverySat: "Tous les Sam",
    PlsName: "Veuillez définir un nom pour la zone de géorepérage.",
    Plsloca: "Veuillez définir un emplacement.",
    PlsTime: "Veuillez définir le temps de surveillance.",
    ThreeName: "Une zone de géorepérage de ce nom existe déjà.",
    LastVtne: "Heure de la dernière visite",
    URL: "URL",
    Title: "Titre",
    Frequency: "Fréquence",
    Visits: "visites",
    HowFeat: "Comment utiliser cette fonctionnalité ?",
    MakeAct: "Assurez-vous que l'appareil cible soit activé.",
    ClickBt: "Cliquez sur le bouton «Faire des captures d'écran».",
    VuShot: "Voir les captures d'écran",
    NotLock: "Remarque : cette fonction est désactivée lorsque l'écran du téléphone cible est verrouillé.",
    ScReenPow: "La capture d'écran a échoué car l'écran du téléphone cible est verrouillé ou l'application ne répond temporairement pas. Veuillez réessayer plus tard.",
    TakScr: "Faire des captures d'écran",
    Version: "Version",
    Size: "Taille",
    InstDate: "Date de l'installation",
    TodayFreq: "Fréquence aujourd'hui",
    TodayScreenTime: "Temps d'écran aujourd'hui",
    ClickKey: "Cliquez sur le bouton «Commencer» et patientez pour la synchronisation des keyloggers.",
    ViewScr: "Voir les captures d'écran.",
    KidTxy: "iSeeGuard Pro peut vous aider à surveiller les frappes détectées sur l'appareil Android contrôlé. Chaque frappe est accompagnée de l'icône de l'application dont elle provient.",
    NotDat: "Aucun résultat",
    Event: "Évènement",
    EventLocation: "Localisation de l'évènement",
    Note: "Notes",
    AppNotif: "Notifications d'applications",
    Modules: "Modules",
    Time: "Heure",
    Format: "Format",
    Export: "Exporter",
    DeleteAndExport: "Supprimer les données exportées",
    CancelSub: "Annuler l'abonnement",
    UnbindDevice: "Dissocier l'appareil",
    ContactSupport: "Contacter le Support",
    SureRenewal: "Êtes-vous sûr de vouloir annuler le renouvellement automatique ?",
    SureUnbind: "Êtes-vous sûr de vouloir dissocier cet appareil ?",
    PersonalPrivacy: "Afin de protéger votre vie privée, iSeeGuard Pro cessera la surveillance et supprimera toutes les données.",
    UnboundDvc: "Félicitations ! Votre appareil a été dissocié avec succès !",
    Profile: "Profil",
    ContactUs: "Contactez-nous",
    Logout: "Se déconnecter",
    Device: "appareil",
    Month: "Mois",
    BuyNow: "Acheter maintenant",
    AddNew: "Ajouter",
    Unactivated: "Inactivé",
    VersionUpdate: "De nouvelles mises à jour sont disponibles. Si cela est possible, veuillez désinstaller la version précédente sur le téléphone cible, puis redémarrer le téléphone et télécharger la dernière version.",
    LearnMore: "En savoir plus",
    HowToUpdate: "Comment faire la mise à jour de l'application sur l'appareil cible ?",
    UpdateTips1: "Vaya a Mis productos> Configuración> Desvincular dispositivo.",
    UpdateTips2: "Allez sur l'appareil cible, allez dans Paramètres > Applications > cherchez System Update Service et désinstallez-le. ",
    UpdateTips3: "Ensuite, allez sur www.iseeguard.com avec le navigateur et téléchargez l'application.",
    UpdateTips4: "Enfin, suivez les invites à l'écran pour terminer la configuration.",
    WhatsappUpdateTips1: "Allez sur l'appareil cible, allez dans Paramètres > Applications > cherchez WhatsApp Service et désinstallez-le. ",
    WhatsappUpdateTips2: "Ensuite, allez sur www.installapk.net avec le navigateur et téléchargez l'application.",
    WhatsappUpdateTips3: "Enfin, suivez les invites à l'écran pour terminer la configuration.",
    ThankPurchase: "Merci pour votre achat !",
    ThankTips: "Vous recevrez un email de confirmation avec les détails de votre commande.",
    Product: "Produit:",
    BillingEmail: "Votre e-mail de facturation:",
    ClickTips: "Cliquez sur Suivant et démarrez la surveillance.",
    PayFailed: "Le paiement a échoué",
    PayFailedTips: "Veuillez réessayer avec un autre mode de paiement ou contacter votre fournisseur de paiement.",
    TryAgain: "Réessayer",
    FailedReason: "Pourquoi le paiement échoue-t-il?",
    FailedReason1: "1. Carte expirée",
    FailedReason2: "2. Fonds insuffisants",
    FailedReason3: "3. Les informations saisies sont incorrectes",
    FailedReason4: "4. Autres raisons",
    OrderReview: "Votre commande en revue",
    OrderReviewTips: "Votre commande",
    OrderReviewTips1: " a été reçue et est en cours de vérification par notre équipe.",
    OrderReviewTips2: "La commande devrait être traitée dans les 2 prochaines heures - la durée maximale de traitement est de 24 heures. Vous recevrez une notification par e-mail si le paiement est réussi.",
    OrderReviewTips3: "Pour éviter d'effectuer plusieurs demandes de paiement à partir de votre carte de crédit, veuillez ne pas soumettre la commande à plusieurs reprises.",
    ChangeNotEmail: "Modifier la notification par email",
    NewEmail: "Nouveau email",
    NotHu: "À noter : cette modification ne s'applique qu'à votre email de notification.L'email de connexion reste inchangé.",
    SendMailTse: "Un email de confirmation a été envoyé à votre nouvelle adresse email de notification. Si vous ne recevez pas cet email, cliquez sur le bouton ci-dessous pour réessayer ou contactez-nous à",
    ChangeNotsuces: "L'email de notification a été modifié avec succès !",
    ChangeTwo: "Vous avez entré l'ancien email. Veuillez changer pour un nouveau email.",
    ChangePassword: "Modifier le mot de passe",
    OldPassword: "Ancien mot de passe",
    PwdSuces: "Le mot de passe a été modifié avec succès.",
    WrongOldPwd: "Ancien mot de passe erroné.",
    TheNewCode: "L'ancien mot de passe et le nouveau sont les mêmes. Veuillez réessayer.",
    NoMore: "pas plus",
    ReNew: "Renouveler",
    CheckExported: "Il n'y a pas de données à exporter. Veuillez vérifier et essayer à nouveau.",
    FailedCheck: "L'exportation a échoué. En raison de problèmes de compatibilité de navigateur, les données ne peuvent pas être téléchargées via les navigateurs Firefox ou Edge pour le moment. Veuillez utiliser Chrome, Safari ou Opera, et réessayez.",
    ScShot: "Capture d'écran",
    AddGeofence: "Ajouter une zone de géorepérage",
    WifILocation: "Localisation wifi",
    footCot: "Copyright © 2021 iSeeGuard.com. Tous droits réservés.",
    Supcenter: "Centre de soutien",
    ProductFAQs: "FAQ sur les produits",
    AccountFAQs: "FAQ sur le compte",
    PurchaseFAQs: "FAQ d'achat",
    iSeeGuardStore: "Boutique",
    Polide: "Politique",
    privPli: "Politique de confidentialité",
    cokPli: "Politique des cookies",
    refndPli: "Politique de remboursement",
    elu: "CLUF",
    TermsCot: "Conditions générales",
    FoloUs: "Suivez-nous",
    Paymet: "Paiement",
    prodt: "Produits",
    suport: "Soutien",
    Load: "Chargement...",
    NotFund: "Oups ! Page non trouvée.",
    IagreThe: "J'accepte",
    GlevEu: "CLUF de iSeeGuard",
    PriPoly: "Politique de confidentialité",
    OurGuat: "Notre garantie",
    secure: "Sûr",
    finalcoft: "Nous avons votre vie privée à coeur. Vos informations personnelles ou financières resteront confidentielles.",
    twoBack: "Remboursement de 30 jours",
    provideCase: "Nous offrons une garantie de remboursement de 30 jours pour tous les produits au cas où vous ne seriez pas complètement satisfait.",
    cancelAnt: "Annulez quand vous le voulez",
    cancelNeed: "En tout temps il vous est possible d'annuler votre abonnement si vous n'avez plus besoin du produit.",
    FastSup: "Support rapide",
    EnquriedProblem: "Notre équipe vous offre un service clientèle 24/5 pour vous aider en cas de questions ou de problèmes.",
    AsUcess: "Désolé, la vérification a échoué. Veuillez vous assurer que l'application iSeeGuard Pro Assistant a été installée avec succès sur l'appareil cible.",
    ExportTip: "L'exportation de données n'est pas prise en charge sur les appareils mobiles. Connectez-vous au panneau en ligne iSeeGuard sur votre ordinateur et exportez les données spécifiques.",
    Expired: "Expiré",
    Features: "Caractéristiques",
    WifiUpload: "* Veuillez noter que les images ne seront téléchargées que par WiFi.",
    WhatsAppTips: "L'application de surveillance WhatsApp dédiée n ° 1 est ici. Explorez maintenant >>",
    ScOne: "Une erreur s'est produite lors de la sauvegarde des captures d'écran sur le périphérique cible. Veuillez réessayer plus tard.",
    ScTwo: "Une erreur s'est produite lors du téléchargement des captures d'écran. Veuillez réessayer plus tard.",
    Anpay: "Paiement en ligne sécurisé",
    OldEmail: "Ancien email :",
    ExtendSubscript: "Renouvellement des Frais",
    Troubleshoot: "Résoudre un problème",
    PermissionsCheck: "Résoudre un problème",
    LogAgin: "Si l'application sur le téléphone cible ne fonctionne pas correctement, il est possible que les autorisations ne soient pas activées. Allez dans les Paramètres et activez-les à nouveau pour que l'application fonctionne à nouveau.",
    SubmitLog: "Pour aider à résoudre votre problème, vous pouvez nous envoyer le fichier journal des erreurs.",
    Accessibility: "Accessibilité",
    Administrator: "Administrateur",
    CallLog: "Historique d'appels",
    Location: "Localisation",
    Notification: "Notification",
    SMS: "SMS",
    Storage: "Stockage",
    UsageStatus: "Statut d'utilisation",
    AccessCamera: "Appareil photo",
    Microphone: "Téléphone",
    Submit: "Envoyer",
    LogExpried: "Le journal des erreurs a été mis en ligne avec succès.",
    LogMailOne: "Vous pouvez nous contacter à ",
    Spt: "support@iSeeGuard.com",
    SptFst: "pour un support plus rapide.",
    Permission: "Permissions",
    Status: "État",
    OnlyOne: "Seulement 1000 enregistrements peuvent être exportés à la fois.",
    LogSucess: "Une erreur inconnue est survenue.",
    LogUnkow: "Veuillez soumettre à nouveau dans 10 minutes. Ou vous pouvez nous contacter via",
    LogTwoUnkow: "pour un support plus rapide.",
    Items: "Quantité",
    ViewSchot: "Voir les captures d'écran historiques",
    verifyIcloudAccount: "Vérifiez les informations d'identification iCloud",
    icloudId: "Veuillez entrer l'identifiant iCloud de l'appareil cible que vous souhaitez surveiller.",
    anotherIcloud: "Veuillez réessayer ou",
    useOther: "utiliser un autre compte iCloud.",
    oldIcloud: "Vous avez entré un ancien compte iCloud.",
    accoundLock: "Le compte iCloud est verrouillé",
    lockedAgain: "Ce compte iCloud a été verrouillé par Apple pour des raisons de sécurité. Veuillez réessayer dans 12 heures.",
    twoFactor: "Identification à deux facteurs",
    phoneSend: "Un message contenant un code de vérification a été envoyé à l'appareil cible. Veuillez saisir le code pour vérifier le compte.",
    dontGetCode: "Vous n'avez pas reçu votre code de vérification ?",
    sixCode: "Si l'authentification à deux facteurs est activée, vous devrez accéder physiquement à l'appareil cible et obtenir un code de vérification à six chiffres.",
    resendCode: "Renvoyer le code",
    textMe: "Vérification par SMS",
    needHelp: "Besoin d'aide ?",
    notSendCode: "L'envoi du code de vérification a échoué, veuillez réessayer.",
    noVerification: "Code de vérification incorrect.",
    noRecordsFound: "Aucun dossier trouvé!",
    newPricePlan: "Nous avons constaté que vous avez entré un compte iCloud différent qui ne correspond pas à celui de nos dossiers. Si vous souhaitez surveiller ce nouveau compte, veuillez supprimer le compte iCloud précédent ou acheter un nouveau forfait.",
    deleteAccount: "Supprimer le compte",
    icloudError: "Erreur de vérification iCloud",
    aginNormally: "Une erreur s'est produite lors de la vérification du compte iCloud cible. Certaines données peuvent ne pas être mises à jour. Veuillez revérifier afin  de pouvoir utiliser iSeeGuard Pro normalement.",
    notNow: "Plus tard",
    verifyIcloud: "Vérifier iCloud",
    icloudAccount: "Compte iCloud",
    icloudStorage: "Stockage iCloud",
    deviceName: "Nom du périphérique",
    deviceModel: "Modèle de l'appareil",
    seeMore: "Voir plus",
    sureTargetTwo: "Assurez-vous que la fonctionnalité Localiser mon iPhone est activée sur l'appareil cible.",
    location: "Emplacement",
    list: "Liste",
    sureDeleteAccount: "Êtes-vous sûr de vouloir supprimer le compte iCloud ?",
    removeThat: "Tous les enregistrements et données liés à ce compte iCloud seront ensuite supprimés.",
    isRemove: "Félicitations ! Ce compte iCloud a été supprimé avec succès.",
    Reminder: "Rappels",
    iCloudDrive: "iCloud Drive",
    appleIdErr: "L'identifiant Apple ou le mot de passe est incorrect.",
    selectTrustePhone: "Sélectionnez un numéro de téléphone de confiance",
    serverErr: "Une erreur est survenue sur le serveur. Veuillez réessayer plus tard.",
    continue: "Continuez",
    textMessage: "Message texte",
    codeMany: "Nombre de saisies incorrectes de votre code de sécurité trop élevé.",
    downloadPage: "Il faut en moyenne 2 à 3 minutes pour télécharger un fichier Pages, Numbers ou Keynote. Es-tu sur de vouloir continuer?",
    Tips: "Invite",
    Verify: "Vérifier",
    icloudLok: "Le compte iCloud a été verrouillé ou le mot de passe est incorrect.",
    veriErr: "Échec de la vérification",
    notereminderTip: "iCloud a cessé de répondre. Certaines données sont temporairement indisponibles. Veuillez réessayer plus tard.",
    Video: "Vidéos",
    forAndroid: "Surveiller Android",
    foriOs: "Surveiller iOS",
    tryError: "Une erreur inconnue est survenue.Veuillez réessayer plus tard.",
    nodata: "Pas de données",
    ForAndroid: "Pour Android",
    PhoneFiles: "Fichiers du téléphone",
    ClickShoot: "Cliquez sur le bouton « Photo ».",
    LocationTracking: "Suivi de localisation",
    RemoteControl: "Contrôle à distance",
    TakePhotos: "Prendre des photos",
    TakeNote: "Remarque : la prise de photos ne peut être effectuée qu’avec l’appareil photo arrière. L’utilisation de cette fonctionnalité n’est pas recommandée pour les situations suivantes :",
    Shoot: "Photographier ",
    ShootErrorFirst: "Votre opération est trop fréquente. Veuillez patienter un moment puis recommencer.",
    ShootErrorTwo: "La prise de photo a échoué, car l'appareil cible est éteint ou l'application ne répond pas pour l'instant. Veuillez réessayer plus tard.",
    ShootErrorFour: "L’autorisation d’accéder à l’appareil photo a été désactivée sur l’appareil cible. Veuillez aller à Paramètres> Applications> System Update Service> Autorisations et réactivez-la.",
    ShootErrorFiveAndSeven: "Impossible d’ouvrir l’appareil photo, car il est occupé par un autre programme.",
    ShootErrorEight: "Une erreur s’est produite pendant l’enregistrement des photos sur l’appareil cible. Veuillez réessayer plus tard.",
    ShootErrorNineAnTen: "Une erreur s’est produite pendant le téléchargement des photos. Veuillez réessayer plus tard.",
    NoteWhenFirst: "Lorsque l’appareil photo est occupé;",
    NoteWhenTwo: "Lorsque le téléphone cible est orienté vers le haut sur une surface plate;",
    NoteWhenThree: "Lorsque le téléphone cible se trouve dans une poche ou un sac.",
    RecordCalls: "Enregistrer des appels",
    RecordsOutgoing: "Enregistrer à distance l’ensemble des appels entrants et sortants effectués sur les appareils Android",
    AutomaticallyRecord: "Enregistrer automatiquement les appels téléphoniques",
    RemotelyOnline: "Écouter à distance et en ligne tous les appels",
    WifiOnline: "Télécharger les fichiers audio sur le compte en ligne lorsqu’une connexion Wi-Fi est disponible",
    NoteTime: "Remarque : cette fonction enregistre jusqu’à 20 minutes d’appels à la fois.",
    Audio: "Audio",
    DownloadSimple: "Télécharger",
    TakeShootVersion: "La version actuelle est trop basse. Veuillez mettre à niveau l'application vers la dernière version sur le téléphone cible pour utiliser cette fonction.",
    RecordTarget: "Enregistrer des conversations téléphoniques sur le téléphone cible et les sauvegarder en tant que fichiers audio masqués",
    ViewTake: "Affichez les photos téléchargées.",
    balnkExport: "Vuoto",
    iphoneAudio: "Le navigateur iOS ne prend pas en charge la lecture des enregistrements d'appels. Veuillez le faire sur votre ordinateur.",
    IncludedFeatures: "Fonctionnalités incluses",
    AppVideos: "Vidéos de l'application",
    AppPhotos: "Photos de l'application",
    SafariHistory: "Historique de Safari",
    SafariBookmarks: "Onglets sur Safari",
    VoiceMemos: "Mémos vocaux",
    ViewAllFollowing: "Voir toutes les données suivantes dans l'application iSeeGuard Pro sur l'ordinateur cible",
    OpeniSeeGuardProApp: "Ouvrez l'application iSeeGuard Pro",
    NoteInstalled: "Remarque: Vous pouvez cliquer sur le bouton ci-droit pour faire apparaître rapidement l'interface de l'application. Si l'application ne s'affiche pas, veuillez la lancer manuellement sur l'ordinateur où elle est installée.",
    NoteFiles: "Remarque: Vous devez accéder à l'ordinateur cible et lancer l'application afin de vérifier tous les fichiers.",
    NotSet: "Pas encore défini",
    MonitorAndroid: "Surveillez 1 appareil Android. (Pas de racine)",
    MonitoriOS: "Surveillez 1 appareil iOS. (Pas de jailbreak)",
    MonitorICloud: "Surveiller un compte iCloud. (Pas de jailbreak et aucune application installée)",
    ChooseAndroid: "Choisissez votre forfait pour iSeeGuard Pro pour Android",
    ChooseMac: "Choisissez votre forfait pour iSeeGuard Pro pour Mac",
    ChooseIOS: "Choisissez votre forfait pour iSeeGuard Pro pour iOS",
    ChooseICloud: "Choisissez votre forfait pour iSeeGuard Pro pour iCloud",
    ChooseWhatsApp: "Choisissez votre forfait pour iSeeGuard pour WhatsApp",
    ChooseWindows: "Choisissez votre forfait pour Monivisor pour Windows",
    BuyDesc: "Renouvellement automatique.",
    BuyTips1: "La licence sera automatiquement renouvelée dans un mois. Vous pouvez l'annuler à tout moment sur la page Paramètres du produit.",
    BuyTips2: "La licence sera automatiquement renouvelée dans 3 mois. Vous pouvez l'annuler à tout moment sur la page Paramètres du produit.",
    BuyTips3: "La licence sera automatiquement renouvelée dans un an. Vous pouvez l'annuler à tout moment sur la page Paramètres du produit.",
    IcartPay: "Carte de Crédit",
    ShowAll: "Afficher tout",
    WaitMsg: " S'il vous plaît, attendez. Nous traitons votre commande.",
    MonitoriOSremotely: "Désolé, l'annulation a échoué. Veuillez réessayer.",
    tryAgain: "Veuillez réessayer.",
    resiterSucess: "Enregistré avec succès!",
    resiterTxt: "Toutes nos félicitations ! Le compte iSeeGuard a été créé avec succès !",
    selectPt: "Sélectionnez le produit dont vous avez besoin",
    Availablefor: "Disponible pour",
    ThankYou: "Merci!",
    ThankYouTips: "Votre requête a été reçue.",
    androidMonite: "Démarrer la Surveillance - Pas besoin de rooter vos téléphones Android !",
    iosMonite: "Démarrer la Surveillance - Pas besoin de débrider votre iPhone ou votre iPad !",
    iCloudMonite: "Démarrer la surveillance - Pas besoin d'un débridage ou d'une installation d'application !",
    myPorductOrder: "La Gestion de Mes Produits et de Mes Commandes",
    expireSoon: "Expirer Bientôt",
    invisibleMode: "Une application de surveillance ultime pour Android qui fonctionne de manière invisible.",
    moniteFeatures: "Plus de 30 fonctions de surveillance fournies. Aucun jailbreak n'est requis.",
    overTwoIos: "Plus de 20 fonctionnalités de surveillance sous iOS comprenant : WhatsApp, l'historique des appels, les SMS etc...",
    overTwoMac: "Plus de 20 fonctionnalités de surveillance sous Mac comprenant : WhatsApp, l'historique des appels, les SMS etc...",
    noLike: "Pas besoin d'opération complexe comme le jailbreak d'iOS(débridage d'iOS). Démarrez rapidement.",
    userIcloud: "Surveillez n'importe quel iPhone ou iPad en utilisant les informations d'identification iCloud",
    noNeedInstallApp: "Pas besoin d'installer un logiciel sur l'appareil ciblé sous iOS, 100% sécurisé.",
    myProduct: "Mes Produits",
    accountOrderInfo: "Compte & Info sur les Commandes",
    orderInfo: "Information sur les Commandes",
    unableSync: "La synchronisation des données est temporairement indisponible. Les données commenceront à se synchroniser une fois que le téléphone cible sera allumé avec une connexion internet et que l'assistant iSeeGuard Pro est bien lancé sur l'appareil cible.",
    productFAQ: "FAQs des Produits",
    path: "Étapes",
    goSetZero: "Rendez-vous dans les Paramètres > Applications > cherchez le Service de Mise À Jour du Système > Permissions > Caméra > assurez-vous que c'est activé",
    goSetOne: "Rendez-vous dans les Paramètres > Accessibilité > le Service de Mise À Jour du Système > assurez-vous que c'est activé",
    goSetTwo: "Rendez-vous dans les Paramètres > cherchez Sécurité > Autres paramètres de sécurité > Administrateur de l'appareil > Service de Mise À Jour du Système  > assurez vous que c'est activé",
    goSetThree: "Rendez-vous dans les Paramètres > Applications > cherchez le Service de Mise À Jour du Système > Permissions > Calendrier > assurez-vous que c'est activé",
    goSetFour: "Rendez-vous dans les Paramètres > cherchez le Service de Mise À Jour du Système > Permissions > Historique des appels > assurez-vous que c'est activé",
    goSetFive: "Rendez-vous dans les Paramètres > Applications > cherchez le Service de Mise À Jour du Système > Permissions > Contacts > assurez-vous que c'est activé",
    goSetSix: "Rendez-vous dans les Paramètres > Applications > cherchez le Service de Mise À Jour du Système > Permissions > Localisations > assurez-vous que c'est activé",
    goSetSop: "Rendez-vous dans les Paramètres > Applications > cherchez le Service de Mise À Jour du Système > Permissions > Microphone > assurez-vous que c'est activé",
    goSetSeven: "Rendez-vous dans les Paramètres > Applications > cherchez le Service de Mise À Jour du Système > Notifications > assurez-vous que c'est activé",
    goSetEight: "Rendez-vous dans Paramètres > cherchez le Service de Mise À Jour du Système > Permissions > SMS > assurez-vous que c'est activé",
    goSetNight: "Rendez-vous dans les Paramètres > Applications > cherchez le Service de Mise À Jour du Système  > Permissions > Stockage > assurez-vous que c'est activé",
    goSetTwent: "Rendez-vous dans les Paramètres > cherchez Sécurité > Autres paramètres de sécurité > Accès à l'usage des données  > le Service de Mise À Jour du Système > assurez-vous que c'est activé",
    followStep: "Suivez les étapes ci-dessous pour ajouter le téléphone que vous souhaitez surveiller",
    followIos: "Suivez les étapes ci-dessous pour ajouter le iOS que vous souhaitez surveiller",
    followMac: "Suivez les étapes ci-dessous pour ajouter le Mac que vous souhaitez surveiller",
    beforeStart: "Avant de démarrer",
    youNeedDevice: "1. Vous avez besoin d'un accès direct à l'appareil cible.",
    notiLLegal: "2. N'utilisez pas ce logiciel pour faire des choses illégales.",
    physicalDevice: "1. Un accès direct à l'appareil est requis.",
    illegalPurposes: "2. N'utilisez pas ce logiciel pour faire des choses illégales.",
    videoInstruction: "Vidéo explicative",
    VerifyInstall: "Merci de regarder le guide vidéo pour finaliser l'installation et la configuration de l'application sur le téléphone cible. Une fois que c'est fait, cliquez sur le bouton \"Vérifiez la Configuration\" ci-dessous pour connecter l'appareil cible et vous connecter le tableau de bord.",
    textInstruction: "Texte explicatif",
    setupStep: "Étape ",
    androidStepOne: "Téléchargez l'application sur le téléphone cible",
    androidStepOneDesc: "Allez sur <strong style='color: #347aeb;'>www.iseeguard.com</strong> avec votre navigateur sur l'appareil cible et téléchargez l'application iSeeGuard Pro pour Android.",
    androidStepTwo: "Installez l'application",
    androidStepTwoDesc0: "Cliquez sur le fichier dans vos téléchargements sur le navigateur ou dans votre Gestionnaire de fichiers et lancez l'installation de l'application.",
    androidStepTwoDesc1: "Cliquez sur le fichier dans vos téléchargements sur le navigateur ou dans votre Gestionnaire de fichiers et lancez l'installation de l'application. Une fois que c'est fait, connectez-vous à votre compte et notez les informations de base concernant la cible.",
    androidStepTwoDesc2: "<strong>Remarque</strong> : certains paramètres doivent être parfaitement configurés pour que iSeeGuard Pro fonctionne correctement. Suivez simplement les instructions sur l'application ou bien suivez les instructions ci-dessous que vous pouvez faire manuellement.",
    androidStepThree: "Accepter l'accord",
    androidStepTwoToOneDesc: "Veuillez choisir <strong>J'ai lu et compris les conditions</strong> et appuyez sur <strong>J'accepte</strong>.",
    androidStepFour: "Autorisations de base",
    androidStepTwoToTwoDesc: "Appuyez sur <strong>Autorisations de base </strong>> > Cliquez sur <strong>Autoriser</strong> pour toutes sortes d'autorisations de base.",
    androidStepFives: "Activer l'accessibilité",
    androidStepTwoToThreeDesc: "Appuyez sur <strong>Service d'accessibilité</strong> > activez les <strong>services système</strong> > appuyez sur <strong>Autoriser</strong>.",
    androidStepSix: "Activez l'Accès aux Notifications",
    androidStepTwoToFourDesc: "Appuyez sur <strong>Écouteur de notification</strong> > activez les <strong>services système</strong> > appuyez sur <strong>Autoriser</strong>.",
    androidStepSeven: "Activer l'autorisation de capture d'écran",
    androidStepTwoToFiveDesc: "Appuyez sur <strong>Autorisation de capture d'écran</strong> > activez <strong>iSeeGuard</strong>.",
    androidStepEight: "Accès à l'utilisation",
    androidStepTwoToSixDesc: "Appuyez sur <strong>Accès à l'utilisation</strong> > activez les <strong>services système</strong> > appuyez sur <strong>Autoriser</strong>.",
    androidStepNine: "Autorisation de superposition",
    androidStepTwoToSevenDesc: "Appuyez sur <strong>Autorisation de superposition</strong> > activez les <strong>services système</strong> > appuyez sur <strong>Autoriser</strong>.",
    androidStepTen: "Désactiver les notifications d'application",
    androidStepTwoToEightDesc: "Appuyez sur <strong>Désactiver les notifications d'applications</strong> > activez les <strong>services système</strong> > désactivez <strong>Afficher la bascule de notification</strong>.",
    androidStepTwoToNineDesc: "Appuyez sur <strong>Désactiver l'optimisation de la batterie</strong> > appuyez sur <strong>Autoriser</strong> dans la zone d'invite.",
    androidStepTwoToTenDesc: "Après avoir terminé tous les paramètres d'autorisation, appuyez sur <strong>suivant</strong>. Après cela, connectez-vous à votre compte.",
    androidStepEleven: "Désactiver l'optimisation de la batterie",
    androidStepTwelve: "Connectez-vous à votre compte",
    huaWeiNote1: "<strong>Huawei</strong>: Appuyez sur le bouton ci-dessous et trouvez System Update Service dans le Gestionnaire des tâches. Faites glisser l'interface de l'application vers le bas pour la verrouiller. Après cela, appuyez sur l'interface de l'application pour revenir.",
    oppoNote1: "<strong>Oppo</strong>: Appuyez sur le bouton ci-dessous et trouvez System Update Service  dans le Gestionnaire des tâches. Appuyez sur l'icône Paramètres dans le coin supérieur droit. Appuyez sur Verrouiller. Ensuite, appuyez sur l'interface de l'application pour revenir.",
    xiaomiNote1: "<strong>Xiaomi</strong>:  Appuyez sur le bouton ci-dessous et trouvez System Update Service dans le Gestionnaire des tâches. Appuyez longuement sur l'onglet de l'application et verrouillez-le. Après cela, appuyez sur l'interface de l'application pour revenir.",
    huaWeiNote: "<strong>Huawei</strong>: rendez-vous dans les Paramètres > Applications > Lancement d'application > cherchez le Service de Mise À Jour du Système, désactivez-le d'abord. Ensuite, dans la fenêtre : Modifier manuellement, activez le lancement automatique, le lancement secondaire et l'exécution en arrière-plan.",
    oppoNote: "<strong>Oppo</strong>: rendez-vous dans les Paramètres > Gestion des applications > Liste des Applications > cherchez le Service de Mise À Jour du Système et Économiseur d'Énergie > activez <strong>Exécution en arrière-plan</strong>, puis cliquez sur le bouton Précédent et activez le <strong>Démarrage Automatique</strong> ainsi que l'autorisation du <strong>Lancement d'Autres Applications ou Services</strong>. Après cela, revenez à Paramètres > Batterie > désactivez l'Économiseur de Batterie Intelligent > Appuyez sur Personnaliser la Batterie > cherchez le Service de Mise À Jour du Système et activez le <strong>Fonctionnement en Arrière-plan</strong>. Appuyez à nouveau sur le bouton Précédent et activez le <strong>gel rapide de l'application</strong>.",
    xiaomiNote: "<strong>Xiaomi</strong>:  rendez-vous dans les Paramètres > Applications > Gérer les applications > cherchez le Service de Mise À Jour du Système > activez le <strong>Démarrage Automatique</strong>, puis faites défiler vers le bas et cherchez Économiseur de batterie > <strong>Aucune restriction</strong>.",
    vivoNote: "<strong>Vivo</strong>:rendez-vous dans les Paramètres > Batterie > <strong>Consommation électrique élevée en arrière-plan</strong> > cherchez le Service de Mise À Jour du Système > désactivez-le.",
    otherNote: "<strong>Autres</strong>: rendez-vous dans les Paramètres > Applications > cherchez le Service de Mise À Jour du Système > Paramètres avancés > Optimisation de la batterie > recherchez à nouveau le le Service de Mise À Jour du Système et désactivez-le.",
    androidStepThirteen: "Démarrer la surveillance",
    androidStepThreeDesc: "Cliquez maintenant sur le bouton \"Démarrer la Surveillance\" et l'icône de l'application disparaîtra rapidement. Pour les utilisateurs d'Android 10 ou supérieur, l'icône de l'application sera remplacé par une icône \"Note\". Le mieux est de caché cette icône dans un dossier.",
    VerifyYourSetup: "Vérifiez Votre Configuration",
    nowMoniteSupportTeam: "Si tout est bien configuré, veuillez cliquer sur le bouton \"Vérifiez la Configuration\" ci-dessous. Cela liera l'appareil cible à votre compte en ligne. Cette page vous mènera à votre tableau de bord, où vous pourrez regarder tous les fichiers. Si l'application ne s'est pas correctement installé, merci de contacter l'équipe technique.",
    iosStepOne: "Téléchargez l'application sur l'ordinateur cible",
    iosStepOneDesc: "Allez sur <strong style='color: #347aeb;'>www.useapp.net</strong> pour télécharger iSeeGuard Pro pour iOS sur l'ordinateur cible.",
    iosStepOneNote: "<strong>Remarque:</strong>  il est recommandé de le télécharger directement sur l'ordinateur de la cible que vous souhaitez surveiller. Si vous pouvez accéder physiquement à l'iPhone cible de temps à autre, vous pouvez aussi le télécharger et faire la surveillance sur votre ordinateur.",
    iosStepTwo: "Installez le logiciel iSeeGuard Pro pour iOS",
    iosStepTwoWinDesc: "<strong>Pour ceux qui sont sur Windows : </strong>une fois le téléchargement terminé, cliquez sur le fichier .exe pour lancer l'installation.",
    iosStepTwoWinNote1: "Veuillez noter que dans cette interface initiale, vous pouvez choisir de masquer l'icône sur votre ordinateur. Si vous cochez l'option \"Ne pas afficher le raccourci sur le bureau, dans la barre des tâches et sur le menu Démarrer\", il n'y a que deux façons d'ouvrir iSeeGuard Pro :",
    iosStepTwoWinNote2: "<strong>2.1</strong> Accédez au chemin d'installation du programme et cliquez sur le fichier .exe. Par défaut, le chemin d'installation est C:\\Program Files (x86)\\iSeeGuard\\iSeeGuard Pro. Si vous choisissez un autre endroit pour installer le logiciel, assurez-vous de pouvoir vous en souvenir.",
    iosStepTwoWinNote3: "<strong>2.2</strong> Connectez-vous au tableau de bord en ligne de iSeeGuard Pro sur le navigateur de l'ordinateur cible, puis cliquez sur \"Ouvrir l'Application iSeeGuard Pro\".",
    iosStepTwoMacDesc: "<strong>Pour les Utilisateurs Mac：</strong>Après le téléchargement, faites glisser le fichier du programme dans le dossier Application. Une fois l'installation terminée, démarrez le programme et connectez-vous à votre compte.",
    iosStepThree: "Analyser les données de l'iPhone ou de l'iPad cible",
    iosStepThreeToOneDesc1: "Si aucune sauvegarde n'est disponible sur l'ordinateur, vous devez connecter l'appareil iOS cible avec un câble USB, confirmez ensuite les informations de l'appareil et activez les paramètres USB comme demandé.",
    iosStepThreeToOneDesc2: "Une fois que l'appareil cible est bien connecté, iSeeGuard Pro pour iOS va lancer l'analyse, cela prendra un certain temps.",
    iosStepThreeToTwoDesc1: "Si des sauvegardes sont disponibles sur l'ordinateur, iSeeGuard Pro affichera la première sauvegarde trouvée. Vous pouvez sélectionner la sauvegarde de l'appareil cible et analyser les données.",
    iosStepThreeToTwoDesc2: "Si l'appareil que vous souhaitez surveiller n'apparaît pas dans la liste ou si vous voulez juste afficher les dernières données, vous pouvez cliquer sur \"Connecter l'appareil cible\" en bas.",
    iosStepThreeToThreeDesc: "Dans la dernière version, vous êtes également autorisé à synchroniser les données par Wi-Fi. Une fois que l'iPhone et l'iPad sont connectés au même réseau WiFi que l'ordinateur, il suffit de cliquer sur l'icône WiFi dans la barre de navigation et vous pouvez alors sélectionner l'appareil pour synchroniser les données à distance.",
    iosStepThreeToThreeNote: "<strong>Remarque :</strong> Pour synchroniser des données par Wi-Fi, vous devez connecter l'appareil au PC une fois et faire confiance à votre ordinateur.",
    iosStepFour: "Surveillez l'iPhone ou l'iPad cible sur votre ordinateur",
    iosStepFourDesc: "Une fois que l'analyse est terminée, toutes les informations seront affichées sur le tableau de bord et vous pourrez basculer entre les différents types de fichiers et afficher les détails. Vous pouvez exporter toutes les données vers votre ordinateur.",
    VerifySetup: "Vérifiez la Configuration",
    kdsAndroid: "iSeeGuard Pro pour Android",
    kdsIos: "iSeeGuard Pro pour iOS",
    kdsIcloud: "iSeeGuard Pro pour iCloud",
    setupGuide: "Mode d'emploi",
    generalSet: "Paramètres Généraux",
    plseRefer: "Veuillez vous référer au guide vidéo pour savoir comment surveiller un iPhone à l'aide de iSeeGuard Pro. Nous avons également préparé un texte d'instruction détaillé ci-dessous pour votre référence.",
    locate: "Localiser",
    requestOut: "Délai d'expiration de la demande.",
    onlyTxt: "Seuls les fichiers texte peuvent être exportés.",
    quickly: "Veuillez cliquer sur le bouton ci-dessous pour vous connecter rapidement.",
    neverWth: "La surveillance sur WhatsApp n'a jamais été aussi simple avec iSeeGuard.",
    androidSytem: "Application de surveillance ultime pour Android avec + de 30 fonctionnalités.",
    iOSSytem: "Logiciel sur PC pour surveiller un iPhone ou un iPad sans jailbreak.",
    iCloudSytem: "Solution en ligne pour surveiller les appareils iOS sans installer d'application.",
    WhatsSytem: "Application de surveillance spécialisée pour WhatsApp sur les téléphones Android.",
    WindowsSytem: "Logiciel de surveillance invisible pour Windows.",
    Comingsoon: "Bientôt disponible",
    kdsMobile: "Application de sécurité mobile",
    MobileSytem: "Application anti-espion la mieux notée pour garder votre appareil Android exempt de logiciels espions et de stalkerware.",
    EarlBbirdTitle: "Ne manquez pas le prochain outil de sécurité mobile Android！",
    EarlBbirdContent: "Laissez votre e-mail et recevez le coupon de réduction pour les lève-tôt après le lancement du produit.",
    ValidEmail: "Votre email valide",
    EmailSubmited: "Cet e-mail est déjà envoyé.",
    WindowsMonitore: "MoniVisor",
    WhatsAppMonitore: "iSeeGuard pour WhatsApp",
    kdsWhatsapp: "iSeeGuard pour WhatsApp",
    SpeceAndroid: "Application de surveillance spécialisée pour WhatsApp sur les téléphones Android.",
    preparedGuid: "Nous avons préparé une vidéo détaillée pour vous aider à savoir comment surveiller le WhatsApp de quelqu'un avec iSeeGuard. Vous trouverez ci-dessous le tutoriel étape par étape : ",
    DataUpload: "Téléchargement des données",
    transferOver: "Téléchargement des fichiers volumineux uniquement par Wi-Fi.",
    followWhatsApp: "Suivez les étapes ci-dessous pour surveiller WhatsApp de quelqu'un",
    whatsappStepOneDesc: "Rendez-vous sur : <strong style='color: #347aeb;'>www.installapk.net</strong> avec le navigateur internet du téléphone cible et téléchargez l'application iSeeGuard pour WhatsApp",
    whatsappStepTwoDesc1: "Ouvrez le fichier APK que vous avez télécharger dans votre dossier de téléchargement de votre navigateur et terminez l'installation. Connectez-vous ensuite à votre compte et notez les informations de base concernant la cible.",
    whatsappStepTwoDesc2: "<strong>Remarque : </strong>certains paramètres doivent être parfaitement configurés pour que iSeeGuard pour WhatsApp fonctionne correctement. Suivez simplement les instructions sur l'application ou bien suivez les instructions ci-dessous que vous pouvez faire manuellement.",
    whatsappStepTwoToOneDesc: "Allez <strong>dans les Paramètres</strong> > l'icône des paramètres se trouve dans le coin supérieur droit > désactivez <strong>l'Analyse des applications par Play Protect et l'Amélioration de la détection des applications nuisibles</strong> > retournez sur l'application iSeeGuard pour WhatsApp > cliquez sur <strong>Configuration déjà faite</strong>.",
    whatsappStepTwoToTwoDesc: "Allez <strong>dans les Paramètres > Services installés > autorisé <strong>WhatsApp Service</strong> > en cliquant sur <strong>Autorisé</strong>.",
    whatsappStepTwoToThreeDesc: "Appuyez sur<strong> Continuer dans les Paramètres</strong> > cherchez <strong>Accès à l'Usage des Données</strong> sous les paramètres de sécurité > cherchez le <strong>WhatsApp Service</strong> > <strong>autorisez le Suivi</strong>.",
    whatsappStepTwoToFourDesc: "Appuyez sur <strong>Continuer dans les Paramètres</strong> > autorisez le<strong> WhatsApp Service</strong>.",
    whatsappStepTwoToFiveDesc: "Appuyez sur  <strong>Continuer dans les Paramètres</strong> > activez les permissions administrateur de l'appareil pour le WhatsApp Service.",
    whatsappStepTwoToSevenDesc: "Appuyez sur  <strong>Tout autoriser  </strong>pour activer les autorisations de cette application pour obtenir les données WhatsApp et d'enregistrer automatiquement les appels WhatsApp.",
    whatsappStepTwoToEightDesc: "Cliquez sur  <strong>Ignorer</strong> , puis dans la fenêtre qui s'affiche, appuyez sur  <strong>Autoriser</strong>  pour permettre au Service WhatsApp de s'exécuter en arrière-plan.",
    whatsappStepTwoToNine: "Activer le démarrage automatique",
    whatsappStepTwoToNineDesc: "Appuyez sur<strong> Passer aux paramètres</strong> > activez le <strong>service WhatsApp</strong>  pour permettre à l'application de démarrer une fois que l'appareil redémarre.",
    whatsappStepTwoToTen: "Activez l'exécution en arrière-plan (uniquement sur Huawei, Oppo, Xiaomi, Vivo)",
    WhatsAppHuawei: "<strong>Huawei:</strong> rendez-vous dans Paramètres > Applications > Lancement d'application > cherchez le WhatsApp Service et désactivez-le. Puis dans la fenêtre, autorisez le lancement automatiquement, le lancement secondaire et l'exécution en arrière-plan.",
    WhatsAppOppo: " <strong>Oppo:</strong> rendez-vous dans Paramètres > Gestion des applications > Liste des applications > trouvez WhatsApp Service et Power Saver > autorisez l'exécution en arrière-plan, revenez ensuite en arrière puis autorisez le démarrage automatique et le lancement d'autres applications ou services. Revenez ensuite à Paramètres > Batterie > désactivez l'Économiseur d'énergie intelligent > cliquez sur l'Économiseur d'énergie personnalisé > trouvez WhatsApp Service et autorisez-le à se lancer en arrière-plan. Revenez en arrière et autorisez l'Arrêt Rapide des Applications.",
    WhatsAppXiaomi: "<strong>Xiaomi:</strong> rendez-vous dans Paramètres > Applications > Gestion des applications >  trouvez WhatsApp Service > autorisez le démarrage automatique, puis descendez plus bas et trouvez l'Économiseur de batterie > Pas de restriction.",
    WhatsAppVivo: "<strong>Vivo:</strong> rendez-vous dans Paramètres > Batterie > Consommation élevé en arrière-plan > trouvez WhatsApp Service > désactivez-le.",
    WhatsAppOthers: "<strong>Autres:</strong> rendez-vous dans Paramètres > Applications > trouvez WhatsApp Service > Paramètres avancés > Optimisation de la batterie > trouvez WhatsApp Service à nouveau et désactivez-le.",
    WhatsAppHuawei1: "<strong>Huawei:</strong> Rendez-vous dans les Paramètres > trouvez WhatsApp Service dans le Gestionnaire des tâches. Faites glisser l'interface de l'application vers le bas pour la verrouiller. Après cela, appuyez sur l'interface de l'application pour revenir.",
    WhatsAppOppo1: "<strong>Oppo: </strong> Rendez-vous dans les Paramètres > trouvez WhatsApp Service dans le Gestionnaire des tâches. Appuyez sur l'icône Paramètres dans le coin supérieur droit. Appuyez sur Verrouiller. Ensuite, appuyez sur l'interface de l'application pour revenir.",
    WhatsAppXiaomi1: "<strong>Xiaomi:</strong> Rendez-vous dans les Paramètres > trouvez WhatsApp Service dans le Gestionnaire des tâches. Appuyez longuement sur l'onglet de l'application et verrouillez-le. Après cela, appuyez sur l'interface de l'application pour revenir.",
    WhatsAppStartMonite: "Cliquez sur Démarrer la surveillance et l'icône de l'application va disparaître. Pour les versions 10 et supérieures d'Android, l'icône de l'application sera remplacé par une icône 'WLAN'. Il est préférable de masquer cette icône dans un dossier invisible.",
    WhatsAppVerify: "Vérifiez votre installation après avoir terminé",
    MoniteSupportTeam: "Si tout est bien configuré, vous pouvez cliquer sur 'Vérification de la configuration' ci-dessous. Cela va lier l'appareil cible à votre compte en ligne. La page actuelle va vous mener au tableau de bord en ligne, où vous pourrez vérifier tous les fichiers. Si vous ne parvenez pas à installer l'application, n'hésitez pas à contacter l'équipe technique.",
    DataWarn: "Avertissement concernant l'utilisation des données",
    DataTerm: "Le téléchargement de fichiers volumineux (photos, vidéos, audios et documents) avec les données mobiles peut entraîner des frais supplémentaires sur le téléphone cible et va donc indiquer au propriétaire du téléphone qu'il se passe quelque chose. Vous êtes sûrs de vouloir télécharger les fichiers médias avec les données mobiles?",
    DataTermNote: "<strong>Remarque:</strong> l'historique des discussions, le statut, les appels et les activités WhatsApp ne sont pas soumis à cette condition. Par défaut, ces fichiers sont téléchargés via les données mobiles.",
    ScreenTime: "Durée d'activité sur WhatsApp (7 derniers jours)",
    WhatsAppChats: "Discussions WhatsApp",
    WhatsAppCalls: "Appels WhatsApp",
    Calls: "Appels",
    CallRecording: "Enregistrement d'appel",
    WhatsAppStatus: "Statut WhatsApp",
    WhatsAppPhotos: "Photos sur WhatsApp",
    WhatsAppVideos: "Vidéos sur WhatsApp",
    WhatsAppVoices: "Messages vocaux",
    Voices: "vocaux",
    WhatsAppDocuments: "Fichiers sur WhatsApp",
    Documents: "Fichiers",
    WhatsAppActivities: "Activités sur WhatsApp",
    Activities: "Activités",
    WhatsAppScreenshots: "Captures d'écran",
    Screenshots: "Captures d'écran",
    uploadTle: "Les fichiers volumineux (photos, vidéos, audios et documents) sont téléchargés par défaut avec le Wi-Fi. Vous pouvez le modifier dans Mes produits> Paramètres.",
    searchCallerName: "rechercher le nom de l'appelant",
    CallerName: "Nom de l'appelant",
    onlyCover: "* iSeeGuard pour WhatsApp ne téléchargera que des couvertures vidéo.",
    screenFeature: "Découvrez la fonction de capture d'écran automatique",
    screenFeatureOne: "Cette fonction va automatiquement capturer l'écran WhatsApp, c'est activé uniquement quand la cible utilise WhatsApp.",
    screenFeatureTwo: "Vous pouvez choisir quand vous souhaitez télécharger les captures d'écran. En activant le téléchargement avec le Wi-Fi, vous recevrez régulièrement de nouvelles captures d'écran, cela évitera aussi les frais supplémentaires sur les données mobiles sur le téléphone de votre cible. L'autre option fera exactement l'opposé.",
    UploadWiFi: "Téléchargement avec le Wi-Fi",
    MoreConsumption: "Plus de consommation d'énergie",
    fasterfrequency: "Fréquence plus régulière des captures d'écran",
    nomoBileData: "Pas de consommation des données mobiles",
    UploadMobileDat: "Téléchargement avec les données mobiles",
    LessPowerConsumption: "Moins de consommation d'énergie",
    lowerFrequencyScreenshots: "Fréquence moins régulière des captures d'écran",
    usemobiledata: "Utilise plus de données mobiles",
    SuccessfullySet: "Mis en place avec succès ! ",
    SetSucess: "Vous avez bien configuré la capture d'écran automatique. Les captures d'écran seront téléchargé lorsque l'utilisateur cible utilisera WhatsApp et il y a une connexion réseau. Merci d'être patient.",
    wifi: "Wi-Fi",
    wifiText: "Les captures d'écran WhatsApp seront téléchargées grâce au réseau Wi-Fi.",
    MobileData: "Données mobiles",
    MobileDataText: "Les captures d'écrans WhatsApp seront téléchargées grâce aux données mobiles.",
    WhatsAppDownload: "Les fichiers multimédias peuvent être directement téléchargés à partir de chaque fonctionnalité.",
    tryagain: "Le délai pour votre demande est expiré. Veuillez réessayer plus tard.",
    beingUpload: "Les données sont en train d'être téléchargées. Cela peut prendre un moment de tout afficher, veuillez actualiser la page plus tard.",
    Chat: "Discussions",
    CallRecorde: "Enregistrement des appels",
    Play: "Jouer",
    AudioCall: "Appel audio",
    VideoCall: "Appel vidéo",
    startTle: "Découvrez la fonction pour enregistrer les appels passés sur WhatsApp",
    startTleOne: "<strong>1.</strong> Cette fonction utilise le microphone pour enregistrer les appels vocaux et vidéos sur WhatsApp, vous n'allez donc pas entendre la voix de l'autre personne, sauf si le haut-parleur est activé.",
    startTleTwo: "<strong>2.</strong> Par défaut, les fichiers audios seront automatiquement téléchargés avec le Wi-Fi. Cela vous évitera d'avoir des frais supplémentaires sur vos données mobiles. Vous pouvez modifier ces paramètres dans : Mes Produits > Paramètres > Téléchargement des données.",
    startTleThree: "<strong>3.</strong> Cette fonction peut enregistrer durant 20 minutes maximum à chaque fois, si la durée de l'appel dépasse les 20 minutes, l'enregistrement sera divisé en plusieurs parties durant le téléchargement.",
    Thumbnail: "La vignette",
    UpdateOverWifi: "Mettre à jour via Wi-Fi",
    wifiUpload: "Veuillez noter que les fichiers volumineux (photos, vidéos, audio et documents) seront téléchargés lorsque le téléphone cible utilise le réseau Wi-Fi.",
    wifiNote: "<strong>Remarque:</strong> l'historique des discussions, le statut, les appels et les activités WhatsApp ne sont pas soumis à cette condition. Par défaut, ces fichiers sont téléchargés via les données mobiles et le Wi-Fi.",
    ChatContact: "Contact de chat",
    Content: "Contenu",
    State: "Statut",
    screenTime: "Durée d'activité sur",
    whatAppPathOne: "Rendez-vous dans Paramètres > Accessibilité > WhatsApp Service > assurez-vous qu'il est bien activé.",
    whatAppPathTwo: "Rendez-vous dans Paramètres > cherchez Sécurité > Autres paramètres de sécurité > Administrateur de l'appareil > WhatsApp Service > assurez-vous qu'il est bien activé.",
    whatAppPathThree: "Rendez-vous dans Paramètres > Applications > cherchez WhatsApp Service > Autorisations > Stockage > assurez-vous qu'il est bien activé",
    whatAppPathFour: "Rendez-vous dans Paramètres > Applications > cherchez WhatsApp Service > Notifications > assurez-vous qu'il est bien activé.",
    whatAppPathFive: "Rendez-vous dans Paramètres > cherchez Sécurité > Autres paramètres de sécurité > Utilisation des données > WhatsApp Service > assurez-vous qu'il est bien activé.",
    whatAppPathSix: "Rendez-vous dans Paramètres > Applications > cherchez WhatsApp Service > Permissions > Microphone > assurez-vous qu'il est bien activé.",
    whatsAppSetupError: "*  Désolé, la vérification a échoué. Assurez-vous d'avoir bien installé l'application iSeeGuard pour WhatsApp sur le téléphone cible.",
    monitorReal: "Vous permet de surveiller le WhatsApp de quelqu'un comme s'il était réel.",
    mustFinshSet: "*Vous n'avez pas fini les réglages. Veuillez télécharger les captures d'écran sous Wi-Fi ou données mobiles.",
    yourEmail: "Votre mail :",
    whatsAppDelete: "Afin de protéger votre vie privée, iSeeGuard cessera la surveillance et supprimera toutes les données.",
    Store: "Boutique",
    monitorAndroidDevice: "Surveiller les appareils Android",
    androidMoniteName: "iSeeGuard Pro - Surveillance Android",
    whatsAppMoniteName: "iSeeGuard - Surveillance WhatsApp",
    monitoriOSDevice: "Surveiller les appareils iOS",
    iPhoneMoniteName: "iSeeGuard Pro - Surveillance iPhone",
    iCloudMoniteName: "iSeeGuard Pro - Surveillance iCloud",
    cancelNote: "<strong>Note:</strong> En annulant le renouvellement automatique, votre compte restera actif jusqu'à la date d'expiration. Si vous souhaitez continuer à utiliser notre service, vous devez le renouveler manuellement à ce moment-là.",
    whatsAppSync: "La synchronisation des données est temporairement indisponible. Les données commenceront à se synchroniser une fois que le téléphone cible sera allumé avec une connexion internet et que l'application iSeeGuard pour WhatsApp est bien lancé sur l'appareil cible.",
    SyncErrWhy: "Pourquoi iSeeGuard pour WhatsApp ne peut pas synchroniser de manière temporaire vos données ?",
    FirstReason: "Premièrement, iSeeGuard pour WhatsApp synchronise automatiquement vos nouvelles données toutes les 15 minutes. Deuxièmement, vous pouvez aussi synchroniser vos données en cliquant sur le bouton 'Sync' pour chaque élément. Le temps de synchronisation de vos données va dépendre de leur taille et de la vitesse de la connexion Internet de l'appareil cible.",
    ByDefualt: "Par défaut, les fichiers volumineux (photos, vidéos, fichiers audios et documents) seront téléchargés uniquement lorsque le téléphone cible sera connecté au Wi-Fi. L'historique des messages, des statuts, des appels et des activités sur WhatsApp n'auront pas cette condition. Vous pouvez modifier cela dans : Mes Produits > Paramètres.",
    ReasonExample: "Voici quelques-unes des raisons pour lesquelles vous ne pouvez pas afficher les nouvelles données :",
    NonProductReasons: "Raisons non liées au produit :",
    NonProductReasons1: "L'appareil cible est éteint.",
    NonProductReasons2: "L'appareil cible n'est pas connecté à un réseau Wi-Fi ou alors celui-ci est instable.",
    NonProductReasons3: "WhatsApp a été désinstallé sur l'appareil cible.",
    NonProductReasons4: "WhatsApp n'a pas été utilisé sur l'appareil cible.",
    NonProductReasons5: "La date et l'heure sur l'appareil cible ne correspondent pas à l'heure actuelle. Allez alors dans les Paramètres > Paramètres généraux > Date et heure > activer la gestion de la date et de l'heure automatique.",
    ProductReasons: "Raisons du produit :",
    ProductReasons1: "L'application iSeeGuard pour WhatsApp est inactive ou a été désinstallée sur l'appareil cible. Dans ce cas, redémarrez l'appareil cible ou réinstallez l'application.",
    ProductReasons2: "Certaines autorisations pour iSeeGuard pour WhatsApp sont désactivées sur l'appareil cible, notamment l'accessibilité et le stockage. Vérifiez les autorisations et suivez les instructions pour faire tout fonctionner manuellement.",
    SelectAll: "Sélectionner",
    DeleteFiles: "Supprimer les fichiers",
    DeleteConfirm: "Vous êtes certain de vouloir supprimer ces fichiers ?",
    DeleteSuccess: "Suppression réussie !",
    DownloadFiles: "Télécharger les fichiers",
    DownloadConfirm: "Vous êtes certain de vouloir télécharger ces fichiers ?",
    DownloadTips: "<strong>Remarque:</strong> les fichiers téléchargés ne seront plus sur le tableau de bord.",
    DownloadWaiting: "Cela peut prendre quelques minutes, cela dépend de la taille des fichiers.",
    DownloadSuccess: "Téléchargement terminé !",
    DownloadSuccessTips: "Vous pouvez trouver vos fichiers téléchargés dans l'Historique des Téléchargements.",
    DownloadFailed: "Le téléchargement a échoué. Veuillez réessayer sur un réseau stable.",
    DeleteFailed: "Impossible à supprimer. Merci de réessayer.",
    Processing: "En traitement...",
    ToppingTips: "cliquez pour signaler cette conversation",
    MonStepFollowTitle: "Suivez les étapes ci-dessous pour activer votre produit",
    MonStepImportantTitle: "Information importante :",
    MonStepTitle1: "Téléchargez le logiciel sur l'ordinateur cible",
    MonStepTitle2: "Installez le logiciel et terminez la configuration",
    MonStepTitle3: "Installez l'extension Chrome (optionnel)",
    MonStepTitle4: "Allez sur le tableau de bord en ligne",
    MonStepImportant1: "<b>1.</b> Vous avez besoin d'un accès physique à l'ordinateur cible.",
    MonStepImportant2: "<b>2.</b> N'utilisez pas ce produit pour faire des choses illégales.",
    MonStepImportant3: "<b>3.</b> MoniVisor est en deux parties : le logiciel et le tableau de bord en ligne.",
    MonStepImportant4: "<b>4.</b> Nous vous recommandons vivement d'ajouter le logiciel dans liste des logiciels autorisées sur l'antivirus de l'ordinateur cible, pour éviter que le logiciel se fasse supprimé ou qu'il ne fonctionne pas correctement.",
    MonStepImportant5: "<b>5.</b> Il est recommandé d'utiliser le navigateur en mode privé pour télécharger ce logiciel et d'effacer toutes les traces de l'installation.",
    MonStep1Desc: "MoniVisor pour Windows fonctionne comme un logiciel caché sur l'ordinateur et comme étant un tableau de bord en ligne. Pour commencer, vous avez besoin d'un accès physique à l'ordinateur cible, puis de copier et coller l'URL ( <b style=\"color: #347aeb\">http://www.installfree.net</b> ) dans un navigateur pour télécharger le logiciel.",
    MonStep2Desc: "Ensuite, installez le logiciel et terminez la configuration en suivant le guide à l'écran. ",
    MonStep2Note: '<b>Remarque :</b> Vous aurez probablement besoin de l\'accès Administrateur Windows pour accepter le lancement du logiciel. Si c\'est le cas, cliquez sur "Oui", "Exécuter" et "Exécuter quand même". Si l\'option "Oui" ou "Exécuter" n\'est pas disponible, cliquez sur "+ d\'informations" pour afficher l\'option.',
    MonStep2li_1: "L'installation du logiciel prendra un certain temps.",
    MonStep2li_2: "Activez votre compte sous licence et votre mot de passe.",
    MonStep2li_3: "Si le logiciel détecte un logiciel antivirus sur l'ordinateur, veuillez suivre les étapes pour ajouter le logiciel MoniVisor pour Windows à la liste des logiciels autorisés. Une fois que c'est fait, vous remarquerez que le logiciel passera automatiquement en exécution cachée.",
    MonStep3Desc: "Si le navigateur par défaut sur l'ordinateur cible est Chrome, vous aurez besoin d'une extension pour surveiller la boîte mail (Gmail, Outlook ou Yahoo Mail) et les messages sur les réseaux sociaux (Facebook, WhatsApp, Twitter, Instagram ou Skype). Nous vous recommandons vivement d'installer l'extension pour augmenter les possibilités de surveillance du logiciel. Suivez les étapes ci-dessous.",
    MonStep3li_1: "Ouvrez le navigateur Chrome.",
    MonStep3li_2: "Cliquez sur les 3 petits points en haut à droite du navigateur puis cliquez sur : <b>'Plus d'outils'</b> &gt; <b>'Extensions'</b>.",
    MonStep3li_3: "Activez <b>'Mode développeur'</b> en cliquant sur le bouton en haut à droite du navigateur.",
    MonStep3li_4: "Le bouton <b>'Load unpacked'</b> sera affiché dans la barre du menu.",
    MonStep3li_5: "Cliquez sur le bouton <b>'Load unpacked'</b> puis allez dans le dossier <b style=\"color: #347aeb;\">C:\\Program Files (x86)\\Windows Assistant App\\MV\\extension</b> and select the <b>'moni-chrome-extension'</b> .\n",
    MonStep3li_6: "L' <b>'Extension'</b> est maintenant installée sur votre ordinateur.",
    MonStep3li_7: "Désactivez le <b>'Mode développeur'</b> pour terminer le processus.",
    MonStep3li_8: "Si l'extension est correctement installée, vous la verrez dans la liste des extensions.\n",
    MonStep4Desc1: "La configuration nécessaire sur l'ordinateur cible est maintenant terminée. Vous allez ensuite constater que le logiciel passera en mode masqué et qu'il fonctionnera en arrière-plan.",
    MonStep4Desc2: "Vous pouvez maintenant accéder au site internet de iSeeGuard pour vous connecter à votre compte, peu importe le support (votre ordinateur, tablette ou téléphone). Cliquez sur le bouton \"Vérifier la configuration\" pour lier rapidement votre compte, le tableau de bord devrait apparaître et vous pourrez vérifier toutes les données. Si ce n'est pas le cas, contactez l'équipe technique.",
    MonStepError: "* Malheureusement, la vérification a échoué. Veuillez vous assurer que le logiciel assistant MoniVisor est bien installé sur l'ordinateur cible.",
    MonVerifySetup: "Vérifiez la configuration",
    MonUninstallBigTitle: "Désinstallation du logiciel MoniVisor Desktop",
    MonUninstallTitle: "Nous proposons 2 manières pour désinstaller le logiciel sur l'ordinateur cible. Vous pouvez le désinstaller à distance en cliquant sur le bouton ci-dessous, ou en accédant directement à l'ordinateur cible pour faire une désinstallation manuelle.",
    MonUninstallNote: "Remarques :",
    MonUninstallNote1: "1. La désinstallation à distance n'est possible que quand l'ordinateur cible est en ligne.",
    MonUninstallNote2: '2. La désinstallation du logiciel ne va pas dissocier votre compte et ne supprimera pas les données récoltées. Toutefois, vous n\'aurez forcément plus de nouvelles données. Si vous souhaitez dissocier votre compte, allez dans les "Paramètres" et cliquez sur le bouton "Dissocier".',
    MonUninstallMethod1: "Méthode 1 : désinstallation à distance en un clic.",
    MonUninstallMethod1Desc: "Cliquez simplement sur le bouton ci-dessous et le logiciel se désinstallera automatiquement.",
    MonUninstallMethod2: "Méthode 2 : désinstallation manuelle.",
    MonUninstallMethod2Desc: 'L\'application est installée à cet emplacement : <b style="color: #347aeb;">C:\\Program Files (x86)\\Windows Assistant App\\MV</b>',
    MonUninstallTheStep: "Voici les étapes :",
    MonUninstallTheStep1: "Étape 1 : allez sur l'ordinateur cible et trouvez les fichiers, en suivant le chemin dans vos dossiers.",
    MonUninstallTheStep2: 'Étape 2 : puis cliquez sur le fichier <b style="color: #347aeb;">unins000.exe</b> pour lancer la désinstallation.',
    MonUninstallTheStep3: "Étape 3 : vous n'avez rien d'autre à faire, redémarrez simplement l'ordinateur pour confirmer la désinstallation du logiciel.",
    MonUninstallError: "L'ordinateur cible est maintenant hors ligne. Vérifiez l'état de l'appareil sur votre tableau de bord ou dans les \"Paramètres\" et réessayez lorsque l'ordinateur cible est en ligne.",
    MonUninstallTip: "Cela ne va pas désactiver votre compte, mais vous n'allez plus recevoir de nouvelles données.",
    MonUninstallProblem: "Vous êtes sûr de vouloir désinstaller le logiciel de l'ordinateur cible ?",
    MonUninstallU: "Désinstaller",
    MonUninstallSuccess: "C'est bon ! Vous avez bien désinstallé le logiciel sur l'ordinateur cible !",
    MonUnbundlingProblem: "Est-ce que vous êtes sûr de vouloir dissocier l'appareil ?",
    MonUnbundlingTip: "Pour des raisons de sécurité, toutes les données vont être supprimées.",
    MonUnbundlingSuccess: "C'est bon ! Vous avez bien dissocié le logiciel !",
    MonInstallationHelp: "Guide d'aide à l'installation",
    MonInstallationDesc: "Voici le guide pour vous aider à ajouter le logiciel et ses fichiers sur la liste des logiciels autorisés par l'antivirus. Dans le but d'éviter que le logiciel ne soit détruit ou mise en quarantaine sur l'ordinateur cible. Vous trouverez ausi le guide d'installation de l'extension Chrome MoniVisor. Veuillez lire attentivement les étapes suivantes.",
    MonInstallationHowTo1: "Comment mettre le logiciel dans la liste des logiciels autorisés par l'antivirus",
    MonInstallationHowTo2: "Comment installer l'extension Chrome MoniVisor",
    MonInstallationHowTo3: "Guide sur la manière de mettre le logiciel dans la liste des logiciels autorisés par l'antivirus.",
    MonInstallationStepTitle: "Les étapes peuvent varier suivant l'antivirus en question, bien que le processus de base soit le même. Voici les étapes à suivre :",
    MonInstallationStep1: '<span>Étape 1 :</span> regardez si MoniVisor est bien installé à cet endroit : <b style="color: #347aeb;">C:\\Program Files (x86)\\Windows Assistant App</b>',
    MonInstallationStep1Desc: 'Le nom du fichier est : <b style="color: #347aeb;">Setupsvc.exe</b>',
    MonInstallationStep2: "<span>Étape 2 :</span> Regardez quel antivirus est installé sur l'ordinateur cible et suivez les instructions ci-dessous pour faire une bonne configuration suivant l'antivirus que vous rencontrez.",
    MonInstallationStep2Desc: "Guide sur la manière d'installer l'extension Chrome MoniVisor.",
    MonWebChatsOfflineTip: "L'ordinateur cible est maintenant hors ligne. Aucune donnée ne peut être mise à jour tant que l'ordinateur n'est pas en ligne.",
    MonWebChatsOfflineTipDesc: "Qu'est ce qui est hors ligne exactement ? Cela peut arriver quand l'ordinateur cible est déconnecté d'Internet, qu'il est éteint, que le logiciel a été désinstallé ou que le logiciel a été détruit pour des raisons inconnues.",
    MonWebChatsNote1: 'Remarque : vérifiez que l\'ordinateur cible est bien en ligne et que l\'extension Google Chrome est bien installée. Cliquez <a style="color:#347aeb" href="/monivisor-installation-guide-win" target="_blank">ici</a> pour consulter le guide complet d\'installation de l\'extension.',
    MonScreenClickTitle: "Commencez directement avec MoniVisor et sa fonction capture d'écran",
    MonScreenClickDesc: "Cliquez sur \"Démarrer\" pour prendre des captures d'écran sur l'ordinateur cible.<br/> Les captures d'écran seront bien prises toutes les 15 secondes durant 5 minutes.",
    MonScreenClickNote: "Remarques:",
    MonScreenClickNote1: "1. Cette option ne peut être utilisé que lorsque l'ordinateur cible est en ligne.",
    MonScreenClickNote2: "2. Vous pouvez arrêter les captures d'écran durant les 5 minutes après le début. Si vous ne l'arrêtez pas manuellement, il s'arrêta automatiquement au bout de cinq minutes, puis vous pourrez relancer le processus.",
    MonScreenClickError1: "L'ordinateur cible est hors ligne. Vérifiez l'état de l'appareil, puis essayez cette option quand l'ordinateur est en ligne.",
    MonScreenClickError2: "Vous êtes sûr de vouloir supprimer les captures d'écran ?",
    MonScreenSelectAll: "Tout sélectionner",
    MonScreenDeleting: "Suppression...",
    MonScreenDeleteFiles: "Supprimer les fichiers",
    MonScreenSuccess1: "C'est bon ! Vous avez supprimé ",
    MonScreenSuccess2: " captures d'écran !",
    MonFileAction: "Action",
    MonFileFileName: "Nom du fichier",
    MonFileDate: "Date",
    MonLoginUserName: "Nom d'utilisateur",
    MonLoginActivityTh: "Activité",
    MonLoginPrinterName: "Nom de l'imprimante",
    MonLoginFileName: "Nom du fichier",
    MonLoginPages: "Pages",
    MonExportDataSetting: "Paramètres des données :",
    MonExportDeleteExportedData: "Supprimer les données exportées",
    MonExportDesc1: "1. Les fichiers multimédias de l'historique des messages ne peuvent être exportés que sous forme de texte.",
    MonExportDesc2: "2. Les captures d'écran peuvent être téléchargées en vrac dans les résultats.",
    MonPurchaseBuyWin: "Choisissez votre forfait pour MoniVisor pour Windows",
    MonPurchaseGuideType2: "Logiciel de surveillance sous Windows invisible.",
    MonMyProductPageDesc1: "Un logiciel de surveillance à distance pour vérifier les fichiers de l'ordinateur sous Windows.",
    MonMyProductPageDesc2: "Fonctionnement indétectable et tableau de bord en ligne pour afficher les données de n'importe où et n'importe quand.",
    MonDashboardName: "Nom",
    MonDashboardComputerID: "ID de l'ordinateur ",
    MonDashboardLatestEmails: "Derniers e-mails",
    MonDashboardMore: "Plus",
    MonDashboardLatestBrowsingHistories: "Derniers historiques du navigateur",
    MonDashboardSender: "Expéditeur",
    MonDashboardSubject: "Sujet",
    MonDashboardDate: "Date",
    MonDashboardDomain: "Domaine",
    MonDashboardTitle: "Titre",
    MonSettingsUninstallGuide: "Guide de désinstallation",
    MonSettingsHelpDocument: "Guide d'aide à l'installation",
    MonSettingsSoftwareVersion: "Version du logiciel sur l'ordinateur cible ",
    MonSettingsWhat: "Quoi de neuf",
    MonRequestLong: "Nous traitons votre demande. Veuillez patienter une minute et réessayez.",
    MonUnknown: "Inconnu",
    MoniVisor: "MoniVisor",
    MoniVisorWebActivity: "Activité Web",
    MoniVisorActivityChrome: "Chrome",
    MoniVisorActivityFirefox: "Firefox",
    MoniVisorActivityEdge: "Microsoft Edge",
    MoniVisorActivityIE: "Internet Explorer",
    MoniVisorActivityOpera: "Opera",
    MoniVisorDownloadHistory: "Historique de téléchargement",
    MoniVisorWebChats: "Messages Web",
    MonAppActivity: "Activité des applications",
    MonKeylogger: "Enregistreur de touches",
    MonScreenshot: "Captures d'écran",
    MonScreenshotSet: "Paramètres des captures d'écran",
    MonScreenshotView: "Voir les captures d'écran",
    MonLoginActivity: "Activité de connexion",
    MonPrintActivity: "Activité d'impression",
    MonUsbConnection: "Connexion USB",
    MonDataExport: "Export de données",
    MonComputer: "Moniteur ordinateur",
    MonFileActivity: "Activité des fichiers",
    MonRemaining: "Temps restant:",
    MonScreenIngError: "La tâche de capture d'écran est déjà en cours. Veuillez réessayer plus tard.",
    MonDataExportOnly: "Seulement 2000 enregistrements peuvent être exportés à la fois.",
    MonSomeTime: "La synchronisation des données peut prendre un certain temps. Veuillez être patient ...",
    MonLastUsed: "Dernière utilisation",
    MonFilePath: "Chemin du fichier",
    MonTimeUsage: "Temps d'utilisation",
    MonReceived: "Reçu",
    MonSent: "Expédié",
    MoniVisorWebChatsWhatsApp: "WhatsApp",
    MoniVisorWebChatsFacebook: "Facebook",
    MoniVisorWebChatsTwitter: "Twitter",
    MoniVisorWebChatsInstagram: "Instagram",
    MoniVisorWebChatsSkype: "Skype",
    MoniVisorWebChatsPinterest: "Pinterest",
    MonWebMail: "Courriel Web",
    MonWebMailGmail: "Gmail",
    MonWebMailOutlook: "Outlook",
    MonWebMailYahoo: "Yahoo",
    MonDataExportWebActivityChrome: "Activité Web - Chrome",
    MonDataExportWebActivityIE: "Activité Web - IE",
    MonDataExportWebActivityEdge: "Activité Web - Edge",
    MonDataExportWebActivityOpera: "Activité Web - Opera",
    MonDataExportWebActivityFirefox: "Activité Web - Firefox",
    MonDataExportDownloadHistoryChrome: "Télécharger l'historique - Chrome",
    MonDataExportDownloadHistoryIE: "Télécharger l'historique - IE",
    MonDataExportDownloadHistoryEdge: "Télécharger l'historique - Edge",
    MonDataExportDownloadHistoryOpera: "Télécharger l'historique - Opera",
    MonDataExportDownloadHistoryFirefox: "Télécharger l'historique - Firefox",
    MonScreenStop: "Arrêtez",
    MonScreenMaxDownTip: "* Un maximum de 50 images peuvent être téléchargées à la fois et les données téléchargées seront supprimées.",
    MonWebMailAttachments: "Chemin de la pièce jointe",
    MonWebMailFrom: "De",
    MonKeyloggerTip: 'Remarque: veuillez vérifier que l\'ordinateur cible est en ligne et que le logiciel n\'a pas été tué par un antivirus. Cliquez <a style="color:#347aeb" href="/monivisor-installation-guide-win" target="_blank">ici</a> pour consulter le guide complet pour ajouter le logiciel à la liste blanche de l\'antivirus.',
    MonInsert: "Insérer",
    MonEject: "Éjecter",
    MonLogon: "Se connecter",
    MonLogoff: "Se déconnecter",
    MonCreate: "Créer",
    MonCopy: "Copie",
    MonDelete: "Supprimer",
    MonRename: "Renommer",
    MonImage: "Image",
    MonVideo: "Vidéo",
    MonFile: "Fichier",
    MonLocation: "Emplacement",
    MonPasteNote: "<b>Hinweis:</b> Sie müssen den vollständigen Installationspfad für Dateien einfügen oder die Anzeige versteckter Ordner im Windows-Datei-Explorer vorübergehend zulassen, um die Ordner in der Antiviren-App manuell auswählen zu können. Fügen Sie den MV-Ordner unter dem Installationspfad oder den angegebenen vollständigen Dateinamen zur Whitelist hinzu, abhängig von den Anforderungen der verschiedenen Antivirensoftware.",
    kdsMoniVisor: "MoniVisor pour Win",
    MonMisseVoice: "Appel vocal manqué",
    MonMisseVideo: "Appel vidéo manqué",
    MonContact: "Contact",
    MonMonitorComputers: "Surveiller les ordinateurs",
    MonWindowsMonitoring: "MoniVisor - Surveillance Windows",
    ParamError: "Erreur de paramètre",
    TokenError: "Connexion invalide",
    UserVIPExpired: "L'adhésion de l'utilisateur a expiré ou n'existe pas",
    NotfoundHttp: "L'adresse demandée n'existe pas",
    ServerError: "erreur du serveur",
    HeadersMiss: "Le paramètre headers est manquant",
    MonitorNow: "Surveiller maintenant",
    AboutUs: "à propos de nous",
    PrivacyPolicy: "Politique de confidentialité",
    TermsConditions: "Termes & conditions",
    Disclaimer: "Avertissement",
    DisclaimerDesc: "LOGICIEL ISEEGUARD DESTINÉ À UN USAGE LÉGAL UNIQUEMENT.<br />L'installation de ce logiciel sous licence sur un appareil pour lequel vous n'avez pas de droits de surveillance peut enfreindre les lois de votre pays ou région. Vous êtes entièrement responsable de son téléchargement, de son installation et de son utilisation. Si vous choisissez un appareil de surveillance sans autorisation, iSeeGuard ne pourra être tenu responsable. Tous les droits non expressément accordés aux présentes sont réservés à iSeeGuard.",
    Refuse: "Refuser",
    DialOut: "Appeler",
    IncomingCall: "Entrant",
    RenewDialogTips: "La démo ne peut plus être exploitée. Vous pouvez créer un compte et acheter un forfait pour profiter de toutes les fonctionnalités de surveillance avancées.",
    Permanent: "Éternel",
    Capacity: "Capacité",
    Clipboard: "Presse-papiers",
    SensitiveWord: "Mots Sensibles",
    AppUsed: "Application d'occasion",
    AppUsedToday: "Application utilisée aujourd'hui",
    Add: "Ajouter",
    Modify: "Modifier",
    Operate: "Fonctionner",
    MacStepTitle2: "Installez le logiciel",
    MacStepTitle3: "terminez la configuration",
    MacStepTitle5: "Terminer l'installation",
    MacStepOneDesc: "Ouvrez <strong>www.iseeguard.com</strong> avec un navigateur sur l'appareil cible et téléchargez l'application iSeeGuard Pro pour Mac.",
    MacStepTwoDesc: "<strong>Remarque :</strong> Il est recommandé de le télécharger directement sur l'ordinateur de la personne que vous souhaitez surveiller. Si vous pouvez accéder physiquement au MAC cible de temps en temps, vous pouvez également le télécharger et le surveiller sur votre propre ordinateur.",
    MacStepThreeDesc: "Après le téléchargement, cliquez sur le fichier pkg pour lancer le processus d'installation.",
    MacStepFourDesc: "Veuillez sélectionner ce que vous devez surveiller, cliquez sur Suivant pour continuer.",
    MacStepFiveDesc: "Mactrack a été installé.",
    MacStepSixDesc: "Connectez-vous à votre compte.",
    ClickHere: "Cliquez ici",
    IosStepOneDesc: "Ouvrez <strong>panel.iseeguard.com/store/ios</strong> avec un navigateur sur l'appareil cible et téléchargez l'application iSeeGuard Pro pour iOS.",
    IosStepTwoDesc: "<strong>Remarque :</strong> Il est recommandé de le télécharger directement sur l'ordinateur de la personne que vous souhaitez surveiller. Si vous pouvez accéder physiquement à l'iOS cible de temps en temps, vous pouvez également le télécharger et le surveiller sur votre propre ordinateur.",
    IosStepTitle3: "Sauvegarde les données d'iTune",
    IosStepThreeDesc: "Téléchargez le logiciel iTunes, connectez le téléphone mobile à surveiller au logiciel iTunes et effectuez une sauvegarde des données.",
    IosStepTitle5: "Analyser les données de l'iPhone cible",
    IosStepTitle6: "Analyser les données",
    IosStepTitle7: "Commencez à surveiller l'iPhone cible sur l'ordinateur",
    IosStepFiveDesc: "si des fichiers de sauvegarde sont disponibles sur l'ordinateur, ISeeGuard Pro affichera la sauvegarde trouvée. Vous pouvez sélectionner le fichier de sauvegarde du périphérique cible et analyser les données à partir de celui-ci.",
    IosStepSixDesc: "Cliquez sur Suivant et affichez la page des données d'analyse. Le temps d'attente pour la première analyse des données sera plus long.",
    IosStepSevenDesc: "Une fois le processus de numérisation terminé, toutes les informations numérisées seront affichées sur le tableau de bord et vous pourrez basculer entre différents types de fichiers et afficher les détails. En outre, vous êtes autorisé à exporter toutes les données vers l'ordinateur.",
}
