/*
 * @Author: withering 
 * @Date: 2021-07-03 16:15:29 
 * @Last Modified by:   withering 
 * @Last Modified time: 2021-07-03 16:15:29 
 */
import request from "@/utils/request"
import qs from 'qs'
/**
 * 获取指定文件指令
 * @param {*} equipmentId 
 * @param {*} params 
 * @returns 
 */
export function getAssignFile(equipmentId, params) {
    // return request.post('/command/getAssignFile/' + equipmentId, params)
    return request({
        url: '/command/getAssignFile/' + equipmentId,
        method: 'post',
        data: qs.stringify(params)
    })
}
/**
 * 发送同步指令
 * @param {*} equipmentId 
 * @param {*} params 
 * @returns 
 */
export function synchronize(equipmentId, params) {
    // return request.post('/command/synchronize/' + equipmentId, params)
    return request({
        url: '/command/synchronize/' + equipmentId,
        method: 'post',
        data: qs.stringify(params)
    })
}
/**
 * 荧幕截屏指令
 * @param {*} equipmentId 
 * @returns 
 */
export function screenshot(equipmentId) {
    // return request.post('/command/screenshot/' + equipmentId)
    return request({
        url: '/command/screenshot/' + equipmentId,
        method: 'post',
    })
}
/**
 * 拍照指令
 * @param {*} equipmentId 
 * @returns 
 */
export function takePhotos(equipmentId) {
    // return request.post('/command/takePhotos/' + equipmentId)
    return request({
        url: '/command/takePhotos/' + equipmentId,
        method: 'post',
    })
}
/**
 * 查询设备是否在线
 * @param {*} equipmentId 
 * @returns 
 */
export function isUidOnline(equipmentId) {
    // return request.get('/command/isUidOnline/'+equipmentId)
    return request({
        url: '/command/isUidOnline/' + equipmentId,
        method: 'get',
    })
}