/*
 * @Author: withering 
 * @Date: 2021-07-03 17:11:04 
 * @Last Modified by:   withering 
 * @Last Modified time: 2021-07-03 17:11:04 
 */
import request from "@/utils/request"
/**
 * 获取键盘记录App列表
 * @param {*} equipmentId 
 */
export function getKeyloggerApplicationList(equipmentId, params) {
    // return request.get('/keyloggerApplication/'+equipmentId,params);
    return request({
        url: '/keyloggerApplication/' + equipmentId,
        method: 'get',
        params
    })
}
/**
 * 获取键盘记录列表
 * @param {*} equipmentId 
 * @param {*} params 
 * @returns 
 */
export function getList(equipmentId, params) {
    // return request.get('/keyloggers/' + equipmentId, params);
    return request({
        url: '/keyloggers/' + equipmentId,
        method: 'get',
        params
    })
}