import Vue from 'vue'
import VueRouter from 'vue-router'
/* AdminLayout */
import Home from './modules/home'
import Android_Admin from './modules/android_admin'
import Android_Demo from './modules/android_demo'
import WhatsApp_Demo from './modules/whatsapp_demo'
import WhatsApp_Admin from './modules/whatsapp_admin'
import Mac_Demo from './modules/mac_demo'
import Mac_Admin from './modules/mac_admin'

Vue.use(VueRouter)

// ? 重写push方法，解决相同路径跳转报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export const asyncRouterMap = [
]

export const constantRoutes = [
  Home,
  Android_Admin,
  Android_Demo,
  WhatsApp_Demo,
  WhatsApp_Admin,
  Mac_Demo,
  Mac_Admin,
]
const createRouter = () => new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
})

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

const router = createRouter()

// 路由
router.beforeEach((to, from, next) => {
  //如果存在promote_source参数
  if(to.query?.promote_source !== undefined && to.query?.promote_source !== ''){
    window.localStorage.setItem('PROMOTE_SOURCE', to.query.promote_source);
    window.localStorage.setItem('PROMOTE_EXPIRES', Date.now() + 1000 * 86400); //1天有效期
  }
  next()
})

export default router