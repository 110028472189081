<template>
  <el-container class="el-container-box">
    <Header />
    <AppMain />
    <Footer />
  </el-container>
</template>

<script>
import Header from "./components/Header"
import Footer from "./components/Footer"
import AppMain from "./components/AppMain"
export default {
  name: "HomeLayout",
  components: {
    Header,
    Footer,
    AppMain,
  },
  data() {
    return {};
  },
};
</script>