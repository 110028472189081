import AdminLayout from '@/layout/android_admin/index'
export default {
  path: '/admin/:equipmentId',
  name: 'Admin',
  component: AdminLayout,
  redirect: '/admin/dashboard',
  key: 'android',
  children: [
    {
      path: 'dashboard',
      name: 'Dashboard',
      component: () => import('@/views/android/dashboard/index'),
      meta: { headertitle: 'Android Dashboard', title: "Dashboard", icon: "home" }
    },
    {
      name: 'phoneFile',
      component: () => import('@/views/android/phone-file/index'),
      meta: { title: 'PhoneLogs', icon: "phone-01", show: true },
      path: 'phone-file',
      redirect: '/admin/phone-file/cal-logs',
      alwaysShow: true,
      children: [
        {
          path: 'cal-logs',
          name: 'callog',
          component: () => import('@/views/android/phone-file/cal-logs/index'),
          meta: { headertitle: 'Android CallLogs', title: 'CallLogs' }
        },
        {
          path: 'sms',
          name: 'Sms',
          component: () => import('@/views/android/phone-file/sms/index'),
          meta: { headertitle: 'Android Messages', title: 'Messages' }
        },
        {
          path: 'contacts',
          name: 'Contacts',
          component: () => import('@/views/android/phone-file/contacts/index'),
          meta: { headertitle: 'Android Contacts', title: 'Contacts' }
        },
        {
          path: 'browser-history',
          name: 'BrowserHistory',
          component: () => import('@/views/android/phone-file/browser-history/index'),
          meta: { headertitle: 'Android BrowserHistory', title: "BrowserHistory" }
        }, {
          path: 'photo-list',
          name: 'PhotoList',
          component: () => import('@/views/android/phone-file/photo-list/index'),
          meta: { headertitle: 'Android Photos', title: 'Photos' }
        },
        {
          path: 'photos',
          name: 'Photos',
          component: () => import('@/views/android/phone-file/photos/index'),
          meta: { headertitle: 'Android Photos', title: 'Photos' },
          hidden: true
        }, {
          path: 'videos',
          name: 'Videos',
          component: () => import('@/views/android/phone-file/videos/index'),
          meta: { headertitle: 'Android VideoPreview', title: 'VideoPreview' },
        },
        {
          path: 'applications',
          name: 'Applications',
          component: () => import('@/views/android/phone-file/applications/index'),
          meta: { headertitle: 'Android AppActivity', title: 'AppActivity' },
        },
        {
          path: 'keyloggers',
          name: 'Keyloggers',
          component: () => import('@/views/android/phone-file/keyloggers/index'),
          meta: { headertitle: 'Android Keylogger', title: 'Keylogger' },
        },
        {
          path: 'calendars',
          name: 'Calendars',
          component: () => import('@/views/android/phone-file/calendars/index'),
          meta: { headertitle: 'Android Calendar', title: 'Calendar' },
        }
      ]
    },
    {
      path: 'location-tracking',
      name: 'LocationTracking',
      redirect: '/admin/location-tracking/location',
      component: () => import('@/views/android/location-tracking/index'),
      meta: { title: 'LocationTracking', icon: "localization", show: false },
      alwaysShow: true,
      children: [
        {
          path: 'location',
          name: 'Location',
          component: () => import('@/views/android/location-tracking/location/index'),
          meta: { headertitle: 'Android Location', title: 'location' }
        },
        {
          path: 'geofence',
          name: 'Geofence',
          redirect: '/admin/location-tracking/geofence',
          component: () => import('@/views/android/location-tracking/geofence/index'),
          meta: { title: 'Geofence' },
          children: [
            {
              path: '',
              name: 'Geofence',
              component: () => import('@/views/android/location-tracking/geofence/list'),
              meta: { headertitle: 'Android Geofence', title: 'Geofence' },
            }, {
              path: 'create',
              name: 'CreateGeofence',
              component: () => import('@/views/android/location-tracking/geofence/create'),
              meta: { headertitle: 'Android AddGeofence', title: 'AddGeofence' },
            }, {
              path: 'edit/:id',
              name: 'EditGeofence',
              component: () => import('@/views/android/location-tracking/geofence/create'),
              meta: { headertitle: 'Android EditGeofence', title: 'EditGeofence' },
            }]
        },
        {
          path: 'wifi-loggers',
          name: 'WifiLoggers',
          component: () => import('@/views/android/location-tracking/wifi-loggers/index'),
          meta: { headertitle: 'Android WiFiLogger', title: 'WiFiLogger' }
        }]
    },
    {
      path: 'social-application',
      name: 'SocialApplication',
      redirect: '/admin/social-application/whatsapp',
      component: () => import('@/views/android/social-application/index'),
      meta: { title: 'SocialApps', icon: "social-application", show: false },
      alwaysShow: true,
      children: [
        {
          path: 'whatsapp',
          name: 'Whatsapp',
          component: () => import('@/views/android/social-application/whatsapp/index'),
          meta: { headertitle: 'Android Whatsapp', title: 'Whatsapp' }
        },
        {
          path: 'facebook',
          name: 'Facebook',
          component: () => import('@/views/android/social-application/facebook/index'),
          meta: { headertitle: 'Android Facebook', title: 'Facebook' }
        },
        {
          path: 'messenger',
          name: 'Messenger',
          component: () => import('@/views/android/social-application/messenger/index'),
          meta: { headertitle: 'Android Messenger', title: 'Messenger' }
        },
        {
          path: 'snapchat',
          name: 'Snapchat',
          component: () => import('@/views/android/social-application/snapchat/index'),
          meta: { headertitle: 'Android Snapchat', title: 'Snapchat' }
        },
        {
          path: 'instagram',
          name: 'Instagram',
          component: () => import('@/views/android/social-application/instagram/index'),
          meta: { headertitle: '', title: 'Instagram' }
        },
        {
          path: 'tinder',
          name: 'Tinder',
          component: () => import('@/views/android/social-application/tinder/index'),
          meta: { headertitle: 'Android Tinder', title: 'Tinder' }
        },
        {
          path: 'telegram',
          name: 'Telegram',
          component: () => import('@/views/android/social-application/telegram/index'),
          meta: { headertitle: 'Android Telegram', title: 'Telegram' }
        },
        {
          path: 'kik',
          name: 'Kik',
          component: () => import('@/views/android/social-application/kik/index'),
          meta: { headertitle: 'Android Kik', title: 'Kik' }
        },
        {
          path: 'viber',
          name: 'Viber',
          component: () => import('@/views/android/social-application/viber/index'),
          meta: { headertitle: 'Android Viber', title: 'Viber' }
        },
        {
          path: 'line',
          name: 'Line',
          component: () => import('@/views/android/social-application/line/index'),
          meta: { headertitle: 'Android Line', title: 'Line' }
        },
        {
          path: 'wechat',
          name: 'Wechat',
          component: () => import('@/views/android/social-application/wechat/index'),
          meta: { headertitle: 'Android Wechat', title: 'Wechat' }
        },
        {
          path: 'qq',
          name: 'QQ',
          component: () => import('@/views/android/social-application/qq/index'),
          meta: { headertitle: 'Android QQ', title: 'QQ' }
        }]
    },
    {
      path: 'remote-control',
      name: 'RemoteControl',
      redirect: '/admin/remote-control/call-recording',
      component: () => import('@/views/android/remote-control/index'),
      meta: { title: 'RemoteControl', icon: "remote-control", show: false },
      alwaysShow: true,
      children: [
        {
          path: 'call-recording',
          name: 'RecordCalls',
          component: () => import('@/views/android/remote-control/call-recording/index'),
          meta: { headertitle: 'Android RecordCalls', title: 'RecordCalls' }
        },
        {
          path: 'capture-screenshots',
          name: 'CaptureScreenshots',
          component: () => import('@/views/android/remote-control/capture-screenshots/index'),
          meta: { headertitle: 'Android CaptureScreenshots', title: 'CaptureScreenshots' }
        },
        {
          path: 'take-photos',
          name: 'TakePhotos',
          component: () => import('@/views/android/remote-control/take-photos/index'),
          meta: { headertitle: 'Android TakePhotos', title: 'TakePhotos' }
        }
      ]
    },
    {
      path: 'export-data',
      name: 'ExportData',
      component: () => import('@/views/android/export-data/index'),
      meta: { headertitle: 'Android DataExport', title: 'DataExport', icon: "data-output" }
    }
  ]
}