var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"name":"Sidebar"}},[_c('div',{staticClass:"sidebar"},[_c('el-scrollbar',{attrs:{"wrap-class":"scrollbar-wrapper"}},[_c('el-collapse-transition',[_c('div',{staticClass:"side_ul"},_vm._l((_vm.menuList),function(item,index){return _c('div',{key:item.name,staticClass:"side_li"},[(!item.children || item.children.length === 0)?_c('router-link',{staticClass:"pacity cursor-pointer",class:[
                item.children && item.children.length > 0
                  ? 'pacityChild'
                  : '',
                item.children && item.meta.show ? 'pacitys' : '',
              ],attrs:{"tag":"div","to":{ name: item.name },"active-class":"active-menu","exact":""},nativeOn:{"click":function($event){return _vm.handleMenuClick()}}},[_c('svg-icon',{attrs:{"icon-class":item.meta.icon?item.meta.icon:'',"class-name":"icon"}}),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.generateLanguageFn(item.meta.title))+" ")])],1):_c('div',{staticClass:"pacity pacityChild cursor-pointer",class:[item.meta.show ? 'pacitys' : ''],on:{"click":function($event){return _vm.updateAssignMenuShowStatus({
                  key: index,
                  showStatus: !item.meta.show,
                })}}},[_c('svg-icon',{attrs:{"icon-class":item.meta.icon?item.meta.icon:'',"class-name":"icon"}}),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.generateLanguageFn(item.meta.title))+" ")])],1),_c('el-collapse-transition',[(item.children && item.children.length > 0)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.meta.show),expression:"item.meta.show"}],staticClass:"side_ul child_ul"},_vm._l((item.children),function(v){return _c('router-link',{key:v.name,staticClass:"side_li cursor-pointer",attrs:{"tag":"div","to":{ name: v.name },"active-class":"active-menu","exact":""},nativeOn:{"click":function($event){return _vm.handleMenuClick(v)}}},[_c('div',{staticClass:"pacity"},[_c('svg-icon',{attrs:{"icon-class":v.meta.icon?v.meta.icon:''}}),_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.generateLanguageFn(v.meta.title))+" ")])],1)])}),1):_vm._e()])],1)}),0)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }