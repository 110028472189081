import axios from 'axios'
const http = axios.create({
  baseURL: 'https://api.mapbox.com/geocoding/v5/mapbox.places',
  timeout: 5000
})

export function getAddressInfo(longitude, latitude) {
  const params = {
    autocomplete: true,
    access_token: "pk.eyJ1IjoiaG9wb3NreSIsImEiOiJja3AyYWdxbTUwMGh3MzBzNDJrYnUxN3B2In0.FHRiOVjVg46Fye6dWBWDOw",
    types:"poi"
  }
  const result = http({
    url: "/" + longitude + "," + latitude + ".json",
    method: 'GET',
    params: params,
  })
  return result;
}
/**
 * 搜索地址
 * @param {*} name 
 * @returns 
 */
export function searchAddress(name) {
  const params = {
    access_token: "pk.eyJ1IjoiaG9wb3NreSIsImEiOiJja3AyYWdxbTUwMGh3MzBzNDJrYnUxN3B2In0.FHRiOVjVg46Fye6dWBWDOw",
    types:"poi",
    language: "tw-TW",
    limit:5
  }
  const result = http({
    url: "/" + name + ".json",
    method: 'GET',
    params: params,
  })
  return result;
}