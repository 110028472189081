// store/permission.js
import { asyncRouterMap, constantRoutes } from '@/router';


/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
    let res = []
    routes.forEach(route => {
        if (route.children) {
            route.children = filterAsyncRoutes(route.children, roles)
        }
        res.push(route)
    })
    return res
}

const state = {
    routers: constantRoutes,
    addRouters: []
}

const mutations = {
    SET_ROUTERS: (state, routers) => {
        state.addRouters = routers;
        state.routers = constantRoutes.concat(routers);
    }
}

const actions = {
    generateRoutes({ commit }, data) {
        return new Promise(resolve => {
            const roles = data;
            let accessedRouters = asyncRouterMap.filter(v => {
                return v.key.indexOf(roles) > -1
            })
            let list = filterAsyncRoutes(accessedRouters)
            commit('SET_ROUTERS', list);
            resolve(list);
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}