/*
 * @Author: withering 
 * @Date: 2021-07-03 17:11:52 
 * @Last Modified by:   withering 
 * @Last Modified time: 2021-07-03 17:11:52 
 */
import request from '@/utils/request'
/**
 * 获取位置列表
 * @param {*} equipmentId 
 * @param {*} params 
 * @returns 
 */
export function getList(equipmentId, params) {
    // return request.get('/locations/'+equipmentId,params);
    return request({
        url: '/locations/' + equipmentId,
        method: 'get',
        params
    })
}
/**
 * 获取设备最近一次的位置
 * @param {*} equipmentId 
 * @returns 
 */
export function getMostRecentLocation(equipmentId) {
    // return request.get('/mostRecentLocation/'+equipmentId);
    return request({
        url: '/mostRecentLocation/' + equipmentId,
        method: 'get',
    })
}