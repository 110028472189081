export default {
    CreateAccount: "Crea un Account",
    Email: "E-mail",
    Password: "Password",
    ConfirmPas: "Conferma password",
    SignUp: "Registrati",
    AleadyAccount: "Hai già un account?",
    Login: "Accedi",
    Required: "Questo campo è obbligatorio.",
    NotEmail: "L'indirizzo e-mail non è valido.",
    TwoPass: "Sono state immesse due password diverse.",
    SixOne: "La password deve avere una lunghezza di 6-16 caratteri.",
    Alphabets: "TLa password deve contenere cifre e lettere.",
    Space: "La password non può contenere uno spazio vuoto.",
    Symbols: "La password può contenere solo caratteri di cifre, lettere o simboli.",
    SignLoad: "Iscrizione in corso...",
    AgainTry: "Iscrizione non riuscita. Per favore riprova.",
    EmailExists: "L'e-mail già esiste.",
    SupportToMonitor: "Può monitorare:",
    SignIn: "Accedi",
    IncorrectOr: "Nome utente o password non corretti.",
    Forpwd: "Password Dimenticata?",
    Signtry: "Iscrizione non riuscita. Per favore riprova.",
    Restpwd: "Reimposta la tua password:",
    SendLink: "Invia il Link per la Reimpostazione",
    ReturnTo: "Torna al",
    DontExit: "L'indirizzo e-mail non esiste.",
    CheckEmail: "Per favore controlla la tua e-mail",
    JustFold: "Abbiamo appena inviato un link per la reimpostazione della password all'indirizzo e-mail specificato. Se non riesci a trovarlo nella posta in arrivo, vai a controllare la cartella dello spam o della posta indesiderata.",
    TwoHour: "Se non ricevi l'e-mail nelle prossime 24 ore, contatta il nostro team di supporto scrivendo a <a href='mailto:support@iSeeGuard.com'>support@iSeeGuard.com</a>",
    UnknowError: "Errore Sconosciuto",
    TryOp: "Ops. Qualcosa è andato storto proprio adesso. Per favore, riprova.",
    Retry: "Riprova",
    CreatNewPwd: "Crea una Nuova Password",
    NewPwd: "Nuova Password",
    Confirm: "Conferma",
    OldPwd: "Hai inserito la vecchia password. Si prega di metterne un’altra.",
    NewPwdSucess: "Nuova password creata con successo!",
    ThePage: "La pagina verrà indirizzata alla pagina di accesso entro 5 secondi.",
    ProceedNow: "Procedi Ora",
    UnknowTry: "Errore sconosciuto. Per favore, riprova.",
    LinkExpired: "Il link è scaduto. Per favore, riprova.",
    ProfileSett: "Impostazioni Profilo",
    Download: "Scarica iSeeGuard Pro App sul Dispositivo di Destinazione",
    Back: "Indietro",
    Next: "Avanti",
    Dashboard: "Cruscotto",
    PhoneLogs: "Registri telefonici",
    CallLogs: "Registri delle Chiamate",
    Messages: "Messaggi",
    Contacts: "Contatti",
    Locations: "Posizioni",
    Geofence: "Geofence",
    BrowserHistory: "Cronologia di Navigazione",
    Photos: "Foto",
    VideoPreview: "Anteprima Video",
    CaptureScreenshots: "Cattura Schermate",
    AppActivity: "Attività dell'App",
    WiFiLogger: "Logger Wi-Fi",
    Keylogger: "Keylogger",
    Calendar: "Calendario",
    SocialApps: "App Social",
    DataExport: "Esportazione Dati",
    Settings: "Impostazioni",
    Sync: "Sincronizzazione",
    Updated: "Aggiornato: ",
    DataLoad: "Potrebbe richiedere un po' di tempo sincronizzare i dati del telefono. Si prega di essere pazienti...",
    DatanNotWhy: "Perché iSeeGuard Pro non riesce a sincronizzare i nuovi dati?",
    DataUpdata: "Prima di tutto, devi sapere che l'applicazione iSeeGuard Pro installata sul dispositivo interessato non sincronizza i nuovi dati immediatamente ma raccoglie i dati ogni 15 minuti. Ogni volta che ti collegherai all'account di iSeeGuard Pro o cliccherai sul pulsante di Sincronizzazione, i dati inizieranno a essere aggiornati. Il tempo richiesto per sincronizzare i dati dipende dalla grandezza degli stessi e dalla velocità di connessione internet del dispositivo interessato.",
    DataNum: "Alcuni dati saranno caricati attraverso la connessione a una rete Wi-Fi: foto, anteprima video, registrazioni chiamate, screenshot dei social media, logger Wi-Fi e attività delle app. Altri dati saranno caricati con reti 3G, 4G o Wi-Fi: posizione, informazioni sul dispositivo, keylogger, screenshot catturati manualmente, registrazione chiamate, messaggi, contatti, promemoria, geofence, cronologia del browser e lo storico dei messaggi sui social media. ",
    DataG: "Di seguito alcuni motivi per i quali potresti non visualizzare i dati aggiornati.",
    Dataup: "Il dispositivo interessato è spento.",
    DataOff: "Il dispositivo interessato non è connesso a internet o la connessione non è stabile.",
    Datastrong: "I permessi di accessibilità di iSeeGuard Pro sono stati disabilitati sul dispositivo interessato. In questo caso, vai su Impostazioni > Accessibilità > trova System Update Service e abilitalo.",
    Datauninstall: "I permessi di archiviazione di iSeeGuard Pro sono disabilitati sul dispositivo interessato. In questo caso, vai su Impostazioni > Applicazioni > System Update Service > Permessi > abilita l'accesso all'archiviazione dell'app.",
    Dataassint: "L'applicazione di assistenza iSeeGuard Pro è inattiva o è stata disinstallata dal dispositivo interessato. In questo caso, riavvia il telefono interessato o reinstalla l'applicazione.",
    ActInfo: "Informazioni Account",
    ActId: "ID Account",
    NotEml: "E-mail di Notifica",
    DvcInfo: "Informazioni sul Dispositivo",
    DvcName: "Nome Dispositivo",
    DvcStatus: "Stato Dispositivo",
    Online: "Online",
    Offline: "Offline",
    Plan: "Piano",
    ExpDate: "Data di Scadenza",
    AutoRel: "Rinnovo Automatico",
    Disable: "Disabilitato",
    Enable: "Abilitato",
    DvcMod: "Modello Dispositivo",
    Dvcson: "Versione Sistema Operativo Dispositivo",
    GPS: "GPS",
    On: "Acceso",
    Off: "Spento",
    BatLev: "Livello Batteria",
    WiFi: "Wi-Fi",
    TopCal: "Prime 8 Chiamate",
    TopMes: "Primi 8 Messaggi",
    LastLoca: "Ultima Posizione Nota",
    AppVersion: "Versione app",
    UpdateBtn: "Aggiornare",
    SureUpdate: "Sei sicuro di voler aggiornare l'app?",
    SureUpdateTips: "Dopo aver premuto sul pulsante \"Sì\", riceverai un comando sul dispositivo per approvare l'aggiornamento dell'app. Assicurati di avere il dispositivo di destinazione, altrimenti esporrà il fatto che il dispositivo è monitorato.",
    UpdateSuccess: "L'aggiornamento viene scaricato automaticamente. Verifica il processo e approva l'installazione sul dispositivo di destinazione.",
    UseLastetVersion: "Stai utilizzando l'ultima versione di questa applicazione. Per favore ricontrolla per aggiornamenti in un secondo momento.",
    InUpdating: "L'app si sta aggiornando e puoi controllare il processo sul dispositivo di destinazione. Attendi qualche minuto.",
    UpdateFailed: "L'aggiornamento non è riuscito perché il dispositivo di destinazione non è connesso alla rete o l'app non risponde temporaneamente.",
    Name: "Nome",
    PhoneNumber: "Numero di telefono",
    Type: "Tipo",
    Incoming: "In entrata",
    Cancelled: "Annullato",
    Outgoing: "In Uscita",
    Duration: "Durata",
    Date: "Data",
    All: "Tutto",
    Today: "Oggi",
    ThisMonth: "Questo Mese",
    LastMonth: "Mese Scorso",
    LastYear: "Anno Scorso",
    Search: "Cerca",
    To: "A",
    Phone: "Telefono",
    Work: "Lavoro",
    Home: "Casa",
    Others: "Altro",
    Mail: "Posta",
    Birthday: "Compleanni",
    Anniversary: "Anniversario",
    Address: "Indirizzo",
    LonLat: "Longitudine e Latitudine",
    LocTime: "Ora delle Posizioni",
    MapView: "Vedi Mappa",
    FailSync: "Impossibile eseguire la sincronizzazione. Per favore, riprovare.",
    GenerateDes: "Generare un geofence (limite geografico), in modo da ricevere una notifica se il figlio esce da un'area stabilita.",
    GenerateEff: "Genera Geofence in modo comodo ed efficiente",
    GenerateCir: "Generare un geofence intorno a un'area stabilita circondando una posizione specifica.",
    StayAlt: "Rimani Informato con gli Avvisi Geofence",
    WhGeo: "Quando il tuo figlio attraversa il recinto, riceverai un avviso tramite e-mail.",
    Start: "Avvio",
    SureDelete: "Sei sicuro di voler eliminare questo geofence?",
    Yes: "Sì",
    No: "No",
    MaxT: "Massimo 10 elementi.",
    Ok: "OK",
    NameGeofc: "Nome del Geofence",
    Setlon: "Imposta Limiti per la Posizione",
    SearchAd: "Indirizzo di Ricerca",
    SetRadius: "Imposta Raggio",
    Meters: "Raggio del Geofence in metri (max 1000 metri)",
    GuardTime: "Imposta Tempo di Avviso",
    SetNow: "Imposta ora >",
    SetAlt: "Imposta Avvisi Tramite E-mail di Notifica",
    Save: "Salva",
    Cancel: "Annulla",
    StartTime: "Ora di Inizio",
    EndTime: "Ora di Fine",
    Repeat: "Ripeti",
    EverySun: "Ogni Dom",
    EveryMon: "Ogni Lun",
    EveryTue: "Ogni Mar",
    EveryWed: "Ogni Mer",
    EveryThu: "Ogni Giov",
    EveryFri: "Ogni Ven",
    EverySat: "Ogni Sab",
    PlsName: "Si prega di impostare un nome per il geofence.",
    Plsloca: "Si prega di impostare una posizione.",
    PlsTime: "Si prega di impostare il tempo di avviso.",
    ThreeName: "Esiste già un geofence con lo stesso nome.",
    LastVtne: "Ora Ultima Visita",
    URL: "URL",
    Title: "Titolo",
    Frequency: "Frequenza",
    Visits: "Visite",
    HowFeat: "Come utilizzare questa funzione?",
    MakeAct: "Assicurarsi che il dispositivo di destinazione sia attivo.",
    ClickBt: 'Fare clic sul pulsante "Cattura Schermate".',
    VuShot: "Vedere le Schermate.",
    NotLock: "Nota: Questa funzione è disattivata quando lo schermo del telefono di destinazione è bloccato.",
    ScReenPow: "La cattura dello schermo non è riuscita perché lo schermo del telefono di destinazione è bloccato o l'app non risponde temporaneamente. Ti preghiamo di riprovare più tardi.",
    TakScr: "Cattura Schermate",
    Version: "Versione",
    Size: "Dimensione",
    InstDate: "Data di Installazione",
    TodayFreq: "Frequenza di Oggi",
    TodayScreenTime: "Tempo dello Schermo di Oggi",
    ClickKey: 'Fare clic sul pulsante "Avio" e attendere che i keylogger siano sincronizzati.',
    ViewScr: "Vedi le schermate.",
    KidTxy: "iSeeGuard Pro può aiutarti a monitorare le sequenze di tasti rilevate dal dispositivo Android monitorato. Ogni sequenza di tasti viene fornita con un'icona dell'app che indica la provenienza.",
    NotDat: "Nessun Risultato",
    Event: "Evento",
    EventLocation: "Luogo dell'Evento",
    Note: "Nota",
    AppNotif: "Notifiche dell'App",
    Modules: "Esportazione Dati",
    Time: "Ora",
    Format: "Formato",
    Export: "Esporta",
    DeleteAndExport: "Elimina i dati esportati dalla dashboard",
    CancelSub: "Annulla Abbonamento",
    UnbindDevice: "Disassocia Dispositivo",
    ContactSupport: "Contatta l'assistenza",
    SureRenewal: "Sei sicuro di voler annullare il rinnovo automatico?",
    SureUnbind: "Sei sicuro di voler scollegare questo dispositivo?",
    PersonalPrivacy: "Per proteggere la tua privacy personale, iSeeGuard Pro interromperà il monitoraggio ed eliminerà tutti i dati.",
    UnboundDvc: "Congratulazioni! Hai sbloccato con successo il dispositivo!",
    Profile: "Profilo",
    ContactUs: "Contattaci",
    Logout: "Esci",
    Device: "Dispositivo",
    Month: "Mese",
    BuyNow: "Compra ora",
    AddNew: "Aggiungi",
    Unactivated: "Non attivato",
    VersionUpdate: "Sono disponibili nuovi aggiornamenti. Se possibile, disinstalla la versione precedente sul telefono di destinazione, quindi riavvia il telefono e scarica la versione più recente.",
    LearnMore: "Ulteriori informazioni",
    HowToUpdate: "Come aggiornare l'app sul dispositivo di destinazione?",
    UpdateTips1: "Vai a I miei prodotti> Impostazioni> Annulla associazione dispositivo.",
    UpdateTips2: "Prendi il dispositivo di destinazione, vai su Impostazioni > Applicazioni > trova System Update Service e disinstallalo. ",
    UpdateTips3: "Poi apri www.iseeguard.com con il browser e scarica l'app. ",
    UpdateTips4: "Infine, segui le istruzioni sullo schermo per completare la configurazione.",
    WhatsappUpdateTips1: "Prendi il dispositivo di destinazione, vai su Impostazioni > Applicazioni > trova WhatsApp Service e disinstallalo. ",
    WhatsappUpdateTips2: "Poi apri www.installapk.net con il browser e scarica l'app.",
    WhatsappUpdateTips3: "Infine, segui le istruzioni sullo schermo per completare la configurazione.",
    ThankPurchase: "Grazie per il tuo acquisto!",
    ThankTips: "Riceverai un'e-mail di conferma con i dettagli del tuo ordine.",
    Product: "Prodotto:",
    BillingEmail: "La tua email con licenza:",
    ClickTips: "Fare clic su Avanti e avviare il monitoraggio.",
    PayFailed: "Il pagamento non è riuscito",
    PayFailedTips: "Riprova con un altro metodo di pagamento o contatta il tuo fornitore di servizi di pagamento.",
    TryAgain: "Riprova",
    FailedReason: "Perché il pagamento non riesce?",
    FailedReason1: "1. Carta scaduta",
    FailedReason2: "2. Fondi insufficienti",
    FailedReason3: "3. Le informazioni inserite non sono corrette",
    FailedReason4: "4. Altri motivi",
    OrderReview: "Il tuo ordine in revisione",
    OrderReviewTips: "Il tuo ordine ",
    OrderReviewTips1: " è stato ricevuto ed è in fase di verifica da parte del nostro team.",
    OrderReviewTips2: "L'ordine dovrebbe essere elaborato entro le prossime 2 ore - la durata massima di elaborazione è di 24 ore. Riceverai una notifica via email se il pagamento va a buon fine.",
    OrderReviewTips3: "Per evitare di effettuare più richieste di pagamento dalla tua carta di credito, non inviare l'ordine ripetutamente.",
    ChangeNotEmail: "Modifica E-mail di Notifica",
    NewEmail: "Nuova E-mail",
    NotHu: "Nota: questa modifica si applica solo all'e-mail di notifica.L'e-mail di login rimane invariata.",
    SendMailTse: "Abbiamo inviato un'e-mail di conferma al tuo nuovo indirizzo e-mail di notifica. Se non ricevi questa e-mail, fai clic sul pulsante qui sotto per riprovare o contattaci ascrivendo a",
    ChangeNotsuces: "Hai cambiato l'e-mail di notifica con successo!",
    ChangeTwo: "Hai inserito l'e-mail precedente. Si prega di cambiarla con un’altra.",
    ChangePassword: "Cambia Password",
    OldPassword: "Vecchia Password",
    PwdSuces: "La password è stata modificata correttamente.",
    WrongOldPwd: "Vecchia password errata.",
    TheNewCode: "La nuova e la vecchia password sono uguali. Riprova.",
    NoMore: "Niente altro",
    ReNew: "Rinnova",
    CheckExported: "Non vi sono dati da esportare. Controlla e riprova.",
    FailedCheck: "Impossibile esportare. A causa di problemi di compatibilità del browser, per il momento i dati non possono essere scaricati tramite il browser Firefox o Edge. Per favore, usa Chrome, Safari o Opera e riprova.",
    ScShot: "Schermata",
    AddGeofence: "Aggiungi un geofence",
    WifILocation: "Posizione Wi-fi",
    footCot: "Copyright © 2021 iSeeGuard.com. Tutti i Diritti Riservati.",
    Supcenter: "Centro di Supporto",
    ProductFAQs: "FAQ prodotto",
    AccountFAQs: "FAQ account",
    PurchaseFAQs: "FAQ acquisti",
    iSeeGuardStore: "Negozio",
    Polide: "Politica",
    privPli: "Politica sulla Privacy",
    cokPli: "Politica sui Cookie",
    refndPli: "Politica sul Rimborso",
    elu: "EULA",
    TermsCot: "Termini & Condizioni",
    FoloUs: "Seguici",
    Paymet: "Pagamento",
    prodt: "Prodotti",
    suport: "Supporto",
    Load: "Caricamento in corso...",
    NotFund: "Ops! Pagina non trovata.",
    IagreThe: "Sono d'accordo su",
    GlevEu: "EULA iSeeGuard",
    PriPoly: "Politica sulla Privacy",
    OurGuat: "La Nostra Garanzia",
    secure: "Sicuro",
    finalcoft: "Diamo valore alla la tua privacy. Le tue informazioni personali o finanziarie saranno tenute riservate.",
    twoBack: "30 Giorni di Rimborso",
    provideCase: "Forniamo una garanzia di rimborso di 30 giorni per tutti i prodotti nel caso in cui non tu non fossi pienamente soddisfatto.",
    cancelAnt: "Annulli in Qualsiasi Momento",
    cancelNeed: "Puoi sempre annullare l'abbonamento se non hai più bisogno del prodotto.",
    FastSup: "Supporto Rapido",
    EnquriedProblem: "Il nostro team offre un servizio clienti 24/5 per essere di supporto nel caso di eventuali richieste e problemi.",
    AsUcess: "Spiacenti, la verifica non è riuscita. Assicurati che l'app iSeeGuard Pro Assistant sia stata installata correttamente sul dispositivo di destinazione.",
    ExportTip: "L'esportazione dei dati non è supportata sui dispositivi mobili. Accedere al pannello online iSeeGuard sul computer ed esportare i dati specifici.",
    Expired: "Scaduto",
    Features: "Caratteristiche",
    WifiUpload: "* Si prega di notare che le foto verranno caricate solo tramite WiFi.",
    WhatsAppTips: "L'app di monitoraggio WhatsApp n. 1 è qui. Esplora ora >>",
    ScOne: "Si è verificato un errore durante il salvataggio degli screenshot sul dispositivo di destinazione. Per favore riprova più tardi.",
    ScTwo: "Si è verificato un errore durante il caricamento degli screenshot. Per favore riprova più tardi.",
    Anpay: "Pagamento online sicuro",
    OldEmail: "Vecchia Email:",
    ExtendSubscript: "Estendi l'abbonamento",
    Troubleshoot: "Risoluzione dei problemi",
    PermissionsCheck: "Risoluzione dei problemi",
    LogAgin: "Se l'app non funziona correttamente sul telefono target, è possibile che l'autorizzazione sia disattivata. Vai su Impostazioni e abilitala nuovamente per far di nuovo funzionare l'app.",
    SubmitLog: "Per risolvere meglio il tuo problema, puoi inviarci il file di registro degli errori.",
    Accessibility: "Accessibilità",
    Administrator: "Amministratore",
    CallLog: "Registro Chiamate",
    Location: "Posizione",
    Notification: "Notifica",
    SMS: "SMS",
    Storage: "Memoria",
    UsageStatus: "Stato d'uso",
    AccessCamera: "Fotocamera",
    Microphone: "Microfono",
    Submit: "Invia",
    LogExpried: "Il registro degli errori è stato caricato correttamente.",
    LogMailOne: "Puoi contattarci su ",
    Spt: "support@iSeeGuard.com",
    SptFst: "per un supporto più rapido.",
    Permission: "permessi",
    Status: "Stato",
    OnlyOne: "È possibile esportare solo 1000 record alla volta.",
    LogSucess: "Si è verificato un errore sconosciuto.",
    LogUnkow: "Invia di nuovo tra 10 minuti. Oppure puoi contattarci tramite",
    LogTwoUnkow: "per un supporto più rapido.",
    Items: "Quantità",
    ViewSchot: "Visualizza schermate storiche",
    verifyIcloudAccount: "Verificare le credenziali iCloud",
    icloudId: "Si prega di inserire l'ID iCloud del dispositivo target che si desidera monitorare.",
    anotherIcloud: "Riprovare o",
    useOther: "utilizzare un altro account iCloud.",
    oldIcloud: "Hai inserito il vecchio account iCloud.",
    accoundLock: "Account iCloud bloccato",
    lockedAgain: "Questo account iCloud è stato bloccato da Apple per motivi di sicurezza. Riprova tra 12 ore.",
    twoFactor: "Autenticazione a due fattori",
    phoneSend: "Un messaggio con un codice di verifica è stato inviato al dispositivo target. Si prega di inserire il codice per verificare l'account.",
    dontGetCode: "Non hai ricevuto un codice di verifica?",
    sixCode: "Con l'autenticazione a due fattori attiva, avrai bisogno di un accesso fisico al dispositivo di destinazione per ottenere un codice di verifica a sei cifre.",
    resendCode: "Invia di nuovo il codice",
    textMe: "Inviami un messaggio",
    needHelp: "Ho bisogno di aiuto?",
    notSendCode: "Impossibile inviare il codice di verifica, si prega di riprovare.",
    noVerification: "Codice di verifica non corretto.",
    noRecordsFound: "Nessun record trovato!",
    newPricePlan: "Abbiamo notato che si è inserito un account iCloud diverso che non corrisponde nostri record. Se desideri monitorare l'account appena digitato, si prega di eliminare l'account iCloud precedente o acquistare un nuovo piano tariffario.",
    deleteAccount: "Eliminare l'account",
    icloudError: "Errore verifica iCloud",
    aginNormally: "Si è verificato un errore durante la verifica dell'account iCloud target. Alcuni dati potrebbero non essere aggiornati. Si prega di verificare nuovamente in modo da poter utilizzare iSeeGuard Pro normalmente.",
    notNow: "Non adesso",
    verifyIcloud: "Verifica iCloud",
    icloudAccount: "Account iCloud",
    icloudStorage: "Spazio di archiviazione iCloud",
    deviceName: "Nome del dispositivo",
    deviceModel: "Modello del dispositivo",
    seeMore: "Scopri di più",
    sureTargetTwo: "Assicurati che la funzione Trova il mio iPhone sia abilitata sul dispositivo target.",
    location: "Posizione",
    list: "Elenco",
    sureDeleteAccount: "Sei sicuro di voler eliminare l'account iCloud?",
    removeThat: "Tutti i record e i dati relativi a questo account iCloud verranno rimossi.",
    isRemove: "Congratulazioni! Questo account iCloud è stato rimosso con successo.",
    Reminder: "Promemoria",
    iCloudDrive: "iCloud Drive",
    appleIdErr: "L'ID Apple o la password sono errati.",
    selectTrustePhone: "Seleziona Numero di Telefono Attendibile",
    serverErr: "Si è verificato un errore sul server. Per favore riprova più tardi.",
    continue: "Continue",
    textMessage: "Text message",
    codeMany: "Troppi inserimenti del codice di sicurezza non corretti.",
    downloadPage: "Sono necessari in media 2-3 minuti per scaricare un file di Pages, Numbers o Keynote. Sei sicuro di voler continuare?",
    Tips: "Suggerimenti",
    Verify: "Verifica",
    icloudLok: "L'account iCloud è stato bloccato o la password non è corretta.",
    veriErr: "Verifica fallita",
    notereminderTip: "iCloud ha smesso di rispondere. Alcuni dati sono temporaneamente non disponibili. Per favore riprova più tardi.",
    Video: "Video",
    forAndroid: "Monitora Android",
    foriOs: "Monitora iOS",
    tryError: "Si è verificato un errore sconosciuto.Per favore riprova più tardi.",
    nodata: "Nessun dato",
    ForAndroid: "Per Android",
    PhoneFiles: "File del Telefono",
    ClickShoot: 'Clicca sul pulsante "Scatta". ',
    LocationTracking: "Tracciamento Posizione",
    RemoteControl: "Controllo a Distanza",
    TakePhotos: "Fai Foto",
    TakeNote: "Nota: Puoi fare foto solo con la fotocamera posteriore. Questa funzione non è consigliata nelle seguenti situazioni:",
    Shoot: "Scatta",
    ShootErrorFirst: "La tua operazione è troppo frequente. Si prega di aspettare prima di operare di nuovo.",
    ShootErrorTwo: "Scatto di foto non riuscito perché il dispositivo di destinazione è spento o l'app non risponde temporaneamente. Si prega di riprovare più tardi.",
    ShootErrorFour: "Il permesso per accedere alla fotocamera è stato disattivato sul dispositivo target. Si prega di andare su Impostazioni> App> System Update Service> Autorizzazioni per abilitarlo di nuovo. ",
    ShootErrorFiveAndSeven: "Avvio della fotocamera fallito, è occupata da un altro programma.",
    ShootErrorEight: "Si è verificato un errore nel salvataggio delle foto nel dispositivo target. Si prega di riprovare più tardi. ",
    ShootErrorNineAnTen: "Si è verificato un errore durante l'upload delle foto. Si prega di riprovare più tardi. ",
    NoteWhenFirst: "Quando la fotocamera è occupata;",
    NoteWhenTwo: "Quando il telefono target è rivolto verso l'alto sopra un oggetto piano; ",
    NoteWhenThree: "Quando il telefono target è in una tasca o borsa. ",
    RecordCalls: "Registra chiamate",
    RecordsOutgoing: "Registra tutte le chiamate in entrata e in uscita fatte sui dispositivi Android remotamente",
    AutomaticallyRecord: "Registra Automaticamente le Chiamate",
    RemotelyOnline: "Acolta Tutte le Chiamate Remotamente Online",
    WifiOnline: "Carica i file audio sull'account online quando è disponibile una connessione Wi-Fi",
    NoteTime: "Nota: questa funzione può registrare fino a 20 minuti di chiamate per volta. ",
    Audio: "Audio",
    DownloadSimple: "Download",
    TakeShootVersion: "La versione attuale è troppo vecchia. Aggiorna l'app all'ultima versione sul telefono di destinazione per utilizzare questa funzione.",
    RecordTarget: "Registra le chiamate sul telefono di destinazione e salva come file audio nascosti",
    ViewTake: "Guarda le foto caricate.",
    balnkExport: "Vuoto",
    iphoneAudio: "Il browser iOS non supporta la riproduzione delle registrazioni delle chiamate. Per favore, fallo sul tuo computer.",
    IncludedFeatures: "Caratteristiche incluse",
    AppVideos: "Video App",
    AppPhotos: "Foto App",
    SafariHistory: "Cronologia Safari",
    SafariBookmarks: "Segnalibri Safari",
    VoiceMemos: "Memo vocali",
    ViewAllFollowing: "Visualizza tutti i seguenti dati nella app iSeeGuard Pro su computer target",
    OpeniSeeGuardProApp: "Apri la app iSeeGuard Pro",
    NoteInstalled: "Nota: puoi cliccare sul pulsante destro per richiamare rapidamente l'interfaccia dell'app. Se l'applicazione non compare, si prega di avviare manualmente l'applicazione sul computer in cui è installata.",
    NoteFiles: "Nota: è necessario accedere al computer di destinazione e avviare l'app per controllare tutti i file.",
    NotSet: "Non impostato",
    MonitorAndroid: "Monitora 1 dispositivo Android. (No radicamento)",
    MonitoriOS: "Monitora 1 dispositivo iOS. (No jailbreak)",
    MonitorICloud: "Monitora 1 account iCloud. (No jailbreak e senza app installate)",
    ChooseAndroid: "Scegli un piano per il tuo iSeeGuard Pro su Android",
    ChooseMac: "Scegli un piano per il tuo iSeeGuard Pro su Mac",
    ChooseIOS: "Scegli un piano per il tuo iSeeGuard Pro su iOS",
    ChooseICloud: "Scegli un piano per il tuo iSeeGuard Pro su iCloud",
    ChooseWhatsApp: "Scegli un piano per il tuo iSeeGuard su WhatsApp",
    ChooseWindows: "Scegli il tuo piano per il monitoraggio di Windows Monivisor",
    BuyDesc: "Rinnovo automatico.",
    BuyTips1: "La licenza sarà rinnovata automaticamente tra un mese. Puoi annullarla in qualsiasi momento nella pagina delle impostazioni del prodotto.",
    BuyTips2: "La licenza sarà rinnovata automaticamente tra tre mesi. Puoi annullarla in qualsiasi momento nella pagina delle impostazioni del prodotto.",
    BuyTips3: "La licenza sarà rinnovata automaticamente tra un anno. Puoi annullarla in qualsiasi momento nella pagina delle impostazioni del prodotto.",
    IcartPay: "Carta di credito",
    ShowAll: "Mostra tutto",
    WaitMsg: "Attendere prego. Stiamo elaborando il tuo ordine.",
    MonitoriOSremotely: "Spiacenti, la cancellazione non è riuscita. Per favore riprova.",
    tryAgain: "Per favore riprova.",
    resiterSucess: "Registrato con successo!",
    resiterTxt: "Congratulazioni! Il tuo account iSeeGuard è stato creato con successo!",
    selectPt: "Seleziona il prodotto che ti serve",
    Availablefor: "Disponibile per",
    ThankYou: "Grazie!",
    ThankYouTips: "La tua richiesta è stata ricevuta.",
    androidMonite: "Inizia ora a monitorare. Nessun bisogno di rootare i telefoni Android!",
    iosMonite: "Inizia ora a monitorare. Nessun bisogno di fare jailbreak su iPhone o iPad!",
    iCloudMonite: "Inizia ora a monitorare. Nessun jailbreak o installazione!",
    myPorductOrder: "I miei prodotti e gestione ordini",
    expireSoon: "Scadrà presto",
    invisibleMode: "App definitiva di monitoraggio Android che funziona in modalità invisibile.",
    moniteFeatures: "30+ funzioni di monitoraggio. Nessun rooting richiesto.",
    overTwoIos: "Più di 20 funzioni di monitoraggio iOS incluse: Whatsapp, storico chiamate, messaggi, ecc.",
    overTwoMac: "Più di 20 funzioni di monitoraggio Mac incluse: Whatsapp, storico chiamate, messaggi, ecc.",
    noLike: "Nessuna operazione complessa richiesta, come jailbreak. Inizia in semplicità.",
    userIcloud: "Monitora da remoto e online qualunque iPhone o iPad usando le credenziali iCloud.",
    noNeedInstallApp: "Nessun bisogno di installare app sul dispositivo iOS, sicuro al 100%.",
    myProduct: "I miei prodotti",
    accountOrderInfo: "Info Account e ordini",
    orderInfo: "Informazioni ordine",
    unableSync: "Impossibile sincronizzare i dati al momento. I dati verranno sincronizzati dopo aver acceso il telefono, avviato la connessione internet e dopo aver avviato iSeeGuard Pro assistant sul telefono interessato.",
    productFAQ: "FAQ del prodotto",
    path: "Percorso",
    goSetZero: "Vai su Impostazioni > Applicazioni > System Update Service > Permessi > Telecamera > assicurati che sia attivato",
    goSetOne: "Vai su Impostazioni > Accessibilità > System Update Service > assicurati che sia attivato",
    goSetTwo: "Vai su Impostazioni > Sicurezza > Altre impostazioni di sicurezza > Amministratore del dispositivo > System Update Service > assicurati che sia attivato",
    goSetThree: "Vai su Impostazioni > Applicazioni > System Update Service > Permessi > Agenda > assicurati che sia attivato",
    goSetFour: "Vai su Impostazioni > Applicazioni > System Update Service > Permessi > Registrazione chiamate > assicurati che sia attivata",
    goSetFive: "Vai su Impostazioni > Applicazioni > System Update Service > Permessi > Contatti > assicurati che sia attivato",
    goSetSix: "Vai su Impostazioni > Applicazioni > System Update Service > Permessi > Posizione > assicurati che sia attivato",
    goSetSop: "Vai su Impostazioni > Applicazioni > System Update Service > Permessi >  Microfono  > assicurati che sia attivato",
    goSetSeven: "Vai su Impostazioni > Applicazioni > System Update Service > Notifiche > assicurati che siano attivate",
    goSetEight: "Vai su Impostazioni > Applicazioni > System Update Service > Permessi > SMS > assicurati che sia attivato",
    goSetNight: "Vai su Impostazioni > Applicazioni > System Update Service > Permessi > Archiviazione > assicurati che sia attivato",
    goSetTwent: "Vai su Impostazioni > Sicurezza > Altre impostazioni di sicurezza > Accesso ai dati di utilizzo > System Update Service > assicurati che sia attivato",
    followStep: "Segui i passaggi per aggiungere il telefono che preferisci al monitor",
    followIos: "Segui i passaggi per aggiungere il iOS che preferisci al monitor",
    followMac: "Segui i passaggi per aggiungere il Mac che preferisci al monitor",
    beforeStart: "Prima di iniziare",
    youNeedDevice: "1. Devi avere un accesso fisico al dispositivo interessato.",
    notiLLegal: "2. Non usare questo prodotto a fini illegali.",
    physicalDevice: "1. Devi avere un accesso fisico al dispositivo interessato.",
    illegalPurposes: "2. Non usare questo prodotto a fini illegali.",
    videoInstruction: "Video di istruzioni",
    VerifyInstall: "Fai riferimento alla guida video per completare l'installazione dell'app e la configurazione sul telefono interessato. Dopodiché, clicca sul pulsante 'Verifica installazione' in fondo per connetterti al dispositivo di destinazione e accedere alla dashboard web.",
    textInstruction: "Testo di istruzioni",
    setupStep: "Passaggio ",
    androidStepOne: "Scarica l'app sul telefono intererssato",
    androidStepOneDesc: "Apri <strong style='color: #347aeb;'>www.iseeguard.com</strong> sul browser del dispositivo di destinazione e scarica l'app iSeeGuard Pro per Android.",
    androidStepTwo: "Installa l'applicazione",
    androidStepTwoDesc0: "Clicca sul file scaricato dalla cartella dei download del browser o nell'archivio dei file e avvia l'installazione dell'app.",
    androidStepTwoDesc1: "Clicca sul file scaricato dalla cartella dei download del browser o nell'archivio dei file e avvia l'installazione dell'app. Dopo averlo fatto, accedi al tuo account e digita le credenziali del dispositivo di destinazione.",
    androidStepTwoDesc2: "<strong>Nota bene: </strong>Alcune impostazioni dovranno essere configurate per far funzionare iSeeGuard Pro. Segui i comandi sull'interfaccia dell'applicazione oppure le linee guida seguenti per configurarlo manualmente.",
    androidStepThree: "Accetto l'accordo",
    androidStepTwoToOneDesc: "Scegli <strong>Ho letto e compreso i termini</strong> e tocca <strong>Accetto</strong>.",
    androidStepFour: "Autorizzazioni di base",
    androidStepTwoToTwoDesc: "Tocca <strong>Autorizzazioni di base </strong>> > Fai clic su <strong>Consenti</strong> per tutti i tipi di autorizzazioni di base.",
    androidStepFives: "Attiva Accessibilità",
    androidStepTwoToThreeDesc: "Tocca <strong>Servizio di accessibilità</strong> > abilita <strong>Servizi di sistema</strong> > tocca <strong>Consenti</strong>.",
    androidStepSix: "Attivare l'accesso alle notifiche",
    androidStepTwoToFourDesc: "Tocca <strong>Ascoltatore notifiche</strong> > attiva <strong>Servizi di sistema</strong> > tocca <strong>Consenti</strong>.",
    androidStepSeven: "Attiva autorizzazione screenshot",
    androidStepTwoToFiveDesc: "Tocca <strong>Autorizzazione screenshot</strong> > abilita <strong>iSeeGuard</strong>.",
    androidStepEight: "Accesso all'utilizzo",
    androidStepTwoToSixDesc: "Tocca <strong>Accesso all'utilizzo</strong> > attiva <strong>Servizi di sistema</strong> > tocca <strong>Consenti</strong>.",
    androidStepNine: "Permesso di sovrapposizione",
    androidStepTwoToSevenDesc: "Tocca <strong>Autorizzazione sovrapposizione</strong> > attiva <strong>Servizi di sistema</strong> > tocca <strong>Consenti</strong>.",
    androidStepTen: "Disattiva notifiche app",
    androidStepTwoToEightDesc: "Tocca <strong>Disattiva notifiche app</strong> > attiva <strong>Servizi di sistema</strong> > disattiva <strong>Mostra interruttore notifica</strong>.",
    androidStepTwoToNineDesc: "Tocca <strong>Disattiva ottimizzazione batteria</strong> > tocca <strong>Consenti</strong> nella finestra del messaggio.",
    androidStepTwoToTenDesc: "Dopo aver completato tutte le impostazioni delle autorizzazioni, tocca <strong>avanti</strong>. Successivamente, accedi al tuo account.",
    androidStepEleven: "Disabilita l'ottimizzazione della batteria",
    androidStepTwelve: "Accedi al tuo account",
    huaWeiNote1: "<strong>Huawei</strong>: Tocca il pulsante in basso e trova System Update Service in Task Manager. Scorri verso il basso l'interfaccia dell'app per bloccarla. Successivamente, tocca l'interfaccia dell'app per tornare.",
    oppoNote1: "<strong>Oppo</strong>: Tocca il pulsante in basso e trova System Update Service in Task Manager. Tocca l'icona Impostazioni nell'angolo in alto a destra. Tocca Blocca. Successivamente, tocca l'interfaccia dell'app per tornare.",
    xiaomiNote1: "<strong>Xiaomi</strong>: Tocca il pulsante in basso e trova System Update Service in Task Manager. Premi a lungo la scheda dell'app e bloccala. Successivamente, tocca l'interfaccia dell'app per tornare.",
    huaWeiNote: "<strong>Huawei</strong>: Vai su Impostazioni > Applicazioni > Lancio applicazioni > trova System Update Service, disabilitalo. Poi nella finestra dei pop up gestione manuale abilita Auto-avvio, Lancio secondario e Esegui in background.",
    oppoNote: "<strong>Oppo</strong>: Vai su Impostazioni > Gestione Applicazioni > Lista applicazioni > trova System Update Service e Risparmio energetico > abilita <strong>Esegui in background</strong>, poi vai al pulsante indietro e abilita Auto avvio e <strong>Permetti ad altre app e servizi di lanciare</strong>. Dopodiché torna su Impostazioni > Batteria > disabilita Risparmio energetico intelligente > Premi su Personalizza risparmio energetico > trova System Update Service e abilita <strong>Esegui in background</strong>. Premi sul pulsante indietro e abilita <strong>Freeze rapido app</strong>.",
    xiaomiNote: "<strong>Xiaomi</strong>: Vai su Impostazioni > Applicazioni > Gestione applicazioni > trova System Update Service > abilita <strong>Autoavvio</strong>, poi scorri e trova Risparmio batteria > <strong>No restrizioni</strong>.",
    vivoNote: "<strong>Vivo</strong>:Vai su impostazioni > Batteria > <strong>consumo elevato energia in background</strong> > trova System Update Service > spegnilo.",
    otherNote: "<strong>Others</strong>:  Vai su Impostazioni > Applicazioni > trova System Update Service > Impostazioni avanzate > Ottimizzazione batteria > trova System Update Service e disattivalo. ",
    androidStepThirteen: "Inizia a monitorare",
    androidStepThreeDesc: "Ora clicca sul pulsante Inizia monitoraggio e l'icona dell'app scomparirà. Per Android 10 e le versioni superiori, l'icona dell'app sarà sostituita da un'icona 'Avviso'. Sarebbe meglio nascondere quest'icona in una cartella. ",
    VerifyYourSetup: "Verifica la tua installazione",
    nowMoniteSupportTeam: "Se tutto è stato impostato correttamente, clicca sul pulsante sottostante 'Verifica installazione'. Questo ti permetterà di collegare il dispositivo interessato con il tuo account online. La pagina andrà alla dashboard web, dove puoi controllare tutti i file. Se non sei riuscito a installare l'app, contatta il team di assistenza.",
    iosStepOne: "Scarica il programma sul computer interessato",
    iosStepOneDesc: 'Visita <strong style="color: #347aeb;">www.useapp.net</strong> per scaricare iSeeGuard Pro per iOS sul computer di destinazione.',
    iosStepOneNote: "<strong>Nota bene:</strong> Si consiglia di scaricarlo direttamente sul computer della persona che vuoi monitorare. Se puoi accedere fisicamente all'iPhone di destinazione, puoi anche scaricare e monitorarlo sul computer.",
    iosStepTwo: "Installa l'applicazione iSeeGuard Pro per iOS",
    iosStepTwoWinDesc: "<strong>Per utenti Windows: </strong>Dopo aver scaricato, clicca sul file .exe per avviare il processo di installazione.",
    iosStepTwoWinNote1: "Nell'interfaccia iniziale puoi scegliere se nascondere l'icona del programma. Se scegli l'opzione “non mostrare la scorciatoia su desktop, barra delle applicazioni e menu di avvio”, avrai due modi per aprire iSeeGuard Pro.",
    iosStepTwoWinNote2: "<strong>2.1</strong> Cercare il percorso di installazione del programma e cliccare il file exe. Per default, il percorso dell'installazione è C:\\Program Files (x86)\\iSeeGuard\\iSeeGuard Pro. Se cambi il percorso di installazione, assicurati di ricordartelo.",
    iosStepTwoWinNote3: "<strong>2.2</strong> Accedi alla dashboard online di iSeeGuard Pro sul browser del computer interessato e poi clicca “Apri l'app iSeeGuard Pro”.",
    iosStepTwoMacDesc: "<strong>Per utenti Mac：</strong>Dopo il download, trascina il file del programma nella cartella dell'applicazione. Al termine dell'installazione, avvia il programma e accedi al tuo account.",
    iosStepThree: "Scansiona i dati dall'iPhone/iPad di destinazione",
    iosStepThreeToOneDesc1: "Se non sono disponibili file di backup sul computer, devi connettere il dispositivo iOS di destinazione con un cavo USB, poi confermare le informazioni del dispositivo e abilitare le impostazioni USB secondo le istruzioni.",
    iosStepThreeToOneDesc2: "Una volta che il dispositivo è connesso, iSeeGuard Pro per iOS inizierà il processo di scansione e di analisi, per cui ci vorrà del tempo.",
    iosStepThreeToTwoDesc1: "Se i file di backup sono disponibili sul computer, iSeeGuard Pro mostrerà i backup trovati. Puoi selezionare i file di backup del dispositivo di destinazione e scansionare i dati da lì.",
    iosStepThreeToTwoDesc2: "Se il dispositivo che vuoi monitorare non è nella lista oppure vuoi vedere soltanto i dati più recenti, puoi cliccare sull'opzione 'Connetti al dispositivo di destinazione' in fondo.",
    iosStepThreeToThreeDesc: "Nelle versioni più recenti è anche possibile sincronizzare i dati tramite Wi-Fi. Una volta che iPhone e iPad sono collegati alla stessa rete WiFi del computer, è possibile fare clic sull'icona WiFi nella barra di navigazione e quindi selezionare il dispositivo per sincronizzare i dati da remoto.",
    iosStepThreeToThreeNote: "<strong>Nota: </strong>Per sincronizzare i dati tramite Wi-Fi, è necessario collegare il dispositivo una volta al PC e autorizzare il computer.",
    iosStepFour: "Inizia a monitorare l'iPhone/iPad di destinazione sul computer",
    iosStepFourDesc: "Dopo aver completato il processo di scansione, tutte le informazioni saranno mostrate sulla dashboard e potrai sfogliare i diversi tipi di file e visionarne i dettagli. Inoltre ti è consentito esportare tutti i file sul computer.",
    VerifySetup: "Verifica installazione",
    kdsAndroid: "iSeeGuard Pro per Android",
    kdsIos: "iSeeGuard Pro per iOS",
    kdsIcloud: "iSeeGuard Pro per iCloud",
    setupGuide: "Manuale d'uso",
    generalSet: "Impostazioni generali",
    plseRefer: "Fare riferimento alla guida video per sapere come monitorare un iPhone utilizzando iSeeGuard Pro. Abbiamo anche preparato un'istruzione di testo dettagliata di seguito come riferimento.",
    locate: "Individuare",
    requestOut: "Il timeout della richiesta.",
    onlyTxt: "È possibile esportare solo file di testo.",
    quickly: "Fare clic sul pulsante in basso per accedere rapidamente.",
    neverWth: "Il tracciamento di WhatsApp non è mai stato così facile con iSeeGuard.",
    androidSytem: "Eccellente app di monitoraggio Android, con fino a 30 funzioni.",
    iOSSytem: "Software basato su PC per monitorare un iPhone o iPad senza jailbreak.",
    iCloudSytem: "Soluzione online per tracciare i dispositivi iOS senza installazione di app.",
    WhatsSytem: "App di monitoraggio WhatsApp specializzata per telefoni Android.",
    WindowsSytem: "Software di monitoraggio invisibile per computer Windows. ",
    Comingsoon: "Prossimamente",
    kdsMobile: "Applicazione di sicurezza mobile",
    MobileSytem: "La migliore app anti-spia per mantenere il tuo dispositivo Android libero da spyware e stalkerware.",
    EarlBbirdTitle: "Non perdere il prossimo strumento di sicurezza mobile Android！",
    EarlBbirdContent: "Lascia la tua email e ricevi il buono sconto early bird dopo il lancio del prodotto.",
    ValidEmail: "La tua email valida",
    EmailSubmited: "Questa email è già stata inviata.",
    WindowsMonitore: "MoniVisor",
    WhatsAppMonitore: "iSeeGuard per WhatsApp",
    kdsWhatsapp: "iSeeGuard per WhatsApp",
    SpeceAndroid: "App di monitoraggio WhatsApp specializzata per telefoni Android.",
    preparedGuid: "Qui abbiamo preparato un video dettagliato per aiutarti a sapere come monitorare il WhatsApp di qualcuno tramite iSeeGuard per WhatsApp. Di seguito puoi anche trovare la guida testuale passo-passo.",
    DataUpload: "Caricamento dei dati",
    transferOver: "Trasferisci i file di grandi dimensioni solo tramite Wi-Fi.",
    followWhatsApp: "Segui i passaggi seguenti per monitorare WhatsApp di qualcuno",
    whatsappStepOneDesc: "Visitare <strong style='color: #347aeb;'>www.installapk.net</strong> con il browser del telefono di destinazione e scarica l'app iSeeGuard per WhatsApp.",
    whatsappStepTwoDesc1: "Apri il file APK scaricato dalla cartella dei download del browser o nell'archivio dei file e termina l'installazione dell'app. Poi accedi al tuo account e digita le credenziali del tuo dispositivo di destinazione.",
    whatsappStepTwoDesc2: "<strong>Nota bene:</strong> Alcune impostazioni dovranno essere configurate per far funzionare iSeeGuard per WhatsApp. Segui i comandi sull'interfaccia dell'applicazione oppure le linee guida seguenti per configurarlo manualmente.",
    whatsappStepTwoToOneDesc: "Premi <strong>Procedi alle impostazioni</strong> > l'icona delle impostazioni in alto a destra > disabilita <strong>Scansiona app con Play Protect e Individua app dannose</strong> > torna all'app iSeeGuard per WhatsApp > premi <strong>Già impostato</strong> per continuare.",
    whatsappStepTwoToTwoDesc: "Premi <strong>Procedi alle impostazioni</strong> > servizi installati > abilita <strong>WhatsApp Service</strong> > premi <strong>Abilita</strong>.",
    whatsappStepTwoToThreeDesc: "Premi <strong>Procedi alle impostazioni</strong> > trova <strong>Accesso all'utilizzo dei dati</strong> nelle impostazioni di sicurezza > trova <strong>WhatsApp Service</strong> > abilita <strong>Consenti tracciamento</strong>.",
    whatsappStepTwoToFourDesc: "Premi su <strong>Procedi alle impostazioni </strong>> abilita WhatsApp Service</strong>.",
    whatsappStepTwoToFiveDesc: "Premi su  <strong>Procedi alle impostazioni</strong> > attiva il permesso dell'amministratore del dispositivo per WhatsApp Service.",
    whatsappStepTwoToSevenDesc: "Premi  <strong>Abilita tutto </strong> per abilitare i consensi per quest'app e ottenere i file WhatsApp e le chiamate WhatsApp registrate automaticamente.",
    whatsappStepTwoToEightDesc: "Premi  <strong>Ignora</strong> , poi nella finestra pop-up premi su  <strong>Abilita </strong> per far sì che WhatsApp Service si avvii in background.",
    whatsappStepTwoToNine: "Abilita avvio automatico",
    whatsappStepTwoToNineDesc: "Tocca <strong>Procedi su Impostazioni</strong> > abilita il <strong>servizio WhatsApp</strong>  per avviare l'esecuzione dell'app una volta riavviato il dispositivo.",
    whatsappStepTwoToTen: "Abilita Esecuzione in background (Solo per Huawei, Oppo, Xiaomi, Vivo)",
    WhatsAppHuawei: "<strong>Huawei:</strong> vai su Impostazioni> App> Avvio app> trova WhatsApp Service, disabilitalo. Quindi, nella finestra a comparsa Gestisci manualmente, abilita Avvio automatico, Avvio secondario ed Esegui in background.",
    WhatsAppOppo: "<strong>Oppo:</strong> passare a Impostazioni > Gestione app > Elenco app > trova il WhatsApp Service e Risparmio energia > abilita Esegui in background, quindi vai sul pulsante indietro e abilita l'avvio automatico e consente l'avvio di altre app o servizi. Successivamente, torna a Impostazioni > Batteria > disabilita Risparmio energia intelligente > Tocca Risparmio energia personalizzato > trova WhatsApp Service e abilita Esegui in background. Tocca di nuovo il pulsante Indietro e abilita Blocco rapido app. ",
    WhatsAppXiaomi: "<strong>Xiaomi:</strong> vai su Impostazioni > App > Gestisci app > trova il WhatsApp Service > abilita Avvio automatico, quindi scorri verso il basso e trova Risparmiobatteria > Nessuna restrizione.",
    WhatsAppVivo: "<strong>Vivo:</strong> passa a Impostazioni > Batteria > Consumo di energia in background elevato > trova WhatsApp Service > disattivalo. ",
    WhatsAppOthers: "<strong>Altri:</strong> vai su Impostazioni > App > trova WhatsApp Service > Impostazioni avanzate > Ottimizzazione batteria > trova di nuovo il WhatsApp Service e disabilitalo. ",
    WhatsAppHuawei1: "<strong>Huawei:</strong> Vai su Impostazioni > trova WhatsApp Service in Task Manager. Scorri verso il basso l'interfaccia dell'app per bloccarla. Successivamente, tocca l'interfaccia dell'app per tornare.",
    WhatsAppOppo1: "<strong>Oppo: </strong> Vai su Impostazioni > trova WhatsApp Service in Task Manager. Tocca l'icona Impostazioni nell'angolo in alto a destra. Tocca Blocca. Successivamente, tocca l'interfaccia dell'app per tornare.",
    WhatsAppXiaomi1: "<strong>Xiaomi:</strong> Vai su Impostazioni > trova WhatsApp Service in Task Manager. Premi a lungo la scheda dell'app e bloccala. Successivamente, tocca l'interfaccia dell'app per tornare.",
    WhatsAppStartMonite: "Adesso clicca sul pulsante Avvia monitoraggio e l'icona dell'app scomparirà. Per Android 10 e versioni successive, l'icona dell'app verrà sostituita con l'icona 'WLAN'. È meglio nascondere questa icona in una cartella non visibile. ",
    WhatsAppVerify: "Verifica la tua installazione dopo aver completato l'installazione",
    MoniteSupportTeam: "Se tutto è impostato correttamente, fare clic sul pulsante 'Verifica installazione' di seguito. Questo vincolerà il dispositivo di destinazione al tuo account online. La pagina corrente passerà alla dashboard web, dove potrai controllare tutti i file di. Se non riesci a installare correttamente l'app, contatta il team di supporto.",
    DataWarn: "Avviso sull'utilizzo dei dati",
    DataTerm: "Il caricamento di file di grandi dimensioni (foto, video, audio e documenti) su dati mobili può comportare costi di rete aggiuntivi sul telefono di destinazione ed esporre il fatto che stai monitorando WhatsApp. Sei sicuro di voler ancora caricare file multimediali su dati mobili?",
    DataTermNote: "<strong>Nota:</strong> la cronologia della chat, lo stato, le chiamate e le attività di WhatsApp non sono soggette a questa condizione. Per impostazione predefinita, questi file vengono caricati tramite dati mobili.",
    ScreenTime: "Screen Time di WhatsApp (ultimi 7 giorni)",
    WhatsAppChats: "Chat di WhatsApp",
    WhatsAppCalls: "Chiamate WhatsApp",
    Calls: "Chiamate",
    CallRecording: "Registrazioni chiamate",
    WhatsAppStatus: "Stato di WhatsApp",
    WhatsAppPhotos: "Foto di WhatsApp",
    WhatsAppVideos: "Video di WhatsApp",
    WhatsAppVoices: "Voci di WhatsApp",
    Voices: "Voci",
    WhatsAppDocuments: "Documenti WhatsApp",
    Documents: "Documenti",
    WhatsAppActivities: "Attività di WhatsApp",
    Activities: "Attività",
    WhatsAppScreenshots: "Schermate",
    Screenshots: "Schermate",
    uploadTle: "I file di grandi dimensioni (foto, video, audio e documenti) vengono caricati tramite Wi-Fi per impostazione predefinita. Puoi modificarlo in I miei prodotti> Impostazioni.",
    searchCallerName: "cerca il nome del chiamante",
    CallerName: "Nome del chiamante",
    onlyCover: "* iSeeGuard per WhatsApp caricherà solo le anteprime dei video.",
    screenFeature: "Inizia con la funzione Schermata automatica",
    screenFeatureOne: "Questa funzione acquisirà automaticamente gli screenshot della schermata di WhatsApp. Viene attivata solo quando l'utente di destinazione utilizza WhatsApp. ",
    screenFeatureTwo: "Puoi scegliere quando caricare gli screenshot. Abilitando il caricamento tramite Wi-Fi, gli screenshot vengono acquisiti più frequentemente e questo può evitare costi di rete aggiuntivi sul telefono di destinazione. Altrimenti sarà esattamente l'opposto.",
    UploadWiFi: "Carica tramite Wi-Fi",
    MoreConsumption: "Più consumo di energia",
    fasterfrequency: "Frequenza screenshot più veloce",
    nomoBileData: "Nessun consumo di dati mobili",
    UploadMobileDat: "Carica tramite dati mobili",
    LessPowerConsumption: "Minor consumo energetico",
    lowerFrequencyScreenshots: "Frequenza screenshot inferiore",
    usemobiledata: "Usa più dati mobili",
    SuccessfullySet: "Impostato con successo!",
    SetSucess: "Hai impostato correttamente la funzione screenshot automatici. Gli screenshot inizieranno il caricamento quando l'utente di destinazione sta utilizzando WhatsApp e c'è una connessione di rete. Ti preghiamo di attendere.",
    wifi: "Wi-Fi",
    wifiText: "Gli screenshot di WhatsApp verranno caricati sulla rete Wi-Fi.",
    MobileData: "Dati mobili",
    MobileDataText: "Gli screenshot di WhatsApp verranno caricati utilizzando i dati mobili.",
    WhatsAppDownload: "I file multimediali possono essere scaricati direttamente in ciascuna funzione.",
    tryagain: "Timeout richiesta. Per favore riprova più tardi.",
    beingUpload: "Dati in caricamento. Potrebbe essere necessario del tempo per vederli completamente, aggiorna la pagina più avanti.",
    Chat: "Chat di",
    CallRecorde: "Registrazioni chiamate",
    Play: "Riproduci",
    AudioCall: "Audio Call",
    VideoCall: "Video chiamata",
    startTle: "Inizia con la funzione di registrazione delle chiamate di WhatsApp",
    startTleOne: "<strong>1.</strong> Questa funzione sfrutta il microfono per registrare le chiamate vocali/video di WhatsApp, quindi potresti non sentire la voce dall'altra parte a meno che non sia abilitato il vivavoce.",
    startTleTwo: "<strong>2.</strong> Per impostazione predefinita, i file audio verranno caricati automaticamente tramite Wi-Fi. Questo per evitare costi aggiuntivi sui dati mobili e proteggerti dall'essere scoperto. Puoi modificare queste impostazioni in I miei prodotti - Impostazioni > Caricamento dati.",
    startTleThree: "<strong>3.</strong> Questa funzione può registrare fino a 20 minuti alla volta, quindi se la durata della chiamata supera i 20 minuti, verrà suddivisa in diversi piccoli frammenti per il caricamento.",
    Thumbnail: "Miniatura",
    UpdateOverWifi: "Aggiornamento tramite Wi-Fi",
    wifiUpload: "Si prega di notare che file di grandi dimensioni (foto, video, audio e documenti) verranno caricati quando il telefono di destinazione utilizza la rete Wi-Fi.",
    wifiNote: "<strong>Nota:</strong> la cronologia della chat, lo stato, le chiamate e le attività di WhatsApp non sono soggette a questa condizione. Per impostazione predefinita, questi file vengono caricati su dati mobili e Wi-Fi.",
    ChatContact: "Contatto chat",
    Content: "Contenuto",
    State: "Stato",
    screenTime: "Screen Time d",
    whatAppPathOne: "Vai su Impostazioni > Accessibilità > WhatsApp Service > assicurati che sia attivo",
    whatAppPathTwo: "Vai su Impostazioni > trova sicurezza > Altre impostazioni di sicurezza > Amministratore dispositivo > WhatsApp Service > assicurati che sia attivo",
    whatAppPathThree: "Vai su Impostazioni > App> trova il WhatsApp Service > Autorizzazioni > Archiviazione > assicurati che sia attivo",
    whatAppPathFour: "Vai su Impostazioni > App > trova WhatsApp Service > Notifiche > assicurati che sia attivo",
    whatAppPathFive: "Vai su Impostazioni > trova sicurezza > Altre impostazioni di sicurezza > Accesso ai dati di utilizzo > WhatsApp Service > assicurati che sia attivo",
    whatAppPathSix: "Vai su Impostazioni > App > trova WhatsApp Service > Autorizzazioni > Microfono > assicurati che sia attivo",
    whatsAppSetupError: "* Spiacenti, la verifica non è riuscita. Assicurati di aver installato l'app iSeeGuard per WhatsApp correttamente nel telefono di destinazione. ",
    monitorReal: "Ti consente di monitorare WhatsApp di qualcuno come se fosse reale.",
    mustFinshSet: "* Non hai terminato le impostazioni. Scegli se in Wi-Fi o dati mobili per caricare gli screenshot.",
    yourEmail: "La tua email:",
    whatsAppDelete: "Per proteggere la tua privacy personale, iSeeGuard interromperà il monitoraggio ed eliminerà tutti i dati.",
    Store: "Negozio",
    monitorAndroidDevice: "Monitora i dispositivi Android",
    androidMoniteName: "iSeeGuard Pro - Monitoraggio Android",
    whatsAppMoniteName: "iSeeGuard - Monitoraggio WhatsApp",
    monitoriOSDevice: "Monitora i dispositivi iOS",
    iPhoneMoniteName: "iSeeGuard Pro - Monitoraggio iPhone",
    iCloudMoniteName: "iSeeGuard Pro - Monitoraggio iCloud",
    cancelNote: "<strong>Nota:</strong> annullando il rinnovo automatico, il tuo account rimarrà attivo fino alla data di scadenza. Se vuoi continuare a utilizzare il nostro servizio, devi rinnovarlo manualmente in quel momento.",
    whatsAppSync: "Impossibile sincronizzare i dati al momento. I dati verranno sincronizzati dopo aver acceso il telefono, avviato la connessione internet e dopo aver avviato iSeeGuard per WhatsApp sul telefono interessato.",
    SyncErrWhy: "Perché iSeeGuard per Whatsapp non può sincronizzare i dati temporaneamente?",
    FirstReason: "Prima di tutto, iSeeGuard per Whatsapp sincronizza i dati automaticamente ogni 15 minuti. In secondo luogo, puoi sincronizzare i dati cliccando sul pulsante di Sincronizzazione. Il tempo richiesto per sincronizzare i dati dipende dalla dimensione degli stessi e dalla velocità della connessione internet del dispositivo di destinazione.",
    ByDefualt: "I file di grandi dimensioni (foto, video, audio e documenti) verranno caricati per default quando il dispositivo di destinazione è collegato a una rete Wi-Fi, mentre lo storico delle chat, lo stato, le chiamate e le attività di Whatsapp non sono soggette a questa condizione. Puoi cambiare le impostazioni in I miei prodotti > Impostazioni.",
    ReasonExample: "Di seguito troverai alcuni dei motivi per i quali potresti non riuscire a visualizzare i dati caricati. ",
    NonProductReasons: "Motivi non legati al prodotto:",
    NonProductReasons1: "Il dispositivo di destinazione è spento.",
    NonProductReasons2: "Il dispositivo di destinazione non è collegato alla rete o la rete è instabile.",
    NonProductReasons3: "Whatsapp è stato disinstallato dal dispositivo di destinazione.",
    NonProductReasons4: "Il dispositivo di destinazione non sta utilizzando Whatsapp.",
    NonProductReasons5: "La data e l'orario del dispositivo di destinazione non corrispondono all'orario locale. Vai in Impostazioni > Gestione generale > Data e orario > Attiva data e orario automatici.",
    ProductReasons: "Motivi legati al prodotto:",
    ProductReasons1: "iSeeGuard per Whatsapp è inattiva o è stata disinstallata dal dispositivo di destinazione. In tal caso riavvia il dispositivo di destinazione oppure reinstalla l'applicazione.",
    ProductReasons2: "Alcuni permessi di iSeeGuard per Whatsapp sono stati disattivati sul dispositivo di destinazione, soprattutto per quanto riguarda l'Accessibilità e l'Archiviazione. Vai sui Permessi e segui le istruzioni per attivarli manualmente.",
    SelectAll: "Seleziona",
    DeleteFiles: "Elimina file",
    DeleteConfirm: "Sei sicuro di voler eliminare questi file?",
    DeleteSuccess: "Eliminazione avvenuta con successo!",
    DownloadFiles: "Scarica file",
    DownloadConfirm: "Sei sicuro di voler scaricare questi file?",
    DownloadTips: "<strong>Ricorda:<strong>I file scaricati saranno eliminati dalla dashboard.",
    DownloadWaiting: "Potrebbe volerci qualche minuto in base alla dimensione dei file.",
    DownloadSuccess: "Download avvenuto con successo!",
    DownloadSuccessTips: "Puoi trovare i file scaricati nella Cronologia dei Download.",
    DownloadFailed: "Download non riuscito. Riprova con una rete stabile.",
    DeleteFailed: "Eliminazione non riuscita. Riprova nuovamente.",
    Processing: "In lavorazione...",
    ToppingTips: "fare clic per contrassegnare questa conversazione",
    MonStepFollowTitle: "Segui la procedura sotto per attivare il tuo prodotto",
    MonStepImportantTitle: "Informazioni importanti:",
    MonStepTitle1: "Scarica il programma sul computer di destinazione",
    MonStepTitle2: "Installa il software e termina la configurazione",
    MonStepTitle3: "Installa l'estensione di Chrome (facoltativo)",
    MonStepTitle4: "Vai alla dashboard online per monitorare adesso",
    MonStepImportant1: "<b>1.</b> È necessario l'accesso fisico al computer di destinazione.",
    MonStepImportant2: "<b>2.</b> Non utilizzare questo prodotto per scopi illegali.",
    MonStepImportant3: "<b>3.</b> MoniVisor è composto da due parti: Il software desktop e la dashboard online.",
    MonStepImportant4: "<b>4.</b> Ti consigliamo vivamente di aggiungere il software desktop alla lista delle eccezioni di qualsiasi software antivirus o di sicurezza, per evitare che il programma venga interrotto o le sue funzionalità non agiscano correttamente.",
    MonStepImportant5: "<b>5.</b> Si consiglia di utilizzare la modalità di navigazione privata per scaricare questo strumento, oppure di cancellare la cronologia di navigazione per evitare di lasciare tracce dell'installazione.",
    MonStep1Desc: 'MoniVisor per Windows funziona tramite un software computerizzato nascosto e una dashboard online. Per iniziare è necessario accedere fisicamente al computer di destinazione e incollare l\'URL ( <b style="color: #347aeb">http://www.installfree.net</b> ) in qualsiasi browser per scaricare il software.',
    MonStep2Desc: "Dopodiché, installa il software e completa la configurazione seguendo la guida sullo schermo. ",
    MonStep2Note: "<b>Nota:</b> È possibile che venga visualizzato un messaggio dal Controllo Accessi Utente di Windows o SmartScreen, che richiede l'approvazione per l'esecuzione del software. Se richiesto, clicca su Sì, Esegui o Esegui comunque. Se l'opzione per selezionare Sì o Esegui non è disponibile, clicca su \"ulteriori informazioni\" per visualizzare l'opzione.",
    MonStep2li_1: "Ci vorrà del tempo per installare il software.",
    MonStep2li_2: "Attiva con il tuo account con licenza e password.",
    MonStep2li_3: "Se il programma rileva un programma antivirus sul computer, segui le istruzioni fornite per aggiungere MoniVisor for Win alle eccezioni. Dopo aver terminato, scoprirai che lo strumento passerà automaticamente alla modalità di esecuzione nascosta.",
    MonStep3Desc: "Se il browser predefinito sul computer di destinazione è Chrome, avrai bisogno di un'estensione per controllare la posta elettronica (come Gmail, Outlook o Yahoo Mail) ed i messaggi delle web chat (come WhatsApp, Facebook, Twitter, Instagram o Skype). Consigliamo caldamente di installare l'estensione per migliorare le capacità di monitoraggio del software. Per farlo, segui la guida sotto.",
    MonStep3li_1: "Apri il browser Chrome.",
    MonStep3li_2: "Clicca sull'opzione con tre puntini in alto a destra nel browser - <b>Altri strumenti</b> - <b>Menu Estensioni</b>.",
    MonStep3li_3: "Attiva la <b>'modalità sviluppatore'</b> facendo clic sul pulsante di scorrimento in alto a destra nel browser.",
    MonStep3li_4: "Il tasto<b>'Carica decompresso'</b> verrà visualizzato nella barra dei menu.",
    MonStep3li_5: "Clicca sul tasto<b>'Carica decompresso'</b> quindi vai a <b style=\"color: #347aeb;\">C:\\Program Files (x86)\\Windows Assistant App\\MV\\extension</b> e seleziona la cartella <b>'moni-chrome-extension'</b>.\n",
    MonStep3li_6: "L<b>'Estensione'</b> è ora installata sul tuo computer.",
    MonStep3li_7: "Disattiva la <b>'modalità sviluppatore'</b> per completare la procedura.",
    MonStep3li_8: "Se l'estensione è stata installata correttamente, la vedrai nell'apposito elenco.\n",
    MonStep4Desc1: "Quindi, la configurazione necessaria sul computer di destinazione è stata completata. Scoprirai che lo strumento passa automaticamente alla modalità di esecuzione nascosta e funzionerà continuamente in background.",
    MonStep4Desc2: "Puoi visitare il sito di iSeeGuard per accedere al tuo account con qualsiasi browser (sul tuo computer, tablet o telefono cellulare). Oppure puoi cliccare sul pulsante Verifica configurazione in basso per associare rapidamente il tuo account, e la pagina corrente passerà alla dashboard, dove potrai controllare tutti i dati. Se non riesci a installare l'app correttamente, contatta il team di assistenza.",
    MonStepError: "*Spiacenti, la verifica non è riuscita. Assicurati che il software assistente MoniVisor sia stato installato correttamente sul computer di destinazione.",
    MonVerifySetup: "Verifica configurazione",
    MonUninstallBigTitle: "Disinstallazione per il software desktop MoniVisor",
    MonUninstallTitle: "Ti offriamo 2 modi per disinstallare il software sul computer di destinazione. Disinstalla in remoto facendo clic sul pulsante in basso o accedi fisicamente al computer di destinazione per la disinstallazione manuale.",
    MonUninstallNote: "Note:",
    MonUninstallNote1: "1. La funzione di disinstallazione remota è disponibile solo se il dispositivo di destinazione è online.",
    MonUninstallNote2: "2. La disinstallazione del software non svincolerà il tuo account e non eliminerà i dati monitorati. Tuttavia, non riceverai più nuovi dati. Se desideri svincolare l'account, puoi andare alla pagina Impostazioni e fare clic sul pulsante Annulla associazione.",
    MonUninstallMethod1: "Metodo 1: Disinstalla da remoto con un clic.",
    MonUninstallMethod1Desc: "Basta cliccare sul pulsante in basso e il software basato su computer eseguirà il comando automaticamente.",
    MonUninstallMethod2: "Metodo 2. Disinstallalo manualmente.",
    MonUninstallMethod2Desc: 'L\'app è installata nel percorso: <b style="color: #347aeb;">C:\\Program Files (x86)\\Windows Assistant App\\MV</b>',
    MonUninstallTheStep: "I passaggi sono:",
    MonUninstallTheStep1: "Passo 1: Vai al computer di destinazione e digita il percorso di installazione completo soprastante per individuare il file.",
    MonUninstallTheStep2: 'Passo 2: Clicca quindi sul file <b style="color: #347aeb;">unins000.exe</b> per eseguire il comando di disinstallazione.',
    MonUninstallTheStep3: "Passo 3: Non è necessario fare nient'altro, basta riavviare il computer, quindi è possibile disinstallare correttamente il software.",
    MonUninstallError: "Spiacenti, il computer di destinazione adesso è offline. Controlla lo stato del dispositivo nella dashboard o nella pagina Impostazioni e riprova quando il dispositivo target è online.",
    MonUninstallTip: "Questo non svincolerà il tuo account, ma non riceverai più nuovi dati.",
    MonUninstallProblem: "Sei sicuro di voler disinstallare il software sul computer di destinazione?",
    MonUninstallU: "Disinstalla",
    MonUninstallSuccess: "Congratulazioni! Hai disinstallato con successo il software!",
    MonUnbundlingProblem: "Sei sicuro di voler svincolare il dispositivo?",
    MonUnbundlingTip: "Per motivi di sicurezza, tutti i dati verranno eliminati.",
    MonUnbundlingSuccess: "Congratulazioni! Hai sbloccato con successo il software!",
    MonInstallationHelp: "Documento di aiuto per l'installazione",
    MonInstallationDesc: "Eccoti la guida su come aggiungere la cartella e i file del software alla  lista di eccezioni o programmi fidati, o all'elenco di esclusione di file e cartelle dell'antivirus, per evitare che il software venga troncato sul computer di destinazione. È inclusa anche la guida per installare l'estensione MoniVisor per Chrome. Osserva quindi la procedura in basso.",
    MonInstallationHowTo1: "Come aggiungere alla whitelist del software antivirus",
    MonInstallationHowTo2: "Come installare l'estensione MoniVisor per Chrome",
    MonInstallationHowTo3: "Guida su come aggiungere alla whitelist del software antivirus",
    MonInstallationStepTitle: "Le istruzioni specifiche possono differire per una varietà di pacchetti antivirus, sebbene il processo di base sia lo stesso. Ecco i passaggi su come farlo:",
    MonInstallationStep1: '<span>Passo 1:</span> Assicurati che la directory di installazione di MoniVisor sia: <b style="color: #347aeb;">C:\\Program Files (x86)\\Windows Assistant App</b>',
    MonInstallationStep1Desc: 'Il nome del file è: <b style="color: #347aeb;">Setupsvc.exe</b>',
    MonInstallationStep2: "<span>Passo 2:</span> Ricorda quale software antivirus è installato sul computer di destinazione, e controlla le istruzioni ufficiali riportate di seguito per configurare l'elenco delle eccezioni in pacchetti antivirus specifici.",
    MonInstallationStep2Desc: "Guida su come installare l'estensione MoniVisor per Chrome",
    MonWebChatsOfflineTip: "Il computer di destinazione è ora offline. Nessun dato verrà aggiornato finché non sarà online.",
    MonWebChatsOfflineTipDesc: "Che cosa significa offline? Questo potrebbe succedere quando il dispositivo di destinazione è disconnesso da Internet, il computer è spento, il software è stato disinstallato dal computer di destinazione o il software è stato altrimenti interrotto per motivi sconosciuti.",
    MonWebChatsNote1: 'Nota: Verifica che il computer di destinazione sia online e che l\'estensione Google Chrome sia stata installata correttamente. Clicca <a style="color:#347aeb" href="/monivisor-installation-guide-win" target="_blank">qui</a> per la guida completa per installare l\'estensione.',
    MonScreenClickTitle: "Inizia con la funzione per istantanea della schermata MoniVisor",
    MonScreenClickDesc: "Clicca su Start per acquisire gli screenshot dal computer di destinazione.<br/> Gli screenshot verranno acquisiti ogni 15 secondi per 5 minuti.",
    MonScreenClickNote: "Note:",
    MonScreenClickNote1: "1. Questa funzione può essere utilizzata solo quando il computer di destinazione è online.",
    MonScreenClickNote2: "2. È possibile interrompere lo screenshot entro cinque minuti dall'inizio. Se non li interrompi manualmente, si fermeranno automaticamente dopo cinque minuti e potrai ricominciare.",
    MonScreenClickError1: "Il computer di destinazione è offline, controlla lo stato del dispositivo quindi prova questa funzione quando è online.",
    MonScreenClickError2: "Sei sicuro di voler eliminare gli screenshot?",
    MonScreenSelectAll: "Seleziona tutto",
    MonScreenDeleting: "Eliminazione in corso...",
    MonScreenDeleteFiles: "Cancella file",
    MonScreenSuccess1: "Successo! Hai eliminato ",
    MonScreenSuccess2: " screenshot!",
    MonFileAction: "Azione",
    MonFileFileName: "Nome del file",
    MonFileDate: "Data",
    MonLoginUserName: "Nome utente",
    MonLoginActivityTh: "Attività",
    MonLoginPrinterName: "Nome stampante",
    MonLoginFileName: "Nome del file",
    MonLoginPages: "Pagine",
    MonExportDataSetting: "Impostazione dati:",
    MonExportDeleteExportedData: "Elimina dati esportati",
    MonExportDesc1: "1. I file multimediali nella cronologia delle chat Web possono essere esportati solo sotto forma di testo.",
    MonExportDesc2: "2. Gli screenshot possono essere scaricati in blocco dalla pagina dei risultati.",
    MonPurchaseBuyWin: "Scegli il tuo piano per il monitoraggio di Windows MoniVisor",
    MonPurchaseGuideType2: "Software monitoraggio computer Windows invisibile.",
    MonMyProductPageDesc1: "Uno strumento di monitoraggio per controllare in remoto tutti i file sul computer Windows.",
    MonMyProductPageDesc2: "Modalità di esecuzione non rilevabile e dashboard online per visualizzare i dati ovunque e in qualsiasi momento.",
    MonDashboardName: "Nome",
    MonDashboardComputerID: "ID computer",
    MonDashboardLatestEmails: "Ultime email",
    MonDashboardMore: "Di Più",
    MonDashboardLatestBrowsingHistories: "Ultime cronologie di navigazione",
    MonDashboardSender: "Mittente",
    MonDashboardSubject: "Soggetto",
    MonDashboardDate: "Data",
    MonDashboardDomain: "Dominio",
    MonDashboardTitle: "Titolo",
    MonSettingsUninstallGuide: "Guida alla disinstallazione",
    MonSettingsHelpDocument: "Documento di aiuto per l'installazione",
    MonSettingsSoftwareVersion: "Versione software sul computer di destinazione",
    MonSettingsWhat: "Cosa c'è di Nuovo",
    MonRequestLong: "Stiamo elaborando la tua richiesta. Attendi un minuto e riprova.",
    MonUnknown: "Sconosciuto",
    MoniVisor: "MoniVisor",
    MoniVisorWebActivity: "Attività web",
    MoniVisorActivityChrome: "Chrome",
    MoniVisorActivityFirefox: "FireFox",
    MoniVisorActivityEdge: "Microsoft Edge",
    MoniVisorActivityIE: "Internet Explorer",
    MoniVisorActivityOpera: "Opera",
    MoniVisorDownloadHistory: "Scarica la cronologia",
    MoniVisorWebChats: "Chat web",
    MonAppActivity: "Attività app",
    MonKeylogger: "Keylogger",
    MonScreenshot: "Cattura schermate",
    MonScreenshotSet: "Impostazioni screenshot",
    MonScreenshotView: "Visualizza screenshot",
    MonLoginActivity: "Attività di accesso",
    MonPrintActivity: "Attività di stampa",
    MonUsbConnection: "Connessione USB",
    MonDataExport: "Esportazione dei dati",
    MonComputer: "Monitorare il computer",
    MonFileActivity: "Attività dei file",
    MonRemaining: "Tempo rimanente:",
    MonScreenIngError: "L'attività di screenshot è già in corso. Per favore riprova più tardi.",
    MonDataExportOnly: "È possibile esportare solo 2000 record alla volta.",
    MonSomeTime: "Potrebbe essere necessario del tempo per sincronizzare i dati. Si prega di essere pazienti...",
    MonLastUsed: "Ultimo uso",
    MonFilePath: "Percorso del file",
    MonTimeUsage: "Utilizzo del tempo",
    MonReceived: "Ricevuto",
    MonSent: "Inviato",
    MoniVisorWebChatsWhatsApp: "WhatsApp",
    MoniVisorWebChatsFacebook: "Facebook",
    MoniVisorWebChatsTwitter: "Twitter",
    MoniVisorWebChatsInstagram: "Instagram",
    MoniVisorWebChatsSkype: "Skype",
    MoniVisorWebChatsPinterest: "Pinterest",
    MonWebMail: "Web Mail",
    MonWebMailGmail: "Gmail",
    MonWebMailOutlook: "Outlook",
    MonWebMailYahoo: "Yahoo",
    MonDataExportWebActivityChrome: "Attività web - Chrome",
    MonDataExportWebActivityIE: "Attività web - IE",
    MonDataExportWebActivityEdge: "Attività web - Edge",
    MonDataExportWebActivityOpera: "Attività web - Opera",
    MonDataExportWebActivityFirefox: "Attività web - Firefox",
    MonDataExportDownloadHistoryChrome: "Scarica la cronologia - Chrome",
    MonDataExportDownloadHistoryIE: "Scarica la cronologia - IE",
    MonDataExportDownloadHistoryEdge: "Scarica la cronologia - Edge",
    MonDataExportDownloadHistoryOpera: "Scarica la cronologia - Opera",
    MonDataExportDownloadHistoryFirefox: "Scarica la cronologia - Firefox",
    MonScreenStop: "Fermare",
    MonScreenMaxDownTip: "* È possibile scaricare un massimo di 50 immagini alla volta ei dati scaricati verranno eliminati.",
    MonWebMailAttachments: "Percorso allegato",
    MonWebMailFrom: "A partire dal",
    MonKeyloggerTip: 'Nota: verificare che il computer di destinazione sia online e che il software non sia stato ucciso da un antivirus. Fare clic <a style="color:#347aeb" href="/monivisor-installation-guide-win" target="_blank">qui</a> per la guida completa per aggiungere il software alla Whitelist dell\'antivirus.',
    MonInsert: "Inserire",
    MonEject: "Espellere",
    MonLogon: "Accedere",
    MonLogoff: "Disconnettersi",
    MonCreate: "Creare",
    MonCopy: "copia",
    MonDelete: "Elimina",
    MonRename: "Rinominare",
    MonImage: "Immagine",
    MonVideo: "Video",
    MonFile: "File",
    MonLocation: "Posizione",
    MonPasteNote: "<b>Nota:</b> è necessario incollare il percorso di installazione completo del file o consentire temporaneamente la visualizzazione delle cartelle nascoste in Esplora file di Windows, per selezionare manualmente le cartelle dall'interno dell'app antivirus. Aggiungere la cartella MV sotto il percorso di installazione o il nome file completo fornito alla whitelist, a seconda dei requisiti dei diversi software antivirus.",
    kdsMoniVisor: "MoniVisor per Win",
    MonMisseVoice: "Chiamata persa",
    MonMisseVideo: "Videochiamata persa",
    MonContact: "Contatti",
    MonMonitorComputers: "Monitorare i computer",
    MonWindowsMonitoring: "MoniVisor - Monitoraggio di Windows",
    ParamError: "Errore di parametro",
    TokenError: "Accesso non valido",
    UserVIPExpired: "L'abbonamento utente è scaduto o non esiste",
    NotfoundHttp: "L'indirizzo richiesto non esiste",
    ServerError: "Errore del server",
    HeadersMiss: "Manca il parametro headers",
    MonitorNow: "Monitora ora",
    AboutUs: "chi siamo",
    PrivacyPolicy: "politica sulla riservatezza",
    TermsConditions: "Termini & Condizioni",
    Disclaimer: "Disclaimer",
    DisclaimerDesc: "IL SOFTWARE DELL'ISEEGUARD DESTINATO ESCLUSIVAMENTE ALL'USO LEGALE.<br />L'installazione di questo software con licenza su un dispositivo per il quale non si dispone dei diritti di monitoraggio potrebbe violare le leggi del proprio paese o regione. Sei completamente responsabile per il download, l'installazione e l'utilizzo. Se si sceglie un dispositivo di monitoraggio senza autorizzazione, iSeeGuard non sarà ritenuto responsabile. Tutti i diritti non espressamente concessi nel presente documento sono riservati a iSeeGuard.",
    Refuse: "Rifiutare",
    DialOut: "Chiamami",
    IncomingCall: "In arrivo",
    RenewDialogTips: "La demo non può essere ulteriormente utilizzata. Puoi registrarti per un account e acquistare un piano per usufruire di tutte le funzionalità di monitoraggio avanzate.",
    Permanent: "Permanente",
    Capacity: "Capacità",
    Clipboard: "Appunti",
    SensitiveWord: "Parole Sensibili",
    AppUsed: "App usata",
    AppUsedToday: "App usata oggi",
    Add: "Inserisci",
    Modify: "Modificare",
    Operate: "Operare",
    MacStepTitle2: "Installa il software",
    MacStepTitle3: "termina la configurazione",
    MacStepTitle5: "Termina l'installazione",
    MacStepOneDesc: "Apri <strong>www.iseeguard.com</strong> con un browser sul dispositivo di destinazione e scarica l'app iSeeGuard Pro per Mac.",
    MacStepTwoDesc: "<strong>Nota:</strong> si consiglia di scaricarlo direttamente sul computer della persona che si desidera monitorare. Se di tanto in tanto puoi accedere fisicamente al MAC di destinazione, puoi anche scaricarlo e monitorarlo sul tuo computer.",
    MacStepThreeDesc: "Dopo il download, fai clic sul file pkg per avviare il processo di installazione.",
    MacStepFourDesc: "Seleziona quello che devi monitorare, fai clic su Avanti per continuare.",
    MacStepFiveDesc: "Mactrack è stato installato.",
    MacStepSixDesc: "Accedi al tuo account.",
    ClickHere: "clicca qui",
    IosStepOneDesc: "Apri <strong>panel.iseeguard.com/store/ios</strong> con un browser sul dispositivo di destinazione e scarica l'app iSeeGuard Pro per iOS.",
    IosStepTwoDesc: "<strong>Nota:</strong> si consiglia di scaricarlo direttamente sul computer della persona che si desidera monitorare. Se di tanto in tanto puoi accedere fisicamente all'iOS di destinazione, puoi anche scaricarlo e monitorarlo sul tuo computer.",
    IosStepTitle3: "Backup dei dati da iTunes",
    IosStepThreeDesc: "Scarica il software iTunes, collega il telefono cellulare da monitorare al software iTunes ed esegui il backup dei dati.",
    IosStepTitle5: "Scansiona i dati dall'iPhone di destinazione",
    IosStepTitle6: "Analizzando i dati",
    IosStepTitle7: "Inizia a monitorare l'iPhone di destinazione sul computer",
    IosStepFiveDesc: "se ci sono file di backup disponibili sul computer, ISeeGuard Pro visualizzerà il backup trovato. È possibile selezionare il file di backup del dispositivo di destinazione e scansionare i dati da esso.",
    IosStepSixDesc: "Fare clic su Avanti e visualizzare la pagina dei dati di analisi. Il tempo di attesa per la prima analisi dei dati sarà più lungo.",
    IosStepSevenDesc: "Al termine del processo di scansione, tutte le informazioni scansionate verranno visualizzate sulla dashboard e sarà possibile passare da un tipo di file all'altro e visualizzare i dettagli. Inoltre, puoi esportare tutti i dati sul computer.",
}

