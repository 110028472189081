import { getList, show, update, store, del, getRecords, updateStatus } from "@/api/android/geofence"
import {isUidOnline} from "@/api/android/command"
import router from "@/router"
import { Notification } from 'element-ui';
import { getCurrentDate } from "@/utils/common"
import { getAddressInfo } from "@/utils/mapboxRequest"
import i18n from "@/lang"
export default {
    namespaced: true,
    state: {
        // 最后同步时间
        synchronisedTime: '',
        //地理围栏列表
        geofenceList: [],
        //地理围栏列表请求参数
        geofenceRequestParams: {
            page: 1,
            total: 0
        },
        //地理围栏详情
        geofenceDetails: {},
        //地理围栏记录
        geofenceRecords: [],
        //地理围栏记录请求参数
        geofenceRecordRequestParams: {
            page: 1,
            total: 0
        },
        //设备是否在线
        isUidOnline:false,
        //首次提示
        prompt:false,
    },
    actions: {
        //获取地理围栏列表
        async requestGeofenceList(context) {
            let requestParams = {
                page: context.getters.getGeofenceRequestParams.page
            }
            const result = await getList(router.app._route.params.equipmentId, requestParams)
            context.commit("setGeofenceList", result.data.data)
            context.commit("setGeofenceRequestParams", result.data)
        },
        //获取地理围栏详情
        async requestGetGeofenceDetails(context, id) {
            const result = await show(router.app._route.params.equipmentId, id)
            context.commit("setGeofenceDetails", result.data)
        },
        //更新地理围栏
        async requestUpdateGeofence(context, data) {
            await update(router.app._route.params.equipmentId, data)
            return new Promise((resolve) => {
                Notification.success({
                    title: 'Success',
                    message: i18n.t("SuccessfullySet"),
                })
                resolve()
            })
        },
        //添加地理围栏
        async requestStoreGeofence(context, data) {
            await store(router.app._route.params.equipmentId, data)
            return new Promise((resolve) => {
                Notification.success({
                    title: 'Success',
                    message: i18n.t("SuccessfullySet"),
                })
                resolve()
            })
        },
        //删除地理围栏
        async requestDelGeofence(context, id) {
            await del(router.app._route.params.equipmentId, id)
            return new Promise((resolve) => {
                Notification.success({
                    title: 'Success',
                    message: i18n.t("DeleteSuccess"),
                })
                resolve()
            })
        },
        //获取地理围栏记录
        async requestGeofenceRecords(context, id) {
            const result = await getRecords(router.app._route.params.equipmentId, id, context.getters.getGeofenceRecordRequestParams.page)
            let data = result.data.data;
            Promise.all([
                data.forEach(item => {
                    item.place_name = "---"
                    getAddressInfo(item.longitude, item.latitude).then(result => {
                        if (result.data.features.length > 0) {
                            item.place_name = result.data.features[0].place_name
                        }
                    })
                })
            ]).then(() => {
                context.commit('setGeofenceRecords', data)
                context.commit("setGeofenceRecordRequestParams", result.data)
            })
        },
        //更新地理围栏状态
        async requestUpdateGeofenceStatus(context, { id, status }) {
            await updateStatus(router.app._route.params.equipmentId, id, status)
            return new Promise((resolve) => {
                Notification.success({
                    title: 'Success',
                    message: i18n.t("SuccessfullySet"),
                })
                resolve()
            })
        },
        //获取设备在线状态
        async requestIsUidOnline(context) {
            const result = await isUidOnline(router.app._route.params.equipmentId)
            context.commit("setIsUidOnline",result.data.status)
            return new Promise((resolve) => {
                resolve(result.data.status)
            })
        }
    },
    mutations: {
        /**
        * 更新最后同步时间
        * @param {*} state
        * @param {synchronisedTime} payload
        */
        setSynchronisedTime(state) {
            state.synchronisedTime = getCurrentDate()
            // Vue.set(state, 'synchronisedTime', getCurrentDate());
        },
        //寫入首次提示
        setPrompt(state,status=false) {
            state.prompt = status
            // Vue.set(state,'prompt',status)
        },
        //写入地理围栏列表
        setGeofenceList(state, data) {
            let geofenceList = state.geofenceList
            data.forEach(item => {
                geofenceList.push(item)
            })
            state.geofenceList = geofenceList
            // Vue.set(state, 'geofenceList', geofenceList)
        },
        //重置地理围栏列表
        resetGeofenceList(state) {
            state.geofenceList = []
            // Vue.set(state, "geofenceList", [])
        },
        //写入地理围栏列表请求参数
        setGeofenceRequestParams(state, data) {
            state.geofenceRequestParams = {
                page: data.current_page,
                total: data.total
            }
            // Vue.set(state, "geofenceRequestParams", {
            //     page: data.current_page,
            //     total: data.total
            // })
        },
        //重置地理围栏列表请求参数
        resetGeofenceRequestParams(state) {
            state.geofenceRequestParams = {
                page: 1,
                total: 0
            }
            // Vue.set(state, "geofenceRequestParams", {
            //     page: 1,
            //     total: 0
            // })
        },
        //写入地理围栏列表分页请求参数
        setGeofencePageRequestParams(state, page) {
            state.geofenceRequestParams.page = page
            // Vue.set(state.geofenceRequestParams, "page", page)
        },
        //写入地理围栏详情
        setGeofenceDetails(state, data) {
            state.geofenceDetails = data
            // Vue.set(state, "geofenceDetails", data)
        },
        //写入地理围栏记录
        setGeofenceRecords(state, data) {
            let geofenceRecords = state.geofenceRecords
            data.forEach(item => {
                geofenceRecords.push(item)
            })
            state.geofenceRecords = geofenceRecords
            // Vue.set(state, 'geofenceRecords', geofenceRecords)
        },
        //重置地理围栏记录
        resetGeofenceRecords(state) {
            state.geofenceRecords = []
            // Vue.set(state, 'geofenceRecords', [])
        },
        //写入地理围栏记录请求参数
        setGeofenceRecordRequestParams(state, data) {
            state.geofenceRecordRequestParams = {
                page: data.current_page,
                total: data.total
            }
            // Vue.set(state, 'geofenceRecordRequestParams', {
            //     page: data.current_page,
            //     total: data.total
            // })
        },
        //重置地理围栏记录请求参数
        resetGeofenceRecordRequestParams(state) {
            state.geofenceRecordRequestParams = {
                page: 1,
                total: 0
            }
            // Vue.set(state, 'geofenceRecordRequestParams', {
            //     page: 1,
            //     total: 0
            // })
        },
        //写入地理围栏分页请求参数
        setGeofenceRecordPageRequestParams(state, page) {
            state.geofenceRecordRequestParams.page = page
            // Vue.set(state.geofenceRecordRequestParams, 'page', page)
        },
        //写入设备是否在线状态
        setIsUidOnline(state, status) {
            state.isUidOnline = status
            // Vue.set(state, 'isUidOnline', status)
        }
    },
    getters: {
        //同步时间
        getSynchronisedTime: state => {
            return state.synchronisedTime
        },
        //首次提示
        getPrompt:state =>{
            return state.prompt
        },
        //获取地理围栏列表
        getGeofenceList: state => {
            return state.geofenceList
        },
        //获取地理围栏列表请求参数
        getGeofenceRequestParams: state => {
            return state.geofenceRequestParams
        },
        //获取地理围栏详情
        getGeofenceDetails: state => {
            return state.geofenceDetails
        },
        //获取地理围栏记录列表
        getGeofenceRecordList: state => {
            return state.geofenceRecords
        },
        //获取地理围栏记录列表请求参数
        getGeofenceRecordRequestParams: state => {
            return state.geofenceRecordRequestParams
        },
        //获取设备在线状态
        getIsUidOnline: state => {
            return state.isUidOnline
        }
    }
}