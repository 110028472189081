import { getList } from '@/api/android/contacts'
import router from "@/router"
import i18n from '@/lang'
import { getCurrentDate } from "@/utils/common"
export default {
    namespaced: true,
    state: {
        //联系人列表
        contactList: [],
        //分页参数
        pageParams: {
            page: 1,
            total: 0,
            search:""
        },
        // 最后同步时间
        synchronisedTime: ''
    },
    mutations: {
        /**
       * 更新最后同步时间
       * @param {*} state
       * @param {synchronisedTime} payload
       */
        setSynchronisedTime(state) {
            state.synchronisedTime = getCurrentDate()
            // Vue.set(state, 'synchronisedTime', getCurrentDate());
        },
        /**
         * 写入联系人列表
         * @param {*} state 
         * @param {*} data 
         */
        setContactList(state, data) {
            let contactList = state.contactList
            data.forEach(item => { contactList.push(item) })
            state.contactList = contactList
            // Vue.set(state, 'contactList', contactList)
        },
        /**
         * 重置联系人列表
         * @param {*} state 
         */
        resetContactList(state) {
            state.contactList = []
            // Vue.set(state, 'contactList', [])
        },
        /**
         * 写入分页参数
         * @param {*} state 
         * @param {*} data 
         */
        setPageParams(state, data) {
            if(data.current_page) {
                state.pageParams.page = data.current_page
                // Vue.set(state.pageParams, "page", data.current_page)
            }
            if(data.total) {
                state.pageParams.total = data.total
                // Vue.set(state.pageParams, "total", data.total)
            }
            if(data.search) {
                state.pageParams.search = data.search
                // Vue.set(state.pageParams, "search", data.search)
            }
        },
        /**
         * 重置分页参数
         * @param {*} state 
         */
        resetPageParams(state) {
            state.pageParams = {
                page: 1,
                total: 0,
            }
            // Vue.set(state, "pageParams", {
            //     page: 1,
            //     total: 0,
            // })
        }
    },
    actions: {
        /**
         * 请求获取联系人列表
         * @param {*} context 
         */
        async requestContactList(context) {
            const params = context.getters.getRequestContactListParams
            const result = await getList(router.app._route.params.equipmentId, params);
            context.commit('setContactList', result.data.data)
        }
    },
    getters: {
        /**
         * 获取同步时间
         * @param {*} state 
         * @returns 
         */
        getSynchronisedTime: state => {
            return state.synchronisedTime
        },
        /**
         * 获取联系人列表
         * @param {*} state 
         * @returns 
         */
        getContactList: state => {
            let data = state.contactList
            state.contactList.forEach(contactList => {
                //地址
                contactList.address.forEach(item => {
                    switch (item.type) {
                        case 0:
                            item.typeName = i18n.t("Home")
                            break;
                        case 1:
                            item.typeName = i18n.t("Work")
                            break;
                        case 2:
                            item.typeName = i18n.t("Others")
                            break;
                        default:
                            item.typeName = i18n.t("MonUnknown")
                            break;
                    }
                })
                //日期
                contactList.dates.forEach(dates => {
                    switch (dates.type) {
                        case 0:
                            dates.typeName = i18n.t("Birthday")
                            break;
                        default:
                            dates.typeName = i18n.t("MonUnknown")
                            break;
                    }
                })
                //邮箱
                contactList.emails.forEach(emails => {
                    switch (emails.type) {
                        case 0:
                            emails.typeName = i18n.t("Home")
                            break;
                        case 1:
                            emails.typeName = i18n.t("Work")
                            break;
                        case 2:
                            emails.typeName = i18n.t("Others")
                            break;
                        default:
                            emails.typeName = i18n.t("MonUnknown")
                            break;
                    }
                })
                contactList.phones.forEach(phones => {
                    switch (phones.type) {
                        case 0:
                            phones.typeName = i18n.t("Home")
                            break;
                        case 1:
                            phones.typeName = i18n.t("Work")
                            break;
                        case 2:
                            phones.typeName = i18n.t("Others")
                            break;
                        default:
                            phones.typeName = i18n.t("MonUnknown")
                            break;
                    }
                })
            })

            return data
        },
        /**
         * 获取分页请求参数
         * @param {*} state 
         * @returns 
         */
        getRequestContactListParams: state => {
            return {
                page: state.pageParams.page,
                search:state.pageParams.search
            }
        },
        getPageParams: state => {
            return state.pageParams
        }
    }
}