export default {
    CreateAccount: "Crear una cuenta",
    Email: "Correo electrónico",
    Password: "Contraseña",
    ConfirmPas: "Confirmar Contraseña",
    SignUp: "Regístrese",
    AleadyAccount: "¿Ya tienes una cuenta?",
    Login: "Iniciar sesión",
    Required: "Este campo es obligatorio.",
    NotEmail: "La dirección de correo electrónico no es válida.",
    TwoPass: "Ha introducido dos contraseñas diferentes.",
    SixOne: "La contraseña debe tener entre 6 y 16 caracteres de longitud.",
    Alphabets: "La contraseña debe contener números y letras.",
    Space: "La contraseña no puede contener espacios en blanco.",
    Symbols: "La contraseña solo puede contener números, letras o símbolos.",
    SignLoad: "Registro",
    AgainTry: "No ha sido posible realizar el registro. Por favor, inténtelo de nuevo.",
    EmailExists: "El correo electrónico ya existe.",
    SupportToMonitor: "Puede monitorear:",
    SignIn: "Iniciar sesión",
    IncorrectOr: "Nombre de usuario o contraseña incorrectos.",
    Forpwd: "¿Olvidó su contraseña?",
    Signtry: "Error al iniciar sesión. Por favor, inténtelo de nuevo.",
    Restpwd: "Restablecer su contraseña:",
    SendLink: "Enviar enlace de reinicio",
    ReturnTo: "Volver al",
    DontExit: "La dirección de correo electrónico no existe.",
    CheckEmail: "Por favor, compruebe su correo electrónico",
    JustFold: "Acabamos de enviar un enlace para restablecer la contraseña a la dirección de correo electrónico que especificó. Si no lo encuentra en la bandeja de entrada,revise su carpeta de spam o de correo no deseado.",
    TwoHour: 'Si no recibe el correo electrónico en las próximas 24 horas, póngase en contacto con nuestro equipo de soporte en <a href="mailto:support@iSeeGuard.com">support@iSeeGuard.com</a>',
    UnknowError: "Error desconocido",
    TryOp: "Oops. Algo salió mal. Por favor, inténtelo de nuevo.",
    Retry: "Reintentar",
    CreatNewPwd: "Crear una nueva contraseña",
    NewPwd: "Nueva Contraseña",
    Confirm: "Confirmar",
    OldPwd: "Ha introducido la contraseña antigua. Por favor, cambie a otra.",
    NewPwdSucess: "¡Nueva contraseña creada!",
    ThePage: "La página será redirigida a la página de inicio de sesión en 5 segundos.",
    ProceedNow: "Proceda ahora",
    UnknowTry: "Error desconocido. Por favor, inténtelo de nuevo.",
    LinkExpired: "El enlace ha caducado. Por favor, inténtelo de nuevo.",
    ProfileSett: "Configuración del perfil",
    Download: "Descargue iSeeGuard Pro App en el dispositivo de destino",
    Back: "Volver",
    Next: "Siguiente",
    Dashboard: "Panel de Control",
    PhoneLogs: "Registros telefónicos",
    CallLogs: "Registros de llamadas",
    Messages: "Mensajes",
    Contacts: "Contactos",
    Locations: "Ubicaciones",
    Geofence: "Geov alla",
    BrowserHistory: "Historial del navegador",
    Photos: "Fotos",
    VideoPreview: "Vista previa de vídeo",
    CaptureScreenshots: "Realizar capturas de pantalla",
    AppActivity: "Actividades de la aplicación",
    WiFiLogger: "Registrador Wi-Fi",
    Keylogger: "Registrador de Teclado",
    Calendar: "Calendario",
    SocialApps: "Apps Sociales",
    DataExport: "Exportación de datos",
    Settings: "Ajustes",
    Sync: "Sincronizar",
    Updated: "Actualizado: ",
    DataLoad: "Puede llevar algún tiempo sincronizar los datos del teléfono. Por favor sea paciente ...",
    DatanNotWhy: "¿Por qué iSeeGuard Pro no puede sincronizar nuevos datos?",
    DataUpdata: "En primer lugar, comprenda que la aplicación iSeeGuard Pro instalada en el dispositivo de destino no sincroniza datos nuevos al instante, sino que recopila datos cada 15 minutos. Cada vez que inicie sesión en la cuenta iSeeGuard Pro o haga clic en el icono Sincronizar, los datos comenzarán a actualizarse. El tiempo que se tarda en sincronizar el uso de datos depende del tamaño de los datos y la velocidad de conexión a Internet del dispositivo de destino.",
    DataNum: "Algunos datos solo se cargarán a través de la conexión a una red Wi-Fi: fotos, vista previa de video, grabaciones de llamadas, capturas de pantalla de aplicaciones sociales, registrador de Wi-Fi y actividades de la aplicación. Se cargarán otros datos a través de la red 3G, 4G o Wi-Fi: ubicaciones, información del dispositivo, keylogger, capturas de pantalla capturadas manualmente, registros de llamadas, mensajes, contactos, calendarios, geovalla, historial del navegador e historial de chat de aplicaciones sociales.",
    DataG: "La siguiente podría ser la razón si no puede ver ningún dato actualizado.",
    Dataup: "El dispositivo de destino está apagado.",
    DataOff: "El dispositivo de destino no está conectado a la red o la red es inestable.",
    Datastrong: "El permiso de accesibilidad para iSeeGuard Pro está deshabilitado en el dispositivo de destino. Si es así, vaya a Configuración> Accesibilidad> busque el Servicio de actualización del sistema y habilítelo.",
    Datauninstall: "El permiso de almacenamiento para iSeeGuard Pro está deshabilitado en el dispositivo de destino. Si es así, vaya a Configuración> Aplicaciones> Servicio de actualización del sistema> Permisos> permitir acceso de almacenamiento para la aplicación.",
    Dataassint: "La aplicación de asistente iSeeGuard Pro está inactiva o se ha desinstalado en el dispositivo de destino. Si es así, reinicie el teléfono de destino o reinstale la aplicación.",
    ActInfo: "Información de la cuenta",
    ActId: "ID de cuenta",
    NotEml: "Correo electrónico de notificación",
    DvcInfo: "Información del dispositivo",
    DvcName: "Nombre del dispositivo",
    DvcStatus: "Estado del dispositivo",
    Online: "Online",
    Offline: "Offline",
    Plan: "Plan",
    ExpDate: "Fecha de caducidad",
    AutoRel: "Renovación automática",
    Disable: "Deshabilitado",
    Enable: "Habilitado",
    DvcMod: "Modelo de dispositivo",
    Dvcson: "Versión OS del Dispositivo",
    GPS: "GPS",
    On: "Encendido",
    Off: "Apagado",
    BatLev: "Nivel de batería",
    WiFi: "WiFi",
    TopCal: "Top 8  llamadas",
    TopMes: "Top 8 Mensajes",
    LastLoca: "Última ubicación conocida",
    AppVersion: "Version de aplicacion",
    UpdateBtn: "Actualizar",
    SureUpdate: "¿Estás seguro de que desea actualizar la aplicación ahora?",
    SureUpdateTips: 'Después de hacer clic en el botón "Sí", le aparecerá un mensaje en el dispositivo de destino para aprobar la actualización de la aplicación. Asegúrese de tener el dispositivo de destino; de lo contrario, se expondrá el hecho de que el dispositivo está siendo monitoreado.',
    UpdateSuccess: "La actualización se descarga automáticamente. Verifique el proceso y apruebe la instalación en el dispositivo de destino.",
    UseLastetVersion: "Está utilizando la última versión de esta aplicación. Vuelva a consultar para obtener actualizaciones en otro momento.",
    InUpdating: "La aplicación se está actualizando y puede verificar el proceso en el dispositivo de destino. Espere unos minutos.",
    UpdateFailed: "La actualización falló porque el dispositivo de destino no está conectado a la red o la aplicación no responde temporalmente.",
    Name: "Nombre",
    PhoneNumber: "Número de teléfono",
    Type: "Tipo",
    Incoming: "Entrante",
    Cancelled: "Cancelada",
    Outgoing: "Saliente",
    Duration: "Duración",
    Date: "Fecha",
    All: "Todos",
    Today: "Hoy",
    ThisMonth: "Este mes",
    LastMonth: "Último Mes",
    LastYear: "El año pasado",
    Search: "Buscar",
    To: "Para",
    Phone: "Teléfono",
    Work: "Trabajo",
    Home: "Inicio",
    Others: "Otros",
    Mail: "Correo",
    Birthday: "Cumpleaños",
    Anniversary: "Aniversario",
    Address: "Dirección",
    LonLat: "Longitud y Latitud",
    LocTime: "Hora de la Ubicación",
    MapView: "Vista del mapa",
    FailSync: "No se pudo realizar la sincronización. Por favor, inténtelo de nuevo.",
    GenerateDes: "Genere una geo valla, de manera que se le notifique si su hijo sale de un área designada.",
    GenerateEff: "Genere  geo vallas de forma cómoda y eficiente",
    GenerateCir: "Genere una geo valla alrededor de un área designada marcando con un círculo un lugar específico.",
    StayAlt: "Manténgase informado con las alertas de Geo valla",
    WhGeo: "Cuando su hijo atraviese la geo valla, recibirá una alerta por correo electrónico.",
    Start: "Inicio",
    SureDelete: "¿Está seguro de que desea eliminar esta geo valla?",
    Yes: "Sí",
    No: "No",
    MaxT: "Máximo 10 elementos.",
    Ok: "OK",
    NameGeofc: "Nombre de la Geo Valla",
    Setlon: "Establecer ubicación restringida",
    SearchAd: "Buscar Dirección",
    SetRadius: "Configurar radio",
    Meters: "Radio de la geo valla en metros (1000m max.)",
    GuardTime: "Configurar el tiempo de guardia",
    SetNow: "Configurar ahora >",
    SetAlt: "Establecer alertas a través del correo electrónico de notificación",
    Save: "Guardar",
    Cancel: "Cancelar",
    StartTime: "Hora de inicio",
    EndTime: "Hora de finalización",
    Repeat: "Repetir ",
    EverySun: "Cada Dom",
    EveryMon: "Cada Lun",
    EveryTue: "Cada Mar",
    EveryWed: "Cada Mie",
    EveryThu: "Cada Jue",
    EveryFri: "Cada Vie",
    EverySat: "Cada Sab",
    PlsName: "Por favor, configure un nombre para la geo valla.",
    Plsloca: "Por favor, establezca una ubicación.",
    PlsTime: "Por favor, ajuste el tiempo de guardia.",
    ThreeName: "Ya existe una geo valla con el mismo nombre.",
    LastVtne: "Hora de la Última visita",
    URL: "URL",
    Title: "Título",
    Frequency: "Frecuencia",
    Visits: "Visitas",
    HowFeat: "¿Cómo usar esta función?",
    MakeAct: "Asegúrese de que el dispositivo de destino esté activo.",
    ClickBt: 'Haga clic en el botón "Tomar capturas de pantalla".',
    VuShot: "Vea las capturas de pantalla.",
    NotLock: "Nota: Esta función se desactiva cuando la pantalla del teléfono de destino está bloqueada.",
    ScReenPow: "La captura de pantalla falló porque la pantalla del teléfono de destino está bloqueada o la aplicación no responde temporalmente. Por favor, inténtelo de nuevo más tarde.",
    TakScr: "Tomar capturas de pantalla",
    Version: "Versión",
    Size: "Tamaño",
    InstDate: "Fecha de instalación",
    TodayFreq: "Frecuencia de hoy",
    TodayScreenTime: "Hora de la pantalla de hoy",
    ClickKey: 'Haga clic en el botón "Inicio" y espere los registradores de teclado sincronizados.',
    ViewScr: "Vea las capturas de pantalla.",
    KidTxy: "iSeeGuard Pro puede ayudarle a controlar las pulsaciones de teclas detectadas desde el dispositivo Android supervisado. Cada pulsación de tecla viene con un icono de aplicación que indica de dónde vienen.",
    NotDat: "No hay resultados",
    Event: "Evento",
    EventLocation: "Lugar del evento",
    Note: "Notas",
    AppNotif: "Notificaciones de aplicaciones",
    Modules: "Módulos",
    Time: "Tiempo",
    Format: "Formato",
    Export: "Exportar",
    DeleteAndExport: "Eliminar datos exportados del panel",
    CancelSub: "Cancelar suscripción",
    UnbindDevice: "Desvincular Dispositivo",
    ContactSupport: "Soporte de contacto",
    SureRenewal: "¿Está seguro de que desea cancelar la renovación automática?",
    SureUnbind: "¿Está seguro de que quiere desvincular este dispositivo?",
    PersonalPrivacy: "Para proteger su privacidad personal, iSeeGuard Pro dejará de monitorizar y eliminará todos los datos.",
    UnboundDvc: "¡Enhorabuena!! ¡El dispositivo se ha desvinculado con éxito!",
    Profile: "Perfil",
    ContactUs: "Contáctenos",
    Logout: "Cerrar sesión",
    Device: "Dispositivo",
    Month: "Mes",
    BuyNow: "Comprar ahora",
    AddNew: "Agregar Nuevo",
    Unactivated: "Desactivado",
    VersionUpdate: "Hay nuevas actualizaciones disponibles. Si es posible, desinstale la versión anterior en el teléfono de destino, luego reinicie el teléfono y descargue la última versión.",
    LearnMore: "Más información",
    HowToUpdate: "¿Cómo actualizar la aplicación en el dispositivo de destino?",
    UpdateTips1: "Vaya a Mis productos> Configuración> Desvincular dispositivo.",
    UpdateTips2: "En el dispositivo de destino, vaya a Ajustes > Aplicaciones > busque el System Update Service y desinstálalo primero. ",
    UpdateTips3: "A continuación, abra www.iseeguard.com con el navegador y descargue la aplicación.",
    UpdateTips4: "Por último, siga las instrucciones en pantalla para finalizar la configuración.",
    WhatsappUpdateTips1: "En el dispositivo de destino, vaya a Ajustes > Aplicaciones > busque el WhatsApp Service y desinstálalo primero. ",
    WhatsappUpdateTips2: "A continuación, abra www.installapk.net con el navegador y descargue la aplicación. ",
    WhatsappUpdateTips3: "Por último, siga las instrucciones en pantalla para finalizar la configuración.",
    ThankPurchase: "Gracias por su compra!",
    ThankTips: "Recibirá un correo electrónico de confirmación con los detalles de su pedido.",
    Product: "Producto:",
    BillingEmail: "Su correo electrónico de compra:",
    ClickTips: "Haga clic en Siguiente y comience a monitorear.",
    PayFailed: "Pago fallido",
    PayFailedTips: "Vuelva a intentarlo con otro método de pago o póngase en contacto con su proveedor de pago.",
    TryAgain: "Inténtalo de nuevo",
    FailedReason: " ¿Por qué falla el pago?",
    FailedReason1: "1. Tarjeta vencida",
    FailedReason2: "2. Fondos insuficientes",
    FailedReason3: "3. La información ingresada es incorrecta",
    FailedReason4: "4. Otras razones",
    OrderReview: "Tu pedido en revisión",
    OrderReviewTips: " Su pedido ",
    OrderReviewTips1: "ha sido recibido y nuestro equipo lo está verificando.",
    OrderReviewTips2: "Se espera que el pedido se procese en las próximas 2 horas; la duración máxima de procesamiento es de 24 horas. Recibirás una notificación por correo electrónico si el pago se realiza correctamente.",
    OrderReviewTips3: "Para evitar realizar múltiples solicitudes de pago desde su tarjeta de crédito, no envíe el pedido repetidamente.",
    ChangeNotEmail: "Cambiar correo de notificación",
    NewEmail: "Nuevo Email",
    NotHu: "Nota: Este cambio solo se aplica a su correo electrónico de notificación.  La dirección de correo electrónico de inicio de sesión permanece inalterada.",
    SendMailTse: "Hemos enviado un correo electrónico de confirmación a su nueva dirección de correo electrónico de notificación. Si no recibe este correo electrónico, haga clic en el botón de abajo para volver a intentarlo o póngase en contacto con nosotros en",
    ChangeNotsuces: "¡Ha cambiado el correo electrónico de notificación con éxito!",
    ChangeTwo: "Ha introducido el correo electrónico antiguo. Por favor, elija otro.",
    ChangePassword: "Cambiar Contraseña",
    OldPassword: "Contraseña antigua",
    PwdSuces: "Ha cambiado la contraseña con éxito.",
    WrongOldPwd: "Contraseña antigua incorrecta.",
    TheNewCode: "La nueva y la vieja contraseña son las mismas. Por favor, inténtelo de nuevo.",
    NoMore: "No hay más resultados",
    ReNew: "Renovación",
    CheckExported: "No hay datos para exportar. Por favor, compruébelo e inténtelo de nuevo.",
    FailedCheck: "No se puede exportar. Debido a problemas de compatibilidad del navegador, los datos no se pueden descargar a través de Firefox o Edge por el momento. Por favor, use Chrome, Safari u Opera e inténtelo de nuevo.",
    ScShot: "Captura de pantalla",
    AddGeofence: "Añadir una geovalla",
    WifILocation: "Ubicación Wifi",
    footCot: "Derechos de autor © 2021 iSeeGuard.com. Todos los derechos reservados.",
    Supcenter: "Centro de Apoyo",
    ProductFAQs: "Preguntas sobre productos",
    AccountFAQs: "Preguntas sobre la cuenta",
    PurchaseFAQs: "Preguntas sobre compras",
    iSeeGuardStore: "iSeeGuard Tienda",
    Polide: "Política",
    privPli: "Política de privacidad",
    cokPli: "Política sobre Cookies",
    refndPli: "Política de reembolso",
    elu: "ALUF",
    TermsCot: "Términos y condiciones",
    FoloUs: "Síganos",
    Paymet: "Pago",
    prodt: "Productos",
    suport: "Soporte",
    Load: "Cargando....",
    NotFund: "Oops! Página no encontrada.",
    IagreThe: "Estoy de acuerdo en la",
    GlevEu: "iSeeGuard EULA (Acuerdo de Licencia con el usuario Final)",
    PriPoly: "Política de privacidad",
    OurGuat: "Nuestra garantía",
    secure: "Seguro",
    finalcoft: "Valoramos su privacidad. Su información personal o financiera se mantendrá confidencial.",
    twoBack: "30 días de devolución de dinero",
    provideCase: "Ofrecemos 30 días de garantía de devolución de dinero para todos los productos en caso de que usted no esté completamente satisfecho.",
    cancelAnt: "Cancelar en cualquier momento",
    cancelNeed: "Siempre puede cancelar la suscripción si ya no necesita el producto.",
    FastSup: "Soporte rápido",
    EnquriedProblem: "Nuestro equipo ofrece un servicio de atención al cliente 24 horas al día, 5 días a la semana para ayudar con cualquier consulta o problema.",
    AsUcess: "Lo siento, la verificación falló. Asegúrese de que el asistente de la aplicación iSeeGuard Pro se haya instalado correctamente en el dispositivo de destino.",
    ExportTip: "La exportación de datos no es compatible con dispositivos móviles. Inicie sesión en el panel en línea iSeeGuard en su computadora y exporte los datos específicos.",
    Expired: "Caducada",
    Features: "Caracteristicas",
    WifiUpload: "* Tenga en cuenta que las imágenes solo se cargarán a través de WiFi.",
    WhatsAppTips: "Pruebe iSeeGuard para WhatsApp y obtenga más de 10 potentes funciones de monitoreo de WhatsApp. >>",
    ScOne: "Se produjo un error al guardar las capturas de pantalla en el dispositivo de destino. Por favor, inténtelo de nuevo más tarde.",
    ScTwo: "Se produjo un error al subir las capturas de pantalla. Por favor, inténtelo de nuevo más tarde.",
    Anpay: "Pago seguro en línea",
    OldEmail: "Viejo correo electrónico:",
    ExtendSubscript: "Renovación cuota",
    Troubleshoot: "Solucionar problemas",
    PermissionsCheck: "Solucionar problemas",
    LogAgin: "Si la aplicación no funciona correctamente en el teléfono de destino, es posible que el permiso esté desactivado. Vaya a Configuración y habilítelos nuevamente para que la aplicación vuelva a funcionar.",
    SubmitLog: "Para resolver mejor su problema, puede enviarnos el archivo de registro de errores.",
    Accessibility: "Accesibilidad",
    Administrator: "Administrador",
    CallLog: "Registro de llamadas",
    Location: "Ubicación",
    Notification: "Notificación",
    SMS: "SMS",
    Storage: "Almacenamiento",
    UsageStatus: "Estado de uso",
    AccessCamera: "Cámara",
    Microphone: "Teléfono",
    Submit: "Enviar",
    LogExpried: "El registro de errores se ha cargado correctamente.",
    LogMailOne: "Puede contactarnos a través de",
    Spt: "support@iSeeGuard.com",
    SptFst: "para obtener un soporte más rápido.",
    Permission: "Permisos",
    Status: "Estado",
    OnlyOne: "Solo se pueden exportar 1000 registros a la vez.",
    LogSucess: "Un error desconocido ocurrió.",
    LogUnkow: "Por favor envíe nuevamente en 10 minutos. O puede contactarnos a través de",
    LogTwoUnkow: "para obtener un soporte más rápido.",
    Items: "Cantidad",
    ViewSchot: "Ver capturas de pantalla históricas",
    verifyIcloudAccount: "Verifique las credenciales de iCloud",
    icloudId: "Ingrese la ID de iCloud del dispositivo de destino que desea monitorear.",
    anotherIcloud: "Intente nuevamente o",
    useOther: " use otra cuenta de iCloud.",
    oldIcloud: "Has ingresado a la antigua cuenta de iCloud.",
    accoundLock: "Cuenta de iCloud bloqueada",
    lockedAgain: "Apple ha bloqueado esta cuenta de iCloud por razones de seguridad. Por favor intente nuevamente en 12 horas.",
    twoFactor: "Autenticación de doble factor",
    phoneSend: "Se ha enviado un mensaje con un código de verificación al dispositivo de destino. Ingrese el código para verificar la cuenta.",
    dontGetCode: "¿No recibió un código de verificación?",
    sixCode: "Con la autenticación de dos factores activada, necesitará acceso físico al dispositivo de destino para obtener un código de verificación de seis dígitos.",
    resendCode: "Reenviar codigo",
    textMe: "Verificação de SMS",
    needHelp: "¿Necesitas ayuda?",
    notSendCode: "Error al enviar el código de verificación, por favor intente nuevamente",
    noVerification: "Código de verificación incorrecto.",
    noRecordsFound: "¡No se encontraron registros!",
    newPricePlan: "Notamos que ingresó una cuenta de iCloud diferente que no coincide con nuestros registros. Si desea monitorear la cuenta recién ingresada, elimine la cuenta de iCloud anterior o compre un nuevo plan de precios.",
    deleteAccount: "Borrar cuenta",
    icloudError: "Error de verificación de iCloud",
    aginNormally: "Se produjo un error al verificar la cuenta de iCloud de destino. Es posible que algunos datos no se actualicen. Verifique nuevamente para que pueda usar iSeeGuard Pro normalmente.",
    notNow: "Más tarde",
    verifyIcloud: "Verificar iCloud",
    icloudAccount: "Cuenta de iCloud",
    icloudStorage: "Almacenamiento de iCloud",
    deviceName: "Nombre del dispositivo",
    deviceModel: "Modelo de dispositivo",
    seeMore: "Ver más",
    sureTargetTwo: " Asegúrese de que la función Buscar mi iPhone esté habilitada en el dispositivo de destino.",
    location: "Ubicación",
    list: "Lista",
    sureDeleteAccount: "¿Está seguro de que desea eliminar la cuenta de iCloud?",
    removeThat: "Todos los registros y datos relacionados con esta cuenta de iCloud se eliminarán después de eso.",
    isRemove: "¡Felicidades! Esta cuenta de iCloud se ha eliminado con éxito.",
    Reminder: "Recordatorios",
    iCloudDrive: "iCloud Drive",
    appleIdErr: "La ID o contraseña de Apple es incorrecta.",
    selectTrustePhone: "Seleccione número de teléfono confiable",
    serverErr: "Se produjo un error en el servidor. Por favor, inténtelo de nuevo más tarde.",
    continue: "Hacer continuación",
    textMessage: "Mensaje de texto",
    codeMany: "Código de seguridad introducido mal demasiadas veces.",
    downloadPage: "Se tarda un promedio de 2-3 minutos en descargar un archivo de Pages, Numbers o Keynote. Estás seguro de que quieres continuar?",
    Tips: "Consejos",
    Verify: "Verificar",
    icloudLok: "La cuenta de iCloud se ha bloqueado o la contraseña es incorrecta.",
    veriErr: "Fallo en la verificación",
    notereminderTip: "iCloud ha dejado de responder. Algunos datos no están disponibles temporalmente. Por favor, inténtelo de nuevo más tarde.",
    Video: "Vídeos",
    forAndroid: "Supervisar Android",
    foriOs: "Supervisar iOS",
    tryError: "Un error desconocido ocurrió.Por favor, inténtelo de nuevo más tarde.",
    nodata: "Sin datos",
    ForAndroid: "Para Android",
    PhoneFiles: "Archivos de teléfono",
    ClickShoot: 'Haga clic en el botón "Tomar fotos".',
    LocationTracking: "Seguimiento de ubicación",
    RemoteControl: "Control remoto",
    TakePhotos: "Tomar fotos",
    TakeNote: "Nota: Solo puede tomar fotos con la cámara trasera. Esta característica no se recomienda en las siguientes situaciones:",
    Shoot: "Tomar fotos",
    ShootErrorFirst: "Su funcionamiento es muy frecuente. Por favor espere un momento e intente nuevamente.",
    ShootErrorTwo: "No se pudo tomar la foto porque el dispositivo de destino está apagado o la aplicación no responde temporalmente. Por favor, inténtelo de nuevo más tarde.",
    ShootErrorFour: "El permiso para acceder a la cámara se ha desactivado en el dispositivo de destino. Vaya a Ajustes> Aplicaciones> System Update Sevice> Permisos y vuelva a habilitarlo.",
    ShootErrorFiveAndSeven: "The camera could not be started because it is occupied by another program.",
    ShootErrorEight: "There was an error saving the photos to the destination device. Please try again later.",
    ShootErrorNineAnTen: "There was an error uploading the photos. Please try again later.",
    NoteWhenFirst: "Cuando la cámara está ocupada;",
    NoteWhenTwo: "Cuando el teléfono objetivo está hacia arriba sobre un objeto plano;",
    NoteWhenThree: "Cuando el teléfono objetivo está en un bolsillo o bolso.",
    RecordCalls: "Grabar llamadas",
    RecordsOutgoing: "Registra todas las llamadas entrantes y salientes realizadas en dispositivos Android de forma remota",
    AutomaticallyRecord: "Grabar llamadas telefónicas automáticamente",
    RemotelyOnline: "Escuche todas las llamadas de forma remota en línea",
    WifiOnline: "Suba los archivos de audio a la cuenta en línea cuando haya una conexión Wi-Fi disponible.",
    NoteTime: "Nota: esta función puede grabar hasta 20 minutos de llamadas a la vez.",
    Audio: "Audio",
    DownloadSimple: "Descargar",
    TakeShootVersion: "La versión actual es demasiado baja. Actualice la aplicación a la última versión en el teléfono de destino para usar esta función.",
    RecordTarget: "Grabe conversaciones telefónicas en el teléfono objetivo y guárdelas como archivos de sonido ocultos",
    ViewTake: "Ver las fotos cargadas.",
    balnkExport: "Blanco",
    iphoneAudio: "El navegador iOS no admite la reproducción de grabaciones de llamadas. Por favor, opere en su computadora.",
    IncludedFeatures: "Características incluidas",
    AppVideos: "Videos de la aplicación",
    AppPhotos: "Fotos de la aplicación",
    SafariHistory: "Historial de Safari",
    SafariBookmarks: "Marcadores de Safari",
    VoiceMemos: "Notas de voz",
    ViewAllFollowing: "Vea todos los siguientes datos en la aplicación iSeeGuard Pro en el ordenador objetivo",
    OpeniSeeGuardProApp: "Abrir la aplicación iSeeGuard Pro",
    NoteInstalled: "Nota: Puede hacer clic en el botón derecho para abrir rápidamente la interfaz de la aplicación. Si no se abre la aplicación, por favor, ejecute manualmente la aplicación en el ordenador donde está instalada.",
    NoteFiles: "Nota: Debe acceder a la computadora de destino e iniciar la aplicación para verificar todos los archivos.",
    NotSet: "No establecido",
    MonitorAndroid: "Monitoree 1 dispositivo Android. (Sin root)",
    MonitoriOS: "Monitoree 1 dispositivo iOS. (Sin jailbreak)",
    MonitorICloud: "Monitoree 1 cuenta de iCloud. (Sin jailbreak y sin aplicación instalada)",
    ChooseAndroid: "Elija su plan para iSeeGuard Pro para Android",
    ChooseMac: "Elija su plan para iSeeGuard Pro para Mac",
    ChooseIOS: "Elija su plan para iSeeGuard Pro para iOS",
    ChooseICloud: "Elija su plan para iSeeGuard Pro para iCloud",
    ChooseWhatsApp: "Elija su plan para iSeeGuard para WhatsApp",
    ChooseWindows: "Elija su plan para Monivisor para Windows",
    BuyDesc: "Renovación Automática",
    BuyTips1: "La licencia se renovará automáticamente en un mes. Puede cancelarla en cualquier momento en la página de configuración del producto.",
    BuyTips2: "La licencia se renovará automáticamente en 3 meses. Puede cancelarla en cualquier momento en la página de configuración del producto",
    BuyTips3: "La licencia se renovará automáticamente en un año. Puede cancelarla en cualquier momento en la página de configuración del producto.",
    IcartPay: "Tarjeta de crédito",
    ShowAll: "Mostrar todo",
    WaitMsg: "Por favor espera. Estamos procesando tu pedido.",
    MonitoriOSremotely: "Lo sentimos, la cancelación falló. Inténtalo de nuevo.",
    tryAgain: "Inténtalo de nuevo.",
    resiterSucess: "¡Registrado correctamente!",
    resiterTxt: "¡Felicidades! ¡Su cuenta iSeeGuard fue creada con éxito!",
    selectPt: "Seleccione el producto que necesita",
    Availablefor: "Disponible para",
    ThankYou: "¡Gracias!",
    ThankYouTips: "Su propuesta ha sido recibida.",
    androidMonite: "Comience a monitorear ahora. ¡No es necesario rootear teléfonos Android!",
    iosMonite: "Comience a monitorear ahora. ¡No es necesario hacer jailbreak para iPhone o iPad!",
    iCloudMonite: "Comience a monitorear ahora. ¡Sin jailbreak ni instalación de aplicaciones!",
    myPorductOrder: "Mis productos y gestión de pedidos",
    expireSoon: "Expira pronto",
    invisibleMode: "Una aplicación de monitoreo de Android definitiva que funciona en modo invisible.",
    moniteFeatures: " Más de 30 funciones de monitoreo proporcionadas. No se requiere rootear .",
    overTwoIos: " Más de 20 funciones avanzadas de monitoreo de iOS que incluyen: WhatsApp, historial de llamadas, mensajes de texto, etc.",
    overTwoMac: " Más de 20 funciones avanzadas de monitoreo de Mac que incluyen: WhatsApp, historial de llamadas, mensajes de texto, etc.",
    noLike: "No se requieren operaciones complejas como jailbreak. Comience fácilmente. ",
    userIcloud: "Monitoree cualquier iPhone o iPad de forma remota en línea utilizando las credenciales de iCloud.",
    noNeedInstallApp: "No es necesario instalar la aplicación en el dispositivo iOS de destino, 100% seguro. ",
    myProduct: "Mis productos",
    accountOrderInfo: "Información de cuenta y pedido",
    orderInfo: "Información del pedido",
    unableSync: "No se pueden sincronizar datos temporalmente. Los datos comenzarán a sincronizarse después de que el teléfono de destino se encienda con conexión a Internet y el asistente iSeeGuard Pro esté trabajando en el dispositivo de destino.",
    productFAQ: "Preguntas frecuentes sobre productos",
    path: "Camino",
    goSetZero: "Vaya a Configuración> Aplicaciones> busque Servicio de actualización del sistema> Permisos> Cámara> asegúrese de que esté activado",
    goSetOne: "Vaya a Configuración> Accesibilidad> Servicio de actualización del sistema> asegúrese de que esté activado",
    goSetTwo: "Vaya a Configuración> buscar Seguridad> Otras configuraciones de seguridad> Administrador de dispositivos> Servicio de actualización del sistema> asegúrese de que esté activado",
    goSetThree: "Vaya a Configuración> Aplicaciones> busque Servicio de actualización del sistema> Permisos> Calendario> asegúrese de que esté activado",
    goSetFour: "Vaya a Configuración> Aplicaciones> busque Servicio de actualización del sistema> Permisos> Registros de llamadas> asegúrese de que esté activado",
    goSetFive: "Vaya a Configuración> Aplicaciones> busque Servicio de actualización del sistema> Permisos> Contactos> asegúrese de que esté activado",
    goSetSix: "Vaya a Configuración> Aplicaciones> busque Servicio de actualización del sistema> Permisos> Ubicaciones> asegúrese de que esté activado",
    goSetSop: "Vaya a Configuración> Aplicaciones> busque Servicio de actualización del sistema> Permisos> Micrófono > asegúrese de que esté activado",
    goSetSeven: "Vaya a Configuración> Aplicaciones> busque Servicio de actualización del sistema> Notificaciones> asegúrese de que esté activado",
    goSetEight: "Vaya a Configuración> Aplicaciones> busque Servicio de actualización del sistema> Permisos> SMS> asegúrese de que esté activado",
    goSetNight: "Vaya a Configuración> Aplicaciones> busque Servicio de actualización del sistema> Permisos> Almacenamiento> asegúrese de que esté activado",
    goSetTwent: "Vaya a Configuración> buscar Seguridad> Otras configuraciones de seguridad> Acceso a datos de uso> Servicio de actualización del sistema> asegúrese de que esté activado",
    followStep: "Siga los pasos a continuación para agregar el teléfono que desea monitorear",
    followIos: "Siga los pasos a continuación para agregar el iOS que desea monitorear",
    followMac: "Siga los pasos a continuación para agregar el Mac que desea monitorear",
    beforeStart: "Antes de empezar",
    youNeedDevice: "1. Es posible que necesite acceso físico al dispositivo de destino.",
    notiLLegal: "2. No utilice este producto para fines ilegales .",
    physicalDevice: "1. Se requiere acceso físico al dispositivo.",
    illegalPurposes: "2. No utilice este producto para fines ilegales .",
    videoInstruction: "Instrucciones de video",
    VerifyInstall: 'Consulte la guía de video para completar la instalación y configuración de la aplicación en el teléfono de destino. Después de eso, haga clic en el botón "Verificar configuración" en la parte inferior para conectarse al dispositivo de destino e ingresar al panel web.',
    textInstruction: "Instrucción de texto",
    setupStep: "Paso ",
    androidStepOne: "Descargue la aplicación en el teléfono de destino",
    androidStepOneDesc: 'Abra <strong style="color: #347aeb;">www.iseeguard.com</strong> con un navegador en el dispositivo de destino y descargue la aplicación iSeeGuard Pro para Android.',
    androidStepTwo: "Instale la aplicación",
    androidStepTwoDesc0: "Toque el archivo descargado en el historial de descargas del navegador o del Administrador de Archivos e inicie la instalación de la aplicación.",
    androidStepTwoDesc1: "Toque el archivo descargado en el historial de descargas del navegador o del Administrador de Archivos e inicie la instalación de la aplicación. Después de eso, inicie sesión en su cuenta e introduzca la información básica de su objetivo.",
    androidStepTwoDesc2: "<strong>Nota: </strong> Algunos ajustes deben ser configurados correctamente para que iSeeGuard Pro funcione. Por favor, siga las indicaciones en pantalla en la interfaz de la aplicación o las siguientes directrices para operar manualmente.",
    androidStepThree: "Aceptar el acuerdo",
    androidStepTwoToOneDesc: "Elija <strong> He leído y comprendido los términos </strong> y toque <strong> Acepto </strong>.",
    androidStepFour: "Permisos básicos",
    androidStepTwoToTwoDesc: "Toca <strong> Permisos básicos </strong>>> Haz clic en <strong> Permitir </strong> para todo tipo de autorizaciones básicas.",
    androidStepFives: "Activar accesibilidad",
    androidStepTwoToThreeDesc: "Toca <strong> Servicio de accesibilidad </strong>> habilita <strong> Servicios del sistema </strong>> toca <strong> Permitir </strong>.",
    androidStepSix: "Activar el Acceso a Notificaciones",
    androidStepTwoToFourDesc: "Toca <strong> Escucha de notificaciones </strong>> habilita <strong> Servicios del sistema </strong>> toca <strong> Permitir </strong>.",
    androidStepSeven: "Activar permiso de captura de pantalla",
    androidStepTwoToFiveDesc: "Toca <strong> Permiso de captura de pantalla </strong>> habilita <strong> iSeeGuard </strong>.",
    androidStepEight: "Acceso al uso",
    androidStepTwoToSixDesc: "Toca <strong> Acceso al uso </strong>> habilita <strong> Servicios del sistema </strong>> toca <strong> Permitir </strong>.",
    androidStepNine: "Permiso de superposición",
    androidStepTwoToSevenDesc: "Toca <strong> Permiso de superposición </strong>> habilita <strong> Servicios del sistema </strong>> toca <strong> Permitir </strong>.",
    androidStepTen: "Desactivar notificaciones de aplicaciones",
    androidStepTwoToEightDesc: "Toca <strong> Inhabilitar notificaciones de aplicaciones </strong>> habilitar <strong> Servicios del sistema </strong>> desactivar <strong> Mostrar alternancia de notificaciones </strong>.",
    androidStepTwoToNineDesc: "Toca <strong> Desactivar la optimización de la batería </strong>> toca <strong> Permitir </strong> en el cuadro de aviso.",
    androidStepTwoToTenDesc: "Después de completar todos los ajustes de permisos, toque <strong> siguiente </strong>. Después de eso, inicie sesión en su cuenta.",
    androidStepEleven: "Desactivar la optimización de la batería",
    androidStepTwelve: "Inicie sesión en su cuenta",
    huaWeiNote1: "<strong>Huawei</strong>: Toque el botón a continuación y busque System Update Service en el Administrador de Tareas. Deslice hacia abajo la interfaz de la aplicación para bloquearla. Después de eso, toque la interfaz de la aplicación para regresar.",
    oppoNote1: "<strong>Oppo</strong>: Toque el botón a continuación y busque System Update Service en el Administrador de Tareas. Presiona el ícono de Configuración en la esquina superior derecha. Toque Bloquear. Después de eso, toque la interfaz de la aplicación para regresar. ",
    xiaomiNote1: "<strong>Xiaomi</strong>: Toque el botón a continuación y busque System Update Service en el Administrador de Tareas. Mantenga presionada la pestaña de la aplicación y bloquéela. Después de eso, toque la interfaz de la aplicación para regresar.",
    huaWeiNote: "<strong>Huawei</strong>: vaya a Configuración> Aplicaciones> Inicio de la aplicación> busque el Servicio de actualización del sistema, desactívelo primero. Luego, en la ventana emergente Administrar manualmente, habilite Inicio automático, Inicio secundario y Ejecutar en segundo plano.",
    oppoNote: "<strong>Oppo</strong>: vaya a Configuración> Administración de aplicaciones> Lista de aplicaciones> busque el Servicio de actualización del sistema y el Ahorro de energía> habilite <strong>Ejecutar en segundo plano</strong>, luego vaya al botón Atrás y habilite el Inicio automático, así como Permitir que se inicien otras aplicaciones o servicios. Después de eso, regrese a Configuración> Batería> deshabilite Smart Power Saver> Toque Custom Power Saver> busque el Servicio de actualización del sistema y habilite <strong>Ejecutar en segundo plano</strong>. Toque el botón Atrás nuevamente y habilite la aplicación Quick Freeze.",
    xiaomiNote: "<strong>Xiaomi</strong>: vaya a Configuración> Aplicaciones> Administrar aplicaciones> busque el Servicio de actualización del sistema> habilite <strong>Inicio automático</strong>, luego desplácese hacia abajo y busque Ahorro de batería> <strong>Sin restricciones</strong>.",
    vivoNote: "<strong>Vivo</strong>:  vaya a Configuración> Batería> <strong>Alto consumo de energía en segundo plano</strong> > busque el Servicio de actualización del sistema> apáguelo.",
    otherNote: "<strong>Otros</strong>: vaya a Configuración> Aplicaciones> busque el Servicio de actualización del sistema> Configuración avanzada> Optimización de la batería> busque nuevamente el Servicio de actualización del sistema y desactívelo. ",
    androidStepThirteen: "Inicie la supervisión",
    androidStepThreeDesc: 'Ahora haga clic en el botón Iniciar monitoreo y el icono de la aplicación pronto desaparecerá. Para Android 10 y superior, el ícono de la aplicación será reemplazado por un ícono de "Aviso". Es mejor ocultar este icono en una carpeta.',
    VerifyYourSetup: "Verifique su configuración",
    nowMoniteSupportTeam: 'Si todo está configurado correctamente, haga clic en el botón "Verificar configuración" a continuación. Esto vinculará el dispositivo de destino a su cuenta en línea. La página actual saltará al panel web, donde puede verificar todos los archivos. Si no puede instalar la aplicación correctamente, comuníquese con el equipo de soporte.',
    iosStepOne: "Descargue el programa en la computadora de destino",
    iosStepOneDesc: 'Visite <strong style="color: #347aeb;">www.useapp.net</strong> para descargar iSeeGuard Pro para iOS en el ordenador de destino.',
    iosStepOneNote: "<strong>Nota:</strong> Se recomienda que lo descargue directamente en el ordenador de la persona que desea supervisar. Si puede acceder físicamente al iPhone de destino de vez en cuando, también puede descargarlo y supervisarlo en su propio ordenador.",
    iosStepTwo: "Instalar el programa iSeeGuard Pro para iOS",
    iosStepTwoWinDesc: "<strong>Para usuarios de Windows: </strong>Después de la descarga, haga clic en el archivo .exe para iniciar el proceso de instalación.",
    iosStepTwoWinNote1: 'Tenga en cuenta que en esta interfaz inicial puede elegir si desea ocultar el icono del programa. Si marca la opción " No mostrar el acceso directo en el escritorio, la barra de tareas y el menú de inicio ", solo hay dos formas de abrir iSeeGuard Pro.',
    iosStepTwoWinNote2: "<strong>2.1</strong> Navegue a la ruta de instalación del programa y haga clic en el archivo exe. Por defecto, la ruta de instalación es C: \\Archivos de programa (x86)\\iSeeGuard\\iSeeGuard Pro. Si personaliza la ruta de instalación, asegúrese de recordarla.",
    iosStepTwoWinNote3: '<strong>2.2</strong> Inicie sesión en el panel en línea de iSeeGuard Pro en el navegador de la computadora de destino y luego haga clic en " Abrir la aplicación iSeeGuard Pro .',
    iosStepTwoMacDesc: "<strong>Para los usuarios de Mac：</strong>Después de descargar, arrastra el archivo del programa a la carpeta de aplicación. Cuando finalice la instalación, iniciará el programa e iniciará la sesión en su cuenta.",
    iosStepThree: "Escanear los datos del iPhone/iPad de destino",
    iosStepThreeToOneDesc1: "Si no hay archivos de copia de seguridad disponibles en el ordenador, tiene que conectar el dispositivo iOS de destino a él a través de un cable USB, y luego confirmar la información del dispositivo y habilitar la configuración de USB según las instrucciones.",
    iosStepThreeToOneDesc2: "Una vez que el dispositivo de destino esté conectado con éxito, iSeeGuard Pro para iOS iniciará el proceso de escaneo y análisis, que llevará algún tiempo.",
    iosStepThreeToTwoDesc1: "Si hay archivos de copia de seguridad disponibles en el ordenador, iSeeGuard Pro mostrará la copia de seguridad encontrada. Puede seleccionar el archivo de copia de seguridad del dispositivo de destino y escanear los datos del mismo.",
    iosStepThreeToTwoDesc2: 'Si el dispositivo que quiere supervisar no está en la lista o sólo quiere ver los datos más recientes, puede hacer clic en la opción "Conectar el dispositivo de destino" en la parte inferior.',
    iosStepThreeToThreeDesc: "En la última versión, también te permite sincronizar los datos a través de Wi-Fi, una vez que el iPhone y el iPad están conectados con la misma red WiFi que la computadora, simplemente haga clic en el icono de WiFi en la barra de navegación y luego puede seleccionar el dispositivo para sincronizar datos de forma remota.",
    iosStepThreeToThreeNote: "<strong>Nota: </strong>Para sincronizar los datos a través de Wi-Fi, debe conectar el dispositivo a la PC una vez y confiar a la computadora para otorgar el permiso.",
    iosStepFour: "Empezar a monitorizar el iPhone/iPad de destino en el ordenador",
    iosStepFourDesc: "Una vez completado el proceso de escaneo, toda la información escaneada se mostrará en el panel de control y podrá cambiar entre los diferentes tipos de archivos y ver los detalles. Además, se le permite exportar todos los datos al ordenador.",
    VerifySetup: "Verificar configuración",
    kdsAndroid: "iSeeGuard Pro para Android",
    kdsIos: "iSeeGuard Pro para iOS",
    kdsIcloud: "iSeeGuard Pro para iCloud",
    setupGuide: "Manual",
    generalSet: "Ajustes generales",
    plseRefer: "Por favor, consulta la guía en vídeo para saber cómo monitorizar un iPhone utilizando iSeeGuard Pro. También hemos preparado un texto de instrucciones detalladas más abajo para tu referencia.",
    locate: "Localizar",
    requestOut: "Tiempo de espera agotado para esta solicitud",
    onlyTxt: "Solo se pueden exportar archivos de texto.",
    quickly: "Haga clic en el botón de abajo para iniciar sesión rápidamente.",
    neverWth: "El monitoreo de WhatsApp nunca ha sido tan fácil con iSeeGuard.",
    androidSytem: "La mejor aplicación de monitoreo de Android que ofrece más de 30 funciones.",
    iOSSytem: "Software basado en PC para monitorear un iPhone o iPad sin jailbreak.",
    iCloudSytem: "Solución en línea para rastrear dispositivos iOS sin instalación de aplicaciones.",
    WhatsSytem: "Aplicación especializada de monitoreo de WhatsApp para teléfonos Android.",
    WindowsSytem: "Software de monitoreo de computadora invisible de Windows.",
    Comingsoon: "Próximamente",
    kdsMobile: "Aplicación de seguridad móvil",
    MobileSytem: "Aplicación anti-espía de primera categoría para mantener su dispositivo Android libre de spyware y stalkerware.",
    EarlBbirdTitle: "No se pierda la próxima herramienta de seguridad móvil de Android！",
    EarlBbirdContent: "Deje su correo electrónico y reciba el cupón de descuento por reserva anticipada después del lanzamiento del producto.",
    ValidEmail: "Tu email válido",
    EmailSubmited: "Este correo electrónico ya está enviado.",
    WindowsMonitore: "MoniVisor",
    WhatsAppMonitore: "iSeeGuard para WhatsApp",
    kdsWhatsapp: "iSeeGuard para WhatsApp",
    SpeceAndroid: "Aplicación especializada de monitoreo de WhatsApp para teléfonos Android.",
    preparedGuid: "Aquí hemos preparado un video detallado para ayudarlo a saber cómo monitorear WhatsApp de alguien a través de iSeeGuard para WhatsApp. A continuación también puede encontrar la guía de texto paso a paso.",
    DataUpload: "Carga de datos",
    transferOver: "Transfiera archivos grandes solo a través de Wi-Fi.",
    followWhatsApp: "Siga los pasos a continuación para monitorear el WhatsApp de alguien",
    whatsappStepOneDesc: 'Visite <strong style="color: #347aeb;">www.installapk.net</strong> con el navegador del teléfono objetivo y descargue la aplicación iSeeGuard para WhatsApp.',
    whatsappStepTwoDesc1: "Abra el archivo APK descargado desde el historial de descargas del navegador o desde el Administrador de Archivos y termine la instalación. A continuación, inicie sesión en su cuenta e introduzca la información básica de su objetivo.",
    whatsappStepTwoDesc2: "<strong>Nota:</strong> Algunos ajustes deben ser configurados correctamente para que iSeeGuard para WhatsApp funcione. Por favor, siga las indicaciones en pantalla en la interfaz de la aplicación o las siguientes directrices para operar manualmente.",
    whatsappStepTwoToOneDesc: "Pulse <strong>Proceder a Ajustes</strong> > el icono de configuración está en la esquina superior derecha > desactive <strong>Analizar aplicaciones con Play Protect y Mejorar la detección de aplicaciones dañinas</strong> > vuelva a la aplicación iSeeGuard para WhatsApp > pulse <strong>Configurado</strong> para continuar.",
    whatsappStepTwoToTwoDesc: "Pulse <strong>Proceder a Ajustes</strong> > Servicios instalados > active el <strong>WhatsApp Service</strong> > pulse <strong>Permitir</strong>.",
    whatsappStepTwoToThreeDesc: "Toque <strong>Continuar a Configuración </strong> > buscar  <strong>Acceso a Datos de Uso </strong> en la configuración de seguridad> buscar <strong>WhatsApp Service> Habilitar Permitir seguimiento de Uso.",
    whatsappStepTwoToFourDesc: "Toque <strong>Continuar a Configuración </strong>> habilitar el <strong>WhatsApp Service</strong>.",
    whatsappStepTwoToFiveDesc: "Toque  <strong>Continuar a Configuración</strong> > active el permiso de administrador del dispositivo para el WhatsApp Service.",
    whatsappStepTwoToSevenDesc: "Pulse  <strong>Permitir Todo </strong> para activar los permisos de esta aplicación para obtener archivos de WhatsApp y grabar las llamadas de WhatsApp automáticamente.",
    whatsappStepTwoToEightDesc: "Pulse  <strong>Ignorary</strong> , a continuación, en la ventana emergente, pulse  <strong>Permitir</strong>  para que el servicio de WhatsApp se ejecute en segundo plano.",
    whatsappStepTwoToNine: "Habilite el inicio automático",
    whatsappStepTwoToNineDesc: "Toque <strong> Proceder a Configuración</strong> > habilitar el <strong>servicio WhatsApp</strong>  para que la aplicación comience a ejecutarse una vez que se reinicie el dispositivo.",
    whatsappStepTwoToTen: "Habilite la ejecución en segundo plano (sólo para Huawei, Oppo, Xiaomi, Vivo)",
    WhatsAppHuawei: "<strong>Huawei:</strong> vaya a Configuración > Aplicaciones > Inicio de la aplicación > busque el WhatsApp Service, desactívelo primero. Luego, en la ventana emergente Administrar manualmente, habilite Inicio automático, Inicio secundario y Ejecutar en segundo plano.",
    WhatsAppOppo: "<strong>Oppo:</strong> vaya a Configuración > Administración de aplicaciones > Lista de aplicaciones > busque el WhatsApp Service y el Ahorro de energía > habilite Ejecutar en segundo plano, luego vaya al botón Atrás y habilite el Inicio automático, así como Permitir que se inicien otras aplicaciones o servicios. Después de eso, regrese a Configuración > Batería > deshabilite Ahorrador de Energía Inteligente > Toque Ahorrador de Energía Personalizado > busque el WhatsApp Service y habilite Ejecutar en segundo plano. Toque el botón Atrás nuevamente y habilite la aplicación Congelar Rápido.",
    WhatsAppXiaomi: "<strong>Xiaomi:</strong> vaya a Configuración > Aplicaciones > Administrar aplicaciones> busque el WhatsApp Service > habilite Inicio automático, luego desplácese hacia abajo y busque Ahorro de batería > Sin restricciones.",
    WhatsAppVivo: "<strong>Vivo:</strong> vaya a Configuración> Batería > Alto consumo de energía de fondo > busque el WhatsApp Service > apáguelo.",
    WhatsAppOthers: "<strong>Otros:</strong> vaya a Configuración > Aplicaciones > busque el WhatsApp Service > Configuración avanzada > Optimización de la batería > busque nuevamente el WhatsApp Service y desactívelo. ",
    WhatsAppHuawei1: "<strong>Huawei:</strong> Vaya a Configuración> busque WhatsApp Service en el Administrador de Tareas. Deslice hacia abajo la interfaz de la aplicación para bloquearla. Después de eso, toque la interfaz de la aplicación para regresar.",
    WhatsAppOppo1: "<strong>Oppo: </strong> Vaya a Configuración> busque WhatsApp Service en el Administrador de Tareas. Presiona el ícono de Configuración en la esquina superior derecha. Toque Bloquear. Después de eso, toque la interfaz de la aplicación para regresar.",
    WhatsAppXiaomi1: "<strong>Xiaomi:</strong> Vaya a Configuración> busque WhatsApp Service en el Administrador de Tareas. Mantenga presionada la pestaña de la aplicación y bloquéela. Después de eso, toque la interfaz de la aplicación Xiaomi: regresar.",
    WhatsAppStartMonite: 'Ahora haga clic en el botón Iniciar Monitoreo y el icono de la aplicación pronto desaparecerá. Para Android 10 y superior, el ícono de la aplicación será reemplazado por un ícono de "WLAN". Es mejor ocultar este icono en una carpeta no visible. ',
    WhatsAppVerify: "Verifique su configuración después de completar la instalación",
    MoniteSupportTeam: 'Si todo está configurado correctamente, haga clic en el botón "Verificar configuración" a continuación. Esto vinculará el dispositivo de destino a su cuenta en línea. La página actual saltará al panel web, donde puede verificar todos los archivos de. Si no puede instalar la aplicación correctamente, comuníquese con el equipo de soporte.',
    DataWarn: "Advertencia de uso de datos",
    DataTerm: "La carga de archivos grandes (fotos, videos, audio y documentos) a través de datos móviles puede generar cargos adicionales de red en su teléfono objetivo y exponer el hecho de que está rastreando WhatsApp. ¿Está seguro de que aún desea cargar archivos multimedia a través de datos móviles?",
    DataTermNote: "<strong>Nota:</strong> el historial de chat, el estado, las llamadas y las actividades de WhatsApp no están sujetas a esta condición. Por defecto, estos archivos se cargan a través de datos móviles .",
    ScreenTime: "Tiempo de pantalla de WhatsApp (últimos 7 días)",
    WhatsAppChats: "Chats de WhatsApp",
    WhatsAppCalls: "Llamadas de WhatsApp",
    Calls: "Llamadas",
    CallRecording: "Grabacion de llamada",
    WhatsAppStatus: "Estados de WhatsApp",
    WhatsAppPhotos: "Fotos de WhatsApp",
    WhatsAppVideos: "Videos de WhatsApp",
    WhatsAppVoices: "Voces de WhatsApp",
    Voices: "Voces",
    WhatsAppDocuments: "Documentos",
    Documents: "Documentos",
    WhatsAppActivities: "Actividades",
    Activities: "Actividades",
    WhatsAppScreenshots: "Imágenes de WhatsApp",
    Screenshots: "Capturas de pantalla",
    uploadTle: "Los archivos grandes (fotos, videos, audios y documentos) se cargan a través de Wi-Fi de forma predeterminada. Puede cambiarlo en Mis productos> Configuraciones.",
    searchCallerName: "buscar nombre de la persona que llama",
    CallerName: "Nombre de la persona que llama",
    onlyCover: "* iSeeGuard para WhatsApp solo cargará portadas de video.",
    screenFeature: "Comience con la función de captura de pantalla automática",
    screenFeatureOne: "Esta característica capturará automáticamente capturas de pantalla de la pantalla de WhatsApp. Por lo tanto, solo se activa cuando el usuario objetivo está utilizando WhatsApp.",
    screenFeatureTwo: "Puede elegir cuándo cargar las capturas de pantalla. Al habilitar la Carga a través de Wi-Fi, las capturas de pantalla se capturan con mayor frecuencia y esto puede evitar cargos de red adicionales en su teléfono objetivo. El otro es todo lo contrario.",
    UploadWiFi: "Subir a través de Wi-Fi",
    MoreConsumption: "Más consumo de energía",
    fasterfrequency: "Frecuencia de captura de pantalla más rápida",
    nomoBileData: "Sin consumo de datos móviles.",
    UploadMobileDat: "Subir a través de datos móviles",
    LessPowerConsumption: "Menos consumo de energía",
    lowerFrequencyScreenshots: "Baja frecuencia de captura de pantalla",
    usemobiledata: "Usar más datos móviles",
    SuccessfullySet: "¡Configurado con éxito!",
    SetSucess: "Ha configurado la función de captura de pantalla automática con éxito. Las capturas de pantalla comenzarán a cargarse cuando el usuario objetivo esté usando WhatsApp y haya una conexión de red. Por favor sea paciente.",
    wifi: "Wifi",
    wifiText: "Las capturas de pantalla de WhatsApp se cargarán a través de la red Wi-Fi.",
    MobileData: "Datos móviles",
    MobileDataText: "Las capturas de pantalla de WhatsApp se cargarán con datos móviles.",
    WhatsAppDownload: "Los archivos multimedia se pueden descargar directamente en cada función.",
    tryagain: "Se excedió el tiempo de espera de la solicitud. Por favor, inténtelo de nuevo más tarde.",
    beingUpload: "Los datos se están cargando. Es posible que tarde un tiempo en mostrarlo completamente. Actualice la página más tarde.",
    Chat: "Chats de WhatsApp",
    CallRecorde: "Grabaciones de llamadas",
    Play: "Jugar",
    AudioCall: "Llamada de audio",
    VideoCall: "Videollamada",
    startTle: "Comience con la función de grabación de llamadas de WhatsApp",
    startTleOne: "<strong>1.</strong> Esta función aprovecha el micrófono para grabar llamadas de voz/video de WhatsApp, por lo que es posible que no escuche la voz del otro lado a menos que el altavoz esté habilitado.",
    startTleTwo: "<strong>2.</strong> Por defecto, los archivos de audio se cargarán automáticamente a través de Wi-Fi. Esto es para evitar cargos adicionales en los datos móviles y evitar que te descubran. Puede cambiar esta configuración en Mis productos - Configuración> Carga de datos.",
    startTleThree: "<strong>3.</strong> Esta función puede grabar hasta 20 minutos a la vez, por lo que si la duración de la llamada supera los 20 minutos, se dividirá en varios fragmentos pequeños para cargar.",
    Thumbnail: "Miniatura",
    UpdateOverWifi: "Actualización a través de Wi-Fi",
    wifiUpload: "Tenga en cuenta que los archivos grandes (fotos, videos, audio y documentos) se cargarán cuando el teléfono objetivo esté utilizando la red Wi-Fi.",
    wifiNote: "<strong>Nota:</strong> El historial de chat, el estado, las llamadas y las actividades de WhatsApp no están sujetas a esta condición. Por defecto, estos archivos se cargan a través de datos móviles y Wi-Fi.",
    ChatContact: "Contacto de chat",
    Content: "Contenido",
    State: "Estado",
    screenTime: "Tiempo de pantalla de WhatsApp (últimos 7 días)",
    whatAppPathOne: "Vaya a Configuración > Accesibilidad > WhatsApp Service > asegúrese de que esté activado",
    whatAppPathTwo: "Vaya a Configuración > buscar Seguridad > Otras configuraciones de seguridad > Administrador de dispositivos > WhatsApp Service > asegúrese de que esté activado",
    whatAppPathThree: "Vaya a Configuración > Aplicaciones > busque el WhatsApp Service > Permisos > Almacenamiento > asegúrese de que esté activado",
    whatAppPathFour: "Vaya a Configuración > Aplicaciones > busque el WhatsApp Service > Notificaciones > asegúrese de que esté activado",
    whatAppPathFive: "Vaya a Configuración > buscar Seguridad > Otras configuraciones de seguridad > Acceso a datos de uso > WhatsApp Service > asegúrese de que esté activado",
    whatAppPathSix: "Vaya a Configuración > Aplicaciones > busque el WhatsApp Service > Permisos > Micrófono > asegúrese de que esté activado",
    whatsAppSetupError: "* Lo sentimos, la verificación falló. Asegúrese de haber instalado correctamente la aplicación iSeeGuard para WhatsApp en el teléfono de destino.",
    monitorReal: "Le permite vigilar el WhatsApp de alguien como si fuera real.",
    mustFinshSet: "* No ha terminado la configuración. Elija en bajo de Wi-Fi o datos móviles para subir las capturas de pantalla.",
    yourEmail: "Su correo electrónico:",
    whatsAppDelete: "Para proteger su privacidad personal, iSeeGuard dejará de monitorizar y eliminará todos los datos.",
    Store: "Tienda",
    monitorAndroidDevice: "Monitorear dispositivos Android",
    androidMoniteName: "iSeeGuard Pro - Monitoreo de Android",
    whatsAppMoniteName: "iSeeGuard - Monitoreo de WhatsApp",
    monitoriOSDevice: "Monitorear dispositivos iOS",
    iPhoneMoniteName: "iSeeGuard Pro - Monitoreo de iPhone",
    iCloudMoniteName: "iSeeGuard Pro - Monitoreo de iCloud",
    cancelNote: "<strong>Nota:</strong> Al cancelar la renovación automática, su cuenta permanecerá activa hasta la fecha de vencimiento. Si desea seguir utilizando nuestro servicio, debe renovarlo manualmente en ese momento.",
    whatsAppSync: "No se pueden sincronizar datos temporalmente. Los datos comenzarán a sincronizarse después de que el teléfono de destino se encienda con conexión a Internet y la aplicación iSeeGuard para WhatsApp está funcionando en el dispositivo de destino.",
    SyncErrWhy: "¿Por qué iSeeGuard para WhatsApp no puede sincronizar los datos temporalmente?",
    FirstReason: "En primer lugar, iSeeGuard para WhatsApp sincroniza nuevos datos cada 15 minutos automáticamente. En segundo lugar, puedes sincronizar los datos haciendo clic en el botón Sync de cada función. El tiempo que se tarda en sincronizar los datos depende del tamaño de los mismos y de la velocidad de conexión a Internet del dispositivo de destino.",
    ByDefualt: "De forma predeterminada, los archivos grandes (fotos, vídeos, audios y documentos) se cargarán cuando el teléfono de destino utilice la red Wi-Fi. El historial de mensajes de chat, el estado del texto, las llamadas y las actividades de WhatsApp no están sujetos a esta condición. Puedes cambiarla en Mis productos > Ajustes.",
    ReasonExample: "Si no puede ver los datos actualizados, es posible que la razón sea la siguiente.",
    NonProductReasons: "Razones ajenas al producto:",
    NonProductReasons1: "El dispositivo de destino está apagado.",
    NonProductReasons2: "El dispositivo de destino no está conectado a la red o la red es inestable.",
    NonProductReasons3: "WhatsApp se ha desinstalado en el dispositivo de destino.",
    NonProductReasons4: "WhatsApp no se está utilizando en el dispositivo de destino.",
    NonProductReasons5: "    La fecha y la hora del dispositivo de destino no coinciden con la hora local. Ve a Configuración > Gestión general > Fecha y hora > habilitar Fecha y hora automáticas.",
    ProductReasons: "Razones del producto:",
    ProductReasons1: "La aplicación iSeeGuard para WhatsApp está inactiva o se ha desinstalado en el dispositivo de destino. Si es así, reinicie el dispositivo de destino o vuelva a instalar la aplicación.",
    ProductReasons2: "Algunos permisos de iSeeGuard para WhatsApp están desactivados en el dispositivo de destino, especialmente para Accesibilidad y Almacenamiento. Por favor, vaya a Comprobación de permisos y siga las directrices para operar manualmente.",
    SelectAll: "Seleccionar",
    DeleteFiles: "Borrar archivos",
    DeleteConfirm: "¿Estás seguro de que quieres borrar los archivos?",
    DeleteSuccess: "¡Eliminado con éxito!",
    DownloadFiles: "Descargar archivos",
    DownloadConfirm: "¿Estás seguro de que quieres descargar los archivos?",
    DownloadTips: "<strong>Nota:</strong> Los archivos descargados serán eliminados del tablero.",
    DownloadWaiting: "Puede tardar unos minutos en terminar, dependiendo del tamaño de los archivos.",
    DownloadSuccess: "¡Descarga exitosa!",
    DownloadSuccessTips: "Puedes encontrar los archivos descargados en el Historial de Descargas.",
    DownloadFailed: "No se pudo descargar. Por favor, vuelva a descargar en una red estable.",
    DeleteFailed: "No se ha podido eliminar. Por favor, inténtalo de nuevo.",
    Processing: "Procesando...",
    ToppingTips: "haz clic para marcar esta conversación",
    MonStepFollowTitle: "Siga los pasos a continuación para activar su producto",
    MonStepImportantTitle: "Información importante:",
    MonStepTitle1: "Descargue el programa en el ordenador de destino",
    MonStepTitle2: "Instale el software y termine la configuración",
    MonStepTitle3: "Instale la extensión de Chrome (Opcional)",
    MonStepTitle4: "Vaya al panel de control en línea para monitorizar ahora",
    MonStepImportant1: "<b>1.</b> Necesita acceso físico al ordenador de destino.",
    MonStepImportant2: "<b>2.</b> No utilice este producto con fines ilegales.",
    MonStepImportant3: "<b>3.</b> MoniVisor tiene dos partes: El software de escritorio y el panel de control en línea.",
    MonStepImportant4: "<b>4.</b> Recomendamos encarecidamente que añada el software de escritorio a la lista autorizada de cualquier antivirus o software de seguridad para evitar que el programa sea eliminado o que sus funcionalidades no funcionen correctamente.",
    MonStepImportant5: "<b>5.</b> Se recomienda que use el modo de navegación privada para descargar esta herramienta o borrar el historial de navegación para que evitaría dejar cualquier rastro de la instalación.",
    MonStep1Desc: 'MoniVisor para Win funciona a través de un software oculto basado en un ordenador y un panel de control en línea. Para empezar, necesita acceder físicamente al ordenador de destino y pegar la URL ( <b style="color: #347aeb">http://www.installfree.net</b> ) en cualquier navegador para descargar el software.',
    MonStep2Desc: "A continuación, instale el software y termine la configuración siguiendo la guía en pantalla.",
    MonStep2Note: '<b>Nota:</b> Puede que el Control de Acceso de Usuarios de Windows o SmartScreen le solicite la aprobación para ejecutar el software. Si se le solicita, haga clic en Sí, Ejecutar o Ejecutar de todos modos. Si la opción de seleccionar Sí o Ejecutar no está disponible, haga clic en "más información" para mostrar la opción.',
    MonStep2li_1: "Llevará algún tiempo para instalar el software.",
    MonStep2li_2: "Active con la cuenta y la contraseña de su licencia.",
    MonStep2li_3: "Si el programa detecta un anti-virus en el software, por favor siga las instrucciones dadas para añadir MoniVisor para Win a la lista autorizada. Después de terminar, encontrará que la herramienta pasa al modo de ejecución oculto automáticamente.",
    MonStep3Desc: "Si el navegador predeterminado en el ordenador de destino es Chrome, necesitará una extensión para monitorizar el correo web (como Gmail, Outlook o Yahoo Mail) y los mensajes de chat web (como WhatsApp, Facebook, Twitter, Instagram o Skype). Le recomendamos que instale la extensión para aumentar las capacidades de monitorización del software. Para ello, por favor, siga la guía que aparece a continuación.",
    MonStep3li_1: "Abra el navegador Chrome.",
    MonStep3li_2: "Haga clic en la opción de 3 puntos en la parte superior derecha del navegador - <b>Más herramientas</b> - <b>Extensiones</b>.",
    MonStep3li_3: "Active modo de desarrollador haciendo clic en el botón deslizante de la parte superior derecha del navegador.",
    MonStep3li_4: 'El botón Cargar descomprimida" se mostrará en la barra de menú.',
    MonStep3li_5: "Haga clic en el botón <b>'Cargar descomprimida'</b> y vaya a <b style=\"color: #347aeb;\">C:\\Program Files (x86)\\Windows Assistant App\\MV\\extension</b> y seleccione la carpeta<b>'moni-chrome-extension'</b>.\n",
    MonStep3li_6: "La <b>'Extension'</b> está ahora instalada en su ordenador.",
    MonStep3li_7: 'Haga click con el botón derecho del ratón sobre el círculo del espacio vacío y seleccione Ocultar en el menú de Chrome\' o "Quitar" para ocultar la extensión completamente.',
    MonStep3li_8: "Si la extensión se instala con éxito, la verá en la lista de extensiones.\n",
    MonStep4Desc1: "Ahora, la configuración necesaria en el ordenador de destino ha sido completada. Entonces encontrará que la herramienta pasa automáticamente a modo de ejecución oculta y trabajará continuamente en segundo plano.",
    MonStep4Desc2: "Puede ir al sitio web de iSeeGuard para acceder a su cuenta con cualquier navegador (en su propio ordenador, tabletas o teléfono móvil). O haga clic en el botón Verificar Configuración abajo para vincular rápidamente su cuenta, y la página actual saltará al panel web, donde podrá comprobar todos los datos.  Si no puede instalar la aplicación con éxito, póngase en contacto con el equipo de soporte.",
    MonStepError: "* Lo sentimos, la verificación falló. Por favor, asegúrese de que el software asistente de MoniVisor ha sido instalado en el ordenador de destino con éxito.",
    MonVerifySetup: "Verificar configuración",
    MonUninstallBigTitle: "Desinstalación para el software de escritorio MoniVisor",
    MonUninstallTitle: "Le proporcionamos 2 formas de desinstalar el software en el ordenador de destino. Desinstalar remotamente haciendo clic en el botón de abajo o acceder físicamente al ordenador de destino para la desinstalación manual.",
    MonUninstallNote: "Notas:",
    MonUninstallNote1: "1. La función de desinstalación remota sólo está disponible cuando el dispositivo de destino está en línea.",
    MonUninstallNote2: "2. La desinstalación del software no desbloqueará su cuenta, y no borrará los datos monitorizados. Sin embargo, no recibirá nuevos datos. Si desea desvincular la cuenta, puede ir a la página de configuración y hacer clic en el botón Desvincular.",
    MonUninstallMethod1: "Método 1: Desinstalación remota con un solo clic.",
    MonUninstallMethod1Desc: "Solo tiene que hacer clic en el botón de abajo, y el software del ordenador ejecutará el comando automáticamente.",
    MonUninstallMethod2: "Método 2. Desinstalarlo manualmente.",
    MonUninstallMethod2Desc: 'La aplicación esta instalada en la ruta: <b style="color: #347aeb;">C:\\Program Files (x86)\\Windows Assistant App\\MV</b>',
    MonUninstallTheStep: "Los pasos son:",
    MonUninstallTheStep1: "Paso 1: Vaya a la computadora de destino y escriba la ruta de instalación completa anterior para localizar el archivo.",
    MonUninstallTheStep2: 'Paso 2: Luego haga clic en el archivo <b style="color: #347aeb;">unins000.exe</b> para ejecutar el comando de desinstalación.',
    MonUninstallTheStep3: "Paso 3: No se necesita hacer nada más, sólo reiniciar el ordenador y entonces podrá desinstalar el software con éxito.",
    MonUninstallError: "Lo sentimos, el ordenador de destino está desconectado ahora. Por favor, compruebe el estado del dispositivo en su Panel o en la página de Configuración e inténtelo de nuevo cuando el ordenador de destino esté conectado",
    MonUninstallTip: "Esto no desvinculará su cuenta, pero ya no recibirá ningún dato nuevo.",
    MonUninstallProblem: "¿Está seguro de que quiere desinstalar el software en el ordenador de destino?",
    MonUninstallU: "Desinstalar",
    MonUninstallSuccess: "¡Felicidades! Ha desinstalado con éxito el software!",
    MonUnbundlingProblem: "¿Está seguro de que quiere desvincular el dispositivo?",
    MonUnbundlingTip: "Por motivos de seguridad, todos los datos serán eliminados",
    MonUnbundlingSuccess: "¡Felicidades! Ha desvinculado con éxito el software!",
    MonInstallationHelp: "Documento de ayuda a la instalación",
    MonInstallationDesc: "Aquí está su guía sobre cómo añadir la carpeta de software y los archivosa la Lista blanca, lista de confianza, o lista de exclusión de archivos y carpetas del antivirus para evitar que el software sea eliminado en el ordenador de destino. También se incluye la guía para instalar la Extensión de Chrome MoniVisor. Por favor, lea atentamente los siguientes pasos.",
    MonInstallationHowTo1: "Cómo añadir a la lista autorizada del software antivirus",
    MonInstallationHowTo2: "Cómo instalar la Extensión de Chrome de MoniVisor",
    MonInstallationHowTo3: "Guía sobre cómo añadir a la lista autorizada de software antivirus",
    MonInstallationStepTitle: "Las instrucciones específicas pueden diferir para una variedad de paquetes de antivirus, aunque el proceso básico es el mismo. Aquí están los pasos para hacerlo:",
    MonInstallationStep1: '<span>Paso 1:</span> Tenga claro dónde está instalado MoniVisor: <b style="color: #347aeb;">C:\\Program Files (x86)\\Windows Assistant App</b>',
    MonInstallationStep1Desc: 'El nombre del archivo es: <b style="color: #347aeb;">Setupsvc.exe</b>',
    MonInstallationStep2: "<span>Paso 2:</span> Por favor, tenga en cuenta qué software antivirus está instalado en el equipo de destino y compruebe las instrucciones oficiales de abajo para configurar la lista de excepciones en los paquetes de antivirus específicos.",
    MonInstallationStep2Desc: "Guía sobre cómo instalar la extensión de Chrome de MoniVisor",
    MonWebChatsOfflineTip: "El ordenador de destino está ahora offline. No se actualizará ningún dato hasta que esté en línea.",
    MonWebChatsOfflineTipDesc: "¿Qué es offline? Esto puede suceder cuando el dispositivo de destino está desconectado de Internet, el ordenador está apagado, el software ha sido desinstalado del ordenador de destino, o el software ha sido eliminado por razones desconocidas.",
    MonWebChatsNote1: 'MonWebChatsNote1: \'Nota: compruebe que el equipo de destino esté conectado y que la extensión de Google Chrome se haya instalado correctamente. Haga clic <a style="color:#347aeb" href="/monivisor-installation-guide-win" target="_blank">aquí</a> para ver la guía completa para instalar la extensión\',',
    MonScreenClickTitle: "Comienza con la Función de Captura de Pantalla de MoniVisor",
    MonScreenClickDesc: "Haga clic en Comenzar para tomar capturas de pantalla del ordenador objetivo.<br/> Las capturas de pantalla se harán cada 15 segundos durante 5 minutos.",
    MonScreenClickNote: "Notas:",
    MonScreenClickNote1: "1. Esta funcionalidad sólo puede ser usada cuando el ordenador objetivo está en línea.",
    MonScreenClickNote2: "2. Puede detener las capturas de pantalla en los cinco minutos siguientes al inicio. Si no la detiene manualmente, se detendrá automáticamente después de cinco minutos y luego podrá comenzar de nuevo.",
    MonScreenClickError1: "El ordenador objetivo está offline, por favor compruebe el estado del dispositivo y luego pruebe esta función cuando esté online.",
    MonScreenClickError2: "¿Está seguro de que quiere borrar las capturas de pantalla?",
    MonScreenSelectAll: "Seleccionar Todo",
    MonScreenDeleting: "Eliminando…",
    MonScreenDeleteFiles: "Eliminar Archivos",
    MonScreenSuccess1: "¡Exito! Ha borrado ",
    MonScreenSuccess2: " capturas de pantalla!",
    MonFileAction: "Acción",
    MonFileFileName: "Nombre del Archivo",
    MonFileDate: "Fecha",
    MonLoginUserName: "Nombre de usuario",
    MonLoginActivityTh: "Actividad",
    MonLoginPrinterName: "Nombre de la impresora",
    MonLoginFileName: "Nombre del Archivo",
    MonLoginPages: "Páginas",
    MonExportDataSetting: "Configuración de datos:",
    MonExportDeleteExportedData: "Borrar datos exportados",
    MonExportDesc1: "1. Los archivos de medios en el historial de los chats de la web sólo pueden ser exportados en forma de texto.",
    MonExportDesc2: "2. Las capturas de pantalla pueden ser descargadas en bloque en su página de resultados.",
    MonPurchaseBuyWin: "Elija su plan para MoniVisor para Windows",
    MonPurchaseGuideType2: "Software invisible de monitorización de ordenadores de Windows.",
    MonMyProductPageDesc1: "Una herramienta de monitorización para comprobar remotamente todos los archivos en un ordenador con Windows.",
    MonMyProductPageDesc2: "Modo de funcionamiento indetectable y panel de control online para ver los datos en cualquier lugar y en cualquier momento.",
    MonDashboardName: "Nombre",
    MonDashboardComputerID: "ID del Equipo",
    MonDashboardLatestEmails: "Últimos Emails",
    MonDashboardMore: "Más",
    MonDashboardLatestBrowsingHistories: "Últimas Historias de Navegación",
    MonDashboardSender: "Remitente",
    MonDashboardSubject: "Asunto",
    MonDashboardDate: "Fecha",
    MonDashboardDomain: "Dominio",
    MonDashboardTitle: "Título",
    MonSettingsUninstallGuide: "Guía de Desinstalación",
    MonSettingsHelpDocument: "Documento de Ayuda a la Instalación",
    MonSettingsSoftwareVersion: "Versión de software en el ordenador de destino",
    MonSettingsWhat: "Novedades",
    MonRequestLong: "Estamos tratando su petición. Por favor, espere un minuto e inténtelo de nuevo.",
    MonUnknown: "Desconocido",
    MoniVisor: "MoniVisor",
    MoniVisorWebActivity: "Actividad en la web",
    MoniVisorActivityChrome: "Chrome",
    MoniVisorActivityFirefox: "FireFox",
    MoniVisorActivityEdge: "Microsoft Edge",
    MoniVisorActivityIE: "Internet Explorer",
    MoniVisorActivityOpera: "Opera",
    MoniVisorDownloadHistory: "Descargar Historia",
    MoniVisorWebChats: "Chats de la Web",
    MonAppActivity: "Actividad de la aplicación",
    MonKeylogger: "Keylogger",
    MonScreenshot: "Hacer capturas de pantalla",
    MonScreenshotSet: "Configuración de la pantalla",
    MonScreenshotView: "Ver capturas de pantalla",
    MonLoginActivity: "Actividad de inicio de sesión",
    MonPrintActivity: "Imprimir actividad",
    MonUsbConnection: "Conexión USB",
    MonDataExport: "Exportación de datos",
    MonComputer: "Monitor de computadora",
    MonFileActivity: "Actividad de archivo",
    MonRemaining: "Tiempo restante:",
    MonScreenIngError: "La tarea de captura de pantalla ya está en curso. Por favor, inténtelo de nuevo más tarde.",
    MonDataExportOnly: "Solo se pueden exportar 2000 registros a la vez.",
    MonSomeTime: "Es posible que la sincronización de los datos tarde un poco. Por favor sea paciente...",
    MonLastUsed: "Usado por última vez…",
    MonFilePath: "Ruta de archivo",
    MonTimeUsage: "Tiempo de Uso",
    MonReceived: "Recibido",
    MonSent: "Enviado",
    MoniVisorWebChatsWhatsApp: "WhatsApp",
    MoniVisorWebChatsFacebook: "Facebook",
    MoniVisorWebChatsTwitter: "Twitter",
    MoniVisorWebChatsInstagram: "Instagram",
    MoniVisorWebChatsSkype: "Skype",
    MoniVisorWebChatsPinterest: "Pinterest",
    MonWebMail: "Web Mail",
    MonWebMailGmail: "Gmail",
    MonWebMailOutlook: "Outlook",
    MonWebMailYahoo: "Yahoo",
    MonDataExportWebActivityChrome: "Actividad en la web - Chrome",
    MonDataExportWebActivityIE: "Actividad en la web - IE",
    MonDataExportWebActivityEdge: "Actividad en la web - Edge",
    MonDataExportWebActivityOpera: "Actividad en la web - Opera",
    MonDataExportWebActivityFirefox: "Actividad en la web - Firefox",
    MonDataExportDownloadHistoryChrome: "Descargar Historia - Chrome",
    MonDataExportDownloadHistoryIE: "Descargar Historia - IE",
    MonDataExportDownloadHistoryEdge: "Descargar Historia - Edge",
    MonDataExportDownloadHistoryOpera: "Descargar Historia - Opera",
    MonDataExportDownloadHistoryFirefox: "Descargar Historia - Firefox",
    MonScreenStop: "Detener",
    MonScreenMaxDownTip: "* Se pueden descargar un máximo de 50 imágenes a la vez y los datos descargados se eliminarán.",
    MonWebMailAttachments: "Ruta del archivo adjunto",
    MonWebMailFrom: "De",
    MonKeyloggerTip: 'Nota: Verifique que la computadora de destino esté en línea y que el software no haya sido eliminado por un antivirus. Haga clic <a style="color:#347aeb" href="/monivisor-installation-guide-win" target="_blank">aquí</a> para obtener la guía completa para agregar el software a la lista blanca del antivirus.',
    MonInsert: "Insertar",
    MonEject: "Expulsar",
    MonLogon: "Iniciar sesión",
    MonLogoff: "Desconectarse",
    MonCreate: "Crear",
    MonCopy: "Copiar",
    MonDelete: "Eliminar",
    MonRename: "Rebautizar",
    MonImage: "Imagen",
    MonVideo: "Vídeo",
    MonFile: "Expediente",
    MonLocation: "Ubicación",
    MonPasteNote: "<b>Nota:</b> Debe pegar la ruta completa del archivo de instalación o permitir temporalmente la visualización de carpetas ocultas en el Explorador de archivos de Windows, para seleccionar manualmente las carpetas dentro de la aplicación antivirus. Agregue la carpeta MV en la ruta de instalación o el nombre de archivo completo proporcionado a la lista blanca, según los requisitos de los diferentes programas antivirus.",
    kdsMoniVisor: "MoniVisor para Win",
    MonMisseVoice: "Llamada perdida",
    MonMisseVideo: "Llamada perdida",
    MonContact: "Contactos",
    MonMonitorComputers: "Monitorear computadoras",
    MonWindowsMonitoring: "MoniVisor - Monitoreo de Windows",
    ParamError: "Error de parametro",
    TokenError: "Ingreso invalido",
    UserVIPExpired: "La membresía del usuario caducó o no existe",
    NotfoundHttp: "La dirección solicitada no existe",
    ServerError: "Error del Servidor",
    HeadersMiss: "Falta el parámetro de encabezados",
    MonitorNow: "Supervisar ahora",
    AboutUs: "Sobre Nosotros",
    PrivacyPolicy: "Política de privacidad",
    TermsConditions: "Términos &  condiciones",
    Disclaimer: "Descargo de responsabilidad",
    DisclaimerDesc: "EL SOFTWARE DE ISEEGUARD ESTÁ DISEÑADO PARA USO LEGAL ÚNICAMENTE.<br />La instalación de este software con licencia en un dispositivo para el que no tiene derechos de supervisión puede violar las leyes de su país o región. Usted es totalmente responsable de descargarlo, instalarlo y usarlo. Si elige un dispositivo de monitoreo sin permiso, iSeeGuard no se hará responsable. Todos los derechos no otorgados expresamente en este documento se reservarán a iSeeGuard.",
    Refuse: "Negar",
    DialOut: "Llamar",
    IncomingCall: "Entrante",
    RenewDialogTips: "La demostración no se puede seguir utilizando. Puede registrarse para obtener una cuenta y comprar un plan para disfrutar de todas las funciones avanzadas de monitoreo.",
    Permanent: "Eterno",
    Capacity: "Capacidad",
    Clipboard: "Portapapeles",
    SensitiveWord: "Palabras Sensibles",
    AppUsed: "Aplicación usada",
    AppUsedToday: "Aplicación usada hoy",
    Add: "Agregar",
    Modify: "Modificar",
    Operate: "Funcionar",
    MacStepTitle2: "Instale el software",
    MacStepTitle3: "termine la configuración",
    MacStepTitle5: "Finalizar la instalación",
    MacStepOneDesc: "Abra <strong> www.iseeguard.com </strong> con un navegador en el dispositivo de destino y descargue la aplicación iSeeGuard Pro para Mac.",
    MacStepTwoDesc: "<strong> Nota: </strong> se recomienda que lo descargue directamente en la computadora de la persona que desea monitorear. Si puede acceder físicamente al MAC de destino de vez en cuando, también puede descargarlo y monitorearlo en su propia computadora.",
    MacStepThreeDesc: "Después de la descarga, haga clic en el archivo pkg para iniciar el proceso de instalación.",
    MacStepFourDesc: "Seleccione cuál necesita monitorear, haga clic en Siguiente para continuar.",
    MacStepFiveDesc: "Mactrack ha sido instalado.",
    MacStepSixDesc: "Ingrese a su cuenta.",
    ClickHere: "haga clic aquí",
    IosStepOneDesc: "Abra <strong> panel.iseeguard.com/store/ios </strong> con un navegador en el dispositivo de destino y descargue la aplicación iSeeGuard Pro para iOS.",
    IosStepTwoDesc: "<strong> Nota: </strong> se recomienda que lo descargue directamente en la computadora de la persona que desea monitorear. Si puede acceder físicamente al iOS de destino de vez en cuando, también puede descargarlo y monitorearlo en su propia computadora.",
    IosStepTitle3: "Copia de seguridad de datos de iTune",
    IosStepThreeDesc: "Descargue el software de iTunes, conecte el teléfono móvil a monitorear al software de iTunes y realice una copia de seguridad de los datos.",
    IosStepTitle5: "Escanear datos desde el iPhone de destino",
    IosStepTitle6: "Analizando los datos",
    IosStepTitle7: "Comience a monitorear el iPhone de destino en la computadora",
    IosStepFiveDesc: "si hay archivos de respaldo disponibles en la computadora, ISeeGuard Pro mostrará el respaldo encontrado. Puede seleccionar el archivo de copia de seguridad del dispositivo de destino y escanear los datos de él.",
    IosStepSixDesc: "Haga clic en Siguiente y muestre la página de datos de análisis. El tiempo de espera para el primer análisis de datos será mayor.",
    IosStepSevenDesc: "Una vez completado el proceso de escaneo, toda la información escaneada se mostrará en el tablero y podrá cambiar entre diferentes tipos de archivos y ver detalles. Además, puede exportar todos los datos a la computadora.",
}
