import { login, register } from "@/api/users"
import { setToken, getToken, removeToken } from "@/utils/auth"
import aes from "@/utils/aes"


const REGISTER_EMAIL = aes.Encrypt('REGISTER_EMAIL');
const REGISTER_SOURCE = aes.Encrypt('REGISTER_SOURCE');

const token = getToken();

function local(key, defaultValue) {
    return localStorage.getItem(key) ? aes.Decrypt(localStorage.getItem(key)) : defaultValue
}

const getDefaultState = () => {
    return {
        // 用户信息
        userInfo: {},
        // 是否禁用登录
        isDisableLogin: false,
        // 是否禁用注册
        isDisableRegister: false,
        // 登录状态
        loginStatus: token ? true : false,
        // 用户token
        user_token: '',
        // 用户权限
        roles: [],
        // 购买注册流程的需要的数据
        registerData: {
            email: local(REGISTER_EMAIL, ''),
            source: local(REGISTER_SOURCE, 'android'),
        },
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_USERINFO: (state, userInfo) => {
        state.userInfo = userInfo
    },
    SET_TOKEN: (state, token) => {
        state.user_token = token
    },
    SET_IS_DISABLE_LOGIN: (state, data) => {
        state.isDisableLogin = data
    },
    SET_IS_DISABLE_REGISTER: (state, data) => {
        state.isDisableRegister = data
    },
    SET_LOGIN_STATUS: (state, data) => {
        state.loginStatus = data
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
    SET_REGISTER_EMAIL: (state, data) => {
        state.registerData.email = data
    },
    SET_REGISTER_SOURCE: (state, data) => {
        state.registerData.email = data
    },
}

const actions = {
    set_register_source({ commit }, data) {
        return new Promise((resolve) => {
            let _dd = data ? data : 'android';
            let d = aes.Encrypt(_dd);
            localStorage.setItem(REGISTER_SOURCE, d)
            commit('SET_REGISTER_SOURCE', d)
            resolve();
        })
    },
    set_register_email({ commit }, data) {
        return new Promise((resolve) => {
            let d = aes.Encrypt(data);
            localStorage.setItem(REGISTER_EMAIL, d)
            commit('SET_REGISTER_EMAIL', d)
            resolve();
        })
    },
    login({ commit, state }, data) {
        const { email, password } = data
        return new Promise((resolve, reject) => {
            if (!state.isDisableLogin) {
                commit('SET_IS_DISABLE_LOGIN', true)
                login({ email: email.trim(), password: password }).then(response => {
                    const { data } = response
                    commit('SET_TOKEN', data.token)
                    commit('SET_USERINFO', data.token)
                    commit('SET_LOGIN_STATUS', true)
                    setToken(data.token)
                    resolve()
                }).catch(error => {
                    commit('SET_IS_DISABLE_LOGIN', false)
                    reject(error)
                })
            }
        })
    },
    // 动态路由，已弃用
    getUserInfo({ commit, state }) {
        return new Promise((resolve) => {
            let roles = []
            if (state.loginStatus) {
                roles = ['admin']
            } else {
                roles = []
            }
            commit('SET_ROLES', roles)
            resolve(roles)
        })
    },
    set_is_disable_login({ commit }, data) {
        commit('SET_IS_DISABLE_LOGIN', data)
    },
    register({ commit, state }, data) {
        return new Promise((resolve, reject) => {
            if (!state.isDisableRegister) {
                commit('SET_IS_DISABLE_REGISTER', true)
                const params = {
                    email: data.email,
                    password: data.password,
                    password_confirmation: data.passwordConfirmation
                }
                register(params).then(() => {
                    commit('SET_IS_DISABLE_REGISTER', false)
                    resolve()
                }).catch(err => {
                    commit('SET_IS_DISABLE_REGISTER', false)
                    reject(err)
                })

            }
        })
    },
    login_out({ commit }) {
        return new Promise((resolve, reject) => {
            try {
                removeToken();
                commit('SET_USERINFO', {});
                commit('SET_TOKEN', '');
                commit('SET_LOGIN_STATUS', false);
                commit('SET_IS_DISABLE_LOGIN', false);
                commit('SET_IS_DISABLE_REGISTER', false)
                commit('SET_ROLES', [])
                resolve()
            } catch (error) {
                reject(error)
            }
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}