 /**
  * 将秒转化为 时/分/秒
  * @param {*} value 
  * @returns 
  */
 export function formatSeconds(value) {
    var theTime = parseInt(value);// 秒
    var middle = 0;// 分
    var hour = 0;// 小时

    if (theTime > 60) {
        middle = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (middle > 60) {
            hour = parseInt(middle / 60);
            middle = parseInt(middle % 60);
        }
    }
    var result = "" + parseInt(theTime);
    if (middle > 0) {
        result = "" + parseInt(middle) + ":" + result;
    }
    if (hour > 0) {
        result = "" + parseInt(hour) + ":" + result;
    }
    return result;
}
/**
 * 获取推广来源
 * @returns 
 */
export function getPromoteSource() {
    let promote_source = window.localStorage.getItem('PROMOTE_SOURCE') || '';
    let promote_expires = window.localStorage.getItem('PROMOTE_EXPIRES') || 0;
    if(promote_source !== '' && promote_source !== undefined && promote_expires > Date.now()){
      return promote_source;
    }
    return '';
}
/**
 * 是否可刷新
 * @returns 
 */
 export function isReload() {
    let reload_expire = window.localStorage.getItem('RELOAD_EXPIRES') || 0;
    if(reload_expire < Date.now()){
      return true;
    }
    return false;
}
/**
 * 获取今日的时间
 * @returns 
 */
export function getTodayDate() {
    var toDay = new Date();
    toDay.setTime(toDay.getTime());
    return toDay.getFullYear()+"-" + (toDay.getMonth()+1) + "-" + toDay.getDate();
}
/**
 * 获取当前时间
 * @returns 
 */
export function getCurrentDate() {
    const d = new Date();
    let getFullYear = d.getFullYear()
    let getMonth = d.getMonth()+1
    let getDate = d.getDate()
    let getHours = d.getHours()
    let getMinutes = d.getMinutes() 
    let getSeconds = d.getSeconds()
    //补0
    if(getMonth<10){
        getMonth = "0"+getMonth
    }
    if(getDate<10){
        getDate = "0"+getDate
    }
    if(getHours<10){
        getHours = "0"+getHours
    }
    if(getMinutes<10){
        getMinutes = "0"+getMinutes
    }
    if(getSeconds<10){
        getSeconds = "0"+getSeconds
    }
    return `${getFullYear}-${getMonth}-${getDate} ${getHours}:${getMinutes}:${getSeconds}`;
}

export const DateTimeUtil = function () {
    /***     
     * 获得当前时间
     */
    this.getCurrentDate = function () {
        return new Date();
    };
    /***     
     * 获得本周起止时间     
     */
    this.getCurrentWeek = function () {
        //起止日期数组
        var startStop = new Array();
        //获取当前时间
        var currentDate = this.getCurrentDate();
        //返回date是一周中的某一天
        var week = currentDate.getDay();
        // //返回date是一个月中的某一天
        // var month = currentDate.getDate();
        //一天的毫秒数
        var millisecond = 1000 * 60 * 60 * 24;
        //减去的天数
        var minusDay = week != 0 ? week - 1 : 6;
        //alert(minusDay);
        //本周 周一
        var monday = new Date(currentDate.getTime() - (minusDay * millisecond));
        //本周 周日
        var sunday = new Date(monday.getTime() + (6 * millisecond));
        //添加本周时间
        startStop.push(monday); //本周起始时间
        //添加本周最后一天时间
        startStop.push(sunday); //本周终止时间
        //返回
        return startStop;
    };
    /***     
     * 获得本月的起止时间     
     */
    this.getCurrentMonth = function () {
        //起止日期数组
        var startStop = new Array();
        //获取当前时间
        var currentDate = this.getCurrentDate();
        //获得当前月份0-11
        var currentMonth = currentDate.getMonth();
        //获得当前年份4位年
        var currentYear = currentDate.getFullYear();
        //求出本月第一天
        var firstDay = new Date(currentYear, currentMonth, 1);
        //当为12月的时候年份需要加1
        //月份需要更新为0 也就是下一年的第一个月
        if (currentMonth == 11) {
            currentYear++;
            currentMonth = 0; //就为
        } else {
            //否则只是月份增加,以便求的下一月的第一天
            currentMonth++;
        }
        //一天的毫秒数
        var millisecond = 1000 * 60 * 60 * 24;
        //下月的第一天
        var nextMonthDayOne = new Date(currentYear, currentMonth, 1);
        //求出上月的最后一天
        var lastDay = new Date(nextMonthDayOne.getTime() - millisecond);
        //添加至数组中返回
        startStop.push(firstDay);
        startStop.push(lastDay);
        //返回
        return startStop;
    };
    /**     
     * 得到本季度开始的月份     
     * @param month 需要计算的月份
     */
    this.getQuarterSeasonStartMonth = function (month) {
        // var quarterMonthStart = 0;
        var spring = 0; //春
        var summer = 3; //夏
        var fall = 6; //秋
        var winter = 9; //冬
        //月份从0-11
        if (month < 3) {
            return spring;
        }
        if (month < 6) {
            return summer;
        }
        if (month < 9) {
            return fall;
        }
        return winter;
    };
    /**     
     * 获得该月的天数
     * @param year年份
     * @param month月份     
     */
    this.getMonthDays = function (year, month) {
        //本月第一天 1-31
        var relativeDate = new Date(year, month, 1);
        //获得当前月份0-11
        var relativeMonth = relativeDate.getMonth();
        //获得当前年份4位年
        var relativeYear = relativeDate.getFullYear();
        //当为12月的时候年份需要加1
        //月份需要更新为0 也就是下一年的第一个月
        if (relativeMonth == 11) {
            relativeYear++;
            relativeMonth = 0;
        } else {
            //否则只是月份增加,以便求的下一月的第一天
            relativeMonth++;
        }
        //一天的毫秒数
        var millisecond = 1000 * 60 * 60 * 24;
        //下月的第一天
        var nextMonthDayOne = new Date(relativeYear, relativeMonth, 1);
        //返回得到上月的最后一天,也就是本月总天数
        return new Date(nextMonthDayOne.getTime() - millisecond).getDate();
    };
    /**     
     * 获得本季度的起止日期     
     */
    this.getCurrentSeason = function () {
        //起止日期数组
        var startStop = new Array();
        //获取当前时间
        var currentDate = this.getCurrentDate();
        //获得当前月份0-11
        var currentMonth = currentDate.getMonth();
        //获得当前年份4位年
        var currentYear = currentDate.getFullYear();
        //获得本季度开始月份
        var quarterSeasonStartMonth = this.getQuarterSeasonStartMonth(currentMonth);
        //获得本季度结束月份
        var quarterSeasonEndMonth = quarterSeasonStartMonth + 2;
        //获得本季度开始的日期
        var quarterSeasonStartDate = new Date(currentYear, quarterSeasonStartMonth, 1);
        //获得本季度结束的日期
        var quarterSeasonEndDate = new Date(currentYear, quarterSeasonEndMonth, this.getMonthDays(currentYear, quarterSeasonEndMonth));
        //加入数组返回
        startStop.push(quarterSeasonStartDate);
        startStop.push(quarterSeasonEndDate);
        //返回
        return startStop;
    };
    /**
     * 得到本年的起止日期
     */
    this.getCurrentYear = function () {
        //起止日期数组
        var startStop = new Array();
        //获取当前时间
        var currentDate = this.getCurrentDate();
        //获得当前年份4位年
        var currentYear = currentDate.getFullYear();
        //本年第一天
        var currentYearFirstDate = new Date(currentYear, 0, 1);
        //本年最后一天
        var currentYearLastDate = new Date(currentYear, 11, 31);
        //添加至数组
        startStop.push(currentYearFirstDate);
        startStop.push(currentYearLastDate);
        //返回
        return startStop;
    };
    /**     
     * 返回上一个月的第一天Date类型     
     * @param year 年    
     * @param month 月     
     */
    this.getPriorMonthFirstDay = function (year, month) {
        //年份为0代表,是本年的第一月,所以不能减
        if (month == 0) {
            month = 11;
            //月份为上年的最后月份
            year--; //年份减1
            return new Date(year, month, 1);
        }
        //否则,只减去月份
        month--;
        return new Date(year, month, 1); 
    };
    /**     
     * 获得上一月的起止日期     
     */
    this.getPreviousMonth = function () {
        //起止日期数组
        var startStop = new Array();
        //获取当前时间
        var currentDate = this.getCurrentDate();
        //获得当前月份0-11
        var currentMonth = currentDate.getMonth();
        //获得当前年份4位年
        var currentYear = currentDate.getFullYear();
        //获得上一个月的第一天
        var priorMonthFirstDay = this.getPriorMonthFirstDay(currentYear, currentMonth);
        //获得上一月的最后一天
        var priorMonthLastDay = new Date(priorMonthFirstDay.getFullYear(), priorMonthFirstDay.getMonth(), this.getMonthDays(priorMonthFirstDay.getFullYear(), priorMonthFirstDay.getMonth()));
        //添加至数组
        startStop.push(priorMonthFirstDay);
        startStop.push(priorMonthLastDay);
        //返回
        return startStop;
    };
    /**     
     * 获得上一周的起止日期     
     */
    this.getPreviousWeek = function () {
        //起止日期数组
        var startStop = new Array();
        //获取当前时间
        var currentDate = this.getCurrentDate();
        //返回date是一周中的某一天
        var week = currentDate.getDay();
        //返回date是一个月中的某一天
        // var month = currentDate.getDate();
        //一天的毫秒数
        var millisecond = 1000 * 60 * 60 * 24;
        //减去的天数
        var minusDay = week != 0 ? week - 1 : 6;
        //获得当前周的第一天
        var currentWeekDayOne = new Date(currentDate.getTime() - (millisecond * minusDay));
        //上周最后一天即本周开始的前一天
        var priorWeekLastDay = new Date(currentWeekDayOne.getTime() - millisecond);
        //上周的第一天
        var priorWeekFirstDay = new Date(priorWeekLastDay.getTime() - (millisecond * 6));
        //添加至数组
        startStop.push(priorWeekFirstDay);
        startStop.push(priorWeekLastDay);
        return startStop;
    };
    /**     
     * 得到上季度的起始日期     
     * year 这个年应该是运算后得到的当前本季度的年份     
     * month 这个应该是运算后得到的当前季度的开始月份     
     */
    this.getPriorSeasonFirstDay = function (year, month) {
        // var quarterMonthStart = 0;
        var spring = 0; //春
        var summer = 3; //夏
        var fall = 6; //秋
        var winter = 9; //冬
        //月份从0-11
        switch (month) { //季度的其实月份
        case spring:
            //如果是第一季度则应该到去年的冬季
            year--;
            month = winter;
            break;
        case summer:
            month = spring;
            break;
        case fall:
            month = summer;
            break;
        case winter:
            month = fall;
            break;
        }
        return new Date(year, month, 1);
    };
    /**     
     * 得到上季度的起止日期     
     */
    this.getPreviousSeason = function () {
        //起止日期数组
        var startStop = new Array();
        //获取当前时间
        var currentDate = this.getCurrentDate();
        //获得当前月份0-11
        var currentMonth = currentDate.getMonth();
        //获得当前年份4位年
        var currentYear = currentDate.getFullYear();
        //上季度的第一天
        var priorSeasonFirstDay = this.getPriorSeasonFirstDay(currentYear, currentMonth);
        //上季度的最后一天
        var priorSeasonLastDay = new Date(priorSeasonFirstDay.getFullYear(), priorSeasonFirstDay.getMonth() + 2, this.getMonthDays(priorSeasonFirstDay.getFullYear(), priorSeasonFirstDay.getMonth() + 2));
        //添加至数组
        startStop.push(priorSeasonFirstDay);
        startStop.push(priorSeasonLastDay);
        return startStop;
    };
    /**     
     * 得到去年的起止日期
     */
    this.getPreviousYear = function () {
        //起止日期数组
        var startStop = new Array();
        //获取当前时间
        var currentDate = this.getCurrentDate();
        //获得当前年份4位年
        var currentYear = currentDate.getFullYear();
        currentYear--;
        var priorYearFirstDay = new Date(currentYear, 0, 1);
        var priorYearLastDay = new Date(currentYear, 11, 1);
        //添加至数组
        startStop.push(priorYearFirstDay);
        startStop.push(priorYearLastDay);
        return startStop;
    };

    /**
     * 转换时间格式
     * @param {*} time 
     */
    this.conversionTimeFormat = function(time) {
        var date = new Date(time);
        date.setTime(date.getTime());
        let getFullYear = date.getFullYear()
        let getMonth = date.getMonth()+1
        let getDate = date.getDate()
        let getHours = date.getHours()
        let getMinutes = date.getMinutes() 
        let getSeconds = date.getSeconds()
         //补0
        if(getMonth<10){
            getMonth = "0"+getMonth
        }
        if(getDate<10){
            getDate = "0"+getDate
        }
        if(getHours<10){
            getHours = "0"+getHours
        }
        if(getMinutes<10){
            getMinutes = "0"+getMinutes
        }
        if(getSeconds<10){
            getSeconds = "0"+getSeconds
        }
        return `${getFullYear}-${getMonth}-${getDate} ${getHours}:${getMinutes}:${getSeconds}`;
    }
};

export function downloadFile(url) {
    url = url.replace(/\\/g, "/");
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    // 文件下载进度
    // xhr.onprogress = (res) => {
    //   this.loadingTip =
    //     "源文件下载中: " + ((res.loaded / res.total) * 100).toFixed(2) + "%";
    // };
    xhr.onload = () => {
    //   this.loadingTip = "";
    //   this.loading = false;

      if (xhr.status === 200) {
        // 获取文件blob数据并保存
        var num = url.lastIndexOf("/") + 1;
        //把参数和文件名分割开
        var fileName = url.substring(num).split("?")[0];
        var export_blob = new Blob([xhr.response]);
        var save_link = document.createElementNS(
          "http://www.w3.org/1999/xhtml",
          "a"
        );
        save_link.href = URL.createObjectURL(export_blob);
        save_link.download = fileName;
        save_link.click();
      }
    };
    // this.loading = true;
    xhr.send();
}

