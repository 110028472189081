/*
 * @Author: withering 
 * @Date: 2021-07-27 11:27:13 
 * @Last Modified by: withering
 * @Last Modified time: 2021-08-19 11:11:58
 */

'use strict';

const dayjs = require('dayjs');
const duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

export default {
    /**
      * Vue
      * @param {Vue} Vue
    */
    install: function (Vue) {
        Object.defineProperties(Vue.prototype, {
            $dayjs: {
                get: function () {
                    return dayjs;
                },
            },
        });
    }
};