import { getList } from "@/api/android/calLogs"
import router from "@/router"
import i18n from "@/lang"
import { getCurrentDate } from "@/utils/common"
export default {
    namespaced: true,
    state: {
        //通话记录列表
        callLogList: [],
        //分页参数
        pageParams: {
            current_page: 1,
            total: 0,
            limit: 10,
            search:"",
            type:0
        },
        // 最后同步时间
        synchronisedTime: '',
    },
    mutations: {
        /**
         * 更新最后同步时间
         * @param {*} state
         * @param {synchronisedTime} payload
         */
    setSynchronisedTime(state,data) {
        state.synchronisedTime = data
      },
        /**
         * 写入通话记录列表
         * @param {*} state 
         * @param {*} data 
         */
        setCallLogList(state, data) {
            state.callLogList = data
        },
        /**
         * 重置分页参数
         * @param {*} param0 
         */
        initPageParams(state,data) {
            state.pageParams = data
        },
        /**
         * 重置通话记录列表
         * @param {*} param0 
         */
        initCallLogList(state,data) {
            state.callLogList = data
        },
        /**
         * 写入分页当前页参数
         * @param {*} param0 
         * @param {*} payload 
         */
        setPageParamsCrurentPage(state, current_page) {
            state.pageParams.current_page = current_page
        },
        /**
         * 写入分页参数每页限制数
         * @param {*} param0
         * @param {*} limit 
         */
        setPageParamsLimit(state, limit) {
            state.pageParams.limit = limit
        },
        /**
         * 写入搜索参数
         * @param {*} state 
         * @param {*} search 
         */
        setPageParamsSearch(state, search) {
            state.pageParams.pageParams = search
            // Vue.set(state.pageParams,"search",search)
        },
        /**
         * 写入分页参数 列表总数
         * @param {*} param0 
         * @param {*} total 
         */
        setPageParamsTotal(state, total) {
            state.pageParams.total = total
        }
    },
    actions: {
        /**
         * 请求获取通话记录列表
         */
        async requestCallLogList(context, data = []) {
            const params = context.getters.getPageRequestParams;
            data.forEach((item) => { params[item.key] = item.value });
            const result = await getList(router.app._route.params.equipmentId, params);
            context.commit('setCallLogList', result.data.data);
            context.commit('setPageParamsTotal', result.data.total)
            context.commit('setPageParamsCrurentPage',result.data.current_page)
        },
        /**
         * 更新最后同步时间
         * @param {*} state
         * @param {synchronisedTime} payload
         */
        setSynchronisedTime(context) {
            context.commit("setSynchronisedTime",getCurrentDate())
        },
        /**
         * 写入通话记录列表
         * @param {*} context 
         * @param {*} data 
         */
        setCallLogList(context, data) {
            let callLogList = context.state.callLogList;
            data.forEach(item => callLogList.push(item))
            context.commit("setCallLogList",callLogList)
        },
        /**
         * 重置分页参数
         * @param {*} context
         */
        initPageParams(context) {
            context.commit("initPageParams",{
                current_page: 1,
                total: 0,
                limit: 10
            })
        },
        /**
         * 重置通话记录列表
         * @param {*} param0 
         */
        initCallLogList(context) {
            context.commit("initCallLogList",[])
        },
        /**
         * 写入分页当前页参数
         * @param {*} param0 
         * @param {*} payload 
         */
        setPageParamsCrurentPage(context, current_page) {
            context.commit("setPageParamsCrurentPage",current_page)
        },
        /**
         * 写入分页参数每页限制数
         * @param {*} param0
         * @param {*} limit 
         */
        setPageParamsLimit(context, limit) {
            context.commit("setPageParamsLimit",limit)
        },
        /**
         * 写入搜索参数
         * @param {*} state 
         * @param {*} search 
         */
        setPageParamsSearch(context, search) {
            context.commit("setPageParamsSearch",search)
        },
        /**
         * 写入分页参数 列表总数
         * @param {*} param0 
         * @param {*} total 
         */
        setPageParamsTotal(context, total) {
            context.commit("setPageParamsTotal",total)
        }
    },
    getters: {
        getSynchronisedTime:state =>{
            return state.synchronisedTime
          },
        getCallLogList: state => {
            let data = state.callLogList;
            data.forEach(item => {
                switch (item.type) {
                    case 0:
                        item.typeName = i18n.t('Incoming')
                        item.typeIconClass = "incomingCall"
                        item.typeColor = "#2DD179"
                        break;
                    case 1:
                        item.typeName = i18n.t('Outgoing')
                        item.typeIconClass = "dialOut"
                        item.typeColor = "#347AEB"
                        break;
                    case 2:
                        item.typeName = i18n.t('Cancelled')
                        item.typeIconClass = "refuse"
                        item.typeColor = "#EB3459"
                        break;
                    case 3:
                        item.typeName = i18n.t('MonUnknown')
                        break;
                    default:
                        item.typeName = i18n.t('MonUnknown')
                        break;
                }
            })
            return data;
        },
        /**
         * 获取请求分页参数
         * @param {*} state 
         * @returns 
         */
        getPageRequestParams: state => {
            const params = {
                page: state.pageParams.current_page,
                limit: state.pageParams.limit,
                search:state.pageParams.search
            }
            switch(state.pageParams.type) {
                case 0:
                    
                    break;
            }
            return params
        },
        /**
         * 获取分页参数
         * @param {*} state 
         * @returns 
         */
        getPageParams:state => {
            return state.pageParams
        }
    }
}