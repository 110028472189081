import axios from 'axios'
import { getToken, removeToken } from './auth'
import { httpCode } from './consts'
import { Message } from 'element-ui'
import router from '@/router'
import store from '@/store'

const _service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: false,
  timeout: 10000,
})

// 请求拦截器
_service.interceptors.request.use(
  config => {
    if(config.v2){
      // 是否带有new参数，如果有，则是前台接口
      // 分流，后续前台有接口的时候，可以重写后缀
      config.baseURL = config.baseURL + process.env.VUE_APP_V2_API;
      delete config['v2']
    } else {
      config.baseURL = config.baseURL + process.env.VUE_APP_ADMIN_API;
    }
    const token = getToken()
    if (token) {
      config.headers['token'] = token
    }
    return config
  }, error => {
    Promise.reject(error)
  })

// 响应拦截器
_service.interceptors.response.use(
  response => {
    const result = response.data;
    // code: 10000
    // data: {token: "b8d5b038cc72e529068c9149c8542e6eb52536e5"}
    // message: "success"
    const { code, message } = result;
    // 根据code 值 进行相应的操作
    // 如果状态码过多，后续可封装
    if (code !== httpCode.SUCCESS.code) {
      switch (code) {
        case httpCode.TOKEN_ERROR.code:
          removeToken()
          Message({
            type: 'error',
            message: httpCode.TOKEN_ERROR.msg || message
          })
          router.push("/login")
          break;
        case httpCode.PARAMS_ERROR.code:
          Message({
            type: 'error',
            message: httpCode.PARAMS_ERROR.msg || message
          })
          break;
        case httpCode.USER_VIP_EXPIRED.code:
          Message({
            type: 'error',
            message: httpCode.USER_VIP_EXPIRED.msg || message
          })
          break;
        case httpCode.NOTFOUND_HTTP.code:
          Message({
            type: 'error',
            message: httpCode.NOTFOUND_HTTP.msg || message
          })
          router.push("/")
          break;
        case httpCode.SERVER_ERROR.code:
          Message({
            type: 'error',
            message: httpCode.SERVER_ERROR.msg || message
          })
          break;
        case httpCode.HEADERS_MISSING.code:
          Message({
            type: 'error',
            message: httpCode.HEADERS_MISSING.msg || message
          })
          break;
        case httpCode.LOGIN_EMAIL_ERROR.code:
          Message({
            type: 'error',
            message: httpCode.LOGIN_EMAIL_ERROR.msg || message
          })
          break;
        case httpCode.LOGIN_PASSWORD_ERROR.code:
          Message({
            type: 'error',
            message: httpCode.LOGIN_PASSWORD_ERROR.msg || message
          })
          break;
        case httpCode.LOGIN_EXPIRED.code:
          Message({
            type: 'error',
            message: httpCode.LOGIN_EXPIRED.msg || message
          })
          store.dispatch('users/login_out').then(()=>{
            router.push("/login")
          })
          break;
        default:
          break;
      }

      return Promise.reject(new Error(message || 'Error'))

    } else {
      return Promise.resolve(result)
    }
  }, error => {
    return Promise.reject(error)
  })

// const _request = {
//   get(url, params = {}) {
//     return _service({
//       url: url,
//       method: 'GET',
//       params: params
//     })
//   },
//   post(url, params) {
//     const data = qs.stringify(params)
//     return _service({
//       url: url,
//       method: 'POST',
//       data: data
//     })
//   },
//   fpost(url, params) {
//     const data = qs.stringify(params)
//     return _service({
//       url: url,
//       method: 'POST',
//       data: data
//     })
//   },
//   put(url, params) {
//     const data = qs.stringify(params)
//     return _service({
//       url: url,
//       method: 'PUT',
//       data: data
//     })
//   },
//   del(url, params) {
//     return _service({
//       url: url,
//       method: 'DELETE',
//       params: params
//     })
//   }
// }
export default _service