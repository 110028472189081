import { getAssignFile } from '@/api/android/command'
// import collect from 'collect.js';
import router from "@/router"
export default {
    namespaced: true,
    state: {
        //图片进度
        imageSchedule: [],
        //视频进度
        videoSchedule: [],
    },
    mutations: {
        /**
         * 写入图片文件上传进度
         * @param {*} state 
         * @param {*} data 
         */
        setFileSchedule(state, data) {
            state[data.stateKey] = data.fileSchedule
            // Vue.set(state, data.stateKey, data.fileSchedule)
        }
    },
    actions: {
        /**
         * 获取指定文件指令
         * @param {*} context 
         * @param {*} uniqueId 
         */
        async requestAssignFile(context, { uniqueId, type = "image" }) {
            let params = {
                uniqueId: uniqueId,
                type: ""
            };
            switch (type) {
                case "image":
                    params.type = "GET_ASSIGN_PHOTOS_BIG_PICTURE"
                    break;
                case "video":
                    params.type = "GET_ASSIGN_VIDEO"
                    break;
                default:
                    break;
            }
            await getAssignFile(router.app._route.params.equipmentId, params);

        },
        setFileSchedule(context,data) {
            const { uniqueId, schedule, type = "image" } = data;
            
            let fileSchedule = context.getters.getImageSchedule
            let stateKey = "imageSchedule"
            switch (type) {
                case "image":
                    fileSchedule = context.getters.getImageSchedule
                    stateKey = "imageSchedule"
                    break;
                case "video":
                    fileSchedule = context.getters.getVideoSchedule
                    stateKey = "videoSchedule"
                    break;
            }
            //查找是否存在
            let exists = false;
            fileSchedule.forEach(item => {
                if (item.uniqueId === uniqueId) {
                    exists = true;
                    item.schedule = schedule
                }
            })
            if (exists === false) {
                fileSchedule.push({
                    uniqueId: uniqueId,
                    schedule: schedule
                })
            }
            // collect(fileSchedule).where('uniqueId','=',uniqueId).first();
            //当图片上传完成
            // if(schedule === 100){
            //     switch (type) {
            //         case "image":
                        
            //             break;
            //         case "video":
                        
            //             break;
            //     }
            // }
            context.commit("setFileSchedule",{
                stateKey:stateKey,
                fileSchedule:fileSchedule
            })
        }
    },
    getters: {
        /**
         * 获取指定文件上传进度
         * @param {*} state 
         * @returns 
         */
        getAssignUniqueIdFileSchedule: state => (uniqueId, type = "image") => {
            let data = {
                uniqueId: uniqueId,
                schedule: 0
            };
            switch (type) {
                case "image":
                    if (state.imageSchedule.length > 0) {
                        state.imageSchedule.forEach(item => {
                            if (item.uniqueId === uniqueId) {
                                data.schedule = item.schedule
                            }
                        })
                    }
                    break;
                case "video":
                    if (state.videoSchedule.length > 0) {
                        state.videoSchedule.forEach(item => {
                            if (item.uniqueId === uniqueId) {
                                data.schedule = item.schedule
                            }
                        })
                    }
                    break;
                default:
                    break;
            }

            return data.schedule
        },
        /**
         * 获取图片上传进度
         * @param {*} state 
         * @returns 
         */
        getImageSchedule: state => {
            return state.imageSchedule
        },
        getVideoSchedule:state => {
            return state.videoSchedule
        }

    }
}