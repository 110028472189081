import HomeLayout from '@/layout/home'
import { getToken } from "@/utils/auth"
export default {
    path: "/",
    name: 'Home',
    component: HomeLayout,
    children: [{
        path: '/',
        name: 'Index',
        component: () => import('@/views/index'),
        meta: {
            headertitle: 'Cell Phone Spy | Best Spy Phone App to Track Your Kids Phone Activity',
        }
    },
    {
        path: 'notfound',
        name: 'notfound',
        component: () => import('@/views/notfound'),
        meta: {
            headertitle: 'Page No Found',
        }
    },
    {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/login'),
        meta: {
            headertitle: 'Sign In',
        }
    },
    {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/register/index'),
        redirect: to => {
            let token = getToken();
            if(token){
                return { path: "/my-products"}
            } else {
                if(to.query.source){
                    return { path: "/email", query: { source: to.query.source } }
                } else {
                    return { path: "/email" }
                }
            }
        },
        meta: {
            headertitle: 'Sign Up',
        }
    },
    {
        path: 'email',
        name: 'Email',
        component: () => import('@/views/register/email'),
        meta: {
            headertitle: 'Create iSeeGuard Account',
        }
    },
    {
        path: 'device',
        name: 'Device',
        component: () => import('@/views/register/device'),
        meta: {
            headertitle: 'Create iSeeGuard Account',
        }
    },
    {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: () => import('@/views/forgot-password/index'),
        meta: {
            headertitle: 'Forgot Password',
        },
    },
    {
        path: 'change-password',
        name: 'ChangePassword',
        component: () => import('@/views/forgot-password/change-password'),
        meta: {
            headertitle: 'Change Password',
        },
    },
    {
        path: 'email-sent',
        name: 'EmailSent',
        component: () => import('@/views/forgot-password/email-sent'),
        meta: {
            headertitle: 'Email Sent',
        },
    },
    {
        path: 'successfully',
        name: 'Successfully',
        component: () => import('@/views/forgot-password/successfully'),
        meta: {
            headertitle: 'Successfully',
        },
    },
    {
        path: 'my-products',
        name: 'MyProducts',
        component: () => import('@/views/my-products'),
        meta: {
            headertitle: 'My Products & Orders Management',
        },
        props: {
            isDemo: false
        }
    },
    {
        path: 'store',
        name: 'Store',
        component: () => import('@/views/store/index'),
        meta: { headertitle: 'Store' },
    },
    {
        path: 'store/android-pricing',
        name: 'Android-Pricing',
        component: () => import('@/views/store/android/pricing'),
        meta: { headertitle: 'Android Pricing' }
    },
    {
        path: 'store/whatsapp-pricing',
        name: 'Whatsapp-Pricing',
        component: () => import('@/views/store/whatsapp/pricing'),
        meta: { headertitle: 'Whatsapp Pricing' }
    },
    {
        path: 'store/mac-pricing',
        name: 'Mac-Pricing',
        component: () => import('@/views/store/mac/pricing'),
        meta: { headertitle: 'Mac Pricing' }
    },
    {
        path: 'store/window',
        name: 'Window-Pricing',
        component: () => import('@/views/store/windows/index'),
        meta: { headertitle: 'Window Pricing' }
    },
    {
        path: 'store/ios',
        name: 'Ios-Index',
        component: () => import('@/views/store/ios/index'),
        meta: { headertitle: 'Ios Index' }
    },
    {
        path: 'store/ios-pricing',
        name: 'Ios-Pricing',
        component: () => import('@/views/store/ios/pricing'),
        meta: { headertitle: 'Ios Pricing' }
    },
    {
        path: 'demo',
        name: 'Demo',
        redirect: "demo/my-products",
    },
    {
        path: 'demo/my-products',
        name: 'My-products-Demo',
        component: () => import('@/views/my-products'),
        meta: {
            headertitle: 'My Products & Orders Management',
        },
        props: {
            isDemo: true
        }
    },
    {
        path: 'settings',
        name: 'Settings',
        component: () => import('@/views/settings'),
        meta: {
            headertitle: 'Settings',
        },
    },
    {
        path: 'guide/android',
        name: 'GuideAndroid',
        component: () => import('@/views/guide/android'),
        meta: {
            headertitle: 'Guide-Android',
        },
    },
    {
        path: 'guide/whatsapp',
        name: 'GuideWhatsapp',
        component: () => import('@/views/guide/whatsapp'),
        meta: {
            headertitle: 'Guide-Whatsapp',
        },
    },
    {
        path: 'guide/mac',
        name: 'GuideWhatsapp',
        component: () => import('@/views/guide/mac'),
        meta: {
            headertitle: 'Guide-Mac',
        },
    },
    {
        path: 'guide/ios',
        name: 'GuideIos',
        component: () => import('@/views/guide/ios'),
        meta: {
            headertitle: 'Guide-Ios',
        },
    },
    {
        path: 'settings/change-account',
        name: 'ChangeAccount',
        component: () => import('@/views/settings/change-account'),
        meta: {
            headertitle: 'change-account',
        },
    },
    {
        path: 'eula',
        name: 'EULA',
        component: () => import('@/views/policy/eula'),
        meta: {
            headertitle: 'Iseeguard EULA',
        },
    },
    {
        path: 'privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import('@/views/policy/privacy-policy'),
        meta: {
            headertitle: 'privacy-policy',
        },
    },
    {
        path: 'cookies-policy',
        name: 'CookiesPolicy',
        component: () => import('@/views/policy/cookies-policy'),
        meta: {
            headertitle: 'cookies-policy',
        },
    },
    {
        path: 'refund-policy',
        name: 'RefundPolicy',
        component: () => import('@/views/policy/refund-policy'),
        meta: {
            headertitle: 'refund-policy',
        },
    }]
}