export default {
    CreateAccount: "Create an Account",
    Email: "Email",
    Password: "Password",
    ConfirmPas: "Confirm Password",
    SignUp: "Sign Up",
    AleadyAccount: "Already have an account? ",
    Login: "Login",
    Required: "This field is required.",
    NotEmail: "The email address is not valid.",
    TwoPass: "You've input two different passwords.",
    SixOne: "The password must be 6-16 characters in length.",
    Alphabets: "The password must contain digits and alphabets.",
    Space: "The password can't contain a blank space.",
    Symbols: "The password can contain characters of only digits, alphabets, or symbols.",
    SignLoad: "Registering",
    AgainTry: "Signing up failed. Please try again.",
    EmailExists: "The email already exists.",
    SupportToMonitor: "Support to monitor: ",
    SignIn: "Sign In",
    IncorrectOr: "Incorrect username or password.",
    Forpwd: "Forgot Password?",
    Signtry: "Signing in failed. Please try again.",
    Restpwd: "Reset your password:",
    SendLink: "Send Reset Link",
    ReturnTo: "Return to",
    DontExit: "The email address doesn't exist.",
    CheckEmail: "Please Check Your Email",
    JustFold: "We just sent a password reset link to the email address you specified. If you can't find it in the inbox, go to check your spam or junk folder.",
    TwoHour: "If you don't receive the email in the next 24 hours, please contact our support team at <a href='mailto:support@iSeeGuard.com'>support@iSeeGuard.com</a>",
    UnknowError: "Unknown Error",
    TryOp: "Oops. Something went wrong just now. Please try again.",
    Retry: "Retry",
    CreatNewPwd: "Create a New Password",
    NewPwd: "New Password",
    Confirm: "Confirm",
    OldPwd: "You have input the old password. Please change another one.",
    NewPwdSucess: "New password created successfully!",
    ThePage: "The page will be directed to the login page in 5 seconds.",
    ProceedNow: "Proceed Now",
    UnknowTry: "Unknown error. Please retry.",
    LinkExpired: "The link has been expired. Please retry.",
    ProfileSett: "Profile Settings",
    Download: "Download iSeeGuard Pro App onto the Target Device ",
    Back: "Back",
    Next: "Next",
    Dashboard: "Dashboard",
    PhoneLogs: "Phone Logs",
    CallLogs: "Call Logs",
    Messages: "Messages",
    Contacts: "Contacts",
    Locations: "Locations",
    Geofence: "Geofence",
    BrowserHistory: "Browser History",
    Photos: "Photos",
    VideoPreview: "Video Preview",
    CaptureScreenshots: "Capture Screenshots",
    AppActivity: "App Activities",
    WiFiLogger: "Wi-Fi Logger",
    Keylogger: "Keylogger",
    Calendar: "Calendar",
    SocialApps: "Social Apps",
    DataExport: "Data Export",
    Settings: "Settings",
    Sync: "Sync",
    Updated: "Updated: ",
    DataLoad: "It may take some time to sync phone data. Please be patient...",
    DatanNotWhy: "Why is iSeeGuard Pro not able to sync new data?",
    DataUpdata: 'First of all, understand that the iSeeGuard Pro app installed on the target device does not sync new data instantly but collects data every 15 minutes. Whenever you log into the iSeeGuard Pro account or click on the Sync icon, the data will start to update. The time it takes for <span class="red">data to sync </span> depends on the size of the data and the internet connection speed of the target device.',
    DataNum: "Some data will only be uploaded through connection to a Wi-Fi network: photos, video preview, call recordings, social app screenshots, Wi-Fi logger, and app activities. Other data will be uploaded through 3G, 4G or Wi-Fi network: locations, device info, keylogger, manually captured screenshots, call logs, messages, contacts, calendars, geofence, browser history, and social app chat history. ",
    DataG: "The following might be the reason if you can not view any updated data.",
    Dataup: "The target device is switched off.",
    DataOff: "The target device is not connected to the network or the network is unstable.",
    Datastrong: "The Accessibility permission for iSeeGuard Pro is disabled on the target device. If so, please go to Settings > Accessibility > find System Update Service and enable it.",
    Datauninstall: "The storage permission for iSeeGuard Pro is disabled on the target device. If so, please go to Settings > Apps > System Update Service > Permissions > allow storage access for the app.",
    Dataassint: 'The iSeeGuard Pro assistant app is inactive or has been uninstalled on the target device. If so, please restart the target <span class="red"> phone or reinstall </span> the app.',
    ActInfo: "Account Info",
    ActId: "Account ID",
    NotEml: "Notification Email",
    DvcInfo: "Device Info",
    DvcName: "Device Name",
    DvcStatus: "Device Status",
    Online: "Online",
    Offline: "Offline",
    Plan: "Plan",
    ExpDate: "Expiration Date",
    AutoRel: "Auto-Renewal",
    Disable: "Disabled ",
    Enable: "Enabled",
    DvcMod: "Device Model",
    Dvcson: "Device OS Version",
    GPS: "GPS",
    On: "On",
    Off: "Off",
    BatLev: "Battery Level",
    WiFi: "WiFi",
    TopCal: "Top 8 Calls",
    TopMes: "Top 8 Messages",
    LastLoca: "Last Known Location",
    AppVersion: "App Version",
    UpdateBtn: "Update",
    SureUpdate: "Are you sure you want to update the app now?",
    SureUpdateTips: 'After clicking the "Yes" button below, you will get a prompt on the target device to approve the app update. Please make sure you\'ve got the target device, otherwise it will expose the fact that the device is being monitored.',
    UpdateSuccess: "The update is downloading automatically. Please check the process and approve the installation on the target device.",
    UseLastetVersion: "You are using the latest version of this application. Please check back again for updates at a later time.",
    InUpdating: "The app is updating and you can check the process on the target device. Please wait a few minutes.",
    UpdateFailed: "The update failed because the target device is not connected to the network or the app is temporarily unresponsive.",
    Name: "Name",
    PhoneNumber: "Phone Number",
    Type: "Type",
    Incoming: "Incoming",
    Cancelled: "Cancelled",
    Outgoing: "Outgoing",
    Duration: "Duration",
    Date: "Date",
    All: "All",
    Today: "Today",
    ThisMonth: "This Month",
    LastMonth: "Last Month",
    LastYear: "Last Year",
    Search: "Search",
    To: "To",
    Phone: "Phone",
    Work: "Work",
    Home: "Home",
    Others: "Others",
    Mail: "Mail",
    Birthday: "Birthdays",
    Anniversary: "Anniversary",
    Address: "Address",
    LonLat: "Longitude and Latitude",
    LocTime: "Location Time",
    MapView: "Map View",
    FailSync: "Fail to sync. Please try again.",
    GenerateDes: "Generate a geofence, so that you will be notified if your child leaves a designated area.",
    GenerateEff: "Generate Geofences Conveniently and Efficiently",
    GenerateCir: "Generate a geofence around a designated area by circling a specific location.",
    StayAlt: "Stay Informed with Geofence Alerts",
    WhGeo: "When your child traverses the geofence, you will receive an alert by email.",
    Start: "Start",
    SureDelete: "Are you sure you want to delete this geofence?",
    Yes: "Yes",
    No: "No",
    MaxT: "Maximum 10 items.",
    Ok: "OK",
    NameGeofc: "Name the Geofence",
    Setlon: "Set Restricted Location ",
    SearchAd: "Search Address",
    SetRadius: "Set Radius",
    Meters: "Radius of the geofence in meters (1000m max.)",
    GuardTime: "Set Guard Time",
    SetNow: "Set now >",
    SetAlt: "Set Alerts via Notification Email",
    Save: "Save",
    Cancel: "Cancel",
    StartTime: "Start Time",
    EndTime: "End Time",
    Repeat: "Repeat",
    EverySun: "Every Sun",
    EveryMon: "Every Mon",
    EveryTue: "Every Tue",
    EveryWed: "Every Wed",
    EveryThu: "Every Thu",
    EveryFri: "Every Fri",
    EverySat: "Every Sat",
    PlsName: "Please set a name for the geofence.",
    Plsloca: "Please set a location.",
    PlsTime: "Please set the guard time.",
    ThreeName: "There is already a geofence with the same name.",
    LastVtne: "Last Visit Time",
    URL: "URL",
    Title: "Title",
    Frequency: "Frequency",
    Visits: "Visits",
    HowFeat: "How to use this feature?",
    MakeAct: "Make sure that the target device is active.",
    ClickBt: 'Click the "Take Screenshots" button.',
    VuShot: "View the screenshots.",
    NotLock: "Note: This feature is disabled when the screen of the target phone is locked.",
    ScReenPow: "The screen capture failed because the target phone screen is locked or the app is temporarily unresponsive. Please try again later.",
    TakScr: "Take Screenshots",
    Version: "Version",
    Size: "Size",
    InstDate: "Installed Date",
    TodayFreq: "Today's Frequency",
    TodayScreenTime: "Today's Screen Time",
    ClickKey: 'Click the "Start" button and wait for the synced keyloggers.',
    ViewScr: "View the screenshots.",
    KidTxy: "iSeeGuard Pro can help you monitor the keystrokes detected from the monitored Android device. Each keystroke comes with an app icon indicating where they come from. ",
    NotDat: "No Results",
    Event: "Event",
    EventLocation: "Event Location",
    Note: "Notes",
    AppNotif: "App Notifications",
    Modules: "Modules",
    Time: "Time",
    Format: "Format",
    Export: "Export",
    DeleteAndExport: "Delete exported data from the dashboard",
    CancelSub: "Cancel Subscription",
    UnbindDevice: "Unbind Device",
    ContactSupport: "Contact Support",
    SureRenewal: "Are you sure you want to cancel auto-renewal?",
    SureUnbind: "Are you sure you want to unbind this device?",
    PersonalPrivacy: "To protect your personal privacy, iSeeGuard Pro will stop monitoring and delete all data.",
    UnboundDvc: "Congratulations! You have successfully unbound the device!",
    Profile: "Profile",
    ContactUs: "Contact Us",
    Logout: "Log out",
    Device: "Device",
    Month: "Mo",
    BuyNow: "Buy Now",
    AddNew: "Add New",
    Unactivated: "Unactivated",
    VersionUpdate: "There is a new update available. If possible, please uninstall the previous version on the target phone, then restart the phone and download the latest version. ",
    LearnMore: "Learn more",
    HowToUpdate: "How to update the app on the target device?",
    UpdateTips1: "Please proceed to My Products > Settings > Unbind device.",
    UpdateTips2: "Go hold the target device, proceed to Settings > Apps > find System Update Service and uninstall it first.",
    UpdateTips3: "Then open www.iseeguard.com with the browser and download the app.",
    UpdateTips4: "Finally, follow the on-screen prompts to finish the configuration.",
    WhatsappUpdateTips1: "Go hold the target device, proceed to Settings > Apps > find WhatsApp Service and uninstall it first.",
    WhatsappUpdateTips2: "Then open www.installapk.net with the browser and download the app.",
    WhatsappUpdateTips3: "Finally, follow the on-screen prompts to finish the configuration.",
    ThankPurchase: "Thanks for your order!",
    ThankTips: "You will receive a confirmation email with details of your order.",
    Product: "Product:",
    BillingEmail: "Your billing email:",
    ClickTips: "Click Next and start monitoring.",
    PayFailed: "Payment Failed",
    PayFailedTips: "Please try again with another payment method or contact your payment provider. ",
    TryAgain: "Try Again",
    FailedReason: "Why does the payment fail?",
    FailedReason1: "1. Expired card",
    FailedReason2: "2. Insufficient funds",
    FailedReason3: "3. The information entered is incorrect",
    FailedReason4: "4. Other reasons",
    OrderReview: "Order in Review",
    OrderReviewTips: "Your order ",
    OrderReviewTips1: "has been received and is being verified by our team.",
    OrderReviewTips2: "The order is expected to be processed within the next 2 hours - the maximum processing duration is 24 hours. You will receive an email notification if the payment is successful.",
    OrderReviewTips3: "To avoid making multiple payment requests from your credit card, please do not submit the order repeatedly.",
    ChangeNotEmail: "Change Notification Email",
    NewEmail: "New Email",
    NotHu: "Note: This change only applies to your notification email.The login email remains unchanged.",
    SendMailTse: "We have sent a confirmation email to your new notification email address. If you do not receive this email, please click the button below to retry or contact us at",
    ChangeNotsuces: "You have changed the notification email successfully!",
    ChangeTwo: "You have input the old email. Please change another one.",
    ChangePassword: "Change Password",
    OldPassword: "Old Password",
    PwdSuces: "You have changed the password successfully.",
    WrongOldPwd: "Wrong old password.",
    TheNewCode: "The new and the old password are the same. Please try again.",
    NoMore: "no more",
    ReNew: "Renew",
    CheckExported: "There is no data to be exported. Please check and try again.",
    FailedCheck: "Fail to export. Due to browser compatibility issues, data cannot be downloaded through Firefox or Edge browser for now. Please use Chrome, Safari or Opera and try again.",
    ScShot: "Screenshot",
    AddGeofence: "Add a geofence",
    WifILocation: "Wifi Location",
    footCot: "Copyright © 2021 iSeeGuard.com. All rights reserved.",
    Supcenter: "Support Center",
    ProductFAQs: "Product FAQs",
    AccountFAQs: "Account FAQs",
    PurchaseFAQs: "Purchase FAQs",
    iSeeGuardStore: "iSeeGuard Store",
    Polide: "Policy",
    privPli: "Privacy Policy",
    cokPli: "Cookie Policy",
    refndPli: "Refund Policy",
    elu: "EULA",
    TermsCot: "Terms & Conditions",
    FoloUs: "Follow Us",
    Paymet: "Payment",
    prodt: "Products",
    suport: "Support",
    Load: "Loading...",
    NotFund: "Oops! Page Not Found.",
    IagreThe: "I agree on the",
    GlevEu: "iSeeGuard EULA",
    PriPoly: "Privacy Policy",
    OurGuat: "Our Guarantee",
    secure: "Secure",
    finalcoft: "We value your privacy. Your personal or financial information will be kept confidential.",
    twoBack: "30-Day Money Back",
    provideCase: "We provide 30-day money back guarantee for all products in case you are not completely satisfied.",
    cancelAnt: "Cancel Anytime",
    cancelNeed: "You can always cancel the subscription if you don't need the product anymore.",
    FastSup: "Fast Support",
    EnquriedProblem: "Our team offers 24/5 Customer Service to help with any enquires and problems.",
    AsUcess: "Sorry, the verification failed. Please make sure the iSeeGuard Pro Assistant app has been installed on the target device successfully.",
    ExportTip: "Data Export is not supported on mobile devices. Please log on to iSeeGuard online panel on your computer and export the specific data.",
    Expired: "Expired",
    Features: "Features",
    WifiUpload: "* Kindly note pictures will only upload via WiFi.",
    WhatsAppTips: "Try iSeeGuard for WhatsApp and get 10+ powerful WhatsApp monitoring features.>>",
    ScOne: "An error occurred while saving the screenshots to the target device. Please try again later.",
    ScTwo: "An error occurred while uploading the screenshots. Please try again later.",
    Anpay: "Secure online payment",
    OldEmail: "Old Email:",
    ExtendSubscript: "Extend Plan",
    Troubleshoot: "Troubleshoot",
    PermissionsCheck: "Permissions Check",
    LogAgin: "If the app is not working properly on the target phone, it may be that the permission is turned off. Go to Settings and enable them again to make the app function again.",
    SubmitLog: "To better solve your problem, you can submit the error log file to us.",
    Accessibility: "Accessibility",
    Administrator: "Administrator",
    CallLog: "Call Log",
    Location: "Location",
    Notification: "Notification",
    SMS: "SMS",
    Storage: "Storage",
    UsageStatus: "Usage Status",
    AccessCamera: "Access Camera",
    Microphone: "Microphone",
    Submit: "Submit",
    LogExpried: "The error log has been successfully uploaded.",
    LogMailOne: "You can contact us via",
    Spt: "support@iSeeGuard.com",
    SptFst: "for faster support.",
    Permission: "Permissions",
    Status: "Status",
    OnlyOne: "Only 1000 records can be exported at a time.",
    LogSucess: "An unknown error occurred.",
    LogUnkow: "Please submit again in 10 minutes. Or you can contact us via ",
    LogTwoUnkow: "for faster support.",
    Items: "Items",
    ViewSchot: "View Historical Screenshots",
    verifyIcloudAccount: "Verify iCloud credentials",
    icloudId: "Please enter the iCloud ID of the target device you want to monitor.",
    anotherIcloud: "Please try again or",
    useOther: "use another iCloud account.",
    oldIcloud: "You have entered the old iCloud account.",
    accoundLock: "iCloud Account Locked",
    lockedAgain: "This iCloud account has been locked by Apple for security reasons. Please try again in 12 hours.",
    twoFactor: "Two-Factor Authentication",
    phoneSend: "A message with a verification code has been sent to the target device. Please enter the code to verify the account.",
    dontGetCode: "Didn't get a verification code?",
    sixCode: "With two-factor authentication on, you’ll need physical access to the target device and get a six-digit verification code.",
    resendCode: "Resend Code",
    textMe: "Text Me",
    needHelp: "Need Help?",
    notSendCode: "Failed to send verification code, please try again.",
    noVerification: "Incorrect verification code.",
    noRecordsFound: "No Records Found!",
    newPricePlan: "We noticed that you entered a different iCloud account that does not match our records.If you want to monitor the newly entered account,please delete the previous iCloud account or buy a new pricing plan.",
    deleteAccount: "Delete Account",
    icloudError: "iCloud Verification Error",
    aginNormally: "An error occurred when verifying the target iCloud account. Some data may not be updated. Please verify again so that you can use iSeeGuard Pro normally.",
    notNow: "Not Now",
    verifyIcloud: "Verify iCloud",
    icloudAccount: "iCloud Account",
    icloudStorage: "iCloud Storage",
    deviceName: "Device Name",
    deviceModel: "Device Model",
    seeMore: "See More",
    sureTargetTwo: "Please make sure the Find My iPhone feature is enabled on the target device.",
    location: "Location",
    list: "List",
    sureDeleteAccount: "Are you sure you want to delete the iCloud account?",
    removeThat: "All the records and data related to this iCloud account will be removed after that.",
    isRemove: "Congratulations! This iCloud account has been successfully removed.",
    Reminder: "Reminders",
    iCloudDrive: "iCloud Drive",
    appleIdErr: "The Apple ID or password is incorrect.",
    selectTrustePhone: "Select Trusted Phone Number",
    serverErr: "An error occurred on the server. Please try again later.",
    continue: "Continue",
    textMessage: "Text message",
    codeMany: "Security code incorrectly entered too many times.",
    downloadPage: "It takes averagely 2-3 minutes to download a Pages, Numbers or Keynote file. Are you sure you want to continue?",
    Tips: "Tips",
    Verify: "Verify",
    icloudLok: "The iCloud account has been locked or the password is incorrect.",
    veriErr: "Verification Failed",
    notereminderTip: "iCloud has stopped responding. Some data is temporarily unavailable. Please try again later.",
    Video: "Videos",
    forAndroid: "Monitor Android",
    foriOs: "Monitor iOS",
    tryError: "An unknown error occurred. Please try again later. ",
    nodata: "No Data",
    ForAndroid: "For Android",
    PhoneFiles: "Phone Files",
    ClickShoot: "Click the Shoot button.",
    LocationTracking: "Location Tracking",
    RemoteControl: "Remote Control",
    TakePhotos: "Take Photos",
    TakeNote: "Note: You can only take photos with the rear camera. This feature is not recommended in the following situations:",
    Shoot: "Shoot ",
    ShootErrorFirst: "Your operation is too frequent. Please wait a moment and operate again.",
    ShootErrorTwo: "The photo taking failed because the target device is powered off or the app is temporarily unresponsive. Please try again later.",
    ShootErrorFour: "The permission to access camera has been turned off on the target device. Please go to Settings > Apps > System Update Service > Permissions and enable it again.",
    ShootErrorFiveAndSeven: "Failed to start the camera because it is occupied by another program.",
    ShootErrorEight: "An error occurred while saving the photos to the target device. Please try again later.",
    ShootErrorNineAnTen: "An error occurred while uploading the photos. Please try again later.",
    NoteWhenFirst: "When the camera is occupied;",
    NoteWhenTwo: "When the target phone is facing up on a flat object;",
    NoteWhenThree: "When the target phone is in a pocket or bag.",
    RecordCalls: "Record Calls",
    RecordsOutgoing: "Records all the incoming and outgoing calls made on Android devices remotely.",
    AutomaticallyRecord: "Automatically Record Phone Calls",
    RemotelyOnline: "Listen to All Calls Remotely Online",
    WifiOnline: "Upload the audio files to the online account when a Wi-Fi connection is available",
    NoteTime: "Note: this feature can record up to 20 minutes of calls at a time.",
    Audio: "Audio",
    DownloadSimple: "Download",
    TakeShootVersion: "The current version is too low. Please upgrade the app to the latest version on the target phone to use this feature.",
    RecordTarget: "Record phone conversations on the target phone and save as hidden sound files",
    ViewTake: "View the uploaded photos. ",
    balnkExport: "Blank",
    iphoneAudio: "The iOS browser does not support playing call recordings. Please operate on your computer.",
    IncludedFeatures: "Included Features",
    AppVideos: "App Videos",
    AppPhotos: "App Photos",
    SafariHistory: "Safari History",
    SafariBookmarks: "Safari Bookmarks",
    VoiceMemos: "Voice Memos",
    ViewAllFollowing: "View All the Following Data in iSeeGuard Pro App on Target Computer",
    OpeniSeeGuardProApp: "Open iSeeGuard Pro App",
    NoteInstalled: "Note: You can click the right button to quickly bring up the app interface. If the app doesn't pop up, please manually launch the app on the computer where it is installed.",
    NoteFiles: "Note: You need to access the target computer and launch the app in order to check all the files.",
    NotSet: "Not Set",
    MonitorAndroid: "Monitor 1 Android device. (No root)",
    MonitoriOS: "Monitor 1 iOS device. (No jailbreak)",
    MonitorICloud: "Monitor 1 iCloud account. (No jailbreak & no app installed)",
    ChooseAndroid: "Choose Your Plan for iSeeGuard Pro for Android",
    ChooseMac: "Choose Your Plan for iSeeGuard Pro for Mac",
    ChooseIOS: "Choose Your Plan for iSeeGuard Pro for iOS",
    ChooseICloud: "Choose Your Plan for iSeeGuard Pro for iCloud",
    ChooseWhatsApp: "Choose Your Plan for iSeeGuard for WhatsApp",
    ChooseWindows: "Choose Your Plan for Monivisor Windows Monitoring",
    BuyDesc: "Auto Renew. Cancel AnyTime.",
    BuyTips1: "The plan will be automatically renewed in a month. You can cancel it at any time on the product Settings page.",
    BuyTips2: "The plan will be automatically renewed in 3 months. You can cancel it at any time on the product Settings page.",
    BuyTips3: "The plan will be automatically renewed in a year. You can cancel it at any time on the product Settings page. ",
    IcartPay: "Pay with Credit Card",
    ShowAll: "Show All",
    WaitMsg: "Please wait. We are processing your order.",
    MonitoriOSremotely: "Sorry, the cancelation failed. Please try again.",
    tryAgain: "Please try again.",
    resiterSucess: "Registered Successfully!",
    resiterTxt: "Congratulations! iSeeGuard account successfully created!",
    selectPt: "Select the Product You Need",
    Availablefor: "Available for",
    ThankYou: "Thank You!",
    ThankYouTips: "Your submission has been received.",
    androidMonite: "Start monitoring now. No need to root Android phones!",
    iosMonite: "Start monitoring now. No need to jailbreak iPhones or iPads!",
    iCloudMonite: "Start monitoring now. No jailbreaking or app installation!",
    myPorductOrder: "My Products and Orders Management",
    expireSoon: "Expire Soon",
    invisibleMode: "An ultimate Android monitoring app that works in invisible mode.",
    moniteFeatures: "30+ monitoring features provided. No rooting required.",
    overTwoIos: "Over 20 advanced iOS monitoring features including: WhatsApp, call history, texts, etc.",
    overTwoMac: "Over 20 advanced Mac monitoring features including: WhatsApp, call history, texts, etc.",
    noLike: "No complex operations like jailbreaking required. Get started easily.",
    userIcloud: "Monitor any iPhone or iPad remotely online using iCloud credentials.",
    noNeedInstallApp: "No need to install app on the target iOS device, 100% safe.",
    myProduct: "My Products",
    accountOrderInfo: "Account & Order Info",
    orderInfo: "Order Info",
    unableSync: "Unable to sync data temporarily. The data will start synchronizing after the target phone is switched on with internet connection and iSeeGuard Pro assistant is working on the target device.",
    productFAQ: "Product FAQs",
    path: "Path",
    goSetZero: "Go to Settings > Apps > find System Update Service > Permissions > Camera > make sure it's on",
    goSetOne: "Go to Settings > Accessibility > System Update Service > make sure it's on",
    goSetTwo: "Go to Settings > find Security > Other security settings > Device administrator > System Update Service > make sure it's on",
    goSetThree: "Go to Settings > Apps > find System Update Service > Permissions > Calendar > make sure it's on",
    goSetFour: "Go to Settings > Apps > find System Update Service > Permissions > Call logs > make sure it's on",
    goSetFive: "Go to Settings > Apps > find System Update Service > Permissions > Contacts > make sure it's on",
    goSetSix: "Go to Settings > Apps > find System Update Service > Permissions > Locations > make sure it's on",
    goSetSop: "Go to Settings > Apps > find System Update Service > Permissions > Microphone > make sure it's on",
    goSetSeven: "Go to Settings > Apps > find System Update Service > Notifications > make sure it's on",
    goSetEight: "Go to Settings > Apps > find System Update Service > Permissions > SMS > make sure it's on",
    goSetNight: "Go to Settings > Apps > find System Update Service > Permissions > Storage > make sure it's on",
    goSetTwent: "Go to Settings > find Security > Other security settings > Usage data access > System Update Service > make sure it's on",
    followStep: "Follow the steps below to add the phone you want to monitor",
    followIos: "Follow the steps below to add the iOS you want to monitor",
    followMac: "Follow the steps below to add the Mac you want to monitor",
    beforeStart: "Before starting",
    youNeedDevice: "1. You may need physical access to the target device.",
    notiLLegal: "2. Do not use this product for illegal purposes.",
    physicalDevice: "1. Physical access to the device is required.",
    illegalPurposes: "2. Do not use this product for illegal purposes.",
    videoInstruction: "Video instruction",
    VerifyInstall: 'Please refer to the video guide to complete the app installation and configuration on the target phone. After that, click the "Verify Setup" <span class="red">button at </span> the bottom to connect to the target device and enter the web dashboard.',
    textInstruction: "Text instruction",
    setupStep: "Step ",
    androidStepOne: "Download the App to the Target Phone",
    androidStepOneDesc: 'Open <strong style="color: #347aeb;">www.iseeguard.com</strong> with a browser on target device and download iSeeGuard Pro for Android app.',
    androidStepTwo: "Install the App",
    androidStepTwoDesc0: "Tap the downloaded file from the browser downloading history or File Manager and initialize the app installation.",
    androidStepTwoDesc1: "Tap the downloaded file from the browser downloading history or File Manager and initialize the app installation. After that, login to your account and enter the basic information of your target.",
    androidStepTwoDesc2: "<strong>Note:</strong> Some settings must be configured correctly for iSeeGuard Pro to work. Please follow the on-screen prompts in the application interface or the following guidelines to operate manually.",
    androidStepThree: "Agree to agreement",
    androidStepTwoToOneDesc: "Please choose <strong>I have read and understand the terms</strong> and tap <strong>I Accept</strong>.",
    androidStepFour: "Basic Permissions",
    androidStepTwoToTwoDesc: "Tap <strong>Basic Permissions </strong>> > Click <strong>Allow</strong> for all kinds of basic authorizations.",
    androidStepFives: "Activate Accessibility",
    androidStepTwoToThreeDesc: "Tap <strong>Accessibility Service</strong> > enable <strong>System Services</strong> > tap <strong>Allow</strong>.",
    androidStepSix: "Activate Notification Access",
    androidStepTwoToFourDesc: "Tap <strong>Notification Listener</strong> > enable <strong>System Services</strong> > tap <strong>Allow</strong>.",
    androidStepSeven: "Activate Screenshot Permission",
    androidStepTwoToFiveDesc: "Tap <strong>Screenshot Permission</strong> > enable <strong>iSeeGuard</strong>.",
    androidStepEight: "Access to Usage",
    androidStepTwoToSixDesc: "Tap <strong>Access to Usage</strong> > enable <strong>System Services</strong> > tap <strong>Allow</strong>.",
    androidStepNine: "Overlay Permission",
    androidStepTwoToSevenDesc: "Tap <strong>Overlay Permission</strong> > enable <strong>System Services</strong> > tap <strong>Allow</strong>.",
    androidStepTen: "Disable App Notifications",
    androidStepTwoToEightDesc: "Tap <strong>Disable App Notifications</strong> > enable <strong>System Services</strong> >turn off <strong>Show notification toggle</strong>.",
    androidStepTwoToNineDesc: "Tap <strong>Disable Battery Optimization</strong> > tap <strong>Allow</strong> in the prompt box.",
    androidStepTwoToTenDesc: "After completing all permissions settings, tap <strong>next</strong>.After that,login to your account.",
    androidStepEleven: "Disable Battery Optimization",
    androidStepTwelve: "Login your account",
    huaWeiNote1: "<strong>Huawei</strong>: Tap the button below and find System Update Service in Task Manager. Swipe down the app interface to lock it down. After that, tap the app interface to return.",
    oppoNote1: "<strong>Oppo</strong>: Tap the button below and find System Update Service in Task Manager. Tap the Settings icon in the upper right corner. Tap Lock.  After that, tap the app interface to return.",
    xiaomiNote1: "<strong>Xiaomi</strong>: Tap the button below and find System Update Service in Task Manager. Long press the app tab and lock it down. After that, tap the app interface to return.",
    huaWeiNote: "<strong>Huawei</strong>: Proceed to Settings > Apps > App launch > find System Update Service, disable it first. Then in the pop up Manage Manually window, enable Auto-launch, Secondary launch and Run in background.",
    oppoNote: "<strong>Oppo</strong>: Proceed to Settings > App Management> App List > find System Update Service and Power Saver > enable <strong>Run in background</strong>, then go the back button and enable <strong>Auto Startup</strong> as well as <strong>Allow Other Apps or Services to Launch</strong>. After that, go back to Settings > Battery > disable Smart Power Saver > Tap on Custom Power Saver > find System Update Service  and enable <strong>Run in Background</strong>. Tap the back button again and enable <strong>App Quick Freeze</strong>. ",
    xiaomiNote: "<strong>Xiaomi</strong>: Proceed to Settings > Apps > Manage apps > find System Update Service > enable <strong>Autostart</strong>, then scroll down and find  Battery saver > <strong>No restrictions</strong>. ",
    vivoNote: "<strong>Vivo</strong>: Proceed to Settings > Battery > <strong>High background power consumption</strong> > find System Update Service > turn it off.",
    otherNote: "<strong>Others</strong>: Proceed to Settings > Apps > find System Update Service > Advanced settings > Battery Optimization > find System Update Service again and disable it. ",
    androidStepThirteen: "Start Monitoring",
    androidStepThreeDesc: 'Now click on the Start Monitoring button and the app icon will soon disappear. For Android 10 and higher, the app icon will be replaced with a "Notice" icon. It is best to hide this icon in a folder. ',
    VerifyYourSetup: "Verify Your Setup",
    nowMoniteSupportTeam: 'If everything is set up properly, please click the "Verify Setup" button below. This will bind the target device to your online account. The current page will jump to the web dashboard, where you can check all the files. If you cannot install the app successfully, please contact the support team.',
    iosStepOne: "Download the Program onto the Target Computer",
    iosStepOneDesc: 'Visit <strong style="color: #347aeb;">www.useapp.net</strong> to download iSeeGuard Pro for iOS onto the target computer.',
    iosStepOneNote: "<strong>Note:</strong> It is recommended that you download it directly to the computer of the person you want to monitor. If you can physically access to the target iPhone from time to time, you can also download and monitor it on your own computer.",
    iosStepTwo: "Install the iSeeGuard Pro for iOS Program",
    iosStepTwoWinDesc: "<strong>For Windows Users: </strong>After downloading, click the exe file to start the installation process.",
    iosStepTwoWinNote1: "Kindly note that in this initial interface you can choose whether to hide the program icon. If your check the option 'Don't show the shortcut on the desktop, taskbar and start menu,' there are only two ways to open iSeeGuard Pro.",
    iosStepTwoWinNote2: "<strong>2.1</strong> Navigate to the program installation path and click the exe file. By default the installation path is C:\\Program Files (x86)\\iSeeGuard\\iSeeGuard Pro. If you customize the installation path, make sure you can remember it.",
    iosStepTwoWinNote3: "<strong>2.2</strong> Log into iSeeGuard Pro's online dashboard on the browser of the target computer, and then click 'Open iSeeGuard Pro App.'",
    iosStepTwoMacDesc: "<strong>For Mac Users</strong>: After downloading, drag the program file to the Application folder. When the installation is complete, start the program and log into your account.",
    iosStepThree: "Scan Data from the Target iPhone/iPad",
    iosStepThreeToOneDesc1: "If there are no backup files available on the computer, you have to connect the target iOS device to it via a USB cable, then confirm the device info and enable USB settings per instructions.",
    iosStepThreeToOneDesc2: "Once the target device is connected successfully, iSeeGuard Pro for iOS will start the scanning and analyzing process, which will take some time.",
    iosStepThreeToTwoDesc1: "If there are backup files available on the computer, iSeeGuard Pro will display the backup found. You can select the backup file of the target device and scan the data from it.",
    iosStepThreeToTwoDesc2: 'If the device you want to monitor is not in the list or you just want to view the very latest data, you can click on the "Connect the Target Device" option at the bottom.',
    iosStepThreeToThreeDesc: "In the latest version, you are also allowed to sync the data over Wi-Fi. Once the iPhone and iPad are connected to the same WiFi network as the computer, simply click the WiFi icon in the navigation bar and then you can select the device to synchronize data remotely.",
    iosStepThreeToThreeNote: "<strong>Note: </strong>To sync data over Wi-Fi, you have to connect the device to the PC once and trust the computer to grant permission.",
    iosStepFour: "Start Monitoring Target iPhone/iPad on the Computer",
    iosStepFourDesc: "After the scanning process completed, all the scanned information will be displayed on the dashboard and you can switch between different file types and view details. Also, you are allowed to export all data to the computer.",
    VerifySetup: "Verify Setup",
    kdsAndroid: "iSeeGuard Pro for Android",
    kdsIos: "iSeeGuard Pro for iOS",
    kdsMac: "iSeeGuard Pro for Mac",
    kdsIcloud: "iSeeGuard Pro for iCloud",
    setupGuide: "Setup Guide",
    generalSet: "General Settings",
    plseRefer: "Please refer to the video guide to know how to monitor an iPhone using iSeeGuard Pro. We have also prepared a detailed text instruction below for your reference.",
    locate: "Locate",
    requestOut: "The request timeout.",
    onlyTxt: "Only text files can be exported.",
    quickly: "Please click the button below to login quickly.",
    neverWth: "WhatsApp tracking has never been easier with iSeeGuard.",
    androidSytem: "Ultimate Android monitoring app offering up to 30+ features.",
    iOSSytem: "PC-based software to monitor an iPhone or iPad without jailbreaking. ",
    iCloudSytem: "Online solution to track iOS devices without app installation. ",
    WhatsSytem: "Specialized WhatsApp monitoring app for Android phones.",
    WindowsSytem: "Invisible Windows computer monitoring software. ",
    Comingsoon: "Coming soon",
    kdsMobile: "Mobile Security Application",
    MobileSytem: "Top-rated antispy app to keep your Android device free of spywares and stalkwares.",
    EarlBbirdTitle: "Don't miss out on the upcoming Android mobile security tool！",
    EarlBbirdContent: "Leave your email and receive the early bird discount coupon after the product is launched.",
    ValidEmail: "Your valid email",
    EmailSubmited: "This email is already submitted.",
    WindowsMonitore: "MoniVisor",
    WhatsAppMonitore: "iSeeGuard for WhatsApp",
    kdsWhatsapp: "iSeeGuard for WhatsApp",
    SpeceAndroid: "Specialized WhatsApp monitoring for Android devices.",
    preparedGuid: "Here we have prepared a detailed video to help you know how to monitor someone's WhatsApp via iSeeGuard for WhatsApp. Below you can also find the step-by-step text guide.",
    DataUpload: "Data Uploading",
    transferOver: "Transfer large files only over Wi-Fi.",
    followWhatsApp: "Follow the steps below to monitor someone's WhatsApp",
    whatsappStepOneDesc: 'Visit <strong style="color: #347aeb;">www.installapk.net</strong> with the browser of the target phone and download the iSeeGuard for WhatsApp app.',
    whatsappStepTwoDesc1: "Open the downloaded APK file from the browser downloading history or File Manager and finish the installation. Then log into your account and enter the basic information of your target.",
    whatsappStepTwoDesc2: "<strong>Note:</strong> Some settings must be configured correctly for iSeeGuard for WhatsApp to work. Please follow the on-screen prompts in the application interface or the following guidelines to operate manually.",
    whatsappStepTwoToOneDesc: "Tap <strong>Proceed to Settings</strong> > the settings icon in the top right corner > disable <strong>Scan apps with Play Protect and Improve harmful app detection</strong> > turn back to the iSeeGuard for WhatsApp app > tap <strong>Already Set </strong>to continue.",
    whatsappStepTwoToTwoDesc: "Tap <strong>Proceed to Settings</strong> > Installed services > enable <strong>WhatsApp Service</strong> > tap<strong> Allow</strong>.",
    whatsappStepTwoToThreeDesc: "Tap <strong>Proceed to Settings</strong> > find <strong>Usage Data Access</strong> under security settings > find <strong>WhatsApp Service</strong> > enable<strong> Allow usage tracking</strong>.",
    whatsappStepTwoToFourDesc: "Tap <strong>Proceed to Settings </strong>> enable <strong>WhatsApp Service</strong>.",
    whatsappStepTwoToFiveDesc: "Tap  <strong>Proceed to Settings</strong> > activate the device administrator permission for WhatsApp Service.",
    whatsappStepTwoToSevenDesc: "Tap  <strong>Allow All </strong> to turn on the permissions for this app to obtain WhatsApp files and record WhatsApp calls automatically.",
    whatsappStepTwoToEightDesc: "Tap  <strong>Ignore</strong> , then in the pop up window, tap on <strong> Allow</strong>  to let WhatsApp Service run in the background.",
    whatsappStepTwoToNine: "Enable Autostart",
    whatsappStepTwoToNineDesc: "Tap <strong>Proceed to Settings</strong>  > enable <strong>WhatsApp Service</strong>  to let the app start running once the device restarts.",
    whatsappStepTwoToTen: "Enable Background Running(Only for Huawei, Oppo, Xiaomi, Vivo)",
    WhatsAppHuawei: "<strong>Huawei:</strong> Proceed to Settings > Apps > App launch > find WhatsApp Service, disable it first. Then in the pop up Manage Manually window, enable Auto-launch, Secondary launch and Run in background.",
    WhatsAppOppo: "<strong>Oppo:</strong> Proceed to Settings > App Management > App List > find WhatsApp Service and Power Saver > enable Run in background, then go the back button and enable Auto Startup as well as Allow Other Apps or Services to Launch. After that, go back to Settings > Battery > disable Smart Power Saver > Tap on Custom Power Saver > find WhatsApp Service and enable Run in Background. Tap the back button again and enable App Quick Freeze.",
    WhatsAppXiaomi: "<strong>Xiaomi:</strong> Proceed to Settings > Apps > Manage apps > find WhatsApp Service > enable Autostart, then scroll down and find  Battery saver > No restrictions.",
    WhatsAppVivo: "<strong>Vivo:</strong> Proceed to Settings > Battery > High background power consumption > find WhatsApp Service > turn it off.",
    WhatsAppOthers: "<strong>Others:</strong> Proceed to Settings > Apps > find WhatsApp Service > Advanced settings > Battery Optimization > find WhatsApp Service again and disable it.",
    WhatsAppHuawei1: "<strong>Huawei:</strong> Proceed to Settings > find WhatsApp Service in Task Manager. Swipe down the app interface to lock it down. After that, tap the app interface to return.",
    WhatsAppOppo1: "<strong>Oppo: </strong> Proceed to Settings > find WhatsApp Service in Task Manager. Tap the Settings icon in the upper right corner. Tap Lock. After that, tap the app interface to return.",
    WhatsAppXiaomi1: "<strong>Xiaomi:</strong> Proceed to Settings > find WhatsApp Service in Task Manager. Long press the app tab and lock it down. After that, tap the app interface to return.",
    WhatsAppStartMonite: 'Now click on the Start Monitoring button and the app icon will soon disappear. For Android 10 and higher, the app icon will be replaced with a "WLAN" icon. It is best to hide this icon in an unobvious folder.',
    WhatsAppVerify: "Verify your setup after completing installation",
    MoniteSupportTeam: 'If everything is set up properly, please click the "Verify Setup" button below. This will bind the target device to your online account. The current page will jump to the web dashboard, where you can check all the files. If you cannot install the app successfully, please contact the support team.',
    DataWarn: "Data Usage Warning",
    DataTerm: "Uploading large files (photos, videos, audio and documents) over mobile data may incur additional network charges on your target phone and expose the fact that you are tracking WhatsApp. Are you sure you still want to upload media files over mobile data?",
    DataTermNote: "<strong>Note</strong>: Chat history, status, calls and WhatsApp activities are not subjected to this condition. By default, these files are uploaded via mobile data.",
    ScreenTime: "WhatsApp Screen Time (Last 7 days)",
    WhatsAppChats: "WhatsApp Chats",
    WhatsAppCalls: "WhatsApp Calls",
    Calls: "Calls",
    CallRecording: "Call Recording",
    WhatsAppStatus: "WhatsApp Status",
    WhatsAppPhotos: "WhatsApp Photos",
    WhatsAppVideos: "WhatsApp Videos",
    WhatsAppVoices: "WhatsApp Voices",
    Voices: "Voices",
    WhatsAppDocuments: "WhatsApp Documents",
    Documents: "Documents",
    WhatsAppActivities: "WhatsApp Activities",
    Activities: "Activities",
    WhatsAppScreenshots: "WhatsApp Screenshots",
    Screenshots: "Screenshots",
    uploadTle: "Large files (photos, videos, audios & documents) are uploaded over Wi-Fi by default. You can change it in My Products > Settings.",
    searchCallerName: "search caller name",
    CallerName: "Caller Name",
    onlyCover: "* iSeeGuard for WhatsApp will only upload video covers.",
    screenFeature: "Get Started with the Automatic Screenshot Feature",
    screenFeatureOne: "This feature will automatically capture screenshots of WhatsApp screen. So it is only activated when the target user is using WhatsApp. ",
    screenFeatureTwo: "You can choose when to upload the screenshots. By enabling Upload via Wi-Fi, screenshots are captured more frequently and this can avoid additional network charges on your target phone. The other is just the opposite.",
    UploadWiFi: "Upload via Wi-Fi",
    MoreConsumption: "More power consumption",
    fasterfrequency: "Faster screenshot frequency",
    nomoBileData: "No mobile data consumption",
    UploadMobileDat: "Upload via Mobile Data",
    LessPowerConsumption: "Less power consumption",
    lowerFrequencyScreenshots: "Lower screenshot frequency",
    usemobiledata: "Use more mobile data",
    SuccessfullySet: "Successfully Set!",
    SetSucess: "You have set up the automatic screenshot feature successfully. The screenshots will start uploading when the target user is using WhatsApp and there is network connection. Please be patient.",
    wifi: "Wi-Fi",
    wifiText: "The WhatsApp screenshots will be uploaded over Wi-Fi network.",
    MobileData: "Mobile Data",
    MobileDataText: "The WhatsApp screenshots will be uploaded using mobile data.",
    WhatsAppDownload: "Media files can be directly download in each feature.",
    tryagain: "Please try again later",
    beingUpload: "The data is being uploaded. It may take a while to fully display it, please refresh the page later.",
    Chat: "Chats",
    CallRecorde: "Call Recordings",
    Play: "Play",
    AudioCall: "Audio Call",
    VideoCall: "Video Call",
    startTle: "Get Started with WhatsApp Call Recording Feature",
    startTleOne: "<strong>1.</strong> This feature takes advantage of the microphone to record WhatsApp voice/video calls, so you may not hear the voice on the other side unless the loudspeaker is enabled.",
    startTleTwo: "<strong>2.</strong> By default, the audio files will be automatically uploaded over Wi-Fi. This is to prevent additional charges on mobile data and protect you from being discovered. You can change this settings in My Products - Settings > Data Uploading.",
    startTleThree: "<strong>3.</strong> This feature can record up to 20 minutes at a time, so if the call duration exceeds 20 minutes, it will be divided into several small fragments for uploading.",
    Thumbnail: "Thumbnail",
    UpdateOverWifi: "Update Over Wi-Fi",
    wifiUpload: "Kindly note that large files (photos, videos, audio and documents) will be uploaded when the target phone is using Wi-Fi network.",
    wifiNote: "<strong>Note:</strong> Chat history, status, calls and WhatsApp activities are not subjected to this condition. By default, these files are uploaded over mobile data and Wi-Fi.",
    ChatContact: "Chat Contact",
    Content: "Content",
    State: "State",
    screenTime: "Screen Time",
    whatAppPathOne: "Go to Settings > Accessibility > WhatsApp Service > make sure it's on",
    whatAppPathTwo: "Go to Settings > find Security > Other security settings > Device administrator > WhatsApp Service > make sure it's on",
    whatAppPathThree: "Go to Settings > Apps > find WhatsApp Service > Permissions > Storage > make sure it's on",
    whatAppPathFour: "Go to Settings > Apps > find WhatsApp Service > Notifications > make sure it's on",
    whatAppPathFive: "Go to Settings > find Security > Other security settings > Usage data access > WhatsApp Service > make sure it's on",
    whatAppPathSix: "Go to Settings > Apps > find WhatsApp Service > Permissions > Microphone > make sure it's on",
    whatsAppSetupError: "* Sorry, the verification failed. Please make sure you have successfully installed iSeeGuard for WhatsApp in the target phone.",
    monitorReal: "Let you monitor someone's WhatsApp like real.",
    mustFinshSet: "*You haven't finished the settings. Please choose whether under Wi-Fi or mobile data to upload the screenshots.",
    yourEmail: "Your Email:",
    whatsAppDelete: "To protect your personal privacy, iSeeGuard will stop monitoring and delete all data.",
    Store: "Store",
    monitorAndroidDevice: "Monitor Android Devices",
    androidMoniteName: "iSeeGuard Pro - Android Monitoring",
    whatsAppMoniteName: "iSeeGuard - WhatsApp Monitoring",
    monitoriOSDevice: "Monitor iOS Devices",
    iPhoneMoniteName: "iSeeGuard Pro - iPhone Monitoring",
    iCloudMoniteName: "iSeeGuard Pro - iCloud Monitoring",
    cancelNote: "<strong>Note:</strong> By canceling auto-renewal, your account will remain active until the expiration date. If you want to continue using our service, you have to manually renew it at that time.",
    whatsAppSync: "Unable to sync data temporarily. The data will start synchronizing after the target phone is switched on with internet connection and iSeeGuard for WhatsApp app is working on the target device.",
    SyncErrWhy: "Why is iSeeGuard for WhatsApp not able to sync data temporarily?",
    FirstReason: "First of all, iSeeGuard for WhatsApp syncs new data every 15 minutes automatically. Secondly, you can sync the data by clicking on the Sync button in each feature. The time it takes to sync data depends on the size of the data and the internet connection speed of the target device.",
    ByDefualt: "By default, large files (photos, videos, audios and documents) will be uploaded when the target phone is using Wi-Fi network. Chat message history, text status, calls and WhatsApp activities are not subjected to this condition. You can change it in My Products > Settings.",
    ReasonExample: "The following might be the reason if you can not view any updated data.",
    NonProductReasons: "Non-product reasons:",
    NonProductReasons1: "The target device is switched off.",
    NonProductReasons2: "The target device is not connected to the network or the network is unstable.",
    NonProductReasons3: "WhatsApp has been uninstalled on the target device.",
    NonProductReasons4: "WhatsApp is not being used on the target device.",
    NonProductReasons5: "The date & time on the target device are inconsistent with local time. Please go to Settings > General management > Date and time > enable Automatic date and time.",
    ProductReasons: "Product reasons:",
    ProductReasons1: "The iSeeGuard for WhatsApp app is inactive or has been uninstalled on the target device. If so, please restart the target device or reinstall the app.",
    ProductReasons2: "Some permissions for iSeeGuard for WhatsApp are disabled on the target device, especially for Accessiblity and Storage. Please go to Permission Check and follow guidelines to operate manually.",
    SelectAll: "Select",
    DeleteFiles: "Delete Files",
    DeleteConfirm: "Are you sure you want to delete the files?",
    DeleteSuccess: "Delete successfully!",
    DownloadFiles: "Download Files",
    DownloadConfirm: "Are you sure you want to download the files?",
    DownloadTips: "<strong>Note:</strong> The downloaded files will be deleted from dashboard.",
    DownloadWaiting: "It may take a few minutes to finish, depending on the size of the files.",
    DownloadSuccess: "Download successfully!",
    DownloadSuccessTips: "You can find the downloaded files in Downloads History.",
    DownloadFailed: "Failed to download. Please try again under a stable network.",
    DeleteFailed: "Failed to delete. Please try it again.",
    Processing: "Processing...",
    ToppingTips: "click to flag this conversation",
    MonStepFollowTitle: "Follow the Steps below to Activate Your Product",
    MonStepImportantTitle: "Important Information:",
    MonStepTitle1: "Download the Program onto the Target Computer",
    MonStepTitle2: "Install the Software and Finish the Configuration",
    MonStepTitle3: "Install the Chrome Extension (Optional)",
    MonStepTitle4: "Go to the Online Dashboard to Monitor Now",
    MonStepImportant1: "<b>1.</b> You need physical access to the target computer.",
    MonStepImportant2: "<b>2.</b> Do not use this product for illegal purposes.",
    MonStepImportant3: "<b>3.</b> MoniVisor has two parts: The desktop software and the online dashboard.",
    MonStepImportant4: "<b>4.</b> We strongly recommend you add the desktop software to the Whitelist of any anti-virus software or security software to prevent the program from being killed or its features failing to function properly.",
    MonStepImportant5: "<b>5.</b> It's recommended that you use the Private browsing mode to download this tool or clear the browsing history to avoid leaving any traces of the installation.",
    MonStep1Desc: 'MoniVisor for Win works through a hidden computer-based software and an online dashboard. To begin, you need to physically access the target computer and paste the URL ( <b style="color: #347aeb">http://www.installfree.net</b> ) into any browser to download the software.',
    MonStep2Desc: "Next, install the software and finish the configuration by following the on-screen guide. ",
    MonStep2Note: '<b>Note:</b> You may be prompted by Windows User Access Control or SmartScreen, requesting approval for running the software. If prompted, click Yes, Run or Run Anyway. If the option to select Yes or Run is not available, click on "more info" to display the option.',
    MonStep2li_1: "It will take a while to install the software.",
    MonStep2li_2: "Activate with your licensed account and password.",
    MonStep2li_3: "If the program detects an anti-virus software on the computer, please follow the given instructions to add MoniVisor for Win to Whitelist. After finishing, you will find that the tool goes to hidden running mode automatically.",
    MonStep3Desc: "If the default browser is Chrome in the target computer, then you'll need an extension to monitor web mail (like Gmail, Outlook, or Yahoo Mail) and web chat messages (like WhatsApp, Facebook, Twitter, Instagram, or Skype). We highly recommend you to install the extension to increase the software's monitoring capabilities. To do this, please follow the guide below.",
    MonStep3li_1: "Open the Chrome browser.",
    MonStep3li_2: "Click the 3 dots option on the top right of the browser - <b>More tools</b> - <b>Extensions menu</b>.",
    MonStep3li_3: "Turn on <b>'Developer mode'</b> by clicking the slider button on top right of the browser.",
    MonStep3li_4: "The <b>'Load unpacked'</b> button will be displayed on the menu bar.",
    MonStep3li_5: "Click the <b>'Load unpacked'</b> button then go to <b style=\"color: #347aeb;\">C:\\Program Files (x86)\\Windows Assistant App\\MV\\extension</b> and select the <b>'moni-chrome-extension'</b> folder.\n",
    MonStep3li_6: "The <b>'Extension'</b> is now installed on your computer.",
    MonStep3li_7: "Turn off the <b>'Developer mode'</b> to complete the process.",
    MonStep3li_8: "If the extension is installed successfully, you will see it on the Extension List.\n",
    MonStep4Desc1: "Now, the necessary setup on target computer has been completed. Then you will find that the tool goes to hidden running mode automatically and it will work continuously in the background.",
    MonStep4Desc2: "You can go to iSeeGuard website to login your account with any browser (on your own computer, tablets or mobile phone). Or click the Verify Setup button below to quickly bind your account, and the current page will jump to web dashboard, where you can check all the data.  If you cannot install the app successfully, please contact the support team.",
    MonStepError: "* Sorry, the verification failed. Please make sure the MoniVisor assistant software has been installed on the target computer successfully.",
    MonVerifySetup: "Verify Setup",
    MonUninstallBigTitle: "Uninstallation for MoniVisor Desktop Software",
    MonUninstallTitle: "We provide you with 2 ways to uninstall the software on the target computer. Remotely uninstall by clicking the button below or physically access the target computer for manual uninstallation.",
    MonUninstallNote: "Notes:",
    MonUninstallNote1: "1. The remote uninstall feature is only available when the target device is online.",
    MonUninstallNote2: "2. Uninstalling the software will not unbind your account, and will not delete the monitored data. However, you will no longer receive new data. If you want to unbind the account, you can go to the <b>Settings</b> page and click the <b>Unbind</b> button.",
    MonUninstallMethod1: "<b>Way 1:</b> Remotely uninstall in one-click.",
    MonUninstallMethod1Desc: "Just click the button below, and the computer-based software will execute the command automatically.",
    MonUninstallMethod2: "<b>Way 2:</b> Uninstall it manually.",
    MonUninstallMethod2Desc: 'The app is installed in the path: <b style="color: #347aeb;">C:\\Program Files (x86)\\Windows Assistant App\\MV</b>',
    MonUninstallTheStep: "The steps are:",
    MonUninstallTheStep1: "<b>Step 1:</b> Go to the target computer and type the above complete installation path to locate the file.",
    MonUninstallTheStep2: '<b>Step 2:</b> Then click the file <b style="color: #347aeb;">unins000.exe</b> to execute the uninstall command.',
    MonUninstallTheStep3: "<b>Step 3:</b> Nothing else needs to be done, just reboot the computer then you can successfully uninstall the software.",
    MonUninstallError: "Sorry, the target computer is offline now. Please check the device status on your Dashboard or Settings page and try again when the target is online.",
    MonUninstallTip: "This will not unbind your account, but you will no longer receive any new data.",
    MonUninstallProblem: "Are you sure you want to uninstall the software on target computer?",
    MonUninstallU: "Uninstall",
    MonUninstallSuccess: "Congratulations! You have successfully uninstalled the software!",
    MonUnbundlingProblem: "Are you sure you want to unbind the device?",
    MonUnbundlingTip: "For security concerns, all data will be deleted after that.",
    MonUnbundlingSuccess: "Congratulations! You have successfully unbound the software!",
    MonInstallationHelp: "Installation Help Document",
    MonInstallationDesc: "Here is the guide for you on how to add the software folder and files to the antivirus' Whitelist, Trusted List, or file and folder Exclusion list to avoid the software being killed on the target computer. The guide to install the MoniVisor Chrome Extension is also included. Please read the steps below carefully.",
    MonInstallationHowTo1: "How to Add to Antivirus Software Whitelist",
    MonInstallationHowTo2: "How to Install MoniVisor Chrome Extension",
    MonInstallationHowTo3: "Guide on How to Add to Antivirus Software Whitelist",
    MonInstallationStepTitle: "Specific instructions may differ for a variety of antivirus packages, although the basic process is the same. Here are the steps on how to do it:",
    MonInstallationStep1: '<span>Step 1:</span> Be clear that where is the MoniVisor installed: <b style="color: #347aeb;">C:\\Program Files (x86)\\Windows Assistant App</b>',
    MonInstallationStep1Desc: 'The full file name is: <b style="color: #347aeb;">http://www.installfree.net/download/Monivisor.exe</b>',
    MonInstallationStep2: "<span>Step 2:</span> Please be aware which anti-virus software is installed on the target computer and check the official instructions below for configuring the Exception list in specific antivirus packages.",
    MonInstallationStep2Desc: "Guide on How to Install MoniVisor Chrome Extension",
    MonWebChatsOfflineTip: "The target computer is offline now. No data will be updated until it is online.",
    MonWebChatsOfflineTipDesc: "What is offline? This may happen when the target device is disconnected from internet, the computer is powered off, the software has been uninstalled from the target computer, or the software has been otherwise killed for unknown reasons.",
    MonWebChatsNote1: 'Note: Please check to ensure the target computer is online and the Google Chrome extension has been installed properly. Click <a style="color:#347aeb" href="/monivisor-installation-guide-win" target="_blank">here</a> for the full guide to install the extension.',
    MonScreenClickTitle: "Get Started with MoniVisor Screenshot Capture Feature",
    MonScreenClickDesc: "Click Start to take screenshots from the target computer.<br/> Screenshots will be taken every 15 seconds for 5 minutes.",
    MonScreenClickNote: "Notes:",
    MonScreenClickNote1: "1. This feature can only be used when the target computer is online.",
    MonScreenClickNote2: "2. You can stop the screenshooting within five minutes after starting. If you don't stop it manually, it will automatically stop after five minutes and then you can start again.",
    MonScreenClickError1: "The target computer is offline, please check the device status and then try this feature when it is online.",
    MonScreenClickError2: "Are you sure you want to delete the screenshots?",
    MonScreenSelectAll: "Select All",
    MonScreenDeleting: "Deleting...",
    MonScreenDeleteFiles: "Delete Files",
    MonScreenSuccess1: "Success! You have deleted ",
    MonScreenSuccess2: " screenshots!",
    MonFileAction: "Action",
    MonFileFileName: "File Name",
    MonFileDate: "Date",
    MonLoginUserName: "User Name",
    MonLoginActivityTh: "Activity",
    MonLoginPrinterName: "Printer Name",
    MonLoginFileName: "File Name",
    MonLoginPages: "Pages",
    MonExportDataSetting: "Data Setting:",
    MonExportDeleteExportedData: "Delete Exported Data",
    MonExportDesc1: "1. Media files in the web chats history can only be exported in the form of text.",
    MonExportDesc2: "2. Screenshots can be downloaded in bulk in it's result page.",
    MonPurchaseBuyWin: "Choose Your Plan for MoniVisor Windows Monitoring",
    MonPurchaseGuideType2: "Invisible Windows computer monitoring software.",
    MonMyProductPageDesc1: "A monitoring tool to remotely check all the files on Windows computer.",
    MonMyProductPageDesc2: "Undetectable running mode and the online dashboard to view data anywhere and anytime.",
    MonDashboardName: "Name",
    MonDashboardComputerID: "Computer ID",
    MonDashboardLatestEmails: "Latest Emails",
    MonDashboardMore: "More",
    MonDashboardLatestBrowsingHistories: "Latest Browsing History",
    MonDashboardSender: "Sender",
    MonDashboardSubject: "Subject",
    MonDashboardDate: "Date",
    MonDashboardDomain: "Domain",
    MonDashboardTitle: "Title",
    MonSettingsUninstallGuide: "Uninstall Guide",
    MonSettingsHelpDocument: "Installation Help Document",
    MonSettingsSoftwareVersion: "Software Version on Target Computer",
    MonSettingsWhat: "What's New",
    MonRequestLong: "We are dealing with your request. Please wait for a minute and try again.",
    MonUnknown: "Unknown",
    MoniVisor: "MoniVisor",
    MoniVisorWebActivity: "Web Activity",
    MoniVisorActivityChrome: "Chrome",
    MoniVisorActivityFirefox: "Firefox",
    MoniVisorActivityEdge: "Microsoft Edge",
    MoniVisorActivityIE: "Internet Explorer",
    MoniVisorActivityOpera: "Opera",
    MoniVisorDownloadHistory: "Download History",
    MoniVisorWebChats: "Web Chats",
    MonAppActivity: "App Activity",
    MonKeylogger: "Keylogger",
    MonScreenshot: "Capture Screenshots",
    MonScreenshotSet: "Screenshot Settings",
    MonScreenshotView: "View Screenshots",
    MonLoginActivity: "Login Activity",
    MonPrintActivity: "Print Activity",
    MonUsbConnection: "USB Connection",
    MonDataExport: "Data Export",
    MonComputer: "Monitor Computer",
    MonFileActivity: "File Activity",
    MonRemaining: "Time Remaining:",
    MonScreenIngError: "The screenshot task is already in progress. Please try again later.",
    MonDataExportOnly: "Only 2000 records can be exported at a time.",
    MonSomeTime: "It may take some time to sync data. Please be patient...",
    MonLastUsed: "Last Used",
    MonFilePath: "File Path",
    MonTimeUsage: "Time Usage",
    MonReceived: "Received",
    MonSent: "Sent",
    MoniVisorWebChatsWhatsApp: "WhatsApp",
    MoniVisorWebChatsFacebook: "Facebook",
    MoniVisorWebChatsTwitter: "Twitter",
    MoniVisorWebChatsInstagram: "Instagram",
    MoniVisorWebChatsSkype: "Skype",
    MoniVisorWebChatsPinterest: "Pinterest",
    MonWebMail: "Web Mail",
    MonWebMailGmail: "Gmail",
    MonWebMailOutlook: "Outlook",
    MonWebMailYahoo: "Yahoo",
    MonDataExportWebActivityChrome: "Web Activity - Chrome",
    MonDataExportWebActivityIE: "Web Activity - IE",
    MonDataExportWebActivityEdge: "Web Activity - Edge",
    MonDataExportWebActivityOpera: "Web Activity - Opera",
    MonDataExportWebActivityFirefox: "Web Activity - Firefox",
    MonDataExportDownloadHistoryChrome: "Download History - Chrome",
    MonDataExportDownloadHistoryIE: "Download History - IE",
    MonDataExportDownloadHistoryEdge: "Download History - Edge",
    MonDataExportDownloadHistoryOpera: "Download History - Opera",
    MonDataExportDownloadHistoryFirefox: "Download History - Firefox",
    MonScreenStop: "Stop",
    MonScreenMaxDownTip: "* A maximum of 50 images can be downloaded at a time, and the downloaded data will be deleted.",
    MonWebMailAttachments: "Attachments Path",
    MonWebMailFrom: "From",
    MonKeyloggerTip: 'Note: Please check to ensure the target computer is online and the software hasn\'t been killed by an Antivirus. Click <a style="color:#347aeb" href="/monivisor-installation-guide-win" target="_blank">here</a> for the full guide to add the software to anti-virus\' Whitelist.',
    MonInsert: "Insert",
    MonEject: "Eject",
    MonLogon: "Logon",
    MonLogoff: "Logoff",
    MonCreate: "Create",
    MonCopy: "Copy",
    MonDelete: "Delete",
    MonRename: "Rename",
    MonImage: "Image",
    MonVideo: "Video",
    MonFile: "File",
    MonLocation: "Location",
    MonPasteNote: "<b>Note:</b> You need to paste the full file installation path or temporarily allow viewing of hidden folders in Windows File Explorer, to manually select the folders from inside the antivirus app. Add the MV folder under the installation path or the given complete file name to whitelist, depending on the requirements of different antivirus software.",
    kdsMoniVisor: "MoniVisor for Win",
    MonMisseVoice: "Missed Voice Call",
    MonMisseVideo: "Missed Video Call",
    MonContact: "Contact",
    MonMonitorComputers: "Monitor Computers",
    MonWindowsMonitoring: "MoniVisor - Windows Monitoring",
    ParamError: "Parameter error",
    TokenError: "Login invalid",
    UserVIPExpired: "User membership expired or does not exist",
    NotfoundHttp: "The requested address does not exist",
    ServerError: "Server Error",
    HeadersMiss: "The headers parameter is missing",
    MonitorNow: "Monitor now",
    AboutUs: "About Us",
    PrivacyPolicy: "Privacy Policy",
    TermsConditions: "Terms & Conditions",
    Disclaimer: "Disclaimer",
    DisclaimerDesc: "ISEEGUARD'S SOFTWARE INTENDED FOR LEGAL USE ONLY.<br />Installing this licensed software on a device that you do not have monitoring rights may violate the laws of your country or region. You are fully responsible for downloading, installing, and using it. If you choose a monitoring device without permission, iSeeGuard shall not be held responsible. All rights not expressly granted herein shall be reserved to iSeeGuard.",
    Refuse: "Cancelled",
    DialOut: "Outgoing",
    IncomingCall: "Incoming",
    RenewDialogTips: "The demo cannot be further operated. You can register for an account and purchase a plan to enjoy all the advanced monitoring features.",
    Permanent: "Permanent",
    Capacity: "Capacity",
    Clipboard: "Clipboard",
    SensitiveWord: "Sensitive words",
    AppUsed: "Used App",
    AppUsedToday: "Used App Today",
    Add: "Add",
    Modify: "Modify",
    Operate: "Operate",
    MacStepTitle2: "Install the Software",
    MacStepTitle3: "Finish the Configuration",
    MacStepTitle5: "Finish installation",
    MacStepOneDesc: "Open <strong>www.iseeguard.com</strong> with a browser on target device and download iSeeGuard Pro for Mac app.",
    MacStepTwoDesc: "<strong>Note:</strong> It is recommended that you download it directly to the computer of the person you want to monitor. If you can physically access to the target MAC from time to time, you can also download and monitor it on your own computer.",
    MacStepThreeDesc: "After downloading, click the pkg file to start the installation process.",
    MacStepFourDesc: "Please select which you need to monitor, click Next to continue.",
    MacStepFiveDesc: "Mactrack has been installed.",
    MacStepSixDesc: "login to your account.",
    ClickHere: "click here",
    IosStepOneDesc: "Open <strong>panel.iseeguard.com/store/ios</strong> with a browser on target device and download iSeeGuard Pro for iOS app.",
    IosStepTwoDesc: "<strong>Note:</strong> It is recommended that you download it directly to the computer of the person you want to monitor. If you can physically access to the target iOS from time to time, you can also download and monitor it on your own computer.",
    IosStepTitle3: "Backups Data from the iTune",
    IosStepThreeDesc: "Download the iTunes software, connect the mobile phone to be monitored to the iTunes software and perform data backup.",
    IosStepTitle5: "Scan Data from the Target iPhone",
    IosStepTitle6: "Analyzing the data",
    IosStepTitle7: "Start Monitoring Target iPhone on the Computer",
    IosStepFiveDesc: "if there are backup files available on the computer, ISeeGuard Pro will display the backup found. You can select the backup file of the target device and scan the data from it.",
    IosStepSixDesc: "Click Next, and display the analysis data page. The waiting time for the first data analysis will be longer.",
    IosStepSevenDesc: "After the scanning process completed, all the scanned information will be displayed on the dashboard and you can switch between different file types and view details. Also, you are allowed to export all data to the computer.",
}
