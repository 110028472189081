<template>
  <div class="copy-right">
    <span>{{ $t("footCot") }}</span>
    <Lang class="lang" v-show="showLang" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Lang from "@/components/header/Lang";
export default {
  name: "CopyRight",
  components: {
    Lang,
  },
  computed: {
    ...mapGetters(["device"]),
    showLang() {
      return this.device === 'mobile' ? true : false
    },
  },
};
</script>

<style lang="scss" scoped>
.copy-right {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  color: #999;
  padding: 15px 30px;
}
</style>