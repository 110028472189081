<template>
  <div name="DemoLayout">
    <div class="app-wrapper">
      <div class="main-container">
        <div
          v-show="getAdminSidebarShow"
          class="hazy-layer"
          @click="setAdminSidebarShow(false)"
        />
        <transition name="left">
          <div v-show="getAdminSidebarShow" class="left-main">
            <Headers @nextPagehandle="nextPagehandle" />
            <sidebar :menu-list.sync="menuList" /> 
          </div>
        </transition>
        <div class="right-main">
          <backstage-head class="backstage-head" :is-demo="true" @handleMonitorBtn="handleMonitorBtn" />
          <el-scrollbar class="app-main-scrollbar" wrap-class="app-main">
            <app-main ref="appMain" />
          </el-scrollbar>
          <copyright />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import { AppMain, Sidebar, BackstageHead, Headers } from "./components";
import Copyright from "@/components/admin/Copyright";
export default {
  name: "DemoLayout",
  components: {
    AppMain,
    Sidebar,
    BackstageHead,
    Copyright,
    Headers,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters("admin", {
      getAdminSidebarShow: "getAdminSidebarShow",
    }),
    ...mapGetters("admin/permission", {
      menuList: "getMenuList",
    }),
    ...mapGetters("width", {
      theCurrentWidth: "theCurrentWidth",
    }),
    ...mapGetters("equipment", {
      getEquipmentId: "getEquipmentId",
    }),
  },
  watch: {
    //监听页面宽度
    theCurrentWidth: {
      immediate: true,
      handler(newValue) {
        this.setAdminSidebarShow(newValue > 1200);
      },
    },
  },
  created() {
    const that = this;
    that.setMenuList("Demo");
  },
  methods: {
    ...mapMutations("admin", {
      setAdminSidebarShow: "setAdminSidebarShow",
    }),
    ...mapMutations("admin/permission", {
      setMenuList: "setMenuList",
    }),
    ...mapActions("admin/dashboard", {
      requestData: "requestData",
    }),
    nextPagehandle() {
      this.$router.push({ path: '/demo/my-products' });
    },
    handleMonitorBtn() {
      this.$router.push({ name: "MyProducts" });
    }
  },
};
</script>

<style lang="scss" scoped>
//朦胧层
.hazy-layer {
  @media (max-width: 1199px) {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }
  /* 大屏幕（大桌面显示器，大于等于 1200px） */
  @media (min-width: 1200px) {
    display: none;
  }
}
//左侧边栏过度效果
.left-enter,
.left-leave-to {
  transform: translate3d(-100%, 0, 0);
}
.left-leave,
.left-enter-to {
  transform: translate3d(0, 0, 0);
}
.left-enter-active,
.left-leave-active {
  transition: all 0.2s;
}
.app-wrapper {
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: #f2f2f2;
  overflow: hidden;
}
.main-container {
  display: flex;
  justify-content: flex-start;
  height: calc(100vh - 60px);
}
.left-main {
  @media (max-width: 1199px) {
    position: absolute;
    z-index: 999;
  }

  /* 大屏幕（大桌面显示器，大于等于 1200px） */
  @media (min-width: 1200px) {
    display: block;
  }
}
.right-main {
  /* 小屏幕（平板，大于等于 768px） */
  @media (max-width: 1199px) {
    width: calc(100%);
  }

  /* 大屏幕（大桌面显示器，大于等于 1200px） */
  @media (min-width: 1200px) {
    width: calc(100% - 300px);
  }
}
.app-main-scrollbar {
  width: calc(100%);
  overflow-x: hidden;
  height: calc(100vh - 100px);
}
.app-main {
  width: 100%;
  padding: 0 32px;
  overflow-x: hidden;
  height: calc(100vh - 130px);
}
.el-scrollbar__wrap {
  overflow-x: hidden;
}
</style>
